import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { BASE_PATH_ADMIN_PRODUCTS, BASE_PATH_CATALOG } from "./../shopPath";
const styles = theme => ({
  link: {
    color: "inherit!important",
    textDecoration: "none!important"
  }
});

class ProductLink extends React.Component {
  render() {
    const { classes, id, children, isEdit } = this.props;
    return (
      <Link
        className={classes.link}
        to={
          isEdit
            ? `${BASE_PATH_ADMIN_PRODUCTS}/edit/${id}`
            : `${BASE_PATH_CATALOG}/view/${id}`
        }
      >
        {children}
      </Link>
    );
  }
}

ProductLink.defaultProps = {
  isEdit: false
};

ProductLink.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number,
  children: PropTypes.any,
  isEdit: PropTypes.bool
};

export default withStyles(styles)(ProductLink);
