/* eslint-disable */
import React, {Component, Fragment} from 'react';
import {get} from 'lodash';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DeleteIcon from '@material-ui/icons/Delete';
import Visibility from '@material-ui/icons/Visibility';
import Edit from '@material-ui/icons/Edit';
import Button from "@material-ui/core/Button/Button";
import Search from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import SweetAlert from 'react-bootstrap-sweetalert'
import { Link } from "react-router-dom";


import DeletePermission from '../../../Containers/Permissions/DeletePermission';
import ModifyPermission from '../../../Containers/Permissions/ModifyPermission';
import Modal from "Components/Modal/Modal";
import {toastr} from "helpers/CustomToastr";
import localize from "helpers/localize";
class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: props.data,
      confirm: false,
      success: false,
      isRowFormModalShow: false,
      selectedRow: null
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if(this.props.data !== nextProps.data) {
      this.setState({
        rows: nextProps.data
      });
    }
    if(!this.props.deleted && nextProps.deleted) {

        if(nextProps.deletedId) {

          const rows = this.state.rows.slice(0);
          let elementIndex;
          rows.forEach((obj, index) => {
            if(obj.id === nextProps.deletedId) {
              elementIndex = index;
            }
          });
          rows.splice(elementIndex, 1);
          this.setState({
            confirm: false,
            //success: true,
            rows
          });
          toastr.success(localize("basic", "Sikeres művelet"))
        }
    }
  };

  componentDidMount = () => {
      this.props.setLoading(false);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if(this.props.data !== prevProps.data) {
      this.props.setLoading(false);
    }
  };

  handleDeleteConfirm = (id) => {
    this.setState({
        confirm: id
    });
  };

  onCancelConfirm = () => {
    this.setState({
        confirm: false
    });
  };

  onSuccessClose = () => {
      this.setState({
          success: false
      });
  };

  onConfirm = () => {
      this.props.handleModuleEvent('delete', this.state.confirm);
  };

  showApnLink = (obj) => {
      if(!this.props.disabledTools && !this.props.disableViewIcon) {
          return <a className="cursor link" onClick={this.props.handleModuleEvent.bind(this, 'view', obj.id)}>{obj.apn}</a>
      }
      else {
          return <Fragment>
              {obj.apn}
          </Fragment>
      }

  };
    handleCloseFormModal = () => {
        this.setState({isRowFormModalShow: false});
    };
    handleOpenFormModal = (obj) => {
        this.setState({
            isRowFormModalShow: true,
            selectedRow: obj
        });
    };

  render() {
      let getViewButton;
      let modalBody = this.state.selectedRow ? this.props.getModalBodyByRow(this.state.selectedRow) : '';

      if (this.props.isViewInModalMode) {
          getViewButton = obj => <Button className="jr-btn text-secondary jr-btn-xs" aria-label={localize("basic", "Megtekintés")} onClick={() => this.handleOpenFormModal(obj)} component="span"><Visibility/></Button>;
      } else {
          getViewButton = obj => <a href={`${this.props.match.url}/view/${obj.id}`} onClick={evt =>{evt.preventDefault()}}><Button className="jr-btn text-secondary jr-btn-xs" aria-label={localize("basic", "Megtekintés")} onClick={this.props.handleModuleEvent.bind(this, 'view', obj.id)} component="span"><Visibility/></Button></a>;
      }

    return <Fragment>
        <SweetAlert show={this.state.confirm ? true : false}
          warning
          showCancel
          showLoaderOnConfirm
          confirmBtnText={localize("basic", "Igen")}
          cancelBtnText={localize("basic", "Mégsem")}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          
          timer={false}
          title={localize("basic", "Biztosan törölni akarja?")}
          onCancel={this.onCancelConfirm.bind(this)}
          onConfirm={this.onConfirm.bind(this)}
        />
        <SweetAlert
            show={this.state.success}
            success
            title={localize("basic", "Sikeres művelet")}
            onConfirm={this.onSuccessClose.bind(this)}
        />
        <TableBody>
            <Modal
                open={this.state.isRowFormModalShow}
                title={localize("settings", this.props.rowFormTitle ? this.props.rowFormTitle : '' )}
                onClose={this.handleCloseFormModal}
                onCancel={this.handleCloseFormModal}
                onAccept={this.props.onAcceptViewModal}
                disableIcon
                maxWidth="lg"
                keepMounted={false}
                yesText={this.props.modalYesText || 'Ok'}
                noText={this.props.modalNoText || 'Bezár'}
                disableOk
            >
                {modalBody}
            </Modal>
          {this.state.rows.length !== 0 && this.state.rows.map && this.state.rows.map((obj, index) => <TableRow key={'trb-'+index} style={this.props.rowFormat ? this.props.rowFormat(obj, index) : null}>
              {this.props.header.map((key, i) => {
                const renderedValue = this.props.renderCell ? this.props.renderCell(obj, index, i, key) : false;

                const value = renderedValue === false ?
                
                (get(obj, key._key, false) ? (Array.isArray(get(obj, key._key)) ? get(obj, key._key).join(',') :  get(obj, key._key)) : '-') : renderedValue;

                return <TableCell key={'tdb-'+index+'-'+i} style={{overflow: "visible"}} >
                  {this.props.disabledTooltips && this.props.disabledTooltips[key._key] ? (
                    <div style={{overflow: "hidden", textOverflow: "ellipsis", paddingLeft:3, paddingRight:3}}>
                    {value}
                </div>
                  ):(<Tooltip title={get(obj, key._key, '-') ? get(obj, key._key, '-'): ""}>
                      <div style={{overflow: "hidden", textOverflow: "ellipsis", paddingLeft:3, paddingRight:3}}>
                          {value}
                      </div>
                  </Tooltip>)}
              </TableCell>
            })}
              {!this.props.disabledTools && <TableCell className="tools text-right" style={{minWidth: 160, whiteSpace: "nowrap", textOverflow:"initial"}}>
                  {this.props.customIcons && <Fragment>{this.props.customIcons(obj)}</Fragment>}
                  {!this.props.disableViewIcon && <Tooltip title={localize("basic", "Megtekintés")}>
                      {getViewButton(obj) }
                  </Tooltip>}
                  {!this.props.disableEditIcon && <ModifyPermission isOwn={get(obj, 'is_owner', false)} match={this.props.match} module={this.props.module}>
                      <Tooltip title={localize("basic", "Szerkesztés")}>
                          <Button disabled={!get(obj, 'can_modify', true)} className={"jr-btn "+ (get(obj, 'can_modify', true) ? "text-warning": "") +" jr-btn-xs"} aria-label={localize("basic", "Szerkesztés")} onClick={this.props.handleModuleEvent.bind(this, 'edit', obj.id)} component="span">
                              <Edit />
                          </Button>
                      </Tooltip>
                  </ModifyPermission>}
                  {!this.props.disableDeleteIcon && <DeletePermission isOwn={get(obj, 'is_owner', false)} match={this.props.match} module={this.props.module}>
                      <Tooltip title={localize("basic", "Törlés")}>
                          <Button disabled={!get(obj, 'can_delete', true)} className={"jr-btn "+(get(obj, 'can_delete', true) ? "text-danger":"")+" jr-btn-xs"} aria-label={localize("basic", "Törlés")} onClick={this.handleDeleteConfirm.bind(this, obj.id)} component="span">
                              <DeleteIcon/>
                          </Button>
                      </Tooltip>
                  </DeletePermission>}
              </TableCell>}
          </TableRow>)}
          {this.state.rows.length === 0 && <TableRow>
              <TableCell colSpan="100">
                  <div><Search/> {localize("basic", "Nem található")}</div>
              </TableCell>
          </TableRow>}
      </TableBody>
    </Fragment>
  }
}

export default Body;
