import React, { Fragment } from "react";
import DataTableDx from "Components/New/DataTable/DataTableDx/DataTableDx";
import DataForm from "Components/New/DataForm/DataFormNew";
import DataProvider from "Components/New/DataProvider/DataProviderNew";
import OrderView from "./Cart/OrderView";
import { Grid, Typography, Toolbar, IconButton } from "@material-ui/core";
import { get, values, sumBy } from "lodash";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import { Link as RouterLink } from "react-router-dom";
import localize from "helpers/localize";
/**
 * Get total count of cart items
 *
 * @param {array} items cart item list
 */
const getTotal = items => {
  return sumBy(items, item => item.quantity);
};
const getTotalPrice = items => {
  return sumBy(
    items,
    item => item.quantity * (item.price ? parseFloat(item.price) : 0)
  );
};
const CustomView = ({
  fieldProps,
  onChange,
  editItem,
  onSave,
  changeStep,
  onEndEdit,
  options
}) => {
  //console.log(options);
  const order = { item: editItem };
  //prepare options
  order.options = { order: { baseFields: options.form.fields } };
  if (order.options.order.baseFields.shipping_fee) {
    order.options.order.baseFields.shipping_fee.default = editItem.shipping_fee;
  }
  const items = editItem.items
    ? editItem.items.map(item => {
        return {
        id: item.id,
          product: {
            id: item.shop_product_id,
            sku: localize("shop", "nincs"),
          name: item.shop_product_name
          },
          price: parseFloat(item.price),
          original_price: item.original_price
          ? parseFloat(item.original_price)
          : null,
          quantity: item.quantity
        };
      })
    : [];
  const total = getTotal(items);
  const totalPrice = getTotalPrice(items);
  return (
    <Fragment>
      <Grid item xs={12}>
        <Toolbar disableGutters>
          <IconButton onClick={onEndEdit} style={{ padding: 8 }}>
            <BackIcon
              fontSize="small"
              style={{ marginLeft: 4, marginRight: -4 }}
            />
          </IconButton>
          <Typography variant="h6">
            {localize("shop", "Megrendelés")} #{editItem.id}
          </Typography>
        </Toolbar>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={10}>
            <Grid container spacing={16}>
              <OrderView
                order={order}
                items={items}
                total={total}
                totalPrice={totalPrice}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

class Orders extends React.Component {
  render() {
    //const { client, readOnly, afterSave } = this.props;

    return (
      <div style={{ width: "100%", position: "relative" }}>
        <DataProvider
          url={"shop-orders"}
          //url={"cashregisters"}
          goBackAfterSave
          useUrlRouting
          //disableGetView
          tableComponent={DataTableDx}
          formComponent={DataForm}
          viewComponent={CustomView}
          customDeleteTitle={localize("shop", "Megerősítés")}
          customDeleteMessage={localize(
            "shop",
            "Biztosan visszamondja a rendelést?"
          )}
          tableProps={{
            title: localize("shop", "Rendeléseim"),
            filterable: false,
            canEdit: false,
            //canDelete: false,
            canDeleteFunc: row =>
              ["NEW", "OUT_OF_STOCK"].indexOf(row.status) >= 0 ? true : false,
            canCreate: false,
            columns: {
              number: {
                width: 170,
                render: (value, row) => {
                  return (
                    <RouterLink
                      style={{ cursor: "pointer" }}
                      to={`/shop/orders/view/${row.id}`}
                    >
                      {value}
                    </RouterLink>
                  );
                }
              },
              name: { width: 200 },
              created_at: { width: 160 }
            }
          }}
          formProps={{
            title: localize("shop", "Rendeléseim"),
            fields: {
              name: { validators: ["required"] }
            }
          }}
        />
      </div>
    );
  }
}

export default Orders;
