import React, { Fragment } from "react";
import DataTableDx from "Components/New/DataTable/DataTableDx/DataTableDx";
import DataForm from "Components/New/DataForm/DataFormNew";
import DataProvider from "Components/New/DataProvider/DataProviderNew";
import { Grid, Typography, Link } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import Select from "Components/New/Input/Select/Select";
import TextField from "Components/New/Input/TextField/TextField";
import OrderView from "./../Cart/OrderView";
import { ColorIndicator } from "Components/Forms/Fields/Select";
import Modal from "Components/Modal/Modal";
import MUIList from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link as RouterLink } from "react-router-dom";
import { Toolbar, Button } from "@material-ui/core";
import { get, sumBy } from "lodash";
import { setStatus } from "redux/modules/shopOrder";
import authRules from "helpers/authRules";
import { SHOP_ADMIN_ORDERS_PERMISSION } from "constans/permissions";
import localize from "helpers/localize";

const getTotal = items => {
  return sumBy(items, item => item.quantity);
};
const getTotalPrice = items => {
  return sumBy(
    items,
    item => item.quantity * (item.price ? parseFloat(item.price) : 0)
  );
};

class CustomView extends React.Component {
  /* state = {
    options: {}
  };

  componentDidMount() {
    restHelper.view("shop/options").then(response => {
      const options = get(response, "data", {});
      this.setState({ options });
    });
  }*/

  render() {
    //console.log("aa", this.props);
    const { onChange, editItem, options, refreshView } = this.props;
    //const { options } = this.state;

    const order = { item: editItem };
    //order.options = options;
    //prepare options
    order.options = { order: { baseFields: options.form.fields } };
    if (order.options.order.baseFields.shipping_fee) {
      order.options.order.baseFields.shipping_fee.default =
        editItem.shipping_fee;
    }
    const items = editItem.items
      ? editItem.items.map(item => {
        return {
          id: item.id,
          product: {
            id: item.shop_product_id,
            sku: localize("shop", "nincs"),
            name: item.shop_product_name
          },
          price: parseFloat(item.price),
          original_price: parseFloat(item.original_price),
          quantity: item.quantity
        };
      })
      : [];
    const total = getTotal(items);
    const totalPrice = getTotalPrice(items);

    return (
      <Fragment>
        <OrderView
          order={order}
          items={items}
          total={total}
          totalPrice={totalPrice}
          onChange={onChange}
          refreshView={refreshView}
          //isAdmin
          isAdminView
          //onlyProductsAndHistory
        />
        <Grid item xs={12}>
          <Toolbar disableGutters>
            <Button
              color="primary"
              //variant="contained"
              size="small"
              //onClick={() => changeStep(0)}
              to={`/shop/admin-orders/edit/${order.item.id}`}
              style={{ marginLeft: "auto" }}
              component={RLink}
            >
              {localize("basic", "Szerkesztés")}
            </Button>
          </Toolbar>
        </Grid>
      </Fragment>
    );
  }
}

class CustomForm extends React.Component {
  /* state = {
    options: {}
  };

  componentDidMount() {
    restHelper.view("shop/options").then(response => {
      const options = get(response, "data", {});
      this.setState({ options });
    });
  }*/

  render() {
    //console.log("aa", this.props);
    const { fieldProps, onChange, editItem, options, refreshView } = this.props;
    //const { options } = this.state;

    const order = { item: editItem };
    //order.options = options;
    //prepare options
    order.options = { order: { baseFields: options.form.fields } };
    if (order.options.order.baseFields.shipping_fee) {
      order.options.order.baseFields.shipping_fee.default =
        editItem.shipping_fee;
    }
    const items = editItem.items
      ? editItem.items.map(item => {
        return {
          id: item.id,
          product: {
            id: item.shop_product_id,
            sku: localize("shop", "nincs"),
            name: item.shop_product_name
          },
          price: parseFloat(item.price),
          original_price: item.original_price
              ? parseFloat(item.original_price)
              : null,
          quantity: item.quantity
        };
      })
      : [];
    const total = getTotal(items);
    const totalPrice = getTotalPrice(items);

    return (
      <Fragment>
        <Grid item xs={12}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {localize("shop", "Számlázási adatok")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField {...fieldProps("tax_number")} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField {...fieldProps("name")} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField {...fieldProps("phone")} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField {...fieldProps("email")} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField {...fieldProps("zip")} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField {...fieldProps("city")} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField {...fieldProps("street")} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField {...fieldProps("street_suffix")} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField {...fieldProps("street_details")} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {localize("shop", "Szállítási adatok")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Select
                    {...fieldProps("shipping_method", "dropdown")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            {editItem.shipping_method && editItem.shipping_method !== "NONE" && (
              <Fragment>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Select
                    {...fieldProps("shipping_fee", "dropdown")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField {...fieldProps("delivery_phone")} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField {...fieldProps("delivery_zip")} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField {...fieldProps("delivery_city")} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField {...fieldProps("delivery_street")} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    {...fieldProps("delivery_street_suffix")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    {...fieldProps("delivery_street_details")}
                    fullWidth
                  />
                </Grid>
              </Fragment>
            )}
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {localize("shop", "Fizetési adatok")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Select
                    {...fieldProps("payment_method", "dropdown")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <TextField {...fieldProps("comment")} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField {...fieldProps("admin_comment_public")} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField {...fieldProps("admin_comment_private")} fullWidth />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <OrderView
          order={order}
          items={items}
          total={total}
          totalPrice={totalPrice}
          onChange={onChange}
          refreshView={refreshView}
          isAdmin
          onlyProductsAndHistory
        />
      </Fragment>
    );
  }
}
/*
const CustomForm = ({ fieldProps, onChange, editItem, options }) => {
  console.log("o1", options);;
  return (
    <Fragment>
      <CustomView editItem={editItem} options={options} />
    </Fragment>
  );
};
*/

class CustomTable extends React.Component {
  state = {
    openStatusModal: false,
    selected: null
  };

  openStatusModal = (value, row) => () => {
    this.setState({
      openStatusModal: true,
      selectedStatus: row.status
        ? { id: row.status, label: row.status_text }
        : null,
      selected: row
    });
  };

  closeStatusModal = () => {
    this.setState({
      openStatusModal: false,
      selectedStatus: null,
      selected: null
    });
  };

  saveStatus = () => {
    const { selected, selectedStatus } = this.state;
    const { updateListItem } = this.props;
    setStatus(selected.id, selectedStatus ? selectedStatus.id : null).then(
      () => {
        const newItem = Object.assign(
          {},
          selected,
          selectedStatus
            ? { status: selectedStatus.id, status_text: selectedStatus.label }
            : null
        );
        updateListItem(newItem);
        this.closeStatusModal();
      }
    );
  };

  setStatus = status => {
    this.setState({ selectedStatus: status });
  };

  render() {
    const { openStatusModal, selectedStatus } = this.state;
    const statuses = get(
      this.props,
      "options.table.columns.status_text.filter.data",
      []
    );
    let options = Object.assign({}, this.props.options);
    //console.log(this.props);
    const newStatus = {
      render: (value, row) => {
        //console.log(value, row);
        if (!value) {
          return (
            <div
              style={{ cursor: "pointer", minHeight: 20 }}
              onClick={this.openStatusModal(row.status, row)}
            >
              <Link>-</Link>
            </div>
          );
        }
        return (
          <div
            style={{ cursor: "pointer", minHeight: 20 }}
            onClick={this.openStatusModal(row.status, row)}
          >
            <Link>{value}</Link>
          </div>
        );
      }
    };
    options.table.columns.status_text = Object.assign(
      {},
      options.table.columns.status_text,
      newStatus
    );

    return (
      <Fragment>
        <DataTableDx {...this.props} options={options} />
        <Modal
          title={localize("shop", "Rendelés státusz")}
          open={openStatusModal}
          disableIcon
          onClose={this.closeStatusModal}
          onCancel={this.closeStatusModal}
          onAccept={this.saveStatus}
        >
          <MUIList component="nav" aria-label="coloring">
            {/*<ListItem
              dense
              style={{
                backgroundColor: !selectedStatus ? "#ddd" : undefined
              }}
              button
              onClick={evt => this.setStatus(null)}
            >
              <ListItemIcon>
                <ColorIndicator color={undefined} size={20} />
              </ListItemIcon>
              <ListItemText primary="Nincs" />
            </ListItem>*/}
            {statuses.map((status, index) => {
              return (
                <ListItem
                  dense
                  style={{
                    backgroundColor:
                      selectedStatus && status.id === selectedStatus.id
                        ? "#ddd"
                        : undefined
                  }}
                  key={index}
                  button
                  onClick={() => this.setStatus(status)}
                >
                  <ListItemIcon>
                    <ColorIndicator
                      color={
                        status.id === "OUT_OF_STOCK"
                          ? "#ef9a9a"
                          : status.id === "DELIVERY"
                            ? "#e8f5e9"
                            : undefined
                      }
                      size={20}
                    />
                  </ListItemIcon>
                  <ListItemText primary={status.label} />
                </ListItem>
              );
            })}
          </MUIList>
        </Modal>
      </Fragment>
    );
  }
}

class AdminOrders extends React.Component {
  render() {
    //console.log(this.props);

    return (
      <div style={{ width: "100%", position: "relative" }}>
        <DataProvider
          url={"shop-admin-orders"}
          //url={"cashregisters"}
          goBackAfterSave
          useUrlRouting
          //disableGetView
          tableComponent={CustomTable}
          formComponent={DataForm}
          viewComponent={DataForm}
          tableProps={{
            title: localize("shop", "Rendelések"),
            //canView: false,
            canCreate: false,
            canDelete: authRules.canDeleteByKey(SHOP_ADMIN_ORDERS_PERMISSION),
            canEdit: authRules.canModifyByKey(SHOP_ADMIN_ORDERS_PERMISSION),
            rowStyle: row => {
              //console.log(row);
              if (row.status === "OUT_OF_STOCK") {
                return {
                  backgroundColor: "#ef9a9a"
                };
              }
              if (row.status === "DELIVERY") {
                return {
                  backgroundColor: "#e8f5e9"
                };
              }

              return {};
            },
            columns: {
              number: {
                width: 170,
                render: (value, row) => {
                  return (
                    <RouterLink
                      style={{ cursor: "pointer" }}
                      to={`/shop/admin-orders/view/${row.id}`}
                    >
                      {value}
                    </RouterLink>
                  );
                }
              },
              name: { width: 200 },
              /*status: { render: row => {
                console.log(row);
                return "a";
              } },*/
              created_at: { width: 160 }
            }
          }}
          viewProps={{
            title: item => `${item.number || `#${item.id}`} rendelés`,
            component: CustomView,
            autoRenderFields: false,
            disableButtons: true
          }}
          formProps={{
            title: item =>
              `${item.number || `#${item.id}`} ${localize(
                "shop",
                "rendelés szerkesztése"
              )}`,
            titleNew: localize("shop", "Új rendelés felvitele"),
            fields: {
              tax_number: {
                name: localize("shop", "Adószám"),
                mask: "tax_number",
                validators: ["required", "tax_number"]
              },
              name: {
                name: localize("shop", "Név"),
                validators: ["required"]
              },
              phone: {
                name: localize("shop", "Mobil"),
                mask: "mobile_phone",
                validators: ["required", "mobile_phone"]
              },
              email: {
                name: localize("shop", "Email"),
                validators: ["required", "email"]
              },
              zip: {
                name: localize("shop", "Irányítószám"),
                validators: ["required"]
              },
              city: {
                name: localize("shop", "Város"),
                validators: ["required"]
              },
              street: {
                name: localize("shop", "Közterület"),
                validators: ["required"]
              },
              street_suffix: {
                name: localize("shop", "Közterület jellege"),
                validators: ["required"]
              },
              street_details: {
                name: localize("shop", "Házszám"),
                validators: ["required"]
              },
              comment: {
                name: localize("shop", "Megjegyzés"),
                multiline: true,
                rows: 5
              },
              status: { name: localize("shop", "Státusz") },
              payment_method: { name: localize("shop", "Fizetési mód") },
              shipping_method: { name: localize("shop", "Szállítási mód") },
              shipping_fee: { name: localize("shop", "Szállítás díj") },
              delivery_phone: {
                name: localize("shop", "Mobil"),
                mask: "mobile_phone",
                validators: ["required", "mobile_phone"]
              },
              delivery_zip: {
                name: localize("shop", "Irányítószám"),
                validators: ["required"]
              },
              delivery_city: {
                name: localize("shop", "Város"),
                validators: ["required"]
              },
              delivery_street: {
                name: localize("shop", "Közterület"),
                validators: ["required"]
              },
              delivery_street_suffix: {
                name: localize("shop", "Közterület jellege"),
                validators: ["required"]
              },
              delivery_street_details: {
                name: localize("shop", "Házszám"),
                validators: ["required"]
              },
              admin_comment_public: {
                name: localize("shop", "Publikus megjegyzés"),
                multiline: true,
                rows: 5,
                sizes: {
                  xs: 12
                }
              },
              admin_comment_private: {
                name: localize("shop", "Privát megjegyzés"),
                multiline: true,
                rows: 5,
                sizes: {
                  xs: 12
                }
              }
            },
            fieldsOrder: ["name"],
            component: CustomForm,
            autoRenderFields: false
          }}
        />
      </div>
    );
  }
}

export default AdminOrders;
