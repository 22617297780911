import React, { Fragment } from "react";
import AnnualReviewForm from "./AnnualReviewForm";
import Edit from "../../../../../Components/Edit/Edit";

class EditAnnualReview extends React.Component {
  constructor(props) {
    super(props);
    this._form = React.createRef();
  }

  submit = () => {
    const instance = this._form.current.getWrappedInstance();
    if (instance) {
      instance.submit();
    }
  };

  render() {
    return (
      <Fragment>
        <Edit isViewPage={false} {...this.props} cardBox={false}>
          <AnnualReviewForm
            {...this.props}
            isViewPage={false}
            ref={this._form}
          />
        </Edit>
      </Fragment>
    );
  }
}

export default EditAnnualReview;
