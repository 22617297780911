import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  PagePermission,
  Page404Page,
  WorksheetsListPage,
  EditWorksheetPage,
  WorkfeesListPage,
  EditWorkfeePage
} from "Containers";
import AnykList from "Containers/Pages/Worksheets/Anyk/AnykList";
import {
  WORKSHEETS_PERMISSION,
  WORKFEES_PERMISSION
} from "constans/permissions";
import Navbar from "./Navbar";
import WorksheetStatistic from "./Worksheets/WorksheetStatistic";

const WorksheetRoutes = pr => {
  return (
    <div>
      <Navbar location={pr.location} history={pr.history} />
      <Switch /*location={props.location}*/>
        <Route
          exact
          path="/worksheets"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <WorksheetsListPage {...props} />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/new"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <WorksheetsListPage {...props} mode="new" />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/in-progress"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <WorksheetsListPage {...props} mode="in_progress" />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/done"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <WorksheetsListPage {...props} mode="done" />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/waiting"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <WorksheetsListPage {...props} mode="waiting" />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/closed"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <WorksheetsListPage {...props} mode="closed" />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/bank_transfer"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <WorksheetsListPage {...props} mode="bank_transfer" />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/anyk-exports"
          render={props => (
            <PagePermission {...props} module={WORKFEES_PERMISSION}>
              <AnykList {...props} />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/anyk"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <WorksheetsListPage {...props} mode="anyk" />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/deleted"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <WorksheetsListPage {...props} mode="deleted" />
            </PagePermission>
          )}
        />

        <Route
          exact
          path="/worksheets/workfees"
          render={props => (
            <PagePermission {...props} module={WORKFEES_PERMISSION}>
              <WorkfeesListPage {...props} components="workfees" />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/workfees/create"
          render={props => (
            <PagePermission {...props} module={WORKFEES_PERMISSION}>
              <EditWorkfeePage {...props} components="workfees" />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/workfees/edit/:id"
          render={props => (
            <PagePermission {...props} module={WORKFEES_PERMISSION}>
              <EditWorkfeePage {...props} components="workfees" />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/workfees/view/:id"
          render={props => (
            <PagePermission {...props} module={WORKFEES_PERMISSION}>
              <EditWorkfeePage {...props} components="workfees" />
            </PagePermission>
          )}
        />

        <Route
          exact
          path="/worksheets/create"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <EditWorksheetPage {...props} key="new" />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/(view|edit)/:id"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <EditWorksheetPage {...props} />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/*/create"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <EditWorksheetPage {...props} key="new" />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/*/(view|edit)/:id"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <EditWorksheetPage {...props} />
            </PagePermission>
          )}
        />

        <Route
          exact
          path="/worksheets/statistic"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <WorksheetStatistic {...props} />
            </PagePermission>
          )}
        />
        <Route
          exact
          path="/worksheets/statistic/:date"
          render={props => (
            <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
              <WorksheetStatistic {...props} />
            </PagePermission>
          )}
        />
        <Route exact path="**" component={Page404Page} />
      </Switch>
    </div>
  );
};

export default WorksheetRoutes;
