import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";
import SelectField from "../../../../../Components/Forms/Fields/Select";
import Autocomplete from "../../../../../Components/Forms/Fields/Autocomplete";
import Validations from "../../../../../Components/Forms/Validations";
import EditForm from "../../../../../Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import localize from "helpers/localize";

class RightForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  componentWillMount() {
    this.props.initialize();
  }

  handleDistributorSelect = (value, key, selected, name) => {
    const { dispatch, change } = this.props;
    //const item = selected && selected.length > 0 ? selected[0] : null;
    if (selected) {
      dispatch(change(name, selected));
      dispatch(change(key, value));
    }
  };

  render() {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      enabledFields
    } = this.props;

    let types = [];
    Object.keys(get(this.props, "baseFields.type.data", {})).forEach(key => {
      types.push({ id: key, label: this.props.baseFields.type.data[key] });
    });
    let statuses = [];
    Object.keys(get(this.props, "baseFields.status.data", {})).forEach(key => {
      statuses.push({ id: key, label: this.props.baseFields.status.data[key] });
    });

    return (
      <EditForm {...this.props} disabledEditButton={true}>
        <div className="col-12">
          <Field
            name="distributor_id"
            label={localize("cashregisters", "Viszonteladó")}
            error={fieldErrors.distributor_id}
            validate={[Validations.required]}
            formName="rights"
            onChange={() => handleInputChange("distributor_id")}
            component={Autocomplete}
            mode="select"
            isViewPage={isViewPage}
            minChars={get(this.props, "baseFields.distributor_id.minChars", 2)}
            url={get(this.props, "baseFields.distributor_id.url", null)}
            change={this.props.change}
            labelKey={"text"}
            disabled={
              enabledFields
                ? enabledFields.indexOf("distributor_id") !== -1
                  ? false
                  : true
                : false
            }
            onSelect={this.handleDistributorSelect}
          />
        </div>
        <div className="col-12">
          <Field
            name="type"
            label={localize("cashregisters", "Típus")}
            fieldError={fieldErrors.type}
            onChange={() => handleInputChange("type")}
            isRequired={true}
            validate={[Validations.required]}
            component={SelectField}
            placeholder={localize("cashregisters", "Típus")}
            isViewPage={isViewPage}
            options={types}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("type") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
        <div className="col-12">
          <Field
            name="status"
            label={localize("cashregisters", "Státusz")}
            fieldError={fieldErrors.status}
            onChange={() => handleInputChange("status")}
            isRequired={true}
            validate={[Validations.required]}
            component={SelectField}
            placeholder={localize("cashregisters", "Státusz")}
            isViewPage={isViewPage}
            options={statuses}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("status") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
      </EditForm>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "rights",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(RightForm);

export default connect(state => ({
  initialValues: state.cashregisterRights.viewData
}))(UsersReduxForm);
