import axios from "axios";
import _, { get } from "lodash";
import moment from "moment";
import qs from "qs";
import * as fileActions from "./file";

const ANNUALREVIEWS_LIST_LOAD = "ANNUALREVIEWS_LIST_LOAD";
const ANNUALREVIEWS_LIST_SUCCESS = "ANNUALREVIEWS_LIST_SUCCESS";
const ANNUALREVIEWS_LIST_FAILED = "ANNUALREVIEWS_LIST_FAILED";
const ANNUALREVIEWS_SET_LOADING = "ANNUALREVIEWS_SET_LOADING";

const ANNUALREVIEWS_FORM_LOAD = "ANNUALREVIEWS_FORM_LOAD";
const ANNUALREVIEWS_FORM_SUCCESS = "ANNUALREVIEWS_FORM_SUCCESS";
const ANNUALREVIEWS_FORM_FAILED = "ANNUALREVIEWS_FORM_FAILED";

const ANNUALREVIEW_DELETE_LOAD = "ANNUALREVIEW_DELETE_LOAD";
const ANNUALREVIEW_DELETE_SUCCESS = "ANNUALREVIEW_DELETE_SUCCESS";
const ANNUALREVIEW_DELETE_FAILED = "ANNUALREVIEW_DELETE_FAILED";

const ANNUALREVIEW_EDIT_LOAD = "ANNUALREVIEW_EDIT_LOAD";
const ANNUALREVIEW_EDIT_SUCCESS = "ANNUALREVIEW_EDIT_SUCCESS";
const ANNUALREVIEW_EDIT_FAILED = "ANNUALREVIEW_EDIT_FAILED";

const ANNUALREVIEW_VIEW_LOAD = "ANNUALREVIEW_VIEW_LOAD";
const ANNUALREVIEW_VIEW_SUCCESS = "ANNUALREVIEW_VIEW_SUCCESS";
const ANNUALREVIEW_VIEW_FAILED = "ANNUALREVIEW_VIEW_FAILED";

const ANNUALREVIEWS_RESET = "ANNUALREVIEWS_RESET";
const ANNUALREVIEWS_RESET_FORM = "ANNUALREVIEWS_RESET_FORM";
const SET_VIEW_DATA = "SET_VIEW_DATA";

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit: process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: "",
  orderMode: "ASC",
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ANNUALREVIEWS_RESET:
      return {
        ...initialState
      };
    case ANNUALREVIEWS_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        saved: false,
        id: null
      };
    case ANNUALREVIEW_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case ANNUALREVIEWS_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false
      };
    case ANNUALREVIEW_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case ANNUALREVIEW_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case ANNUALREVIEWS_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case ANNUALREVIEWS_FORM_FAILED:
    case ANNUALREVIEW_EDIT_FAILED:
    case ANNUALREVIEW_DELETE_FAILED:
    case ANNUALREVIEWS_LIST_FAILED:
    case ANNUALREVIEW_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false
      };
    case ANNUALREVIEWS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode
      };
    case SET_VIEW_DATA:
    case ANNUALREVIEW_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        viewData: action.viewData,
        viewLoading: false
      };
    case ANNUALREVIEWS_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case ANNUALREVIEW_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case ANNUALREVIEW_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        viewData: get(action, "data.data.data", null),
        id: action.id,
        viewLoading: false
      };
    case ANNUALREVIEWS_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
};

export const setViewData = (payload, cashregister_stickern) => dispatch => {
  const viewData = {
    ...payload,
    cashregister_stickern,
    user_name: payload.user_real_name,
    user_number: payload.user_card_number,
    owner_name: payload.client_name
  };

  return dispatch({
    type: SET_VIEW_DATA,
    viewData
  });
};

export const reset = () => dispatch => {
  dispatch({
    type: ANNUALREVIEWS_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: ANNUALREVIEWS_RESET_FORM
  });
};

export const updateAnnualReviews = (model, id, data) => dispatch => {
  dispatch({
    type: ANNUALREVIEW_EDIT_LOAD
  });

  if (data.operating_address_type !== "other") {
    data.form_values.operating_address = data.operating_address_type;
  }

  let obj = {};
  obj[model] = _.pick(data, [
    "cashregister_apn",
    "cashregister_stickern",
    "user_name",
    "user_number",
    "owner_name",
    "form_values"
  ]);

  return axios
    .put("/v1/annual-reviews/" + id, obj)
    .then(data => data =>
      dispatch({
        type: ANNUALREVIEW_EDIT_SUCCESS,
        data: data
      })
    )
    .catch(error => {
      dispatch({
        type: ANNUALREVIEW_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
    });
};

export const checkKey = (number, apn) => dispatch => {
  dispatch({
    type: ANNUALREVIEW_VIEW_LOAD
  });

  return axios
    .get("/v1/annual-reviews/check-sticker", { params: { number, apn } })
    .then(response => {
      if (response.data.success) {
        dispatch({
          type: ANNUALREVIEW_VIEW_SUCCESS,
          viewData: null
        });
        return true;
      } else {
        dispatch({
          type: ANNUALREVIEW_VIEW_FAILED,
          formErrors: { param: ["Hibás Matrica szám"] }
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ANNUALREVIEW_VIEW_FAILED,
        message: get(error, "response.data.message", null)
      });
    });
};
export const checkTokenKey = (number, apn, keygen) => dispatch => {
  dispatch({
    type: ANNUALREVIEW_VIEW_LOAD
  });

  return (
    axios
      //.get("/v1/cashregisters/reregister-check-token", {
      .get("/v1/cashregisters/check-sticker", {
        params: { number, apn, keygen }
      })
      .then(response => {
        if (response.data.success) {
          dispatch({
            type: ANNUALREVIEW_VIEW_SUCCESS,
            viewData: null
          });
          return true;
        } else {
          dispatch({
            type: ANNUALREVIEW_VIEW_FAILED,
            formErrors: { param: ["Hibás Token"] }
          });
        }
      })
      .catch(error => {
        dispatch({
          type: ANNUALREVIEW_VIEW_FAILED,
          message: get(error, "response.data.message", null)
        });
      })
  );
};

const getViewData = response => {
  //TODO: Jó lenne, ha szerveren nem stringként jönne;
  let data = get(response, "data.data");
  if (data && data.form_values && typeof data.form_values === "string") {
    data.form_values = JSON.parse(data.form_values);
  }
  return data;
};

export const viewAnnualReviews = id => dispatch => {
  dispatch({
    type: ANNUALREVIEW_VIEW_LOAD
  });
  return axios
    .get("/v1/annual-reviews/" + id)
    .then(response =>
      dispatch({
        type: ANNUALREVIEW_VIEW_SUCCESS,
        viewData: getViewData(response),
        commentThread: get(response, "data.commentThread", false)
      })
    )
    .catch(error => {
      dispatch({
        type: ANNUALREVIEW_VIEW_FAILED,
        message: get(error, "response.data.message", null)
      });
    });
};

export const createAnnualReviews = (model, data) => dispatch => {
  console.log(model, data);
  dispatch({
    type: ANNUALREVIEW_EDIT_LOAD
  });

  if (data.operating_address_type !== "other") {
    data.form_values.operating_address = data.operating_address_type;
  }

  let obj = {};
  obj[model] = _.pick(data, [
    "cashregister_apn",
    "cashregister_stickern",
    "user_name",
    "user_number",
    "owner_name",
    "form_values"
  ]);

  return axios
    .post("/v1/annual-reviews", obj)
    .then(response =>
      dispatch({
        type: ANNUALREVIEW_EDIT_SUCCESS,
        id: get(response, "data.data.id", null),
        data: response
      })
    )
    .catch(error => {
      dispatch({
        type: ANNUALREVIEW_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
    });
};

export const annualReviewDelete = id => dispatch => {
  dispatch({
    type: ANNUALREVIEW_DELETE_LOAD
  });
  return axios
    .delete("/v1/annual-reviews/" + id)
    .then(response =>
      dispatch({
        type: ANNUALREVIEW_DELETE_SUCCESS,
        id
      })
    )
    .catch(error =>
      dispatch({
        type: ANNUALREVIEW_DELETE_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: ANNUALREVIEWS_SET_LOADING,
    loading
  });
};

export const annualReviewsList = (
  page,
  keyword,
  sort,
  filters,
  perPage
) => dispatch => {
  dispatch({
    type: ANNUALREVIEWS_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    "per-page": perPage
  };

  params[filters.model] = filters.filters;

  return axios
    .get(
      "/v1/annual-reviews",
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then(response => {
      let sort = get(response, "data.sort", null),
        mode = "ASC";
      if (sort && sort.indexOf("-") !== -1) {
        mode = "DESC";
        sort = sort.substr(1);
      }
      return dispatch({
        type: ANNUALREVIEWS_LIST_SUCCESS,
        data: get(response, "data.data", null),
        page: get(response, "data.page", 0),
        limit: get(response, "data.per-page", 0),
        total: get(response, "data.total", 0),
        order: sort,
        orderMode: mode
      });
    })
    .catch(error =>
      dispatch({
        type: ANNUALREVIEWS_LIST_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};

export const downloadPDF = id => dispatch => {
  return dispatch(
    fileActions.download(
      `/v1/annual-reviews/pdf/${id}`,
      `annual_review_${moment().format("YYYY-MM-DD")}.pdf`
    )
  );
};
export const annualReviewPublic = (sticker, apn) => {
  return axios
    .get(`/v1/annual-reviews/public?stickerNo=${sticker}&apn=${apn}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error.response;
    });
};
