import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CashregisterRead from "./views/CashregisterRead";
import * as cashregistersActions from "../../../../redux/modules/cashregisters";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.cashregisters.read_loading,
    success: state.cashregisters.read_success,
    message: state.cashregisters.read_message,
    viewData: state.cashregisters.viewData,
    reducerName: "cashregisters",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      read: cashregistersActions.startCashregisterRead,
      deleteSubscriberReadId: cashregistersActions.deleteSubscriberReadId,
      endRead: cashregistersActions.endCashregisterRead,
      getCustomInfo: optionsActions.getCustomInfo
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CashregisterRead);
