import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";

import EditForm from "../Forms/Builder/EditForm";
import { connect } from "react-redux";
import CheckboxField from "../Forms/Fields/Checkbox";
import Grid from "@material-ui/core/Grid";

class SettingsForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this._form = React.createRef();
  }

  submit = () => {
    const instance = this._form.current.getWrappedInstance();
    if (instance) {
      instance.submit();
    }
  };

  componentWillMount = () => {
    const { dispatch, change, columns } = this.props;
    Object.keys(columns)
      .filter(row => columns[row].hideable && !columns[row].hidden)
      .forEach(row => dispatch(change(row, true)));
  };

  render() {
    const { handleInputChange } = this.props;
    return (
      <EditForm {...this.props} ref={this._form}>
        {this.props.columns &&
          Object.keys(this.props.columns)
            .filter(row => this.props.columns[row].hideable)
            .map((row, index) => (
              <Grid key={index} item={true} xs={12} sm={6} md={4}>
                <Field
                  name={row}
                  label={this.props.columns[row].name}
                  onChange={() => handleInputChange(row)}
                  component={CheckboxField}
                  isViewPage={false}
                />
              </Grid>
            ))}
      </EditForm>
    );
  }
}

const SettingsReduxForm = reduxForm({
  form: "gridSettings",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true
})(SettingsForm);

export default connect(
  state => ({
    //initialValues: Object.keys(state.options.columns).filter(row => state.options.columns[row].hideable && !state.options.columns[row].hidden ),
    columns: state.options.columns
  }),
  null,
  null,
  { withRef: true }
)(SettingsReduxForm);
