import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import List from "Components/List/List";
import ContainerHeader from "Components/ContainerHeader";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Highlight from "@material-ui/icons/Highlight";
import Button from "@material-ui/core/Button/Button";
import localize from "helpers/localize";

class ArticlesList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      keyword: null,
      search: null,
      filters: null,
      perPage: null
    };
  }

  componentWillReceiveProps = nextProps => {
    if (!this.props.reload && nextProps.reload) {
      const { page, keyword, sort, filters, perPage } = this.state;
      this.getList(page, keyword, sort, filters, perPage);
    }
  };

  getList = (page, keyword, sort, filters, perPage) => {
    this.setState({
      page,
      keyword,
      sort,
      filters,
      perPage
    });
    return this.props.getList(page, keyword, sort, filters, perPage);
  };

  customIcons = obj => {
    return (
      <Fragment>
        {!obj.highlighted && (
          <Tooltip title={localize("articles", "Kiemelés")}>
            <Button
              className="jr-btn jr-btn-xs"
              onClick={() => this.props.articlesHightLight(obj.id)}
              component="span"
            >
              <Highlight />
            </Button>
          </Tooltip>
        )}
      </Fragment>
    );
  };

  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("articles", "Hírek")}</title>
        </Helmet>
        <ContainerHeader title={localize("articles", "Hírek")} />
        <List
          {...this.props}
          customIcons={this.customIcons.bind(this)}
          getList={this.getList.bind(this)}
        />
      </div>
    );
  }
}

ArticlesList.propTypes = {
  getList: PropTypes.func.isRequired,
  articlesHightLight: PropTypes.func.isRequired,
  reload: PropTypes.bool
};

export default ArticlesList;
