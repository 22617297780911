import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ContainerHeader from "Components/ContainerHeader/index";

import CircularProgress from "@material-ui/core/CircularProgress";
import { get } from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../styles/slider/slick-theme.css";
import { NavLink } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import CancelIcon from "@material-ui/icons/Cancel";
import Modal from "Components/Modal/Modal";
import SliderEditor from "./SliderEditor";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import KeyIcon from "@material-ui/icons/VpnKey";
import PersonIcon from "@material-ui/icons/Person";
import CodeIcon from "@material-ui/icons/Lock";
import NewsIcon from "@material-ui/icons/TextFormat";
import LoginIcon from "@material-ui/icons/ExitToApp";
import FuncPermission from "Containers/Permissions/FuncPermission";
import Moment from "moment";
import localize from "helpers/localize";

const eventTypes = {
  all: "Minden adat",
  aee: "AEE kódgenerálás",
  read: "Ügyfélbetöltés",
  key: "Egyéb kódgenerálások",
  news: "Új hír rögzítések",
  login: "Bejelentkezések"
};

class Home extends PureComponent {
  state = {
    value: "all",
    newsOpen: false,
    selectedNews: {},
    sliderEdit: false
  };

  handleChange = value => {
    this.props.eventList(value);
    this.setState({ value });
  };

  componentDidMount = () => {
    this.props.getList().then(() => {
      this.props.eventList(this.state.value);
    });
  };

  componentWillUnmount = () => {
    this.props.reset();
  };

  handleCloseNews = () => {
    this.setState({ newsOpen: false });
  };

  toggleEditSlider = () => {
    this.setState({ sliderEdit: !this.state.sliderEdit });
  };

  handleSliderChange = items => {
    this.props.update(items);
  };

  handleSlideUpload = slide => {
    let newSlides = this.props.slider.items.slice();
    newSlides.push(slide);
    this.props.update(newSlides);
  };

  handleSlideRemove = (slide, index) => {
    let newSlides = this.props.slider.items.slice();
    newSlides.splice(index, 1);
    this.props.update(newSlides);
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      centerMode: true,
      centerPadding: "240px",
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            centerMode: true,
            centerPadding: "80px"
          }
        },
        {
          breakpoint: 1024,
          settings: {
            centerMode: true,
            centerPadding: "40px"
          }
        },
        {
          breakpoint: 480,
          settings: {
            centerMode: false
          }
        }
      ]
    };
    const { value, newsOpen, selectedNews, sliderEdit } = this.state;
    const {
      slider,
      fileDelete,
      fileUpload,
      fileDownload,
      dashboard,
      events,
      events_loading
    } = this.props;

    const newsNotHighlited =
      get(this.props, "dashboard.data.news", false) &&
      get(this.props, "dashboard.data.news", []).filter(
        news => !news.highlighted
      );

    const EventButton = props => {
      const { id, Icon } = props;
      return (
        <Tooltip title={localize("dashboard", eventTypes[id])}>
          <IconButton
            onClick={this.handleChange.bind(this, id, value)}
            color={value === id ? "primary" : "default"}
          >
            <Icon />
          </IconButton>
        </Tooltip>
      );
    };

    return (
      <div
        className="dashboard animated slideInUpTiny animation-duration-3"
        style={{ position: "relative" }}
      >
        <Modal
          title={selectedNews.title || ""}
          open={newsOpen}
          disableIcon
          disableOk
          disableCancel
          onClose={this.handleCloseNews}
        >
          <div dangerouslySetInnerHTML={{ __html: selectedNews.text }} />
        </Modal>
        <Helmet>
          <title>{localize("dashboard", "Főoldal")}</title>
        </Helmet>
        <ContainerHeader
          title={
            <span>
              {localize("dashboard", "Főoldal")}{" "}
              <small>{Moment().format("YYYY. MMMM DD.")}</small>
            </span>
          }
        />
        <FuncPermission module="dashboard" permission="edit-slider">
          <Tooltip
            title={
              sliderEdit
                ? localize("dashboard", "Szerkesztés befejezése")
                : localize("dashboard", "Diavetítés szerkesztése")
            }
          >
            <IconButton
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                width: 32,
                height: 32,
                padding: 0
              }}
              color="primary"
              onClick={this.toggleEditSlider}
            >
              {sliderEdit ? (
                <CancelIcon style={{ width: 20, height: 20 }} />
              ) : slider.items.length > 0 ? (
                <EditIcon style={{ width: 20, height: 20 }} />
              ) : (
                <SlideshowIcon style={{ width: 20, height: 20 }} />
              )}
            </IconButton>
          </Tooltip>
        </FuncPermission>
        <Grid container spacing={24}>
          <Grid item xs={12} md={12} style={{ position: "relative" }}>
            {sliderEdit ? (
              <SliderEditor
                slides={slider.items}
                onChange={this.handleSliderChange}
                /*cardProps={{
                  style: {
                    marginBottom: 24
                  }
                }}*/
                fileDelete={fileDelete}
                upload={fileUpload}
                download={fileDownload}
                onUpload={this.handleSlideUpload}
                onRemove={this.handleSlideRemove}
              />
            ) : (
              <Paper>
                <Slider {...settings}>
                  {slider.items.map((item, index) => {
                    return (
                      <div key={index}>
                        <img
                          className="card-img img-fluid"
                          src={
                            process.env.REACT_APP_HOST +
                            item.download_url +
                            "&accessToken=" +
                            localStorage
                              .getItem("access_token")
                              .replace("Bearer ", "")
                          }
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Slider>
              </Paper>
            )}
          </Grid>
          {!dashboard.loading && (
            <Fragment>
              <Grid item xs={12} md={6}>
                {get(this.props, "dashboard.data.news", false) &&
                  get(this.props, "dashboard.data.news", [])
                    .filter(news => news.highlighted)
                    .map((news, index) => (
                      <Card key={index} style={{ height: "100%" }}>
                        <CardHeader
                          title={news.title}
                          subheader={news.created_at}
                        />
                        <CardContent style={{ paddingTop: 0 }}>
                          <p dangerouslySetInnerHTML={{ __html: news.text }} />
                        </CardContent>
                      </Card>
                    ))}
              </Grid>
              <Grid item xs={12} md={6}>
                <Card style={{ height: "100%" }}>
                  <CardHeader
                    title={localize("dashboard", "Hírek")}
                    //subheader={news.created_at}
                  />
                  <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                      {newsNotHighlited &&
                        newsNotHighlited.map((news, index) => (
                          <Fragment key={index}>
                            <ListItem
                              ContainerProps={{
                                style: { listStyleType: "none" }
                              }}
                            >
                              <ListItemText
                                primary={news.title}
                                //secondary={news.created_at}
                              />
                              <ListItemSecondaryAction>
                                {news.created_at}
                                <Button
                                  color="primary"
                                  size="small"
                                  onClick={() =>
                                    this.setState({
                                      selectedNews: news,
                                      newsOpen: true
                                    })
                                  }
                                >
                                  {localize("dashboard", "Tovább")}
                                </Button>
                              </ListItemSecondaryAction>
                            </ListItem>
                            {newsNotHighlited &&
                              newsNotHighlited.length > index + 1 && (
                                <Divider />
                              )}
                          </Fragment>
                        ))}
                    </List>
                  </CardContent>
                  <CardActions>
                    <Button color="primary" size="small">
                      <NavLink
                        to="/articles"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {localize("dashboard", "Összes hír")}
                      </NavLink>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Fragment>
          )}

          {!dashboard.loading && (
            <Fragment>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    action={
                      <Fragment>
                        {events_loading && (
                          <CircularProgress
                            size={48}
                            style={{
                              padding: 16,
                              margin: -16,
                              top: 4,
                              position: "relative",
                              right: 6
                            }}
                          />
                        )}
                        <EventButton id="all" Icon={HomeIcon} />
                        <EventButton id="aee" Icon={KeyIcon} />
                        <EventButton id="read" Icon={PersonIcon} />
                        <EventButton id="key" Icon={CodeIcon} />
                        <EventButton id="news" Icon={NewsIcon} />
                        <EventButton id="login" Icon={LoginIcon} />
                      </Fragment>
                    }
                    title={localize("dashboard", "Események")}
                    subheader={localize("dashboard", eventTypes[value])}
                  />
                  <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <div className="table-responsive-material">
                      {events.data.length === 0 ? (
                        <Typography
                          variant="body2"
                          style={{
                            textAlign: "center"
                          }}
                        >
                          {localize("dashboard", "Betöltés...")}
                        </Typography>
                      ) : (
                        <Table className="" style={{ minWidth: 600 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell width="25%">
                                {localize("dashboard", "Esemény")}
                              </TableCell>
                              <TableCell width="25%">
                                {localize("dashboard", "Idő")}
                              </TableCell>
                              <TableCell width="25%">
                                {localize("dashboard", "Felhasználó")}
                              </TableCell>
                              <TableCell width="25%">
                                {localize("dashboard", "Tárgy")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {events.data.map((event, index) => {
                              return (
                                <TableRow key={this.state.value + index}>
                                  <TableCell width="25%">{event.msg}</TableCell>
                                  <TableCell width="25%">
                                    {event.created_at}
                                  </TableCell>
                                  <TableCell width="25%">
                                    {event.created_by_company_name ? (
                                      <Tooltip
                                        title={event.created_by_company_name}
                                      >
                                        <span>
                                          {event.created_by_real_name}
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      event.created_by_real_name
                                    )}
                                  </TableCell>
                                  <TableCell width="25%">
                                    {event.subject}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      )}
                    </div>
                  </CardContent>
                  <CardActions>
                    <Button color="primary" size="small">
                      <NavLink
                        to="/events"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {localize("dashboard", "Összes esemény")}
                      </NavLink>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Fragment>
          )}
        </Grid>
        {dashboard.loading && (
          <div className="text-center mt-2">
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

Home.propTypes = {
  loading: PropTypes.bool,
  slider: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
  events_loading: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  index: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  fileDelete: PropTypes.func.isRequired,
  fileUpload: PropTypes.func.isRequired,
  fileDownload: PropTypes.func.isRequired,
  eventList: PropTypes.func.isRequired
};

export default Home;
