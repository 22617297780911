import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditWorksheet from "./views/EditWorksheet";
import * as worksheetsActions from "redux/modules/worksheets";
import * as signingActions from "redux/modules/signing";
import { upload } from "redux/modules/file";

const mapStateToProps = state => {
  return {
    loading: state.worksheets.loading,
    success: state.worksheets.success,
    saved: state.worksheets.saved,
    message: state.worksheets.message,
    fieldErrors: state.worksheets.formErrors,
    id: state.worksheets.id,
    viewData: state.worksheets.viewData,
    reducerName: "worksheets",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields,
    connected: state.signing.connected,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: worksheetsActions.createWorksheets,
      update: worksheetsActions.updateWorksheets,
      view: worksheetsActions.viewWorksheets,
      reset: worksheetsActions.reset,
      changeWorksheets: worksheetsActions.changeWorksheets,
      resetForm: worksheetsActions.resetForm,
      sendView: signingActions.sendView,
      getSigned: signingActions.getSigned,
      sendSign: signingActions.sendSign,
      unSubscribeAdminSigning: signingActions.unSubscribeAdminSigning,
      uploadFile: upload
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditWorksheet);
