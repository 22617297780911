import React, { Fragment } from "react";
import PropTypes from "prop-types";
import localize from "helpers/localize";
import restHelper from "helpers/aee/restHelper";
import DataForm from "Components/New/DataForm/DataFormNew";
import withLock from "./withLock";
import SetRentDataFrom from "./SetRentDataFrom";
import { FormLabel, Grid, Typography } from "@material-ui/core";
import authRules from "helpers/authRules";
import { CLOUD_CASHREGISTER_YEARLYGENERAL } from "constans/permissions";
const LC = "cloud";
const warns = { 0: localize(LC, "15 nap"), 1: localize(LC, "Nincs") };
class YearlyGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: { flag: 4 },
      errors: {},
      loading: false
    };
  }

  componentDidMount() {
    /*const { setToolbarItems } = this.props;
    setToolbarItems(<div>LOL</div>);*/
    this.view();
  }

  view = () => {
    const { cashregister_id: ap, client_id: client } = this.props;
    this.setState({ loading: true });
    restHelper
      .view("cashregister-yearly-general/view", null, { ap, client })
      .then(response => {
        const data = Array.isArray(response.data) ? {} : response.data;
        this.setState({ item: data || {}, loading: false });
      });
  };

  onSave = data => {
    const { cashregister_id: ap, client_id: client, onUpdated } = this.props;
    this.setState({ loading: true });
    restHelper
      .update("cashregister-yearly-general/update", null, null, data, {
        ap,
        client
      })
      .then((/*response*/) => {
        onUpdated(() => {
          this.setState({ loading: false });
        });
      });
  };

  onChange = (key, value) => {
    const item = Object.assign({}, this.state.item, { [key]: value });
    this.setState({ item });
  };

  onItemChange = values => {
    const item = Object.assign({}, this.state.item, values);
    this.setState({ item });
  };

  onChangeErrors = errors => {
    this.setState({ errors });
  };

  toggleEdit = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  render() {
    const { editMode } = this.props;
    const { item, errors, loading } = this.state;
    return (
      <Fragment>
        <Grid container spacing={16} style={{ marginBottom: 8 }}>
          <Grid item xs={12} s={6} md={4} lg={3} style={{ display: "flex" }}>
            <Typography variant="caption" color="textSecondary">
              {localize(LC, "Következő felülvizsgálat")}:
            </Typography>
            <div style={{ width: 8 }} />
            <Typography>{item.next_date || "-"}</Typography>
          </Grid>
          <Grid item xs={12} s={6} md={4} lg={3} style={{ display: "flex" }}>
            <Typography variant="caption" color="textSecondary">
              {localize(LC, "Figyelmeztetés")}:
            </Typography>
            <div style={{ width: 8 }} />
            <Typography>{warns[item.warn_date] || "-"}</Typography>
          </Grid>
        </Grid>
        <DataForm
          editItem={item}
          disabled={!editMode}
          disableHeader
          disableCancelButton
          errors={errors}
          onChangeErrors={this.onChangeErrors}
          onChange={this.onChange}
          onItemChange={this.onItemChange}
          onValidated={this.onSave}
          loading={loading}
          options={{
            form: {
              //model: "ShopOrder",
              fieldsOrder: [],
              fields: {
                flag: {
                  name: localize(LC, "Állapot"),
                  type: "dropdown",
                  data: {
                    0: localize(LC, "Felülvizsgálati jelentés"),
                    1: localize(LC, "Szervizes jelentés"),
                    2: localize(LC, "Szervizes történet törlése")
                  }
                },
                service_end: {
                  name: localize(LC, "Felülvizsgálati dátum"),
                  type: "date"
                },
                service_done: {
                  name: localize(LC, "Felülvizsgálat elvégzése"),
                  type: "checkbox",
                  sizes: { xs: 12 }
                },
                warn_date: {
                  name: localize(LC, "Figyelmeztetés"),
                  type: "dropdown",
                  data: warns,
                  visible: row => (row.service_done ? true : false)
                },
                man_code: {
                  name: localize(LC, "Műszerész ig. száma"),
                  visible: row => (row.service_done ? true : false)
                },
                password: {
                  name: localize(LC, "Jelszó"),
                  visible: row => (row.service_done ? true : false)
                }
                /*no: {
                name: "No"
              },
              date: {
                name: "Date"
              },*/
              }
            }
          }}
          //fieldsOrder: ["name"],
          //component={CheckOutFrom}
          autoRenderFields={true}
          history={this.props.history}
        //componentProps={{ changeStep: this.props.changeStep }}
        />
      </Fragment>
    );
  }
}

YearlyGeneral.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  editMode: PropTypes.bool.isRequired,
  setToolbarItems: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
  cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

export default withLock(YearlyGeneral, "YEARLY_GENERAL", {
  dataTitle: "Éves felülvizsgálat",
  title: "Éves felülvizsgálat",
  forceUpdate: false,
  disabled: () => !authRules.canModifyByKey(CLOUD_CASHREGISTER_YEARLYGENERAL)
});
