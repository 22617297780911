import React, { Component } from "react";
import { reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";
import EditForm from "../../../../../Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import SimpleFileInput from "../../../../../Components/Forms/Fields/SimpleFile";
import localize from "helpers/localize";

class ImportForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  handleFileChange = (remove, file) => {
    const { dispatch, change } = this.props;
    dispatch(change("file_id", remove ? null : get(file, "id", "")));
  };

  render() {
    const { isViewPage } = this.props;

    return (
      <EditForm {...this.props}>
        <div className="col-12">
          <SimpleFileInput
            name="file_id"
            label={localize("cashregisters", "Fájl")}
            addedFile={this.handleFileChange.bind(this, false)}
            removeFile={this.handleFileChange.bind(this, true)}
            files={[]}
            maxFiles={1}
            isViewPage={isViewPage}
            acceptedFiles=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </div>
      </EditForm>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "cashregister-import",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(ImportForm);

export default connect(
  state => ({
    initialValues: state.eventTypes.viewData
  }),
  null
)(UsersReduxForm);
