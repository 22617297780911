/* eslint-disable */
import Button from "@material-ui/core/Button/Button";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Add from "@material-ui/icons/Add";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Search from "@material-ui/icons/Search";
import Settings from "@material-ui/icons/Settings";
import ResetColumnWidthsIcon from "@material-ui/icons/ViewColumn";
import localize from "helpers/localize";
import React, { Component, Fragment } from "react";
import ModifyPermission from "../../Containers/Permissions/ModifyPermission";

class ListHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKeyTimeout: null,
      searchAll: props.keyword,
      customFilters: props.customFiltersValues
    };

    this.clearTimeOut = this.clearTimeOut.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
    this.clearTimeOut();
    if (event.keyCode === 13) {
      this.handleSearch();
    } else {
      this.setState({
        searchKeyTimeout: window.setTimeout(
          _this => {
            _this.handleSearch();
          },
          800,
          this
        )
      });
    }
  };

  handleChangeCustom = prop => event => {
    let customFilters = this.state.customFilters;
    customFilters[prop] = event.target.value;
    this.setState(customFilters);
    this.clearTimeOut();
    if (event.keyCode === 13) {
      this.handleSearch();
    } else {
      this.setState({
        searchKeyTimeout: window.setTimeout(
          _this => {
            _this.handleSearch();
          },
          800,
          this
        )
      });
    }
  };

  handleSearch = () => {
    this.props.handleSearch(
      this.state.searchAll || "",
      this.state.customFilters || ""
    );
  };

  componentWillUnmount = () => {
    this.clearTimeOut();
  };

  clearTimeOut = () => {
    window.clearTimeout(this.state.searchKeyTimeout);
  };

  render() {
    //console.log("ListHeader", this.props);
    return (
      <Fragment>
        <div className="row">
          <div className={"col-auto"}>
            {!this.props.disabledCreate && (
              <ModifyPermission module={this.props.module}>
                <Button
                  onClick={this.props.handleModuleEvent.bind(this, "create")}
                  /*variant="contained" */ color="primary"
                  component="span"
                >
                  <Add
                    style={{
                      width: 18,
                      height: 18,
                      marginRight: 5,
                      marginLeft: -6,
                      marginBottom: 1,
                      marginTop: -1
                    }}
                  />
                  {this.props.customCreateText
                    ? localize("basic", this.props.customCreateText)
                    : localize("basic", "Létrehozás")}
                </Button>
              </ModifyPermission>
            )}
          </div>
          {this.props.customButtons && this.props.customButtons()}
          <div className="col-auto" style={{ marginLeft: "auto" }}>
            <FormControl fullWidth={this.props.customFilters ? false : true}>
              <InputLabel htmlFor="searchAll">
                {localize("basic", "Keresés")}
              </InputLabel>
              <Input
                id="searchAll"
                type="text"
                value={this.state.searchAll}
                onChange={this.handleChange("searchAll")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleSearch.bind(this)}
                      onMouseDown={this.handleSearch.bind(this)}
                      style={{ width: 32, height: 32, padding: 0 }}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {this.props.customFilters &&
              this.props.customFilters.map((filter, index) => (
                <FormControl key={filter.name + index}>
                  <InputLabel htmlFor={filter.name}>{filter.label}</InputLabel>
                  <Input
                    id={filter.name}
                    type="text"
                    value={this.state.customFilters[filter.name]}
                    onChange={this.handleChangeCustom(filter.name)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={this.handleSearch.bind(this)}
                          onMouseDown={this.handleSearch.bind(this)}
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              ))}
          </div>
          {!!this.props.exportUrl && (
            <Tooltip
              title={localize("basic", "Exportálás")}
              disableFocusListener
            >
              <IconButton
                style={{
                  width: 32,
                  height: 32,
                  marginTop: 16,
                  marginRight: 4,
                  padding: 0
                }}
                component={"a"}
                href={this.props.exportUrl}
                //style={{ color: "inherit!important" }}
                download
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          )}
          {!this.props.disableGridSetting && this.props.columns && (
            <Tooltip
              title={localize("basic", "Oszlopok be és kikapcsolása")}
              disableFocusListener
            >
              <IconButton
                style={{ width: 32, height: 32, marginTop: 16, padding: 0 }}
                onClick={this.props.showSettingsModal}
              >
                <Settings />
              </IconButton>
            </Tooltip>
          )}
          {!this.props.resizable && this.props.columns && (
            <Tooltip
              title={localize(
                "basic",
                "Oszlopszélességek alaphelyzetbe állítása"
              )}
              disableFocusListener
            >
              <IconButton
                style={{ width: 32, height: 32, marginTop: 16, padding: 0 }}
                onClick={this.props.resetColumnWidths}
              >
                <ResetColumnWidthsIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Fragment>
    );
  }
}

export default ListHeader;
