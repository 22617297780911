const loginStyle = theme => ({
  leftPane: {
    padding: theme.spacing.unit * 3
  },
  rightPane: {
    backgroundColor:
      theme.header.color === "primary"
        ? theme.palette.primary.main
        : theme.palette.grey[200]
  },
  link: {
    marginLeft: "auto"
  },
  rightLogoContainer: {
    height: "100%",
    padding: theme.spacing.unit * 4
  },
  rightLogo: {
    width: "100%"
  },
  topLogoContainer: {
    padding: theme.spacing.unit * 2
  },
  topLogo: {
    maxWidth: "100%",
    maxHeight: 64
  },
  textField: {},
  textFieldInput: {
    //paddingTop: theme.spacing.unit,
    //paddingBottom: theme.spacing.unit
  },
  textFieldLabel: {
    //transform: "translate(14px, 12px) scale(1)"
  }
});

export default loginStyle;
