import requestHelper from "./requestHelper";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
/**
 * Egy Objektumot (Kulcs érték pár) alakítá át url query-vé
 *
 * @param {Object} params Átalakítandó paraméterek
 * @return {String} Átalakított paraméterek
 */
const getUrlParams = params => {
  var str = "";
  for (var key in params) {
    if (str !== "") {
      str += "&";
    }
    if (params[key]) {
      if (typeof params[key] === "object") {
        str += key + "=" + encodeURIComponent(JSON.stringify(params[key]));
      } else {
        if (params[key] === "*empty_string*") {
          str += key + "=";
        } else {
          str += key + "=" + encodeURIComponent(params[key]);
        }
      }
    }
  }
  return str;
};
const index = (controller, params, ignoreCache) => {
  const urlParams = getUrlParams(params);
  const hasQuery = controller.indexOf("?") >= 0;

  return requestHelper({
    url:
      "/" + controller + (urlParams ? (hasQuery ? "&" : "?") + urlParams : ""),
    method: "GET",
    cache: {
      ignoreCache
    }
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res;
    });
};

const view = (controller, id, params) => {
  const urlParams = getUrlParams(params);
  return requestHelper({
    url:
      (id ? `/${controller}/${id}` : `/${controller}`) +
      (urlParams ? "?" + urlParams : ""),
    method: "GET"
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const update = (controller, model, id, item) => {
  return requestHelper({
    url: id ? `/${controller}/${id}` : `/${controller}`,
    method: "PUT",
    data: item ? (model ? { [model]: item } : item) : undefined
  })
    .then(res => {
      globalMessage.success("Sikeres módosítás");
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const create = (controller, model, item) => {
  return requestHelper({
    url: "/" + controller,
    method: "POST",
    data: item ? (model ? { [model]: item } : item) : undefined
  })
    .then(res => {
      globalMessage.success("Sikeres létrehozás");
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const remove = (controller, id, params) => {
  const urlParams = getUrlParams(params);

  return requestHelper({
    url:
      "/" +
      controller +
      (id ? `/${id}` : "") +
      (urlParams ? "?" + urlParams : ""),
    method: "DELETE"
  })
    .then(res => {
      globalMessage.success("Sikeres törlés");
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const upload = file => {
  const formData = new FormData();
  formData.append("file", file);
  return requestHelper({
    url: "/files",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const post = (url, model, item) => {
  return requestHelper({
    url: "/" + url,
    method: "POST",
    data: item ? (model ? { [model]: item } : item) : undefined
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const put = (url, model, item) => {
  return requestHelper({
    url: "/" + url,
    method: "PUT",
    data: item ? (model ? { [model]: item } : item) : undefined
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const restHelper = {
  index,
  view,
  update,
  create,
  remove,
  upload,
  post,
  put
};

export default restHelper;
