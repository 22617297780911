import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";

class Input extends React.Component {
  render() {
    const {
      name,
      label,
      error,
      value,
      placeholder,
      readonly,
      validate,
      onChange,
      ...rest
    } = this.props;
    const InputComp = this.props.component;
    return (
      <InputComp
        name={name}
        label={label}
        fieldError={error}
        isRequired={true}
        type="text"
        defaultValue={value}
        placeholder={placeholder}
        readOnly={readonly}
        meta={{ touched: false }}
        input={{
          onChange: evt => onChange(evt.target.value, name)
          //value: value
        }}
        {...rest}
      />
    );
  }
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  component: PropTypes.func,
  onChange: PropTypes.func,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array])
};
export default Input;
