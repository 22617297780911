import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import { red } from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import Price from "./Price";
import AddToCart from "./AddToCart";
import StockInfoCard from "./StockInfoCard";
import Tags from "./Tags";
import ProductLink from "./ProductLink";
import { getUrl } from "Components/New/Image";
import authrules from "helpers/authRules";
import { SHOP_ADMIN_PRODUCTS_PERMISSION } from "constans/permissions";

const styles = theme => ({
  card: {
    //maxWidth: 345
    display: "flex",
    height: "100%",
    overflow: "inherit",
    position: "relative",
    //justifyContent: "space-between",
    flexDirection: "column"
  },
  tags: {
    //height: "100%",
    alignSelf: "strech"
  },
  media: {
    height: 0,
    //paddingTop: "56.25%" // 16:9
    paddingTop: "80.25%", // 16:9,
    margin: theme.spacing.unit,
    backgroundSize: "contain"
  },
  actions: {
    justifyContent: "space-between",
    marginTop: "auto",
    paddingTop: 0
  },
  addToCartInput: {
    width: 40,
    marginLeft: "auto",
    "& input": {
      textAlign: "center"
    }
  },
  addToCartButton: {},
  price: {
    paddingLeft: theme.spacing.unit * 2
  },
  stockInfo: {
    marginLeft: theme.spacing.unit * 3
  },
  expand: {
    transform: "rotate(0deg)",
    //marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500]
  },
  cardContent: {
    paddingTop: 0,
    paddingBottom: 0
  }
});

class CardItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: 1
    };
  }

  onChangeQty = qty => {
    this.setState({ qty });
  };

  render() {
    const { classes, item } = this.props;
    const { qty } = this.state;

    return (
      <Card className={classes.card}>
        <StockInfoCard
          stockInfo={item.stock_info}
          stockInfoText={item.stock_info_text}
        />
        <CardHeader
          /*avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              %
            </Avatar>
          }*/
          action={
            authrules.canModifyByKey(SHOP_ADMIN_PRODUCTS_PERMISSION) ? (
              <ProductLink id={item.id} isEdit>
                <IconButton aria-label="settings">
                  <EditIcon />
                </IconButton>
              </ProductLink>
            ) : (
              undefined
            )
          }
          title={<ProductLink id={item.id}>{item.name}</ProductLink>}
          //subheader={<ProductLink id={item.id}>{item.sku}</ProductLink>}
        />
        <ProductLink id={item.id}>
          <CardMedia
            onContextMenu={e => {
              e.preventDefault();
              return false;
            }}
            className={classes.media}
            image={getUrl(item.image_id)}
            title={item.name}
          />
        </ProductLink>
        <CardContent className={classes.cardContent}>
          <Tags rootClassName={classes.tags} tags={item.tags} />
        </CardContent>
        <CardActions disableActionSpacing className={classes.actions}>
          {/*<IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
        </IconButton>*/}
          <Price prices={item.prices} qty={qty} rootClassName={classes.price} />
          <AddToCart
            qty={qty}
            onChangeQty={this.onChangeQty}
            id={item.id}
            inputClassName={classes.addToCartInput}
            buttonClassName={classes.addToCartButton}
          />
        </CardActions>
      </Card>
    );
  }
}

CardItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired
};

export default withStyles(styles)(CardItem);
