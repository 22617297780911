const modalStyle = theme => ({
  modalRoot: {
    overflow: "auto",
    alignItems: "unset",
    justifyContent: "unset"
  },
  scrollPaper: {
    alignItems: "center"
  },
  scrollPaperTop: {
    alignItems: "unset"
  },
  progress: {
    color: theme.palette.text.primary,
    position: "absolute",
    padding: 4,
    marginLeft: 8
  },
  modal: {
    [theme.breakpoints.up("sm")]: {
      //maxWidth: 500,
      minWidth: 400,
      margin: "1.75rem auto"
    },
    margin: 0,
    borderRadius: 6,
    //marginTop: "100px !important",
    overflow: "visible",
    maxHeight: "unset",
    position: "relative",
    height: "fit-content"
  },
  modalHeader: {
    paddingBottom: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    minHeight: 16.43,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: theme.palette.primary.dark
  },
  modalHeaderError: {
    paddingBottom: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    minHeight: 16.43,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: theme.palette.error.main
  },
  modalTitle: {
    margin: 0,
    lineHeight: "1.42857143",
    color: theme.palette.primary.contrastText,
    textIndent: 30,
    marginTop: -26,
    fontWeight: "normal"
  },
  modalCloseButton: {
    color: theme.palette.primary.contrastText,
    WebkitAppearance: "none",
    padding: 0,
    cursor: "pointer",
    background: "0 0",
    border: 0,
    fontSize: "inherit",
    opacity: ".9",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1",
    float: "right",
    minWidth: 30,
    marginRight: -10
  },
  modalClose: {
    width: 16,
    height: 16
  },
  titleIcon: {
    color: theme.palette.primary.contrastText
    //position: "absolute"
  },
  modalBody: {
    paddingTop: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    position: "relative",
    overflowY: "scroll",
    maxHeight: "calc(100vh - 100px)",
    [theme.breakpoints.up("sm")]: {
      maxHeight: "calc(100vh - 136px)"
    },

    "&::-webkit-scrollbar": {
      width: 17
    }
  },
  modalFullScreenMobile: {
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 100px)"
    }
  },
  modalBodyOverflowVisible: {
    paddingTop: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    position: "relative",
    overflowY: "visible",
    maxHeight: "calc(100vh - 106px)",
    "&::-webkit-scrollbar": {
      width: 17
    }
  },
  modalFooter: {
    padding: theme.spacing.unit * 2,
    textAlign: "right",
    paddingTop: 0,
    margin: 0
  },
  modalFooterCenter: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  instructionNoticeModal: {
    marginBottom: 25
  },
  imageNoticeModal: {
    maxWidth: 150
  },
  modalSmall: {
    width: 300
  },
  modalSmallBody: {
    paddingTop: 0
  },
  modalSmallFooterFirstButton: {
    margin: 0,
    paddingLeft: 16,
    paddingRight: 16,
    width: "auto"
  },
  modalSmallFooterSecondButton: {
    marginBottom: 0,
    marginLeft: 5
  },
  modalError: {
    color: theme.palette.secondary.dark,
    fontSize: 11
  }
});

export default modalStyle;
