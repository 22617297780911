import React, { Fragment } from "react";


import RightForm from "./RightForm";
import Edit from "../../../../../Components/Edit/Edit";
import Navbar from "../../Navbar";
import localize from "helpers/localize";

class EditCashregister extends React.Component {
  getPageTitle = () => {
    if (this.props.isViewPage) {
      return localize("cashregisters", "Jogosultság megtekintése");
    }
    return this.props.match.params.id
      ? localize("cashregisters", "Jogosultság megtekintése")
      : localize("cashregisters", "Jogosultság létrehozása");
  };

  handleGetFormInfo = () => {
    return this.props.getCustomInfo(
      `cashregisters/${this.props.match.params.pid}/cashregister-rights`
    );
  };

  create = (model, formData) => {
    return this.props.createRight(this.props.match.params.pid, model, formData);
  };

  update = (model, id, formData) => {
    return this.props.updateRight(
      this.props.match.params.pid,
      model,
      id,
      formData
    );
  };

  view = () => {
    return this.props.viewRight(
      this.props.match.params.pid,
      this.props.match.params.id
    );
  };

  createSavedRedirect = id => {
    this.props.history.push(
      `/cashregisters/view/${this.props.match.params.pid}`
    );
  };

  handleBack = () => {
    this.props.history.push(
      `/cashregisters/view/${this.props.match.params.pid}`
    );
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.saved && nextProps.saved && this.props.match.params.id) {
      this.createSavedRedirect();
    }
  };
  render() {
    return (
      <Fragment>
        <Navbar {...this.props} />
        <Edit
          getPagetitle={this.getPageTitle}
          handleGetFormInfo={this.handleGetFormInfo.bind(this)}
          create={this.create.bind(this)}
          update={this.update.bind(this)}
          view={this.view.bind(this)}
          createSavedRedirect={this.createSavedRedirect.bind(this)}
          {...this.props}
        >
          <RightForm {...this.props} handleBack={this.handleBack.bind(this)} />
        </Edit>
      </Fragment>
    );
  }
}

export default EditCashregister;
