import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import UploadIcon from "@material-ui/icons/Search";
import AttachIcon from "@material-ui/icons/AttachFile";
import RemoveIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import ModalConfirm from "Components/Modal/ModalConfirm";
import restHelper from "helpers/restHelper";

class UploadButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: props.file,
      confirm: false,
      success: false,
      error: null,
      loading: false
    };
    this.modal = React.createRef();
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (!this.props.file && nextProps.file) {
      this.setState({
        file: nextProps.file
      });
    }
  };

  showErrorMessage = message => {
    //toastr.error(message);
  };

  validateExt = sFileName => {
    let acceptedFiles = this.props.acceptedFiles
      ? this.props.acceptedFiles.split(/\||,/)
      : null;
    //Nincs korlátozás
    if (!acceptedFiles) {
      return true;
    }

    if (sFileName.length > 0) {
      var blnValid = false;
      for (var j = 0; j < acceptedFiles.length; j++) {
        var sCurExtension = acceptedFiles[j]
          .replace("application/", "")
          .replace("image/", "");
        /*console.log(
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase(),
          "==",
          sCurExtension.toLowerCase()
        );*/
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        const error =
          sFileName +
          " formátuma nem megfelelő, elfogadott formátumok: " +
          acceptedFiles
            .join(", ")
            .replace(new RegExp("application/", "g"), "")
            .replace(new RegExp("[.]", "g"), "")
            .replace(new RegExp("image/", "g"), "");
        this.setState({ error: error });
        this.props.onError && this.props.onError(error);
        return false;
      }
    }

    return true;
  };

  handleUpload = event => {
    const { texts, confirmUpload } = this.props;

    if (confirmUpload) {
      event.persist();
      this.modal
        .open(
          texts.confirmUploadTitle,
          texts.confirmUploadMsg,
          null, //this.state.file.file_name,
          "Ok",
          "Mégsem"
        )
        .then(() => {
          this.upload(event);
        });
    } else {
      this.upload(event);
    }
  };

  upload = event => {
    let file = event.target.files[0];
    if (!file) {
      return;
    }
    //console.log(file);
    if (!this.validateExt(file.name)) {
      return false;
    }
    this.setState({ loading: true });

    restHelper
      .upload(file)
      .then(response => {
        console.log(response);
        file.id = response.id;
        //console.log("upload", result);
        if (this.props.onUpload) {
          console.log("TEST");
          let success = this.props.onUpload(response, file);
          this.setState({
            file: success !== false ? file : null,
            loading: false
          });
        } else {
          this.setState({
            file: file,
            loading: false
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleRemove = () => {
    const { removeOnlyClient } = this.props;

    if (removeOnlyClient) {
      if (this.props.onRemove) {
        this.props.onRemove(null, this.state.file);
      }
      this.setState({ file: null, loading: false });
      return;
    }

    restHelper
      .remove("files", this.state.file.id)
      .then(response => {
        if (this.props.onRemove) {
          this.props.onRemove(response, this.state.file);
        }
        this.setState({ file: null, loading: false });
      })
      .catch((/*error*/) => {});
  };

  render() {
    const {
      /*label,*/ name,
      disableDelete,
      disableUpload /*, texts*/,
      type,
      canReplace,
      isNeeded,
      buttonProps,
      disabled,
      iconProps
    } = this.props;
    const { file, loading } = this.state;
    const id = name + "_file";

    if (loading) {
      return <CircularProgress size={24} />;
    }

    const deleteBtn = (
      <Tooltip
        key={id + "tooltip_1"}
        id={id + "tooltip_1"}
        disableFocusListener
        title={
          file && file.file_name
            ? file.file_name + " eltávolítása."
            : "Fájl eltávolítása."
        }
      >
        <IconButton
          {...buttonProps}
          onClick={this.handleRemove}
          aria-label="Delete"
          color="primary"
          disabled={disabled}
        >
          {type === "attach" ? (
            <DeleteIcon {...iconProps} />
          ) : (
            <RemoveIcon {...iconProps} />
          )}
        </IconButton>
      </Tooltip>
    );

    const uploadBtn = (
      <Tooltip
        key={id + "tooltip_2"}
        id={id + "tooltip_2"}
        disableFocusListener
        title={type === "upload" ? "Fájl tallózása" : "Fájl csatolása"}
      >
        <IconButton
          //size="small"
          {...buttonProps}
          variant="contained"
          color={canReplace && !isNeeded ? "default" : "primary"}
          component="label"
          htmlFor={id}
          disabled={disabled}
        >
          <input
            onChange={this.handleUpload}
            type="file"
            id={id}
            accept={this.props.acceptedFiles}
            style={{ display: "none" }}
          />
          {type === "attach" ? (
            <AttachIcon {...iconProps} />
          ) : (
            <UploadIcon {...iconProps} />
          )}
        </IconButton>
      </Tooltip>
    );

    return (
      <Fragment>
        <ModalConfirm onRef={ref => (this.modal = ref)} />
        {file ? !disableDelete && deleteBtn : !disableUpload && uploadBtn}
      </Fragment>
    );
  }
}

UploadButton.defaultProps = {
  texts: {},
  type: "attach",
  confirmUpload: false,
  disabled: false,
  buttonProps: {},
  iconProps: {}
};

UploadButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  fileDelete: PropTypes.func,
  file: PropTypes.object,
  onError: PropTypes.func,
  disableDelete: PropTypes.bool,
  canReplace: PropTypes.bool,
  disableUpload: PropTypes.bool,
  disabled: PropTypes.bool,
  isNeeded: PropTypes.bool,
  acceptedFiles: PropTypes.string,
  texts: PropTypes.object,
  type: PropTypes.oneOf(["attach", "upload"]),
  confirmUpload: PropTypes.bool, //True esetben megerősítő kérdés jelenik meg feltöltés előtt. A szövegét a texts.confirmUploadTitle és texts.confirmUploadMsg-ből veszi
  remove: PropTypes.func,
  buttonProps: PropTypes.object,
  iconProps: PropTypes.object
};

export default UploadButton;
