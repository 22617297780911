import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {isEqual} from "lodash";

class GenericForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    children: PropTypes.node
  };

  constructor(props) {
    super(props);
    this.state = {
      fieldErrors: {}
    };
  }

  handleInputChange(fieldName) {
    if (this.state.fieldErrors[fieldName]) {
      this.setState({
        fieldErrors: {
          ...this.state.fieldErrors,
          [fieldName]: undefined
        }
      });
    }
  }

  componentWillUpdate(nextProps) {
    if (!isEqual(nextProps.fieldErrors, this.props.fieldErrors)) {
      this.setState({
        fieldErrors: nextProps.fieldErrors
      });
    }
  }


  render() {
    const children = React.cloneElement(this.props.children, {
      onSubmit: this.props.onSubmit,
      handleInputChange: this.handleInputChange.bind(this),
      fieldErrors: this.state.fieldErrors
    });
    return (
        <Fragment>
          {children}
        </Fragment>
    );
  }
}

export default GenericForm;
