import axios from "axios";
import qs from "qs";
import { get } from "lodash";

const CHANGES_LIST_LOAD = "CHANGES_LIST_LOAD";
const CHANGES_LIST_SUCCESS = "CHANGES_LIST_SUCCESS";
const CHANGES_LIST_FAILED = "CHANGES_LIST_FAILED";
const CHANGES_SET_LOADING = "CHANGES_SET_LOADING";
const CHANGES_LIST_HIGHTLIGHT = "CHANGES_LIST_HIGHTLIGHT";

const CHANGES_FORM_LOAD = "CHANGES_FORM_LOAD";
const CHANGES_FORM_SUCCESS = "CHANGES_FORM_SUCCESS";
const CHANGES_FORM_FAILED = "CHANGES_FORM_FAILED";

const CHANGE_DELETE_LOAD = "CHANGE_DELETE_LOAD";
const CHANGE_DELETE_SUCCESS = "CHANGE_DELETE_SUCCESS";
const CHANGE_DELETE_FAILED = "CHANGE_DELETE_FAILED";

const CHANGE_EDIT_LOAD = "CHANGE_EDIT_LOAD";
const CHANGE_EDIT_SUCCESS = "CHANGE_EDIT_SUCCESS";
const CHANGE_EDIT_FAILED = "CHANGE_EDIT_FAILED";

const CHANGE_VIEW_LOAD = "CHANGE_VIEW_LOAD";
const CHANGE_VIEW_SUCCESS = "CHANGE_VIEW_SUCCESS";
const CHANGE_VIEW_FAILED = "CHANGE_VIEW_FAILED";

const CHANGES_RESET = "CHANGES_RESET";
const CHANGES_RESET_FORM = "CHANGES_RESET_FORM";

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit: process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: "",
  orderMode: "ASC",
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null,
  reload: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGES_RESET:
      return {
        ...initialState
      };
    case CHANGES_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case CHANGE_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case CHANGES_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false,
        reload: false
      };
    case CHANGE_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case CHANGE_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case CHANGES_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case CHANGES_FORM_FAILED:
    case CHANGE_EDIT_FAILED:
    case CHANGE_DELETE_FAILED:
    case CHANGES_LIST_FAILED:
    case CHANGE_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false
      };
    case CHANGES_LIST_HIGHTLIGHT:
      return {
        ...state,
        success: true,
        failed: false,
        loading: false,
        reload: true
      };
    case CHANGES_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode,
        loading: false
      };
    case CHANGE_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        viewData: action.viewData,
        commentThread: action.commentThread || false,
        viewLoading: false
      };
    case CHANGES_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case CHANGE_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case CHANGE_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: action.id,
        viewLoading: false
      };
    case CHANGES_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: CHANGES_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: CHANGES_RESET_FORM
  });
};

export const updateChanges = (model, id, data) => dispatch => {
  dispatch({
    type: CHANGE_EDIT_LOAD
  });
  let values = Object.assign({}, data);
  delete values.documents_uploaded;
  let obj = {};

  obj[model] = values;

  return axios
    .put("/v1/service-changes/" + id, obj)
    .then(response => {
      dispatch({
        type: CHANGE_EDIT_SUCCESS
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CHANGE_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const viewChanges = id => dispatch => {
  dispatch({
    type: CHANGE_VIEW_LOAD
  });
  return axios
    .get("/v1/service-changes/" + id)
    .then(response => {
      return axios
        .get("/v1/service-changes/documents/" + id.replace("raw/", ""))
        .then(responseDocuments => {
          response.data.data.documents_uploaded = Array.isArray(
            responseDocuments.data.data
          )
            ? responseDocuments.data.data
            : [];
          dispatch({
            type: CHANGE_VIEW_SUCCESS,
            viewData: get(response, "data.data"),
            commentThread: get(response, "data.commentThread", false)
          });
          return response;
        });
    })
    .catch(error => {
      dispatch({
        type: CHANGE_VIEW_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const createChanges = (model, data) => dispatch => {
  dispatch({
    type: CHANGE_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .post("/v1/service-changes", obj)
    .then(response => {
      dispatch({
        type: CHANGE_EDIT_SUCCESS,
        id: get(response, "data.data.id", null)
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CHANGE_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const changesDelete = id => dispatch => {
  dispatch({
    type: CHANGE_DELETE_LOAD
  });
  return axios
    .delete("/v1/service-changes/" + id)
    .then(response => {
      dispatch({
        type: CHANGE_DELETE_SUCCESS,
        id
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CHANGE_DELETE_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: CHANGES_SET_LOADING,
    loading
  });
};

export const changesList = (
  page,
  keyword,
  sort,
  filters,
  perPage
) => dispatch => {
  dispatch({
    type: CHANGES_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    "per-page": perPage
  };

  params[filters.model] = filters.filters;

  return axios
    .get(
      "/v1/service-changes",
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then(response => {
      let sort = get(response, "data.sort", null),
        mode = "ASC";
      if (sort && sort.indexOf("-") !== -1) {
        mode = "DESC";
        sort = sort.substr(1);
      }
      dispatch({
        type: CHANGES_LIST_SUCCESS,
        data: get(response, "data.data", null),
        page: get(response, "data.page", 0),
        limit: get(response, "data.per-page", 0),
        total: get(response, "data.total", 0),
        order: sort,
        orderMode: mode
      });
      return response.data; // todo  use response.data
    })
    .catch(error => {
      dispatch({
        type: CHANGES_LIST_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};
