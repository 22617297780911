import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import restHelper from "helpers/aee/restHelper";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import ModalConfirm from "Components/Modal/ModalConfirm";
import { Grid } from "@material-ui/core";
//import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
import localize from "helpers/localize";
import TextField from "Components/New/Input/TextField/TextField";
import withLock from "./withLock";
const LC = "cloud"; //Localization category

const styles = () => ({});

class SignOperation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ""
    };
    this.modal = null;
  }

  componentDidMount() {
    this.view();
  }

  componentWillUnmount() {}

  view = () => {
    const { cashregister_id: ap, client_id: client } = this.props;
    this.setState({ loading: true });
    restHelper
      .view("cashregister-sign-operation/view", null, { ap, client })
      .then(response => {
        const value =
          response && response.data && response.data.value
            ? response.data.value
            : "";
        this.setState({ value, loading: false });
      });
  };

  onSave = () => {
    const { cashregister_id: ap, client_id: client, onUpdated } = this.props;
    const { value } = this.state;
    this.setState({ loading: true });
    restHelper
      .create(
        "cashregister-sign-operation/create",
        null,
        { value },
        {
          ap,
          client
        }
      )
      .then(() => {
        onUpdated(() => {
          this.setState({ loading: false });
        });
      });
  };

  render() {
    const { editMode } = this.props;
    const { value } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                disabled={!editMode}
                fullWidth
                value={value}
                multiline
                rows={5}
                onChanged2={evt => {
                  this.setState({ value: evt.target.value });
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Toolbar disableGutters>
                <Button
                  disabled={!editMode || value === ""}
                  color="primary"
                  style={{ marginLeft: "auto" }}
                  onClick={this.onSave}
                >
                  {localize(LC, "Küldés")}
                </Button>
              </Toolbar>
            </Grid>
          </Grid>
        </Grid>
        <ModalConfirm onRef={ref => (this.modal = ref)} />
      </Grid>
    );
  }
}

SignOperation.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  onUpdated: PropTypes.func.isRequired,
  cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //Ez igazából az AP szám
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
export default withLock(withStyles(styles)(SignOperation), "SIGN_OPERATION", {
  dataTitle: "Eladás",
  title: "Eladás",
  forceUpdate: false,
  disableRefreshButton: true,
  hideTimer: true,
  last_refresh_time_text: "Utolsó feldolgozás ideje"
});
