import React from "react";
import DataTableDx from "Components/New/DataTable/DataTableDx/DataTableDx";
import DataForm from "Components/New/DataForm/DataFormNew";
import DataProvider from "Components/New/DataProvider/DataProviderNew";
import authRules from "helpers/authRules";
import { SHOP_ADMIN_PRICES_PERMISSION } from "constans/permissions";
import localize from "helpers/localize";
class AdminProductCustomPrices extends React.Component {
  render() {
    //const { client, readOnly, afterSave } = this.props;

    return (
      <div style={{ width: "100%", position: "relative" }}>
        <DataProvider
          url={"shop-admin-product-custom-prices"}
          //url={"cashregisters"}
          goBackAfterSave
          useUrlRouting
          //disableGetView
          tableComponent={DataTableDx}
          formComponent={DataForm}
          tableProps={{
            title: localize("shop", "Egyedi árak"),
            canView: false,
            canCreate: authRules.canModifyByKey(SHOP_ADMIN_PRICES_PERMISSION),
            canDelete: authRules.canDeleteByKey(SHOP_ADMIN_PRICES_PERMISSION),
            canEdit: authRules.canModifyByKey(SHOP_ADMIN_PRICES_PERMISSION),
            columns: {}
          }}
          formProps={{
            title: localize("shop", "Ár szerkesztése"),
            titleNew: localize("shop", "Új ár felvitele"),
            fields: {
              distributor_id: {
                name: localize("shop", "Viszonteladó")
              },
              shop_product_id: {
                name: localize("shop", "Termék")
              },
              price: {
                name: localize("shop", "Egyedi ár"),
                type: "Ft"
              }
            },
            fieldsOrder: ["distributor_id", "shop_product_id", "price"],
            //component: CustomForm,
            autoRenderFields: true
          }}
        />
      </div>
    );
  }
}

export default AdminProductCustomPrices;
