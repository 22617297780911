import React from "react";
import DataTableDx from "Components/New/DataTable/DataTableDx/DataTableDx";
import DataForm from "Components/New/DataForm/DataFormNew";
import DataProvider from "Components/New/DataProvider/DataProviderNew";
import authRules from "helpers/authRules";
import { SHOP_ADMIN_TAGS_PERMISSION } from "constans/permissions";
import localize from "helpers/localize";
class AdminTags extends React.Component {
  render() {
    //const { client, readOnly, afterSave } = this.props;

    return (
      <div style={{ width: "100%", position: "relative" }}>
        <DataProvider
          url={"shop-admin-tags"}
          //url={"cashregisters"}
          goBackAfterSave
          useUrlRouting
          //disableGetView
          tableComponent={DataTableDx}
          formComponent={DataForm}
          tableProps={{
            title: "Címkék",
            canView: false,
            canCreate: authRules.canModifyByKey(SHOP_ADMIN_TAGS_PERMISSION),
            canDelete: authRules.canDeleteByKey(SHOP_ADMIN_TAGS_PERMISSION),
            canEdit: authRules.canModifyByKey(SHOP_ADMIN_TAGS_PERMISSION),
            columns: {
              name: { width: 200 },
              created_at: { width: 160 }
            }
          }}
          formProps={{
            title: "Címke szerkesztése",
            titleNew: "Új címke felvitele",
            fields: {
              name: { name: localize("shop", "Név"), validators: ["required"] }
            },
            fieldsOrder: ["name"],
            //component: CustomForm,
            autoRenderFields: true
          }}
        />
      </div>
    );
  }
}

export default AdminTags;
