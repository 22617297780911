import React, {Fragment} from 'react';

import ImportForm from './ImportForm';
import EditPage from '../../../../../Components/Extends/EditPage';
import Edit from '../../../../../Components/Edit/Edit';


class ImportCashregister extends EditPage {

    createSavedRedirect = () => {
        this.props.handleRequestClose();
    };

  render() {
    return (
        <Fragment>
            <Edit
                isViewPage={false}
                {...this.props}
                createSavedRedirect={this.createSavedRedirect.bind(this)}
            >
                <ImportForm
                    {...this.props}
                    isViewPage={false}
                />
            </Edit>
        </Fragment>

    );
  }

}

export default ImportCashregister;