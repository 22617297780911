import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import GenericForm from "../../../../../Components/Forms/GenericForm";
import { toastr } from "../../../../../helpers/CustomToastr";
import CashregisterKeyGeneratorForm from "./CashregisterKeyGeneratorForm";
//import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import ModalConfrim from "Components/Modal/ModalConfirm";
import authRules from "helpers/authRules";
import localize from "helpers/localize";
import EditAnnualReview from "../../AnnualReviews/EditAnnualReview";
import {
  KEYGEN_BATTERY,
  KEYGEN_REREG,
  KEYGEN_RS232
} from "../../Stickers/views/StickerCreate";

function TabContainer({ children, dir }) {
  return (
    <div dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string
};

const types = [
  "aeeboxopen",
  "fiscat_prior",
  "generalcheck",
  "superrecent",
  "recent",
  "prior_fiscat",
  "pwdreregister",
  "aeedriver",
  "battery",
  "erconline",
  "rs232",
  "empty"
];
const KEYGEN_TYPES = {
  pwdreregister: KEYGEN_REREG,
  battery: KEYGEN_BATTERY,
  rs232: KEYGEN_RS232
};
class CashregisterKeyGenerators extends React.Component {
  state = {
    value: 5,
    open: false,
    annualForm: {
      model: null,
      baseFields: null,
      enabledFields: null
    }
  };

  constructor(props) {
    super(props);
    this._form = React.createRef();
  }

  submit = () => {
    const instance = this._form.current.getWrappedInstance();
    if (instance && instance.submit) {
      instance.submit();
    }
  };

  handleChange = (event, value) => {
    this.props.resetCode();
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.props.resetCode();
    this.setState({ value: index });
  };

  /*refreshHistory = () => {
    if (this.props.refreshHistory) {
      this.props.refreshHistory();
    }
  }*/

  refreshHistory = () => {
    const id = get(this.props, "match.params.id", null);
    if (id) {
      this.props.historyList(id);
    }
  };

  onCloseCodeModal = () => {
    const id = get(this.props, "match.params.id", null);
    this.props.view(id);
  };

  handleSubmit = formData => {
    formData.type = types[this.state.value];

    if (
      [/*"pwdreregister", */ "battery", "rs232"].includes(
        types[this.state.value]
      )
    ) {
      const keygen_type = KEYGEN_TYPES[types[this.state.value]];
      this.props
        .checkTokenKey(formData.param, formData.apn, keygen_type)
        .then(response => {
          if (response) {
            this.generateKey(formData).then(res => {
              const key = get(res, "data.data.key", null);
              this.refreshHistory();
              const id = get(this.props, "match.params.id", null);
              if (types[this.state.value] === "pwdreregister") {
                this.modal
                  .open(
                    "Áteszemélyesítés",
                    `A generált kód: ${key}`,
                    null, //"123",
                    "Ok",
                    false,
                    false
                  )
                  .then(() => {
                    this.modal.close();
                    this.props.view(id);
                  });
              } else {
                this.props.view(id);
              }
            });
          } else {
            this.setState({
              fieldErrors: { param: ["Hibás Token"] }
            });
          }
        });
    } else if (types[this.state.value] !== "generalcheck") {
      this.generateKey(formData).then(res => {
        const key = get(res, "data.data.key", null);
        this.refreshHistory();
        const id = get(this.props, "match.params.id", null);

        //Átszemélyesítésnél külön popup, mert statuszt vált a pénztárgép és eltűnik a füllel együtt a generált kód is kód is
        if (formData.type === "pwdreregister") {
          this.modal
            .open(
              "Áteszemélyesítés",
              `A generált kód: ${key}`,
              null, //"123",
              "Ok",
              false,
              false
            )
            .then(() => {
              this.modal.close();
              this.props.view(id);
            });
        } else {
          this.props.view(id);
        }
      });
    } else {
      this.props.checkKey(formData.param, formData.apn).then(response => {
        if (response) {
          //TODO: Ha kell kód generáláskor is
          //this.refreshHistory();
          this.setState({
            open: formData
          });
        } else {
          this.setState({
            fieldErrors: { params: ["Hibás matricaszám"] }
          });
        }
      });
    }
  };

  handleCloseAnnual = param => {
    let formData = Object.assign({}, this.state.open);
    this.setState({
      open: false,
      annualForm: {
        model: null,
        baseFields: null,
        enabledFields: null
      }
    });
    if (param) {
      formData.param = param;
      delete formData.muszereszIgazolvanySzam;
      this.generateKey(formData).then(res => {
        this.refreshHistory();
        const id = get(this.props, "match.params.id", null);
        this.props.view(id);
      });
    }
  };

  generateKey = formData => {
    return this.props.create(formData);
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.saved && nextProps.saved) {
      toastr.success(localize("basic", "Sikeres művelet"));
    }
    if (!this.props.message && nextProps.message) {
      toastr.error(nextProps.message);
    }
    if (!this.props.annual_message && nextProps.annual_message) {
      toastr.error(nextProps.annual_message);
    }
  };

  loadViewContent = () => {
    this.props.setViewData(
      this.state.annualForm.defaultValues,
      this.state.open.param
    );
  };

  handleGetFormInfo = () => {
    return this.props
      .getFormInfo("annual-reviews", "&apn=" + this.state.open.apn)
      .then(response => {
        if (response.type === "FORM_SETTING_SUCCESS") {
          this.setState({
            annualForm: {
              model: response.model,
              baseFields: response.baseFields,
              enabledFields: response.enabledFields,
              defaultValues: response.defaultValues
            }
          });
        }
        return response;
      });
  };

  render() {
    const isMobile = window.innerWidth < 600;
    const { canReregister } = this.props;
    return (
      <div style={{ paddingLeft: 12, paddingRight: 12 }} className="w-100">
        <Dialog
          open={this.state.open}
          maxWidth="md"
          PaperProps={{
            style: { marginLeft: "auto", marginRight: "auto" }
          }}
          onClose={this.handleCloseAnnual.bind(this, false)}
          scroll="body"
        >
          <DialogTitle>
            {localize("cashregisters", "Az éves felülvizsgálat adatai")}
          </DialogTitle>
          <DialogContent
            style={
              isMobile
                ? { paddingLeft: 0, paddingRight: 0, overflow: "hidden" }
                : {}
            }
          >
            <EditAnnualReview
              initalize={this.loadViewContent.bind(this)}
              handleGetFormInfo={this.handleGetFormInfo.bind(this)}
              forceCreateOnly={true}
              createSavedRedirect={this.handleCloseAnnual.bind(this)}
              {...this.state.annualForm}
              {...this.props}
              ref={this._form}
              disableButtons={true}
            />
          </DialogContent>
          <DialogActions
            style={{ padding: 16, paddingTop: 0, allignItems: "flex-start" }}
          >
            <Button
              style={{ marginLeft: "auto" }}
              onClick={this.handleCloseAnnual.bind(this, false)}
              variant="contained"
              color="secondary"
              component="span"
            >
              {localize("cashregisters", "Bezár")}
            </Button>
            <Button
              onClick={this.submit}
              variant="contained"
              color="primary"
              component="span"
            >
              {localize("settings", "Mentés")}
            </Button>
          </DialogActions>
        </Dialog>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab
              className="tab"
              label={localize("cashregisters", "AEE felnyitás")}
              hidden={
                !authRules.hasPermissionByKeyAndName(
                  "cashregisters",
                  "cashregisters.keygenerator.aeeboxopen"
                )
              }
            />
            <Tab
              className="tab"
              label={localize("cashregisters", "Ház felnyitás")}
            />
            <Tab
              className="tab"
              label={localize("cashregisters", "Éves felülvizsgálat")}
            />
            <Tab
              className="tab"
              label={localize("cashregisters", "Bérlés ultimate")}
              hidden={
                !authRules.hasPermissionByKeyAndName(
                  "cashregisters",
                  "cashregisters.keygenerator.aeeboxopen"
                )
              }
            />
            <Tab className="tab" label={localize("cashregisters", "Bérlés")} />
            <Tab className="tab" label={localize("cashregisters", "S-mód")} />
            <Tab
              className="tab"
              label={localize("cashregisters", "Átszemélyesítés")}
            />
            <Tab
              className="tab"
              label={localize("cashregisters", "AEE driver")}
              hidden={!authRules.isRoot}
            />
            <Tab className="tab" label={localize("cashregisters", "Akku")} />
            <Tab
              className="tab"
              label={localize("cashregisters", "Ecr online")}
              hidden={!authRules.isRoot}
            />
            <Tab className="tab" label={localize("cashregisters", "RS232")} />
            <Tab className="tab" label={"Empty"} hidden />
          </Tabs>
        </AppBar>
        {this.state.value === 0 && (
          <TabContainer>
            <GenericForm
              onSubmit={this.handleSubmit.bind(this)}
              fieldErrors={this.props.fieldErrors}
              key={types[this.state.value]}
            >
              <CashregisterKeyGeneratorForm
                {...this.props}
                type={types[this.state.value]}
              />
            </GenericForm>
          </TabContainer>
        )}
        {this.state.value === 1 && (
          <TabContainer>
            <GenericForm
              onSubmit={this.handleSubmit.bind(this)}
              fieldErrors={this.props.fieldErrors}
              key={types[this.state.value]}
            >
              <CashregisterKeyGeneratorForm
                {...this.props}
                type={types[this.state.value]}
              />
            </GenericForm>
          </TabContainer>
        )}
        {this.state.value === 2 && (
          <TabContainer>
            <GenericForm
              onSubmit={this.handleSubmit.bind(this)}
              fieldErrors={this.props.checkKeyErrors}
              key={types[this.state.value]}
            >
              <CashregisterKeyGeneratorForm
                {...this.props}
                type={types[this.state.value]}
              />
            </GenericForm>
          </TabContainer>
        )}
        {this.state.value === 3 && (
          <TabContainer>
            <GenericForm
              onSubmit={this.handleSubmit.bind(this)}
              fieldErrors={this.props.fieldErrors}
              key={types[this.state.value]}
            >
              <CashregisterKeyGeneratorForm
                {...this.props}
                type={types[this.state.value]}
              />
            </GenericForm>
          </TabContainer>
        )}
        {this.state.value === 4 && (
          <TabContainer>
            <GenericForm
              onSubmit={this.handleSubmit.bind(this)}
              fieldErrors={this.props.fieldErrors}
              key={types[this.state.value]}
            >
              <CashregisterKeyGeneratorForm
                {...this.props}
                type={types[this.state.value]}
              />
            </GenericForm>
          </TabContainer>
        )}
        {this.state.value === 5 && (
          <TabContainer>
            <GenericForm
              onSubmit={this.handleSubmit.bind(this)}
              fieldErrors={this.props.fieldErrors}
              key={types[this.state.value]}
            >
              <CashregisterKeyGeneratorForm
                {...this.props}
                type={types[this.state.value]}
              />
            </GenericForm>
          </TabContainer>
        )}
        {this.state.value === 6 && (
          <TabContainer>
            <GenericForm
              onSubmit={this.handleSubmit.bind(this)}
              fieldErrors={{
                ...this.props.fieldErrors,
                ...this.props.checkKeyErrors
              }}
              key={types[this.state.value]}
            >
              <CashregisterKeyGeneratorForm
                {...this.props}
                type={types[this.state.value]}
                canReregister={canReregister}
              />
            </GenericForm>
          </TabContainer>
        )}
        {this.state.value === 7 && (
          <TabContainer>
            <GenericForm
              onSubmit={this.handleSubmit.bind(this)}
              fieldErrors={{
                ...this.props.fieldErrors,
                ...this.props.checkKeyErrors
              }}
              key={types[this.state.value]}
            >
              <CashregisterKeyGeneratorForm
                {...this.props}
                type={types[this.state.value]}
                canReregister={canReregister}
              />
            </GenericForm>
          </TabContainer>
        )}
        {this.state.value === 8 && (
          <TabContainer>
            <GenericForm
              onSubmit={this.handleSubmit.bind(this)}
              fieldErrors={{
                ...this.props.fieldErrors,
                ...this.props.checkKeyErrors
              }}
              key={types[this.state.value]}
            >
              <CashregisterKeyGeneratorForm
                {...this.props}
                type={types[this.state.value]}
                canReregister={canReregister}
              />
            </GenericForm>
          </TabContainer>
        )}
        {this.state.value === 9 && (
          <TabContainer>
            <GenericForm
              onSubmit={this.handleSubmit.bind(this)}
              fieldErrors={{
                ...this.props.fieldErrors,
                ...this.props.checkKeyErrors
              }}
              key={types[this.state.value]}
            >
              <CashregisterKeyGeneratorForm
                {...this.props}
                type={types[this.state.value]}
                canReregister={canReregister}
              />
            </GenericForm>
          </TabContainer>
        )}
        {this.state.value === 10 && (
          <TabContainer>
            <GenericForm
              onSubmit={this.handleSubmit.bind(this)}
              fieldErrors={{
                ...this.props.fieldErrors,
                ...this.props.checkKeyErrors
              }}
              key={types[this.state.value]}
            >
              <CashregisterKeyGeneratorForm
                {...this.props}
                type={types[this.state.value]}
                canReregister={canReregister}
              />
            </GenericForm>
          </TabContainer>
        )}
        <ModalConfrim
          onClose={this.onCloseCodeModal}
          onRef={ref => (this.modal = ref)}
          disableIcon
        />
      </div>
    );
  }
}

export default CashregisterKeyGenerators;
