import React, { Fragment, PureComponent } from "react";
import { Helmet } from "react-helmet";
import ModifyPermission from "../../../../Permissions/ModifyPermission";
import Button from "@material-ui/core/Button/Button";

import List from "../../../../../Components/List/List";
import ContainerHeader from "../../../../../Components/ContainerHeader";
import Navbar from "../../Navbar";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import EditCrule from "../EditCrule";
import authRules from "helpers/authRules";
import localize from "helpers/localize";

class CrulesList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      keyword: null,
      search: null,
      filters: null,
      perPage: null,
      open: false
    };
  }

  customButtons = () => {
    return (
      <Fragment>
        <ModifyPermission match={this.props.match}>
          <div className="col-auto mr-auto">
            <Button
              variant="contained"
              component="span"
              onClick={() => this.setState({ open: true })}
            >
              {localize("cashregisters", "Adatpár import")}
            </Button>
          </div>
        </ModifyPermission>
      </Fragment>
    );
  };

  handleRequestClose = () => {
    this.setState({ open: false });
    const { page, keyword, sort, filters, perPage } = this.state;
    this.getList(page, keyword, sort, filters, perPage);
  };

  getList = (page, keyword, sort, filters, perPage) => {
    this.setState({
      page,
      keyword,
      sort,
      filters,
      perPage
    });
    return this.props.getList(page, keyword, sort, filters, perPage);
  };

  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("cashregisters", "Adatpárok")}</title>
        </Helmet>
        <Navbar {...this.props} />
        <ContainerHeader title={localize("cashregisters", "Adatpárok")} />
        {this.state.open && (
          <Dialog
            open={this.state.open}
            maxWidth="md"
            fullWidth={true}
            scroll="body"
          >
            <DialogTitle>
              {localize("cashregisters", "Adatpár import")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <EditCrule
                  {...this.props}
                  handleRequestClose={this.handleRequestClose.bind(this)}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleRequestClose.bind(this)}
                variant="contained"
                color="secondary"
                component="span"
              >
                {localize("cashregisters", "Bezár")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <List
          {...this.props}
          disabledCreate={true}
          //disabledTools={true}
          disableEditIcon
          disableViewIcon
          //disableDeleteIcon={!authRules.isRoot}
          disableDeleteIcon={!authRules.canDeleteByKey("c-rules")}
          customButtons={this.customButtons.bind(this)}
          getList={this.getList.bind(this)}
          reset={() => {}}
        />
      </div>
    );
  }
}

export default CrulesList;
