import React, { Fragment, cloneElement } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
//import Toolbar as MuiToolbar from "@material-ui/core/Toolbar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from "@material-ui/icons/Refresh";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import Select from "Components/New/Input/Select/Select";
import { withRouter, Link, Route, Switch, Redirect } from "react-router-dom";
// import ReactDataSheet from "react-datasheet";
// Be sure to include styles at some point, probably during your bootstrapping
// import "react-datasheet/lib/react-datasheet.css";
import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import DataForm from "Components/New/DataForm/DataForm";
//import { IconButton, TableCell } from "@material-ui/core";
import TextField from "Components/New/Input/TextField/TextField";
import Checkbox from "Components/New/Input/Checkbox/Checkbox";
import DateRangePicker from "Components/New/Input/DateRangePicker/DateRangePicker";
import dateHelper from "helpers/dateHelper";
import formatHelper from "helpers/formatHelper";

import TableDetailToggleCell from "./TableDetailToggleCell";
import TableGroupToggleIcon from "./TableGroupToggleIcon";
import CheckedIcon from "@material-ui/icons/CheckBox";
import UnCheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";
/*
import { GroupingState, IntegratedGrouping } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow
} from "@devexpress/dx-react-grid-material-ui";
*/

import {
  Plugin,
  Template,
  TemplatePlaceholder
} from "@devexpress/dx-react-core";

import {
  GroupingState,
  IntegratedGrouping,
  RowDetailState,
  SearchState,
  IntegratedFiltering,
  SelectionState,
  IntegratedSummary,
  DataTypeProvider,
  SummaryState,
  CustomSummary
} from "@devexpress/dx-react-grid";

import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableGroupRow,
  TableRowDetail,
  Toolbar,
  SearchPanel,
  VirtualTable,
  GroupingPanel,
  DragDropProvider,
  TableSelection,
  TableSummaryRow,
  ColumnChooser,
  TableColumnVisibility
} from "@devexpress/dx-react-grid-material-ui";
import { CircularProgress, Hidden, IconButton } from "@material-ui/core";
import MetaTableImages from "Containers/Pages/Cloud/MetaTableImages";
import Loader from "Components/New/Loader";

const SyntaxHighlight = props => {
  let json = props.json;
  if (typeof json != "string") {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/\\"/g, '"');
  let pretty = json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function(match) {
      var cls = "color:blue;"; //'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "color:black;"; //'key';
        } else {
          cls = "color:red; white-space: normal;"; //'string';
        }
      } else if (/true|false/.test(match)) {
        cls = "color:green;"; //'boolean';
      } else if (/null/.test(match)) {
        cls = "color:gray;"; //'null';
      }
      return '<span style="' + cls + '">' + match + "</span>";
    }
  );
  pretty = "<code><pre>" + pretty + "</pre></code>";
  return <div dangerouslySetInnerHTML={{ __html: pretty }} />;
};
const arr_diff = (a1, a2) => {
  var a = [],
    diff = [];

  for (var i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (var i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (var k in a) {
    diff.push(k);
  }

  return diff;
};

const tableMessages = {
  noData: "Nincs adat"
};

const groupingPanelMessages = {
  groupByColumn: "Húzzon ide egy oszlopot a csoportosításhoz"
};
const searchMessages = {
  searchPlaceholder: "Keresés"
};

const summaryMessages = {
  sum: "Össz"
};
/*
const ActionCellBase = props => {
  const { classes, row, rowIndex, onEdit, onDelete } = props;
  return (
    <TableCell className={classes.actionCell} padding="none">
      <IconButton
        className={classes.actionCellButton}
        onClick={() => onEdit(row, rowIndex)}
      >
        <EditIcon className={classes.actionCellIcon} />
      </IconButton>
      <IconButton
        className={classes.actionCellButton}
        onClick={() => onDelete(row, rowIndex)}
      >
        <DeleteIcon className={classes.actionCellIcon} />
      </IconButton>
    </TableCell>
  );
};

const ActionCell = withStyles(styles)(ActionCellBase);
*/
/*const RowDetail = ({ row }) => (
  <div>
    Részletek {row.real_name} ekkor rögzítve {row.created_at}
  </div>
);*/
const Cell = props => {
  const { column } = props;
  //if (column.name === "amount") {
  return (
    <Table.Cell
      {...props}
      style={{ ...props.style, backgroundColor: "orange" }}
    />
  );
  //}
  //return <Table.Cell {...props} />;
};

const HeaderRowComponent = props => {
  const { column } = props;
  //if (column.name === "amount") {
  return (
    <VirtualTable.Row {...props} style={{ ...props.style, height: "unset" }} />
  );
  //}
  //return <Table.Cell {...props} />;
};

const GroupCell = ({ colSpan, ...restProps }) => {
  return (
    <TableGroupRow.Cell
      {...restProps}
      colSpan={colSpan - 7}
      //style={{ ...restProps.style, height: "unset" }}
    />
  );
};
const GroupSummaryRow = () => {
  return (
    <Plugin>
      <Template
        name="tableRow"
        predicate={({ tableRow }) => {
          //tableRow.type.toString() === "group"
          //console.log(tableRow.type.toString() === "Symbol(group)");
          //console.log(tableRow.type.toString());;
          return (
            tableRow.type.toString() === "Symbol(group)" //||
            //tableRow.type.toString() === "Symbol(data)"
          );
          //return false;
        }}
      >
        {params => {
          if (params.tableRow.type.toString() === "Symbol(group)") {
            return (
              <TableGroupRow.Row
                {...params}
                row={params.tableRow.row}
                style={{
                  ...params.style,
                  //height: "unset",
                  backgroundColor: "#fffde7"
                }}
              >
                {params.children}
                {/*<Table.Cell
                //style={{ backgroundColor: "red" }}
                >
                  <i>1 123 123 Ft</i>
                </Table.Cell>
                <Table.Cell>
                  <i>470 000 Ft</i>
                </Table.Cell>
                <Table.Cell>
                  <i>330 000 Ft</i>
                </Table.Cell>
                <Table.Cell>
                  <i>3000 Ft</i>
                </Table.Cell>
                <Table.Cell>
                  <i>1230 Eur</i>
                </Table.Cell>
                <Table.Cell colSpan={4} />*/}
              </TableGroupRow.Row>
            );
          } else {
            return (
              <Table.Row
                {...params}
                row={params.tableRow.row}
                //cellComponent={Cell}
                style={{ ...params.style, height: "unset" }}
              />
            );
          }
        }}
      </Template>
    </Plugin>
  );
};
/*
const TableRow = ({ row, style, ...restProps }) => (
  <Table.Row
    {...restProps}
    // eslint-disable-next-line no-alert
    onClick={() => alert(JSON.stringify(row))}
    style={{
      backgroundColor: row.region === 20 ? "blue" : undefined,
      cursor: "pointer",
      ...style
    }}
  />
);*/
/*
const HighlightedCell = ({ row, style }) => (
  <Table.Cell
    style={{
      backgroundColor: row.region === 20 ? "red" : undefined,
      ...style
    }}
  >
    <span
      style={{
        color: row.region === 20 ? "white" : undefined
      }}
    >
      {row.amount}
    </span>
  </Table.Cell>
);
*/
const CustomToolbarMarkup = props => {
  const {
    changeGroup,
    grouping,
    changeDates,
    dates,
    groupableColumns,
    showRefreshIcon,
    fecthData,
    loading,
    dateRangePickerProps,
    toolbarItems
  } = props;
  let grouped = grouping.map(group => group.columnName);
  console.log("dateRangePickerProps", dateRangePickerProps);
  return (
    <Plugin name="customToolbarMarkup">
      <Template name="toolbarContent">
        {groupableColumns.indexOf("date") >= 0 && (
          <Checkbox
            label="Csoportosítás nap szerint"
            onChange={evt =>
              changeGroup && changeGroup("date", evt.target.checked)
            }
            value={grouped.indexOf("date") >= 0}
          />
        )}
        {groupableColumns.indexOf("cashregister_apn") >= 0 && (
          <Checkbox
            label="Csoportosítás AP szám szerint"
            onChange={evt =>
              changeGroup && changeGroup("cashregister_apn", evt.target.checked)
            }
            value={grouped.indexOf("cashregister_apn") >= 0}
          />
        )}
        <TemplatePlaceholder />
        <div style={{ marginLeft: 16 }}>
          <DateRangePicker
            onChange={changeDates}
            label="Dátum tartomány"
            value={dates}
            showClearButton={false}
            {...dateRangePickerProps}
          />
        </div>
        {showRefreshIcon && !loading && (
          <Tooltip title="Adatok frissítése">
            <IconButton
              color="primary"
              style={{
                padding: 8,
                marginBottom: -8,
                marginTop: 8,
                marginLeft: 4
              }}
              onClick={evt => {
                //console.log(dates);
                fecthData({
                  from: dates.start.format("YYYY-MM-DD"),
                  to: dates.end.format("YYYY-MM-DD")
                });
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )}
        {toolbarItems}
        {loading && (
          <CircularProgress
            style={{
              padding: 8,
              marginBottom: -8,
              marginTop: 8,
              marginLeft: 4
            }}
            size={40}
          />
        )}
      </Template>
    </Plugin>
  );
};

const Container = props => {
  return (
    <div
      key="test1"
      style={{ display: "flex", width: "100%", overflowX: "scroll" }}
    >
      <div style={{ flex: 1 }} style={{ overflowX: "hidden" }}>
        <VirtualTable.Container
          {...props}
          style={{ ...props.style, width: "100%" }}
        />
      </div>
      <div style={{ width: 352 }}>
        {window.accountingSelectedRow && (
          <MetaTableImages row={window.accountingSelectedRow} />
        )}
      </div>
    </div>
  );
  //}
  //return <Table.Cell {...props} />;
};

const SearchInput = props => {
  const { getMessage, onValueChange, ...rest } = props;
  return (
    <TextField
      label={getMessage("searchPlaceholder")}
      //label="Keresés"
      onChange={e => onValueChange(e.target.value)}
      {...rest}
      /*style={{ ...rest.style, fontSize: 12 }}
      inputProps={{
        style: { padding: "5px 7px" }
      }}*/
    />
  );
};
const styles = theme => ({
  tableStriped: {
    borderCollapse: "collapse",
    "& tr": {
      height: "unset",
      "&:hover": {
        backgroundColor: "#ddd"
      }
    },
    "& td": {
      border: "solid 1px #ddd",
      "& > div": {
        margin: 0
      }
    }
  },
  totalCell: {
    //backgroundColor: "green",
    "& > div": {
      margin: 0
    }
  }
});

const RowComponentBase = ({ classes, ...restProps }) => (
  <VirtualTable.Table
    {...restProps}
    className={classes.tableStriped}
    //style={{ height: "unset" }}
  />
);

export const TableRow = withStyles(styles, { name: "TableRow" })(
  RowComponentBase
);

const TotalCellComponentBase = ({ classes, ...restProps }) => (
  <TableSummaryRow.TotalCell
    {...restProps}
    className={classes.totalCell}
    //style={{ height: "unset" }}
  />
);

export const TotalCell = withStyles(styles, { name: "TotalCell" })(
  TotalCellComponentBase
);

function fake() {
  let data = [];
  for (let index = 0; index < 1000; index++) {
    data.push({
      range_start: index.toString() + "2019-08-04 18:32:01",
      GYF_A: 6,
      GYF_B: "",
      GYF_C: "",
      GYF_D: "",
      GYF_E: "",
      SGY_A: "",
      SGY_B: "",
      SGY_C: "",
      SGY_D: "",
      SGY_E: "",
      VGY_A: "",
      VGY_B: "",
      VGY_C: "",
      VGY_D: "",
      VGY_E: "",
      amount: 6,
      dts: "2019-08-04 18:32:13"
    });
  }
  return data;
}

//const fd = fake();
const CurrencyFormatter = ({ value }) => `${formatHelper.currenyFormat(value)}`;
const CurrencyTypeProvider = props => (
  <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
);
const CurrencyEurFormatter = ({ value }) =>
  `${formatHelper.currenyFormat(value)}`;
const CurrencyEurTypeProvider = props => (
  <DataTypeProvider formatterComponent={CurrencyEurFormatter} {...props} />
);

const BooleanFormatter = ({ value }) =>
  value ? (
    <CheckedIcon
      color="primary"
      style={{ marginBottom: -3, width: 16, height: 16 }}
    />
  ) : (
    <UnCheckedIcon
      style={{ marginBottom: -3, width: 16, height: 16 }}
      color="disabled"
    />
  );

const BooleanTypeProvider = props => (
  <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />
);

const RangeFormatter = ({ value }) =>
  value ? `${value[0]}-${value[1] ? value[1] : ""}` : "";

const RangeTypeProvider = props => (
  <DataTypeProvider formatterComponent={RangeFormatter} {...props} />
);
class DataTableDevExpress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grouping: [],
      selection: [],
      selectedRow: null,
      dates: this.defaultRange() //dateHelper.getRange("today") //"today"
    };
    window.accountingSelectedRow = null;
  }

  changeGrouping = grouping => {
    this.setState({ grouping });
  };

  columnIsGrouped = columnName => {
    const { grouping } = this.state;

    let grouped = grouping.map(group => group.columnName);
    return grouped.indexOf(columnName) >= 0;
  };

  defaultRange = () => {
    const defaultRange = dateHelper.getRange("today");
    const storageRange = dateHelper.datesToRange(
      JSON.parse(sessionStorage.getItem("accounting-range"))
    );
    return storageRange || defaultRange;
  };

  changeDates = (dates, range) => {
    this.setState({ dates });
    this.props.fetchData({
      from: dates.start.format("YYYY-MM-DD"),
      to: dates.end.format("YYYY-MM-DD")
    });
    if (dates) {
      sessionStorage.setItem(
        "accounting-range",
        JSON.stringify(dateHelper.rangeToDates(dates))
      );
    } else {
      sessionStorage.removeItem("accounting-range");
    }
  };

  changeGroup = (columnName, grouped) => {
    let grouping = this.state.grouping.slice();

    const isGrouped = this.columnIsGrouped(columnName);

    //Csoportosítás
    if (grouped) {
      //Ha nincs csoportosítva, hozzáadjuk az oszlopot
      if (!isGrouped) {
        grouping.unshift({ columnName });
      }
    } else {
      //Ha csoportosítva van eltávolítjuk az adott oszlop csoportosítását
      if (isGrouped) {
        for (let i in grouping) {
          const group = grouping[i];
          if (group.columnName === columnName) {
            grouping.splice(i, 1);
          }
        }
      }
    }

    this.setState({ grouping });
  };

  onSelectionChange = selection => {
    console.log(selection);
    const { data } = this.props;

    let newSelection = parseInt(arr_diff(selection, this.state.selection)[0]);
    if (newSelection === this.state.selection[0]) {
      newSelection = [];
      window.accountingSelectedRow = null;
      this.setState({ selection: [], selectedRow: null });
    } else {
      window.accountingSelectedRow = data[newSelection];
      this.setState({
        selection: [newSelection],
        selectedRow: data[newSelection]
      });
    }
  };

  render() {
    const { classes, ...formProps } = this.props;

    const {
      data,
      editItem,
      onEdit,
      onEndEdit,
      onDelete,
      onCreate,
      title: titleProp,
      filterable,
      optionsError,
      error,
      readOnly,
      gridOptionsKey,
      detailsComponent: DetailsComponent,
      showRefreshIcon,
      fetchData,
      loading,
      customContainer,
      alwaysShowDetailsComponent,
      columnsFormat,
      summaryItems,
      defaultColumnWidths,
      dateRangePickerProps,
      toolbarItems
    } = this.props;

    const smUp = window.matchMedia("(min-width:600px)").matches;

    const RowDetail = ({ row }) => (
      <div
        style={{
          backgroundColor: "#e3f2fd",
          marginTop: -4,
          marginBottom: -4,
          marginLeft: -24,
          marginRight: -24,
          paddingLeft: 8,
          paddingRight: 8,
          maxWidth: 800,
          overflowX: "auto"
        }}
      >
        <DetailsComponent row={row} />
      </div>
    );

    const { grouping, dates, selectedRow, selection } = this.state;
    const grid = this.props.options ? this.props.options[gridOptionsKey] : null;
    //console.log("DataTable.render", this.props);
    if (!grid) {
      return <Loader />; //<CircularProgress size={24} />;
    }
    const title =
      typeof titleProp === "function" ? titleProp(this.props) : titleProp;

    let visibleColumns = Object.keys(grid.columns).filter(key => {
      const col = grid.columns[key];
      if (col.hidden) {
        return false;
      }
      return true;
    });
    //visibleColumns.push("id");
    const columns = visibleColumns.map((key, index) => {
      const col = grid.columns[key];
      const label = col.name || key;
      return {
        name: key,
        title: label
      };
    });
    const groupableColumns = visibleColumns.filter(key => {
      const col = grid.columns[key];
      if (col.groupable) {
        return true;
      }
      return false;
    });
    /*.map((key, index) => {
        return {
          columnName: key
        };
      });*/
    const defColumnWidths = visibleColumns.map((key, index) => {
      return {
        columnName: key,
        width: defaultColumnWidths[key] || 80
      };
    });
    /*const defaultColumnWidths = [
      {columnName: "username", width: 120},
      {columnName: "email", width: 120},
      {columnName: "real_name", width: 120},
      {columnName: "role", width: 120},
      {columnName: "card_number", width: 120},
      {columnName: "phone", width: 120},
      {columnName: "sealnumber_of_cashregister", width: 120},
      {columnName: "distr_company_name", width: 120},
      {columnName: "created_at", width: 120}
    ];*/
    //console.log(columns, defaultColumnWidths);
    if (error || optionsError) {
      return (
        <div>
          {optionsError && <SyntaxHighlight json={optionsError} />}
          {error && <SyntaxHighlight json={error} />}
        </div>
      );
    }

    //const currencyColumns = ["amount"];
    const currencyColumns = visibleColumns.filter(key => {
      const format = columnsFormat[key];
      return format === "Ft";
    });

    const currencyEurColumns = visibleColumns.filter(key => {
      const format = columnsFormat[key];
      return format === "Eur";
    });

    const boolColumns = visibleColumns.filter(key => {
      const format = columnsFormat[key];
      return format === "bool";
    });

    const rangeColumns = visibleColumns.filter(key => {
      const format = columnsFormat[key];
      return format === "range";
    });

    if (columns.length === 0) {
      return <Loader />;
    }

    const getTotalSummaryValues = () => {
      const selectionSet = new Set(selection);
      /*const selectedRows = data.filter((row, rowIndex) =>
        selectionSet.has(rowIndex)
      );*/
      return summaryItems.map(summary => {
        const { columnName, type } = summary;
        return IntegratedSummary.defaultCalculator(
          type,
          data,
          row => row[columnName]
        );
      });
    };

    return (
      <Grid
        //key="fos"
        rows={data}
        columns={columns}
        //key={selectedRow ? selectedRow.index : -1}
      >
        <CurrencyTypeProvider for={currencyColumns} />
        <CurrencyEurTypeProvider for={currencyEurColumns} />
        <BooleanTypeProvider for={boolColumns} />
        <RangeTypeProvider for={rangeColumns} />
        <SummaryState
          totalItems={summaryItems}
          groupItems={summaryItems}
          //treeItems={treeSummaryItems}
        />
        <DragDropProvider />
        <GroupingState
          //defaultGrouping={groupableColumns}
          grouping={grouping}
          onGroupingChange={this.changeGrouping}
        />
        {DetailsComponent && (!smUp || alwaysShowDetailsComponent) && (
          <RowDetailState />
        )}
        <IntegratedGrouping />
        <SearchState />
        {smUp && (
          <SelectionState
            //selection={selectedRow ? [selectedRow.index] : []}
            selection={selection}
            onSelectionChange={this.onSelectionChange}
          />
        )}
        <IntegratedFiltering />
        <IntegratedSummary />
        {/*<Table messages={tableMessages} cellComponent={Cell} />*/}
        <VirtualTable
          height={this.props.height || 400}
          messages={tableMessages}
          {...(smUp && customContainer
            ? { containerComponent: Container }
            : {})}
          cellComponent={props => {
            return (
              <VirtualTable.Cell
                {...props}
                style={{ ...props.style, paddingTop: 0, paddingBottom: 0 }}
              />
            );
          }}
          groupCellComponent={props => {
            return (
              <VirtualTable.Cell
                {...props}
                style={{ ...props.style, paddingTop: 0, paddingBottom: 0 }}
              />
            );
          }}
          tableComponent={TableRow}
          estimatedRowHeight={20}
        />

        <TableColumnResizing defaultColumnWidths={defColumnWidths} />
        <TableHeaderRow rowComponent={HeaderRowComponent} />
        {smUp && (
          <TableSelection
            selectByRowClick
            highlightRow
            showSelectionColumn={false}
          />
        )}
        <TableGroupRow
          //cellComponent={GroupCell}
          contentComponent={props => {
            return (
              <TableGroupRow.Content
                {...props}
                style={{
                  ...props.style,
                  /*paddingTop: 0,
                  paddingBottom: 0,
                  overflowY: "hidden",
                  height: 20,
                  lineHeight: "18px"*/
                  backgroundColor: "#fffde7"
                }}
              />
            );
          }}
          cellComponent={props => {
            return (
              <TableGroupRow.Cell
                {...props}
                style={{
                  ...props.style,
                  paddingTop: 0,
                  paddingBottom: 0,
                  //overflowY: "hidden",
                  //height: 19,
                  //margin: 0,
                  //padding: 0,
                  lineHeight: "18px"
                }}
              />
            );
          }}
          iconComponent={TableGroupToggleIcon}
        />
        <GroupSummaryRow />
        {DetailsComponent && (!smUp || alwaysShowDetailsComponent) && (
          <TableRowDetail
            rowHeight={16}
            contentComponent={RowDetail}
            /*toggleCellComponent={props => {
              return <TableRowDetail.ToggleCell {...props} />;
            }}*/
            toggleCellComponent={TableDetailToggleCell}
          />
        )}
        <TableColumnVisibility defaultHiddenColumnNames={["date"]} />
        <Toolbar />
        {/*<ColumnChooser />*/}
        <GroupingPanel showGroupingControls messages={groupingPanelMessages} />
        <SearchPanel messages={searchMessages} inputComponent={SearchInput} />
        <CustomToolbarMarkup
          changeGroup={this.changeGroup}
          changeDates={this.changeDates}
          dates={dates}
          grouping={grouping}
          groupableColumns={groupableColumns}
          fecthData={fetchData}
          showRefreshIcon={showRefreshIcon}
          loading={loading}
          dateRangePickerProps={dateRangePickerProps}
          toolbarItems={toolbarItems}
        />
        {/*<CustomSummary totalValues={getTotalSummaryValues()} />*/}
        <TableSummaryRow
          messages={summaryMessages}
          itemComponent={props => {
            return (
              <div
                //{...props}
                style={{ color: "black", fontWeight: "bold" }}
                /*a={console.log(
                  "simItem",
                  props,
                  props.children.type({ value: props.value })
                )}*/
              >
                {props.children.type({ value: props.value })}
                {/*formatHelper.currenyFormat(props.value)*/}
              </div>
            );
          }}
          totalCellComponent={TotalCell}
          groupCellComponent={props => {
            return (
              <TableSummaryRow.GroupCell
                {...props}
                style={{
                  ...props.style,
                  paddingTop: 0,
                  paddingBottom: 0,
                  //overflowY: "hidden",
                  height: 20,
                  //margin: 0,
                  //padding: 0,
                  fontWeight: "bold",
                  lineHeight: "18px"
                }}
              />
            );
          }}
          //formatlessSummaryTypes={["amount"]}
          /*totalCellComponent={props => {
            return (
              <TableSummaryRow.TotalCell
                {...props}
                style={{ ...props.style, backgroundColor: "red" }}
              />
            );
          }}*/
          totalRowComponent={props => {
            return (
              <TableSummaryRow.TotalRow
                {...props}
                style={{ ...props.style, height: "unset" }}
              />
            );
          }}
        />
      </Grid>
    );
  }
}

DataTableDevExpress.defaultProps = {
  filterable: true,
  readOnly: false,
  gridOptionsKey: "grid",
  showRefreshIcon: true,
  customContainer: false,
  alwaysShowDetailsComponent: false,
  columnsFormat: {},
  summaryItems: [],
  defaultColumnWidths: {}
};

DataTableDevExpress.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  filterable: PropTypes.bool,
  fetchData: PropTypes.func,
  readOnly: PropTypes.bool,
  showRefreshIcon: PropTypes.bool,
  customContainer: PropTypes.bool,
  alwaysShowDetailsComponent: PropTypes.bool,
  gridOptionsKey: PropTypes.string,
  detailsComponent: PropTypes.func,
  columnsFormat: PropTypes.object,
  summaryItems: PropTypes.array,
  defaultColumnWidths: PropTypes.object,
  dateRangePickerProps: PropTypes.object
};

//export default withStyles(styles)(DataTableDevExpress);
export default DataTableDevExpress;
