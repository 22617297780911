import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as worksheetsActions from "../../../../redux/modules/worksheets";
import * as optionsActions from "../../../../redux/modules/options";
import WorksheetStatistic from "./views/WorksheetStatistic";

const mapStateToProps = state => {
    return {
        loading: state.worksheets.loading,
        success: state.worksheets.success,
        message: state.worksheets.message,
        data: state.worksheets.data,
        total: state.worksheets.total,
        limit: state.worksheets.limit,
        page: state.worksheets.page,
        order: state.worksheets.order,
        orderMode: state.worksheets.orderMode,
        deleted: state.worksheets.deleted,
        deletedId: state.worksheets.deletedId,

        headerLoaded: state.options.headerLoaded,
        columns: state.options.columns,
        translate: state.options.translate,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getListHeader: optionsActions.getSgridInfo,
            optionsReset: optionsActions.reset,

            getStatistic: worksheetsActions.worksheetsStatistic
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorksheetStatistic);
