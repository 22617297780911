import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import { Toolbar } from "@material-ui/core";
import Price from "./Price";
import StockInfoList from "./StockInfoList";
import AddToCart from "./AddToCart";
import Image from "Components/New/Image";
import Tags from "./Tags";
import ProductLink from "./ProductLink";
import { red } from "@material-ui/core/colors";
import authrules from "helpers/authRules";
import { SHOP_ADMIN_PRODUCTS_PERMISSION } from "constans/permissions";

const styles = theme => ({
  paper: {
    paddingRight: theme.spacing.unit * 2,
    position: "relative"
  },
  img: {
    height: 90,
    width: 90,
    objectFit: "contain",
    marginRight: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2
  },
  texts: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    position: "relative"
    //alignSelf: "stretch"
  },
  addToCartInput: {
    width: 40,
    marginLeft: theme.spacing.unit * 2,
    "& input": {
      textAlign: "center"
    }
  },
  addToCartButton: {},
  price: {
    marginLeft: "auto",
    textAlign: "right"
  },
  stockInfo: {
    alignSelf: "flex-start",
    marginTop: theme.spacing.unit * 2 + 3
  },
  tags: {},
  avatar: {
    backgroundColor: red[500],
    marginRight: theme.spacing.unit * 2
  }
});

class ListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: 1
    };
  }

  onChangeQty = qty => {
    this.setState({ qty });
  };

  gotoDetails = () => {};

  render() {
    const { classes, item } = this.props;
    const { qty } = this.state;
    const url = `${process.env.REACT_APP_HOST}/v1/files/download/${
      item.image_id
    }?accessToken=${localStorage
      .getItem("access_token")
      .replace("Bearer ", "")}`;

    //const isAdmin = authrules.isRoot;
    return (
      <Paper className={classes.paper} onClick={this.gotoDetails}>
        <Toolbar disableGutters>
          <StockInfoList
            stockInfo={item.stock_info}
            stockInfoText={item.stock_info_text}
          />
          <ProductLink id={item.id}>
            <Image
              onContextMenu={e => {
                e.preventDefault();
                return false;
              }}
              className={classes.img}
              fileId={item.image_id}
            />
          </ProductLink>
          {/*<Avatar aria-label="recipe" className={classes.avatar}>
            %
    </Avatar>*/}
          <div className={classes.texts}>
            <ProductLink id={item.id}>
              <Typography variant="subtitle1">{item.name}</Typography>
              {/*<Typography variant="body1">{item.sku}</Typography>*/}
            </ProductLink>
            <Tags rootClassName={classes.tags} tags={item.tags} />
          </div>
          <Price prices={item.prices} qty={qty} rootClassName={classes.price} />
          <AddToCart
            qty={qty}
            onChangeQty={this.onChangeQty}
            id={item.id}
            inputClassName={classes.addToCartInput}
            buttonClassName={classes.addToCartButton}
          />
          {authrules.canModifyByKey(SHOP_ADMIN_PRODUCTS_PERMISSION) && (
            <ProductLink id={item.id} isEdit>
              <IconButton aria-label="settings">
                <EditIcon />
              </IconButton>
            </ProductLink>
          )}
        </Toolbar>
      </Paper>
    );
  }
}

ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired
};

export default withStyles(styles)(ListItem);
