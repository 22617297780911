import axios from "axios";
import qs from "qs";
import { get } from "lodash";
import * as fcmActions from "./fcm";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
const NOTIFICATIONS_LIST_LOAD = "NOTIFICATIONS_LIST_LOAD";
const NOTIFICATIONS_LIST_SUCCESS = "NOTIFICATIONS_LIST_SUCCESS";
const NOTIFICATIONS_LIST_FAILED = "NOTIFICATIONS_LIST_FAILED";
const NOTIFICATIONS_SET_LOADING = "NOTIFICATIONS_SET_LOADING";

const NOTIFICATIONS_FORM_LOAD = "NOTIFICATIONS_FORM_LOAD";
const NOTIFICATIONS_FORM_SUCCESS = "NOTIFICATIONS_FORM_SUCCESS";
const NOTIFICATIONS_FORM_FAILED = "NOTIFICATIONS_FORM_FAILED";

const NOTIFICATION_DELETE_LOAD = "NOTIFICATION_DELETE_LOAD";
const NOTIFICATION_DELETE_SUCCESS = "NOTIFICATION_DELETE_SUCCESS";
const NOTIFICATION_DELETE_FAILED = "NOTIFICATION_DELETE_FAILED";

const NOTIFICATION_EDIT_LOAD = "NOTIFICATION_EDIT_LOAD";
const NOTIFICATION_EDIT_SUCCESS = "NOTIFICATION_EDIT_SUCCESS";
const NOTIFICATION_EDIT_FAILED = "NOTIFICATION_EDIT_FAILED";

const NOTIFICATION_VIEW_LOAD = "NOTIFICATION_VIEW_LOAD";
const NOTIFICATION_VIEW_SUCCESS = "NOTIFICATION_VIEW_SUCCESS";
const NOTIFICATION_VIEW_FAILED = "NOTIFICATION_VIEW_FAILED";

const NOTIFICATIONS_RESET = "NOTIFICATIONS_RESET";
const NOTIFICATIONS_RESET_FORM = "NOTIFICATIONS_RESET_FORM";
const NOTIFICATIONS_PANEL_LIST_LOAD = "NOTIFICATIONS_PANEL_LIST_LOAD";
const NOTIFICATIONS_PANEL_LIST_SUCCESS = "NOTIFICATIONS_PANEL_LIST_SUCCESS";
const NOTIFICATIONS_PANEL_LIST_RELOAD = "NOTIFICATIONS_PANEL_LIST_RELOAD";
const NOTIFICATIONS_PANEL_LIST_FAILED = "NOTIFICATIONS_PANEL_LIST_FAILED";

const NOTIFICATIONS_POPUP_LIST_LOAD = "NOTIFICATIONS_POPUP_LIST_LOAD";
const NOTIFICATIONS_POPUP_LIST_SUCCESS = "NOTIFICATIONS_POPUP_LIST_SUCCESS";
const NOTIFICATIONS_POPUP_LIST_FAILED = "NOTIFICATIONS_POPUP_LIST_FAILED";
const NOTIFICATIONS_POPUP_LIST_CLEAR = "NOTIFICATIONS_POPUP_LIST_CLEAR";

const NOTIFICATIONS_PANEL_LIST_ITEM_READ_SUCCESS =
  "NOTIFICATIONS_PANEL_LIST_ITEM_READ_SUCCESS";
const NOTIFICATIONS_PANEL_LIST_COUNT_SUCCESS =
  "NOTIFICATIONS_PANEL_LIST_COUNT_SUCCESS";

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit: process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: "",
  orderMode: "ASC",
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null,
  reload: false,

  panel_loading: false,
  panel_success: false,
  panel_failed: false,
  panel_list: [],
  panel_list_count: 0,
  panel_message: null,

  popup_loading: false,
  popup_success: false,
  popup_failed: false,
  popup_list: [],
  popup_message: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_RESET:
      return {
        ...initialState
      };
    case NOTIFICATIONS_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case NOTIFICATION_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case NOTIFICATIONS_PANEL_LIST_LOAD:
      return {
        ...state,
        panel_loading: true,
        panel_failed: false,
        panel_message: null,
        reload: false,
        loading: true
      };
    case NOTIFICATIONS_POPUP_LIST_LOAD:
      return {
        ...state,
        popup_loading: true,
        popup_failed: false,
        popup_message: null
      };
    case NOTIFICATIONS_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false
      };
    case NOTIFICATION_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case NOTIFICATION_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case NOTIFICATIONS_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case NOTIFICATIONS_FORM_FAILED:
    case NOTIFICATION_EDIT_FAILED:
    case NOTIFICATION_DELETE_FAILED:
    case NOTIFICATIONS_LIST_FAILED:
    case NOTIFICATION_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false
      };
    case NOTIFICATIONS_PANEL_LIST_FAILED:
      return {
        ...state,
        panel_loading: false,
        panel_success: false,
        panel_failed: true,
        panel_message: action.message,
        message: action.message,
        loading: false
      };
    case NOTIFICATIONS_POPUP_LIST_FAILED:
      return {
        ...state,
        popup_loading: false,
        popup_success: false,
        popup_failed: true,
        popup_message: action.message
      };
    case NOTIFICATIONS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode
      };
    case NOTIFICATIONS_PANEL_LIST_SUCCESS:
      return {
        ...state,
        panel_success: true,
        panel_failed: false,
        panel_loading: false,
        panel_list: action.data,
        loading: false
      };
    case NOTIFICATIONS_POPUP_LIST_SUCCESS:
      return {
        ...state,
        popup_success: true,
        popup_failed: false,
        popup_loading: false,
        popup_list: action.data
      };
    case NOTIFICATIONS_POPUP_LIST_CLEAR:
      return {
        ...state,
        popup_list: []
      };
    case NOTIFICATIONS_PANEL_LIST_COUNT_SUCCESS:
      return {
        ...state,
        panel_list_count: action.count
      };
    case NOTIFICATIONS_PANEL_LIST_ITEM_READ_SUCCESS:
      return {
        ...state,
        panel_list_count: state.panel_list_count + action.delta,
        panel_loading: false
      };
    case NOTIFICATIONS_PANEL_LIST_RELOAD:
      return {
        ...state,
        panel_success: true,
        panel_failed: false,
        panel_loading: false,
        panel_list: action.data,
        reload: true,
        loading: false
      };
    case NOTIFICATION_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        viewData: action.viewData,
        commentThread: action.commentThread || false,
        viewLoading: false
      };
    case NOTIFICATIONS_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case NOTIFICATION_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case NOTIFICATION_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: action.id,
        viewLoading: false
      };
    case NOTIFICATIONS_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: NOTIFICATIONS_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: NOTIFICATIONS_RESET_FORM
  });
};

export const updateNotifications = (model, id, data) => dispatch => {
  dispatch({
    type: NOTIFICATION_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .put("/v1/notifications/" + id, obj)
    .then(() =>
      dispatch({
        type: NOTIFICATION_EDIT_SUCCESS
      })
    )
    .catch(error => {
      dispatch({
        type: NOTIFICATION_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
    });
};

export const viewNotifications = id => dispatch => {
  dispatch({
    type: NOTIFICATION_VIEW_LOAD
  });
  return axios
    .get("/v1/notifications/" + id)
    .then(response =>
      dispatch({
        type: NOTIFICATION_VIEW_SUCCESS,
        viewData: get(response, "data.data"),
        commentThread: get(response, "data.commentThread", false)
      })
    )
    .catch(error => {
      dispatch({
        type: NOTIFICATION_VIEW_FAILED,
        message: get(error, "response.data.message", null)
      });
    });
};

export const createNotifications = (model, data) => dispatch => {
  dispatch({
    type: NOTIFICATION_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .post("/v1/notifications", obj)
    .then(response => {
      dispatch({
        type: NOTIFICATION_EDIT_SUCCESS,
        id: get(response, "data.data.id", null)
      });
      globalMessage.success("Sikeres küldés");
      return response;
    })
    .catch(error => {
      dispatch({
        type: NOTIFICATION_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const notificationDelete = id => dispatch => {
  dispatch({
    type: NOTIFICATION_DELETE_LOAD
  });
  return axios
    .delete("/v1/notifications/" + id)
    .then(response =>
      dispatch({
        type: NOTIFICATION_DELETE_SUCCESS,
        id
      })
    )
    .catch(error =>
      dispatch({
        type: NOTIFICATION_DELETE_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: NOTIFICATIONS_SET_LOADING,
    loading
  });
};

export const notificationsList = (
  page,
  keyword,
  sort,
  filters,
  perPage
) => dispatch => {
  dispatch({
    type: NOTIFICATIONS_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    "per-page": perPage
  };

  params[filters.model] = filters.filters;

  return axios
    .get(
      "/v1/notifications",
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then(response => {
      let sort = get(response, "data.sort", null),
        mode = "ASC";
      if (sort && sort.indexOf("-") !== -1) {
        mode = "DESC";
        sort = sort.substr(1);
      }
      return dispatch({
        type: NOTIFICATIONS_LIST_SUCCESS,
        data: get(response, "data.data", null),
        page: get(response, "data.page", 0),
        limit: get(response, "data.per-page", 0),
        total: get(response, "data.total", 0),
        order: sort,
        orderMode: mode
      });
    })
    .catch(error =>
      dispatch({
        type: NOTIFICATIONS_LIST_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};

export const panelReadItem = id => dispatch => {
  dispatch({
    type: NOTIFICATIONS_PANEL_LIST_LOAD
  });

  return axios
    .put("/v1/notifications/" + id)
    .then(() => {
      dispatch({
        type: NOTIFICATIONS_PANEL_LIST_ITEM_READ_SUCCESS,
        delta: 0 //ennyivel csökkentjük a panel_list_count értékét
      });
      dispatch(notificationsPanelList(false));
      dispatch(notificationsPanelListCount());
    })
    .catch(error => {
      dispatch({
        type: NOTIFICATIONS_PANEL_LIST_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const readItem = id => dispatch => {
  return axios
    .put("/v1/notifications/" + id)
    .then(() => {
      dispatch({
        type: NOTIFICATIONS_PANEL_LIST_ITEM_READ_SUCCESS,
        delta: 0 //ennyivel csökkentjük a panel_list_count értékét
      });
      //dispatch(notificationsPanelList(true, true));
      dispatch(notificationsPanelListCount());
    })
    .catch(error =>
      dispatch({
        type: NOTIFICATIONS_PANEL_LIST_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};

export const panelReadAll = () => dispatch => {
  dispatch({
    type: NOTIFICATIONS_PANEL_LIST_LOAD
  });

  return axios
    .put("/v1/notifications")
    .then(() => {
      //nullára állítjuk a panel_list_count értékét
      dispatch({
        type: NOTIFICATIONS_PANEL_LIST_COUNT_SUCCESS,
        count: 0
      });
      dispatch(notificationsPanelList(false));
    })
    .catch(error => {
      dispatch({
        type: NOTIFICATIONS_PANEL_LIST_FAILED,
        message: get(error, "response.data.message", null)
      });
    });
};

export const notificationsPanelList = isListPage => dispatch => {
  dispatch({
    type: NOTIFICATIONS_PANEL_LIST_LOAD
  });

  return axios
    .get("/v1/notifications/panel")
    .then(response => {
      let data = get(response, "data.data", []);

      if (/*typeof data === "object"*/ !Array.isArray(data)) {
        data = [];
      }
      return dispatch({
        type: isListPage
          ? NOTIFICATIONS_PANEL_LIST_RELOAD
          : NOTIFICATIONS_PANEL_LIST_SUCCESS,
        data
      });
    })
    .catch(error =>
      dispatch({
        type: NOTIFICATIONS_PANEL_LIST_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};

export const notificationsPopupListclear = () => dispatch => {
  dispatch({
    type: NOTIFICATIONS_POPUP_LIST_CLEAR
  });
};
export const notificationsPopupList = () => dispatch => {
  dispatch({
    type: NOTIFICATIONS_POPUP_LIST_LOAD
  });

  return axios
    .get("/v1/notifications/popup")
    .then(response => {
      let data = get(response, "data.data", []);

      if (/*typeof data === "object"*/ !Array.isArray(data)) {
        data = [];
      }
      return dispatch({
        type: NOTIFICATIONS_POPUP_LIST_SUCCESS,
        data
      });
    })
    .catch(error =>
      dispatch({
        type: NOTIFICATIONS_POPUP_LIST_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};

export const notificationsPanelListCount = () => dispatch => {
  return axios
    .get("/v1/notifications/count")
    .then(response => {
      let count = parseInt(get(response, "data.data.count", 0));
      dispatch({
        type: NOTIFICATIONS_PANEL_LIST_COUNT_SUCCESS,
        count: count
      });
      return count;
    })
    .catch(error => {
      dispatch({
        type: NOTIFICATIONS_PANEL_LIST_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const subscribe = () => dispatch => {
  dispatch(
    fcmActions.subscribe("notifications", (/*payload*/) => {
      return dispatch(notificationsPanelListCount());
    })
  );
  dispatch(
    fcmActions.subscribe("notifications.popup", (/*payload*/) => {
      return dispatch(notificationsPopupList());
    })
  );
};
