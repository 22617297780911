import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
  BASE_PATH_CART,
  BASE_PATH_CART_CHECKOUT,
  BASE_PATH_CART_ORDER,
  BASE_PATH_CART_SUCCESS
} from "./../shopPath";
import localize from "helpers/localize";

const styles = theme => ({
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

const steps = ["Kosár", "Rendelés részletei", "Rendelés leadása"];
const stepIndexes = {
  [BASE_PATH_CART]: 0,
  [BASE_PATH_CART_CHECKOUT]: 1,
  [BASE_PATH_CART_ORDER]: 2,
  [BASE_PATH_CART_SUCCESS]: 3
};

class Steps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: stepIndexes[props.currentPath]
    };
  }
  gotoDetails = () => {};

  render() {
    const { classes, currentPath } = this.props;

    return (
      <Stepper activeStep={stepIndexes[currentPath]} /*alternativeLabel*/>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{localize("shop", label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }
}

Steps.propTypes = {
  classes: PropTypes.object.isRequired,
  currentPath: PropTypes.string.isRequired
};

export default withStyles(styles)(Steps);
