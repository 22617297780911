import React, { Component, Fragment } from "react";
//import path from 'path';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import { get } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastr } from "../../../helpers/CustomToastr";
import * as fileActions from "../../../redux/modules/file";

import localize from "helpers/localize";
import { FormGroup, Label } from "reactstrap";

class SimpleFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: props.files[0] || null,
      confirm: false,
      success: false,
      loading: false
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    if (this.state.file) {
      this.preloadImage();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      get(this.state, "file.url", false) !== get(prevState, "file.url", false)
    ) {
      if (get(this.state, "file.url", false)) {
        this.preloadImage();
      }
    }
    if (
      !get(this.state, "file.url", false) &&
      get(this.props, "files[0]", false) &&
      !get(prevProps, "files[0]", false)
    ) {
      this.setState({
        file: this.props.files[0] || null
      });
    }
  }

  showErrorMessage = message => {
    toastr.error(message);
  };

  handleUpload = event => {
    let file = event.target.files[0];
    this.props.upload(file).then(result => {
      if (result.type === "FILE_UPLOAD_SUCCESS") {
        file.id = result.id;
        this.handleFileChange(file);
        this.setState({
          file: {
            id: result.id,
            url: result.url
          }
        });
        // toastr.success(localize("basic", "Sikeres feltöltés"));
      } else {
        this.showErrorMessage(
          result.message || localize("basic", "Sikertelen művelet")
        );
      }
    });
  };

  handleRemove = () => {
    this.props.fileDelete(this.state.file.id, this.state.file).then(result => {
      if (result.type === "FILE_DELETE_SUCCESS") {
        this.props.removeFile(this.state.file);
        this.setState({
          file: null,
          confirm: false,
          success: true
        });
      } else {
        this.setState({
          confirm: false
        });
        this.showErrorMessage(
          result.message || localize("basic", "Sikertelen művelet")
        );
      }
    });
  };

  handleFileChange = file => {
    this.props.addedFile(file);
  };

  handleDownloadFile = () => {
    this.props.download(this.state.file.url, this.state.file.id);
  };

  preloadImage = () => {
    return fileActions.fetchFile(this.state.file.url).then(uint8Array => {
      if (uint8Array) {
        //const file = new File([uint8Array], path.basename(this.state.file.url));
        const ext = this.state.file.url
          .split(".")
          .pop()
          .toLowerCase();
        if (ext === "jpg" || ext === "png" || ext === "jpeg" || ext === "gif") {
          const raw = uint8Array.reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          );
          const b64 = btoa(raw);
          const dataURL = "data:image/" + ext + ";base64," + b64;
          let file = this.state.file;
          file.base64 = dataURL;
          this.setState({
            file
          });
        }
      }
    });
  };

  handleDeleteConfirm = () => {
    this.setState({
      confirm: true
    });
  };

  onCancelConfirm = () => {
    this.setState({
      confirm: false
    });
  };

  onSuccessClose = () => {
    this.setState({
      success: false
    });
  };

  onConfirm = () => {
    this.handleRemove();
  };

  render() {
    const { isViewPage, label } = this.props;

    return (
      <Fragment>
        {!isViewPage && (
          <div className="row">
            <SweetAlert
              show={this.state.confirm}
              warning
              showCancel
              showLoaderOnConfirm
              confirmBtnText={localize("basic", "Igen")}
              cancelButtonText={localize("basic", "Mégsem")}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              timer={false}
              title={localize("basic", "Biztosan törölni akarja?")}
              onCancel={this.onCancelConfirm.bind(this)}
              onConfirm={this.onConfirm.bind(this)}
            />
            <SweetAlert
              show={this.state.success}
              success
              title={localize("basic", "Sikeres művelet")}
              onConfirm={this.onSuccessClose.bind(this)}
            />
            <div className="col-md-3 col-sm-3 col-12 col-lg-3">
              <label className="label-control col-md-3">
                {this.props.label}
              </label>
              <div className="input-group mb-3">
                {this.state.loading ? (
                  <div className="text-center mt-2">
                    <CircularProgress />
                  </div>
                ) : (
                  <div
                    className={
                      this.state.file ? /*'text-center'*/ "" : "custom-file"
                    }
                  >
                    {this.state.file &&
                      this.state.file.base64 &&
                      !this.props.noImageView && (
                        <p>
                          <img
                            className="img-thumbnail cursor"
                            onClick={this.handleDownloadFile.bind(this)}
                            src={this.state.file.base64}
                            alt=""
                          />
                        </p>
                      )}
                    {this.state.file &&
                      (!this.state.file.base64 || this.props.noImageView) && (
                        <p
                          className="cursor form-control"
                          onClick={this.handleDownloadFile.bind(this)}
                        >
                          {this.state.file.id}
                        </p>
                      )}
                    {this.state.file &&
                      !this.props.isViewPage &&
                      !this.props.isUpdate && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={this.handleDeleteConfirm.bind(this)}
                        >
                          {localize("file", "Törlés")}
                        </Button>
                      )}
                    {!this.state.file && (
                      <Fragment>
                        <input
                          onChange={this.handleUpload.bind(this)}
                          type="file"
                          id={this.props.name + "_file"}
                          accept={this.props.acceptedFiles}
                          style={{ display: "none" }}
                        />
                        <label htmlFor={this.props.name + "_file"}>
                          <Button
                            variant="contained"
                            component="span"
                            color="secondary"
                          >
                            {localize("file", "Válasszon fájlt.")}
                          </Button>
                        </label>
                      </Fragment>
                    )}
                  </div>
                )}
              </div>
              {this.props.fieldError ? (
                <FormHelperText error={true}>
                  {this.props.fieldError}
                </FormHelperText>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        {isViewPage && (
          <FormGroup className="row">
            {label && <Label className="label-control col-md-5">{label}</Label>}
            <div className="controls">
              {this.state.file &&
                (!this.state.file.base64 || this.props.noImageView) && (
                  <span
                    className="cursor"
                    onClick={this.handleDownloadFile.bind(this)}
                  >
                    {this.state.file.id}
                  </span>
                )}
              {this.state.file &&
                this.state.file.base64 &&
                !this.props.noImageView && (
                  <span>
                    <img
                      className="img-thumbnail cursor"
                      onClick={this.handleDownloadFile.bind(this)}
                      src={this.state.file.base64}
                      alt=""
                    />
                  </span>
                )}
            </div>
          </FormGroup>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    success: state.file.success,
    file: state.file.file,
    id: state.file.id,
    url: state.file.url,
    loading: state.file.loading
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      upload: fileActions.upload,
      download: fileActions.download,
      fileDelete: fileActions.fileDelete
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleFile);
