import React, { PureComponent } from "react";

import Validations from "Components/Forms/Validations";
import { Field } from "redux-form";
import InputField from "Components/Forms/Fields/Input";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import SelectField from "Components/Forms/Fields/Select";
import Autocomplete from "Components/Forms/Fields/Autocomplete";
import { get } from "lodash";

class FeeItem extends PureComponent {
  onSelect = (ezmi, name, data) => {
    //console.log(ezmi, name, data);
    const { dispatch, change, collector } = this.props;
    if (data) {
      dispatch(change(`${collector}.price`, data.price));
    }
  };

  render() {
    const {
      isViewPage,
      fieldError,
      index,
      collector,
      fees,
      deleteCell,
      workfee_types,
      workfee_id,
      workfees,
      workfee //Options ből
    } = this.props;
    //console.log(this.props);
    return (
      <TableRow /*style={{ overflow: "visible" }}*/>
        <TableCell style={{ overflow: "visible" }}>
          {/*<Field
            name={`${collector}.workfee_id`}
            fieldError={fieldError.workfee_id}
            component={SelectField}
            isViewPage={isViewPage}
            validate={[Validations.required]}
            options={workfees}
            //viewShowKey
          />*/}
          {
            <Field
              name={`${collector}.name`}
              fieldError={fieldError.workfees}
              component={Autocomplete}
              isViewPage={isViewPage}
              validate={[Validations.required]}
              formName="worksheet"
              //onChange={() => handleInputChange("distributor_id")}
              onSelect={this.onSelect}
              mode="select"
              showInTable
              minChars={0} //{get(workfee, "minChars", 2)}
              loadOnFocus
              fillDefaultValue
              url={get(workfee, "url", null)}
              //change={this.props.change}
              labelKey="name"
              valueKey="name"
              //onSelect={this.handleDistributorSelect}
            />
          }
        </TableCell>
        <TableCell>
          <Field
            name={`${collector}.quantity`}
            fieldError={fieldError.quantity}
            component={InputField}
            isViewPage={isViewPage}
            validate={[Validations.required]}
            inputRootClass={"simpleImputRoot"}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${collector}.type`}
            fieldError={fieldError.type}
            component={SelectField}
            isViewPage={isViewPage}
            options={workfee_types}
            validate={[Validations.required]}
            inputRootClass={"simpleImputRoot"}
            //viewShowKey={}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${collector}.price`}
            fieldError={fieldError.price}
            component={InputField}
            isViewPage={isViewPage}
            validate={[Validations.required]}
            inputRootClass={"simpleImputRoot"}
          />
        </TableCell>
        {deleteCell(index)}
      </TableRow>
    );
  }
}

export default FeeItem;
