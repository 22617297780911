import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Typography,
  Toolbar,
  FormControlLabel
} from "@material-ui/core";
import ContinueIcon from "@material-ui/icons/ChevronRight";
import BackIcon from "@material-ui/icons/ChevronLeft";
import { Link } from "react-router-dom";
import TextField from "Components/New/Input/TextField/TextField";
import Select from "Components/New/Input/Select/Select";
import Hidden from "Components/New/Hidden";
import Checkbox from "Components/New/Input/Checkbox/Checkbox";
import SimpleArray2dArray from "Components/New/Input/SimpleArray2dArray/SimpleArray2dArray";
import localize from "helpers/localize";
const LC = "cloud"; //Localization category
/*import Tags from "Components/New/Input/Tags/Tags";
import Wysiwyg from "Components/New/Input/Wysiwyg/Wysiwyg";
import Image from "Components/New/Input/Image/Image";
import formatHelper from "helpers/formatHelper";*/

const styles = theme => ({});
const DownloadParaDataForm = ({
  fieldProps,
  onChange,
  onItemChange,
  editItem,
  onSave,
  changeStep
}) => {
  return (
    <Fragment>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextField {...fieldProps("notify_freq")} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {localize(LC, "Bejelentkezések")}
            </Typography>
          </Grid>
          <Grid item xs={6} md={7} sm={8}>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  {...fieldProps("sign_operation_day_open", "checkbox")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  {...fieldProps("sign_operation_day_close", "checkbox")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  {...fieldProps(
                    "sign_operation_fiscal_receipt_issue",
                    "checkbox"
                  )}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  {...fieldProps(
                    "sign_operation_storno_or_return_receipt",
                    "checkbox"
                  )}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  {...fieldProps("sign_operation_service_done", "checkbox")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  {...fieldProps("sign_operation_aee_block", "checkbox")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  {...fieldProps("sign_operation_ecr_block", "checkbox")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  {...fieldProps(
                    "sign_operation_sign_server_manually",
                    "checkbox"
                  )}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextField {...fieldProps("advertise")} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={8} md={6}>
            <SimpleArray2dArray
              {...fieldProps("footer", "simple-2d-array")}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("ft_euro_rate")} fullWidth />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} /*sm={6} md={4} lg={3}*/>
        <Checkbox {...fieldProps("exchange_mode", "checkbox")} fullWidth />
      </Grid>
      <Hidden visible={editItem.exchange_mode}>
        <Grid item xs={12}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {localize(LC, "Pénzváltási beállítások")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={16}>
                {/*<Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Pénzváltási beállítások
                  </Typography>
              </Grid>*/}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField {...fieldProps("ex_collector")} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField {...fieldProps("ex_bank_name")} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField {...fieldProps("ex_bank_check")} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField {...fieldProps("ex_fee_percent")} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Checkbox
                    {...fieldProps("ex_converFlag", "checkbox")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Checkbox
                    {...fieldProps("currency_clear", "checkbox")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <SimpleArray2dArray
                {...fieldProps("currency_para", "simple-2d-array")}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </Fragment>
  );
};
DownloadParaDataForm.defaultProps = {};

DownloadParaDataForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  fieldProps: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  changeStep: PropTypes.func,
  editItem: PropTypes.object
};

export default withStyles(styles, { withTheme: true })(DownloadParaDataForm);
