import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import formatHelper from "helpers/formatHelper";
import localize from "helpers/localize";
const styles = theme => ({
  nextPrice: {
    //fontSize: "10px"
    fontStyle: "italic"
  }
});

class Price extends React.Component {
  getPrices = () => {
    const { prices, qty: baseQty } = this.props;
    const qty = !baseQty || baseQty === "" || isNaN(baseQty) ? 0 : baseQty;
    let priceIndex = 0;
    let nextPriceIndex = 0;

    for (let i in prices) {
      if (prices[i].from > qty) {
        nextPriceIndex = i;
        break;
      } else {
        priceIndex = i;
      }
    }
    return {
      current: prices ? prices[priceIndex] : null,
      next: nextPriceIndex > 0 && prices ? prices[nextPriceIndex] : null
    };
  };

  render() {
    const { classes, rootClassName, showAllPrices } = this.props;
    const prices = this.getPrices();
    return showAllPrices ? (
      <div className={rootClassName}>
        {this.props.prices &&
          Array.isArray(this.props.prices) &&
          this.props.prices.map((price, index) => {
            return price.price === prices.current.price ? (
              <Typography variant="subtitle2" noWrap>
                {price.price && formatHelper.currency(price.price)} + Áfa
              </Typography>
            ) : (
              <Typography variant="body2" className={classes.nextPrice}>
                {price.from == 0 ? "1" : price.from}{" "}
                {localize("shop", "darabtól")}{" "}
                {formatHelper.currency(price.price)} + {localize("shop", "Áfa")}
              </Typography>
            );
          })}
      </div>
    ) : (
      <div className={rootClassName}>
        <Typography variant="subtitle2" noWrap>
          {prices.current && formatHelper.currency(prices.current.price)} +{" "}
          {localize("shop", "Áfa")}
        </Typography>
        {prices.next && (
          <Typography variant="body2" className={classes.nextPrice}>
            {prices.next.from} {localize("shop", "darabtól")}{" "}
            {formatHelper.currency(prices.next.price)} +{" "}
            {localize("shop", "Áfa")}
          </Typography>
        )}
      </div>
    );
  }
}

Price.propTypes = {
  classes: PropTypes.object.isRequired,
  prices: PropTypes.array,
  qty: PropTypes.number,
  rootClassName: PropTypes.string
};

export default withStyles(styles)(Price);
