import React, { Component, Fragment } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import DropDown from "../../List/Filters/DropDown";
import Text from "../../List/Filters/Text";
import Autocomplete from "../../List/Filters/Autocomplete";
import DateRange from "../../List/Filters/DateRange";

import localize from "helpers/localize";
class Header extends Component {
  handleFilter = (field, value) => {
    this.props.handleColumnFilterChange(field, value);
  };

  render() {
    return (
      <TableHead>
        <TableRow>
          {this.props.header.map((row, index) => (
            <TableCell key={"thead-" + index}>
              <TableSortLabel
                active={this.props.order === row.uriName}
                direction={this.props.orderMode.toLowerCase()}
                onClick={this.props.handleSort.bind(
                  this,
                  row.uriName,
                  this.props.orderMode === "ASC" ? "DESC" : "ASC"
                )}
              >
                {localize(this.props.translate, row.name)}
              </TableSortLabel>
            </TableCell>
          ))}
          {!this.props.disabledTools && <TableCell className="tools" />}
        </TableRow>
        <TableRow>
          {this.props.header.map((row, index) => (
            <TableCell
              key={"thead-filter-" + index}
              className={
                row.filter && row.filter.type === "autocomplete"
                  ? "autocompleteFilter"
                  : ""
              }
            >
              {row.filter && row.filter.type === "dropdown" && (
                <DropDown
                  options={row.filter.data}
                  name={row.uriName}
                  onChange={this.props.handleColumnFilterChange}
                  value={this.props.filterValues[row._key]}
                />
              )}
              {row.filter && row.filter.type === "text" && (
                <Text
                  name={row.uriName}
                  onChange={this.handleFilter.bind(this)}
                  value={this.props.filterValues[row._key]}
                />
              )}
              {row.filter && row.filter.type === "autocomplete" && (
                <Autocomplete
                  name={row.uriName}
                  minChars={row.filter.minChars}
                  url={row.filter.url}
                  onChange={this.handleFilter.bind(this)}
                  value={this.props.filterValues[row._key]}
                />
              )}
              {row.filter && row.filter.type === "daterange" && (
                <DateRange
                  name={row.uriName}
                  onChange={this.handleFilter.bind(this)}
                  value={this.props.filterValues[row._key]}
                  label={localize(this.props.translate, row.name)}
                />
              )}
            </TableCell>
          ))}
          {!this.props.disabledTools && <TableCell className="tools" />}
        </TableRow>
      </TableHead>
    );
  }
}

export default Header;
