const inputStyle = theme => ({
  "@global": {
    ".rbt-input-hint": {
      //backgroundColor: "red!important",
      width: "100%",
      top: "2px!important"
    },
    ".rbt-menu.dropdown-menu.show": {
      zIndex: 2000
      //position: "block"
    }
  },
  root: {
    //margin: theme.spacing.unit
    //marginTop: theme.spacing.unit,
    //marginBottom: theme.spacing.unit
  },
  bootstrapRoot: {
    "label + &": {},
    width: "100%",
    marginTop: theme.spacing.unit * 2 + 2 + "px!important",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    "&:before": {
      borderBottom: "none!important"
    },
    "&:after": {
      borderBottom: "none!important"
    },
    "& > fieldset": {
      border: "none"
    }
  },
  simpleImputRoot: {
    marginTop: "0px!important",
    "& .form-group": {
      marginLeft: 0,
      marginRight: 0
    }
  },
  bootstrapSelectRoot: {
    "label + &": {},
    width: "100%",
    paddingRight: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2 + 2 + "px!important",
    "&:before": {
      borderBottom: "none!important"
    },
    "&:after": {
      borderBottom: "none!important"
    }
  },
  bootstrapSelectRootNoLabel: {
    "label + &": {},
    width: "100%",
    paddingRight: theme.spacing.unit,
    "&:before": {
      borderBottom: "none!important"
    },
    "&:after": {
      borderBottom: "none!important"
    }
  },
  bootstrapRootBefore: {
    marginTop: "0px!important",
    display: "inline-block"
  },
  bootstrapInput: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid " + theme.palette.grey[500],
    fontSize: 14,
    width: "100%",
    padding: "5px 6px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    },
    "&:before": {
      borderBottom: "none!important"
    },
    "&:after": {
      borderBottom: "none!important"
    }
  },
  bootstrapColorInput: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid " + theme.palette.grey[500],
    fontSize: 14,
    minHeight: 31,
    width: "100%",
    padding: "4px 6px",
    cursor: "pointer",
    //transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  },
  tableFieldDisabled: {
    "& td, th": {
      color: "rgba(0, 0, 0, 0.38)!important"
    }
  },
  bootstrapDisabledInput: {
    borderRadius: 4,
    position: "relative",
    color: "rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.common.white,
    border: "1px solid " + theme.palette.grey[500],
    fontSize: 14,
    width: "100%",
    padding: "5px 6px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    },
    "&:before": {
      borderBottom: "none!important"
    },
    "&:after": {
      borderBottom: "none!important"
    }
  },
  bootstrapTypheadInput: {
    "& > ul": {
      width: "auto!important"
    },
    "& input.rbt-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      border: "1px solid " + theme.palette.grey[500],
      fontSize: 14,
      width: "100%",
      lineHeight: "19px",
      padding: "5px 6px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
      },
      "&:before": {
        borderBottom: "none!important"
      },
      "&:after": {
        borderBottom: "none!important"
      }
    }
  },
  bootstrapFormLabel: {
    fontSize: 18,
    marginBottom: theme.spacing.unit,
    color: theme.palette.text.primary
  },
  bootstrapAutocompleteLabel: {
    fontSize: 14,
    marginBottom: theme.spacing.unit / 2,
    color: theme.palette.text.primary
  },
  bootstrapTagInputLabel: {
    fontSize: 14,
    color: theme.palette.text.primary,
    lineHeight: 0.9,
    marginTop: 3,
    position: "absolute"
  },
  bootstrapTypeHeadLabel: {
    fontSize: 14,
    marginTop: 2,
    marginBottom: theme.spacing.unit / 2,
    lineHeight: "12px",
    color: theme.palette.text.primary
  },
  bootstrapFormLabelTop: {
    fontSize: 18,
    marginBottom: theme.spacing.unit
  },
  inputLabel: {
    top: 8,
    left: -13,
    fontSize: 18,
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    //width: "100%",
    pointerEvents: "all",
    paddingBottom: 1
  },
  inputLabelDate: {
    //top: 8,
    //left: -13,
    fontSize: 18,
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    //width: "100%",
    pointerEvents: "all",
    paddingBottom: 1
  },
  formHelperText: {
    marginTop: theme.spacing.unit / 2,
    marginLeft: 0,
    marginRight: 0
  },
  selectLabel: {
    fontSize: 18,
    color: theme.palette.text.primary
  },
  radioLabel: {
    fontSize: 14,
    color: theme.palette.text.primary
  },
  selectOption: {
    padding: 0,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    fontSize: 14
  },
  radioRoot: {
    fontSize: 14,
    paddingLeft: theme.spacing.unit * 2
  },
  radioRow: {
    fontSize: 14
  },
  radioButton: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4
  },
  labelBefore: {
    //backgroundColor:"red"
  },
  radio2colLabel: {
    width: "100%"
  },
  radio2colLabel1: {
    textAlign: "center"
  },
  radio2colLabel1Content: {
    position: "relative",
    float: "left",
    top: "50%",
    left: theme.spacing.unit,
    transform: "translate(0, -50%)"
  },
  datePickerContainer: {
    marginTop: 1 //theme.spacing.unit
  }
});

export default inputStyle;
