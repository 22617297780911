/* eslint-disable no-console */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
//import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import restHelper from "helpers/aee/restHelper";
import requestHelper from "helpers/aee/requestHelper";
import DataProvider from "Components/New/DataProvider/DataProvider";
import DataTableHandson from "Components/New/DataTable/DataTableHandson/DataTableHandson";
import Button from "@material-ui/core/Button";
import withLock from "./withLock";
import { Typography } from "@material-ui/core";
import Handsontable from "handsontable";
import UploadButton from "Components/UploadButton";
import ImportIcon from "@material-ui/icons/CloudUpload";
import ExportIcon from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FileSaver from "file-saver";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ModalConfirm from "Components/Modal/ModalConfirm";
import { get } from "lodash";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
import localize from "helpers/localize";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const LC = "cloud"; //Localization category

const STATUSES = {
  ORIGINAL: "Eredeti",
  CHANGED: "Módosított",
  DELTED: "Törölt",
  NEW: "Új",
  transformStatus: status => {
    switch (status) {
      case 0:
        return STATUSES.DELTED;
      case 1:
        return STATUSES.CHANGED;
      case 2:
        return STATUSES.NEW;
      default:
        return STATUSES.ORIGINAL;
    }
  },
  reverseStatus: status => {
    switch (status) {
      case STATUSES.DELTED:
        return 0;
      case STATUSES.CHANGED:
        return 1;
      case STATUSES.NEW:
        return 2;
      default:
        return -1;
    }
  }
};

const styles = theme => ({
  /*"@global": {
    ".rbt-input-hint": {
      //backgroundColor: "red!important",
      width: "100%",
      top: "2px!important"
    }
  },*/
  rowDeleted: {
    backgroundColor: "red!important",
    color: "white"
  },
  rowChanged: {
    backgroundColor: "green!important",
    color: "white"
  },
  marginLeftAuto: {
    marginLeft: "auto"
  },
  marginLeft: {
    marginLeft: theme.spacing.unit
  },
  marginLeftRight: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing.unit
  },
  infoIcon: {
    color: theme.palette.info.main,
    marginRight: theme.spacing.unit
  },
  infoText: {
    fontStyle: "italic"
  },
  modalQueue: {
    marginLeft: theme.spacing.unit,
    fontWeight: "bold"
  },
  uptoDateIcon: {
    fill: theme.palette.primary.main
  }
});
class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validationErrors: {},
      plu_report: false,
      importFile: null,
      importError: null
    };

    this.hotTableComponent = React.createRef(); // Excel tábla referencia változója
    this.dataProviderRef = React.createRef(); // Dataprovider referencia változója
    this.modal = null;
  }

  /**
   * A felület megtekintesékor zajlik le
   */
  componentDidMount() {
    console.log("Products.componentDidMount");
    this.setToolbarItems(this.props);
    if (this.props.onFcmRef) {
      this.props.onFcmRef(this.onFcm);
    }
  }

  onFcm = () => {
    if (this.dataProviderRef.current) {
      this.dataProviderRef.current.fetchData();
    }
  };

  componentWillReceiveProps(nextPorps) {
    if (nextPorps.editMode !== this.props.editMode) {
      this.setToolbarItems(nextPorps);
    }
  }

  handlePluReportChange = () => {
    this.setState({ plu_report: !this.state.plu_report }, () => {
      this.setToolbarItems(this.props);
    });
  };

  openImport = () => {
    this.modal.open(
      localize(LC, "Termék importálás"),
      //"Telekomos lista fájl leírása",
      null,
      null, //this.state.file.file_name,
      localize(LC, "Importálás"),
      localize("basic", "Mégsem")
    );
  };

  onError = error => {
    //console.log("onError", error);
    this.setState({ importError: error });
  };

  /**
   * Csatol egy feltöltött dokumentumot
   */
  onUpload = file => {
    //console.log("onUpload", file);
    this.setState({
      importFile: file,
      importError: null
    });
    this.modal.error(null);
  };

  /**
   * Eltávolít egy csatolt dokumentumot
   */
  onRemove = type => {
    //console.log("onRemove", type);
    /*let documents = Object.assign({}, this.state.documents);
    documents[type].current = [];*/
    this.setState({
      importFile: null
    });
  };

  onAccept = () => {
    const { cashregister_id, client_id } = this.props;

    if (!this.state.importFile) {
      this.modal.error(localize(LC, "Nincs fájl kiválasztva"));
      return;
    }
    this.modal.loading(true);
    requestHelper({
      url: "cashregister-products/import",
      method: "POST",
      ap: cashregister_id,
      client: client_id,
      data: {
        CashregisterProductsImportForm: {
          file_id: this.state.importFile.id
        }
      }
    })
      .then(res => {
        //console.log("bulkFeedback", res)
        this.modal.loading(false);
        this.modal.close();
        this.setState(
          {
            importFile: null
          },
          () => {
            globalMessage.success(localize(LC, "Sikeres importálás"));
            //Refresh list
            if (this.dataProviderRef.current) {
              this.dataProviderRef.current.fetchData();
            }
          }
        );
      })
      .catch(error => {
        //console.log("error", error.response);
        let errorMessage = this.handleImportError(error, "file_id");
        this.modal.loading(false);
        this.modal.error(errorMessage);
      });
  };

  handleImportError = (error, fieldName) => {
    let errors = get(error, `response.data.form_errors.${fieldName}`, []);
    //errors = ["rossz", "Mégrosszabb", "nagyon rossz"];
    if (errors.length === 0) {
      return get(error, "response.data.message", localize(LC, "Váratlan hiba"));
    }
    if (errors.length === 1) {
      return errors[0];
    }
    return (
      <div>
        Hibák:
        <br />
        <textarea style={{ width: "100%", height: 40 }}>
          {errors.join("\n")}
        </textarea>
      </div>
    );
  };

  setToolbarItems = props => {
    const { classes, editMode, setToolbarItems, getTimeLeft } = props;
    const { validationErrors, plu_report } = this.state;
    const timeLeft = getTimeLeft("upToDate");
    const hasError = Object.keys(validationErrors).length > 0;
    //console.log("editMode", editMode);
    setToolbarItems(
      <Fragment>
        {hasError && (
          <Typography variant="body2" color="error">
            {localize(
              LC,
              "A táblázat hibás értékekt tartalmaz, részletekért kattintson ide"
            )}
          </Typography>
        )}
        {editMode && (
          <Button
            size="small"
            variant="contained"
            disabled={timeLeft <= 0}
            //className={classes.marginLeftAuto}
            onClick={this.uploadChangedData}
          >
            {localize(LC, "Módosítások felküldése")}
          </Button>
        )}
        {editMode && (
          <Button
            size="small"
            variant="contained"
            className={classes.marginLeftRight}
            onClick={this.uploadAllData}
          >
            {localize(LC, "Összes termék felküldése")}
          </Button>
        )}
        {/*editMode && (
          <Button
            size="small"
            variant="contained"
            className={classes.marginLeftRight}
            onClick={this.deleteAllData}
          >
            {localize(LC, "Összes termék törlése")}
          </Button>
        )*/}
        {!editMode && (
          <Tooltip
            title={localize(LC, "Összes termék törlése")}
            disableFocusListener
          >
            <IconButton
              onClick={this.deleteAllData}
              className={classes.marginLeft}
              color="primary"
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        )}
        {editMode && (
          <Tooltip
            disableFocusListener
            title={localize(
              LC,
              "Jelölés esetén az adatbázis frissítésekor a pénztárgép PLU jelentést nyomtat"
            )}
          >
            <FormControlLabel
              style={{ marginRight: 8, marginLeft: -7 }}
              control={
                <Checkbox
                  checked={plu_report}
                  style={{ paddingRight: 2 }}
                  onChange={this.handlePluReportChange}
                  color="primary"
                />
              }
              label={localize(LC, "Riport nyomtatás")}
            />
          </Tooltip>
        )}
        {editMode && (
          <Tooltip title={localize(LC, "Importálás")} disableFocusListener>
            <IconButton
              onClick={this.openImport}
              //className={classes.marginLeft}
              color="primary"
            >
              <ImportIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={localize(LC, "Exportálás")} disableFocusListener>
          <IconButton
            onClick={this.export}
            //className={classes.marginLeft}
            color="primary"
          >
            <ExportIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Fragment>
    );
  };
  /**
   * Lekéri az excel táblázat komponenstől az összes adatot
   */
  getTableData = () => {
    return this.hotTableComponent.current.hotInstance.getSourceData();
  };

  /**
   * Status mező átalakítása a szerver számára értelmezhető formába
   */
  mapTableData = data => {
    return data.map(item => {
      const { _status, status, _original, ...rest } = item; // eslint-disable-line no-unused-vars
      return { ...rest, status: STATUSES.reverseStatus(_status) };
    });
  };

  /**
   * Feltölti a változtatott, törölt és új sorokat a szerverre
   */
  uploadChangedData = () => {
    const { cashregister_id, client_id, onUpdated } = this.props;
    const { plu_report } = this.state;
    const data = this.getTableData();
    const filtered = data.filter(item => {
      return item.status !== STATUSES.ORIGINAL; //Csak a változott sorok
    });

    const mapData = this.mapTableData(filtered);

    this.hotTableComponent.current.hotInstance.validateRows(
      Array.from(Array(data.length).keys()),
      valid => {
        if (valid) {
          requestHelper({
            url: "cashregister-products/update",
            method: "PATCH",
            ap: cashregister_id,
            client: client_id,
            data: { plu_report: plu_report ? 1 : 0, plu_items: mapData }
          })
            .then(response => {
              console.log(response);
              if (response.data.success) {
                //this.setState({ editMode: false });
                //setEditMode(false);
                onUpdated();
              }
            })
            .catch(error => {
              console.log(error.response);
            });
        } else {
          globalMessage.error(
            localize(LC, "A táblázat hibás sorokat tartalmaz.")
          );
        }
      }
    );
  };

  /**
   * Feltölti az összes terméksort a szerverre
   */
  uploadAllData = () => {
    const { cashregister_id, client_id, onUpdated } = this.props;
    const { plu_report } = this.state;
    const data = this.getTableData();
    const mapData = this.mapTableData(data);

    this.hotTableComponent.current.hotInstance.validateRows(
      Array.from(Array(data.length).keys()),
      valid => {
        if (valid) {
          requestHelper({
            url: "cashregister-products/update",
            method: "PUT",
            ap: cashregister_id,
            client: client_id,
            data: { plu_report: plu_report ? 1 : 0, plu_items: mapData }
          })
            .then(response => {
              console.log(response);
              if (response.data.success) {
                //this.setState({ editMode: false });
                //setEditMode(false);
                onUpdated();
              }
            })
            .catch(error => {
              console.log(error.response);
            });
        } else {
          globalMessage.error(
            localize(LC, "A táblázat hibás sorokat tartalmaz.")
          );
        }
      }
    );
  };

  /**
   * Törli a termékeket
   */
  deleteAllData = () => {
    const { cashregister_id, client_id, onUpdated, checkLock } = this.props;
    checkLock(() => {
      requestHelper({
        url: "cashregister-products/delete",
        method: "DELETE",
        ap: cashregister_id,
        client: client_id
      })
        .then(response => {
          console.log(response);
          if (response.data.success) {
            this.hotTableComponent.current.hotInstance.loadData(null);
            onUpdated();
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    });
  };

  export = () => {
    const { cashregister_id, client_id } = this.props;

    requestHelper({
      url: "cashregister-products/export",
      method: "GET",
      ap: cashregister_id,
      client: client_id
    })
      .then(response => {
        console.log(response);
        FileSaver.saveAs(
          new Blob([response.data], { type: "text/plain;charset=utf-8" }),
          `${cashregister_id}-products.csv`
        );
      })
      .catch(error => {
        console.log(error.response);
      });
  };
  setError = (key, message) => {
    return;
    let validationErrors = Object.assign({}, this.state.validationErrors);
    if (message) {
      validationErrors[key] = message;
    } else {
      delete validationErrors[key];
    }
    let changed = false;
    if (
      JSON.stringify(this.state.validationErrors) !==
      JSON.stringify(validationErrors)
    ) {
      changed = true;
    }

    this.setState({ validationErrors }, () => {
      //this.setToolbarItems();
    });
  };
  render() {
    const { /*classes,*/ cashregister_id, client_id, editMode } = this.props;
    const context = this;
    return (
      <div>
        <ModalConfirm
          onRef={ref => (this.modal = ref)}
          disableIcon
          onAccept={this.onAccept}
          modalBodyComp={
            <div>
              <div>
                {localize(
                  LC,
                  "Válassza ki a csv-t a tallózás gomb segítségével, majd nyomja meg az importálás gombot."
                )}
              </div>
              {this.state.importFile
                ? this.state.importFile.file_name
                : localize(LC, "Dokumentum tallózása")}
              <UploadButton
                name={"import_file"}
                label={""}
                onUpload={this.onUpload}
                onRemove={this.onRemove}
                file={this.state.importFile}
                onError={this.onError}
                disableDelete={false}
                acceptedFiles=".csv"
                type="upload"
              />
              {this.state.importError && <div>{this.state.importError}</div>}
            </div>
          }
        />
        <DataProvider
          ref={this.dataProviderRef}
          url={"cashregister-products"}
          restHelperOptions={{ ap: cashregister_id, client: client_id }}
          restHelper={restHelper}
          component={DataTableHandson}
          readOnly={!editMode}
          hotTableRef={ref => (this.hotTableComponent = ref)}
          columns={{
            no: {
              type: "numeric",
              readOnlyOnOriginal: true,
              /*renderer: function validateId(
                instance,
                td,
                row,
                col,
                prop,
                value,
                cellProperties
              ) {
                Handsontable.renderers.NumericRenderer.apply(this, arguments);
                console.log(cellProperties);
                if (this.errormessage) {
                  td.title = this.errormessage;
                }
              },*/
              validator: function(value, callback) {
                if (!value || value.length === 0) {
                  this.validationError = localize("basic", "Kötelező mező");
                  return callback(false);
                }
                if (isNaN(value)) {
                  this.validationError = localize(
                    LC,
                    "Egész szám 1-9999-ig és nem lehet azonos"
                  );
                  return callback(false);
                }
                if (!Number.isInteger(value)) {
                  this.validationError = localize(
                    LC,
                    "Egész szám 1-9999-ig és nem lehet azonos"
                  );
                  return callback(false);
                }
                if (parseInt(value) < 1 || parseInt(value) > 9999) {
                  this.validationError = localize(
                    LC,
                    "Egész szám 1-9999-ig és nem lehet azonos"
                  );
                  return callback(false);
                }
                var data = this.instance.getDataAtCol(this.col);
                var index = data.indexOf(value);
                var valid = true;
                if (index > -1 && this.row !== index) {
                  valid = false;
                }
                if (!valid) {
                  this.validationError = localize(
                    LC,
                    "Egész szám 1-9999-ig és nem lehet azonos"
                  );
                }
                return callback(valid);
              }
            },
            barcode: {
              validator: function validateId(value, callback) {
                if (value && value.length > 13) {
                  this.validationError = localize(LC, "Maximum 13 karakter");
                  return callback(false);
                }
                return callback(true);
              }
            },
            name: {
              validator: function validateId(value, callback) {
                if (!value || value.length === 0) {
                  this.validationError = localize("basic", "Kötelező mező");
                  return callback(false);
                }
                if (value && value.length > 18) {
                  this.validationError = localize(LC, "Maximum 18 karakter");
                  return callback(false);
                }
                return callback(true);
              }
            },
            vts: {
              validator: function validateId(value, callback) {
                if (value && value.length > 10) {
                  this.validationError = localize(LC, "Maximum 10 karakter");
                  return callback(false);
                }
                return callback(true);
              }
            },
            dep: {
              type: "numeric",
              validator: function validateId(value, callback) {
                console.log("dep", value);
                if (!value || value.length === 0) {
                  this.validationError = localize(LC, "Kötelező mező");
                  return callback(false);
                }
                if (isNaN(value)) {
                  this.validationError = localize(LC, "Egész szám 1-40-ig");
                  return callback(false);
                }
                if (!Number.isInteger(parseFloat(value))) {
                  this.validationError = localize(LC, "Egész szám 1-40-ig");
                  return callback(false);
                }
                if (parseInt(value) < 1 || parseInt(value) > 40) {
                  this.validationError = localize(LC, "Egész szám 1-40-ig");
                  return callback(false);
                }
                return callback(true);
              }
            },
            inv: {
              validator: function validateId(value, callback) {
                if (value && value.length > 20) {
                  this.validationError = localize(LC, "Maximum 20 karakter");
                  return callback(false);
                }
                return callback(true);
              }
            },
            pri: {
              type: "numeric",
              numericFormat: {
                pattern: "$ 0,0",
                culture: "hu-HU"
              }
            },
            stock: {
              type: "numeric"
            },
            close: {
              //type: "dropdown",
              //source: [0, 1]
              type: "checkbox",
              checkedTemplate: 1,
              uncheckedTemplate: 0
            },
            collect: {
              validator: /^([0-9][0-9])$/
            }
          }}
        />
      </div>
    );
  }
}

Products.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //Ez igazából az AP szám
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  editMode: PropTypes.bool,
  setToolbarItems: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired //Szerkesztés mód be illetve kikapcsolása
};

//export default withStyles(styles)(withRouter(Products));
export default withLock(
  withStyles(styles)(Products),
  "FULL_DOWNLOAD_PLU_DATA-UPGRADE_PLU_DATA",
  {
    dataTitle: "Termékek",
    title: "Termékek",
    forceUpdate: false,
    notifyUpdate: true
  }
);
