import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/core/styles";
import Modal from "Components/Modal/Modal";
import JsonSyntaxHighlight from "helpers/JsonSyntaxHighlight";
import { Typography } from "@material-ui/core";
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

const MySnackbarContentWrapper = withStyles(styles)(props => {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
});

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired
};

export const globalMessage = {
  success: message => {
    let event = new CustomEvent("onGobalMessage", {
      detail: {
        message,
        variant: "success"
      }
    });
    document.dispatchEvent(event);
  },
  error: message => {
    let event = new CustomEvent("onGobalMessage", {
      detail: {
        message,
        variant: "error"
      }
    });
    document.dispatchEvent(event);
  },
  fatalError: message => {
    let event = new CustomEvent("onGobalModalMessage", {
      detail: {
        message,
        variant: "error"
      }
    });
    document.dispatchEvent(event);
  },
  validateError: (message, errors) => {
    let event = new CustomEvent("onGobalModalMessage", {
      detail: {
        message,
        errors,
        variant: "error"
      }
    });
    document.dispatchEvent(event);
  }
};

class GlobalMessaging extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openModal: false,
      message: "This is a Message!",
      errors: null,
      variant: "success",
      autoHideDuration: 3000
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  componentDidMount() {
    document.addEventListener("onGobalMessage", this.onGobalMessage);
    document.addEventListener("onGobalModalMessage", this.onGobalModalMessage);
  }

  componentWillUnmount() {
    document.removeEventListener("onGobalMessage", this.onGobalMessage);
    document.removeEventListener(
      "onGobalModalMessage",
      this.onGobalModalMessage
    );
  }

  onGobalMessage = evt => {
    const { detail } = evt;
    this.setState({ open: true, ...detail, errors: null });
  };
  onGobalModalMessage = evt => {
    const { detail } = evt;
    this.setState({
      openModal: true,
      ...detail,
      errors: detail.errors ? detail.errors : null
    });
  };

  getValidationErrors = () => {
    const { errors, message } = this.state;
    let errorList = [];
    Object.keys(errors).forEach(key => {
      const itemErrors = errors[key];
      if (Array.isArray(itemErrors)) {
        itemErrors.forEach(err => {
          errorList.push(err);
        });
      } else if (typeof itemErrors === "string") {
        errorList.push(itemErrors);
      } else {
        errorList.push(JSON.stringify(itemErrors));
      }
    });

    return (
      <div>
        {message && message !== "" && (
          <Typography variant="body1">{message}</Typography>
        )}
        {errorList.length > 0 && (
          <ul>
            {errorList.map((error, index) => {
              return (
                <li key={index}>
                  <Typography variant="body2">{error}</Typography>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  };

  render() {
    const {
      open,
      openModal,
      message,
      variant,
      autoHideDuration,
      errors
    } = this.state;
    return (
      <Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={variant}
            message={typeof message === "string" ? message : ""}
          />
        </Snackbar>
        <Modal
          open={openModal}
          title="Hiba"
          error
          disableCancel
          onAccept={this.handleCloseModal}
          onClose={this.handleCloseModal}
          zIndex={3000}
        >
          {typeof message === "object" ? (
            <JsonSyntaxHighlight json={message} />
          ) : errors ? (
            this.getValidationErrors()
          ) : (
            message
          )}
        </Modal>
      </Fragment>
    );
  }
}

GlobalMessaging.propTypes = {};

export default GlobalMessaging;
