import React from "react";
import PropTypes from "prop-types";
import localize from "helpers/localize";
import restHelper from "helpers/aee/restHelper";
import withLock from "./withLock";

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Paper,
  Toolbar,
  Typography
} from "@material-ui/core";

const LC = "cloud"; //Localization category

class AeeLastLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      loading: false
    };
  }

  componentDidMount() {
    /*const { setToolbarItems } = this.props;
    setToolbarItems(<div>LOL</div>);*/
    this.view();
  }

  view = () => {
    const { cashregister_id: ap, client_id: client } = this.props;
    this.setState({ loading: true });
    restHelper
      .view("cashregister-aee-notify/view", null, { ap, client })
      .then(response => {
        this.setState({ item: response.data, loading: false });
      });
  };
  render() {
    const { item, loading } = this.state;
    const { showHeader } = this.props;
    return (
      <Grid container>
        {/*<Grid item xs={12}>
          <Typography variant="h6">AEE Teszt</Typography>
    </Grid>*/}
        {showHeader && (
          <Grid item xs={12}>
            <Toolbar disableGutters>
              <Typography variant="h6">
                {localize(LC, "Utolsó bejelentkezés")}
              </Typography>
            </Toolbar>
          </Grid>
        )}
        <Grid item xs={12} md={8} lg={6}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{localize(LC, "Funkció")}</TableCell>
                  <TableCell>{localize(LC, "Eredmény")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(item || {}).map(key => {
                  return (
                    <TableRow key={key} style={{ height: "unset" }}>
                      <TableCell>{item[key].label}</TableCell>
                      <TableCell>
                        {item[key].value === "OK" ? (
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            {item[key].vale}
                          </span>
                        ) : (
                          item[key].value
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

AeeLastLogin.defaultProps = {
  showHeader: true
};

AeeLastLogin.propTypes = {
  cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  showHeader: PropTypes.bool
};

export default AeeLastLogin;

/*export default withLock(AeeLastLogin, "AEE_LASTLOGIN", {
  dataTitle: "Utolsó bejelentkezés info",
  title: "Utolsó bejelentkezés info",
  forceUpdate: false,
  hideEditButton: true
});*/
