import React, { PureComponent, Fragment } from "react";
import { Helmet } from "react-helmet";
import List from "Components/List/List";
import ContainerHeader from "Components/ContainerHeader/index";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import FileDownload from "@material-ui/icons/Description";
import FileUpload from "@material-ui/icons/CloudUpload";
import Done from "@material-ui/icons/Done";
import PendingIcon from "@material-ui/icons/AccessTime";
import Error from "@material-ui/icons/Error";
import Button from "@material-ui/core/Button/Button";
import Visibility from "@material-ui/icons/Visibility";
import Check from "@material-ui/icons/Check";
import Modal from "Components/Modal/Modal";
import restHelper from "helpers/restHelper";
import { Link } from "react-router-dom";
import StaticTable from "Components/New/DataTable/StaticTable/StaticTable";
import { markAnyk } from "redux/modules/anyk";
import localize from "helpers/localize";

class AnykList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      worksheets: []
    };
  }

  refreshList = () => {
    if (this.list && this.list.getList) {
      return this.list.getList();
    }
  };

  mark = type => () => {
    markAnyk(type).then(() => {
      this.refreshList();
    });
  };

  showWorksheets = id => () => {
    restHelper.view("anyk-exports", id).then(response => {
      this.setState({ open: true, worksheets: response.data.worksheets });
    });
  };

  gotoWorksheet = id => () => {
    this.props.history.push(`/worksheets/view/${id}`);
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  getListHeader = () => {
    return this.props.getListHeader("/anyk-exports");
  };

  /**
   * Megmondja, hogy mely gombokat kell kikapcsolni a táblázat soraiben
   */
  disabledIcons = () => {
    const disabled = {};
    disabled.disableViewIcon = true;
    disabled.disableEditIcon = true;
    //disabled.disableDeleteIcon = true;
    disabled.disabledCreate = true;

    return disabled;
  };

  customIcons = obj => {
    return (
      <Fragment>
        <Tooltip title={localize("worksheets", "Jelölés")} disableFocusListener>
          <Button
            disabled={obj.marked ? true : false}
            className="jr-btn jr-btn-xs"
            color="primary"
            onClick={this.mark(obj.id)}
          >
            <Check />
          </Button>
        </Tooltip>
        <Button
          className="jr-btn jr-btn-xs"
          color="secondary"
          onClick={this.showWorksheets(obj.id)}
        >
          <Visibility />
        </Button>
        <Tooltip
          title={localize("worksheets", "Letöltés")}
          disableFocusListener
        >
          <Button
            className="jr-btn jr-btn-xs"
            target="_blank"
            href={
              process.env.REACT_APP_HOST +
              "/v1/anyk-exports/download/" +
              obj.id +
              "?accessToken=" +
              localStorage.getItem("access_token").replace("Bearer ", "")
            }
            component="a"
          >
            <FileDownload />
          </Button>
        </Tooltip>
      </Fragment>
    );
  };

  render() {
    const { open, worksheets } = this.state;
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("worksheets", "ANYK")}</title>
        </Helmet>
        {/*<Navbar location={this.props.location} history={this.props.history} />*/}
        <ContainerHeader title={localize("worksheets", "ANYK")} />
        <List
          listRef={ref => (this.list = ref)}
          {...this.props}
          getListHeader={this.getListHeader.bind(this)}
          {...this.disabledIcons()}
          customIcons={this.customIcons}
          rowFormat={item => {
            if (item.marked) {
              return {
                backgroundColor: "#e8f5e9"
              };
            }
          }}
        />
        <Modal
          title={localize("worksheets", "Export munkalapjai")}
          open={open}
          onClose={this.closeModal}
          disableCancel
          onAccept={this.closeModal}
          Icon={Visibility}
          maxWidth="xl"
        >
          <StaticTable
            rows={worksheets}
            padding="dense"
            columns={[
              {
                id: "number",
                label: localize("worksheets", "Munkalapszám"),
                url: row => `/worksheets/view/${row.id}`
              },
              { id: "anyk_dates", label: localize("worksheets", "Dátum") },
              { id: "apn", label: localize("worksheets", "AP szám") },
              {
                id: "error_type",
                label: localize("worksheets", "Munkavégzés típusa")
              },
              {
                id: "anyk_add_info",
                label: localize("worksheets", "Egyéb info.")
              }
            ]}
          />
        </Modal>
      </div>
    );
  }
}

export default AnykList;
