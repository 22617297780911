import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";


import List from "../../../../../Components/List/List";
import ContainerHeader from "../../../../../Components/ContainerHeader";
import Navbar from "../../Navbar";
import localize from "helpers/localize";

class ReregList extends PureComponent {
  getListHeader = () => {
    return this.props.getListHeader("/events", "&section=pwdreregister");
  };

  getList = (page, keyword, sort, filters, perPage) => {
    return this.props.getList(
      page,
      keyword,
      sort,
      filters,
      perPage,
      "pwdreregister"
    );
  };

  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("cashregisters", "Átszemélyesítés")}</title>
        </Helmet>
        <Navbar {...this.props} />
        <ContainerHeader title={localize("cashregisters", "Átszemélyesítés")} />
        <List
          {...this.props}
          getListHeader={this.getListHeader.bind(this)}
          getList={this.getList.bind(this)}
          disabledCreate={true}
          disabledTools={true}
        />
      </div>
    );
  }
}

export default ReregList;
