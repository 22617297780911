import axios from "axios";
import { get } from "lodash";

const AUTOCOMPLETE_LOAD = "AUTOCOMPLETE_LOAD";
const AUTOCOMPLETE_SUCCESS = "AUTOCOMPLETE_SUCCESS";
const AUTOCOMPLETE_FAILED = "AUTOCOMPLETE_FAILED";
const AUTOCOMPLETE_SELECTED = "AUTOCOMPLETE_SELECTED";

const initialState = {
  options: [],
  loading: false,
  selected: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTOCOMPLETE_LOAD:
      return {
        ...state,
        options: [],
        loading: true,
        selected: null
      };
    case AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        options: action.options,
        loading: false
      };
    case AUTOCOMPLETE_FAILED:
      return {
        ...state,
        options: [],
        loading: false
      };
    case AUTOCOMPLETE_SELECTED:
      return {
        ...state,
        //options: action.options,
        selected: action.options,
        loading: false
      };
    default:
      return state;
  }
};

export const setSelected = option => dispatch => {
  dispatch({
    type: AUTOCOMPLETE_SELECTED,
    options: [option]
  });
};

export const search = (path, attribute, keyword) => dispatch => {
  dispatch({
    type: AUTOCOMPLETE_LOAD
  });

  return axios
    .get(path, {
      params: {
        keyword,
        attribute
      }
    })
    .then(response => {
        let options = [],
        data = get(response,"data.data", []);
        if (typeof data === "object") {
        Object.keys(data).forEach((key) => {
          options.push(data[key]);
          });
        } else {
        options = data;
        }
      return dispatch({
        type: AUTOCOMPLETE_SUCCESS,
        options
      });
    })
    .catch(error =>
      dispatch({
        type: AUTOCOMPLETE_FAILED
      })
    );
};

export const serviceSearch = (path, attribute, keyword, label) => dispatch => {
  dispatch({
    type: AUTOCOMPLETE_LOAD
  });

  return axios
    .get(path, {
      params: {
        keyword,
    attribute
      }
    })
    .then(response => {
      let options = [],
        data = get(response, "data.data", []);
      if (typeof data === "object") {
        Object.keys(data).forEach(key => {
        options[key] = {
            id: data[key].client_id || data[key].client_location_id,
            label: "" + data[key][label],
            ...data[key]
          };
      });
      } else {
        options = data;
      }
    return dispatch({
      type: AUTOCOMPLETE_SUCCESS,
        options
    });
    })
    .catch(error =>
      dispatch({
        type: AUTOCOMPLETE_FAILED
        })
    );
};
