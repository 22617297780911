import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditWorksheet from "./views/EditWorkfee";
import * as workfeesActions from "../../../../redux/modules/workfees";

const mapStateToProps = state => {
  return {
    loading: state.workfees.loading,
    success: state.workfees.success,
    saved: state.workfees.saved,
    message: state.workfees.message,
    fieldErrors: state.workfees.formErrors,
    id: state.workfees.id,
    viewData: state.workfees.viewData,
    reducerName: "workfees",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: workfeesActions.createWorkfees,
      update: workfeesActions.updateWorkfees,
      view: workfeesActions.viewWorkfees,
      reset: workfeesActions.reset,
      resetForm: workfeesActions.resetForm
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditWorksheet);
