import React, { Component } from "react";
import Modal from "Components/Modal/Modal";
import ListIcon from "@material-ui/icons/List";
import { Typography } from "@material-ui/core";
import restHelper from "helpers/restHelper";
import StaticTable from "Components/New/DataTable/StaticTable/StaticTable";
import localize from "helpers/localize";

class AddressesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentDidMount() {
    this.fetchAddresses(this.props.id, this.props.type);
  }

  fetchAddresses = (id, type) => {
    if (!id) {
      return;
    }
    restHelper
      .index(
        type === "distributor_id"
          ? "notifications/distributors-by-tag"
          : "notifications/clients-by-tag",
        { tag: id }
      )
      .then(response => {
        console.log(response);
        this.setState({ items: response.data || [] });
      })
      .catch(error => {
        this.setState({ items: [] });
      });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.onOpen();
    }
    if (nextProps.id !== this.props.id) {
      this.fetchAddresses(nextProps.id, nextProps.type);
    }
  }

  onOpen = () => {
    console.log("onOpen");
    this.setState({});
  };

  onAccept = () => {
    this.onClose();
  };

  onCancel = () => {
    this.onClose();
  };
  onClose = () => {
    const { onClose } = this.props;
    onClose && onClose();
  };

  render() {
    const { open, loading, type } = this.props;
    const { items } = this.state;

    const title =
      type === "distributor_id"
        ? localize("notifications", "Viszonteladók")
        : localize("notifications", "Ügyfelek");

    return (
      <Modal
        title={localize("notifications", "Címzettek listája")}
        yesText="Ok"
        disableCancel
        open={open}
        loading={loading}
        onCancel={this.onCancel}
        onClose={this.onClose}
        onAccept={this.onAccept}
        Icon={ListIcon}
        //YesIcon={SendIcon}
        maxWidth="md"
      >
        <Typography variant="subtitle2">{title}</Typography>
        <StaticTable
          rows={items}
          columns={[
            { id: "name", label: localize("notifications", "Név") },
            { id: "phone", label: localize("notifications", "Telefonszám") }
          ]}
        />
      </Modal>
    );
  }
}

export default AddressesModal;
