import PropTypes from "prop-types";
import DateRangePicker from "react-daterange-picker";
import { getYearMonth } from "react-daterange-picker/dist/utils/getYearMonth";
import CustomPropTypes from "react-daterange-picker/dist/utils/CustomPropTypes";
//import _momentRange from "react-daterange-picker/dist/moment-range";
import moment from "moment";

class RDateRangePicker extends DateRangePicker {
  getInitialState = () => {
    const { numberOfCalendars, onPageChange } = this.props;
    const initialState = super.getInitialState();
    console.log("initialState", initialState);
    onPageChange &&
      onPageChange(
        moment()
          .year(initialState.year)
          .month(initialState.month)
          .startOf("month"),
        moment()
          .year(initialState.year)
          .month(initialState.month)
          .add(numberOfCalendars - 1, "months")
          .endOf("month")
      );
    return initialState;
  };

  componentDidMount() {
    const { numberOfCalendars, onPageChange } = this.props;
    const { year, month } = this.state;

    console.log("initialState", year, month);
    onPageChange &&
      onPageChange(
        moment()
          .year(year)
          .month(month)
          .startOf("month"),
        moment()
          .year(year)
          .month(month)
          .add(numberOfCalendars - 1, "months")
          .endOf("month")
      );
  }

  moveBack = () => {
    let monthDate;

    if (this.canMoveBack()) {
      monthDate = this.getMonthDate();

      monthDate.subtract(1, "months");

      this.setState(getYearMonth(monthDate), () =>
        this.onPageChange(this.state.year, this.state.month)
      );
    }
  };

  canMoveForward = () => {
    if (
      this.getMonthDate()

        .add(this.props.numberOfCalendars, "months")

        .isAfter(this.state.enabledRange.end)
    ) {
      return false;
    }

    return true;
  };

  moveForward = () => {
    let monthDate;

    if (this.canMoveForward()) {
      monthDate = this.getMonthDate();

      monthDate.add(1, "months");
      this.setState(getYearMonth(monthDate), () =>
        this.onPageChange(this.state.year, this.state.month)
      );
    }
  };

  changeYear = year => {
    let { enabledRange, month } = this.state;

    if (
      moment({ years: year, months: month, date: 1 }).unix() <
      enabledRange.start.unix()
    ) {
      month = enabledRange.start.month();
    }

    if (
      moment({ years: year, months: month + 1, date: 1 }).unix() >
      enabledRange.end.unix()
    ) {
      month = enabledRange.end.month();
    }

    this.setState(
      {
        year: year,

        month: month
      },
      () => this.onPageChange(this.state.year, this.state.month)
    );
  };

  onPageChange = (year, month) => {
    let { onPageChange, numberOfCalendars } = this.props;

    onPageChange &&
      onPageChange(
        moment()
          .year(year)
          .month(month)
          .startOf("month"),
        moment()
          .year(year)
          .month(month)
          .add(numberOfCalendars - 1, "months")
          .endOf("month")
      );
  };

  changeMonth = date => {
    this.setState(
      {
        month: date
      },
      () => this.onPageChange(this.state.year, this.state.month)
    );
  };
}

DateRangePicker.propTypes = {
  bemBlock: PropTypes.string,

  bemNamespace: PropTypes.string,

  className: PropTypes.string,

  dateStates: PropTypes.array, // an array of date ranges and their states

  defaultState: PropTypes.string,

  disableNavigation: PropTypes.bool,

  firstOfWeek: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),

  helpMessage: PropTypes.string,

  initialDate: PropTypes.instanceOf(Date),

  initialFromValue: PropTypes.bool,

  initialMonth: PropTypes.number, // Overrides values derived from initialDate/initialRange

  initialRange: PropTypes.object,

  initialYear: PropTypes.number, // Overrides values derived from initialDate/initialRange

  locale: PropTypes.string,

  maximumDate: PropTypes.instanceOf(Date),
  minimumDate: PropTypes.instanceOf(Date),

  numberOfCalendars: PropTypes.number,
  onHighlightDate: PropTypes.func, // triggered when a date is highlighted (hovered)

  onHighlightRange: PropTypes.func, // triggered when a range is highlighted (hovered)

  onSelect: PropTypes.func, // triggered when a date or range is selectec

  onPageChange: PropTypes.func, // triggered when the displayed calendar changed (paging, year/month changing)

  onSelectStart: PropTypes.func, // triggered when the first date in a range is selected

  paginationArrowComponent: PropTypes.func,

  selectedLabel: PropTypes.string,

  selectionType: PropTypes.oneOf(["single", "range"]),

  singleDateRange: PropTypes.bool,

  showLegend: PropTypes.bool,

  stateDefinitions: PropTypes.object,

  value: CustomPropTypes.momentOrMomentRange
};

export default RDateRangePicker;
