import React, { Fragment, PureComponent } from "react";
import { Helmet } from "react-helmet";

import List from "../../../../Components/List/List";
import ContainerHeader from "../../../../Components/ContainerHeader";
import PlayArrow from "@material-ui/icons/PlayArrow";
import FileDownload from "@material-ui/icons/CloudDownload";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Button from "@material-ui/core/Button/Button";
import localize from "helpers/localize";

class MyExamsList extends PureComponent {
  customIcons = obj => {
    return (
      <Fragment>
        {obj.status === "approved" && (
          <Tooltip title={localize("exams", "Vizsga kitöltése")}>
            <Button
              className="jr-btn jr-btn-xs"
              onClick={() =>
                this.props.history.push(
                  this.props.location.pathname + "/exam/" + obj.id
                )
              }
              component="span"
            >
              <PlayArrow />
            </Button>
          </Tooltip>
        )}
        {obj.certificate && (
          <Tooltip title={localize("exams", "Letöltés")}>
            <Button
              className="jr-btn jr-btn-xs"
              onClick={() => this.props.downloadExam(obj.id)}
              component="span"
            >
              <FileDownload />
            </Button>
          </Tooltip>
        )}
        {obj.certificate_url && (
          <Tooltip
            disableFocusListener
            title={localize("exams", "Tanúsítvány letöltése")}
          >
            <Button
              className="jr-btn jr-btn-xs"
              target="_blank"
              color="primary"
              href={
                process.env.REACT_APP_HOST +
                obj.certificate_url +
                "?accessToken=" +
                localStorage.getItem("access_token").replace("Bearer ", "")
              }
              component="a"
            >
              <FileDownload />
            </Button>
          </Tooltip>
        )}
      </Fragment>
    );
  };

  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("exams", "Vizsgáim")}</title>
        </Helmet>
        <ContainerHeader title={localize("exams", "Vizsgáim")} />

        <List
          {...this.props}
          customCreateText={"Vizsgára jelentkezés"}
          disableEditIcon={true}
          disableDeleteIcon={true}
          disableViewIcon={true}
          customIcons={this.customIcons.bind(this)}
        />
      </div>
    );
  }
}

export default MyExamsList;
