import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastr } from "../../helpers/CustomToastr";

import { Field, reduxForm } from "redux-form";
import Validations from "../Forms/Validations";
import InputField from "../Forms/Fields/Input";
import DefaultFormButtons from "../Forms/Buttons/DefaultFormButtons";
import { Form } from "reactstrap";
import * as signingActions from "../../redux/modules/signing";
import localize from "helpers/localize";

class Connect extends Component {
  handleSubmit = formData => {
    return this.props.sendCode(formData);
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.message && nextProps.message) {
      toastr.error(nextProps.message);
    }
    if (!this.props.connected && nextProps.connected) {
      this.props.closeModal();
    }
  };

  componentWillUnmount = () => {
    this.props.unSubscribeConnected();
  };

  render() {
    const { pristine, submitting, reset, loading } = this.props;

    return (
      <Fragment>
        {!this.props.password && (
          <Form
            onSubmit={e => {
              e.preventDefault();
              this.handleSubmit({
                code: this.refs.codeRef.value || ""
              });
            }}
            className="form"
          >
            <div className="form-body">
              <div className="row">
                <div className="col-12">
                  <Field
                    name="code"
                    label={localize("connecting", "Kód")}
                    fieldError={this.props.fieldErrors.code}
                    isRequired={true}
                    validate={[Validations.required]}
                    component={InputField}
                    type="text"
                    placeholder={localize("connecting", "Kód")}
                    isViewPage={false}
                    ref="codeRef"
                  />
                </div>
              </div>
            </div>
            <DefaultFormButtons
              pristine={pristine}
              submitting={submitting}
              reset={reset}
              loading={loading}
              isViewPage={false}
              {...this.props}
              disableCancel={true}
            />
          </Form>
        )}
        {this.props.password && (
          <Fragment>
            <p>
              {localize("connecting", "Jelszó")}: {this.props.password}
            </p>
            <p>{localize("connecting", "Várakozás a jelszó megadására...")}</p>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    fieldErrors: state.signing.fieldErrors,
    loading: state.signing.loading,
    success: state.signing.success,
    message: state.signing.message,
    password: state.signing.password,
    connected: state.signing.connected
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendCode: signingActions.sendCode,
      unSubscribeConnected: signingActions.unSubscribeConnected
    },
    dispatch
  );
};

const UsersReduxForm = reduxForm({
  form: "connectForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true
})(Connect);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersReduxForm);
