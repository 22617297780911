import requestHelper from "./requestHelper";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";

/**
 * Egy Objektumot (Kulcs érték pár) alakítá át url query-vé
 *
 * @param {Object} params Átalakítandó paraméterek
 * @return {String} Átalakított paraméterek
 */
const getUrlParams = params => {
  var str = "";
  for (var key in params) {
    if (str !== "") {
      str += "&";
    }
    if (params[key]) {
      if (typeof params[key] === "object") {
        str += key + "=" + encodeURIComponent(JSON.stringify(params[key]));
      } else {
        if (params[key] === "*empty_string*") {
          str += key + "=";
        } else {
          str += key + "=" + encodeURIComponent(params[key]);
        }
      }
    }
  }
  return str;
};
const index = (controller, params, options) => {
  const urlParams = getUrlParams(params);
  const hasQuery = controller.indexOf("?") >= 0;
  return requestHelper({
    url:
      "/" + controller + (urlParams ? (hasQuery ? "&" : "?") + urlParams : ""),
    method: "GET",
    ...options
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res;
    });
};

const view = (controller, id, options) => {
  return requestHelper({
    url: id ? `/${controller}/${id}` : `/${controller}`,
    method: "GET",
    ...options
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const upload = (file, options) => {
  const formData = new FormData();
  formData.append("file", file);
  return requestHelper({
    url: "/files",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    ...options
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const update = (controller, model, id, item, options) => {
  return requestHelper({
    url: id ? `/${controller}/${id}` : `/${controller}`,
    method: "PUT",
    data: model ? { [model]: item } : item,
    ...options
  })
    .then(res => {
      globalMessage.success("Sikeres mentés");
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const create = (controller, model, item, options) => {
  return requestHelper({
    url: "/" + controller,
    method: "POST",
    data: model ? { [model]: item } : item,
    ...options
  })
    .then(res => {
      globalMessage.success("Sikeres létrehozás");
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const remove = (controller, id, params, options) => {
  const urlParams = getUrlParams(params);

  return requestHelper({
    url: "/" + controller + "/" + id + (urlParams ? "?" + urlParams : ""),
    method: "DELETE",
    ...options
  })
    .then(res => {
      globalMessage.success("Sikeres törlés");
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const post = (url, model, item, options) => {
  return requestHelper({
    url: "/" + url,
    method: "POST",
    data: item ? (model ? { [model]: item } : item) : undefined,
    ...options
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const restHelper = {
  index,
  view,
  update,
  create,
  remove,
  post,
  upload
};

export default restHelper;
