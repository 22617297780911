import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditSticker from "./views/EditSticker";
import * as stickersActions from "../../../../redux/modules/stickers";
import * as StickersOptionsActions from "../../../../redux/modules/StickersOptions";

const mapStateToProps = state => {
  return {
    loading: state.stickers.loading,
    success: state.stickers.success,
    saved: state.stickers.saved,
    message: state.stickers.message,
    fieldErrors: state.stickers.formErrors,
    id: state.stickers.id,
    viewData: state.stickers.viewData,
    reducerName: "stickers",
    model: state.StickersOptions.model,
    baseFields: state.StickersOptions.baseFields,
    enabledFields: state.StickersOptions.enabledFields,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: stickersActions.createStickers,
      update: stickersActions.updateStickers,
      view: stickersActions.viewStickers,
      reset: stickersActions.reset,
      resetForm: stickersActions.resetForm,
      getFormInfo: StickersOptionsActions.getFormInfo
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSticker);
