import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import InputField from "../../../../../Components/Forms/Fields/Input";
import Validations from "../../../../../Components/Forms/Validations";
import EditForm from "../../../../../Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import TagInput from "../../../../../Components/Forms/Fields/AsyncTagInput";
import { get } from "lodash";
import { mobilNumberMask } from "helpers/maskHelper";
import { Grid } from "@material-ui/core";
import localize from "helpers/localize";

class SmsListItemForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  handleSetTags = (name, tags) => {
    const { dispatch, change } = this.props;
    dispatch(change("tags", tags));
  };

  render() {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      enabledFields
    } = this.props;
    return (
      <EditForm {...this.props}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Field
              name="name"
              label={localize("sms", "Név")}
              fieldError={fieldErrors.name}
              onChange={() => handleInputChange("name")}
              isRequired={true}
              validate={[Validations.required]}
              component={InputField}
              type="text"
              placeholder={localize("sms", "Név")}
              isViewPage={isViewPage}
              readOnly={
                enabledFields
                  ? enabledFields.indexOf("name") !== -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Field
              name="phone"
              label={localize("sms", "Telefonszám")}
              fieldError={fieldErrors.phone}
              onChange={() => handleInputChange("phone")}
              isRequired={true}
              validate={[Validations.required, Validations.mobile_number]}
              {...mobilNumberMask}
              component={InputField}
              type="text"
              placeholder={localize("sms", "Telefonszám")}
              isViewPage={isViewPage}
              readOnly={
                enabledFields
                  ? enabledFields.indexOf("phone") !== -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Field
              name="tags"
              onChangeTags={tags => this.handleSetTags("tags", tags)}
              label={localize("sms", "Címkék")}
              component={TagInput}
              isViewPage={isViewPage}
              tagPath={get(this.props, "baseFields.tags.url", null)}
            />
          </Grid>
        </Grid>
        {/*<div className="col-12">
          <Field
            name="list_id"
            label={localize("sms", "Lista")}
            fieldError={fieldErrors.list_id}
            onChange={() => handleInputChange("list_id")}
            isRequired={true}
            validate={[Validations.required]}
            component={InputField}
            type="text"
            placeholder={localize("sms", "Lista")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("list_id") !== -1
                  ? false
                  : true
                : false
            }
          />
          </div>*/}
      </EditForm>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "smsListItem",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(SmsListItemForm);

export default connect(
  state => ({
    initialValues: state.listItemsSms.viewData
  }),
  null
)(UsersReduxForm);
