/* eslint-disable */
import React, { Fragment, PureComponent } from 'react';
import { Helmet } from "react-helmet";
import List from '../../../../Components/List/List';
import ContainerHeader from '../../../../Components/ContainerHeader';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Visibility from '@material-ui/icons/Visibility';
import Done from '@material-ui/icons/Done';
import Button from "@material-ui/core/Button/Button";
import ModalConfirm from "Components/Modal/ModalConfirm";
import authRules from "helpers/authRules";
import { getUrl } from "helpers/placeHelper";
import { Link } from "react-router-dom";
import localize from "helpers/localize";
import restHelper from 'helpers/restHelper';
import { globalMessage } from 'Components/New/GlobalMessaging/GlobalMessaging';

class NotificationsList extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      page: null,
      keyword: null,
      search: null,
      filters: null,
      perPage: null
    };
    this.modal = React.createRef();
    this.modalReadedAll = React.createRef();
  }

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.reload && nextProps.reload) {
      const { page, keyword, sort, filters, perPage } = this.state;
      this.getList(page, keyword, sort, filters, perPage);
    }
  };

  refreshList = () => {
    if (this.list && this.list.getList) {
      return this.list.getList();
    }
  };

  getList = (page, keyword, sort, filters, perPage) => {
    this.setState({
      page, keyword, sort, filters, perPage
    });
    return this.props.getNotificationList(page, keyword, sort, filters, perPage)
  };

  customIcons = (obj) => {
    let hasContent = obj.content_extended && obj.content_extended.trim() !== "";
    let color = obj.unread ? "secondary" : "default";
    let disabled = !obj.can_setseen || (!hasContent && !obj.unread);

    //TODO: EZT megcsinálni
    //content a title a tartalma az extended
    //color = obj.unread ? "secondary" : obj.content_extended && obj.content_extended.trim() !== "" ? "default"
    //Minden jattintásra, ha van tartalom, akkor modalba lássuk azt.
    //olvasatlan, akkor rózsaszín
    //ha van tartalom és olvasott, akkor default marad kattintható
    //ha nincs tartalom és olvasott, disabled=true

    return <Fragment>
      <Tooltip title={localize("basic", "Megtekintés")}>
        <Button className="jr-btn jr-btn-xs"
          color={color}
          disabled={disabled}
          onClick={() => {
            if (obj.unread) {
              this.props.readItem(obj.id).then(res => {
                const { page, keyword, sort, filters, perPage } = this.state;
                this.getList(page, keyword, sort, filters, perPage);
                if (hasContent) {
                  this.modal.open(obj.content, obj.content_extended, null, "Ok", false, false).then(() => {
                    this.modal.close();
                  });
                }
              });
            } else {
              if (hasContent) {
                this.modal.open(obj.content, obj.content_extended, null, "Ok", false, false).then(() => {
                  this.modal.close();
                });
              }
            }

          }}
          component="span">
          <Visibility />
        </Button>
      </Tooltip>
      {!obj.is_read && false && <Tooltip title={localize("notifications", "Elolvasva")}>
        <Button className="jr-btn jr-btn-xs" onClick={() => this.props.readItem(obj.id)} component="span">
          <Done />
        </Button>
      </Tooltip>}
    </Fragment>
  };

  customButtons = () => {
    return (
      <div className="mr-auto">
          <Button color="primary" onClick={this.openReadedAll}>
            {localize("notifications", "Mindent olvasottnak jelöl")}
          </Button>
      </div>
    );
  };

  openReadedAll = () => {
    this.modalReadedAll.open(
      "Megerősítés",
      "Biztosan olvasottra állítja az összes értesítést?",
      null,
      null//, //this.state.file.file_name,
      //"Ok",
      //"Mégsem"
    ).then(()=>{
      this.modalReadedAll.loading(true);
      restHelper.put("notifications").then(response => {
        globalMessage.success(localize("notifications", "Sikeres művelet"))
        this.modalReadedAll.close();
        this.refreshList();
      })
    });
  };

  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("notifications", "Értesítések")}</title>
        </Helmet>
        <ContainerHeader title={localize("notifications", "Értesítések")} />

        <List
          {...this.props}
          disableEditIcon={true}
          disableDeleteIcon={true}
          disableViewIcon={true}
          disabledCreate={!authRules.isRoot}
          customIcons={this.customIcons.bind(this)}
          customButtons={this.customButtons}
          listRef={ref => (this.list = ref)}
          getList={this.getList.bind(this)}
          renderCell={(row, rowIndex, colIndex, header) => {
            if (header._key === "content") {
              return row.place ? (<Link to={getUrl(row.place)}>
                {row.content}
              </Link>) : row.content;
            }

            return false;
          }}
        />
        <ModalConfirm
          onRef={ref => (this.modal = ref)}
          disableIcon
          disableAccept
          onAccept={this.onAccept}
          htmlContent
        />
        <ModalConfirm
          onRef={ref => (this.modalReadedAll = ref)}
          disableIcon
          //onAccept={this.onAcceptReadedAll}
          //htmlContent
        />
      </div>
    );
  }
}

export default NotificationsList;