import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import localize from "helpers/localize";

const styles = theme => ({
  root: {
    justifyContent: "center",
    flexWrap: "wrap"
  },
  paper: {
    //padding: theme.spacing.unit * 2
    paddingTop: theme.spacing.unit * 1.25,
    paddingBottom: theme.spacing.unit * 0.75
  }
});

class Breadcrumbs extends React.Component {
  title = () => {
    const { item } = this.props;
    if (item && item.id) {
      const items = item.parents.map(item => {
        return item.title;
      });
      items.reverse();
      return items.join(" \\ ");
    } else {
      return localize("shop", "Minden termék");
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={0} className={classes.paper}>
        <Typography color="textSecondary" variant="subtitle1">
          {this.title()}
        </Typography>
      </Paper>
    );
  }
}

Breadcrumbs.defaultProps = {
  item: {}
};
Breadcrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object
};

export default withStyles(styles)(Breadcrumbs);
