import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";
import InputField from "Components/Forms/Fields/Input";
import SimpleFileInput from "Components/Forms/Fields/SimpleFile";
import Validations from "Components/Forms/Validations";
import EditForm from "Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import TagInput from "Components/Forms/Fields/AsyncTagInput";
import Grid from "@material-ui/core/Grid";
import localize from "helpers/localize";

class DownloadsForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  handleFileChange = (remove, file) => {
    const { dispatch, change } = this.props;
    dispatch(change("file_id", remove ? null : get(file, "id", "")));
  };

  handleSetTags = (name, tags) => {
    const { dispatch, change } = this.props;
    dispatch(change("tags", tags));
  };

  render() {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      enabledFields
    } = this.props;

    let denieds = [];
    Object.keys(get(this.props, "baseFields.denied.data", {})).forEach(key => {
      denieds.push({ id: key, label: this.props.baseFields.denied.data[key] });
    });

    return (
      <EditForm {...this.props}>
        <Grid item={true} xs={12} sm={6} md={4} lg={3}>
          <Field
            name="name"
            label={localize("downloads", "Név")}
            fieldError={fieldErrors.name}
            onChange={() => handleInputChange("name")}
            isRequired={true}
            validate={[Validations.required]}
            component={InputField}
            type="text"
            placeholder={localize("downloads", "Név")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("name") !== -1
                  ? false
                  : true
                : false
            }
          />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={4} lg={3}>
          <Field
            name="tags"
            onChangeTags={tags => this.handleSetTags("tags", tags)}
            label={localize("downloads", "Címkék")}
            component={TagInput}
            isViewPage={isViewPage}
            tagPath={get(this.props, "baseFields.tags.url", null)}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={4} lg={3}>
          <Field
            name="description"
            onChange={() => handleInputChange("description")}
            label={localize("downloads", "Leírás")}
            component={InputField}
            isViewPage={isViewPage}
            fieldError={fieldErrors.description}
          />
        </Grid>
        <Grid item={true} xs={12}>
          {isViewPage && this.props.initialValues ? (
            <a
              target="_blank"
              href={
                process.env.REACT_APP_HOST +
                "/v1/files/download/" +
                this.props.initialValues.file_id +
                "?accessToken=" +
                localStorage.getItem("access_token").replace("Bearer ", "")
              }
            >
              {this.props.initialValues.file_name ||
                this.props.initialValues.file_id}
            </a>
          ) : (
            <SimpleFileInput
              noImageView={true}
              name="file_id"
              label={localize("downloads", "Fájl")}
              addedFile={this.handleFileChange.bind(this, false)}
              removeFile={this.handleFileChange.bind(this, true)}
              files={
                this.props.initialValues && this.props.initialValues.file_id
                  ? [
                      {
                        id: this.props.initialValues.file_id,
                      url: this.props.initialValues.file
                      }
                    ]
                  : []
              }
              maxFiles={1}
              isUpdate={
                this.props.initialValues && this.props.initialValues.id
                  ? true
                  : false
              }
              isViewPage={isViewPage}
              fieldError={fieldErrors.file_id}
            />
          )}
        </Grid>
      </EditForm>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "downloads",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(DownloadsForm);

export default connect(
  state => ({
    initialValues: state.downloads.viewData
  }),
  null
)(UsersReduxForm);
