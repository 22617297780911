const downloadButtonStyle = theme => ({
  colorPrimary: {
    color: theme.palette.primary.main + "!important"
  },
  colorSecondary: {
    color: theme.palette.secondary.main + "!important"
  },
  colorDefault: {
    color: theme.palette.text.secondary + "!important"
  }
});

export default downloadButtonStyle;
