import React, {Fragment} from 'react';

import CruleForm from './CruleForm';
import Edit from '../../../../../Components/Edit/Edit';

class EditCrule extends React.Component {

    createSavedRedirect = () => {
        this.props.handleRequestClose();
    };

    handleGetFormInfo = () => {
        return this.props.getFormInfo('c-rules');
    }
 
  render() {
    return (
        <Fragment>
            <Edit
                isViewPage={false}
                createSavedRedirect={this.createSavedRedirect.bind(this)}
                handleGetFormInfo={this.handleGetFormInfo.bind(this)}
                {...this.props}
            >
                <CruleForm {...this.props} isViewPage={false} />
            </Edit>
        </Fragment>

    );
  }

}

export default EditCrule;