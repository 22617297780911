import * as React from "react";
import * as PropTypes from "prop-types";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  groupButton: {
    verticalAlign: "middle",
    display: "inline-block",
    padding: 0,
    width: 16,
    height: 16,
    marginRight: theme.spacing.unit
  },
  toggleCellIcon: {
    width: 16,
    height: 16
  }
});

const IconBase = React.memo(
  ({ expanded, classes, className, ...restProps }) => (
    <IconButton
      className={classNames(classes.groupButton, className)}
      {...restProps}
    >
      {expanded ? (
        <ExpandMore className={classes.toggleCellIcon} />
      ) : (
        <ChevronRight className={classes.toggleCellIcon} />
      )}
    </IconButton>
  )
);

IconBase.propTypes = {
  expanded: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

IconBase.defaultProps = {
  className: undefined
};

export default withStyles(styles)(IconBase);
