import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";


import List from "../../../../Components/List/List";
import ContainerHeader from "../../../../Components/ContainerHeader";
import localize from "helpers/localize";
class DistributorsList extends PureComponent {
  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("distributors", "Viszonteladók")}</title>
        </Helmet>
        <ContainerHeader title={localize("distributors", "Viszonteladók")} />

        <List {...this.props} />
      </div>
    );
  }
}

export default DistributorsList;
