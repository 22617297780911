import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText } from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
const styles = (/*theme*/) => ({
  "@global": {
    ".tox-notifications-container": { display: "none!important" },
    ".tox .tox-dialog-wrap__backdrop": { zIndex: "0!important" },
    ".tox.tox-tinymce": {
      borderRadius: "4px!important"
    },
    ".wysiwyg-focused .tox.tox-tinymce": {
      boxShadow: "#8a5e87 0 0 0 1px",
      borderColor: "#8a5e87"
    }
  }
});

class Wysiwyg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      waitingFirstValue: true //Egy hack valamiért nem töltötte be mindig az initial értéket ez a mocsok
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.value &&
      this.props.value !== "" &&
      this.state.waitingFirstValue
    ) {
      this.setState({ waitingFirstValue: false });
    }
  }

  handleEditorChange = e => {
    const { onChange } = this.props;
    onChange && onChange(e.target.getContent());
  };

  handleBlur = (/*evt*/) => {
    this.setState({ focused: false });
  };
  handleFocus = (/*evt*/) => {
    this.setState({ focused: true });
  };

  render() {
    const {
      id,
      label,
      fullWidth,
      onKeyPress,
      onEnter,
      error,
      helperText,
      mask,
      isRequired,
      onChange,
      InputProps, //Remove Material InputProps
      value,
      toolbar,
      plugins,
      menubar,
      ...rest
    } = this.props;

    const { focused, waitingFirstValue } = this.state;
    //console.log(id, InputProps);;
    /* eslint-disable no-unused-vars */

    /* eslint-enable no-unused-vars */
    return (
      <FormControl
        //key={this.state.time}
        fullWidth={fullWidth}
        error={error ? true : false}
        className={focused ? "wysiwyg-focused" : ""}
        style={{ paddingTop: label && label !== "" ? 16 : 0 }}
      >
        <InputLabel
          shrink
          htmlFor={id}
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontWeight: isRequired ? "bold" : "normal"
          }}
          nolabel={label ? undefined : 1}
          error={error ? true : false}
        >
          {label}
          {isRequired ? " *" : ""}
        </InputLabel>
        <Editor
          //id={id}
          //{...(value && value !== "" ? { initialValue: value } : {})}
          initialValue={value || ""}
          {...(waitingFirstValue && value && value !== "" ? { value } : {})}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          init={{
            entity_encoding: "raw",
            language_url: "/hu_HU.js",
            language: "hu_HU",
            height: 500,
            menubar: menubar ? true : false,
            plugins: plugins || [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount"
            ],
            toolbar:
              toolbar ||
              "undo redo | formatselect | bold italic backcolor | \
              link | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help"
          }}
          onChange={this.handleEditorChange}
          apiKey="ts3cl7hk61cu1t79a00cugc9bsdvnayo7pvfe20re8xwzbtu"
        />
        {(error || helperText) && (
          <FormHelperText error={error ? true : false}>
            {error || helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

Wysiwyg.defaultProps = {
  id: "bootstrap-input"
};

Wysiwyg.propTypes = {
  isRequired: PropTypes.bool,
  onChange: PropTypes.func
};

export default withStyles(styles)(Wysiwyg);
