import React, { Fragment, PureComponent } from "react";
import { Helmet } from "react-helmet";
import List from "../../../../../Components/List/List";
import ContainerHeader from "../../../../../Components/ContainerHeader";
import Navbar from "../../Navbar";
import ModifyPermission from "../../../../Permissions/ModifyPermission";
import Button from "@material-ui/core/Button/Button";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import EditSticker from "../EditSticker";
import Modal from "Components/Modal/Modal";
import StickerCreate from "./StickerCreate";
import StickerRangeDelete from "./StickerRangeDelete";
import restHelper from "helpers/restHelper";
import ModalConfirm from "Components/Modal/ModalConfirm";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
import localize from "helpers/localize";

class StickersList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      keyword: null,
      search: null,
      filters: null,
      perPage: null,
      open: false,
      openCreate: false,
      openRangeDeleteModal: false,
      loadingCreate: false,
      loadingRangeDelete: false
    };
    this.stickerCreate = React.createRef();
    this.stickerRangeDelete = React.createRef();
    this.modal = null;
  }

  getList = (page, keyword, sort, filters, perPage) => {
    this.setState({
      page,
      keyword,
      sort,
      filters,
      perPage
    });
    return this.props.getList(page, keyword, sort, filters, perPage);
  };

  handleRequestClose = () => {
    this.setState({ open: false });
    this.refreshList();
  };

  refreshList = () => {
    const { page, keyword, sort, filters, perPage } = this.state;
    this.getList(page, keyword, sort, filters, perPage);
  };

  customButtons = () => {
    return (
      <Fragment>
        <ModifyPermission module="stickers">
          <div className="col-auto mr-auto">
            <Button
              color="primary"
              onClick={() => this.setState({ open: true })}
            >
              {localize("stickers", "Matrica import")}
            </Button>
            <Button color="primary" onClick={this.openCreateModal}>
              {localize("stickers", "Létrehozás")}
            </Button>
            <Button color="primary" onClick={this.openRangeDeleteModal}>
              {localize("stickers", "Tartomány törlése")}
            </Button>
          </div>
        </ModifyPermission>
      </Fragment>
    );
  };

  openCreateModal = () => {
    this.setState({ openCreate: true });
  };

  closeCreateModal = () => {
    this.setState({ openCreate: false });
  };
  openRangeDeleteModal = () => {
    this.setState({ openRangeDeleteModal: true });
  };

  closeRangeDeleteModal = () => {
    this.setState({ openRangeDeleteModal: false });
  };

  acceptCreateModal = () => {
    if (
      this.stickerCreate &&
      this.stickerCreate.current &&
      this.stickerCreate.current.state.rows
    ) {
      /*const rows = this.stickerCreate.current.state.rows.map(row => {
        return {
          ...row,
          ap_filter:
            row.ap_filter && Array.isArray(row.ap_filter)
              ? row.ap_filter.join(";")
              : null
        };
      });*/
      const errors = this.stickerCreate.current.getErrors();
      if (errors) {
        console.log("ERROR", errors);
        return;
      }

      if (this.stickerCreate.current.needConfirm()) {
        this.modal
          .open(
            localize("basic", "Megerősítés"),
            localize(
              "stickers",
              "A lista 50 darabszám feletti tartományt tartalmaz. Biztosan folytatja?"
            ),
            null,
            localize("basic", "Igen"),
            localize("basic", "Mégsem")
          )
          .then(() => {
            this.saveStickers();
            this.modal.close();
          });
      } else {
        this.saveStickers();
      }
    }
  };
  acceptRangeDeleteModal = () => {
    if (
      this.stickerRangeDelete &&
      this.stickerRangeDelete.current //&&
      //this.stickerRangeDelete.current.state.value
    ) {
      /*const rows = this.stickerCreate.current.state.rows.map(row => {
        return {
          ...row,
          ap_filter:
            row.ap_filter && Array.isArray(row.ap_filter)
              ? row.ap_filter.join(";")
              : null
        };
      });*/
      if (!this.stickerRangeDelete.current.validateValue()) {
        return;
      }
      if (this.stickerRangeDelete.current.needConfirm()) {
        this.modal
          .open(
            localize("basic", "Megerősítés"),
            localize(
              "stickers",
              "A tartomány 50 darabszám feletti matricaszámot tartalmaz. Biztosan törli?"
            ),
            null,
            localize("basic", "törlés"),
            localize("basic", "Mégsem")
          )
          .then(() => {
            this.deleteStickers();
            this.modal.close();
          });
      } else {
        this.deleteStickers();
      }
    }
  };

  deleteStickers = () => {
    //console.log("deleteStickers");

    this.setState({ loadingRangeDelete: true });
    const params = this.stickerRangeDelete.current.getValueParams();

    restHelper
      .remove("stickers", null, {
        start: params.start,
        end: params.end
      })
      .then(response => {
        this.setState({
          openRangeDeleteModal: false,
          loadingRangeDelete: false
        });
        globalMessage.success(localize("basic", "Sikeres törlés"));
        this.refreshList();
      })
      .catch(() => {
        this.setState({ loadingRangeDelete: false });
      });
  };

  saveStickers = () => {
    this.setState({ loadingCreate: true });
    restHelper
      .create("stickers", "StickerImportForm", {
        rows: this.stickerCreate.current.transformRows()
      })
      .then(response => {
        this.setState({ openCreate: false, loadingCreate: false });
        globalMessage.success(localize("basic", "Sikeres létrehozás"));
        this.refreshList();
      })
      .catch(() => {
        this.setState({ loadingCreate: false });
      });
  };

  render() {
    const {
      openCreate,
      loadingCreate,
      openRangeDeleteModal,
      loadingRangeDelete
    } = this.state;
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("stickers", "Matrica adatok")}</title>
        </Helmet>
        <Navbar {...this.props} />
        <ContainerHeader title={localize("stickers", "Matrica adatok")} />
        <Modal
          open={openCreate}
          loading={loadingCreate}
          title={localize("stickers", "Matricák hozzáadása")}
          //overflowVisibleBody
          maxWidth="lg"
          onAccept={this.acceptCreateModal}
          onCancel={this.closeCreateModal}
          onClose={this.closeCreateModal}
          disableIcon
          keepMounted={false}
          positionTop
        >
          <StickerCreate ref={this.stickerCreate} />
          <div style={{ height: 300 }} />
        </Modal>
        <Modal
          open={openRangeDeleteModal}
          loading={loadingRangeDelete}
          title={localize("stickers", "Tartomány törlése")}
          //overflowVisibleBody
          //maxWidth="lg"
          yesText={localize("basic", "Törlés")}
          onAccept={this.acceptRangeDeleteModal}
          onCancel={this.closeRangeDeleteModal}
          onClose={this.closeRangeDeleteModal}
          disableIcon
          keepMounted={false}
          //positionTop
        >
          <StickerRangeDelete ref={this.stickerRangeDelete} />
        </Modal>
        {this.state.open && (
          <Dialog
            open={this.state.open}
            maxWidth="md"
            fullWidth={true}
            scroll="body"
          >
            <DialogTitle>{localize("stickers", "Matrica import")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <EditSticker
                  {...this.props}
                  handleRequestClose={this.handleRequestClose.bind(this)}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleRequestClose.bind(this)}
                variant="contained"
                color="secondary"
                component="span"
              >
                {localize("basic", "Bezár")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <ModalConfirm onRef={ref => (this.modal = ref)} />
        <List
          {...this.props}
          disableViewIcon={true}
          disableEditIcon={true}
          disabledCreate={true}
          customButtons={this.customButtons.bind(this)}
          getList={this.getList.bind(this)}
          reset={() => {}}
        />
      </div>
    );
  }
}

export default StickersList;
