import axios from "axios";
import qs from "qs";
import { get } from "lodash";

const ORDERS_LIST_LOAD = "ORDERS_LIST_LOAD";
const ORDERS_LIST_SUCCESS = "ORDERS_LIST_SUCCESS";
const ORDERS_LIST_FAILED = "ORDERS_LIST_FAILED";
const ORDERS_SET_LOADING = "ORDERS_SET_LOADING";
const ORDERS_LIST_HIGHTLIGHT = "ORDERS_LIST_HIGHTLIGHT";

const ORDERS_FORM_LOAD = "ORDERS_FORM_LOAD";
const ORDERS_FORM_SUCCESS = "ORDERS_FORM_SUCCESS";
const ORDERS_FORM_FAILED = "ORDERS_FORM_FAILED";

const ORDER_DELETE_LOAD = "ORDER_DELETE_LOAD";
const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
const ORDER_DELETE_FAILED = "ORDER_DELETE_FAILED";

const ORDER_EDIT_LOAD = "ORDER_EDIT_LOAD";
const ORDER_EDIT_SUCCESS = "ORDER_EDIT_SUCCESS";
const ORDER_EDIT_FAILED = "ORDER_EDIT_FAILED";

const ORDER_VIEW_LOAD = "ORDER_VIEW_LOAD";
const ORDER_VIEW_SUCCESS = "ORDER_VIEW_SUCCESS";
const ORDER_VIEW_FAILED = "ORDER_VIEW_FAILED";

const ORDERS_RESET = "ORDERS_RESET";
const ORDERS_RESET_FORM = "ORDERS_RESET_FORM";

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit: process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: "",
  orderMode: "ASC",
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null,
  reload: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ORDERS_RESET:
      return {
        ...initialState
      };
    case ORDERS_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case ORDER_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case ORDERS_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false,
        reload: false
      };
    case ORDER_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case ORDER_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case ORDERS_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case ORDERS_FORM_FAILED:
    case ORDER_EDIT_FAILED:
    case ORDER_DELETE_FAILED:
    case ORDERS_LIST_FAILED:
    case ORDER_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false
      };
    case ORDERS_LIST_HIGHTLIGHT:
      return {
        ...state,
        success: true,
        failed: false,
        loading: false,
        reload: true
      };
    case ORDERS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode,
        loading: false
      };
    case ORDER_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        viewData: action.viewData,
        commentThread: action.commentThread || false,
        viewLoading: false
      };
    case ORDERS_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case ORDER_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case ORDER_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: action.id,
        viewLoading: false
      };
    case ORDERS_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: ORDERS_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: ORDERS_RESET_FORM
  });
};

export const updateOrders = (model, id, data) => dispatch => {
  dispatch({
    type: ORDER_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .put("/v1/shop-orders/" + id, obj)
    .then(response => {
      dispatch({
        type: ORDER_EDIT_SUCCESS
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: ORDER_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const viewOrder = id => dispatch => {
  dispatch({
    type: ORDER_VIEW_LOAD
  });
  return axios
    .get("/v1/shop-orders/" + id)
    .then(response => {
      dispatch({
        type: ORDER_VIEW_SUCCESS,
        viewData: get(response, "data.data"),
        commentThread: get(response, "data.commentThread", false),
        loading: false
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: ORDER_VIEW_FAILED,
        message: get(error, "response.data.message", null),
        loading: false
      });
      throw error.response;
    });
};

export const createOrders = (model, data) => dispatch => {
  dispatch({
    type: ORDER_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .post("/v1/shop-orders", obj)
    .then(response => {
      dispatch({
        type: ORDER_EDIT_SUCCESS,
        id: get(response, "data.data.id", null)
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: ORDER_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const orderDelete = id => dispatch => {
  dispatch({
    type: ORDER_DELETE_LOAD
  });
  return axios
    .delete("/v1/shop-orders/" + id)
    .then(response => {
      dispatch({
        type: ORDER_DELETE_SUCCESS,
        id
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: ORDER_DELETE_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: ORDERS_SET_LOADING,
    loading
  });
};

export const ordersList = (
  page,
  keyword,
  sort,
  filters,
  perPage
) => dispatch => {
  dispatch({
    type: ORDERS_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    "per-page": perPage
  };

  params[filters.model] = filters.filters;

  return axios
    .get(
      "/v1/shop-orders",
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then(response => {
      let sort = get(response, "data.sort", null),
        mode = "ASC";
      if (sort && sort.indexOf("-") !== -1) {
        mode = "DESC";
        sort = sort.substr(1);
      }
      dispatch({
        type: ORDERS_LIST_SUCCESS,
        data: get(response, "data.data", null),
        page: get(response, "data.page", 0),
        limit: get(response, "data.per-page", 0),
        total: get(response, "data.total", 0),
        order: sort,
        orderMode: mode,
        loading: false
      });
      return response; // todo  use response.data
    })
    .catch(error => {
      dispatch({
        type: ORDERS_LIST_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};
