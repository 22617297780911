import localize from "helpers/localize";
import { apMask } from "helpers/maskHelper";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import EditForm from "../../../../../Components/Forms/Builder/EditForm";
import DateField from "../../../../../Components/Forms/Fields/Date";
import InputField from "../../../../../Components/Forms/Fields/Input";
import SelectField from "../../../../../Components/Forms/Fields/Select";
import Validations from "../../../../../Components/Forms/Validations";

class CashregisterReadView extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  render() {
    const { handleInputChange, fieldErrors, isViewPage } = this.props;

    let dphs = [];
    Object.keys(get(this.props, "baseFields.dph.data", {})).forEach(key => {
      dphs.push({ id: key, label: this.props.baseFields.dph.data[key] });
    });

    return (
      <EditForm {...this.props} disabledEditButton={true}>
        <Fragment>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="client_name"
              label={localize("cashregisters", "Cégnév")}
              fieldError={fieldErrors.client_name}
              onChange={() => handleInputChange("client_name")}
              isRequired={true}
              validate={[Validations.required]}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "Cégnév")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="client_tax_number"
              label={localize("cashregisters", "Adószám")}
              fieldError={fieldErrors.client_tax_number}
              onChange={() => handleInputChange("client_tax_number")}
              isRequired={true}
              validate={[Validations.required]}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "Adószám")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="client_home"
              label={localize("cashregisters", "Székhely")}
              fieldError={fieldErrors.client_home}
              onChange={() => handleInputChange("client_home")}
              isRequired={true}
              validate={[Validations.required]}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "Székhely")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="client_yard"
              label={localize("cashregisters", "Telephely")}
              fieldError={fieldErrors.client_yard}
              onChange={() => handleInputChange("client_yard")}
              isRequired={true}
              validate={[Validations.required]}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "Telephely")}
              isViewPage={isViewPage}
            />
          </div>

          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="dts"
              label={localize("cashregisters", "Időbélyeg")}
              fieldError={fieldErrors.dts}
              component={DateField}
              placeholder={localize("cashregisters", "Időbélyeg")}
              onChange={() => handleInputChange("dts")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="dty"
              label={localize("cashregisters", "AEE gyártói típusnév")}
              fieldError={fieldErrors.dty}
              onChange={() => handleInputChange("dty")}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "AEE gyártói típusnév")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="drv"
              label={localize("cashregisters", "AEE hardver verzió")}
              fieldError={fieldErrors.drv}
              onChange={() => handleInputChange("drv")}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "AEE hardver verzió")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="dfw"
              label={localize("cashregisters", "AEE szoftver verzió")}
              fieldError={fieldErrors.dfw}
              onChange={() => handleInputChange("dfw")}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "AEE szoftver verzió")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12">
            <Field
              name="dfh"
              label={localize("cashregisters", "AEE sw checksum")}
              fieldError={fieldErrors.dfh}
              onChange={() => handleInputChange("dfh")}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "AEE sw checksum")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="dft"
              label={localize("cashregisters", "AEE sw update date")}
              fieldError={fieldErrors.dft}
              onChange={() => handleInputChange("dft")}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "AEE sw update date")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="dmt"
              label={localize("cashregisters", "AEE modem")}
              fieldError={fieldErrors.dmt}
              onChange={() => handleInputChange("dmt")}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "AEE modem")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="dmf"
              label={localize("cashregisters", "AEE modem sw")}
              fieldError={fieldErrors.dmf}
              onChange={() => handleInputChange("dmf")}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "AEE modem sw")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="dme"
              label={localize("cashregisters", "IMEI")}
              fieldError={fieldErrors.dme}
              onChange={() => handleInputChange("dme")}
              isRequired={true}
              validate={[Validations.required]}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "IMEI")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="dms"
              label={localize("cashregisters", "IMSI")}
              fieldError={fieldErrors.dms}
              onChange={() => handleInputChange("dms")}
              isRequired={true}
              validate={[Validations.required]}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "IMSI")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="dph"
              label={localize("cashregisters", "Pénztárgép típus")}
              fieldError={fieldErrors.dph}
              onChange={() => handleInputChange("dph")}
              isRequired={true}
              validate={[Validations.required]}
              component={SelectField}
              placeholder={localize("cashregisters", "Pénztárgép típus")}
              isViewPage={isViewPage}
              options={dphs}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="vcc"
              label={localize("cashregisters", "Üzembehelyezési kód")}
              fieldError={fieldErrors.vcc}
              onChange={() => handleInputChange("vcc")}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "Üzembehelyezési kód")}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="apn"
              label={localize("cashregisters", "AP szám")}
              fieldError={fieldErrors.apn}
              onChange={() => handleInputChange("apn")}
              isRequired={true}
              validate={[Validations.required]}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "AP szám")}
              isViewPage={isViewPage}
              {...apMask}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="mis"
              label={localize("cashregisters", "Műszerész igazolvány száma")}
              fieldError={fieldErrors.mis}
              onChange={() => handleInputChange("mis")}
              component={InputField}
              type="text"
              placeholder={localize(
                "cashregisters",
                "Műszerész igazolvány száma"
              )}
              isViewPage={isViewPage}
            />
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <Field
              name="ssg"
              label={localize("cashregisters", "Térerő")}
              fieldError={fieldErrors.ssg}
              onChange={() => handleInputChange("ssg")}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "Térerő")}
              isViewPage={isViewPage}
            />
          </div>
        </Fragment>
      </EditForm>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "cashregister",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(CashregisterReadView);

export default connect(
  state => ({
    initialValues: state.cashregisters.viewData
  }),
  null
)(UsersReduxForm);
