import React from "react";
import PropTypes from "prop-types";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
//import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
// core components
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import modalStyle from "./modalStyle.jsx";
import localize from "helpers/localize.js";

/*function Transition(props) {
  return <Slide direction="down" {...props} />;
}*/

class ModalConfirm extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      open: false,
      title: "",
      subject: null,
      body: "",
      loading: false,
      yesText: localize("basic", "Igen"),
      cancelText: localize("basic", "Mégsem"),
      noText: localize("basic", "Nem"),
      error: null
    };
    this.modalBodyComp = props.modalBodyComp;
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  loading = loading => {
    this.setState({ loading: loading });
  };

  open = (title, body, subject, yesText, cancelText, noText) => {
    return new Promise((resolve, reject) => {
      this.setState({
        open: true,
        title: title,
        subject: subject,
        body: body,
        loading: false,
        yesText: yesText ? yesText : this.state.yesText,
        cancelText:
          cancelText === false
            ? false
            : cancelText
              ? cancelText
              : this.state.cancelText,
        noText: noText,
        error: null
      });
      this.resolve = resolve;
      this.reject = reject;
    });
  };

  resolve = () => {};

  reject = () => {};

  close = () => {
    this.setState({
      open: false,
      loading: false
    });
    //this.reject();
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  hide = () => {
    this.setState({
      open: false
    });
  };

  error = error => {
    this.setState({
      error: error
    });
  };

  cancel = () => {
    this.setState({
      open: false,
      loading: false
    });
    //this.reject();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  accept = result => {
    this.resolve(result);
    if (this.props.onAccept) {
      this.props.onAccept(result);
    }
  };

  render() {
    const {
      classes,
      modalBodyComp,
      HeaderIcon,
      disableIcon,
      htmlContent,
      maxWidth,
      disableCloseIcon
    } = this.props;
    const Icon = HeaderIcon ? HeaderIcon : WarningIcon;
    const {
      open,
      title,
      body,
      subject,
      loading,
      yesText,
      cancelText,
      noText,
      error
    } = this.state;
    return (
      <Dialog
        style={{ zIndex: 1350 }}
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={open}
        maxWidth={maxWidth}
        //transition={Transition}
        keepMounted
        onClose={this.close}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          {disableCloseIcon !== true && (
            <Button
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="primary"
              onClick={this.close}
            >
              <CloseIcon className={classes.modalClose} />
            </Button>
          )}
          {!disableIcon && <Icon className={classes.titleIcon} />}
          <h3
            style={disableIcon ? { textIndent: 0, marginTop: 0 } : {}}
            className={classes.modalTitle}
          >
            {title}
          </h3>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          {subject ? (
            <div style={{ textAlign: "center", paddingBottom: 10 }}>
              <b>{subject}</b>
            </div>
          ) : (
            ""
          )}
          {htmlContent ? (
            <div
              style={{ textAlign: "center" }}
              dangerouslySetInnerHTML={{ __html: body }}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              {modalBodyComp ? modalBodyComp : body}
            </div>
          )}
          {error ? <div className={classes.modalError}>{error}</div> : ""}
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          {cancelText !== false && (
            <Button
              onClick={this.cancel}
              color="secondary"
              disabled={loading === true}
            >
              {cancelText}
            </Button>
          )}
          {noText ? (
            <Button
              onClick={() => this.accept(false)}
              color="secondary"
              disabled={loading === true}
            >
              {noText}
            </Button>
          ) : (
            " "
          )}
          <Button
            onClick={() => this.accept(true)}
            color="primary"
            disabled={loading === true}
          >
            {yesText}
          </Button>
          {loading === true ? (
            <CircularProgress
              className={classes.progress}
              color="inherit"
              size={24}
            />
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

ModalConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  onRef: PropTypes.func,
  HeaderIcon: PropTypes.any,
  disableIcon: PropTypes.bool,
  htmlContent: PropTypes.bool,
  disableCloseIcon: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"])
};

export default withStyles(modalStyle)(ModalConfirm);
