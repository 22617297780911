/* eslint-disable no-console */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
//import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import restHelper from "helpers/aee/restHelper";
import requestHelper from "helpers/aee/requestHelper";
import DataProvider from "Components/New/DataProvider/DataProvider";
import DataTableHandson from "Components/New/DataTable/DataTableHandson/DataTableHandson";
import withLock from "./withLock";
import localize from "helpers/localize";
const LC = "cloud"; //Localization category

const STATUSES = {
  ORIGINAL: "Eredeti",
  CHANGED: "Módosított",
  DELTED: "Törölt",
  NEW: "Új",
  transformStatus: status => {
    switch (status) {
      case 0:
        return STATUSES.DELTED;
      case 1:
        return STATUSES.CHANGED;
      case 2:
        return STATUSES.NEW;
      default:
        return STATUSES.ORIGINAL;
    }
  },
  reverseStatus: status => {
    switch (status) {
      case STATUSES.DELTED:
        return 0;
      case STATUSES.CHANGED:
        return 1;
      case STATUSES.NEW:
        return 2;
      default:
        return -1;
    }
  }
};

const styles = theme => ({
  /*"@global": {
    ".rbt-input-hint": {
      //backgroundColor: "red!important",
      width: "100%",
      top: "2px!important"
    }
  },*/
  rowDeleted: {
    backgroundColor: "red!important",
    color: "white"
  },
  rowChanged: {
    backgroundColor: "green!important",
    color: "white"
  },
  marginLeftAuto: {
    marginLeft: "auto"
  },
  marginLeft: {
    marginLeft: theme.spacing.unit
  },
  marginLeftRight: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing.unit
  },
  infoIcon: {
    color: theme.palette.info.main,
    marginRight: theme.spacing.unit
  },
  infoText: {
    fontStyle: "italic"
  },
  modalQueue: {
    marginLeft: theme.spacing.unit,
    fontWeight: "bold"
  },
  uptoDateIcon: {
    fill: theme.palette.primary.main
  }
});
class Departments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.hotTableComponent = React.createRef(); // Excel tábla referencia változója
    this.dataProviderRef = React.createRef(); // Dataprovider referencia változója
  }
  /**
   * A felület megtekintesékor zajlik le
   */
  componentDidMount() {
    console.log("Departments.componentDidMount");
    this.setToolbarItems(this.props);
    if (this.props.onFcmRef) {
      this.props.onFcmRef(this.onFcm);
    }
  }

  componentWillReceiveProps(nextPorps) {
    if (nextPorps.editMode !== this.props.editMode) {
      this.setToolbarItems(nextPorps);
    }
  }

  onFcm = () => {
    if (this.dataProviderRef.current) {
      this.dataProviderRef.current.fetchData();
    }
  };

  setToolbarItems = props => {
    const { /*classes,*/ editMode, setToolbarItems } = props;
    setToolbarItems(
      <Fragment>
        {editMode && (
          <Button
            size="small"
            variant="contained"
            //className={classes.marginLeftAuto}
            onClick={this.uploadAllData}
          >
            {localize(LC, "Gyűjtők felküldése")}
          </Button>
        )}
      </Fragment>
    );
  };

  /**
   * Lekéri az excel táblázat komponenstől az összes adatot
   */
  getTableData = () => {
    return this.hotTableComponent.current.hotInstance.getSourceData();
  };

  /**
   * Status mező átalakítása a szerver számára értelmezhető formába
   */
  mapTableData = data => {
    return data.map(item => {
      let { _status, status, _original, ...rest } = item; // eslint-disable-line no-unused-vars
      delete rest[0];
      delete rest[1];
      delete rest[2];
      delete rest[3];
      delete rest[4];
      delete rest[5];
      delete rest[6];
      return rest;
    });
  };

  /**
   * Feltölti a változtatott, törölt és új sorokat a szerverre
   */
  uploadChangedData = () => {
    const { cashregister_id, client_id, onUpdated } = this.props;

    const filtered = this.getTableData().filter(item => {
      return item.status !== STATUSES.DELTED; //Csak a változott sorok
    });

    const mapData = this.mapTableData(filtered);

    requestHelper({
      url: "cashregister-departments/update",
      method: "PATCH",
      ap: cashregister_id,
      client: client_id,
      data: mapData
    }).then(response => {
      console.log(response);
      if (response.data.success) {
        //this.setState({ editMode: false });
        onUpdated();
      }
    });
    /*.catch(error => {
        //alert(JSON.stringify(error.response));
      })*/
  };

  /**
   * Feltölti az összes terméksort a szerverre
   */
  uploadAllData = () => {
    const { cashregister_id, client_id, setEditMode } = this.props;
    const filtered = this.getTableData().filter(item => {
      return item.no ? true : false;
    });

    const mapData = this.mapTableData(filtered);

    requestHelper({
      url: "cashregister-departments/update",
      method: "PUT",
      ap: cashregister_id,
      client: client_id,
      data: mapData
    }).then(response => {
      console.log(response);
      if (response.data.success) {
        //this.setState({ editMode: false });
        setEditMode(false);
      }
    }); /*
      .catch(error => {
        //alert(JSON.stringify(error.response));
      });*/
  };
  export = () => {
    alert(localize(LC, "Fejlesztés alatt"));
  };

  import = () => {
    alert(localize(LC, "Fejlesztés alatt"));
  };
  render() {
    const { /*classes,*/ cashregister_id, client_id, editMode } = this.props;

    return (
      <div>
        <DataProvider
          ref={this.dataProviderRef}
          url={"cashregister-departments"}
          restHelperOptions={{ ap: cashregister_id, client: client_id }}
          restHelper={restHelper}
          component={DataTableHandson}
          readOnly={!editMode}
          hotTableRef={ref => (this.hotTableComponent = ref)}
          columns={{
            no: {
              type: "numeric",
              readOnlyOnOriginal: true,
              validator: function validateId(value, callback) {
                if (isNaN(value)) {
                  return callback(false);
                }
                var data = this.instance.getDataAtCol(this.col);
                var index = data.indexOf(value);
                var valid = true;
                if (index > -1 && this.row !== index) {
                  valid = false;
                }
                return callback(valid);
              }
            },
            name: {
              validator: function validateId(value, callback) {
                if (value.length > 18) {
                  return callback(false);
                }
                return callback(true);
              }
            },
            pri: {
              type: "numeric",
              numericFormat: {
                pattern: "$ 0,0",
                culture: "hu-HU"
              }
            },
            limit: {
              type: "numeric"
            },
            close: {
              type: "dropdown",
              source: [0, 1]
            },
            collect: {
              validator: /^(A[0-9][0-9])$/
            }
          }}
        />
      </div>
    );
  }
}

Departments.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //Ez igazából az AP szám
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  editMode: PropTypes.bool,
  setToolbarItems: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired //Szerkesztés mód be illetve kikapcsolása
};

// export default withStyles(styles)(withRouter(Departments));
export default withLock(
  withStyles(styles)(Departments),
  "FULL_DOWNLOAD_DEP_DATA",
  {
    dataTitle: "Gyűjtők",
    title: "Gyűjtők",
    forceUpdate: false
  }
);
