import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import authRules from "helpers/authRules";
import localize from "helpers/localize";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import {
  AEEREGS_PERMISSION,
  ANNUAL_REVIEWS_PERMISSION,
  BANNED_CASHREGISTERS_PERMISSION,
  CASHREGISTERS_PERMISSION,
  CRULES_PERMISSION,
  EVENT_TYPES_PERMISSION,
  REREGISTER_PERMISSION,
  SERVICE_CHANGES_PERMISSION,
  SMODE_PERMISSION,
  STICKERS_PERMISSION
} from "constans/permissions";
import withRouter from "react-router/es/withRouter";

const tabList = [
  {
    value: "cashregisters",
    permission: CASHREGISTERS_PERMISSION,
    title: "Pénztárgépek"
  },
  {
    value: "banned-cashregisters",
    permission: BANNED_CASHREGISTERS_PERMISSION,
    title: "Tiltott AP számok"
  },
  {
    value: "aee-regs",
    permission: AEEREGS_PERMISSION,
    title: "AEE Regisztráció"
  },
  {
    value: "s-mode",
    permission: SMODE_PERMISSION,
    title: "S-mód"
  },
  {
    value: "reregister",
    permission: REREGISTER_PERMISSION,
    title: "Átszemélyesítés"
  },
  {
    value: "annual-reviews",
    permission: ANNUAL_REVIEWS_PERMISSION,
    title: "Éves Felülvizsgálat"
  },
  {
    value: "event-types",
    permission: EVENT_TYPES_PERMISSION,
    title: "Események Tipusok"
  },
  {
    value: "stickers",
    permission: STICKERS_PERMISSION,
    title: "Matrica Adatok"
  },
  /*{
    value: "tokens",
    permission: STICKERS_PERMISSION,
    title: "Token Adatok"
  },*/
  { value: "c-rules", permission: CRULES_PERMISSION, title: "Adatpárok" },
  {
    value: "service-changes",
    permission: SERVICE_CHANGES_PERMISSION,
    title: "Szerviz váltás"
  },
  {
    value: "cashregister-worksheets",
    permission: SERVICE_CHANGES_PERMISSION,
    title: "Munkalapok"
  }
];

class Navbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: window.location.pathname.split("/")[1]
    };
  }

  handleChange = (evt, value) => {
    this.props.history.push(`/${value}`);
  };

  render() {
    const { value } = this.state;

    return (
      <AppBar className="mb-3" position="static">
        <Tabs
          variant="scrollable"
          scrollButtons="on"
          value={value}
          onChange={this.handleChange}
        >
          {tabList
            .filter(item => authRules.canViewByKey(item.permission))
            .map((item, index) => (
              <Tab
                key={index}
                label={localize("menu", item.title)}
                value={item.value}
              />
            ))}
        </Tabs>
      </AppBar>
    );
  }
}

Navbar.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Navbar);
