import axios from "axios";
import restHelper from "helpers/restHelper";
import { get, values, sumBy, find } from "lodash";

const CART_FETCH_LOAD = "CART_FETCH_LOAD";
const CART_FETCH_SUCCESS = "CART_FETCH_SUCCESS";
const CART_FETCH_FAILED = "CART_FETCH_FAILED";

const CART_ADD_LOAD = "CART_ADD_LOAD";
const CART_ADD_SUCCESS = "CART_ADD_SUCCESS";
const CART_ADD_FAILED = "CART_ADD_FAILED";

const CART_UPDATE_LOAD = "CART_UPDATE_LOAD";
const CART_UPDATE_SUCCESS = "CART_UPDATE_SUCCESS";
const CART_UPDATE_FAILED = "CART_UPDATE_FAILED";

const CART_REMOVE_LOAD = "CART_REMOVE_LOAD";
const CART_REMOVE_SUCCESS = "CART_REMOVE_SUCCESS";
const CART_REMOVE_FAILED = "CART_REMOVE_FAILED";

const CART_CLEAR = "CART_CLEAR";

/**
 * Get total count of cart items
 *
 * @param {array} items cart item list
 */
const getTotal = items => {
  return sumBy(items, item => item.quantity);
};
const getTotalPrice = items => {
  return sumBy(items, item => item.quantity * item.price);
};

const initialState = {
  loading: false,
  success: true,
  message: null,
  items: [],
  total: 0,
  totalPrice: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Fetch
    case CART_FETCH_LOAD:
      return {
        ...state,
        loading: true
      };
    case CART_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: null,
        items: action.items,
        total: getTotal(action.items),
        totalPrice: getTotalPrice(action.items)
      };
    case CART_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.message
      };
    //Create
    case CART_ADD_LOAD:
      return {
        ...state,
        loading: true
      };
    case CART_ADD_SUCCESS:
      var items = state.items.slice();
      var item = find(items, function (obj) {
        return obj.id === action.item.id;
      });
      if (item) {
        items = state.items.map(element =>
          element.id === action.item.id
            ? { ...element, ...action.item }
            : { ...element }
        );
      } else {
        items.push(action.item);
      }

      return {
        ...state,
        loading: false,
        success: true,
        message: null,
        items,
        total: getTotal(items),
        totalPrice: getTotalPrice(items)
      };
    case CART_ADD_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.message
      };
    //update
    case CART_UPDATE_LOAD:
      return {
        ...state,
        loading: true
      };
    case CART_UPDATE_SUCCESS:
      var items = state.items.map(element =>
        //element.id === action.item.id ? { ...action.item } : { ...element }
        element.id === action.item.id
          ? { ...element, ...action.item }
          : { ...element }
      );
      return {
        ...state,
        loading: false,
        success: true,
        message: null,
        items,
        total: getTotal(items),
        totalPrice: getTotalPrice(items)
      };
    case CART_UPDATE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.message
      };
    //remove
    case CART_REMOVE_LOAD:
      return {
        ...state,
        loading: true
      };
    case CART_REMOVE_SUCCESS:
      var items = state.items.filter(element => element.id !== action.id);
      return {
        ...state,
        loading: false,
        success: true,
        message: null,
        items,
        total: getTotal(items),
        totalPrice: getTotalPrice(items)
      };
    case CART_REMOVE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.message
      };
    //clear
    case CART_CLEAR:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

/**
 * Get list of cart items
 */
export const fetch = () => dispatch => {
  dispatch({
    type: CART_FETCH_LOAD
  });
  return restHelper
    .index("shop/cart")
    .then(response => {
      var items = get(response, "data", []);

      dispatch({
        type: CART_FETCH_SUCCESS,
        items: items || []
      });

      return items;
    })
    .catch(error => {
      dispatch({
        type: CART_FETCH_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const add = (product_id, quantity) => dispatch => {
  dispatch({
    type: CART_ADD_LOAD
  });

  return restHelper
    .create("shop/cart", "CartItemForm", {
      product_id,
      quantity
    })
    .then(response => {
      let item = get(response, "data", {});
      dispatch({
        type: CART_ADD_SUCCESS,
        item
      });

      return item;
    })
    .catch(error => {
      dispatch({
        type: CART_ADD_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const update = (cart_item_id, quantity) => dispatch => {
  dispatch({
    type: CART_UPDATE_LOAD
  });
  return restHelper
    .update("shop/cart", "CartItemForm", undefined, { cart_item_id, quantity })
    .then(response => {
      const item = get(response, "data", {});

      dispatch({
        type: CART_UPDATE_SUCCESS,
        item: {
          //TODO: hack
          id: cart_item_id,
          quantity: quantity
        }
      });

      return item;
    })
    .catch(error => {
      dispatch({
        type: CART_UPDATE_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const remove = id => dispatch => {
  dispatch({
    type: CART_REMOVE_LOAD
  });
  return restHelper
    .remove("shop/cart-remove", id)
    .then(response => {
      dispatch({
        type: CART_REMOVE_SUCCESS,
        id
      });
      return response.data;
    })
    .catch(error => {
      dispatch({
        type: CART_REMOVE_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const clear = () => dispatch => {
  dispatch({
    type: CART_CLEAR
  });
};
