/* eslint-disable no-console */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import restHelper from "helpers/aee/restHelper";
import dateHelper from "helpers/dateHelper";
import requestHelper from "helpers/aee/requestHelper";
import DataProvider from "Components/New/DataProvider/DataProvider";
import DataTableHandson from "Components/New/DataTable/DataTableHandson/DataTableHandson";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CancelEditIcon from "@material-ui/icons/Close";
import ExportIcon from "@material-ui/icons/CloudDownload";
import ImportIcon from "@material-ui/icons/CloudUpload";
import RefreshIcon from "@material-ui/icons/Refresh";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import TimerIcon from "@material-ui/icons/Timer";
import Modal from "Components/Modal/Modal";
import UploadButton from "Components/UploadButton";
import fcmHelper from "helpers/fcmHelper";
const LC = "cloud";

const STATUSES = {
  ORIGINAL: "Eredeti",
  CHANGED: "Módosított",
  DELTED: "Törölt",
  NEW: "Új",
  transformStatus: status => {
    switch (status) {
      case 0:
        return STATUSES.DELTED;
      case 1:
        return STATUSES.CHANGED;
      case 2:
        return STATUSES.NEW;
      default:
        return STATUSES.ORIGINAL;
    }
  },
  reverseStatus: status => {
    switch (status) {
      case STATUSES.DELTED:
        return 0;
      case STATUSES.CHANGED:
        return 1;
      case STATUSES.NEW:
        return 2;
      default:
        return -1;
    }
  }
};

const styles = theme => ({
  /*"@global": {
    ".rbt-input-hint": {
      //backgroundColor: "red!important",
      width: "100%",
      top: "2px!important"
    }
  },*/
  rowDeleted: {
    backgroundColor: "red!important",
    color: "white"
  },
  rowChanged: {
    backgroundColor: "green!important",
    color: "white"
  },
  marginLeftAuto: {
    marginLeft: "auto"
  },
  marginLeft: {
    marginLeft: theme.spacing.unit
  },
  marginLeftRight: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing.unit
  },
  infoIcon: {
    color: theme.palette.info.main,
    marginRight: theme.spacing.unit
  },
  infoIconOrange: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing.unit
  },
  infoText: {
    fontStyle: "italic"
  },
  modalQueue: {
    marginLeft: theme.spacing.unit,
    fontWeight: "bold"
  },
  uptoDateIcon: {
    fill: theme.palette.primary.main
  },
  lastRefreshTime: {
    fontSize: 11,
    paddingRight: 8,
    color: theme.palette.text.secondary
  }
});

const LOCK_CAUSE_OUTDATED = "outdated";
const LOCK_CAUSE_IN_QUEUED = "in-queued";
const LOCK_CAUSE_OUT_QUEUED = "out-queued"; //TODO: ez így jó?

/**
 * Alapéertelmezett lock státusz
 */
const defaultLockState = () => {
  return {
    locked: false, // Zárolva van-e az állomány
    message: "", // Zárolási üzenet. A felugró modal címsora
    removable: false, // Törölhető a zárolás
    open: false, // Nyitva van a zárolási modal ablak
    cause: null // Zárolás okának konstans értéke
  };
};

/*TODO: Mikor felküldjük a szerkesztett adatokat és kilépünk a szerkesztésből,
akkor nem lenne jó megmutatni a várható időt, mikor a szinkron teljesül? Ez egy külön timeleft lekérés lenne
Ekkor felmerül a kérdés, hogy nem kéne ezt is lekérdezni a felület megjelenésekor? Így már rögtön látni lehetne,
hogy módosított gyűjtők válrakoznak (amit ugyan a színekből is) és azt is mikor lesz leküldve a pénztárgépnek
*/
export default function withLock(WrappedComponent, lockType, lockParams) {
  class LockHOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        editMode: false, // Szerkesztő mód, false esetén a táblázat csak olvasható és nem jelennek meg a felküldő gombok
        lock: defaultLockState(),
        queueExpires: new Date().getTime() - 10000, //A következő frissítés ekkor fog történni, ebből számolódik a hátralévő idő
        upTopDateExpires: new Date().getTime(), //Eddig érvényes a adatlista, ebből számolódik a hátralévő idő
        updatedTime: null, //Utolsó frissítés ideje
        toolbarItems: null, //Egyedi toolbar elemek a gyerek komponensről állítjuk a setToolbarItems függvénnyel
        modalLoading: false
      };

      this.hotTableComponent = React.createRef(); // Excel tábla referencia változója
      this.queTimer = React.createRef(); // A ceruza melletti visszaszámláló referencia változója
      this.queTimerModal = React.createRef(); // A zárolási modal ablakban található visszaszámláló referencia változója
      this.upToDateTimer = React.createRef(); // A gyűjtő szerkeszthetőségére vonatkozó visszaszámláló referencia változója
      this.cildOnFcm = null; //A wrapped komponens onFcm eseménye
    }

    setToolbarItems = toolbarItems => {
      this.setState({ toolbarItems });
    };
    setEditMode = editMode => {
      this.setState({ editMode });
    };

    onUpdated = cb => {
      //Lekérjük az időket a szervertől
      this.getTimeLefts((queueExpires, upTopDateExpires, updatedTime) => {
        this.setState(
          {
            queueExpires,
            upTopDateExpires,
            updatedTime,
            editMode: false
          },
          () => {
            cb && cb();
          }
        );
      });
    };

    /**
     * A felület elhagyásakor zajlik le
     */
    componentWillUnmount() {
      const { editMode } = this.state;
      //Leiratkozunk a firebase kategóriáról
      fcmHelper.unSubscribe("cloud.cashregisterUpdated");

      //Kitöröljük az összes timert (setInterval)
      this.clearQueueTimer();
      this.clearUpToDateTimer();

      //Ha editmódban vagyunk közöljük a szerverrel, hogy már ne mvagyunk felületen befejeztük a szerkesztést
      if (editMode) {
        this.endEdit(true);
      }
    }

    /**
     * A felület megtekintesékor zajlik le
     */
    componentDidMount() {
      console.log("Departments.componentDidMount");

      //Firebase kategória feliratkozás
      fcmHelper.subscribe(
        "cloud.cashregisterUpdated",
        this.cashregisterUpdated
      );

      //Lekérjük az időket a szervertől
      this.getTimeLefts((queueExpires, upTopDateExpires, updatedTime) => {
        this.setState({
          queueExpires,
          upTopDateExpires,
          updatedTime
        });
      });
    }

    /**
     * Életjel timer indítása (Szerkesztő mód bekapcsolásakor, startEdit)
     */
    startLiveSignalTimerTimer = () => {
      console.log("Departments.startLiveSignalTimer");
      this.clearLiveSignalTimer();
      //TODO: Életjel sűrűségét beállítani
      const SIGNAL_TIMER_TICK = 5000;
      this.liveInterval = setInterval(() => {
        this.liveSignalTick();
      }, SIGNAL_TIMER_TICK);
    };

    /**
     * Életjel küldése a szervernek, hogy szerkesztjük az oldalt
     */
    liveSignalTick = () => {
      console.log("Departments.liveSignalTick");
      //TODO: az életjel ajaxkérésének megvalósítása
    };

    /**
     * Életjel timer törlése
     */
    clearLiveSignalTimer = () => {
      console.log("Departments.clearLiveSignalTimer");
      if (this.liveInterval) {
        clearInterval(this.liveInterval);
      }
    };

    /**
     * Elkérjük a timer időket a szervertől
     */
    getTimeLefts = callBack => {
      //Összefűzve az ajax kérések, hogy csak egy render legyen.
      //Lekérjük a szervertől, hogy várakozik-e a rendszer arra, hogy a pénztárgép felküldje az adatokat
      this.getQueueTimeLeft().then(queueExpires => {
        //Lekérjük a szervertől, hogy várakozik-e a rendszer arra, hogy a pénztárgép felküldje az adatokat
        this.getUpToDateTimeLeft().then(upTopDateExpires => {
          this.getUpdatedTime().then(updatedTime => {
            callBack(queueExpires, upTopDateExpires, updatedTime);

            //Elindítjuk a timereket
            if (queueExpires) {
              this.startQueueTimer();
            }
            if (upTopDateExpires) {
              this.startUpToDateTimer();
            }
          });
        });
      });
    };

    cashregisterUpdated = payload => {
      console.log("Departments.cashregisterUpdated", payload);
      const { cashregister_id } = this.props;
      const { lock } = this.state;
      const { apn } = payload;
      if (apn === cashregister_id) {
        console.log("Departments.cashregisterUpdated", "MATCHED", apn);
        this.clearQueueTimer();

        this.getTimeLefts((queueExpires, upTopDateExpires, updatedTime) => {
          if (lock.open) {
            //Nyitott állapotban mintha megnyomnánk a ceruzát, ami mégegyszer lekérdezi a lockot, ha nincs lock bekapcsol a szerkesztés
            this.setState({
              queueExpires,
              upTopDateExpires,
              updatedTime
            });
            this.cildOnFcm && this.cildOnFcm();
            this.startEdit();
          } else {
            //Bezárt modal állapotban beállítjuk az alapértelmezett lock státuszt és a lekérdezett időket
            this.setState({
              lock: defaultLockState(),
              queueExpires,
              upTopDateExpires,
              updatedTime
            });
            this.cildOnFcm && this.cildOnFcm();
          }
        });
      }
    };

    /**
     * Szerkesztési módba vált.
     * Először lekéri a szervertől, hogy szerkeszthető-e az állomány, nincs-e zárolva vagy nem elavult-e az állomány
     */
    startEdit = () => {
      console.log("Departments.startEdit");
      const { cashregister_id, client_id } = this.props;
      requestHelper({
        url: `identity/lock?function=${lockType}`,
        method: "POST",
        ap: cashregister_id,
        client: client_id
      })
        .then(response => {
          console.log(response.data);
          //if (true) {
          //TODO: sikeres szerkesztés mód bekapcsolás teszt
          /*if (response.data.data.cause !== "in-queued") {
            response.data = {
              data: { locked: true, removable: true, cause: "outdated" },
              message: "Az állomány elavult.",
              success: true
            };
          }*/
          if (response.data.success) {
            if (lockParams.notifyUpdate && this.getTimeLeft("upToDate") <= 0) {
              const lock = {
                locked: false,
                message: "Elavult állomány",
                removable: false,
                cause: LOCK_CAUSE_OUTDATED,
                //cause: LOCK_CAUSE_OUT_QUEUED, //TODO: out-queued teszt,
                open: true
              };
              this.setState({ lock });
            } else {
              this.startLiveSignalTimerTimer();
              this.setState({ editMode: true, lock: defaultLockState() });
            }
          } else {
            const lock = {
              locked: response.data.data.locked,
              message: response.data.message,
              removable: response.data.data.removable,
              cause: response.data.data.cause,
              //cause: LOCK_CAUSE_OUT_QUEUED, //TODO: out-queued teszt,
              open: true
            };
            this.setState({ lock });
          }
        })
        .catch(error => {
          //alert(JSON.stringify(error.response));
        });
    };

    checkLock = cb => {
      console.log("Departments.startEdit");
      const { cashregister_id, client_id } = this.props;
      requestHelper({
        url: `identity/lock?function=${lockType}`,
        method: "POST",
        ap: cashregister_id,
        client: client_id
      })
        .then(response => {
          console.log(response.data);
          //if (true) {
          //TODO: sikeres szerkesztés mód bekapcsolás teszt
          /*if (response.data.data.cause !== "in-queued") {
            response.data = {
              data: { locked: true, removable: true, cause: "outdated" },
              message: "Az állomány elavult.",
              success: true
            };
          }*/
          if (response.data.success) {
            cb && cb();
          } else {
            const lock = {
              locked: response.data.data.locked,
              message: response.data.message,
              removable: response.data.data.removable,
              cause: response.data.data.cause,
              //cause: LOCK_CAUSE_OUT_QUEUED, //TODO: out-queued teszt,
              open: true
            };
            this.setState({ lock });
          }
        })
        .catch(error => {
          //alert(JSON.stringify(error.response));
        });
    };

    /**
     * Szerkesztés befejezése, küldése a szerverre
     * @param {boolean} disableStateUpdate setState kihagyása, a felület elhagyásakor true-val kell hívni, különben Warning: Can't perform a React state update on an unmounted component
     */
    endEdit = disableStateUpdate => {
      console.log("Departments.endEdit");
      const { cashregister_id, client_id } = this.props;
      requestHelper({
        url: `identity/lock?function=${lockType}`,
        method: "DELETE",
        ap: cashregister_id,
        client: client_id
      })
        .then(response => {
          console.log(response.data);
          if (response.data.success) {
            this.clearLiveSignalTimer();
            if (disableStateUpdate !== true) {
              this.setState({ editMode: false, lock: defaultLockState() });
            }
          } else {
            //alert(JSON.stringify(response.data));
          }
        })
        .catch(error => {
          //alert(JSON.stringify(error.response));
        });
    };

    /**
     * Lekéri a szervertől a következő frissítésig lévő időt
     */
    getQueueTimeLeft = () => {
      console.log("Departments.getQueueTimeLeft");
      const { cashregister_id, client_id } = this.props;
      return requestHelper({
        url: `cashregister-queue/timeleft?function=${lockType}`,
        method: "GET",
        ap: cashregister_id,
        client: client_id
      })
        .then(response => {
          const timeLeft = response.data.data.timeleft;
          console.log("queueTimeLeft", timeLeft);
          if (response.data.success) {
            return timeLeft ? new Date().getTime() + timeLeft * 1000 : null;
          } else {
            //alert(JSON.stringify(response.data));
          }
          return null;
        })
        .catch(error => {
          //alert(JSON.stringify(error.response));
        });
    };

    /**
     * Lekéri a szervertől a következő frissítésig lévő időt
     */
    getUpToDateTimeLeft = () => {
      console.log("Departments.getUpToDateTimeLeft");
      const { cashregister_id, client_id } = this.props;
      return requestHelper({
        url: `identity/uptodate-timeleft?function=${lockType}`,
        method: "GET",
        ap: cashregister_id,
        client: client_id
      })
        .then(response => {
          const timeLeft = response.data.data.timeleft;
          console.log("upToDateTimeLeft", timeLeft);
          if (response.data.success) {
            //return new Date().getTime() + 600 * 1000;
            return timeLeft ? new Date().getTime() + timeLeft * 1000 : null;
          } else {
            //alert(JSON.stringify(response.data));
          }
          return null;
        })
        .catch(error => {
          //alert(JSON.stringify(error.response));
        });
    };
    /**
     * Lekéri a szervertől a következő frissítésig lévő időt
     */
    getUpdatedTime = () => {
      const { cashregister_id, client_id } = this.props;
      return requestHelper({
        url: `identity/updated-time?function=${lockType}`,
        method: "GET",
        ap: cashregister_id,
        client: client_id
      })
        .then(response => {
          //console.log("getUpdatedTime", response);
          if (response.data.success) {
            //return new Date().getTime() + 600 * 1000;
            return response.data.data.updatedtime == 0
              ? null
              : response.data.data.updatedtime;
            //return timeLeft ? new Date().getTime() + timeLeft * 1000 : null;
          } else {
            //alert(JSON.stringify(response.data));
          }
          return null;
        })
        .catch(error => {
          //alert(JSON.stringify(error.response));
        });
    };

    /**
     * Visszaadja a hátralévő időt másodpercben
     * type [String] ["upToDate", "queue"]
     */
    getTimeLeft = type => {
      const { queueExpires, upTopDateExpires } = this.state;
      const time = new Date().getTime();
      const expires = type === "upToDate" ? upTopDateExpires : queueExpires;
      const diff = Math.round((expires - time) / 1000);
      return diff > 0 ? diff : 0;
    };

    /**
     * Értéket ad a timer referenica elemeknek, hogy ne kelljes state updatet csinálni a performance miatt
     */
    setQueueTimer = () => {
      const timeLeft = this.getTimeLeft("queue");
      if (this.queTimer.current) {
        this.queTimer.current.innerHTML = dateHelper.unixToTimeMinaSeconds(
          timeLeft
        );
      }
      if (this.queTimerModal.current) {
        this.queTimerModal.current.innerHTML = dateHelper.unixToTimeMinaSeconds(
          timeLeft
        );
      }
    };

    /**
     * Értéket ad az upToDate timer referenica elemeknek, hogy ne kelljes state updatet csinálni a performance miatt
     */
    setUpToDateTimer = () => {
      const timeLeft = this.getTimeLeft("upToDate");
      if (this.upToDateTimer.current) {
        this.upToDateTimer.current.innerHTML = dateHelper.unixToTimeMinaSeconds(
          timeLeft
        );
      }
    };

    /**
     * Elindítja a timer interval-t
     */
    startQueueTimer = () => {
      console.log("Departments.startQueueTimer");
      this.clearQueueTimer();
      const QUE_TIMER_TICK = 1000;
      this.setQueueTimer();
      this.queueInterval = setInterval(() => {
        this.setQueueTimer();
      }, QUE_TIMER_TICK);
    };

    /**
     * Elindítja a timer interval-t
     */
    startUpToDateTimer = () => {
      console.log("Departments.startUpToDateTimer");
      this.clearUpToDateTimer();
      const QUE_TIMER_TICK = 1000;
      this.setUpToDateTimer();
      this.queueInterval = setInterval(() => {
        this.setUpToDateTimer();
      }, QUE_TIMER_TICK);
    };

    /**
     * Kitörli a queue timer interval-t
     */
    clearQueueTimer = () => {
      if (this.queueInterval) {
        clearInterval(this.queueInterval);
      }
    };

    /**
     * Kitörli az upToDate timer interval-t
     */
    clearUpToDateTimer = () => {
      if (this.upToDateInterval) {
        clearInterval(this.upToDateInterval);
      }
    };

    export = () => {
      alert("Fejlesztés alatt");
    };

    import = () => {
      alert("Fejlesztés alatt");
    };

    /**
     * A lock modal OK gomb eseménye
     */
    acceptLockModal = () => {
      const { lock } = this.state;
      if (lock.cause === LOCK_CAUSE_OUTDATED) {
        this.startQueueUpdate();
      } else if (lock.cause === LOCK_CAUSE_IN_QUEUED) {
        this.closeLockModal();
      } else if (lock.cause === LOCK_CAUSE_OUT_QUEUED) {
        this.closeLockModal();
      }
    };

    /**
     * Elindítja a pénztárgép adatfrissítést
     */
    startQueueUpdate = () => {
      console.log("Departments.getQueueTimeLeft");
      const { cashregister_id, client_id } = this.props;
      const { lock } = this.state;

      requestHelper({
        url: "cashregister-queue/create",
        method: "POST",
        ap: cashregister_id,
        client: client_id,
        data: {
          QueueModel: {
            function: lockType
          }
        }
      })
        .then(response => {
          if (response.data.success) {
            //Státuszt váltunk firssítés alattra
            this.setState({ lock: { ...lock, cause: LOCK_CAUSE_IN_QUEUED } });
            //Elkérjük mennyi van még hátra
            this.getQueueTimeLeft().then(queueExpires => {
              this.setState({
                queueExpires
              });

              //Elindítjuk a timereket
              if (queueExpires) {
                this.startQueueTimer();
              }
            });
          } else {
            //alert(JSON.stringify(response.data));
          }
        })
        .catch(error => {
          //alert(JSON.stringify(error.response));
        });
    };

    /**
     * Visszavonja a pénztárgép adatfrissítést
     */
    cancelQueueUpdate = () => {
      console.log("Departments.cancelQueueUpdate");
      const { cashregister_id, client_id } = this.props;
      const { lock } = this.state;
      this.setState({ modalLoading: true });
      requestHelper({
        url: `cashregister-queue/delete?function=${lockType}`,
        method: "DELETE",
        ap: cashregister_id,
        client: client_id
      })
        .then(response => {
          if (response.data.success) {
            //Státuszt váltunk elavultra
            this.clearQueueTimer();
            //A szebb modal bezárásért késleltetjük az egyéb státuszokat
            this.setState({
              lock: { ...lock, open: false, modalLoading: false }
            });
            var context = this;
            setTimeout(() => {
              context.setState({
                lock: { ...lock, cause: LOCK_CAUSE_OUTDATED, open: false },
                queueExpires: new Date().getTime()
              });
            }, 200);
          } else {
            this.setState({ modalLoading: false });
            //alert(JSON.stringify(response.data));
          }
        })
        .catch(error => {
          this.setState({ modalLoading: false });
          //alert(JSON.stringify(error.response));
        });
    };

    /**
     * Lock modal cancel gomb eseménye
     */
    cancelLockModal = () => {
      console.log("Departments.cancelLockModal");
      const { lock } = this.state;
      if (lock.cause === LOCK_CAUSE_OUTDATED) {
        this.closeLockModal();
      } else if (lock.cause === LOCK_CAUSE_IN_QUEUED) {
        this.cancelQueueUpdate();
      }
    };

    /**
     * Lock modal close gomb eseménye
     */
    closeLockModal = () => {
      const { lock } = this.state;
      this.setState({ lock: { ...lock, open: false } });
    };

    render() {
      const {
        classes,
        cashregister_id,
        client_id,
        match,
        history
      } = this.props;
      const {
        editMode,
        lock,
        toolbarItems,
        updatedTime,
        modalLoading
      } = this.state;
      const queueTimeLeft = this.getTimeLeft("queue");
      const upToDateTimeLeft = this.getTimeLeft("upToDate");
      console.log("Departments.render", queueTimeLeft);

      const QueueTimer = props => (
        <Fragment>
          <TimerIcon fontSize="small" className={classes.warningIcon} />
          <Typography variant="body2">
            {localize(LC, lockParams.dataTitle)}{" "}
            {localize(LC, "firsítésének várható elkészülése")}
            <span className={classes.modalQueue} ref={props.timerRef}>
              {dateHelper.unixToTimeMinaSeconds(queueTimeLeft)}
            </span>
          </Typography>
        </Fragment>
      );

      const EditButton = () =>
        upToDateTimeLeft > 0 && lockParams.forceUpdate ? (
          <Tooltip
            title={
              editMode
                ? localize(LC, "Szereksztés kikapcsolása")
                : localize(LC, "Szerkesztés bekapcsolása")
            }
          >
            <Chip
              /*icon={
            editMode ? (
              <CancelEditIcon
                className={classes.uptoDateIcon}
                fontSize="small"
              />
            ) : (
              <EditIcon className={classes.uptoDateIcon} fontSize="small" />
            )
          }*/
              label={
                <span className={classes.modalQueue} ref={this.upToDateTimer}>
                  {dateHelper.unixToTimeMinaSeconds(upToDateTimeLeft)}
                </span>
              }
              //clickable
              //className={classes.chip}
              //color="primary"
              onDelete={editMode ? this.endEdit : this.startEdit}
              onClick={editMode ? this.endEdit : this.startEdit}
              deleteIcon={
                editMode ? (
                  <CancelEditIcon
                    className={classes.uptoDateIcon}
                    fontSize="small"
                  />
                ) : (
                  <EditIcon className={classes.uptoDateIcon} fontSize="small" />
                )
              }
            />
          </Tooltip>
        ) : (
          <Tooltip
            title={
              editMode
                ? localize(LC, "Szereksztés kikapcsolása")
                : localize(LC, "Szerkesztés bekapcsolása")
            }
          >
            <IconButton
              onClick={editMode ? this.endEdit : this.startEdit}
              className={classes.marginLeft}
              color="primary"
            >
              {editMode ? (
                <CancelEditIcon fontSize="small" />
              ) : (
                <EditIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        );

      var disabled =
        true === lockParams.disabled ||
        ("function" === typeof lockParams.disabled && lockParams.disabled());

      return (
        <div>
          <Toolbar disableGutters>
            <div>
              {lockParams.title && (
                <Typography variant="h6">
                  {localize(LC, lockParams.title)}
                </Typography>
              )}
              <div style={{ display: "flex", justifyContent: "center" }}>
                {editMode && (
                  <Fragment>
                    <WarningIcon
                      fontSize="small"
                      className={classes.warningIcon}
                    />
                    <Typography variant="body2">
                      {localize(
                        LC,
                        "A felület elhagyásakor a módosított adatok elvesznek!"
                      )}
                    </Typography>
                  </Fragment>
                )}

                {queueTimeLeft > 0 && !lockParams.hideTimer && (
                  <QueueTimer timerRef={this.queTimer} />
                )}
              </div>
            </div>
            <div style={{ width: 0 }} className={classes.marginLeftAuto} />
            {updatedTime && (
              <div className={classes.lastRefreshTime}>
                {localize(
                  LC,
                  lockParams.last_refresh_time_text || "Utolsó frissítés ideje"
                )}
                <br />
                <b>{updatedTime}</b>
              </div>
            )}
            {/*editMode && toolbarItems*/}
            {toolbarItems}
            {!disabled &&
              !editMode &&
              !lockParams.forceUpdate &&
              !lockParams.disableRefreshButton &&
              queueTimeLeft === 0 && (
                <Tooltip title={localize(LC, "Adatok frissítése")}>
                  <IconButton color="primary" onClick={this.startQueueUpdate}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              )}
            {!disabled && !lockParams.hideEditButton && <EditButton />}
          </Toolbar>

          <WrappedComponent
            cashregister_id={cashregister_id}
            client_id={client_id}
            match={match}
            history={history}
            editMode={editMode}
            queueExpires={this.state.queueExpires}
            setEditMode={this.setEditMode}
            setToolbarItems={this.setToolbarItems}
            getTimeLeft={this.getTimeLeft}
            onUpdated={this.onUpdated}
            checkLock={this.checkLock}
            onFcmRef={ref => (this.cildOnFcm = ref)}
          />
          <Modal
            open={lock.open}
            loading={modalLoading}
            title={lock.message}
            disableCancel={lock.cause === LOCK_CAUSE_OUT_QUEUED ? true : false}
            noText={
              lock.cause === LOCK_CAUSE_IN_QUEUED
                ? localize(LC, "Visszavonás")
                : localize("basic", "Mégsem")
            }
            yesText={
              lock.cause === LOCK_CAUSE_OUTDATED
                ? localize(LC, "Terméklista frissítése")
                : localize("basic", "Ok")
            }
            onAccept={this.acceptLockModal}
            onClose={this.closeLockModal}
            onCancel={this.cancelLockModal}
            customButtons={
              lock.cause === LOCK_CAUSE_OUTDATED
                ? loading => (
                  <Button
                    onClick={() => {
                      this.startLiveSignalTimerTimer();
                      this.setState({
                        editMode: true,
                        lock: defaultLockState(),
                        open: false
                      });
                    }}
                    //color="secondary"
                    disabled={loading === true}
                  >
                    {localize(LC, "Rendben (csak összes felküldés)")}
                  </Button>
                )
                : undefined
            }
          >
            {lock.cause === LOCK_CAUSE_OUTDATED && (
              <Fragment>
                {localize(
                  LC,
                  "A terméklista elavult, így csak az összes termék felküldésére lesz lehetősége. A módosítások felküldéséhez előbb frissítse a terméklistát!"
                )}
                <br />
                {localize(LC, "Elindítja a {{title}} frissítését?", {
                  title: localize(LC, lockParams.dataTitle)
                })}
                <Toolbar disableGutters variant="dense">
                  <InfoIcon className={classes.infoIcon} fontSize="small" />
                  <Typography
                    variant="caption"
                    display="inline"
                    color="textSecondary"
                    className={classes.infoText}
                  >
                    {localize(
                      LC,
                      "Az indítás nem azonnali adatszinkront jelent. A pénztárgép a következő bejelentkezéskor értesül a folyamatról és kezdi felküldeni a {{title}} adatokat.",
                      { title: localize(LC, lockParams.dataTitle) }
                    )}
                  </Typography>
                </Toolbar>
                <Toolbar disableGutters variant="dense">
                  <InfoIcon
                    className={classes.infoIconOrange}
                    fontSize="small"
                  />
                  <Typography
                    variant="caption"
                    display="inline"
                    color="textSecondary"
                    className={classes.infoText}
                  >
                    {localize(
                      LC,
                      'Az összes termék felküldése művelet nem írja felül a pénztárgépen található terméklistát, amennyiben ezt szeretné, úgy előtte használja az "Összes termék törlése" műveletet (kuka ikon).',
                      { title: localize(LC, lockParams.dataTitle) }
                    )}
                  </Typography>
                </Toolbar>
              </Fragment>
            )}
            {lock.cause === LOCK_CAUSE_IN_QUEUED && (
              <Fragment>
                <Toolbar disableGutters variant="dense">
                  <QueueTimer timerRef={this.queTimerModal} />
                </Toolbar>
                <Toolbar disableGutters variant="dense">
                  <InfoIcon className={classes.infoIcon} fontSize="small" />
                  <Typography
                    variant="caption"
                    display="inline"
                    color="textSecondary"
                    className={classes.infoText}
                  >
                    {localize(
                      LC,
                      "A folyamat meggyorsításához nyomja meg a pénztárgépen található bejelentkezés gombot."
                    )}
                  </Typography>
                </Toolbar>
              </Fragment>
            )}
            {lock.cause === LOCK_CAUSE_OUT_QUEUED && (
              <Fragment>
                {localize(LC, "Egy korábbi adatfrissítés még várakozik.")}
                <Toolbar disableGutters variant="dense">
                  <InfoIcon className={classes.infoIcon} fontSize="small" />
                  <Typography
                    variant="caption"
                    display="inline"
                    color="textSecondary"
                    className={classes.infoText}
                  >
                    {localize(
                      LC,
                      "A frissítési kérelmet a FOLYAMATOK menüben tudja visszavonni."
                    )}
                  </Typography>
                </Toolbar>
              </Fragment>
            )}
          </Modal>
        </div>
      );
    }
  }
  LockHOC.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //Ez igazából az AP szám
    client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired
  };
  return withStyles(styles)(withRouter(LockHOC));
}
