import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditExam from "./views/EditExam";
import * as examineeExamsActions from "../../../../redux/modules/examinee-exams";

const mapStateToProps = state => {
  return {
    loading: state.examineeExams.loading,
    success: state.examineeExams.success,
    saved: state.examineeExams.saved,
    message: state.examineeExams.message,
    fieldErrors: state.examineeExams.formErrors,
    id: state.examineeExams.id,
    viewData: state.examineeExams.viewData,
    reducerName: "examineeExams",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: examineeExamsActions.createExams,
      update: examineeExamsActions.updateExams,
      view: examineeExamsActions.viewExams,
      reset: examineeExamsActions.reset,
      resetForm: examineeExamsActions.resetForm
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditExam);
