import React from "react";
import DataTable from "Components/New/DataTable/DataTable/DataTable";
import DataProvider from "Components/New/DataProvider/DataProvider";
import localize from "helpers/localize";

class ClientLocations extends React.Component {
  render() {
    const { client, readOnly, afterSave } = this.props;
    if (!client) {
      return "Betöltés...";
    }
    return (
      <div style={{ width: "100%", position: "relative" }}>
        <DataProvider
          url={`clients/${client.id}/client-locations`}
          component={DataTable}
          title={localize("clients", "Telephelyek")}
          filterable={false}
          readOnly={readOnly}
          afterSave={afterSave}
          goBackAfterSave
          perPage={1000}
          options={{
            form: {
              baseFields: {
                //name: { validators: ["required"] },
                //zip: { validators: ["required"] },
                city: { validators: ["required"] },
                //street: { validators: ["required"] },
                phone: { mask: "mobile_phone", validators: ["mobile_phone"] },
                email: { validators: ["email"] }
              }
            }
          }}
        />
      </div>
    );
  }
}

export default ClientLocations;
