/* eslint-disable */
import React, {Component, Fragment} from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { formValues } from 'redux-form';  // ES6
import * as serviceOrdersActions from '../../redux/modules/service-orders';

import {Field} from "redux-form";
import Validations from "../Forms/Validations";

import Autocomplete from "../Forms/Fields/Autocomplete";
import {get} from "lodash";
import SelectField from "../Forms/Fields/Select";
import InputField from "../Forms/Fields/InputMaskedBlur";
import DeleteIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import AlertIcon from '@material-ui/icons/ReportProblem';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CustomerSection from './CustomerSection';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Tooltip } from "@material-ui/core";
import localize from "helpers/localize";
const renderLocationMenuItemChildren = (option, props) => {
  return <div>{option.client_location_name}, {option.client_location_zip}{" "}{option.client_location_city}</div>;
}

class ServiceOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lastTaxNumber: "", //CSak arra kell, hogy ne frissítsük többszöri elhagyáskor az ügyféladatokat
            selectedClient: false,
            selectedLocation: false,
            //showEditModal: false,
        };
    }

    componentWillReceiveProps(nextProps) {
      /*if(!nextProps.selectedClient) {
        this.setState({selectedClient: false, selectedLocation: false});
      }*/
      if (nextProps.selectedClient && nextProps.selectedClient.client_id && JSON.stringify(nextProps.selectedClient) !== JSON.stringify(this.state.selectedClient)) {
        const context = this;
        clearTimeout(this.clientTimeout);
        this.clientTimeout = setTimeout(() => {
          //console.log("el van baszva", nextProps.selectedClient, this.state.selectedClient)
          context.setState( {
            selectedClient: Object.assign({}, this.state.selectedClient, nextProps.selectedClient)
          });  
        }, 100);
        
      }
      /*if(!nextProps.selectedLocation && (!nextProps.selectedLocation || !nextProps.selectedClient.client_location_id)) {
        this.setState({selectedLocation: false});
      }*/
      //console.log("Changed")
      if (nextProps.selectedClient && nextProps.selectedClient.client_location_id && JSON.stringify(nextProps.selectedClient) !== JSON.stringify(this.state.selectedLocation)) {
        //console.log("Beállítás")
        const context = this;
        clearTimeout(this.clientLocationTimeout);
        this.clientLocationTimeout = setTimeout(() => {
          //console.log("Location van el van baszva", nextProps.selectedClient, this.state.selectedClient)
          context.setState( {
            selectedLocation: nextProps.selectedClient
          });  
        }, 100);
      }
    }

    componentWillMount() {
      console.log("ServiceOrders.mount")

      this.props.initialize()
    }

    componentDidMount() {
        if(this.props.handleTaxOnBlur) {
            this.props.handleTaxOnBlur(this.props.clientBox ? this.handleTaxOnBlurBox : this.handleTaxOnBlur)
        }
        if(this.props.handleApnLoad) {
          this.props.handleApnLoad(this.handleApnLoad)
        }
        if(this.props.handleLocationSelect) {
          this.props.handleLocationSelect(this.handleLocationSelect)
        }
    }

    handleLocationSelect = (selected, name) => {
      const {dispatch, change} = this.props;

      dispatch(change('client_location_id', selected ? selected.client_location_id : null));
      if(name) {
        dispatch(change(name, ''));
      }
      this.setState({
        selectedLocation: selected
    });
    if(this.props.onClientLocationFound) {
      this.props.onClientLocationFound(selected)
    }
    }

    handleApnLoad = data => {
      const {dispatch, change} = this.props;
      dispatch(change('client_location_id', data.client_location_id));
      dispatch(change('client_id', data.client_id));
      
      this.setState({selectedClient: data, selectedLocation: data});
      
       //Ügyfelet találtunk
       if(this.props.onClientFound) {
        this.props.onClientFound(data)
      }
       if(this.props.onClientLocationFound) {
        this.props.onClientLocationFound(data)
      }

    }

    handleClientFound = data => {
      const {dispatch, change} = this.props;
      dispatch(change('client_id', data.client_id));
      this.setState({selectedClient: data});
       //Ügyfelet találtunk
       if(this.props.onClientFound) {
        this.props.onClientFound(data)
      }

    }

    handleChangeLocationAutocomplete = (name, value) => {

        const {dispatch, change} = this.props;
        let selected = false;
        if(typeof value === 'object') {
            dispatch(change('client_location_id', value.id));
            //dispatch(change(name, ''));
            selected = value;
            //Telephelyet találtunk
            console.log("Telephelyet találtunk", selected)
            this.setState({
              selectedLocation: selected
          });
        if(this.props.onClientLocationFound) {
          this.props.onClientLocationFound(selected)
        }

        }
        else {
            dispatch(change('client_location_id', null));
            dispatch(change(name, value));
            this.setState({
              selectedLocation: selected
          });
        }

        

        
    };

    handleTaxOnBlurBox = (evt, value) => {
      if(value && value !== '' ) {
          this.props.checkTaxNumber(value).then(response => {
              //if(response.type === 'SERVICEORDER_TAX_SUCCESS') {
                  let client = get(response, "data.data", null);
                  const {dispatch, change} = this.props;
                  let selected = false;
                  if(typeof client === "object" && client.client_id) {
                      dispatch(change('client_id', client.client_id));
                      selected = client;
                      if(!value.complete) {
                          this.setState({
                              showEditModal: true
                          });
                      }
                      //Ügyfelet találtunk
                      if(this.props.onClientFound) {
                        this.props.onClientFound(selected)
                      }
                  }
                  else {
                      dispatch(change('client_id', null));
                  }
                  this.setState({
                      selectedClient: selected,
                      lastTaxNumber: value
                  });
              //}
          });
      }
  };

    handleTaxOnBlur = (evt, value) => {
        const isValid = !Validations.tax_number(value) ? true : false;

        //Csak valid adószámnál keresünk
        if(isValid && value !== this.state.lastTaxNumber ) {

            //Adószám alapján betöltjük az ügyfelet
            this.props.checkTaxNumber(value).then(response => {
                let client = get(response, "data.data", null);
                if(typeof client === "object" && client.client_id) {
                    //Merge-öljük a kapott ügyféladatokat a formadatokkal

                    const keys = Object.keys(client);
                    for(let i in keys){
                      const key = keys[i];
                      if(key !== "client_tax_number") {
                        this.props.autofill(key, client[key]);
                      }
                    };
                    //Ügyfelet találtunk
                    if(this.props.onClientFound) {
                      this.props.onClientFound(client)
                    }

                    //Egy kis trükk, ha egyesével változtatom minden renderelődik, lassú lesz, csak init van, akkor nem lesz aktív mentés gomb.
                    //Az adósuzámot mindig változtatjuk.
                    //this.props.initialize(Object.assign({}, this.props.formValues, client, {client_tax_number: null}), true);
                    //this.props.autofill("client_tax_number", client.client_tax_number);
                    //this.props.autofill("client_zip", client.client_zip);
                }

                // frissítjük az utolsónak lekérdezett adószámot
                this.setState({
                    lastTaxNumber: value
                });
            });
        }
    };


    handleRemoveClient = () => {
        const {dispatch, change} = this.props;
        dispatch(change('client_id', null));
        const context = this;
        setTimeout(() => {
          context.setState({
            selectedClient: false,
            selectedLocation: false
        });  
        }, 200);
        
         //Ügyfelet töröltünk
         if(this.props.onClientRemoved) {
          this.props.onClientRemoved()
        }
        if(this.props.onClientLocationRemoved) {
          this.props.onClientLocationRemoved()
        }
    };

    handleRemoveLocation = () => {
        const {dispatch, change} = this.props;
        dispatch(change('client_location_id', null));
        //Telephelyet töröltünk
        if(this.props.onClientLocationRemoved) {
          this.props.onClientLocationRemoved()
        }
        const context = this;
        setTimeout(() => {
          context.setState({
            selectedLocation: false
        });  
        }, 200);
    };

    concatAddress = (data) => {
      return data.client_zip
      + (data.client_city ? " " + data.client_city : "")
      + (data.client_street ? " " + data.client_street : "")
      + (data.client_street_suffix ? " " + data.client_street_suffix : "")
      + (data.client_street_details ? " " + data.client_street_details : "");
    }

    copyCenterAddress = () => {
      const {dispatch, change} = this.props;
      let billing_address = null;

      if(this.state.selectedClient) {
        billing_address = this.concatAddress(this.state.selectedClient)
      } else {
        billing_address = this.concatAddress(this.props.formValues)
      }

      if(billing_address && billing_address !== "" && billing_address !== "undefined") {
        dispatch(change('billing_address', billing_address));
      }
    }

    render = () => {
        const {
            handleInputChange,
            fieldErrors,
            isViewPage,
            enabledFields,
            baseFields,
            regNoRequired,
            showBillingAddress,
            showPayments,
            change,
            clientBox,
            disableRemoveClient,
            disableEditClient,
            disableRemoveLocation,
            disableEmailAndPhoneRequiredValidation,
            enableEmailValidation,
            companyNameAutocomplete
        } = this.props;

        let methods = [];

        Object.keys(get(this.props, 'baseFields.order_payment_method.data', {})).forEach(key => {
            methods.push({id: key, label: this.props.baseFields.order_payment_method.data[key]});
        });
        //console.log("Render")
        // todo it is just for testing
        if (methods.length === 0) {
          methods = [
            {
              id: 'készpénz',
              label: 'keszpenz'
            },
            {
              id: 'bankkartya',
              label: 'bankkártya'
            }
          ];
        }

        const ClientField = props => <div style={{marginTop: 3, marginBottom:3}}>
                                        <b>{localize("serviceOrders", props.label)}:</b> {props.value}
                                     </div>;

        return <Fragment>
            {!this.state.selectedClient && <CustomerSection
              handleInputChange={handleInputChange}
              handleTaxOnBlur={clientBox ? this.handleTaxOnBlurBox : this.handleTaxOnBlur}
              fieldErrors={fieldErrors}
              disableEmailAndPhoneRequiredValidation={true}
              enableEmailValidation={enableEmailValidation}
              isViewPage={isViewPage}
              enabledFields={enabledFields}
              baseFields={baseFields}
              regNoRequired={regNoRequired}
              change={change}
              companyNameAutocomplete={companyNameAutocomplete}
              handleClientFound={this.handleClientFound}
            />}
            {this.state.selectedClient &&
            <Fragment>

                <Grid container spacing={16}>
                <Grid item xs={12} md={6}>
                <Paper style={{padding:16}}>
                <div style={{display: "flex"}}>
                    <div style={{marginRight:"auto"}}><h3><b>{this.state.selectedClient.client_name}</b></h3></div>
                    <div className="text-right">
                    {!disableEditClient &&<IconButton style={{width:32, height:32, marginRight:8, padding:0}} onClick={() => this.props.handleEditClient(this.state.selectedClient)}>
                        <EditIcon/>
                    </IconButton>}
                    {!disableRemoveClient && <IconButton style={{width:32, height:32, padding:0}} onClick={() => this.handleRemoveClient()}>
                        <DeleteIcon/>
                    </IconButton>}
                    </div>
                </div>
                    <ClientField value={this.state.selectedClient.client_tax_number} label="Adószám" />
                    <ClientField value={this.state.selectedClient.client_registration_no} label="Cégjegyzékszám" />
                    <ClientField value={
                      this.state.selectedClient.client_zip
                      + (this.state.selectedClient.client_city ? " " + this.state.selectedClient.client_city : "")
                      + (this.state.selectedClient.client_street ? " " + this.state.selectedClient.client_street : "")
                      + (this.state.selectedClient.client_street_suffix ? " " + this.state.selectedClient.client_street_suffix : "")
                      + (this.state.selectedClient.client_street_details ? " " + this.state.selectedClient.client_street_details : "")
                    } label="Cím" />
                    <ClientField value={this.state.selectedClient.client_email} label="Email"{...disableEmailAndPhoneRequiredValidation}/>
                    <ClientField value={this.state.selectedClient.client_phone} label="Mobiltelefonszám"{...disableEmailAndPhoneRequiredValidation} />
                    <ClientField value={this.state.selectedClient.client_phone2} label="Másodlagos telefonszám"{...disableEmailAndPhoneRequiredValidation} />

                    {this.state.selectedClient.complete === false && <div style={{color: "red", marginTop:8}}>
                        <AlertIcon style={{marginBottom:-6, fill:"red"}} /> Hiányos ügyféladatok, kérem javítsa! (kattintson a ceruzára)
                    </div>}
                    {this.state.selectedClient.outdated === true && <div style={{color: "#f57c00", marginTop:8}}>
                        <AlertIcon style={{marginBottom:-6, fill:"#f57c00"}} /> Elavult ügyféladatok ({this.state.selectedClient.last_update}), kérem ellenőrizze! Ha módosítás szükséges kattintson a ceruzára!
                    </div>}
                    </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    {this.state.selectedLocation && <Paper style={{padding:16, height: "100%"}}><div className="row">
                        <div className="col-10">
                            <h3><b>{localize("serviceOrders", "Telephely")}</b></h3>
                            <div><b>{this.state.selectedLocation.client_location_name}</b></div>
                            <div>{this.state.selectedLocation.client_location_zip} {this.state.selectedLocation.client_location_city}, {this.state.selectedLocation.client_location_street} {this.state.selectedLocation.client_location_street_suffix} {this.state.selectedLocation.client_location_street_details}</div>
                            <ClientField value={this.state.selectedLocation.client_location_phone} label="Telefon"{...disableEmailAndPhoneRequiredValidation} />
                            <ClientField value={this.state.selectedLocation.client_location_email} label="Email"{...disableEmailAndPhoneRequiredValidation} />
                        </div>
                        <div className="col-2 text-right">
                            {!disableRemoveLocation && <IconButton style={{width:32, height:32, padding:0}} onClick={() => this.handleRemoveLocation()}>
                                <DeleteIcon/>
                            </IconButton>}
                        </div>
                    </div></Paper>}


                </Grid>
              </Grid>
              </Fragment>}
              {!this.state.selectedLocation && this.props.showClientLocation &&
                    <Grid item xs={12}>
                    <h6 className="form-legend">Telephely</h6>
                    <Grid container spacing={16}>
                        <Grid item={true} xs={12} sm={6} md={4} lg={1}>
                            <Field
                                name="client_location_zip"
                                //formName="cashregisters"
                                renderMenuItemChildren={renderLocationMenuItemChildren}
                                onSelect={this.handleLocationSelect}
                                label={localize("serviceOrders", "Irányítószám")}
                                fieldError={fieldErrors.client_location_zip}
                                onChange={() => handleInputChange('client_location_zip')}
                                //isRequired={true} validate={[Validations.required]}
                                component={this.state.selectedClient ? Autocomplete : InputField}
                                //minChars={get(this.props, 'baseFields.client_location_zip.minChars', 2)}
                                minChars={0}
                                loadOnFocus
                                url={`v1/cashregisters/autocomplete-client-location?clientId=${this.state.selectedClient ? this.state.selectedClient.client_id : ""}`}
                                //handleChangeAutocomplete={this.handleChangeLocationAutocomplete.bind(this)}
                                isLocation={true}
                                labelKey="client_location_city"
                                isViewPage={isViewPage}
                                disabled={enabledFields ? enabledFields.indexOf('client_location_zip') !== -1 ? false : true : false}
                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={4} lg={2}>
                            <Field
                                name="client_location_city"
                                //formName="cashregisters"
                                renderMenuItemChildren={renderLocationMenuItemChildren}
                                onSelect={this.handleLocationSelect}
                                label={localize("serviceOrders", "Település")}
                                fieldError={fieldErrors.client_location_city}
                                onChange={() => handleInputChange('client_location_city')}
                                isRequired={true} validate={[Validations.required]}
                                component={this.state.selectedClient ? Autocomplete : InputField}
                                //minChars={get(this.props, 'baseFields.client_location_city.minChars', 2)}
                                minChars={0}
                                loadOnFocus
                                url={`v1/cashregisters/autocomplete-client-location?clientId=${this.state.selectedClient ? this.state.selectedClient.client_id : ""}`}
                                //handleChangeAutocomplete={this.handleChangeLocationAutocomplete.bind(this)}
                                labelKey="client_location_city"
                                isLocation={true}
                                isViewPage={isViewPage}
                                disabled={enabledFields ? enabledFields.indexOf('client_location_city') !== -1 ? false : true : false}
                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                            <Field
                                name="client_location_street"
                                label={localize("serviceOrders", "Telephely utca")}
                                fieldError={fieldErrors.client_location_street}
                                onChange={() => handleInputChange('client_location_street')}
                                //isRequired={true} validate={[Validations.required]}
                                component={InputField}
                                type="text"
                                placeholder={localize("serviceOrders", "Telephely utca")}
                                isViewPage={isViewPage}
                                readOnly={enabledFields ? enabledFields.indexOf('client_location_street') !== -1 ? false : true : false}
                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                            <Field
                                name="client_location_street_suffix"
                                label={localize("serviceOrders", "Telephey közterület jellege")}
                                fieldError={fieldErrors.client_location_street_suffix}
                                onChange={() => handleInputChange('client_location_street_suffix')}
                                //isRequired={true} validate={[Validations.required]}
                                component={InputField}
                                type="text"
                                placeholder={localize("serviceOrders", "Telephely közterület jellege")}
                                isViewPage={isViewPage}
                                readOnly={enabledFields ? enabledFields.indexOf('client_location_street_suffix') !== -1 ? false : true : false}
                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                            <Field
                                name="client_location_street_details"
                                label={localize("serviceOrders", "Telephely házszám (emelet, ajtó)")}
                                fieldError={fieldErrors.client_location_street_details}
                                onChange={() => handleInputChange('client_location_street_details')}
                                //isRequired={true} validate={[Validations.required]}
                                component={InputField}
                                type="text"
                                placeholder={localize("serviceOrders", "Telephely házszám (emelet, ajtó)")}
                                isViewPage={isViewPage}
                                readOnly={enabledFields ? enabledFields.indexOf('client_location_street_details') !== -1 ? false : true : false}
                            />
                        </Grid>
                        <Grid item container spacing={16}>
                        <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                            <Field
                                name="client_location_name"
                                //formName="cashregisters"
                                //isRequired={true} validate={[Validations.required]}
                                renderMenuItemChildren={renderLocationMenuItemChildren}
                                onSelect={this.handleLocationSelect}
                                label={localize("serviceOrders", "Telephelynév")}
                                fieldError={fieldErrors.client_location_name}
                                onChange={() => handleInputChange('client_name')}
                                component={this.state.selectedClient ? Autocomplete : InputField}
                                isViewPage={isViewPage}
                                //minChars={get(this.props, 'baseFields.client_location_name.minChars', 2)}
                                minChars={0}
                                loadOnFocus
                                url={`v1/cashregisters/autocomplete-client-location?clientId=${this.state.selectedClient ? this.state.selectedClient.client_id : ""}`}
                                disabled={enabledFields ? enabledFields.indexOf('client_location_name') !== -1 ? false : true : false}
                                //handleChangeAutocomplete={this.handleChangeLocationAutocomplete.bind(this)}
                                labelKey="client_location_city"
                                isLocation={true}
                            />
                        </Grid>

                        </Grid>

                        </Grid></Grid>}
              {showPayments && <Grid item xs={12}>
        <h6 className="form-legend">Fizetési adatok</h6>
        <Grid container spacing={16}>
        {showBillingAddress && <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                            <Field
                                name="billing_address"
                                label={localize("serviceOrders", "Számlázási cím")}
                                fieldError={fieldErrors.billing_address}
                                onChange={() => handleInputChange('billing_address')}
                                isRequired={true} validate={[Validations.required]}
                                component={InputField}
                                type="text"
                                placeholder={localize("serviceOrders", "Számlázási cím")}
                                isViewPage={isViewPage}
                                readOnly={enabledFields ? enabledFields.indexOf('billing_address') !== -1 ? false : true : false}
                                TextInputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Tooltip title="Székhely cím másolása">
                                        <IconButton
                                          style={{width:32, height: 32, padding: 0}}
                                          aria-label="Székhely cím másolása"
                                          onClick={this.copyCenterAddress}
                                        >
                                          <CopyIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  ),
                                }}
                            />
                        </Grid>}
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                    <Field
                        name="order_payment_method"
                        label={localize("serviceOrders", "Fizetési mód")}
                        fieldError={fieldErrors.client_payment_method}
                        onChange={() => handleInputChange('order_payment_method')}
                        component={SelectField}
                        placeholder={localize("serviceOrders", "Fizetési mód")}
                        isRequired={true} validate={[Validations.required]}
                        isViewPage={isViewPage}
                        options={methods}
                        readOnly={enabledFields ? enabledFields.indexOf('order_payment_method') !== -1 ? false : true : false}
                    />
                </Grid>

                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                    <Field
                        name="order_bank_name"
                        label={localize("serviceOrders", "Számlavezető bank neve")}
                        fieldError={fieldErrors.order_bank_name}
                        onChange={() => handleInputChange('order_bank_name')}
                        component={InputField}
                        type="text"
                        placeholder={localize("serviceOrders", "Számlavezető bank neve")}
                        isViewPage={isViewPage}
                        readOnly={enabledFields ? enabledFields.indexOf('order_bank_name') !== -1 ? false : true : false}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                    <Field
                        name="order_bank_account_no"
                        label={localize("serviceOrders", "Bankszámlaszám")}
                        fieldError={fieldErrors.order_bank_account_no}
                        onChange={() => handleInputChange('order_bank_account_no')}
                        component={InputField}
                        type="text"
                        placeholder={localize("serviceOrders", "Bankszámlaszám")}
                        isViewPage={isViewPage}
                        readOnly={enabledFields ? enabledFields.indexOf('order_bank_account_no') !== -1 ? false : true : false}
                    />
                </Grid>
                </Grid>
                </Grid>}
        </Fragment>
    }
}

ServiceOrders.defaultProps = {
  showPayments: true,
  showClientLocation: true
}


const mapStateToProps = (state) => {
    return {
        //taxClient: state.serviceOrders.taxClient,
        formValues: state.form.serviceOrders ? state.form.serviceOrders.values : {}
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            checkTaxNumber: serviceOrdersActions.checkTaxNumber,
            resetTaxNumber: serviceOrdersActions.resetTaxNumber
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOrders);
