import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditClient from "./views/EditClient";
import * as clientsActions from "../../../redux/modules/clients";

const mapStateToProps = state => {
  return {
    loading: state.clients.loading,
    success: state.clients.success,
    saved: state.clients.saved,
    message: state.clients.message,
    fieldErrors: state.clients.formErrors,
    id: state.clients.id,
    viewData: state.clients.viewData,
    reducerName: "clients",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: clientsActions.createClients,
      update: clientsActions.updateClients,
      view: clientsActions.viewClients,
      reset: clientsActions.reset,
      resetForm: clientsActions.resetForm
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)(EditClient);
