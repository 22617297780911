const MATCH_EMAIL = /[^\s@]+@[^\s@]+\.[^\s@]+/gi;
const MATCH_PASSWORD = /(?=.*\d)(?=.*[a-zéáűőúöüóí])(?=.*[A-ZÉÁŰŐÚÖÜÓÍ]).{8,}/;
const TIME = /(00|[0-9]|1[0-9]|2[0-3])([0-9]|[0-5][0-9])/;
const confirmation = fieldName => (value, values) => {
  return value === values[fieldName] ? undefined : "Confirmation must match";
};

/*
  //Szerver oldali validáció teszteléséhez
  const Validations = Object.freeze({
  required: value => undefined,
  tax_number: taxNumber => undefined,
  mobile_number: value => undefined,
  email: value => undefined,
  emailConfirmation: value => undefined,
  password: value => undefined,
  passwordConfirmation: value => undefined,
  time: value => undefined,
  except4: value => undefined
});
*/

const Validations = Object.freeze({
  required: value =>
    value && value.toString().trim() ? undefined : "A mező kitöltése kötelező",
  tax_number: taxNumber => {
    if (!taxNumber) {
      return undefined;
    }
    /*
    return taxNumber.match(/^(\d{8})\-(\d{1})\-(\d{2})/g)
      ? undefined
      : "Hiányos adószám";
    */
    //propg.hu-s cucc https://prog.hu/tudastar/180161/javascript-adoszam-regex
    var pattern = /^(\d{7})(\d)\-([1-5])\-(0[2-9]|[13][0-9]|2[02-9]|4[0-4]|51)$/;
    var matches = taxNumber.match(pattern);
    if (matches) {
      // Szorzók az adószám törzsszám része (első 8 jegy) ellenőrző számjegyének
      // meghatározásához (a 8. jegy az első hét jegy alapján). Az adott szorzóval, a
      // törzsszám megfelelő számjegyét (elölről indulva) megszorozzuk, majd ezen
      // szorzatok összegét képezzük.
      var mul = [9, 7, 3, 1, 9, 7, 3];
      // Az adószám törzsszám részének első 7 jegye.
      var base = matches[1].split("");
      // Ellenőrző számjegy (az adószám 8. jegye).
      var check = parseInt(matches[2]);
      // Ellenőrző összeg meghatározása.
      var sum = 0;
      for (var i = 0; i < 7; i++) {
        sum += parseInt(base[i]) * mul[i];
      }
      // Az ellenőrző összeg utolsó jegyének meghatározása (= 10-zel való osztás maradéka).
      var last = sum % 10;
      // Ha a kiszámított ellenőrző számjegy nem nulla, akkor 10-ből kivonjuk.
      if (last > 0) {
        last = 10 - last;
      }
      // A kiszámított és kapott ellenőrző számjegyek egyezősége alapján
      // lesz helyes vagy helytelen az adószám.
      return last === check ? undefined : "Helytelen adószám";
    }
    return "Helytelen adószám formátum";
  },
  mobile_number: mobileNumber => {
    if (!mobileNumber) {
      return undefined;
    }
    if (mobileNumber === "") {
      return undefined;
    }
    if (mobileNumber === "__-__-_______") {
      return undefined;
    }
    return mobileNumber.match(/^(\d{2})\-(\d{2})\-(\d{7})/g)
      ? undefined
      : "Helytelen telefonszám";
  },
  mobile_phone: mobileNumber => {
    if (!mobileNumber) {
      return undefined;
    }
    if (mobileNumber === "") {
      return undefined;
    }
    if (mobileNumber === "06-__-_______") {
      return undefined;
    }
    return mobileNumber.match(/^(\d{2})\-(\d{2})\-(\d{7})/g)
      ? undefined
      : "Helytelen telefonszám";
  },
  datetime: dateTimeString => {
    if (!dateTimeString) {
      return undefined;
    }

    if (dateTimeString === "____-__-__ __:__:__") {
      return undefined;
    }

    return dateTimeString &&
      dateTimeString.match(
        /^(\d{4})\-(\d{2})\-(\d{2}) (\d{2}):(\d{2}):(\d{2})/g
      )
      ? undefined
      : "Hibás dátum formátum (2011-01-21 16:44:56)";
  },
  email: value =>
    !value || value.match(MATCH_EMAIL) ? undefined : "Hibás email formátum",
  emailConfirmation: confirmation("email"),
  password: value =>
    MATCH_PASSWORD.test(value) ? undefined : "Must be a password (...)",
  passwordConfirmation: fieldName =>
    fieldName ? confirmation(fieldName) : confirmation("password"),
  time: value => (TIME.test(value) ? undefined : "The time is invalid"),
  except4: value => (value.length === 4 ? undefined : "The time is invalid")
});

export default Validations;
