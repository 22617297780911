import React from "react";
import PropTypes from "prop-types";
import ContainerHeader from "Components/ContainerHeader/index";
import Cashregisters from "./Cashregisters";
import ClientSelector from "./ClientSelector";
import localize from "helpers/localize";
import PageMenu from "Components/New/Menu/PageMenu/PageMenu";
import PageMenuContent from "Components/New/Menu/PageMenu/PageMenuContent";
import Accounting from "./Accounting";
import Products from "./Products";
import Queue from "./Queue";
import PrintEndReceipt from "./PrintEndReceipt";
import { withRouter } from "react-router-dom";
import AccountingPlus from "./AccountingPlus";
const LC = "cloud"; //Localization category
const PATH_BASE = "/cloud-control-panel";
const PATH_PRINT_END_RECEIPT = "/print-end-receipt";
const PATH_ACCOUNTING_PLUS = "/accounting-plus";

class ControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Routolás tabváltozás esetén
   */
  handleTabChange = (evt, pathname, params) => {
    /* console.log("Cloud.handleTabChange", evt, pathname, params);
    const { submenu, id } = this.cashregisterProps;
    let p = pathname;
    //Pénztárgépmenü esetén hozzáadjuk a megjegyzett paramétereket
    if (pathname === PATH_CASHREGISTER) {
      if (submenu) {
        p += submenu;
      }
      if (id) {
        p += "/" + id;
      }
      this.props.history.replace(p);
    }*/
  };

  render() {
    const { client_id, menu } = this.props.match.params;
    //Gépház az admin funkciók ellátására
    return (
      <div style={{ position: "relative" }}>
        <ContainerHeader title={localize(LC, "Gépház")} />

        <PageMenu
          key={client_id}
          label={localize(LC, "Biz. nyomt.")}
          basePath={PATH_BASE}
          onChange={this.handleTabChange}
          defaultPath={PATH_PRINT_END_RECEIPT}
          color="primary"
        >
          <PageMenuContent
            path={PATH_PRINT_END_RECEIPT}
            title={localize(LC, "Biz. nyomt.")}
          >
            <PrintEndReceipt
              editMode
              isGlobal
              history={this.props.history}
              match={this.props.match}
            />
          </PageMenuContent>
          <PageMenuContent
            path={PATH_ACCOUNTING_PLUS}
            title={localize(LC, "Könyvelés +")}
          >
            <AccountingPlus
              history={this.props.history}
              match={this.props.match}
            />
          </PageMenuContent>
        </PageMenu>
      </div>
    );
  }
}

ControlPanel.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default ControlPanel;
