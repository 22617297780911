import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { toastr } from "../../../../../helpers/CustomToastr";

import CardBox from "../../../../../Components/CardBox";
import ContainerHeader from "../../../../../Components/ContainerHeader";
import DistributorForm from "./DistributorForm";
import GenericForm from "../../../../../Components/Forms/GenericForm";

import localize from "helpers/localize";

class Distrtibutor extends PureComponent {
  componentWillMount = () => {};

  componentWillUnmount = () => {
    this.props.getDistributor();
  };

  profileSubmit = formData => {
    this.props.changeDistributor(formData);
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.saved && nextProps.saved) {
      toastr.success(localize("basic", "Sikeres művelet"));
    }
    if (!this.props.message && nextProps.message) {
      toastr.error(nextProps.message);
    }
  };

  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("user", "Viszonteladói adataim")}</title>
        </Helmet>
        <ContainerHeader
          title={
            <div>
              {localize("user", "Viszonteladói adataim")}
              {this.props.distributor && this.props.distributor.role_text && (
                <small style={{ marginLeft: 8 }}>
                  ({this.props.distributor.role_text})
                </small>
              )}
            </div>
          }
        />
        <CardBox styleName="" cardStyle="p-4">
          <GenericForm
            onSubmit={this.profileSubmit.bind(this)}
            fieldErrors={this.props.fieldErrors}
          >
            <DistributorForm {...this.props} />
          </GenericForm>
        </CardBox>
      </div>
    );
  }
}

export default Distrtibutor;
