export const PATH_BASE = "/shop";
export const PATH_CATALOG = "/catalog";
export const PATH_ORDERS = "/orders";
export const PATH_CART = "/cart";
export const PATH_ADMIN_PRODUCTS = "/admin-products";
export const PATH_ADMIN_ORDERS = "/admin-orders";
export const PATH_ADMIN_TAGS = "/admin-tags";
export const PATH_ADMIN_CUSTOM_PRICES = "/admin-custom-prices";
export const PATH_ADMIN_MENU = "/admin-menu";

export const SLUG_CHECKOUT = "checkout";
export const SLUG_ORDER = "order";
export const SLUG_SUCCESS = "success";

export const PATH_CART_CHECKOUT = `${PATH_CART}/${SLUG_CHECKOUT}`;
export const PATH_CART_ORDER = `${PATH_CART}/${SLUG_ORDER}`;
export const PATH_CART_SUCCESS = `${PATH_CART}/${SLUG_SUCCESS}`;

export const BASE_PATH_CATALOG = PATH_BASE + PATH_CATALOG;
export const BASE_PATH_ORDERS = PATH_BASE + PATH_ORDERS;
export const BASE_PATH_ADMIN_PRODUCTS = PATH_BASE + PATH_ADMIN_PRODUCTS;
export const BASE_PATH_ADMIN_ORDERS = PATH_BASE + PATH_ADMIN_ORDERS;
export const BASE_PATH_ADMIN_TAGS = PATH_BASE + PATH_ADMIN_TAGS;
export const BASE_PATH_ADMIN_CUSTOM_PRICES =
  PATH_BASE + PATH_ADMIN_CUSTOM_PRICES;
export const BASEPATH_ADMIN_MENU = PATH_BASE + PATH_ADMIN_MENU;
export const BASE_PATH_CART = PATH_BASE + PATH_CART;
export const BASE_PATH_CART_CHECKOUT = PATH_BASE + PATH_CART_CHECKOUT;
export const BASE_PATH_CART_ORDER = PATH_BASE + PATH_CART_ORDER;
export const BASE_PATH_CART_SUCCESS = PATH_BASE + PATH_CART_SUCCESS;
