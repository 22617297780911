import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import React, { Component } from "react";

import Grid from "@material-ui/core/Grid/Grid";
import { WORKSHEETS_PERMISSION } from "constans/permissions";
import authRules from "helpers/authRules";
import localize from "helpers/localize";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  row: {
    borderBottom: "solid 1px " + theme.palette.grey[400],
    padding: theme.spacing.unit
  },
  rowLast: {
    borderBottom: "none"
  },
  textMiddleAndRight: {
    textAlign: "right",
    position: "relative",
    top: "50%",
    right: 0,
    transform: "translate(0, -50%)"
  },
  expansionDetails: {
    display: "block"
  }
});

class CashregisterHistory extends Component {
  state = {
    expanded: null
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  render() {
    const { classes, simple, openWorksheetModal } = this.props;
    const { expanded } = this.state;
    const canViewWorksheets = authRules.canViewByKey(WORKSHEETS_PERMISSION);
    //Teljesen szimpla lista nézet

    let groups = [];
    let lastMsg = "";
    let groupIndex = -1;
    this.props.events &&
      this.props.events.forEach(event => {
        if (lastMsg !== event.msg) {
          groups.push({
            msg: event.msg,
            items: [event]
          });
          groupIndex++;
        } else {
          groups[groupIndex].items.push(event);
        }

        lastMsg = event.msg;
      });

    if (simple) {
      return groups.map((group, gIndex) => {
        return (
          <Grid
            key={"history" + gIndex}
            container={true}
            className={
              classes.row +
              (gIndex < groups.length - 1 ? "" : " " + classes.rowLast)
            }
          >
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>
                {group.msg}
              </Typography>
              {group.items.map((event, index) => {
                return (
                  <Grid
                    key={"history" + index}
                    container={true}
                    className={
                      classes.row +
                      (index < group.items.length - 1
                        ? ""
                        : " " + classes.rowLast)
                    }
                    style={{ padding: 4 }}
                  >
                    <Grid item={true} xs={12} md={6} style={{ paddingLeft: 8 }}>
                      {event.key ? (
                        <Tooltip title={event.key}>
                          <span>{event.created_at}</span>
                        </Tooltip>
                      ) : event.worksheet_id ? (
                        <Link
                          to={
                            canViewWorksheets
                              ? `/worksheets/view/${event.worksheet_id}`
                              : "#"
                          }
                          onClick={
                            canViewWorksheets
                              ? undefined
                              : evt =>
                                  openWorksheetModal(event.worksheet_id, evt)
                          }
                        >
                          {event.created_at}
                        </Link>
                      ) : (
                        event.created_at
                      )}
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <div
                        className={classes.textMiddleAndRight}
                        style={{ marginRight: 12 }}
                      >
                        {event.created_by_company_name ? (
                          <Tooltip title={event.created_by_company_name}>
                            <span>{event.created_by_real_name}</span>
                          </Tooltip>
                        ) : (
                          event.created_by_real_name
                        )}
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      });
    }

    return (
      <ExpansionPanel
        expanded={expanded === "panel1"}
        onChange={this.handleChange("panel1")}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            {localize("cashregisters", "Történet")}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          {this.props.events &&
            this.props.events.map((event, index) => (
              <Grid
                key={"history" + index}
                container={true}
                className={classes.row}
              >
                <Grid item={true} xs={12} md={6}>
                  {event.msg}
                  <br />
                  {event.created_at}
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <div className={classes.textMiddleAndRight}>
                    {event.created_by_company_name ? (
                      <Tooltip title={event.created_by_company_name}>
                        {event.created_by_real_name}
                      </Tooltip>
                    ) : (
                      event.created_by_real_name
                    )}
                  </div>
                </Grid>
              </Grid>
            ))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

CashregisterHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  events: PropTypes.array,
  openWorksheetModal: PropTypes.func.isRequired
};

export default withStyles(styles)(CashregisterHistory);
