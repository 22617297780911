import React from "react";
import PropTypes from "prop-types";
import localize from "helpers/localize";
import DataTableDevExpress from "Components/New/DataTable/DataTableDevExpress/DataTableDevExpress";
import DataProvider from "Components/New/DataProvider/DataProvider";
import Button from "@material-ui/core/Button";
import restHelper from "helpers/aee/restHelper";
import dateHelper from "helpers/dateHelper";
import { withRouter, Link } from "react-router-dom";
import MetaTable from "Containers/Pages/Cloud/MetaTable";
import MetaTableImages from "Containers/Pages/Cloud/MetaTableImages";
import moment from "moment";
import FileSaver from "file-saver";
import axios from "axios";
import {IconButton} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Tooltip from "@material-ui/core/Tooltip";

const LC = "cloud"; //Localization category

class Accounting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  defaultRange = () => {
    const defaultRange = dateHelper.getRange("today");
    const storageRange = dateHelper.datesToRange(
      JSON.parse(sessionStorage.getItem("accounting-range"))
    );
    return storageRange || defaultRange;
  };

  download = (vats = false) => {
    var headers = {
      Client: this.props.client_id,
      Authorization: localStorage.getItem("access_token"),
      Space: process.env.REACT_APP_SPACE,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json; charset=UTF-8"
    };

    const defaultRange = this.defaultRange();
    const params = {
      from: defaultRange.start.format("YYYY-MM-DD"),
      to: defaultRange.end.format("YYYY-MM-DD"),
      export: 1
    };
    const url = process.env.REACT_APP_AEE_HOST +
        "/v1/cashregister-accounting" + ( vats ? "/vats" : "" );

    if (this.props.cashregister_id) {
      headers.APN = this.props.cashregister_id;
    }

    axios.get(url, {headers: headers, params: params}).then(response => {
      FileSaver.saveAs(
        new Blob([response.data], { type: "text/plain;charset=utf-8" }),
          (vats ? "CashregistersAccountingVats.csv" : "CashregistersAccounting.csv")
        );
    });
  };

  downloadVats = () => {
  };

  render() {
    const { cashregister_id, client_id } = this.props;
    const defaultRange = this.defaultRange();

    const hash = this.props.history.location.hash || "#receipts";
    return (
      <div
        style={{
          paddingTop: 8,
          width: "100%",
          position: "relative",
          height: "100%"
        }}
      >
        <Link to="#receipts" style={{ textDecoration: "none" }}>
          <Button
            size="small"
            variant="contained"
            color={hash === "#receipts" ? "primary" : "default"}
            style={{ margin: 8 }}
          >
            {localize(LC, "Nyugták és bizonylatok")}
          </Button>
        </Link>
        <Link to="#vat-categories" style={{ textDecoration: "none" }}>
          <Button
            size="small"
            variant="contained"
            color={hash === "#vat-categories" ? "primary" : "default"}
            style={{ margin: 8 }}
          >
            {localize(LC, "Napi összesítés ÁFA kategóriák szerint")}
          </Button>
        </Link>
        <Link to="#images" style={{ textDecoration: "none" }}>
          <Button
            size="small"
            variant="contained"
            color={hash === "#images" ? "primary" : "default"}
            style={{ margin: 8 }}
          >
            {localize(LC, "Nyugtaképek")}
          </Button>
        </Link>
        {hash === "#receipts" && (
          <DataProvider
            //key={url}
            height={this.props.height}
            url="cashregister-accounting"
            restHelperOptions={{ ap: cashregister_id, client: client_id }}
            restHelper={this.props.restHelper}
            //optionsUrl={"cashregister/options"}
            alwaysShowDetailsComponent
            component={DataTableDevExpress}
            toolbarItems={
              <Tooltip title="Adatok frissítése">
                <IconButton
                  color="primary"
                  style={{
                    padding: 8,
                    marginBottom: -8,
                    marginTop: 8,
                    marginLeft: 4
                  }}
                  onClick={() => {
                    this.download();
                  }}
                >
                  <CloudDownload />
                </IconButton>
            </Tooltip>
            }
            detailsComponent={MetaTable}
            dateRangePickerProps={{
              fetchRanges: (start, end) => {
                return restHelper
                  .index(
                    "cashregister-accounting/dates",
                    {
                      from: start.format("YYYY-MM-DD"),
                      to: end.format("YYYY-MM-DD")
                    },
                    { ap: cashregister_id, client: client_id }
                  )
                  .then(response => {
                    const dateRanges =
                      response.data &&
                      response.data.map(row => ({
                        state: "available",
                        range: moment.range(moment(row.dts), moment(row.dts))
                      }));
                    return dateRanges || [];
                  });
              }
            }}
            title={localize(LC, "Könyvelés")}
            queryParams={{
              from: defaultRange.start.format("YYYY-MM-DD"),
              to: defaultRange.end.format("YYYY-MM-DD")
            }}
            summaryItems={[
              { columnName: "amount", type: "sum" },
              { columnName: "cash", type: "sum" },
              { columnName: "card", type: "sum" },
              { columnName: "voucher", type: "sum" },
              { columnName: "eur", type: "sum" }
            ]}
            columnsFormat={{
              amount: "Ft",
              card: "Ft",
              cash: "Ft",
              voucher: "Ft",
              eur: "Eur",
              cnc: "bool",
              range: "range"
            }}
            defaultColumnWidths={{
              dts: 150,
              range: 280,
              num: 120,
              type: 57,
              cnc: 50
            }}
          />
        )}
        {hash === "#vat-categories" && (
          <DataProvider
            //key={url}
            height={this.props.height}
            url="cashregister-accounting/vats"
            restHelperOptions={{ ap: cashregister_id, client: client_id }}
            restHelper={this.props.restHelper}
            optionsUrl={"cashregister-accounting/options"}
            gridOptionsKey="vats"
            component={DataTableDevExpress}
            toolbarItems={
              <Tooltip title="Adatok frissítése">
                <IconButton
                  color="primary"
                  style={{
                    padding: 8,
                    marginBottom: -8,
                    marginTop: 8,
                    marginLeft: 4
                  }}
                  onClick={() => {
                    this.download(true)
                  }}
                >
                  <CloudDownload />
                </IconButton>
              </Tooltip>
            }
            //detailsComponent={MetaTable}
            title={localize(LC, "Könyvelés")}
            queryParams={{
              from: defaultRange.start.format("YYYY-MM-DD"),
              to: defaultRange.end.format("YYYY-MM-DD")
            }}
            summaryItems={[
              { columnName: "amount", type: "sum" },
              { columnName: "GYF_A", type: "sum" },
              { columnName: "GYF_B", type: "sum" },
              { columnName: "GYF_C", type: "sum" },
              { columnName: "GYF_D", type: "sum" },
              { columnName: "GYF_E", type: "sum" },

              { columnName: "SGY_A", type: "sum" },
              { columnName: "SGY_B", type: "sum" },
              { columnName: "SGY_C", type: "sum" },
              { columnName: "SGY_D", type: "sum" },
              { columnName: "SGY_E", type: "sum" },

              { columnName: "VGY_A", type: "sum" },
              { columnName: "VGY_B", type: "sum" },
              { columnName: "VGY_C", type: "sum" },
              { columnName: "VGY_D", type: "sum" },
              { columnName: "VGY_E", type: "sum" }
            ]}
            columnsFormat={{
              amount: "Ft",
              GYF_A: "Ft",
              GYF_B: "Ft",
              GYF_C: "Ft",
              GYF_D: "Ft",
              GYF_E: "Ft",
              SGY_A: "Ft",
              SGY_B: "Ft",
              SGY_C: "Ft",
              SGY_D: "Ft",
              SGY_E: "Ft",
              VGY_A: "Ft",
              VGY_B: "Ft",
              VGY_C: "Ft",
              VGY_D: "Ft",
              VGY_E: "Ft"
            }}
            defaultColumnWidths={{
              range_start: 155,
              dts: 145,
              amount: 120,
              GYF_A: 70,
              GYF_B: 70,
              GYF_C: 70,
              GYF_D: 70,
              GYF_E: 70,
              SGY_A: 70,
              SGY_B: 70,
              SGY_C: 70,
              SGY_D: 70,
              SGY_E: 70,
              VGY_A: 70,
              VGY_B: 70,
              VGY_C: 70,
              VGY_D: 70,
              VGY_E: 70
            }}
          />
        )}
        {hash === "#images" && (
          <DataProvider
            //key={url}
            url={"cashregister-accounting/images"}
            restHelperOptions={{ ap: cashregister_id, client: client_id }}
            restHelper={this.props.restHelper}
            optionsUrl="cashregister-accounting/options"
            gridOptionsKey="images"
            component={DataTableDevExpress}
            title={localize(LC, "Könyvelés")}
            detailsComponent={MetaTableImages}
            customContainer
            height={this.props.height}
            queryParams={{
              from: defaultRange.start.format("YYYY-MM-DD"),
              to: defaultRange.end.format("YYYY-MM-DD")
            }}
            defaultColumnWidths={{
              dts: 150,
              num: 120,
              type: 57
            }}
          />
        )}
      </div>
    );
  }
}

Accounting.defaultProps = {
  restHelper
};

Accounting.propTypes = {
  classes: PropTypes.object,
  //history: PropTypes.object.isRequired,
  //match: PropTypes.object.isRequired,
  restHelper: PropTypes.any,
  cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

export default withRouter(Accounting);
