import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import UploadServiceOrder from "./views/UploadServiceOrder";

const mapStateToProps = state => {
  return {
    id: state.serviceOrders.id
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)(UploadServiceOrder);
