import axios from 'axios';
import qs from 'qs';
import { get } from 'lodash';
import * as fileActions from "./file";
import moment from "moment";
import _ from "lodash";

const MYEXAMS_LIST_LOAD = 'MYEXAMS_LIST_LOAD';
const MYEXAMS_LIST_SUCCESS = 'MYEXAMS_LIST_SUCCESS';
const MYEXAMS_LIST_FAILED = 'MYEXAMS_LIST_FAILED';
const MYEXAMS_SET_LOADING = 'MYEXAMS_SET_LOADING';

const MYEXAMS_FORM_LOAD = 'MYEXAMS_FORM_LOAD';
const MYEXAMS_FORM_SUCCESS = 'MYEXAMS_FORM_SUCCESS';
const MYEXAMS_FORM_FAILED = 'MYEXAMS_FORM_FAILED';

const MYEXAM_DELETE_LOAD = 'MYEXAM_DELETE_LOAD';
const MYEXAM_DELETE_SUCCESS = 'MYEXAM_DELETE_SUCCESS';
const MYEXAM_DELETE_FAILED = 'MYEXAM_DELETE_FAILED';

const MYEXAM_EDIT_LOAD = 'MYEXAM_EDIT_LOAD';
const MYEXAM_EDIT_SUCCESS = 'MYEXAM_EDIT_SUCCESS';
const MYEXAM_EDIT_FAILED = 'MYEXAM_EDIT_FAILED';

const MYEXAM_VIEW_LOAD = 'MYEXAM_VIEW_LOAD';
const MYEXAM_VIEW_SUCCESS = 'MYEXAM_VIEW_SUCCESS';
const MYEXAM_VIEW_FAILED = 'MYEXAM_VIEW_FAILED';

const MYEXAMS_RESET = 'MYEXAMS_RESET';
const MYEXAMS_RESET_FORM = 'MYEXAMS_RESET_FORM';

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit:process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: '',
  orderMode: 'ASC',
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null,
  result: null,
  certificate: null,
  isFailedExam: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MYEXAMS_RESET:
      return {
        ...initialState
      };
    case MYEXAMS_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case MYEXAM_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case MYEXAMS_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false
      };
    case MYEXAM_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case MYEXAM_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case MYEXAMS_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case MYEXAMS_FORM_FAILED:
    case MYEXAM_EDIT_FAILED:
    case MYEXAM_DELETE_FAILED:
    case MYEXAMS_LIST_FAILED:
    case MYEXAM_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false,
        isFailedExam: action.isFailedExam
      };
    case MYEXAMS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode
      };
      case MYEXAM_VIEW_SUCCESS:
        return {
            ...state,
            loading: false,
            success: true,
            failed: false,
            viewData: action.viewData,
            commentThread: action.commentThread || false,
            viewLoading: false
        };
    case MYEXAMS_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case MYEXAM_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case MYEXAM_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: action.id,
        viewLoading: false,
        result: action.result,
        certificate: action.certificate
      };
    case MYEXAMS_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
}

export const reset = () => dispatch => {
  dispatch({
    type: MYEXAMS_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: MYEXAMS_RESET_FORM
  });
};

export const updateExams = (model,id,data) => dispatch => {
  dispatch({
    type: MYEXAM_EDIT_LOAD
  });

    let obj = {
        ExamForm: _.pick(data, ['exam_result_id', 'answers'])
    };

    if (obj.ExamForm.answers) {
      obj.ExamForm.answers.map((answer, index) => {
        answer.question_id = data.questions[index].id;
      });
    }

  return axios.put('/v1/user-exams/exam/'+id, obj).then(
      (response) => {
        return dispatch({
            type: MYEXAM_EDIT_SUCCESS,
            result: get(response, 'data.data.result', 0),
            certificate: get(response, 'data.data.examUser.certificate', false),
        })
      }
  ).catch(error => {
    dispatch({
      type: MYEXAM_EDIT_FAILED,
      message: get(error, 'response.data.message', null),
      formErrors: get(error, 'response.data.form_errors', {})
    })
  });
};

export const viewExams = (id) => dispatch => {
    let eid = id.replace('raw', '');
    dispatch({
        type: MYEXAM_VIEW_LOAD
    });
    // return axios.get('/v1/user-exams/exam'+eid)
    //     .then(response => {
    //         const viewData = get(response, 'data.data', {});
    //         viewData.exam_result_id = 17;
    //         return dispatch({
    //             type: MYEXAM_VIEW_SUCCESS,
    //             viewData,
    //         })
    //     })
    //     .catch(error => {
    //         dispatch({
    //             type: MYEXAM_VIEW_FAILED,
    //             message: get(error, 'response.data.message', null)
    //         })
    //     });
    return axios.post('/v1/user-exams/exam'+eid).then((resp) => {
      return axios.get('/v1/user-exams/exam'+eid)
          .then(response => {
            const viewData = get(response, 'data.data', {});
            viewData.exam_result_id = resp.data.data.id;
            dispatch({
                type: MYEXAM_VIEW_SUCCESS,
                viewData,
            });

            return response.data;
          })
          .catch(error => {
            dispatch({
                type: MYEXAM_VIEW_FAILED,
                message: get(error, 'response.data.message', null)
            })
        });
      }).catch(error => {
            dispatch({
                type: MYEXAM_VIEW_FAILED,
                message: get(error, 'response.data.message', null),
                isFailedExam: true
            })
      });
};

export const createExams = (model, data) => dispatch => {
  dispatch({
    type: MYEXAM_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios.post('/v1/user-exams', obj).then(response => dispatch({
    type: MYEXAM_EDIT_SUCCESS,
    id: get(response, 'data.data.id', null),
  })).catch(error => {
    dispatch({
      type: MYEXAM_EDIT_FAILED,
      message: get(error, 'response.data.message', null),
      formErrors: get(error, 'response.data.form_errors', {})
    })
  });
};

export const downloadExam = (id) => dispatch => {
    return dispatch(fileActions.download('/v1/user-exams/certificate/'+id, `exam_${moment().format('YYYY-MM-DD')}.pdf`));
};

export const examDelete = (id) => dispatch => {
  dispatch({
    type: MYEXAM_DELETE_LOAD
  });
  return axios.delete('/v1/user-exams/'+id)
      .then((response) => dispatch({
        type: MYEXAM_DELETE_SUCCESS,
        id
      })).catch(error => dispatch({
        type: MYEXAM_DELETE_FAILED,
        message: get(error, 'response.data.message', null)
      }));
};

export const setLoading = (loading) => dispatch => {
  dispatch({
    type: MYEXAMS_SET_LOADING,
    loading
  });
};

export const examsList = (page, keyword, sort, filters, perPage) => dispatch => {
  dispatch({
    type: MYEXAMS_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    'per-page': perPage
  };

  params[filters.model] = filters.filters;

  return axios.get(
      '/v1/user-exams',
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
  ).then(response => {
    let sort = get(response, 'data.sort', null),
        mode = 'ASC';
    if(sort && sort.indexOf('-') !== -1) {
      mode = 'DESC';
      sort = sort.substr(1);
    }
    return dispatch({
      type: MYEXAMS_LIST_SUCCESS,
      data: get(response, 'data.data', null),
      page: get(response, 'data.page', 0),
      limit: get(response, 'data.per-page', 0),
      total: get(response, 'data.total', 0),
      order: sort,
      orderMode: mode
    });
  })
  .catch(error => dispatch({
    type: MYEXAMS_LIST_FAILED,
    message: get(error, 'response.data.message', null)
  }));
};
