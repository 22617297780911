import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ExamsList from "./views/ExamsList";
import * as examsActions from "../../../../redux/modules/exams";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.exams.loading,
    success: state.exams.success,
    message: state.exams.message,
    data: state.exams.data,
    total: state.exams.total,
    limit: state.exams.limit,
    page: state.exams.page,
    order: state.exams.order,
    orderMode: state.exams.orderMode,
    deleted: state.exams.deleted,
    deletedId: state.exams.deletedId,
    token: state.exams.token,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: examsActions.examsList,
      delete: examsActions.examDelete,
      setLoading: examsActions.setLoading,
      reset: examsActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamsList);
