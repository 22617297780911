import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid/Grid";
import restHelper from "helpers/restHelper";
import { height } from "window-size";
const styles = theme => ({
  row: {
    borderBottom: "solid 1px " + theme.palette.grey[400],
    padding: theme.spacing.unit
  },
  rowLast: {
    borderBottom: "none"
  },
  textMiddleAndRight: {
    textAlign: "right",
    position: "relative",
    top: "50%",
    right: 0,
    transform: "translate(0, -50%)"
  }
});

class HistoryBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: []
    };
  }

  componentDidMount() {
    const { url, events } = this.props;
    if (url) {
      const setState = this.setState;
      window.worksheetHistoryTimeout = setTimeout(() => {
        restHelper.index(url).then(response => {
          setState({ events: response.data });
        });
      }, 1000);
    } else if (events) {
      this.setState({ events: events });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.events) !== JSON.stringify(nextProps.events)
    ) {
      //Változtak az adatok
      this.setState({ events: nextProps.events });
    }
  }

  componentWillUnmount() {
    clearTimeout(window.worksheetHistoryTimeout);
  }

  render() {
    const { classes } = this.props;
    const { events } = this.state;

    return (
      <div
        style={{
          overflow: "auto",
          maxHeight: 300
        }}
      >
        {events &&
          events.map((event, index) => (
            <Grid
              key={"history" + index}
              container={true}
              className={
                classes.row +
                (index < events.length - 1 ? "" : " " + classes.rowLast)
              }
            >
              <Grid item={true} xs={12} md={6} style={{ paddingLeft: 8 }}>
                {event.key ? (
                  <Tooltip title={event.key}>
                    <span>{event.msg}</span>
                  </Tooltip>
                ) : (
                  event.msg
                )}
                <br />
                {event.created_at}
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <div
                  className={classes.textMiddleAndRight}
                  style={{ marginRight: 12 }}
                >
                  {event.created_by_company_name ? (
                    <Tooltip title={event.created_by_company_name}>
                      <span>{event.created_by_real_name}</span>
                    </Tooltip>
                  ) : (
                    event.created_by_real_name
                  )}
                </div>
              </Grid>
            </Grid>
          ))}
      </div>
    );
  }
}

HistoryBox.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string,
  events: PropTypes.array
};

export default withStyles(styles)(HistoryBox);
