/* eslint-disable */
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";
import TagInput from "../../../../Components/Forms/Fields/AsyncTagInput";
import InputField from "../../../../Components/Forms/Fields/Input";
import SelectField from "../../../../Components/Forms/Fields/Select";
import Validations from "../../../../Components/Forms/Validations";
import EditForm from "../../../../Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import { mobilNumberMask, taxNumberMask } from "helpers/maskHelper";
import localize from "helpers/localize";

class DistributorsForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  handleSetTags = (name, tags) => {
    const { dispatch, change } = this.props;
    dispatch(change("tags", tags));
  };

  render() {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      enabledFields
    } = this.props;

    let denieds = [];
    Object.keys(get(this.props, "baseFields.denied.data", {})).forEach(key => {
      denieds.push({ id: key, label: this.props.baseFields.denied.data[key] });
    });

    let roles = [];
    Object.keys(get(this.props, "baseFields.role.data", {})).forEach(key => {
      roles.push({ id: key, label: this.props.baseFields.role.data[key] });
    });

    return (
      <EditForm {...this.props}>
        <div className="col-12">
          <Field
            name="role"
            label={localize("distributors", "Típus")}
            fieldError={fieldErrors.role}
            onChange={() => handleInputChange("role")}
            isRequired={true}
            validate={[Validations.required]}
            component={SelectField}
            placeholder={localize("distributors", "Típus")}
            isViewPage={isViewPage}
            options={roles}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("role") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
        <div className="col-12">
          <Field
            name="company_name"
            label={localize("distributors", "Szervíz neve")}
            fieldError={fieldErrors.company_name}
            onChange={() => handleInputChange("company_name")}
            isRequired={true}
            validate={[Validations.required]}
            component={InputField}
            type="text"
            placeholder={localize("distributors", "Szervíz neve")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("company_name") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
        <div className="col-12">
        <Field
                name="tax_number"
                isRequired={true}
                validate={[Validations.required, Validations.tax_number]}
                label={localize("distributors", "Adószám")}
                fieldError={fieldErrors.tax_number}
                onChange={() => handleInputChange("tax_number")}
                component={InputField}
                placeholder={localize("distributors", "Adószám")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("tax_number") !== -1
                      ? false
                      : true
                    : false
                }
                {...taxNumberMask}
              />
        </div>
        <div className="col-12">
          <Field
            name="registration_number"
            label={localize("distributors", "Nyilvántartási száma")}
            fieldError={fieldErrors.registration_number}
            onChange={() => handleInputChange("registration_number")}
            isRequired={true}
            validate={[Validations.required]}
            component={InputField}
            type="text"
            placeholder={localize("distributors", "Nyilvántartási száma")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("registration_number") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
        <div className="col-12">
          <Field
            name="address"
            label={localize("distributors", "Székhelye")}
            fieldError={fieldErrors.address}
            onChange={() => handleInputChange("address")}
            isRequired={true}
            validate={[Validations.required]}
            component={InputField}
            type="text"
            placeholder={localize("distributors", "Székhelye")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("address") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
        <div className="col-12">
          <Field
            name="phone"
            label={localize("distributors", "Telefonszám")}
            fieldError={fieldErrors.phone}
            onChange={() => handleInputChange("phone")}
            validate={[Validations.required, Validations.mobile_number]}
            {...mobilNumberMask}
            component={InputField}
            type="text"
            placeholder={localize("distributors", "Telefonszám")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("phone") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
        <div className="col-12">
          <Field
            name="email"
            label={localize("distributors", "Email cím")}
            fieldError={fieldErrors.email}
            onChange={() => handleInputChange("email")}
            validate={[Validations.required, Validations.email]}
            component={InputField}
            type="text"
            placeholder={localize("distributors", "Email cím")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("email") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
        <div className="col-12">
          <Field
            name="contact_person"
            label={localize("distributors", "Kapcsolattartó")}
            fieldError={fieldErrors.contact_person}
            onChange={() => handleInputChange("contact_person")}
            component={InputField}
            type="text"
            placeholder={localize("distributors", "Kapcsolattartó")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("contact_person") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
        <div className="col-12">
          <Field
            name="denied"
            label={localize("distributors", "Tiltások")}
            fieldError={fieldErrors.denied}
            onChange={() => handleInputChange("denied")}
            component={SelectField}
            placeholder={localize("distributors", "Tiltások")}
            isRequired={true}
            validate={[Validations.required]}
            isViewPage={isViewPage}
            options={denieds}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("denied") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
        <div className="col-12">
          <Field
            name="vendor_ap_filter"
            label={localize("distributors", "AP tartomány")}
            fieldError={fieldErrors.denied}
            onChange={() => handleInputChange("vendor_ap_filter")}
            component={InputField}
            placeholder={localize("distributors", "AP tartomány")}
            //isRequired={true} validate={[Validations.required]}
            isViewPage={isViewPage}
            type="text"
            //options={denieds}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("vendor_ap_filter") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
        <div className="col-12" style={{marginBottom: 16}}>
          <Field
            name="tags"
            label={localize("distributors", "Címkék")}
            fieldError={fieldErrors.tags}
            //onChange={() => handleInputChange("tags")}
            onChangeTags={tags => this.handleSetTags("tags", tags)}
            component={TagInput}
            placeholder={localize("distributors", "Címkék")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("tags") !== -1
                  ? false
                  : true
                : false
            }
            tagPath={get(
              this.props,
              "baseFields.tags.url",
              null
            )}
          />
        </div>
      </EditForm>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "distributors",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(DistributorsForm);

export default connect(
  state => ({
    initialValues: state.distributors.viewData
  }),
  null
)(UsersReduxForm);
