import { Typography } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import WorksheetIcon from "@material-ui/icons/Assignment";
import ClientIcon from "@material-ui/icons/Business";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import InfoIcon from "@material-ui/icons/Info";
import Save from "@material-ui/icons/Save";
import Modal from "Components/Modal/Modal";
import ModalConfirm from "Components/Modal/ModalConfirm";
import EditClient from "Containers/Pages/Clients/EditClient";
import authRules from "helpers/authRules";
import localize from "helpers/localize";
import { apMask } from "helpers/maskHelper.js";
import * as _ from "lodash";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import CashregisterIcon from "../../../../../Components/CashregisterIcon.jsx";
import EditForm from "../../../../../Components/Forms/Builder/EditForm";
import InputField from "../../../../../Components/Forms/Fields/Input";
import SelectField from "../../../../../Components/Forms/Fields/Select";
import Validations from "../../../../../Components/Forms/Validations";
import ServiceOrders from "../../../../../Components/ServiceOrders/ServiceOrders";
import { getCachRegisterStatusConfig } from "../../../../../redux/modules/cashregisters";
import CashregisterHistory from "./CashregisterHistory";
import CashRegisterReregisters from "./CashRegisterReregisters";
import WorksheetModalContent from "./WorksheetModalContent";

const telekomStatusCodeTooltipText = status => {
  switch (status) {
    case "C":
      return "Rendben van: Szerződés ellenőrzési szempontból megfelel az elvárásoknak, nincs vele további teendő.";
    case "E":
      return "Hibás: A szerződés hibás, javítani szükséges, de a szerződés nem kerül visszapostázásra.";
    case "B":
      return "Visszaküldött: A szerződés hibás, javítani szükséges, és visszaküldésre kerül (személyesen kerül átadásra). Súlyos hiba esetén kerül ebbe a státuszba a szerződés, jellemzően aláírás hiánya illetve pecsételt aláírás miatt.";
    case "N":
      return "beérkezett: A szerződés nem érkezett be a Magyar Telekomhoz, kérjük beküldeni.";
    default:
      return "";
  }
};

class CashregisterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showReregisters: false,
      selectedClient: false,
      selectedLocation: false,
      showClientEditModal: false,
      clientData: {},
      cashregistersStatusConf: [],
      cashregistersSuspendedConf: [],
      isStatusEditMode: false,
      isStatusEditable: false,
      isSuspendedEditMode: false,
      isSuspendedEditable: false,
      openWorksheetModal: false,
      worksheetId: null
    };
    this.settingsForm = React.createRef();
    this.handleTaxOnBlur = null;
    this.modal = React.createRef();
  }

  openWorksheetModal = (id, evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({ openWorksheetModal: true, worksheetId: id });
  };

  closeWorksheetModal = () => {
    this.setState({ openWorksheetModal: false });
  };

  componentDidMount() {
    this.refreshHistory();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.initialValues && nextProps.initialValues) {
      const hasPermission =
        authRules.hasPermissionByKeyAndName(
          "cashregisters",
          "cashregisters.status"
        ) ||
        authRules.hasPermissionByKeyAndName(
          "cashregisters",
          "cashregisters.suspended"
        ) ||
        authRules.hasPermissionByKeyAndName(
          "cashregisters",
          "cashregisters.status.whenscrapped"
        );

      if (hasPermission) {
        this._setCashRegisterDropdownData(nextProps.initialValues.id);
      }
    }
  }

  _setCashRegisterDropdownData(id) {
    getCachRegisterStatusConfig(id)
      .then(resp => {
        let cashregistersStatusConf = [];

        _.forEach(
          resp.data.data.status.baseFields.status.data,
          (value, key) => {
            cashregistersStatusConf.push({
              id: key,
              label: value
            });
          }
        );
        let cashregistersSuspendedConf = [];

        _.forEach(
          resp.data.data.status.baseFields.suspended_by_service_order.data,
          (value, key) => {
            cashregistersSuspendedConf.push({
              id: String(key),
              label: value
            });
          }
        );

        this.setState({
          cashregistersStatusConf: cashregistersStatusConf,
          cashregistersSuspendedConf: cashregistersSuspendedConf,
          isStatusEditable:
            authRules.hasPermissionByKeyAndName(
              "cashregisters",
              "cashregisters.status"
            ) ||
            authRules.hasPermissionByKeyAndName(
              "cashregisters",
              "cashregisters.status.whenscrapped"
            ),
          isSuspendedEditable: authRules.hasPermissionByKeyAndName(
            "cashregisters",
            "cashregisters.suspended"
          )
        });
      })
      .catch(err => {});
  }

  saveStatus = () => {
    const status = this.state.modifiedStatus;
    const id = this.props.initialValues.id;

    const status_text = this.state.cashregistersStatusConf.find(
      item => item.id === status
    );
    if (status === "scrapped" && !authRules.isRoot) {
      this.modal
        .open(
          "Selejtezés megerősítése",
          "Biztosan selejtezettre állítja a státuszt? A változás nem visszaállítható, a kulcsgenerálások letiltásra kerülnek.",
          null,
          "Ok",
          "Mégsem"
        )
        .then(() => {
          this.props.setStatus(id, status, status_text.label).then(() => {
            this.setState({ isStatusEditMode: false });
            this.modal.close();
            this.refreshHistory();
          });
        });
    } else {
      this.props.setStatus(id, status, status_text.label).then(() => {
        this.setState({ isStatusEditMode: false });
        //this.props.change("satus", status);
        //this.props.view(id);
        this.refreshHistory();
      });
    }
  };
  saveSuspended = () => {
    const suspended = this.state.modifiedSuspended;
    const id = this.props.initialValues.id;

    this.props.setSuspended(id, suspended).then(() => {
      this.setState({ isSuspendedEditMode: false });
      //this.props.view(id);
      //this.props.change("suspended_by_service_order", suspended);
      this.refreshHistory();
    });
  };

  refreshHistory = () => {
    const id = get(this.props, "match.params.id", null);
    if (id) {
      this.props.historyList(id);
    }
  };

  componentWillMount() {
    //this.props.resetForm();
    this.props.initialize();
  }

  showReregisters = () => {
    const initialValues = this.props.initialValues || {};
    this.props.listCashregisterReregisters(initialValues.id);
    this.setState({ showReregisters: true });
  };

  closeReregisters = () => {
    this.setState({ showReregisters: false });
  };

  submitClientForm = () => {
    const instance = this.settingsForm.current.getWrappedInstance();
    if (instance) {
      instance.submit();
    }
  };

  afterSaveClient = res => {
    this.handleCloseModal();
  };

  handleEditClient = clientData => {
    //const {dispatch, change} = this.props;
    //dispatch(change('client_id', null));
    this.setState({
      showClientEditModal: true,
      clientData: clientData
    });
  };

  handleCloseModal = () => {
    //Vissza a service-orders form opciókat
    this.props.getFormInfo("service-orders").then(response => {
      if (this.handleTaxOnBlur) {
        this.handleTaxOnBlur(null, this.state.clientData.client_tax_number);
      }
      this.setState({
        showClientEditModal: false
      });
    });
  };

  getStatusSelectTooltipText = () => {
    const { viewData } = this.props;
    const statusText = viewData ? viewData.status_text : "";
    const tooltipText = this.state.modifiedStatus
      ? this.state.cashregistersStatusConf.find(
          status => status.id === this.state.modifiedStatus
        ).label
      : statusText;

    return tooltipText;
  };
  render() {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      enabledFields,
      viewData
    } = this.props;
    const { openWorksheetModal, worksheetId } = this.state;
    const initialValues = this.props.initialValues || {};

    let dphs = [];
    Object.keys(get(this.props, "baseFields.dph.data", {})).forEach(key => {
      dphs.push({ id: key, label: this.props.baseFields.dph.data[key] });
    });
    const iconStyle = {
      width: 24,
      height: 24,
      position: "absolute",
      right: 8,
      top: 8,
      fill: "#999",
      zIndex: 0
    };

    const Data = props => (
      <Grid
        container
        spacing={8}
        alignItems="center"
        style={{
          borderBottom: "solid 1px #ddd",
          paddingTop: 4,
          ...props.style
        }}
      >
        <Grid item={true} xs={4}>
          <b>{localize("cashregisters", props.title)}</b>
        </Grid>
        <Grid item={true} xs={8}>
          {props.colorValue && initialValues[props.colorValue] && (
            <div
              style={{
                display: "inline-block",
                marginTop: 6,
                marginRight: 6,
                marginBottom: -2,
                width: 12,
                height: 12,
                borderRadius: 6,
                backgroundColor: initialValues[props.colorValue]
              }}
            />
          )}
          {props.children ||
            (initialValues[props.value] ? (
              initialValues[props.value]
            ) : (
              <span style={{ color: "#bbb", fontStyle: "italic" }}>
                nincs adat
              </span>
            ))}
        </Grid>
      </Grid>
    );

    const clientMatch = Object.assign({}, this.props.match, {
      params: {
        id: this.state.clientData.client_id
      },
      path: "/clients/edit/" + this.state.clientData.client_id,
      url: "/clients/edit/" + this.state.clientData.client_id
    });

    const statusText = viewData ? viewData.status_text : "";
    /*const suspendedText = viewData
      ? viewData.suspended_by_service_order_text
      : "";*/

    const suspendedText =
      viewData && this.state.cashregistersSuspendedConf.length > 0
        ? String(viewData.suspended_by_service_order) === "1"
          ? this.state.cashregistersSuspendedConf[1].label
          : this.state.cashregistersSuspendedConf[0].label
        : "";

    return (
      <Fragment>
        <ModalConfirm onRef={ref => (this.modal = ref)} />
        <Modal
          title="Munkalap"
          open={openWorksheetModal}
          disableCancel
          onAccept={this.closeWorksheetModal}
          onClose={this.closeWorksheetModal}
          maxWidth="lg"
          Icon={WorksheetIcon}
        >
          {viewData && viewData.id && (
            <WorksheetModalContent id={worksheetId} />
          )}
        </Modal>
        <Dialog
          open={this.state.showClientEditModal}
          maxWidth="md"
          onClose={this.handleCloseModal.bind(this)}
          scroll="body"
        >
          {/*<DialogTitle>
                  {this.state.selectedClient.client_name + " " + localize("settings", "szerkesztése")}
              </DialogTitle>*/}
          <DialogContent style={{ paddingBottom: 0, overflow: "hidden" }}>
            <EditClient
              //{...this.props}
              disableButtons={true}
              ref={this.settingsForm}
              match={clientMatch}
              handleGetFormInfo={this.handleGetClientFormInfo}
              history={this.props.history}
              afterSave={this.afterSaveClient}
            />
          </DialogContent>
          <DialogActions
            style={{ padding: 16, paddingTop: 0, allignItems: "flex-end" }}
          >
            <Button
              style={{ marginRight: 8 }}
              onClick={this.handleCloseModal.bind(this)}
              variant="contained"
              color="secondary"
              component="span"
            >
              {localize("settings", "Bezár")}
            </Button>
            <Button
              onClick={this.submitClientForm}
              variant="contained"
              color="primary"
              component="span"
            >
              {localize("settings", "Mentés")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.showReregisters}
          maxWidth="md"
          onClose={this.closeReregisters}
          scroll="body"
        >
          <DialogTitle>
            {localize("cashregisters", "Átszemélyesítések")}
          </DialogTitle>
          <DialogContent>
            <CashRegisterReregisters
              loading={this.props.reregisters_loading}
              items={this.props.reregisters}
              success={this.props.reregisters_success}
              message={this.props.reregisters_message}
            />
          </DialogContent>
          <DialogActions
            style={{ padding: 16, paddingTop: 0, allignItems: "flex-start" }}
          >
            <Button
              style={{ marginLeft: "auto" }}
              onClick={this.closeReregisters}
              variant="contained"
              color="secondary"
              component="span"
            >
              {localize("cashregisters", "Bezár")}
            </Button>
          </DialogActions>
        </Dialog>

        <EditForm {...this.props} disabledEditButton={true}>
          {!isViewPage && (
            <Fragment>
              <Grid
                container={true}
                spacing={16}
                style={{ paddingLeft: 16, paddingRight: 16 }}
              >
                <ServiceOrders
                  {...this.props}
                  showPayments={false}
                  disableEmailAndPhoneRequiredValidation={true}
                  handleEditClient={this.handleEditClient}
                  handleTaxOnBlur={handle => (this.handleTaxOnBlur = handle)}
                  clientBox={true}
                />
                <Grid item xs={12} style={{ marginBottom: -16 }}>
                  <h6 className="form-legend">Pénztárgép</h6>
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="apn"
                    label={localize("cashregisters", "AP szám")}
                    fieldError={fieldErrors.apn}
                    onChange={() => handleInputChange("apn")}
                    isRequired={true}
                    validate={[Validations.required]}
                    component={InputField}
                    type="text"
                    placeholder={localize("cashregisters", "AP szám")}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("apn") !== -1
                          ? false
                          : true
                        : false
                    }
                    {...apMask}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="dme"
                    label={localize("cashregisters", "IMEI")}
                    fieldError={fieldErrors.dme}
                    onChange={() => handleInputChange("dme")}
                    isRequired={true}
                    validate={[Validations.required]}
                    component={InputField}
                    type="text"
                    placeholder={localize("cashregisters", "IMEI")}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("dme") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="dms"
                    label={localize("cashregisters", "IMSI")}
                    fieldError={fieldErrors.dms}
                    onChange={() => handleInputChange("dms")}
                    isRequired={true}
                    validate={[Validations.required]}
                    component={InputField}
                    type="text"
                    placeholder={localize("cashregisters", "IMSI")}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("dms") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="iccid"
                    label={localize("cashregisters", "ICCID")}
                    fieldError={fieldErrors.iccid}
                    onChange={() => handleInputChange("iccid")}
                    isRequired={true}
                    validate={[Validations.required]}
                    component={InputField}
                    type="text"
                    placeholder={localize("cashregisters", "ICCID")}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("iccid") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="dts"
                    label={localize("cashregisters", "Időbélyeg")}
                    fieldError={fieldErrors.dts}
                    component={InputField}
                    //{...dateTimeMask}
                    isRequired={true}
                    validate={[Validations.required, Validations.datetime]}
                    placeholder={localize("cashregisters", "Időbélyeg")}
                    onChange={() => handleInputChange("dts")}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("dts") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="mis"
                    label={localize(
                      "cashregisters",
                      "Műszerész igazolvány száma"
                    )}
                    fieldError={fieldErrors.mis}
                    onChange={() => handleInputChange("mis")}
                    component={InputField}
                    type="text"
                    placeholder={localize(
                      "cashregisters",
                      "Műszerész igazolvány száma"
                    )}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("mis") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="vcc"
                    label={localize("cashregisters", "Üzembehelyezési kód")}
                    fieldError={fieldErrors.vcc}
                    onChange={() => handleInputChange("vcc")}
                    component={InputField}
                    type="text"
                    placeholder={localize(
                      "cashregisters",
                      "Üzembehelyezési kód"
                    )}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("vcc") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="dph"
                    label={localize("cashregisters", "Pénztárgép típus")}
                    fieldError={fieldErrors.dph}
                    onChange={() => handleInputChange("dph")}
                    isRequired={true}
                    validate={[Validations.required]}
                    component={SelectField}
                    placeholder={localize("cashregisters", "Pénztárgép típus")}
                    isViewPage={isViewPage}
                    options={dphs}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("dph") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="dty"
                    label={localize("cashregisters", "AEE gyártói típusnév")}
                    fieldError={fieldErrors.dty}
                    onChange={() => handleInputChange("dty")}
                    component={InputField}
                    type="text"
                    placeholder={localize(
                      "cashregisters",
                      "AEE gyártói típusnév"
                    )}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("dty") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="drv"
                    label={localize("cashregisters", "AEE hardver verzió")}
                    fieldError={fieldErrors.drv}
                    onChange={() => handleInputChange("drv")}
                    component={InputField}
                    type="text"
                    placeholder={localize(
                      "cashregisters",
                      "AEE hardver verzió"
                    )}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("drv") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="dmt"
                    label={localize("cashregisters", "AEE modem")}
                    fieldError={fieldErrors.dmt}
                    onChange={() => handleInputChange("dmt")}
                    component={InputField}
                    type="text"
                    placeholder={localize("cashregisters", "AEE modem")}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("dmt") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="dmf"
                    label={localize("cashregisters", "AEE modem sw")}
                    fieldError={fieldErrors.dmf}
                    onChange={() => handleInputChange("dmf")}
                    component={InputField}
                    type="text"
                    placeholder={localize("cashregisters", "AEE modem sw")}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("dmf") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="dfw"
                    label={localize("cashregisters", "AEE szoftver verzió")}
                    fieldError={fieldErrors.dfw}
                    onChange={() => handleInputChange("dfw")}
                    component={InputField}
                    type="text"
                    placeholder={localize(
                      "cashregisters",
                      "AEE szoftver verzió"
                    )}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("dfw") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="dfh"
                    label={localize("cashregisters", "AEE sw checksum")}
                    fieldError={fieldErrors.dfh}
                    onChange={() => handleInputChange("dfh")}
                    component={InputField}
                    type="text"
                    placeholder={localize("cashregisters", "AEE sw checksum")}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("dfh") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="dft"
                    label={localize("cashregisters", "AEE sw update date")}
                    fieldError={fieldErrors.dft}
                    onChange={() => handleInputChange("dft")}
                    component={InputField}
                    validate={[Validations.datetime]}
                    //{...dateTimeMask}
                    type="text"
                    placeholder={localize(
                      "cashregisters",
                      "AEE sw update date"
                    )}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("dft") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="ssg"
                    label={localize("cashregisters", "Térerő")}
                    fieldError={fieldErrors.ssg}
                    onChange={() => handleInputChange("ssg")}
                    component={InputField}
                    type="text"
                    placeholder={localize("cashregisters", "Térerő")}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields
                        ? enabledFields.indexOf("ssg") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
              </Grid>
            </Fragment>
          )}

          {isViewPage && (
            <Fragment>
              <Grid container item xs={12} sm={12} md={6} lg={4}>
                <Paper
                  style={{ padding: 16, width: "100%", position: "relative" }}
                >
                  <ClientIcon style={iconStyle} />
                  <Grid container spacing={8} style={{ color: "#aaa" }}>
                    <Grid item={true} xs={12}>
                      <i>{localize("cashregisters", "Ügyfél adatok")}</i>
                    </Grid>
                  </Grid>
                  <Data title="Ügyfél">
                    {authRules.hasPermissionByKeyAndName(
                      "cashregisters",
                      "cashregisters.client.view"
                    ) ? (
                      <Link to={`/clients/view/${initialValues.client_id}`}>
                        {initialValues.client_name}
                      </Link>
                    ) : (
                      initialValues.client_name
                    )}{" "}
                    {initialValues.client_phone} {initialValues.client_mail}
                  </Data>
                  <Data title="Adószám" value="client_tax_number" />
                  <Data title="Székhely címe">
                    {initialValues.client_address && (
                      <a
                        href={
                          "https://www.google.com/maps/place/" +
                          initialValues.client_address
                            .replace(/^,+|,+$/g, "")
                            .trim()
                        }
                        target="_blank"
                      >
                        {initialValues.client_address
                          .replace(/^,+|,+$/g, "")
                          .trim()}
                      </a>
                    )}
                  </Data>
                  <Data title="Telephely neve" value="client_location_name" />
                  <Data title="Telephely címe">
                    {initialValues.client_location_address && (
                      <a
                        href={
                          "https://www.google.com/maps/place/" +
                          initialValues.client_location_address
                        }
                        target="_blank"
                      >
                        {initialValues.client_location_address}
                      </a>
                    )}
                  </Data>
                  <Data title="Viszonteladó" value="distributor_name" />
                  <Grid
                    container
                    style={{ paddingTop: 8, color: "#aaa" }}
                    spacing={8}
                  >
                    <Grid item={true} xs={12}>
                      <i>{localize("cashregisters", "Pénztárgép adatok")}</i>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    spacing={8}
                    style={{ borderBottom: "solid 1px #ddd", paddingTop: 4 }}
                  >
                    <Grid item={true} xs={4}>
                      {initialValues.reregister_count > 0 ? (
                        <Badge
                          onClick={this.showReregisters}
                          classes={{ badge: "badgeRight" }}
                          badgeContent={
                            parseInt(initialValues.reregister_count) + 1
                          }
                          color="primary"
                        >
                          <b>{localize("cashregisters", "Státusz")}</b>
                        </Badge>
                      ) : (
                        <b>{localize("cashregisters", "Státusz")}</b>
                      )}
                    </Grid>
                    <Grid item={true} xs={8}>
                      {!this.state.isStatusEditMode ? (
                        <div>
                          <span>{statusText}</span>

                          {this.state.isStatusEditable &&
                            (authRules.hasPermissionByKeyAndName(
                              "cashregisters",
                              "cashregisters.status.whenscrapped"
                            ) ||
                              initialValues.status !== "scrapped") && (
                              <IconButton
                                style={{
                                  padding: 0,
                                  marginLeft: 8
                                }}
                                onClick={e => {
                                  this.setState({ isStatusEditMode: true });
                                }}
                                color="primary"
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            )}
                        </div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                                width: 160
                              }}
                            >
                              <Field
                                fullWidth
                                name="status"
                                onChange={e => {
                                  this.setState({
                                    modifiedStatus: e.target.value
                                  });
                                  handleInputChange("status");
                                }}
                                component={SelectField}
                                placeholder={localize(
                                  "cashregisters",
                                  "Pénztárgép típus"
                                )}
                                options={this.state.cashregistersStatusConf}
                              />
                            </div>
                            <div style={{ width: 68 }}>
                              <IconButton
                                style={{
                                  padding: 0,
                                  marginLeft: 16
                                }}
                                onClick={e => {
                                  this.saveStatus();
                                }}
                                color="primary"
                              >
                                <Save fontSize="small" />
                              </IconButton>

                              <IconButton
                                style={{
                                  padding: 0,
                                  marginLeft: 8
                                }}
                                onClick={e => {
                                  this.setState({ isStatusEditMode: false });
                                }}
                                color="primary"
                              >
                                <Close fontSize="small" />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    spacing={8}
                    style={{ borderBottom: "solid 1px #ddd", paddingTop: 4 }}
                  >
                    <Grid item={true} xs={4}>
                      <b>{localize("cashregisters", "Felfüggesztve")}</b>
                    </Grid>
                    <Grid item={true} xs={8}>
                      {!this.state.isSuspendedEditMode ? (
                        <div>
                          <span>{suspendedText}</span>

                          {this.state.isSuspendedEditable && (
                            <IconButton
                              style={{
                                padding: 0,
                                marginLeft: 8
                              }}
                              onClick={e => {
                                this.setState({ isSuspendedEditMode: true });
                              }}
                              color="primary"
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          )}
                          {viewData &&
                          String(viewData.suspended_by_service_order) ===
                            "1" ? (
                            <Typography
                              component="span"
                              variant="caption"
                              color="error"
                              style={{ display: "inline" }}
                            >
                              <InfoIcon
                                fontSize="inherit"
                                style={{ marginLeft: 3, marginRight: 3 }}
                              />
                              {localize(
                                "cashregisters",
                                "Hibás vagy nincs szerződése."
                              )}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                                width: 160
                              }}
                            >
                              <Field
                                fullWidth
                                name="suspended_by_service_order"
                                onChange={e => {
                                  this.setState({
                                    modifiedSuspended: e.target.value
                                  });
                                  handleInputChange(
                                    "suspended_by_service_order"
                                  );
                                }}
                                component={SelectField}
                                placeholder={localize(
                                  "cashregisters",
                                  "Pénztárgép típus"
                                )}
                                options={this.state.cashregistersSuspendedConf}
                              />
                            </div>
                            <div style={{ width: 68 }}>
                              <IconButton
                                style={{
                                  padding: 0,
                                  marginLeft: 16
                                }}
                                onClick={e => {
                                  this.saveSuspended();
                                }}
                                color="primary"
                              >
                                <Save fontSize="small" />
                              </IconButton>

                              <IconButton
                                style={{
                                  padding: 0,
                                  marginLeft: 8
                                }}
                                onClick={e => {
                                  this.setState({ isSuspendedEditMode: false });
                                }}
                                color="primary"
                              >
                                <Close fontSize="small" />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Data title="Géptípus" value="dph" />
                  <Data title="AP szám" value="apn" />
                  <Data title="Üzembe hely. ideje" value="dts" />
                  <Data
                    title="Üzembehelyezési kód"
                    value="kobak_operation_code"
                  />
                  <Data
                    title="Átszemélyesítés ideje"
                    value="reregister_last_datetime"
                  />
                  <Data
                    title="Megjegyzés"
                    value="comment"
                    colorValue="color"
                    style={{ borderBottom: "none" }}
                  />
                </Paper>
              </Grid>
              <Grid container item xs={12} sm={12} md={6} lg={4}>
                <Paper
                  style={{ padding: 16, width: "100%", position: "relative" }}
                >
                  <CashregisterIcon style={iconStyle} />
                  {/* KOBAK START */}
                  <Grid container spacing={8} style={{ color: "#aaa" }}>
                    <Grid item={true} xs={4}>
                      <i>{localize("cashregisters", "KOBAK")}</i>
                    </Grid>
                    <Grid item={true} xs={8}>
                      {localize("cashregisters", "Utolsó frissítés")}:{" "}
                      {initialValues.kobak_last_update_time}
                    </Grid>
                  </Grid>
                  <Data title="AEE állapot" value="kobak_aee_state" />
                  <Data
                    title="Legújabb FW verzió"
                    value="kobak_latest_fm_version"
                  />
                  <Data title="AEE FW verzió" value="kobak_aee_fm_version" />
                  <Data
                    title="Pénztárgép állapot"
                    value="kobak_nav_cashregister_state"
                  />
                  <Data title="Utolsó komm." value="kobak_last_communication" />
                  <Data title="Utolsó naplóáll." value="kobak_last_logfile" />
                  {/* KOBAK END */}
                  {/* TELEKOM START */}
                  <Grid
                    container
                    spacing={8}
                    style={{ paddingTop: 8, color: "#aaa" }}
                  >
                    <Grid item={true} xs={4}>
                      <i>{localize("cashregisters", "Mobil szolgáltatás")}</i>
                    </Grid>
                    <Grid item={true} xs={8}>
                      {localize("cashregisters", "Utolsó frissítés")}:{" "}
                      {initialValues.mobile_service_provider_last_update_time}
                    </Grid>
                  </Grid>
                  <Data
                    title="Mobilszolgáltató"
                    value="kobak_mobile_service_provider"
                  />
                  <Data title="ICCID" value="iccid" />
                  <Data title="IMSI" value="dms" />
                  {initialValues.telekom && (
                    <React.Fragment>
                      <Data
                        title="Státusz"
                        value="mobile_service_provider_status"
                      />
                      <Data title="Állapot">
                        <Tooltip
                          title={telekomStatusCodeTooltipText(
                            initialValues.mobile_service_provider_status_code
                          )}
                        >
                          <div>
                            {initialValues.mobile_service_provider_status_code}
                          </div>
                        </Tooltip>
                      </Data>
                      <Data
                        title="Megjegyzés"
                        value="mobile_service_provider_comment"
                      />
                      <Data
                        title="Hiba ok"
                        value="mobile_service_provider_wrong_cause"
                        style={{ borderBottom: "none" }}
                      />
                    </React.Fragment>
                  )}
                  <Data title="Utolsó felülvizsgálat ideje" value="???" />
                </Paper>
              </Grid>
              <Grid container item xs={12} sm={12} md={12} lg={4}>
                <Paper
                  style={{
                    padding: 16,
                    width: "100%",
                    position: "relative",
                    minHeight: 200
                  }}
                >
                  <HistoryIcon style={iconStyle} />
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      overflowY: "auto"
                    }}
                  >
                    <CashregisterHistory
                      {...this.props}
                      openWorksheetModal={this.openWorksheetModal}
                      events={
                        Array.isArray(this.props.history_data)
                          ? this.props.history_data
                          : []
                      }
                      simple={true}
                    />
                  </div>
                </Paper>
              </Grid>
            </Fragment>
          )}
        </EditForm>
      </Fragment>
    );
  }
}

const ReduxForm = reduxForm({
  form: "cashregister",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(CashregisterForm);

export default connect(
  state => ({
    initialValues: state.cashregisters.viewData
  }),
  null
)(ReduxForm);
