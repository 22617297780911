import React from "react";
import WorkfeeForm from "./WorkfeeForm";
import EditPage from "../../../../../Components/Extends/EditPage";
import Edit from "../../../../../Components/Edit/Edit";
import localize from "helpers/localize";
class EditWorkfee extends EditPage {
  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("workfees", "Munkadíj megtekintése");
    }
    return this.props.match.params.id
      ? localize("workfees", "Munkadíj szerkesztése")
      : localize("workfees", "Munkadíj létrehozása");
  };

  render() {
    return (
      <Edit
        getPagetitle={this.getPageTitle}
        isViewPage={this.isViewPage()}
        {...this.props}
        key={"EditWorkfee" + (this.isViewPage() ? "isViewPage" : "")}
        optionsKey="workfees"
      >
        <WorkfeeForm {...this.props} isViewPage={this.isViewPage()} />
      </Edit>
    );
  }
}

export default EditWorkfee;
