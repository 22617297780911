import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditNotification from "./views/EditNotification";
import * as notificationsActions from "../../../redux/modules/notifications";

const mapStateToProps = state => {
  return {
    loading: state.articles.loading,
    success: state.articles.success,
    saved: state.articles.saved,
    message: state.articles.message,
    fieldErrors: state.articles.formErrors,
    id: state.articles.id,
    viewData: state.articles.viewData,
    reducerName: "notifications",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: notificationsActions.createNotifications,
      update: notificationsActions.updateNotifications,
      view: notificationsActions.viewNotifications,
      reset: notificationsActions.reset,
      resetForm: notificationsActions.resetForm
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditNotification);
