import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import ListIcon from "@material-ui/icons/ViewList";
import SortIcon from "@material-ui/icons/Sort";
import SortAscIcon from "@material-ui/icons/ArrowUpward";
import SortDescIcon from "@material-ui/icons/ArrowDownward";
import CardIcon from "@material-ui/icons/ViewModule";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Hidden } from "@material-ui/core";
import { VIEW_MODE_CARD, VIEW_MODE_LIST } from "./Catalog";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import localize from "helpers/localize";

const styles = theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
    //width: 400,
  },
  input: {
    marginLeft: theme.spacing.unit,
    flex: 1,
    "&::placeholder": {
      fontSize: "16px!important"
    }
  },
  htmlInput: {
    "&::placeholder": {
      fontSize: "16px!important"
    }
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4,
    width: 1,
    color: theme.palette.grey[500]
  },
  menuItemSelected: {
    backgroundColor: `${theme.palette.primary.main}!important`,
    color: "white",
    "& svg": {
      color: "white"
    }
  }
});

class Search extends React.Component {
  state = {
    anchorEl: null
  };

  handleInputKeypress = evt => {
    const { onEnter } = this.props;
    let keyCode = evt.which || evt.keyCode;
    if (keyCode === 13) {
      //Enter
      onEnter();
    }
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onSortModeClick = mode => () => {
    const { onSortModeClick } = this.props;
    this.handleClose();
    onSortModeClick(mode);
  };

  render() {
    const {
      classes,
      onMenuclick,
      onChange,
      onSearchClick,
      onViewModeClick,
      onSortModeClick,
      viewMode,
      sortMode,
      value
    } = this.props;
    const { anchorEl } = this.state;
    let SortingIcon = SortIcon;
    if (sortMode === "price") {
      SortingIcon = SortAscIcon;
    }
    if (sortMode === "-price") {
      SortingIcon = SortDescIcon;
    }
    return (
      <Paper className={classes.root}>
        <Hidden mdUp>
          <IconButton
            className={classes.iconButton}
            aria-label="menu"
            onClick={onMenuclick}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <InputBase
          autoFocus
          className={classes.input}
          placeholder="Keresés"
          inputProps={{
            "aria-label": localize("shop", "Keresés"),
            className: classes.htmlInput
          }}
          onChange={onChange}
          onKeyPress={this.handleInputKeypress}
          value={value}
        />
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          onClick={onSearchClick}
        >
          <SearchIcon />
        </IconButton>
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          color="primary"
          className={classes.iconButton}
          aria-label="view-mode"
          onClick={onViewModeClick}
        >
          {viewMode === VIEW_MODE_CARD ? <ListIcon /> : <CardIcon />}
        </IconButton>
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          color="primary"
          className={classes.iconButton}
          aria-label="sort-mode"
          onClick={this.handleClick}
        >
          <SortingIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem
            selected={!sortMode}
            onClick={this.onSortModeClick(null)}
            className={!sortMode ? classes.menuItemSelected : undefined}
          >
            <ListItemIcon>
              <SortIcon fontSize="small" />
            </ListItemIcon>
            {localize("shop", "Alapértelmezett")}
          </MenuItem>
          <MenuItem
            selected={sortMode === "price"}
            onClick={this.onSortModeClick("price")}
            className={
              sortMode === "price" ? classes.menuItemSelected : undefined
            }
          >
            <ListItemIcon>
              <SortAscIcon fontSize="small" />
            </ListItemIcon>
            {localize("shop", "Ár szerint növekvő")}
          </MenuItem>
          <MenuItem
            selected={sortMode === "-price"}
            onClick={this.onSortModeClick("-price")}
            className={
              sortMode === "-price" ? classes.menuItemSelected : undefined
            }
          >
            <ListItemIcon>
              <SortDescIcon fontSize="small" />
            </ListItemIcon>
            {localize("shop", "Ár szerint csökkenő")}
          </MenuItem>
        </Menu>
      </Paper>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  onMenuclick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  onViewModeClick: PropTypes.func.isRequired,
  onSortModeClick: PropTypes.func.isRequired,
  value: PropTypes.string,
  viewMode: PropTypes.string,
  sortMode: PropTypes.string
};

export default withStyles(styles)(Search);
