import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";


import List from "../../../../../Components/List/List";
import ContainerHeader from "../../../../../Components/ContainerHeader";
import Navbar from "../../Navbar";
import EditAeeReg from "../EditAeeReg";
import localize from "helpers/localize";
class AeeRegsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      keyword: null,
      search: null,
      filters: null,
      perPage: null
    };
  }
  getListHeader = () => {
    return this.props.getListHeader("/events", "&section=aeereg");
  };

  getList = (page, keyword, sort, filters, perPage) => {
    this.setState({
      page,
      keyword,
      sort,
      filters,
      perPage
    });
    return this.props.getList(page, keyword, sort, filters, perPage, "aeereg");
  };

  forceRefreshList = () => {
    const { page, keyword, sort, filters, perPage } = this.state;
    this.getList(page, keyword, sort, filters, perPage);
  };

  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("cashregisters", "AEE regisztráció")}</title>
        </Helmet>
        <Navbar {...this.props} />
        <ContainerHeader
          title={localize("cashregisters", "AEE regisztráció")}
        />
        <EditAeeReg
          {...this.props}
          forceRefreshList={this.forceRefreshList.bind(this)}
        />

        <List
          {...this.props}
          disabledTools={true}
          disabledCreate={true}
          getListHeader={this.getListHeader.bind(this)}
          getList={this.getList.bind(this)}
        />
      </div>
    );
  }
}

export default AeeRegsList;
