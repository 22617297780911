import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditAnnualReview from "./views/EditAnnualReview";
import * as annualReviewsActions from "../../../../redux/modules/annual-reviews";

const mapStateToProps = state => {
  return {
    loading: state.annualReviews.loading,
    success: state.annualReviews.success,
    saved: state.annualReviews.saved,
    message: state.annualReviews.message,
    fieldErrors: state.annualReviews.formErrors,
    id: state.annualReviews.id,
    viewData: state.annualReviews.viewData,
    reducerName: "annualReviews",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields,
    file_loading: state.file.loading,
    file_message: state.file.message,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: annualReviewsActions.createAnnualReviews,
      update: annualReviewsActions.updateAnnualReviews,
      view: annualReviewsActions.viewAnnualReviews,
      reset: annualReviewsActions.reset,
      resetForm: annualReviewsActions.resetForm,
      downloadPDF: annualReviewsActions.downloadPDF
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)(EditAnnualReview);
