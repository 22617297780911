// TODO: config devbar config a

import {
    createStore,
    applyMiddleware
} from 'redux';
//import {createLogger} from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import reducers from './reducers';
import {composeWithDevTools} from 'redux-devtools-extension';
// import reduxForm from './middlewares/reduxForm';

/*const loggerMiddleware = createLogger({
    level: 'info',
    collapsed: true
});*/

//https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
const enhancer = composeWithDevTools(
    applyMiddleware(
        thunkMiddleware,
        //loggerMiddleware,
        //reduxForm
    )
);

const store = createStore(reducers, enhancer);

export default store;