import React, { PureComponent } from "react";

import IconButton from "@material-ui/core/IconButton/IconButton";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableRow from "@material-ui/core/TableRow/TableRow";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import TrashIcon from "@material-ui/icons/Delete";
import { toastr } from "helpers/CustomToastr";
import localize from "helpers/localize";
import { apMask } from "helpers/maskHelper";
import { get } from "lodash";
import { Field } from "redux-form";
import InputField from "../../../../Components/Forms/Fields/Input";
import Validations from "../../../../Components/Forms/Validations";

class Item extends PureComponent {
  handleGetRule = data => {
    const { dispatch, change } = this.props;
    this.props.getRule(data).then(response => {
      let success = get(response, "data.success", false);
      let error = get(response, "data.message", "Ismeretlen hiba.");
      error = error === "" ? "Ismeretlen hiba." : error;
      if (!success) {
        toastr.error(error);
        return;
      }

      if (get(response, "data.data.apn", false)) {
        dispatch(
          change(
            `${this.props.cashregister}.apn`,
            get(response, "data.data.apn", "")
          )
        );
      }
      if (get(response, "data.data.iccid", false)) {
        dispatch(
          change(
            `${this.props.cashregister}.iccid`,
            get(response, "data.data.iccid", "")
          )
        );
      }
      if (get(response, "data.data.pgsn", false)) {
        dispatch(
          change(
            `${this.props.cashregister}.pgsn`,
            get(response, "data.data.pgsn", "")
          )
        );
      }
      if (get(response, "data.data.imsi", false)) {
        dispatch(
          change(
            `${this.props.cashregister}.imsi`,
            get(response, "data.data.imsi", "")
          )
        );
      }
    });
  };

  handleKeyPress = evt => {
    if (evt.key === "Enter") {
      evt.preventDefault();
      if (evt.target.value && evt.target.value !== "") {
        this.handleGetRule({ apn: evt.target.value });
      }
    }
  };

  render() {
    const { isViewPage, fieldError, index } = this.props;

    return (
      <TableRow>
        <TableCell>
          <Field
            name={`${this.props.cashregister}.apn`}
            //label={localize("serviceOrders", "Penztárgép AP-száma")}
            isRequired={true}
            validate={[Validations.required]}
            fieldError={fieldError.apn}
            component={InputField}
            inputRootClass={"simpleImputRoot"}
            //placeholder={localize("serviceOrders", "Penztárgép AP-száma")}
            isViewPage={isViewPage}
            onBlur={(evt, value) =>
              value ? this.handleGetRule({ apn: value }) : false
            }
            onKeyPress={this.handleKeyPress}
            {...apMask}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${this.props.cashregister}.iccid`}
            //label={localize("serviceOrders", "Eszköz hálózati azonosító (ICCID)")}
            isRequired={true}
            validate={[Validations.required]}
            fieldError={fieldError.iccid}
            component={InputField}
            groupStyle={{ marginLeft: 0, marginRight: 0 }}
            //fieldClass={"fos1"}
            //fieldClass="col-md-6"
            //placeholder={localize("serviceOrders", "Eszköz hálózati azonosító (ICCID)")}
            onBlur={(evt, value) =>
              value ? this.handleGetRule({ iccid: value }) : false
            }
            isViewPage={true /*isViewPage*/}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${this.props.cashregister}.imsi`}
            //label={localize("serviceOrders", "Eszköz SIM azonosító (IMSI)")}
            isRequired={true}
            validate={[Validations.required]}
            fieldError={fieldError.imsi}
            component={InputField}
            groupStyle={{ marginLeft: 0, marginRight: 0 }}
            //fieldClass="col-md-6"
            //placeholder={localize("serviceOrders", "Eszköz SIM azonosító (IMSI)")}
            onBlur={(evt, value) =>
              value ? this.handleGetRule({ imsi: value }) : false
            }
            isViewPage={true /*isViewPage*/}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${this.props.cashregister}.pgsn`}
            //label={localize("serviceOrders", "Penztárgép sorozatszáma (PGSN)")}
            isRequired={true}
            validate={[Validations.required]}
            fieldError={fieldError.pgsn}
            component={InputField}
            groupStyle={{ marginLeft: 0, marginRight: 0 }}
            //fieldClass="col-md-6"
            //placeholder={localize("serviceOrders", "Penztárgép sorozatszáma (PGSN)")}
            onBlur={(evt, value) =>
              value ? this.handleGetRule({ pgsn: value }) : false
            }
            isViewPage={true /*isViewPage*/}
          />
        </TableCell>
        <TableCell>
          {this.props.itemsLenght !== 1 && !isViewPage && (
            <Tooltip title={localize("serviceOrders", "Pénztárgép törlése")}>
              <IconButton
                style={{ width: 24, height: 24, padding: 0 }}
                onClick={this.props.handleDelete.bind(this, index)}
                color="secondary"
              >
                <TrashIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

export default Item;
