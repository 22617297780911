import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Wrapper from "./Wrapper";
import localize from "helpers/localize";

class CheckboxInput extends Component {
  checkViewValue = () => {
    const { input } = this.props;
    if (this.props.manualValue) {
      return get(input, "value", false) === this.props.manualValue
        ? localize("basic", "Igen")
        : localize("basic", "Nem");
    } else {
      return get(input, "value", false) === 1
        ? localize("basic", "Igen")
        : localize("basic", "Nem");
    }
  };

  render() {
    const { input, label, readOnly, checkBoxStyle, labelStyle } = this.props;
    const { onChange, onBlur, ...rest } = input;
    return (
      <Wrapper {...this.props} value={this.checkViewValue()}>
        <FormControlLabel
          style={{ marginLeft: 0, ...labelStyle }}
          control={
            <Checkbox
              disabled={readOnly}
              style={{
                width: "auto",
                height: "auto",
                marginRight: -5,
                ...checkBoxStyle
              }}
              color="primary"
              {...rest}
              onChange={(event, checked) => {
                onChange(checked ? 1 : 0);
              }}
              onBlur={event => {
                onBlur(event.target.checked ? 1 : 0);
              }}
              value={
                this.props.manualValue ? String(this.props.manualValue) : "1"
              }
              checked={get(input, "value", "false") ? "checked" : ""}
              inputProps={{
                readOnly: readOnly
              }}
            />
          }
          label={label}
        />
      </Wrapper>
    );
  }
}
CheckboxInput.defaultProps = {
  checkBoxStyle: {},
  labelStyle: {}
};
CheckboxInput.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  fieldError: PropTypes.string,
  manualValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  checkBoxStyle: PropTypes.object,
  labelStyle: PropTypes.object
};

export default CheckboxInput;
