import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import Wrapper from "./Wrapper";
import radioCheckboxStyle from "styles/jss/radioCheckboxStyle";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import FormHelperText from "@material-ui/core/FormHelperText";
import localize from "helpers/localize";
class RadioCheckbox extends Component {
  state = {
    value: null
  };

  checkViewValue = () => {
    const {
      input,
      yesValue,
      yesText,
      noText,
      readOnlyValue,
      isViewPage
    } = this.props;

    //isViewPage value esetén külön érték
    if (readOnlyValue && isViewPage) {
      return readOnlyValue;
    }

    if (yesValue) {
      return String(get(input, "value", false)) === yesValue
        ? localize("basic", yesText)
        : localize("basic", noText);
    } else {
      return get(input, "value", false) === 1
        ? localize("basic", yesText)
        : localize("basic", noText);
    }
  };

  /**
   * Kitöltött állapotra klikkelve mégegyszer nul értéket ad a mezőnek
   */
  onClick = evt => {
    const { value } = this.state;
    const { onChange } = this.props.input;

    if (typeof evt.target.value === "object") {
      evt.target.value = evt.target.value[0];
    }

    //console.log("evt", evt.target.value);

    if (value === evt.target.value) {
      this.setState({ value: null });
      onChange(null);
    } else {
      onChange(evt.target.value);
      this.setState({
        value: evt.target.value,
        isCheckedYes: evt.target.value === this.props.yesValue
      });
    }
  };

  render() {
    const {
      classes,
      input,
      label,
      disabled,
      yesValue,
      noValue,
      yesText,
      noText,
      color,
      row,
      labelPlacement,
      radioLabelPlacement,
      fieldError,
      boldValue
    } = this.props;

    const { warning, touched, error } = this.props.meta;
    const hasError =
      fieldError || (touched && (warning || error)) ? true : false;
    const errorMsg = fieldError || (touched && (warning || error));
    const labelClassName = classNames(classes.label, {
      [classes.labelRow]: row
    });
    const rgClassName = classNames({
      [classes.radioGrouplabelStart]: labelPlacement === "start" && !row
    });
    const rgLabelClassName = classNames({
      [classes.radioGrouplabelStartLabel]: labelPlacement === "start"
    });

    const inputValue =
      [String(yesValue), String(noValue)].indexOf(String(input.value)) >= 0
        ? String(input.value)
        : "-1";

    return (
      <Wrapper
        {...this.props}
        value={this.checkViewValue()}
        boldValue={boldValue}
        groupClass={classes.wrapper}
      >
        <div className={classes.root}>
          {label && (
            <Typography
              variant="body2"
              className={labelClassName}
              //labelPlacement={labelPlacement}
              component="div"
            >
              {label}
            </Typography>
          )}
          <RadioGroup
            disabled={disabled}
            //{...input}
            name={input.name}
            className={rgClassName}
            value={inputValue}
            row={row}
          >
            <FormControlLabel
              value={yesValue}
              control={
                <Radio
                  color={color}
                  className={classes.radio}
                  onClick={this.onClick}
                />
              }
              label={yesText}
              labelPlacement={radioLabelPlacement}
              className={rgLabelClassName}
            />
            <FormControlLabel
              value={noValue}
              control={
                <Radio
                  color={color}
                  className={classes.radio}
                  onClick={this.onClick}
                />
              }
              label={noText}
              labelPlacement={radioLabelPlacement}
              className={rgLabelClassName}
            />
          </RadioGroup>
        </div>
        {hasError && (
          <FormHelperText error={hasError}>{errorMsg}</FormHelperText>
        )}
      </Wrapper>
    );
  }
}

RadioCheckbox.defaultProps = {
  disabled: false,
  yesText: "Igen",
  noText: "Nem",
  yesValue: "1",
  noValue: "0",
  color: "primary",
  labelPlacement: "start",
  radioLabelPlacement: "end",
  boldValue: true,
  row: false
};

RadioCheckbox.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  input: PropTypes.any.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fieldError: PropTypes.string,
  yesValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  yesText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  noText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  color: PropTypes.oneOf(["default", "primary", "secondary"]),
  labelPlacement: PropTypes.oneOf(["start", "end"]),
  radioLabelPlacement: PropTypes.oneOf(["start", "end"]),
  row: PropTypes.bool,
  boldValue: PropTypes.bool,
  disabled: PropTypes.bool
};
export default withStyles(radioCheckboxStyle, { withTheme: true })(
  RadioCheckbox
);
