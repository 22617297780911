import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Distrtibutor from "./views/Distrtibutor";
import * as distributorActions from "../../../../redux/modules/distributor";
import * as authActions from "../../../../redux/modules/auth";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    distributor: state.auth.distributor,

    loading: state.distributor.loading,
    success: state.distributor.success,
    saved: state.distributor.saved,
    message: state.distributor.message,
    fieldErrors: state.distributor.formErrors,
    profileLoading: state.distributor.profileLoading,
    token: state.distributor.token
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      changeDistributor: distributorActions.changeDistributor,
      getDistributor: authActions.distributor,
      getFormInfo: optionsActions.getFormInfo
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Distrtibutor);
