/* eslint-disable */
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import { withStyles } from "@material-ui/core/styles";
import localize from "helpers/localize";
import { apMask } from "helpers/maskHelper";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Field } from "redux-form";
import EditForm from "../../../../../Components/Forms/Builder/EditForm";
import DateField from "../../../../../Components/Forms/Fields/Date";
import InputField from "../../../../../Components/Forms/Fields/Input";
import RadioField from "../../../../../Components/Forms/Fields/Radio2Col";
import CheckboxField from "../../../../../Components/Forms/Fields/RadioCheckbox";
import SelectField from "../../../../../Components/Forms/Fields/Select";
import Validations from "../../../../../Components/Forms/Validations";
import { toastr } from "../../../../../helpers/CustomToastr";
import annualReviewFormStyle from "./annualReviewFormStyle.js";

class AnnualReviewForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.file_message && nextProps.file_message) {
      toastr.error(nextProps.file_message);
    }
  }

  constructor(props) {
    super(props);
    this._form = React.createRef();
    this.state = {
      showComment: false,
      show_1_5_1: true,
      show_1_6_1: true
    };
  }

  submit = () => {
    const instance = this._form.current.getWrappedInstance();
    if (instance) {
      instance.submit();
    }
  };

  handleCommentChange = value => {
    console.log("handleCommentChange", value);
    this.setState({ showComment: value[0] && value[0] === "1" });
  };

  handle1_5Change = value => {
    console.log("handle1_5Change", value);
    this.setState({ show_1_5_1: value[0] && value[0] === "1" });
  };
  handle1_6Change = value => {
    console.log("handle1_6Change", value);
    this.setState({ show_1_6_1: value[0] && value[0] === "1" });
  };

  render() {
    const { fieldErrors, isViewPage, enabledFields, classes } = this.props;

    //A nézeten a form_values-ban lévő értékek számítanak
    const show_1_5_1 = isViewPage
      ? this.props.form_values["1_5"] === 1
      : this.state.show_1_5_1;
    const show_1_6_1 = isViewPage
      ? this.props.form_values["1_6"] === 1
      : this.state.show_1_6_1;
    const showComment = isViewPage
      ? this.props.form_values["1_17"] === 1
      : this.state.showComment;

    let sofwares = [];
    Object.keys(get(this.props, "baseFields.software_types.data", {})).forEach(
      key => {
        sofwares.push({
          id: key,
          label: this.props.baseFields.software_types.data[key]
        });
      }
    );

    return (
      <EditForm {...this.props} ref={this._form}>
        <Grid
          container={true}
          className={classes.container + " " + "is-view-page" + isViewPage}
        >
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="cashregister_apn"
              label={localize("cashregisters", "AP szám")}
              //onChange={() => handleInputChange('cashregister_apn')}
              component={InputField}
              //className={classes.dark}
              type="text"
              placeholder={localize("cashregisters", "AP száma")}
              isViewPage={true}
              {...apMask}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="cashregister_stickern"
              label={localize("cashregisters", "Matrica sorszáma")}
              //onChange={() => handleInputChange('cashregister_stickern')}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "Matrica sorszáma")}
              isViewPage={true}
            />
          </Grid>
          {isViewPage && (
            <Grid item={true} xs={12}>
              <Field
                boldValue
                name="review_date"
                label={localize("cashregisters", "Felülvizsgálat dátuma")}
                component={DateField}
                placeholder={localize("cashregisters", "Felülvizsgálat dátuma")}
                isViewPage={true}
              />
            </Grid>
          )}
          {isViewPage && (
            <Grid item={true} xs={12}>
              <Field
                boldValue
                name="created_at"
                label={localize("cashregisters", "Rögzítés időpontja")}
                component={DateField}
                placeholder={localize("cashregisters", "Rögzítés időpontja")}
                isViewPage={true}
              />
            </Grid>
          )}
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="user_name"
              label={localize("cashregisters", "Műszerész neve")}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "Műszerész neve")}
              isViewPage={true}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="user_number"
              label={localize("cashregisters", "Műszerész igazolvány száma")}
              component={InputField}
              type="text"
              placeholder={localize(
                "cashregisters",
                "Műszerész igazolvány száma"
              )}
              isViewPage={true}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="owner_name"
              label={localize("cashregisters", "Tulajdonos")}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "Tulajdonos")}
              isViewPage={true}
            />
          </Grid>
          {isViewPage && (
            <Grid item={true} xs={12}>
              <Field
                boldValue
                name="operating_address"
                label={localize("cashregisters", "Éves felülvizsgálat helye")}
                component={InputField}
                type="text"
                placeholder={localize(
                  "cashregisters",
                  "Éves felülvizsgálat helye"
                )}
                isViewPage={true}
              />
            </Grid>
          )}

          {!isViewPage && (
            <Grid
              item={true}
              xs={12}
              className="annual-form-row eves-felulvizsgalat"
            >
              <Field
                boldValue
                name="operating_address_type"
                isRequired={true}
                validate={[Validations.required]}
                label={localize("cashregisters", "Éves felülvizsgálat helye")}
                //onChange={() => handleInputChange('operating_address_type')}
                component={RadioField}
                placeholder={localize(
                  "cashregisters",
                  "Éves felülvizsgálat helye"
                )}
                options={[
                  {
                    key: get(this.props, "initialValues.client_yard"),
                    label: localize("cashregisters", "Nyilvántartás szerint"),
                    col2: get(this.props, "initialValues.client_yard", "-")
                  },
                  {
                    key: get(this.props, "initialValues.distributor_address"),
                    label: localize("cashregisters", "Műszerész telephelye"),
                    col2: get(
                      this.props,
                      "initialValues.distributor_address",
                      "-"
                    )
                  },
                  {
                    key: "other",
                    label: localize("cashregisters", "Egyéb esetben"),
                    col2: (
                      <Field
                        boldValue
                        labelBefore={true}
                        name="operating_address"
                        //label={localize("cashregisters", "Egyéb esetben")}
                        //onChange={() => handleInputChange('form_values.operating_address')}
                        component={InputField}
                        type="text"
                        placeholder={localize("cashregisters", "Egyéb esetben")}
                      />
                    )
                  }
                ]}
              />
            </Grid>
          )}
          <Grid item={true} xs={12} className="annual-form-row">
            {localize("cashregisters", "A műszerész nyilatkozatai")}:
          </Grid>
          <Grid item={true} xs={12} className="annual-form-row">
            <Field
              boldValue
              name="form_values.1_1"
              label={localize(
                "cashregisters",
                "1.1. A pénztárgéphez tartozó pénztárgépnapló rendelkezésre áll és a funkciója betöltésére alkalmas állapotban van. A pénztárgépnapló egyedi sorszámmal van ellátva."
              )}
              //onChange={() => handleInputChange('form_values.1_1')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_1_cashregister_log_sequence_number"
              label={localize("cashregisters", "Pénztárgépnapló sorszáma")}
              fieldError={get(
                fieldErrors,
                "form_values.1_1_cashregister_log_sequence_number"
              )}
              //onChange={() => handleInputChange('form_values.1_1_cashregister_log_sequence_number')}
              isRequired={true}
              validate={[Validations.required]}
              component={InputField}
              type="text"
              placeholder={localize(
                "cashregisters",
                "Pénztárgépnapló sorszáma"
              )}
              isViewPage={isViewPage}
              readOnly={
                enabledFields
                  ? enabledFields.indexOf(
                      "form_values.1_1_cashregister_log_sequence_number"
                    ) !== -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_2"
              label={localize(
                "cashregisters",
                "1.2. A pénztárgépen lévő három jelzőfény az elvégzett Gyári teszt alapján megfelelően működött."
              )}
              //onChange={() => handleInputChange('form_values.1_2')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_3"
              label={localize(
                "cashregisters",
                "1.3. A pénztárgép burkolata sértetlen (nincs rajta olyan törés, hézag, nyílás, stb., amin keresztül a gép belseje elérhető, és ami nem dokumentált felbontásra vagy manipulációra utal, illetve érintésvédelmi kockázatot jelent)."
              )}
              //onChange={() => handleInputChange('form_values.1_3')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_4"
              label={localize(
                "cashregisters",
                "1.4. A pénztárgép burkolatát lezáró plomba sértetlen, a legutóbbi lezárást végző műszerész pecsétnyomójának azonosító száma azonos a pénztárgép naplójában feltüntetett legutóbbi bontást (ennek hiányában: üzembe helyezést) végző műszerész pecsétnyomójának azonosító számával."
              )}
              //onChange={() => handleInputChange('form_values.1_4')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_5"
              label={
                <div>
                  {localize(
                    "cashregisters",
                    "1.5. Az AEE-t szemrevételeztem és annak burkolata sértetlen (nincs rajta olyan törés, hézag, nyílás, stb., amin keresztül a belseje elérhető, és ami nem dokumentált felbontásra vagy manipulációs kísérletre utal), azonos a géptípusra jellemző gyári állapottal."
                  )}
                  {show_1_5_1 && (
                    <Field
                      boldValue
                      name="form_values.1_5_1"
                      //label={localize("cashregisters", "kiszereléssel")}
                      //onChange={() => handleInputChange('form_values.1_5_1')}

                      component={CheckboxField}
                      isViewPage={isViewPage}
                      row
                      yesText={localize("cashregisters", "Kiszereléssel /")}
                      noText={localize("cashregisters", "Kiszerelés nélkül")}
                      isRequired={true}
                      validate={[Validations.required]}
                    />
                  )}
                </div>
              }
              onChange={this.handle1_5Change}
              //onChange={() => handleInputChange('form_values.1_5')}
              component={CheckboxField}
              isViewPage={isViewPage}
              isRequired={true}
              validate={[Validations.required]}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Field
              boldValue
              className="annual-form-row"
              name="form_values.1_6"
              label={
                <div>
                  {localize(
                    "cashregisters",
                    "1.6. Ellenőriztem az AEE akkumulátorának állapotát."
                  )}
                  {show_1_6_1 && (
                    <Field
                      boldValue
                      name="form_values.1_6_1"
                      label={localize("cashregisters", "Az akkumulátort ")}
                      //onChange={() => handleInputChange('form_values.1_6_1')}
                      component={CheckboxField}
                      isViewPage={isViewPage}
                      row
                      yesText="cseréltem /"
                      noText="nem cseréltem"
                      isRequired={true}
                      validate={[Validations.required]}
                    />
                  )}
                </div>
              }
              onChange={this.handle1_6Change}
              //onChange={() => handleInputChange('form_values.1_6')}
              component={CheckboxField}
              isViewPage={isViewPage}
              isRequired={true}
              validate={[Validations.required]}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_7"
              label={localize(
                "cashregisters",
                "1.7. Visszazártam a pénztárgép burkolatát és az alábbi számú plombanyomóval leplombáltam"
              )}
              //onChange={() => handleInputChange('form_values.1_7')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_7_plomba"
              label={localize("cashregisters", "Plomba sorszáma")}
              fieldError={get(fieldErrors, "form_values.1_7_plomba")}
              //onChange={() => handleInputChange('form_values.1_7_plomba')}
              isRequired={true}
              validate={[Validations.required]}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "Plomba sorszáma")}
              isViewPage={isViewPage}
              readOnly={
                enabledFields
                  ? enabledFields.indexOf("form_values.1_7_plomba") !== -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_8"
              label={localize(
                "cashregisters",
                "1.8. A közlemény szerint az első nyugta (megszakított) kibocsátást elvégeztem, vagy elvégzéséről meggyőződtem. A pénztárgép által kiadott bizonylat a gyári állapotnak megfelel, a pénztárgép a helyes gyűjtőt tüntette fel."
              )}
              //onChange={() => handleInputChange('form_values.1_8')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_9"
              label={localize(
                "cashregisters",
                "1.9. A megszakított bizonylat sorszámozása, a megszakítás tényének feltüntetése megfelelő."
              )}
              //onChange={() => handleInputChange('form_values.1_9')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_10"
              label={localize(
                "cashregisters",
                "1.10. A közlemény szerint a második nyugta kibocsátást elvégeztem, vagy elvégzéséről meggyőződtem. A pénztárgép által kiadott bizonylat a gyári állapotnak megfelel, a pénztárgép a helyes gyűjtőket tüntette fel."
              )}
              //onChange={() => handleInputChange('form_values.1_10')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_11"
              label={localize(
                "cashregisters",
                "1.11. A nyugta sorszáma megfelelő a megszakított bizonylatot követő bizonylaton."
              )}
              //onChange={() => handleInputChange('form_values.1_11')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_12"
              label={localize(
                "cashregisters",
                "1.12. A pénztárgépen vagy ahhoz egyértelműen köthetően fel van tüntetve a pénztárgép AP száma, ami azonos a kibocsátott bizonylatokon szereplő AP számmal."
              )}
              //onChange={() => handleInputChange('form_values.1_12')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_13"
              label={localize(
                "cashregisters",
                "1.13. Megállapítottam, hogy a pénztárgép, illetve az AEE működtető szoftverének alábbi verziója van telepítve"
              )}
              //onChange={() => handleInputChange('form_values.1_13')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.szoftver_type"
              label={localize("cashregisters", "Pénztárgép és AEE szoftver")}
              fieldError={get(fieldErrors, "form_values.szoftver_type")}
              //onChange={() => handleInputChange('form_values.szoftver_type')}
              isRequired={true}
              validate={[Validations.required]}
              component={SelectField}
              placeholder={localize(
                "cashregisters",
                "Pénztárgép és AEE szoftver"
              )}
              isViewPage={isViewPage}
              options={sofwares}
              viewShowKey={true}
              readOnly={
                enabledFields
                  ? enabledFields.indexOf("form_values.szoftver_type") !== -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_14"
              label={localize(
                "cashregisters",
                "1.14. A naplóállományok egy részének kiolvasásával meggyőződtem róla, hogy az adott pénztárgépen megvalósítható a naplóállományok kiolvasása."
              )}
              //onChange={() => handleInputChange('form_values.1_14')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_14_naploallomany"
              label={localize("cashregisters", "Naplóállomány neve")}
              fieldError={get(fieldErrors, "form_values.1_14_naploallomany")}
              //onChange={() => handleInputChange('form_values.1_14_naploallomany')}
              isRequired={true}
              validate={[Validations.required]}
              component={InputField}
              type="text"
              placeholder={localize("cashregisters", "Naplóállomány neve")}
              isViewPage={isViewPage}
              readOnly={
                enabledFields
                  ? enabledFields.indexOf("form_values.1_14_naploallomany") !==
                    -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_15"
              label={localize(
                "cashregisters",
                "1.15. A pénztárgépet a karbantartás követően az üzemeltetőnek az előírásoknak megfelelő, működőképes állapotban átadtam."
              )}
              //onChange={() => handleInputChange('form_values.1_15')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_16"
              label={localize(
                "cashregisters",
                "1.16. A pénztárgép naplóban az éves felülvizsgálat elvégzését dokumentáltam."
              )}
              //onChange={() => handleInputChange('form_values.1_16')}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              boldValue
              name="form_values.1_17"
              label={localize(
                "cashregisters",
                "1.17. Jegyzőkönyvezendő hiányosságot, rendellenességet tapasztaltam."
              )}
              onChange={this.handleCommentChange}
              isRequired={true}
              validate={[Validations.required]}
              component={CheckboxField}
              isViewPage={isViewPage}
            />
          </Grid>
          {showComment && (
            <Grid item={true} xs={12}>
              <Field
                boldValue
                name="form_values.1_18_other"
                label={localize("cashregisters", "Megjegyzés")}
                fieldError={get(fieldErrors, "form_values.1_18_other")}
                //onChange={() => handleInputChange('form_values.1_14_naploallomany')}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                multiline={true}
                rows={3}
                placeholder={localize("cashregisters", "Megjegyzés")}
                isViewPage={isViewPage}
                //readOnly={enabledFields ? enabledFields.indexOf('form_values.1_18_other') !== -1 ? false : true : false}
              />
            </Grid>
          )}
          {isViewPage && (
            <Grid item={true} xs={12}>
              {!this.props.file_loading && (
                <Button
                  color="primary"
                  onClick={() => {
                    this.props.downloadPDF(this.props.initialValues.id);
                  }}
                >
                  {localize("cashregisters", "Eredmény letöltése")}
                </Button>
              )}

              {this.props.initialValues &&
                this.props.initialValues.certificate_url && (
                  <Button
                    //className="jr-btn jr-btn-xs"
                    target="_blank"
                    color="primary"
                    href={
                      process.env.REACT_APP_HOST +
                      this.props.initialValues.certificate_url +
                      "?accessToken=" +
                      localStorage
                        .getItem("access_token")
                        .replace("Bearer ", "")
                    }
                    component="a"
                  >
                    Tanúsítvány letöltése
                  </Button>
                )}

              {this.props.file_loading && (
                <div className="text-center">
                  <CircularProgress />
                </div>
              )}
            </Grid>
          )}
        </Grid>
      </EditForm>
    );
  }
}
export default withStyles(annualReviewFormStyle)(AnnualReviewForm);
/*
const UsersReduxForm = reduxForm({
  form: 'annualReviews',
  enableReinitialize: false,
  keepDirtyOnReinitialize: false,
  updateUnregisteredFields: false
})(withStyles(annualReviewFormStyle)(AnnualReviewForm));

const form_values = {
  "1_1": "1",
  "1_2": "1",
  "1_3": "1",
  "1_4": "1",
  "1_5": "1",
  //"1_5_1": "1",
  "1_6": "1",
  //"1_6_1": "1",
  "1_7": "1",
  "1_8": "1",
  "1_9": "1",
  "1_10": "1",
  "1_11": "1",
  "1_12": "1",
  "1_13": "1",
  "1_14": "1",
  "1_15": "1",
  "1_16": "1"
}

export default connect(
  (state) => {
    if (state.annualReviews.viewData) {
      state.annualReviews.viewData.form_values = Object.assign({}, form_values, state.annualReviews.viewData.form_values)
    }
    return {
      initialValues: state.annualReviews.viewData,

    }
  },
  null,
  null,
  { withRef: true }
)(UsersReduxForm);
*/
