import React from "react";
import PropTypes from "prop-types";
import StaticTable from "Components/New/DataTable/StaticTable/StaticTable";
import JsonSyntaxHighlight from "helpers/JsonSyntaxHighlight";
import localize from "helpers/localize";
const LC = "cloud"; //Localization category

const MetaTable = props => {
  const { row } = props;
  const { type, meta } = row;
  //console.log(type, meta);

  switch (type) {
    case "NYN":
    case "ESN":
    case "VBN":
    case "SZN":
      return (
        <StaticTable
          columns={[
            { id: "NA", label: localize(LC, "Megnevezés") },
            { id: "IU", label: localize(LC, "Mennyiségi egység") },
            { id: "PN", label: localize(LC, "Cikkszám") },
            { id: "QY", label: localize(LC, "Mennyiség") },
            { id: "RM", label: localize(LC, "Megjegyzés") },
            { id: "SU", label: localize(LC, "Összeg") },
            { id: "TY", label: localize(LC, "Tétel jellege") },
            { id: "UN", label: localize(LC, "Ft egységár") },
            { id: "VC", label: localize(LC, "Gyűjtő") }
          ]}
          rows={meta.ITL}
        />
      );
    default:
      return <JsonSyntaxHighlight json={meta} />;
  }
};

MetaTable.propTypes = {
  row: PropTypes.object
};

export default MetaTable;
