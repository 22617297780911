import React, { Component, Fragment } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";
import InputField from "Components/Forms/Fields/Input";
import SelectField from "Components/Forms/Fields/Select";
import Validations from "Components/Forms/Validations";
import EditForm from "Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import errorHelper from "helpers/errorHelper";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { createTextMask } from "redux-form-input-masks";
import { mobilNumberMask } from "helpers/maskHelper";
import localize from "helpers/localize";

const TextField = props => (
  <Grid item={true} xs={12}>
    <Field
      name={props.name}
      label={localize("user", props.label)}
      fieldError={props.errors[props.name]}
      //onChange={() => handleInputChange("email")}
      isRequired={true}
      validate={props.validate}
      component={InputField}
      type={props.type || "text"}
      {...props.mask}
      labelInfo={props.labelInfo ? localize("user", props.labelInfo) : null}
    />
  </Grid>
);

const RegisterForm = props => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    //componentProps
    error,
    isDistributorUser,
    hasDistributor,
    role
  } = props;
  const errors = errorHelper.parseFieldErrors(props.errors);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={16}>
        <TextField
          name="user_username"
          label="Felhasználónév"
          validate={Validations.required}
          errors={errors}
        />
        <TextField
          name="user_password"
          label="Jelszó"
          validate={Validations.required}
          type="password"
          errors={errors}
        />
        <TextField
          name="user_password_confirm"
          label="Jelszó ismétlése"
          validate={Validations.passwordConfirmation("user_password")}
          type="password"
          errors={errors}
        />
        <TextField
          name="user_real_name"
          label="Valódi név"
          validate={Validations.required}
          errors={errors}
        />
        <TextField
          name="user_phone"
          label="Telefon"
          validate={
            role === "user"
              ? [Validations.mobile_number]
              : [Validations.required, Validations.mobile_number]
          }
          mask={mobilNumberMask}
          labelInfo="Az alábbi formátum kötelező: 06-30-1234567"
          errors={errors}
        />
        {isDistributorUser && (
          <Fragment>
            <TextField
              name="user_card_number"
              label="Igazolvány szám"
              validate={Validations.required}
              errors={errors}
            />
            <TextField
              name="user_sealnumber_of_cashregister"
              label="Plombanyomó szám"
              validate={Validations.required}
              errors={errors}
            />
          </Fragment>
        )}
        {!hasDistributor && isDistributorUser && (
          <Fragment>
            <TextField
              name="distributor_company_name"
              label="Szervíz neve"
              validate={Validations.required}
              errors={errors}
            />
            <TextField
              name="distributor_registration_number"
              label="Nyilvántartási száma"
              validate={Validations.required}
              errors={errors}
            />
            <TextField
              name="distributor_address"
              label="Székhelye"
              validate={Validations.required}
              errors={errors}
            />
            <TextField
              name="distributor_phone"
              label="Telefonszáma"
              validate={[Validations.required, Validations.mobile_number]}
              mask={mobilNumberMask}
              labelInfo="Az alábbi formátum kötelező: 06-30-1234567"
              errors={errors}
            />
            <TextField
              name="distributor_email"
              label="Email címe"
              validate={Validations.required}
              errors={errors}
            />
            <TextField
              name="distributor_contact_person"
              label="Kapcsolattartó"
              validate={Validations.required}
              errors={errors}
            />
          </Fragment>
        )}
      </Grid>
      <Grid container spacing={16}>
        <Grid container item xs={12} justify="space-between">
          <Link to="/">{localize("user", "Vissza a bejelentkezésre")}</Link>
          <Button
            type="submit"
            disabled={pristine || submitting}
            variant="contained"
            color="primary"
          >
            {localize("basic", "Küldés")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

RegisterForm.defaultProps = {
  errors: {}
};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  hasDistributor: PropTypes.bool,
  isDistributorUser: PropTypes.bool,
  errors: PropTypes.object,
  error: PropTypes.string
};

export default reduxForm({
  form: "register", // a unique identifier for this form
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(RegisterForm);
