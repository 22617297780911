import React from "react";


import UsersForm from "./ClientForm";
import EditPage from "../../../../Components/Extends/EditPage";
import Edit from "../../../../Components/Edit/Edit";
import localize from "helpers/localize";

class EditClient extends EditPage {
  constructor(props) {
    super(props);
    this._form = React.createRef();
  }

  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("clients", "Ügyfél megtekintése");
    }
    return this.props.match.params.id
      ? localize("clients", "Ügyfél szerkesztése")
      : localize("clients", "Ügyfél létrehozása");
  };

  submit = () => {
    const instance = this._form.current.getWrappedInstance();
    if (instance) {
      instance.submit();
    }
  };

  render() {
    return (
      <Edit
        getPagetitle={this.getPageTitle}
        isViewPage={this.isViewPage()}
        {...this.props}
      >
        <UsersForm
          {...this.props}
          isViewPage={this.isViewPage()}
          ref={this._form}
        />
      </Edit>
    );
  }
}

export default EditClient;
