/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import inputStyle from "./inputStyle.js";
import Wrapper from './Wrapper';
import Grid from "@material-ui/core/Grid/Grid";

class RadioInput2Col extends Component {
  static propTypes = {
    input: PropTypes.any.isRequired,
    label: PropTypes.string,
    fieldError: PropTypes.string
  };

  render() {
    const { input, label, options, fieldError, classes } = this.props;
    const {  warning, touched, error } = this.props.meta;
    return <Wrapper {...this.props}>
        <FormControl component="fieldset" error={fieldError || (touched && (warning || (error ? true: false)))} fullWidth>
            <FormLabel className={classes.radioLabel} component="legend">{label}</FormLabel>
            <RadioGroup
                className={classes.radioRoot}
                value={get(input, 'value', false)}
                {...input}
            >
                {options && options.map((data, index) => {

                return <FormControlLabel
                key={index}
                        className={classes.radioRow}
                        classes={{
                          label: classes.radio2colLabel
                        }}
                        value={data.key}
                        control={<Radio className={classes.radioButton} color="primary"/>}
                        label={
                          <Grid container={true}>
                            <Grid item={true} xs={5} className={classes.radio2colLabel1}><div className={classes.radio2colLabel1Content}>{data.label}</div></Grid>
                            <Grid item={true} xs={7} className={classes.radio2colLabel2}>{data.col2}</Grid>
                          </Grid>
                        }
                      />
         })}
            </RadioGroup>
            <FormHelperText>{fieldError || (touched && (warning || error ))}</FormHelperText>
        </FormControl>
    </Wrapper>
  }
}


export default withStyles(inputStyle)(RadioInput2Col);