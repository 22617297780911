import React, {Fragment} from 'react';

import EventForm from './EventForm';
import Edit from '../../../../../Components/Edit/Edit';

class EditEvent extends React.Component {

    handleGetFormInfo = () => {
        return this.props.getCustomInfo(`events`);
    };

  render() {
    return (
        <Fragment>
            <Edit
                handleGetFormInfo={this.handleGetFormInfo.bind(this)}
                {...this.props}
            >
                <EventForm
                    {...this.props}

                />
            </Edit>
        </Fragment>

    );
  }

}

export default EditEvent;