import React, { Fragment } from "react";
import PropTypes from "prop-types";
//
import {
  feedbackFields,
  feedback
} from "../../../../redux/modules/service-orders.js";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import InfoIcon from "@material-ui/icons/Info";
import classNames from "classnames";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputField from "../../../../Components/Forms/Fields/Input";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import localize from "helpers/localize";

const styles = theme => ({
  expandIcon: {
    width: 16,
    height: 16,
    cursor: "pointer",
    marginBottom: -4,
    marginLeft: 2,
    transform: "rotate(0deg)",
    transition: "all 0.3s ease",
    "&:hover": {
      fill: theme.palette.primary.main,
      transition: "all 0.3s ease",
      transform: "scale(1.2)"
    }
  },
  expandIconExpanded: {
    //rotate: "180deg",
    transform: "rotate(180deg)",
    transition: "rotate 0.3s ease",
    "&:hover": {
      fill: theme.palette.primary.main,
      transition: "all 0.3s ease",
      transform: "scale(1.2) rotate(180deg)"
    }
  },
  validAvatar: {
    backgroundColor: theme.palette.primary.main
  },
  toolbarStyle: {
    minHeight: "auto",
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 0,
    paddingRight: 0
  },
  toolbarIconStyle: {
    marginLeft: 0,
    marginRight: 8
    //a: //console.log(theme)
  },
  infoIconColor: {
    fill: theme.palette.info.main
  },
  successIconColor: {
    fill: theme.palette.success.main
  },
  warningIconColor: {
    fill: theme.palette.warning.main
  },
  errorIconColor: {
    fill: theme.palette.error.main
  },
  greyInfoIconColor: {
    fill: "#bbb"
  },
  info: {
    color: theme.palette.info.main,
    fontStyle: "italic"
  },
  greyInfo: {
    color: "#aaa",
    fontStyle: "italic"
  },
  error: {
    color: theme.palette.error.main
  },
  historyItem: {
    color: "#aaa!important",
    fontStyle: "italic",
    "&:hover": {
      color: "#aaa!important"
    },
    "&:visited": {
      color: "#aaa!important"
    }
  },
  fileItem: {
    /*color: theme.palette.info.main + "!important",
    "&:hover": {
      color: theme.palette.info.main + "!important",
    },
    "&:visited": {
      color: theme.palette.info.main + "!important",
    }*/
  },
  historyItemIcon: {
    fill: theme.palette.error.main,
    width: 12,
    height: 12,
    marginBottom: -2,
    marginRight: 2
  },

  bootstrapRoot: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  bootstrapInput: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  },
  bootstrapFormLabel: {
    fontSize: 18
  }
});

class FeedbackServiceOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: {
        AUTHORIZATION: null,
        CONTRACT_PAGE_1_5: null,
        FRAMEWORK_CONTRACT: null,
        SIGNATURE_SPECIMEN: null
      },
      documentTypes: {
        AUTHORIZATION: false,
        CONTRACT_PAGE_1_5: false,
        FRAMEWORK_CONTRACT: false,
        SIGNATURE_SPECIMEN: false
      },
      wrongCause: null,
      warning: null,
      error: null,
      isValid: null
    };
  }

  setError = error => {
    this.setState({ error: error });
  };

  componentDidMount() {
    //console.log(this.props);
    /*const id = this.props.feedbackItem.id;
    feedbackFields(id).then(res => {
      //console.log("feedbackFieldsRes", res);
      this.setState({
        documentTypes: res.data.data
      });
    });*/
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let _isValid = null;

    const id = nextProps.feedbackItem ? nextProps.feedbackItem.id : null;
    if (!id) {
      return;
    }
    if (
      JSON.stringify(nextProps.feedbackItem) ===
      JSON.stringify(this.props.feedbackItem)
    ) {
      return;
    }

    if (nextProps.feedbackItem && nextProps.feedbackItem.status) {
      switch (nextProps.feedbackItem.status) {
        case "okay":
          _isValid = "1";
          break;
        case "timeout":
          _isValid = null; //"0";
          break;
        case "wrong":
          _isValid = "0";
          break;
        case "not_arrived":
          _isValid = null; //"1";
          break;
        default:
          _isValid = null;
      }
      //console.log("_isValid", _isValid, nextProps.feedbackItem.status);
      if (this.state.isValid === null) {
        this.setState({ isValid: _isValid });
      }
    }

    /*feedbackFields(id).then(fieldRes => {
      console.log("fieldRes", fieldRes);
      //feedback(id).then(feedbackRes => {
       // console.log("feedbackRes", feedbackRes.data.data);
        this.setState({
          documentTypes: fieldRes.data.data.feedback,
         // wrongCause: feedbackRes.data.data.wrong_cause,
         // isValid: feedbackRes.data.data.status === "okay" ? "1" : ""
        });
      //});
    });*/
  }

  handleChangeIsValid = event => {
    //console.log(event.target.value)
    this.setState({ isValid: event.target.value });
  };

  handleChange = name => event => {
    let documents = Object.assign({}, this.state.documents);
    //console.log(name, event.target.checked)
    documents[name] = event.target.checked;
    this.setState({ documents: documents });
  };

  render() {
    const { classes } = this.props;
    const {
      documentTypes,
      documents,
      wrongCause,
      warning,
      isValid,
      error
    } = this.state;

    const FeedbackItem = props =>
      documentTypes[props.type] && (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={documents[props.type]}
              onChange={this.handleChange(props.type)}
              value={props.type}
            />
          }
          label={props.label}
        />
      );
    //console.log(isValid);
    return (
      <Fragment>
        <FormControl
          component="fieldset"
          className={classes.formControl}
          fullWidth
        >
          <RadioGroup
            aria-label={localize("serviceOrders", "Megfelelő")}
            name="isValid"
            className={classes.group}
            value={isValid}
            onChange={this.handleChangeIsValid}
          >
            {this.props.feedbackItem &&
              this.props.feedbackItem.status === "not_arrived" && (
                <FormControlLabel
                  value="-1"
                  control={
                    <Radio
                      checked={this.state.isValid === "-1"}
                      color="primary"
                    />
                  }
                  label={localize("serviceOrders", "Ellenőrzésre vár")}
                />
              )}
            <FormControlLabel
              value="1"
              control={
                <Radio checked={this.state.isValid === "1"} color="primary" />
              }
              label={localize("serviceOrders", "Megfelel")}
            />
            <FormControlLabel
              value="2"
              control={
                <Radio checked={this.state.isValid === "2"} color="primary" />
              }
              label={localize("serviceOrders", "Nem érkezett be")}
            />
            <FormControlLabel
              value="0"
              control={
                <Radio checked={this.state.isValid === "0"} color="primary" />
              }
              label={localize("serviceOrders", "Hibás")}
            />
          </RadioGroup>
        </FormControl>
        {isValid === "0" && (
          <Fragment>
            <FormGroup>
              <FeedbackItem
                type="CONTRACT_PAGE_1_5"
                label={localize("serviceOrders", "Szerződés 1. és 5. oldal")}
              />
              <FeedbackItem
                type="AUTHORIZATION"
                label={localize("serviceOrders", "Meghatalmazás")}
              />
              <FeedbackItem
                type="SIGNATURE_SPECIMEN"
                label={localize(
                  "serviceOrders",
                  "Aláírási címpéldány / E.V. esetén személyi igazolvány"
                )}
              />
              <FeedbackItem type="FRAMEWORK_CONTRACT" label="Keretszerződés" />
            </FormGroup>
            <InputField
              value={wrongCause || ""}
              meta={{ touched: false }}
              input={{
                value: wrongCause || "",
                onChange: evt => {
                  this.setState({ wrongCause: evt.target.value });
                }
              }}
              multiline
              rows={3}
              label={localize("serviceOrders", "Indoklás")}
            />
          </Fragment>
        )}
        {isValid !== "0" && isValid !== "1" && (
          <Fragment>
            <InputField
              value={warning || ""}
              meta={{ touched: false }}
              input={{
                value: warning || "",
                onChange: evt => {
                  this.setState({ warning: evt.target.value });
                }
              }}
              multiline
              rows={3}
              label={localize("serviceOrders", "Figyelmeztetés")}
            />
          </Fragment>
        )}
        {error && (
          <Toolbar className={classes.toolbarStyle}>
            <InfoIcon
              className={classNames(
                classes.toolbarIconStyle,
                classes.errorIconColor
              )}
            />
            <Typography className={classes.error}>{error}</Typography>
          </Toolbar>
        )}
      </Fragment>
    );
  }
}

FeedbackServiceOrder.propTypes = {
  classes: PropTypes.object.isRequired,
  feedbackItem: PropTypes.object,
  feedbackError: PropTypes.object
};

export default withStyles(styles)(FeedbackServiceOrder);
