import React, { Fragment } from "react";
import PropTypes from "prop-types";
import localize from "helpers/localize";
import restHelper from "helpers/aee/restHelper";
import DataForm from "Components/New/DataForm/DataFormNew";
import withLock from "./withLock";
import SetRentDataFrom from "./SetRentDataFrom";
import { Chip } from "@material-ui/core";
const LC = "cloud";
class SetRentData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: { flag: 4 },
      errors: {},
      loading: false
    };
  }

  componentDidMount() {
    /*const { setToolbarItems } = this.props;
    setToolbarItems(<div>LOL</div>);*/
    this.view();
  }

  view = () => {
    const { cashregister_id: ap, client_id: client } = this.props;
    this.setState({ loading: true });
    restHelper
      .view("cashregister-set-rent-data/view", null, { ap, client })
      .then(response => {
        const data = Array.isArray(response.data) ? {} : response.data;
        this.setState({ item: data || {}, loading: false });
      });
  };

  onSave = data => {
    const { cashregister_id: ap, client_id: client, onUpdated } = this.props;
    this.setState({ loading: true });
    restHelper
      .update("cashregister-set-rent-data/update", null, null, data, {
        ap,
        client
      })
      .then((/*response*/) => {
        onUpdated(() => {
          this.setState({ loading: false });
        });
      });
  };

  onChange = (key, value) => {
    const item = Object.assign({}, this.state.item, { [key]: value });
    this.setState({ item });
  };

  onItemChange = values => {
    const item = Object.assign({}, this.state.item, values);
    this.setState({ item });
  };

  onChangeErrors = errors => {
    this.setState({ errors });
  };

  toggleEdit = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  render() {
    const { editMode } = this.props;
    const { item, errors, loading } = this.state;
    const active = String(item.state) === "1";
    let data = {
      [-1]: localize(LC, "Beállítás"),
      1: localize(LC, "Bérleti határidő jelentés"),
      2: localize(LC, "Bérleti dátum feloldása")
    };
    if (active) {
      data[0] = localize(LC, "Kikapcsolva");
    }
    return (
      <Fragment>
        <Chip
          style={{
            marginTop: editMode ? 0 : -16,
            marginBottom: 8,
            ...(active ? { backgroundColor: "green", color: "white" } : {})
          }}
          label={localize(LC, active ? "Aktív" : "Inaktív")}
        />
        <DataForm
          editItem={item}
          disabled={!editMode}
          disableHeader
          disableCancelButton
          errors={errors}
          onChangeErrors={this.onChangeErrors}
          onChange={this.onChange}
          onItemChange={this.onItemChange}
          onValidated={this.onSave}
          loading={loading}
          options={{
            form: {
              //model: "ShopOrder",
              fieldsOrder: [],
              fields: {
                flag: {
                  name: localize(LC, "Funkció"),
                  type: "dropdown",
                  data: data
                },
                key: {
                  name: localize(LC, "Partner kód"),
                  maxLength: 6
                  //visible: item => (item.flag === -1 ? true : false)
                },
                warning_flag: {
                  name: localize(LC, "Figyelmeztetés"),
                  type: "dropdown",
                  data: {
                    0: "15 nap",
                    1: "Nincs"
                  }
                  //visible: item => (item.flag === -1 ? true : false)
                },
                warning_msg: {
                  name: localize(LC, "Figyelmeztető szöveg"),
                  maxLength: 28
                  //visible: item => (item.flag === -1 ? true : false)
                },
                date_list: {
                  name: localize(LC, "Dátumok"),
                  type: "simple-2d-array",
                  showLineNumbers: true,
                  maxLength: 10,
                  columns: [
                    { id: "date", label: localize(LC, "Dátum"), type: "date" }
                  ]
                  //visible: item => (item.flag === -1 ? true : false)
                }
                /*no: {
                name: "No"
              },
              date: {
                name: "Date"
              },*/
              }
            }
          }}
          //fieldsOrder: ["name"],
          //component={CheckOutFrom}
          autoRenderFields={true}
          history={this.props.history}
          //componentProps={{ changeStep: this.props.changeStep }}
        />
      </Fragment>
    );
  }
}

SetRentData.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  editMode: PropTypes.bool.isRequired,
  setToolbarItems: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
  cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

export default withLock(SetRentData, "SET_RENT_DATA", {
  dataTitle: "Bérlés",
  title: "Bérlés",
  forceUpdate: false
});
