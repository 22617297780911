import React from "react";
import Menu from "./../Catalog/Menu";
class AdminMenu extends React.Component {
  render() {
    return (
      <div style={{ width: "100%", position: "relative" }}>
        <Menu isAdmin />
      </div>
    );
  }
}

export default AdminMenu;
