import React, { PureComponent, Fragment } from "react";
import { Helmet } from "react-helmet";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
import List from "Components/List/List";
import ContainerHeader from "Components/ContainerHeader/index";
import { get } from "lodash";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import FileDownload from "@material-ui/icons/Description";
import RemoveAnykIcon from "@material-ui/icons/NotInterested";
import DoneIcon from "@material-ui/icons/Done";
import BillingIcon from "@material-ui/icons/AccountBalance";
import RestoreIcon from "@material-ui/icons/RestoreFromTrash";
import Button from "@material-ui/core/Button/Button";
import { eportAnyk } from "redux/modules/anyk";
import { ColorIndicator } from "Components/Forms/Fields/Select";
import worksheet_colors from "Containers/Pages/Worksheets/Worksheets/colors";
import Modal from "Components/Modal/Modal";
import ModalConfirm from "Components/Modal/ModalConfirm";
import MUIList from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MLink from "@material-ui/core/Link";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  restoreWorksheet,
  setPaidStatus,
  billingWorksheet,
  setColor,
  removeAnyk,
  exportUnpaid
} from "redux/modules/worksheets";
import { Link } from "react-router-dom";
import authRules from "helpers/authRules";
import WorksheetBilling from "./WorksheetBilling/WorksheetBilling";
import localize from "helpers/localize";
import dateHelper from "helpers/dateHelper";
import formatHelper from "helpers/formatHelper";

class WorksheetsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openColoring: false,
      selectedColor: null,
      openPaidStatus: false,
      billingId: null,
      selectedPaidStatus: null,
      selectedRow: null
    };
    this.modal = React.createRef();
    this.billingData = null;
  }
  refreshList = () => {
    if (this.list && this.list.getList) {
      return this.list.getList();
    }
  };

  getListHeader = () => {
    return this.props.getListHeader(
      "/worksheets",
      `&section=${this.props.mode}`
    );
  };

  getList = (page, keyword, sort, filters, perPage) => {
    return this.props.getList(
      page,
      keyword,
      sort,
      filters,
      perPage,
      this.props.mode
    );
  };

  getTitle = () => {
    switch (this.props.mode) {
      case "new":
        return localize("menu", "Új Munkalapok");
      case "in_progress":
        return localize("menu", "Folyamatban lévő munkalapok");
      case "done":
        return localize("menu", "Elkészült munkalapok");
      case "waiting":
        return localize("menu", "Ügyfélre váró munkalapok");
      case "closed":
        return localize("menu", "Lezárt munkalapok");
      case "anyk":
        return localize("menu", "ANYK aktuális");
      default:
        return localize("menu", "Munkalapok");
    }
  };

  restore = id => {
    restoreWorksheet(id).then(response => {
      console.log("restoreWorksheet", response);
      this.refreshList();
    });
  };

  setPaid = id => {
    setPaidStatus(id, "paid").then(response => {
      console.log("restoreWorksheet", response);
      this.refreshList();
    });
  };
  onChange = billingData => {
    this.billingData = billingData;
  };
  closeBilling = () => {
    this.setState({ billingId: null });
  };
  setBilling = id => {
    this.setState({ billingId: id });
  };

  validateBillData = () => {
    if (
      !this.billingData ||
      (!this.billingData.workfees && !this.billingData.shop_products) ||
      (this.billingData.workfees.length === 0 &&
        this.billingData.shop_products.length === 0)
    ) {
      globalMessage.fatalError(
        localize("worksheets", "Nincs számlázható tétel.")
      );
      return false;
    }

    let err = false;
    this.billingData.workfees.forEach(item => {
      if (!item.name || item.name === "") {
        err = true;
      }
      if (!item.quantity || item.quantity === "") {
        err = true;
      }
      if (!item.price || item.price === "") {
        err = true;
      }
      if (!item.type || item.type === "") {
        err = true;
      }
    });
    this.billingData.shop_products.forEach(item => {
      if (!item.name || item.name === "") {
        err = true;
      }
      if (!item.quantity || item.quantity === "") {
        err = true;
      }
      if (!item.price || item.price === "") {
        err = true;
      }
    });
    if (err) {
      globalMessage.fatalError(
        localize("worksheets", "Az összes mező kitöltése kötelező.")
      );
      return false;
    }
    return true;
  };

  getSum = data => {
    if (!data) {
      return 0;
    }

    let sum = 0;
    data.shop_products &&
      data.shop_products.forEach(item => {
        sum += parseFloat(item.price || 0) * parseFloat(item.quantity || 0);
      });
    data.workfees &&
      data.workfees.forEach(item => {
        sum += parseFloat(item.price || 0) * parseFloat(item.quantity || 0);
      });

    return sum;
  };

  saveBilling = () => {
    console.log("saveBilling", this.billingData);
    const originalSum = this.billingData.originalSum;
    const sum = this.getSum(this.billingData);
    if (!this.validateBillData()) {
      return;
    }

    this.modal
      .open(
        localize("worksheets", "Megerősítés"),
        localize("worksheets", "Biztosan elkészíti a számlát?"),
        originalSum !== sum
          ? `${localize(
              "worksheets",
              "Eltérés a munkalaphoz képest"
            )}: ${formatHelper.currency(sum - originalSum)}.`
          : null,
        localize("basic", "Ok"),
        localize("basic", "Mégsem"),
        false
      )
      .then(() => {
        this.modal.loading(true);
        billingWorksheet(this.state.billingId, {
          workfees: this.billingData.workfees,
          shop_products: this.billingData.shop_products
        })
          .then(response => {
            console.log("setBilling", response);
            this.modal.loading(false);
            this.modal.hide();
            this.setState({ billingId: null });
            this.refreshList();
            globalMessage.success(
              `${localize(
                "worksheets",
                "A számla sikeresen elkészült, számlaszám"
              )}: ${response.data.invoiceNo}`
            );
          })
          .catch(() => {
            this.modal.hide();
          });
      });
  };

  removeAnyk = id => {
    this.modal
      .open(
        localize("worksheets", "Megerősítés"),
        localize("worksheets", "Biztosan kiveszi a listából?"),
        null,
        localize("basic", "Ok"),
        localize("basic", "Mégsem"),
        false
      )
      .then(() => {
        this.modal.loading(true);
        removeAnyk(id)
          .then(response => {
            console.log("removeAnyk", response);
            this.modal.loading(false);
            this.modal.hide();
            this.refreshList();
          })
          .catch(() => {
            this.modal.hide();
          });
      });
  };

  customIcons = obj => {
    const { mode } = this.props;
    return (
      <Fragment>
        {mode !== "anyk" && (
          <Tooltip
            title={localize("worksheets", "Letöltés")}
            disableFocusListener
          >
            <Button
              className="jr-btn jr-btn-xs"
              target="_blank"
              href={
                process.env.REACT_APP_HOST +
                "/v1/worksheets/pdf/" +
                obj.id +
                "?accessToken=" +
                localStorage.getItem("access_token").replace("Bearer ", "")
              }
              component="a"
            >
              <FileDownload />
            </Button>
          </Tooltip>
        )}
        {mode === "deleted" && (
          <Tooltip title={localize("worksheets", "Visszaállítás")}>
            <Button
              className="jr-btn jr-btn-xs"
              target="_blank"
              onClick={() => this.restore(obj.id)}
            >
              <RestoreIcon />
            </Button>
          </Tooltip>
        )}
        {mode === "anyk" && (
          <Tooltip
            title={localize("worksheets", "Kivétel az exportból")}
            disableFocusListener
          >
            <Button
              className="jr-btn jr-btn-xs"
              target="_blank"
              onClick={() => this.removeAnyk(obj.id)}
            >
              <RemoveAnykIcon />
            </Button>
          </Tooltip>
        )}
        {mode === "unpaid" && (
          <Fragment>
            <Tooltip title={localize("worksheets", "Fizetettre állít")}>
              <Button
                className="jr-btn jr-btn-xs"
                target="_blank"
                onClick={() => this.setPaid(obj.id)}
              >
                <DoneIcon style={{ fill: "green" }} />
              </Button>
            </Tooltip>
            {/*<Tooltip title={localize("worksheets", "Számlát készít")}>
              <Button
                className="jr-btn jr-btn-xs"
                target="_blank"
                color="primary"
                onClick={() => this.setBilling(obj.id)}
              >
                <BillingIcon />
              </Button>
        </Tooltip>*/}
          </Fragment>
        )}
        {mode === "closed" &&
          authRules.hasPermissionByKeyAndName(
            "worksheets",
            "worksheets.invoice"
          ) && (
            <Fragment>
              <Tooltip
                title={localize(
                  "worksheets",
                  obj.can_invoice ? "Számlát készít" : "A számla már elkészült"
                )}
              >
                <Button
                  disabled={obj.can_invoice ? false : true}
                  className="jr-btn jr-btn-xs"
                  target="_blank"
                  color="primary"
                  onClick={() => this.setBilling(obj.id)}
                >
                  <BillingIcon />
                </Button>
              </Tooltip>
            </Fragment>
          )}
      </Fragment>
    );
  };

  customButtons = () => {
    const { mode } = this.props;
    if (mode === "anyk") {
      return (
        <Fragment>
          <Button color="primary" onClick={this.export("ALL")}>
            {localize("worksheets", "Export (Mind)")}
          </Button>
          <Button color="primary" onClick={this.export("FIXING")}>
            {localize("worksheets", "Export (Javítás)")}
          </Button>
          <Button color="primary" onClick={this.export("ANNUAL_REVIEW")}>
            {localize("worksheets", "Export (Éves f.)")}
          </Button>
          <Button color="primary" onClick={this.export("SCRAPPING")}>
            {localize("worksheets", "Export (Selejtezés)")}
          </Button>
        </Fragment>
      );
    }

    if (mode === "bank_transfer") {
      return (
        <div className="col-auto" style={{ paddingTop: 6, paddingBottom: 6 }}>
          <Button
            component={MLink}
            style={{
              textDecoration: "none",
              paddingTop: 10,
              paddingBottom: 10
            }}
            target="_blank"
            href={`${
              process.env.REACT_APP_HOST
            }/v1/worksheets/export-unpaid?accessToken=${localStorage
              .getItem("access_token")
              .replace("Bearer ", "")}`}
            color="primary"
          >
            {localize("worksheets", "Exportálás")}
          </Button>
        </div>
      );
    }

    return "";
  };

  export = type => () => {
    eportAnyk(type).then(response => {
      console.log("export", response);
      this.refreshList();
    });
  };
  exportUnpaid = () => {
    exportUnpaid().then(response => {
      console.log("exportUnpaid", response);
      this.refreshList();
    });
  };

  /* Set color start */
  saveColor = () => {
    const { selectedRow, selectedColor } = this.state;
    setColor(selectedRow.id, selectedColor ? selectedColor.id : null).then(
      response => {
        this.closeColoring();
        const newRow = {
          ...selectedRow,
          color: selectedColor ? selectedColor.label : null,
          color_value: selectedColor ? selectedColor.id : null
        };

        this.props.changeRow(newRow);
        //this.refreshList();
      }
    );
  };

  openColoring = row => evt => {
    evt.preventDefault();
    this.setState({
      openColoring: true,
      selectedRow: row,
      selectedColor: row.color_value
        ? { id: row.color_value, label: row.color }
        : null
    });
  };

  closeColoring = () => {
    this.setState({
      openColoring: false,
      selectedColor: null,
      selectedRow: null
    });
  };

  setColor = color => () => {
    this.setState({ selectedColor: color });
  };

  /* Set color end */

  /* Set paudStatus start */
  savePaidStatus = () => {
    const { selectedRow, selectedPaidStatus } = this.state;
    setPaidStatus(
      selectedRow.id,
      selectedPaidStatus ? selectedPaidStatus.id : null
    ).then(response => {
      this.closePaidStatus();
      const newRow = {
        ...selectedRow,
        paid_status: selectedPaidStatus ? selectedPaidStatus.label : null,
        paid_status_value: selectedPaidStatus ? selectedPaidStatus.id : null
      };

      this.props.changeRow(newRow);
      //this.refreshList();
    });
  };

  openPaidStatus = row => evt => {
    evt.preventDefault();
    this.setState({
      openPaidStatus: true,
      selectedRow: row,
      selectedPaidStatus: row.paid_status_value
        ? { label: row.paid_status, id: row.paid_status_value }
        : null
    });
    console.log(this.props);
  };

  closePaidStatus = () => {
    this.setState({
      openPaidStatus: false,
      selectedPaidStatus: null,
      selectedRow: null
    });
  };

  setPaidStatus = paidStatus => () => {
    this.setState({ selectedPaidStatus: paidStatus });
  };

  /* Set paudStatus end */

  /**
   * Megmondja, hogy mely gombokat kell kikapcsolni a táblázat soraiben
   */
  disabledIcons = () => {
    const { mode } = this.props;
    const disabled = {};
    if (mode === "anyk" || mode === "unpaid") {
      disabled.disableViewIcon = true;
      disabled.disableEditIcon = true;
      disabled.disableDeleteIcon = true;
      disabled.disabledCreate = true;
    }

    if (mode === "deleted") {
      disabled.disableDeleteIcon = true;
    }

    return disabled;
  };

  render() {
    const { pathname } = this.props.history.location;
    const sectionPart = pathname.split("/")[2];
    const section = sectionPart ? `/${sectionPart}` : "";
    const {
      openColoring,
      selectedColor,
      openPaidStatus,
      billingId,
      selectedPaidStatus
    } = this.state;
    const colors = get(this.props, "columns.color.filter.data", []);
    const paidStatuses = get(this.props, "columns.paid_status.filter.data", []);
    return (
      <Fragment>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        <ModalConfirm onRef={ref => (this.modal = ref)} />
        <Modal
          title={localize("worksheets", "Számlázás")}
          open={billingId ? true : false}
          Icon={BillingIcon}
          positionTop
          fullScreenMobile
          onClose={this.closeBilling}
          onCancel={this.closeBilling}
          onAccept={this.saveBilling}
          maxWidth="lg"
        >
          <WorksheetBilling id={billingId} onChange={this.onChange} />
        </Modal>
        <Modal
          title={localize("worksheets", "Jelölés")}
          open={openColoring}
          disableIcon
          onClose={this.closeColoring}
          onCancel={this.closeColoring}
          onAccept={this.saveColor}
        >
          <MUIList component="nav" aria-label="coloring">
            <ListItem
              dense
              style={{
                backgroundColor: !selectedColor ? "#ddd" : undefined
              }}
              button
              onClick={this.setColor(null)}
            >
              <ListItemIcon>
                <ColorIndicator color={undefined} size={20} />
              </ListItemIcon>
              <ListItemText primary={localize("worksheets", "Nincs")} />
            </ListItem>
            {colors.map((color, index) => {
              return (
                <ListItem
                  dense
                  style={{
                    backgroundColor:
                      selectedColor && color.id === selectedColor.id
                        ? "#ddd"
                        : undefined
                  }}
                  key={index}
                  button
                  onClick={this.setColor(color)}
                >
                  <ListItemIcon>
                    <ColorIndicator
                      color={worksheet_colors[color.id]}
                      size={20}
                    />
                  </ListItemIcon>
                  <ListItemText primary={color.label} />
                </ListItem>
              );
            })}
          </MUIList>
        </Modal>
        <Modal
          title={localize("worksheets", "Fizetési státusz")}
          open={openPaidStatus}
          disableIcon
          onClose={this.closePaidStatus}
          onCancel={this.closePaidStatus}
          onAccept={this.savePaidStatus}
        >
          <MUIList component="nav" aria-label="coloring">
            <ListItem
              dense
              style={{
                backgroundColor: !selectedPaidStatus ? "#ddd" : undefined
              }}
              button
              onClick={this.setPaidStatus(null)}
            >
              <ListItemIcon>
                <ColorIndicator color={undefined} size={20} />
              </ListItemIcon>
              <ListItemText primary={localize("worksheets", "Nincs")} />
            </ListItem>
            {paidStatuses.map((paidStatus, index) => {
              return (
                <ListItem
                  dense
                  style={{
                    backgroundColor:
                      selectedPaidStatus &&
                      paidStatus.id === selectedPaidStatus.id
                        ? "#ddd"
                        : undefined
                  }}
                  key={index}
                  button
                  onClick={this.setPaidStatus(paidStatus)}
                >
                  <ListItemIcon>
                    <ColorIndicator
                      color={paidStatus.id === "unpaid" ? "#EB9694" : undefined}
                      size={20}
                    />
                  </ListItemIcon>
                  <ListItemText primary={paidStatus.label} />
                </ListItem>
              );
            })}
          </MUIList>
        </Modal>
        <ContainerHeader title={this.getTitle()} />
        <List
          key={`list_${this.props.mode}`}
          listRef={ref => (this.list = ref)}
          //actionColumnWidth={240}
          //id={`${this.props.mode}-worksheets`}
          {...this.disabledIcons()}
          customIcons={this.customIcons}
          customButtons={this.customButtons}
          topPagination
          {...this.props}
          getListHeader={this.getListHeader.bind(this)}
          getList={this.getList.bind(this)}
          customFilters={[
            { name: "apn", label: localize("worksheets", "APN") }
          ]}
          renderCell={(row, rowIndex, colIndex, header) => {
            if (header._key === "number") {
              return (
                <Link to={`/worksheets${section}/view/${row.id}`}>
                  {row.number}
                </Link>
              );
            }
            if (header._key === "apn" && row.cashregister_id) {
              return (
                <Link to={`/cashregisters/view/${row.cashregister_id}`}>
                  {row.apn}
                </Link>
              );
            }

            if (header._key === "color") {
              return (
                <a href="#" onClick={this.openColoring(row)}>
                  {row.color || localize("worksheets", "Nincs")}
                </a>
              );
            }
            if (header._key === "paid_status") {
              return (
                <a href="#" onClick={this.openPaidStatus(row)}>
                  {row.paid_status || localize("worksheets", "Nincs")}
                </a>
              );
            }

            return false;
          }}
          rowFormat={item => {
            if (item.paid_status_value && item.paid_status_value === "unpaid") {
              return {
                backgroundColor: "#EB9694"
              };
            }
            if (item.color_value && item.color_value !== "") {
              return {
                backgroundColor: worksheet_colors[item.color_value]
              };
            }
          }}
        />
      </Fragment>
    );
  }
}

export default WorksheetsList;
