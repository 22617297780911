import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { Form } from "reactstrap";
import InputField from "../../../../../Components/Forms/Fields/Input";
import Validations from "../../../../../Components/Forms/Validations";
import DefaultFormButtons from "../../../../../Components/Forms/Buttons/DefaultFormButtons";
import Grid from "@material-ui/core/Grid";
import localize from "helpers/localize";
class SendSmsForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  render() {
    const {
      handleInputChange,
      fieldErrors,
      pristine,
      submitting,
      loading,
      handleSubmit
    } = this.props;

    return (
      <Fragment>
        <Form
          onSubmit={handleSubmit}
          className="form"
          style={{ width: "100%", paddingLeft: 12, paddingRight: 12 }}
        >
          <Grid container>
            <Grid item xs={12} md={2} lg={1}>
              <Field
                isRequired={true}
                validate={[Validations.required]}
                name="number"
                label={localize("cashregisters", "Telefonszám")}
                fieldError={fieldErrors.number}
                onChange={() => handleInputChange("number")}
                component={InputField}
                type="text"
                placeholder={localize("cashregisters", "Telefonszám")}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 8, marginBottom: 8 }}>
              <Field
                isRequired={true}
                validate={[Validations.required]}
                name="message"
                label={localize("cashregisters", "SMS szövege")}
                fieldError={fieldErrors.message}
                onChange={() => handleInputChange("message")}
                component={InputField}
                type="text"
                placeholder={localize("cashregisters", "SMS szövege")}
                multiline
                rows="4"
              />
            </Grid>
          </Grid>
          <DefaultFormButtons
            pristine={pristine}
            submitting={submitting}
            loading={loading}
            {...this.props}
            disableCancel={true}
            texts={{ saveButton: localize("basic", "Küldés") }}
          />
        </Form>
      </Fragment>
    );
  }
}

export default reduxForm({
  form: "send-sms",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(SendSmsForm);
