import React from "react";
import PropTypes from "prop-types";
import JsonSyntaxHighlight from "helpers/JsonSyntaxHighlight";
import { Paper } from "@material-ui/core";

if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
    padString = String(typeof padString !== "undefined" ? padString : " ");
    if (this.length >= targetLength) {
      return String(this);
    } else {
      targetLength = targetLength - this.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(this);
    }
  };
}

if (!String.prototype.padEnd) {
  String.prototype.padEnd = function padEnd(targetLength, padString) {
    targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
    padString = String(typeof padString !== "undefined" ? padString : " ");
    if (this.length > targetLength) {
      return String(this);
    } else {
      targetLength = targetLength - this.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
      }
      return String(this) + padString.slice(0, targetLength);
    }
  };
}

const leftCol = str => {
  return str.padEnd(18, "|").replace(/\|/g, "&nbsp;");
};

const rightCol = str => {
  return str.padStart(18, "|").replace(/\|/g, "&nbsp;");
};

const formatRow = str => {
  return str.replace(/ /g, String.fromCharCode(160));
};

const MetaTableImages = props => {
  const { row } = props;
  const { raw_recipe } = row;
  //console.log(raw_recipe);
  if (!raw_recipe || !Array.isArray(raw_recipe))
    return <JsonSyntaxHighlight json={raw_recipe} />;
  return (
    <Paper
      style={{
        margin: 16,
        padding: 16,
        fontFamily: "'Courier New', Courier, monospace",
        fontSize: 13,
        width: 320,
        overflowX: "hidden"
      }}
    >
      {raw_recipe.map((item, index) => {
        if (typeof item === "string") {
          return <div key={index}>{formatRow(item)}</div>;
        }

        switch (item.type) {
          case "sign":
            return (
              <div key={index}>
                <div>
                  ____________&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;____________
                </div>
                <div>
                  &nbsp;&nbsp;&nbsp;ÁTADÓ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ÁTVEVŐ&nbsp;&nbsp;&nbsp;
                </div>
              </div>
            );
          case "asterisk":
            return <div key={index}>************************************</div>;
          case "hr":
            return <div key={index}>____________________________________</div>;
          case "br":
            return (
              <div key={index}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            );
          default:
            return (
              <div key={index} style={item.style}>
                {Array.isArray(item.content) ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        leftCol(item.content[0]) + rightCol(item.content[1])
                    }}
                  />
                ) : (
                    item.content
                  )}
              </div>
            );
        }
      })}{" "}
    </Paper>
  );
};

MetaTableImages.propTypes = {
  row: PropTypes.object
};

export default MetaTableImages;
