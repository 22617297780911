/* eslint-disable */
import axios from 'axios';
import { get } from 'lodash';
import * as fcmActions from "./fcm";
import * as worksheetsActions from "./worksheets";

const GET_CODE_LOAD = 'GET_CODE_LOAD';
const GET_CODE_SUCCESS = 'GET_CODE_SUCCESS';
const GET_CODE_FAILED = 'GET_CODE_FAILED';

const SEND_FCM_LOAD = 'SEND_FCM_LOAD';
const SEND_FCM_SUCCESS = 'SEND_FCM_SUCCESS';
const SEND_FCM_FAILED = 'SEND_FCM_FAILED';

const SHOW_PASSWORD = 'SHOW_PASSWORD';
const SET_CONNECTED = 'SET_CONNECTED';
const CONNECT_CHECK_SUCCESS = 'CONNECT_CHECK_SUCCESS';
const GET_SIGNED = 'GET_SIGNED';

const CONNECT_EDIT_LOAD = 'CONNECT_EDIT_LOAD';
const CONNECT_EDIT_SUCCESS = 'CONNECT_EDIT_SUCCESS';
const CONNECT_EDIT_FAILED = 'CONNECT_EDIT_FAILED';

const COMMUNICATION_EDIT_LOAD = 'COMMUNICATION_EDIT_LOAD';
const COMMUNICATION_EDIT_SUCCESS = 'COMMUNICATION_EDIT_SUCCESS';
const COMMUNICATION_EDIT_FAILED = 'COMMUNICATION_EDIT_FAILED';

const SIGNING_VIEW_LOAD = 'SIGNING_VIEW_LOAD';
const SIGNING_VIEW_SUCCESS = 'SIGNING_VIEW_SUCCESS';
const SIGNING_VIEW_FAILED = 'SIGNING_VIEW_FAILED';

const SIGNED_SEND_LOAD = 'SIGNED_SEND_LOAD';
const SIGNED_SEND_SUCCESS = 'SIGNED_SEND_SUCCESS';
const SIGNED_SEND_FAILED = 'SIGNED_SEND_FAILED';


const DISCONNECT = 'DISCONNECT';

const initialState = {
    loading: false,
    success: false,
    failed: false,
    message: null,
    code: null,
    screenShowCode: false,
    showPassword: false,
    fieldErrors: {},
    saved: false,
    password: null,
    connected: false,
    token: null,
    viewData: null,
    getSigned: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SIGNED:
            return {
                ...state,
                getSigned: true
            };
        case CONNECT_CHECK_SUCCESS:
            return {
                ...state,
                connected: true,
                code: action.code
            };
        case SHOW_PASSWORD:
            return {
                ...state,
                showPassword: true
            };
        case SET_CONNECTED:
            return {
                ...state,
                connected: true
            };
        case DISCONNECT:
            return {
                ...state,
                connected: false,
                code: null
            };
        case SIGNED_SEND_LOAD:
            return {
                ...state,
                loading: true,
                failed: false,
                message: null,
                success: false,
                getSigned: false
            };
        case SIGNING_VIEW_LOAD:
            return {
                ...state,
                loading: true,
                failed: false,
                message: null,
                success: false,
                token: null,
                viewData: null
            };

        case CONNECT_EDIT_LOAD:
            return {
                ...state,
                saved: false,
                password: null,
                loading: true,
                failed: false,
                message: null,
                formErrors: {}
            };
        case COMMUNICATION_EDIT_LOAD:
            return {
                ...state,
                loading: true,
                failed: false,
                message: null,
                success: false
            };
        case SEND_FCM_LOAD:
            return {
                ...state,
                loading: true,
                success: false,
                failed: false,
                message: null,
                screenShowCode: false,
                showPassword: false
            };
        case GET_CODE_LOAD:
            return {
                ...state,
                loading: true,
                success: false,
                failed: false,
                message: null,
                code: null,
            };
        case SEND_FCM_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                screenShowCode: true
            };
        case SIGNED_SEND_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                message: null,
                success: true,
                viewData: null,
                token: null
            };
        case SIGNING_VIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                message: null,
                success: true,
                token: action.token,
                viewData: action.viewData
            };
        case GET_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                code: action.code
            };
        case CONNECT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                saved: true,
                password: action.password,
                code: action.code,
                failed: false,
                message: null,
                formErrors: {},
                success: true
            };
        case COMMUNICATION_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                message: null,
                success: true
            };
        case SIGNED_SEND_FAILED:
        case SIGNING_VIEW_FAILED:
        case COMMUNICATION_EDIT_FAILED:
        case CONNECT_EDIT_FAILED:
        case SEND_FCM_FAILED:
        case GET_CODE_FAILED:
            return {
                ...state,
                loading: false,
                failed: true,
                message: action.message,
                formErrors: action.formErrors || {},
            };
        default:
            return state;
    }
}

export const getView = (token) => dispatch => {
    dispatch({
        type: SIGNING_VIEW_LOAD
    });
    dispatch(unSubscribeSigned());
    return axios.get('/v1/worksheet-signing-tools/worksheet/' + token).then(
        response => {
            dispatch(subscribeSigned());
            return dispatch({
                type: SIGNING_VIEW_SUCCESS,
                token: token,
                viewData: get(response, 'data.data')
            });
        }
    ).catch(error => {
        dispatch({
            type: SIGNING_VIEW_FAILED,
            message: get(error, 'response.data.message', null)
        })
    });
};

export const getCode = () => dispatch => {
    dispatch({
        type: GET_CODE_LOAD
    });
    /*const code = localStorage.getItem("pair_code");
    if(code) {
      dispatch({
          type: GET_CODE_SUCCESS,
          code: code
      })
      return new Promise((resolve, reject) => {
        return resolve(code);
      });
    }*/
    const code = localStorage.getItem("pair_code");
    return axios.get(`/v1/worksheet-signing-tools/code${code ? `?code=${code}` : ""}`).then(
        response => {
            const newCode = get(response, 'data.data.code');
            if (code === newCode) {
                const disp = dispatch({
                    type: GET_CODE_SUCCESS,
                    code: newCode
                });
                dispatch({
                    type: CONNECT_EDIT_SUCCESS,
                    password: newCode,
                    code: newCode
                });
                return disp;

            } else {
                return dispatch({
                    type: GET_CODE_SUCCESS,
                    code: newCode
                })
            }
        }
    ).catch(error => {
        dispatch({
            type: GET_CODE_FAILED,
            message: get(error, 'response.data.message', null)
        })
    });
};

export const sendFcm = () => (dispatch, getState) => {
    dispatch({
        type: SEND_FCM_LOAD
    });
    dispatch(unSubscribeConnecting());
    return axios.post(`/v1/worksheet-signing-tools/fcm-token/${getState().signing.code}`, {
        token: getState().fcm.token
    }).then(
        () => {
            dispatch(subscribeConnecting());
            return dispatch({
                type: SEND_FCM_SUCCESS
            });
        }
    ).catch(error => {
        dispatch({
            type: SEND_FCM_FAILED,
            message: get(error, 'response.data.message', null)
        })
    });
};

export const sendCode = (data) => dispatch => {
    dispatch({
        type: CONNECT_EDIT_LOAD
    });

    return axios.post('/v1/user/worksheet-signing-tool', data).then(
        response => {
            dispatch(subscribeConnected());
            return dispatch({
                type: CONNECT_EDIT_SUCCESS,
                password: get(response, 'data.data.admin_code', false),
                code: data.code
            });
        }
    ).catch(error => {
        dispatch({
            type: CONNECT_EDIT_FAILED,
            message: get(error, 'response.data.message', null),
            formErrors: get(error, 'response.data.form_errors', {})
        })
    });
};

export const sendView = (id) => dispatch => {
    dispatch({
        type: COMMUNICATION_EDIT_LOAD
    });

    return axios.post('/v1/worksheets/signing/' + id).then(
        () => {
            //TODO: ide lett rakva a feliratkozás a beküldés figyelésére
            dispatch(subscribeAdminSigned(id));
            return dispatch({
                type: COMMUNICATION_EDIT_SUCCESS
            });
        }
    ).catch(error => {
        dispatch({
            type: COMMUNICATION_EDIT_FAILED,
            message: get(error, 'response.data.message', null),
            formErrors: get(error, 'response.data.form_errors', {})
        })
    });
};

export const getSigned = (id) => dispatch => {
    dispatch({
        type: COMMUNICATION_EDIT_LOAD
    });

    return axios.post('/v1/worksheets/signed/' + id).then(
        () => {
            //TODO: Ez így csak a gomb megnyomásakor aktiválódik, hamarabb kellett feliratkozni a kiküldésnél  
            //dispatch(subscribeAdminSigned(id));
            return dispatch({
                type: COMMUNICATION_EDIT_SUCCESS
            });
        }
    ).catch(error => {
        dispatch({
            type: COMMUNICATION_EDIT_FAILED,
            message: get(error, 'response.data.message', null),
            formErrors: get(error, 'response.data.form_errors', {})
        })
    });
};

export const sendSign = (file, phase, token) => (dispatch, getState) => {
    dispatch({
        type: SIGNED_SEND_LOAD
    });
    return axios.post(`/v1/worksheet-signing-tools/worksheet-signature/${token ? token : getState().signing.token}?phase=${phase}`, { file }).then(
        () => {
            dispatch(unSubscribeSigned());
            return dispatch({
                type: SIGNED_SEND_SUCCESS
            });
        }
    ).catch(error => {
        dispatch({
            type: SIGNED_SEND_FAILED,
            message: get(error, "response.data.message", null),
            formErrors: get(error, "response.data.form_errors", {})
        });
    });
};

export const sendAdminCode = (data) => (dispatch, getState) => {
    dispatch({
        type: CONNECT_EDIT_LOAD
    });

    data.code = getState().signing.code;
    return axios.post('/v1/worksheet-signing-tools/code', data).then(
        () => {
            localStorage.setItem("pair_code", data.code);
            dispatch({
                type: CONNECT_EDIT_SUCCESS,
                code: data.code
            })
        }
    ).catch(error => {
        dispatch({
            type: CONNECT_EDIT_FAILED,
            message: get(error, 'response.data.message', null),
            formErrors: get(error, 'response.data.form_errors', {})
        })
    });
};

export const disconnect = () => dispatch => {
    dispatch({
        type: DISCONNECT
    });
    localStorage.removeItem("pair_code");
    return axios.delete('/v1/user/worksheet-signing-tool');
};

export const checkCode = () => dispatch => {
    return axios.get('/v1/user/worksheet-signing-tool').then(
        response => {
            let code = get(response, 'data.data.code', false);
            return code ? dispatch({
                type: CONNECT_CHECK_SUCCESS,
                code: get(response, 'data.data.code', false)
            }) : code;
        }
    ).catch(error => {

    });
};

export const subscribeConnected = () => dispatch => {
    return dispatch(
        fcmActions.subscribe(
            `connected`,
            (payload) => {
                return dispatch(connected());
            }
        )
    )
};

export const subscribeConnecting = () => dispatch => {
    return dispatch(
        fcmActions.subscribe(
            `connecting`,
            (payload) => {
                return dispatch(connecting());
            }
        )
    )
};

export const subscribeDisconnect = () => dispatch => {
    return dispatch(
        fcmActions.subscribe(
            `disconnected`,
            (payload) => {
                return dispatch(disconnected());
            }
        )
    )
};

export const subscribeSigning = () => dispatch => {
    return dispatch(
        fcmActions.subscribe(
            `signing`,
            (payload) => {
                return dispatch(signing(payload));
            }
        )
    )
};

export const subscribeSigned = () => dispatch => {
    return dispatch(
        fcmActions.subscribe(
            `signed`,
            (payload) => {
                return dispatch(signed());
            }
        )
    )
};

export const subscribeAdminSigned = (id) => dispatch => {
    return dispatch(
        fcmActions.subscribe(
            `signed`,
            (payload) => {
                //dispatch(unSubscribeAdminSigning());
                return dispatch(
                    worksheetsActions.viewWorksheets(id)
                );
            }
        )
    )
};

export const unSubscribeSigned = () => dispatch => {
    fcmActions.unsubscribe('signed')
};

export const unSubscribeSigning = () => dispatch => {
    fcmActions.unsubscribe('signing')
};

export const unSubscribeAdminSigning = () => dispatch => {
    fcmActions.unsubscribe('signing')
};


export const unSubscribeConnecting = () => dispatch => {
    fcmActions.unsubscribe('connecting')
};

export const unSubscribeConnected = () => dispatch => {
    fcmActions.unsubscribe('connected')
};
export const unSubscribeDisconnected = () => dispatch => {
    fcmActions.unsubscribe('disconnected')
};

export const connecting = () => (dispatch, getState) => {
    dispatch({
        type: SHOW_PASSWORD
    });
    //unSubscribeConnecting();
};

export const connected = () => (dispatch, getState) => {
    dispatch({
        type: SET_CONNECTED
    });
    //unSubscribeConnected();
};

export const disconnected = () => (dispatch, getState) => {
    window.location.reload();
};

export const signing = (payload) => (dispatch, getState) => {
    return dispatch(getView(payload.worksheet_view_token));
};

export const signed = () => (dispatch, getState) => {
    return dispatch({
        type: GET_SIGNED
    })
};