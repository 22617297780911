/* eslint-disable */
import React, {Component, Fragment} from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {
    FormGroup,
    Label
} from 'reactstrap';
import PropTypes from 'prop-types';
import axios from "axios";
import {get} from "lodash";
import { withStyles } from "@material-ui/core/styles";
import inputStyle from "./inputStyle.js";
import localize from "helpers/localize";

const customStyles = theme => {
  return {
    container: (provided, state) => ({
      ...provided,
      //borderBottom: "1px dotted pink",
      //color: state.isSelected ? "red" : "blue"
      //backgroundColor: state.isSelected ? "red" : "blue"
      //padding: 20
      //marginTop:-4
     
      top:18
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      //borderBottom: "1px dotted pink",
      //color: state.isSelected ? "red" : "blue"
      //backgroundColor: state.isSelected ? "red" : "blue"
      //padding: 20
      //marginTop:-4
      padding: "0 8px"
      //backgroundColor: "red"
    }),
    option: (provided, state) => ({
      ...provided
      //borderBottom: "1px dotted pink",
      //color: state.isSelected ? "red" : "blue"
      //backgroundColor: state.isSelected ? "red" : "blue"
      //padding: 20
    }),
    menu: (provided, state) => ({
      ...provided,
      //borderBottom: "1px dotted pink",
      //color: state.isSelected ? "red" : "blue"
      //backgroundColor: state.isSelected ? "red" : "blue"
      zIndex: 2000
    }),
    control: (provided, state) => {
      let s = {};

      if (state.isFocused) {
        s.borderColor= "#80bdff";
          s.boxShadow= "0 0 0 0.2rem rgba(0,123,255,.25)";
        
        s["&:hover"] = {
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
        };
      }

      if (state.selectProps.error) {
        s.borderColor = theme.palette.error.main;
        if (state.isFocused) {
          s.boxShadow = `${theme.palette.error.main} 0 0 0 1px`;
        }
        s["&:hover"] = {
          //boxShadow: `${theme.palette.error.main} 0 0 0 1px`,
          borderColor: theme.palette.error.main
        };
      }

      return {
        // none of react-select's styles are passed to <Control />
        ...provided,
        minHeight: "auto",
        //height: 31,
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        border: "1px solid " + theme.palette.grey[500],
        fontSize: 14,
        //width: "100%",
        //lineHeight: "19px",
        //padding: "5px 6px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        ...s
      };
    },
    dropdownIndicator: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      paddingTop: 0,
      paddingBottom: 0
    }),
    clearIndicator: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      paddingTop: 0,
      paddingBottom: 0
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    }
  };
};

class AsyncTagInput extends Component {
    static propTypes = {
        input: PropTypes.any.isRequired,
        label: PropTypes.string,
        hint: PropTypes.string,
        meta: PropTypes.shape({
            touched: PropTypes.bool.isRequired,
            error: PropTypes.string,
            warning: PropTypes.string
        }),
        fieldError: PropTypes.string
    };

    constructor(props) {
        super(props);
        let defaultTags = Array.isArray(props.input.value) ? props.input.value.map(tag => { return {value: tag, label: tag}}) : '';
        this.state = {
          defaultTags
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if(this.props.input.value || nextProps.input.value) {
            let defaultTags = Array.isArray(nextProps.input.value) ? nextProps.input.value.map(tag => { return {value: tag, label: tag}}) : '';
            this.setState({
                defaultTags: defaultTags,
                menuIsOpen: false
            })
        }
    };

    handleChange = (tags, data) => {
        switch (data.action) {

            case 'select-option':
            case 'create-option':
            case 'remove-value':
                this.setState({
                    tags: ''
                });
                this.props.onChangeTags(tags ? tags.map(item => item.value) : null);
            return;
            default:
                return;
        }
    };

    handleInputChange(tags, data) {
        switch (data.action) {
            case 'input-change':
                this.setState({ tags: tags,  menuIsOpen: true });
                return;
            case 'set-values':

                return;
            case 'menu-close':
                this.setState({
                    menuIsOpen: false,
                    tags: ''
                });
                return;
            default:
                return;
        }
    }

    getSearch = (input, callback) => {
      //console.log("getSearch", `${this.props.tagPath.path}?keyword=${input}`)
      axios.get(`${this.props.tagPath.path}?keyword=${input}`).then((response) => {
        let raw = get(response, 'data.data', []);
        if(!Array.isArray(raw)) {
          raw = [];
        }
        
        const data = raw.map(item => {
          return {
                value: item, label: item
          }
        });
        //console.log("this.props.tagPath.path", this.props.tagPath.path, raw, data)
        callback(data);
    });
  };

  render() {

        const { input, label, fieldError, hint, isViewPage, theme, classes } = this.props;
        const { touched, error, warning } = this.props.meta;
        return (
            <FormGroup className={(touched && (fieldError || error || warning) ? 'error' : '')+(isViewPage ? ' row' : '' )}>
                {label && <Label for={input.name} /*className="label-control col-md-5" */ className={classes.bootstrapTagInputLabel}>{label}</Label>}
                <div className="controls">
                    {isViewPage && <Fragment>{Array.isArray(input.value) ? input.value.join(',') : '-'}</Fragment>}
                    {!isViewPage &&
                    <AsyncCreatableSelect
                        key={'asycTags'+this.state.defaultTags.length}
                        isMulti
                        isSearchable
                        cacheOptions
                        defaultValue={this.state.defaultTags}
                        inputValue={this.state.tags}
                        onChange={this.handleChange.bind(this)}
                        onInputChange={this.handleInputChange.bind(this)}
                        name={input.name}
                        loadOptions={this.getSearch.bind(this)}
                        menuIsOpen={this.state.menuIsOpen}
                        noOptionsMessage={() => localize("basic", "Nincs találat.")}
                        loadingMessage={() => localize("basic", "Töltés...")}
                        formatCreateLabel={(inputValue) => localize("basic", "Létrehozás: ")+inputValue}
                        placeholder={''}
                        styles={customStyles(theme)}
                    />
                    }
                    {hint && <p>{hint}</p>}
                    {touched && (
                        (fieldError && <div className="help-block"><ul><li>{fieldError}</li></ul></div>) ||
                        (error && <div className="help-block"><ul><li>{localize("basic", error)}</li></ul></div>) ||
                        (warning && <div className="help-block"><ul><li>{warning}</li></ul></div>)
                    )}
                </div>
            </FormGroup>
        );
    }
}


export default withStyles(inputStyle, { withTheme: true })(AsyncTagInput);