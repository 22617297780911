import React, { Component } from "react";
import { reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";
import EditForm from "../../../../../Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import SimpleFileInput from "../../../../../Components/Forms/Fields/SimpleFile";
import localize from "helpers/localize";

class CruleForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  handleFileChange = (remove, file) => {
    const { dispatch, change } = this.props;
    dispatch(change("file_id", remove ? null : get(file, "id", "")));
  };

  render() {
    const { isViewPage, fieldErrors } = this.props;

    const fieldError =
      fieldErrors && fieldErrors.file_id
        ? fieldErrors.file_id.join(", ")
        : null;

    return (
      <EditForm {...this.props} disableCancel={true}>
        <p>
          {localize(
            "cashregisters",
            "Az import csv ; azaz pontosvesszővel legyen elkészítve és a következő oszlopai vannak"
          )}
        </p>
        <ol>
          <li>
            <b>ap szám</b> {localize("cashregisters", "A pénztárgép ap száma")}
          </li>
          <li>
            <b>imei</b>{" "}
            {localize("cashregisters", "A pénztárgép imei azonosítója")}
          </li>
          <li>
            <b>imsi</b>{" "}
            {localize("cashregisters", "A pénztárgép imsi azonosítója")}
          </li>
          <li>
            <b>pgsn</b>{" "}
            {localize("cashregisters", "A pénztárgép pgsn azonosítója")}
          </li>
          <li>
            <b>szín</b> {localize("cashregisters", "A sor színkódja")}
          </li>
          <li>
            <b>iccid</b>{" "}
            {localize("cashregisters", "A pénztárgép iccid azonosítója")}
          </li>
        </ol>
        <p>
          {localize(
            "cashregisters",
            "Hiba esetén egyetlen egy adat sem kerül be az adatbázisba, valamint a rendszer megjeleníti a hiba leírását."
          )}
        </p>
        <div className="col-12">
          <SimpleFileInput
            name="file_id"
            label={localize("cashregisters", "Fájl")}
            addedFile={this.handleFileChange.bind(this, false)}
            removeFile={this.handleFileChange.bind(this, true)}
            files={[]}
            maxFiles={1}
            isViewPage={isViewPage}
            fieldError={fieldError}
            acceptedFiles=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </div>
      </EditForm>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "stickers",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(CruleForm);

export default connect(
  state => ({
    initialValues: state.eventTypes.viewData
  }),
  null
)(UsersReduxForm);
