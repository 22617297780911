import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../styles/slider/slick-theme.css";
import Avatar from "@material-ui/core/Avatar";
import ReorderIcon from "@material-ui/icons/Reorder";
import UploadButton from "Components/UploadButton";
import Divider from "@material-ui/core/Divider";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import localize from "helpers/localize";

const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

const arrayMove = (array, from, to) => {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};

//const SortableItem = SortableElement(({ value }) => <li>{value}</li>);
const DragHandle = SortableHandle(() => (
  <ReorderIcon color="primary" style={{ marginRight: 8, cursor: "pointer" }} />
)); // This can be any component you want

const SortableItem = SortableElement(
  ({ item, onUpload, onRemove, index, last, onError, onChangePerm }) => (
    <Fragment>
      <ListItem ContainerProps={{ style: { listStyleType: "none" } }}>
        {item ? <DragHandle /> : <div style={{ marginLeft: 32 }} />}
        <ListItemAvatar>
          <Avatar style={item ? {} : { backgroundColor: "transparent" }}>
            {item ? (
              <img
                style={{
                  width: "100%"
                }}
                src={
                  process.env.REACT_APP_HOST +
                  item.download_url +
                  "&accessToken=" +
                  localStorage.getItem("access_token").replace("Bearer ", "")
                }
              />
            ) : (
              <UploadButton
                file={item}
                name=""
                onUpload={onUpload}
                onRemove={id => onRemove(id, index)}
                onError={onError}
                acceptedFiles="image/*|image/png|image/jpeg|image/jpg"
                type="upload"
              />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            item ? item.file_name : localize("dashboard", "Dia hozzáadása")
          }
          secondary={
            item
              ? item.created_at
              : localize(
                  "dashboard",
                  "Kattintson a nagyító iknora a tallózáshoz."
                )
          }
        />
        {item && (
          <ListItemSecondaryAction>
            <FormControlLabel
              label="V"
              onChange={evt =>
                onChangePerm(index, evt.target.checked, "distributor_can_see")
              }
              control={
                <Checkbox checked={item.distributor_can_see} color="primary" />
              }
            />
            <FormControlLabel
              label="F"
              onChange={evt =>
                onChangePerm(index, evt.target.checked, "user_can_see")
              }
              control={<Checkbox checked={item.user_can_see} color="primary" />}
            />
            <UploadButton
              file={item}
              name=""
              onUpload={onUpload}
              onRemove={id => onRemove(id, index)}
              onError={onError}
              acceptedFiles="image/*|image/png|image/jpeg|image/jpg"
              type="upload"
            />
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {!last && <Divider />}
    </Fragment>
  )
);

const SortableList = SortableContainer(
  ({ items, onUpload, onRemove, onError, onChangePerm }) => {
    return (
      <List>
        {items.map((item, index) => (
          <SortableItem
            key={`slide-${item && item.id ? item.id : index}`}
            index={index}
            itemIndex={index}
            item={item}
            onUpload={onUpload}
            onRemove={onRemove}
            onError={onError}
            disabled={item ? false : true}
            last={index === items.length - 1}
            onChangePerm={onChangePerm}
          />
        ))}
      </List>
    );
  }
);

class SliderEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: this.props.slides || [],
      slideError: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.slides) !== JSON.stringify(this.props.slides)
    ) {
      this.setState({ slides: nextProps.slides });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const slides = arrayMove(this.props.slides, oldIndex, newIndex);
    this.setState({ slides });
    this.props.onChange(slides);
  };

  onError = error => {
    this.setState({ slideError: error });
  };

  onChangePerm = (index, checked, type) => {
    let slides = this.state.slides.slice();
    slides[index][type] = checked;
    this.setState({ slides });
    this.props.onChange(slides);
  };

  render() {
    const { cardProps, onUpload, onRemove } = this.props;
    const { slides, slideError } = this.state;
    let slidesWithNew = slides.slice();
    slidesWithNew.push(null);
    return (
      <Card {...cardProps}>
        <CardHeader
          title={localize("dashboard", "Diavetítés szerkesztése")}
          //subheader={"Feltöltve " + slides.length + " dia."}
        />
        <CardContent style={{ paddingTop: 0 }}>
          <SortableList
            useDragHandle={true}
            items={slidesWithNew}
            onSortEnd={this.onSortEnd}
            //lockToContainerEdges={true}
            onUpload={(data, file) => {
              this.setState({ slideError: null });
              onUpload(data, file);
            }}
            onRemove={onRemove}
            onError={this.onError}
            onChangePerm={this.onChangePerm}
          />
          <Typography variant="body2">
            {localize(
              "dashboard",
              "Azonos méretű diák feltöltése ajánlott. Ideális méret: 1920px * 600px"
            )}
          </Typography>
          {slideError && (
            <Typography color={"error"} variant="body2">
              {slideError}
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  }
}

SliderEditor.propTypes = {
  slides: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  cardProps: PropTypes.object
};

export default SliderEditor;
