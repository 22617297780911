/* eslint-disable */
import React, { Component, Fragment } from "react";
import {Field, FieldArray, formValueSelector, reduxForm, getFormValues} from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";
import InputField from "Components/Forms/Fields/Input";
import SelectField from "Components/Forms/Fields/Select";
import Validations from "Components/Forms/Validations";
import EditForm from "Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import Item from "./Item";
import UploadServiceOrder from "../UploadServiceOrder";
import Button from "@material-ui/core/Button/Button";
import ServiceOrders from "Components/ServiceOrders/ServiceOrders";
import SweetAlert from "react-bootstrap-sweetalert";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
//import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import EditClient from "Containers/Pages/Clients/EditClient";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import AddIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import localize from "helpers/localize";

class RenderItems extends Component {

    constructor(props) {
        super(props);
        props.data && props.data.forEach((row, index) => props.fields.push(row));
        if(this.props.fields.length === 0) {
            this.props.fields.push({});
        }
        this.state = {
            confirm: false
        };
    }

    handleNewItem = () => {
        this.props.fields.push({});
    };

    handleDelete = (index) => {
        this.setState({
            confirm: `${index}`
        })
    };

    handleComfirm = () => {
        this.props.fields.remove(this.state.confirm);
        this.handleClose();
    };

    handleClose = () => {
        this.setState({
            confirm: false
        });
    };

    render = () => {
        const itemsLenght = this.props.fields.length;
        return <Fragment>

            <SweetAlert show={this.state.confirm}
                        warning
                        showCancel
                        showLoaderOnConfirm
                        confirmBtnText={localize("basic", "Igen")}
                        cancelButtonText={localize("basic", "Nem")}
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="default"
                        timer={false}
                        title={localize("basic", "Biztosan törölni akarja?")}
                        onCancel={this.handleClose.bind(this)}
                        onConfirm={this.handleComfirm.bind(this)}
            />
            <div className={"table-responsive-material" + (this.props.isViewPage ? " view" : "")}>
                                    <Table padding="checkbox">
                                    <TableHead>
          <TableRow>
            <TableCell>{localize("serviceOrders", "AP-szám")}</TableCell>
            <TableCell>{localize("serviceOrders", "Hálózati azonosító (ICCID)")}</TableCell>
            <TableCell>{localize("serviceOrders", "SIM azonosító (IMSI)")}</TableCell>
            <TableCell>{localize("serviceOrders", "Sorozatszám (PGSN)")}</TableCell>
            <TableCell>
            {(!this.props.isViewPage && this.props.fields.length < 42 && this.props.contractType === 'aeereg') && <Tooltip title={localize("serviceOrders", "Pénztárgép hozzáadása")}>
                  <IconButton style={{width:24, height:24, padding: 0}} onClick={this.handleNewItem.bind(this)} color="primary">
                    <AddIcon />
                </IconButton>
              </Tooltip>}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {this.props.fields.map(
                (cashregister, index) =>
                    <Item
                        cashregister={cashregister}
                        key={'fi-'+index}
                        index={index}
                        itemsLenght={itemsLenght}
                        {...this.props}
                        fieldError={get(this.props,`fieldsError[${index}]`, {})}
                        getRule={this.props.getRule}
                        dispatch={this.props.dispatch}
                        change={this.props.change}
                        handleDelete={this.handleDelete.bind(this)}
                    />
            )}
            </TableBody>
                                    </Table>
                                </div>

            {/*!this.props.isViewPage && this.props.fields.length < 42 && this.props.contractType === 'aeereg' && <div className="col-12">
                <Button onClick={this.handleNewItem.bind(this)} variant="contained" color="secondary">
                    { localize("serviceOrders", "Új pénztárgép")}
                </Button>
                <hr />
            </div>*/}
        </Fragment>
    };
};

class ServiceOrderForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
  };

    constructor(props) {
        super(props);
        this.state = {
            files: {},
            showClientEditModal: false,
            clientData: {},
        };
        this.settingsForm = React.createRef();
        this.handleTaxOnBlur = null;
    }

    /*shouldComponentUpdate(nextProps, nextState) {
      //console.log("shouldComponentUpdate", nextProps, nextState)
      if(JSON.stringify(this.props.currentValues) === JSON.stringify(nextProps.currentValues)) {
        console.log("nincs render")
        return false;
      }
      return true;

    }*/

    submitClientForm = () => {
        const instance = this.settingsForm.current.getWrappedInstance();
        if(instance) {
          instance.submit()
        }
      }

      afterSaveClient = res => {
        this.handleCloseModal();
      }

    handleEditClient = (clientData) => {
        //const {dispatch, change} = this.props;
        //dispatch(change('client_id', null));
        this.setState({
            showClientEditModal: true,
            clientData: clientData
        });
    };

    handleCloseModal = () => {
        //Vissza a service-orders form opciókat
        this.props.getFormInfo('service-orders').then(response => {
            if(this.handleTaxOnBlur) {
                this.handleTaxOnBlur(null, this.state.clientData.client_tax_number)
            }
            this.setState({
                showClientEditModal: false
            });
        });



    };

    editFiles = (name, remove, file) => {
        let files = this.state.files;
        if( !files[name] ) {
            files[name] = [];
        }

        if(!remove) {
            files[name].push(file.id);
        }
        else {
            let index = files[name].indexOf(file.id);
            if(index !== -1 ) {
                delete files[name][index];
            }
        }
        this.setState({
            files: files
        });
        return files[name];
    };

    handleImageChange = (name, remove, files) => {
        const {dispatch, change} = this.props;
        // let files = this.editFiles(name, remove, file);
        dispatch(change(name, files.map(file => file.id)));
    };

    handleGetClientFormInfo = () => {
        return this.props.getFormInfo('clients');
    }

  render() {
    const {
        handleInputChange,
        fieldErrors,
        isViewPage,
        isCreatePage,
        isEditPage,
        enabledFields,
        dispatch,
        change
    } = this.props;
      let persons = [];
      Object.keys(get(this.props, 'baseFields.order_person_title.data', {})).forEach(key => {
          persons.push({id: key, label: this.props.baseFields.order_person_title.data[key]});
      });
      let cards = [];
      Object.keys(get(this.props, 'baseFields.order_person_id_card_type.data', {})).forEach(key => {
          cards.push({id: key, label: this.props.baseFields.order_person_id_card_type.data[key]});
      });
      let contracts = [];
      Object.keys(get(this.props, 'baseFields.order_contract_type.data', {})).forEach(key => {
          contracts.push({id: key, label: this.props.baseFields.order_contract_type.data[key]});
      });

    //MEghekkeljük a match paramétert, hogy a modal form lekérdezze az adatokat
    const clientMatch = Object.assign({}, this.props.match, {
        params:{
            id: this.state.clientData.client_id
        },
        path: "/clients/edit/" + this.state.clientData.client_id,
        url: "/clients/edit/" + this.state.clientData.client_id,
    })
    return (
        <Fragment>
        <Dialog open={this.state.showClientEditModal} maxWidth="md" onClose={this.handleCloseModal.bind(this)} scroll="body">
              {/*<DialogTitle>
                  {this.state.selectedClient.client_name + " " + localize("settings", "szerkesztése")}
              </DialogTitle>*/}
              <DialogContent style={{paddingBottom:0, overflow: "hidden"}}>
                  <EditClient
                  //{...this.props}
                  disableButtons={true}
                  ref={this.settingsForm}
                  match={clientMatch}
                  handleGetFormInfo={this.handleGetClientFormInfo}
                  history={this.props.history}
                  afterSave={this.afterSaveClient}
                  />
              </DialogContent>
              <DialogActions style={{padding:16, paddingTop:0, allignItems: "flex-end"}}>
                  <Button style={{marginRight: 8}} onClick={this.handleCloseModal.bind(this)}  variant="contained" color="secondary" component="span">
                      {localize("settings", "Bezár")}
                  </Button>
                  <Button onClick={this.submitClientForm} variant="contained" color="primary" component="span">
                      {localize("settings", "Mentés")}
                  </Button>
              </DialogActions>
          </Dialog>
        <EditForm {...this.props} disabledEditButton={true}>
            {(isCreatePage || isViewPage) && <Fragment>
                <Fragment>
                    <ServiceOrders

                        {...this.props}
                        showClientLocation={false}
                        handleEditClient={this.handleEditClient}
                        handleTaxOnBlur={handle => this.handleTaxOnBlur = handle}
                        regNoRequired={true}
                        showBillingAddress={true}
                    />
                    <Fragment>
                        <Grid item xs={12}>
        <h6 className="form-legend">{localize("serviceOrders", "Szerződő személy")}</h6>
        <Grid container spacing={16}>
                        <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                            <Field
                                name="order_person_name"
                                label={localize("serviceOrders", "Szerződő személy neve")}
                                labelInfo={localize("serviceOrders", "A cég nevében aláírásra jogosult személy")}
                                fieldError={fieldErrors.order_person_name}
                                onChange={() => handleInputChange('order_person_name')}
                                isRequired={true} validate={[Validations.required]}
                                component={InputField}
                                type="text"
                                placeholder={localize("serviceOrders", "Szerződő személy neve")}
                                isViewPage={isViewPage}
                                readOnly={enabledFields ? enabledFields.indexOf('order_person_name') !== -1 ? false : true : false}
                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                            <Field
                                name="order_person_title"
                                label={localize("serviceOrders", "Szerződő státusza")}
                                labelInfo={localize("serviceOrders", "Az előző mezőben feltüntetett aláírásra jogosult személy jogi státusza")}
                                fieldError={fieldErrors.order_person_title}
                                onChange={() => handleInputChange('order_person_title')}
                                component={SelectField}
                                placeholder={localize("articles", "Szerződő státusza")}
                                isRequired={true} validate={[Validations.required]}
                                isViewPage={isViewPage}
                                options={persons}
                                readOnly={enabledFields ? enabledFields.indexOf('order_person_title') !== -1 ? false : true : false}
                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                            <Field
                                name="order_person_id_card_type"
                                label={localize("serviceOrders", "Azonosító okmány tipusa")}
                                fieldError={fieldErrors.order_person_id_card_type}
                                onChange={() => handleInputChange('order_person_id_card_type')}
                                component={SelectField}
                                placeholder={localize("serviceOrders", "Azonosító okmány tipusa")}
                                isRequired={true} validate={[Validations.required]}
                                isViewPage={isViewPage}
                                options={cards}
                                readOnly={enabledFields ? enabledFields.indexOf('order_person_id_card_type') !== -1 ? false : true : false}
                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                            <Field
                                name="order_person_id_card"
                                label={localize("serviceOrders", "Azonosító okmány száma")}
                                fieldError={fieldErrors.order_person_id_card}
                                onChange={() => handleInputChange('order_person_id_card')}
                                isRequired={true} validate={[Validations.required]}
                                component={InputField}
                                type="text"
                                placeholder={localize("serviceOrders", "Azonosító okmány száma")}
                                isViewPage={isViewPage}
                                readOnly={enabledFields ? enabledFields.indexOf('order_person_id_card') !== -1 ? false : true : false}
                            />
                        </Grid>
                        </Grid>
                        </Grid>
                    </Fragment>
                    {/*<Fragment>
                        <div className="col-12 mb-0 mt-2"><h2>{localize("serviceOrders", "Kapcsolattartó")}</h2></div>
                        <div className="clearfix" />
                        <div className="col-12 col-md-4">
                            <Field
                                name="contact_name"
                                label={localize("serviceOrders", "Kapcsolattartó neve")}
                                fieldError={fieldErrors.contact_name}
                                onChange={() => handleInputChange('contact_name')}
                                component={InputField}
                                type="text"
                                placeholder={localize("serviceOrders", "Kapcsolattartó neve")}
                                isViewPage={isViewPage}
                                readOnly={enabledFields ? enabledFields.indexOf('contact_name') !== -1 ? false : true : false}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <Field
                                name="contact_phone"
                                label={localize("serviceOrders", "Kapcsolattartó telefonszáma")}
                                fieldError={fieldErrors.contact_phone}
                                onChange={() => handleInputChange('contact_phone')}
                                component={InputField}
                                type="text"
                                placeholder={localize("serviceOrders", "Kapcsolattartó telefonszáma")}
                                isViewPage={isViewPage}
                                readOnly={enabledFields ? enabledFields.indexOf('contact_phone') !== -1 ? false : true : false}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <Field
                                name="contact_email"
                                label={localize("serviceOrders", "Ügyfél email címe")}
                                fieldError={fieldErrors.contact_email}
                                onChange={() => handleInputChange('contact_email')}
                                component={InputField}
                                type="text"
                                placeholder={localize("serviceOrders", "Ügyfél email címe")}
                                isViewPage={isViewPage}
                                readOnly={enabledFields ? enabledFields.indexOf('contact_email') !== -1 ? false : true : false}
                            />
                        </div>
                    </Fragment>*/}
                    <Fragment>
                        {/*<div className="col-12 mb-0 mt-2"><h2>{localize("serviceOrders", "Csomag adatok")}</h2></div>
                        <div className="clearfix" />*/}

                        <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                            <Field
                                name="order_contract_type"
                                label={localize("serviceOrders", "Szerződés típusa")}
                                fieldError={fieldErrors.order_contract_type}
                                onChange={() => handleInputChange('order_contract_type')}
                                component={SelectField}
                                placeholder={localize("serviceOrders", "Szerződés típusa")}
                                isRequired={true} validate={[Validations.required]}
                                isViewPage={isViewPage}
                                options={contracts}
                                readOnly={enabledFields ? enabledFields.indexOf('order_contract_type') !== -1 ? false : true : false}
                            />
                        </Grid>
                    </Fragment>
                    <Fragment>

                        <div className="col-12 mb-0 mt-2" style={{marginLeft: -4}}><h6 className="form-legend">{localize("serviceOrders", "Pénztárgépek")}</h6></div>
                        {/*<div className="clearfix" />*/}
                        <Grid container spacing={16} style={{margin:0, paddingLeft: isViewPage ? 16 : 0, paddingRight: isViewPage ? 16 : 0}} >

                                        <FieldArray
                                          name="cashregisters"
                                          component={RenderItems}
                                          fieldsError={fieldErrors.cashregisters}
                                          isViewPage={isViewPage}
                                                                                  data={get(this.props, 'initialValues.cashregisters', [])}
                                          //change={this.props.change}
                                          //dispatch={this.props.dispatch}
                                          initialValues={this.props.initialValues}
                                          getRule={this.props.getRule.bind(this)}
                                          dispatch={dispatch}
                                          change={change}
                                          contractType={this.props.contractType}
                                      />

                        </Grid>

                    </Fragment>
                </Fragment>
            </Fragment>}
            {(isEditPage || isViewPage) && <Fragment>
              <Grid item xs={12} style={{marginTop:16, marginBottom: -16}}><h6 className="form-legend">{localize("serviceOrders", "Dokumentumnok")}</h6></Grid>
              <Grid item xs={12}><UploadServiceOrder {...this.props} isView={true} /></Grid>
            </Fragment>}
        </EditForm>
        </Fragment>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: 'serviceOrders',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(ServiceOrderForm);

const selector = formValueSelector('serviceOrders');

export default connect(
    state => {
        const contractType = selector(state, 'order_contract_type');
        return {
            initialValues: state.serviceOrders.viewData,
            //formValues: getFormValues("serviceOrders")(state) || {},
            contractType
        }
    }
)(UsersReduxForm);

