import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "Components/New/Input/TextField/TextField";
import localize from "helpers/localize";
import SendParamsEditor from "./SendParamsEditor";
import { IconButton, Toolbar, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
const LC = "emailtemplates"; //Localization category

class SendParamsEditorList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      parameters,
      emails,
      setParam,
      setTo,
      addEmail,
      removeEmail,
      template_id,
      errors
    } = this.props;
    return template_id ? (
      <div style={{ width: "100%", position: "relative" }}>
        <Toolbar disableGutters>
          <Typography variant="subtitle1">
            {localize(LC, emails.length > 1 ? "Címzettek" : "Címzett")}
          </Typography>
          <div style={{ marginLeft: "auto" }} />
          {template_id && (
            <IconButton color="primary" onClick={addEmail}>
              <AddIcon />
            </IconButton>
          )}
        </Toolbar>
        {emails.map((email, index) => {
          return (
            <SendParamsEditor
              key={`parameter-editor.${index}`}
              email={email}
              count={emails.length}
              parameters={parameters}
              setParam={setParam(index)}
              setTo={setTo(index)}
              removeEmail={removeEmail(index)}
              errors={(errors && errors[index]) || {}}
            />
          );
        })}
      </div>
    ) : null;
  }
}

SendParamsEditorList.defaultProps = {};
SendParamsEditorList.propTypes = {
  parameters: PropTypes.array,
  emails: PropTypes.array,
  errors: PropTypes.array,
  addEmail: PropTypes.func.isRequired,
  removeEmail: PropTypes.func.isRequired,
  setParam: PropTypes.func.isRequired,
  setTo: PropTypes.func.isRequired,
  template_id: PropTypes.number
};

export default SendParamsEditorList;
