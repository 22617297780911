import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EditCashregister from "./views/EditCashregister";
import * as cashregistersActions from "redux/modules/cashregisters";
import * as optionsActions from "redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.cashregisters.loading,
    success: state.cashregisters.success,
    saved: state.cashregisters.saved,
    message: state.cashregisters.message,
    fieldErrors: state.cashregisters.formErrors,
    id: state.cashregisters.id,
    viewData: state.cashregisters.viewData,
    reducerName: "cashregisters",
    events: state.cashregisters.events,
    history_data: state.cashregisters.history_data,
    history_loading: state.cashregisters.history_loading,
    history_failed: state.cashregisters.history_failed,
    history_message: state.cashregisters.history_message,
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields,

    SMSloading: state.cashregisters.sms_loading,
    SMSsuccess: state.cashregisters.sms_success,
    SMSsaved: state.cashregisters.sms_saved,
    SMSmessage: state.cashregisters.sms_message,
    SMSfieldErrors: state.cashregisters.sms_formErrors,

    Statusloading: state.cashregisters.status_loading,
    Statussuccess: state.cashregisters.status_success,
    Statussaved: state.cashregisters.status_saved,
    Statusmessage: state.cashregisters.status_message,
    StatusfieldErrors: state.cashregisters.status_formErrors,

    Reportloading: state.cashregisters.report_loading,
    Reportsuccess: state.cashregisters.report_success,
    Reportsaved: state.cashregisters.report_saved,
    Reportmessage: state.cashregisters.report_message,
    ReportfieldErrors: state.cashregisters.report_formErrors,

    reregisters: state.cashregisters.reregisters,
    reregisters_loading: state.cashregisters.reregisters_loading,
    reregisters_success: state.cashregisters.reregisters_success,
    reregisters_message: state.cashregisters.reregisters_message,

    user: state.auth.user,
    cashregistersStatusConf: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: cashregistersActions.createCashregistersManual,
      update: cashregistersActions.updateCashregisters,
      view: cashregistersActions.viewCashregisters,
      reset: cashregistersActions.reset,
      resetForm: cashregistersActions.resetForm,
      getCustomInfo: optionsActions.getCustomInfo,
      sendSms: cashregistersActions.sendSms,
      setStatus: cashregistersActions.setStatus,
      setSuspended: cashregistersActions.setSuspended,
      sendReport: cashregistersActions.sendReport,
      historyList: cashregistersActions.historyList,
      listCashregisterReregisters:
        cashregistersActions.listCashregisterReregisters
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCashregister);
