import axios from 'axios';
import qs from 'qs';
import { get } from 'lodash';

export const DISTRIBUTOR_CHANGE_LOAD = 'DISTRIBUTOR_CHANGE_LOAD';
export const DISTRIBUTOR_CHANGE_SUCCESS = 'DISTRIBUTOR_CHANGE_SUCCESS';
export const DISTRIBUTOR_CHANGE_FAILED = 'DISTRIBUTOR_CHANGE_FAILED';

const initialState = {
  profileLoading: false,
  loading: false,
  failed: false,
  success: false,
  errors: null,
  message: false,
  saved: false,
  formErrors: {},
  token: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISTRIBUTOR_CHANGE_LOAD:
      return {
          ...state,
        loading: true,
        profileLoading: false,
        failed: false,
        success: false,
        errors: null,
        message: false,
        saved: false,
        formErrors: {}
      };
    

    case DISTRIBUTOR_CHANGE_SUCCESS:
      return {
        ...state,
        success: true,
        saved: true,
        loading: false,
        profileLoading: false
      };

    case DISTRIBUTOR_CHANGE_FAILED:
      return {
        ...state,
        loading: false,
        message: action.message,
        formErrors: action.formErrors,
        failed: true,
        profileLoading: false
      };
    default:
      return state;
  }
};



export const changeDistributor = (data) => dispatch => {
  dispatch({
    type: DISTRIBUTOR_CHANGE_LOAD
  });

  return axios.post(
      '/v1/user/distributor',
      qs.stringify({Distributor: data}),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
  ).then(response => {
      return dispatch({
          type: DISTRIBUTOR_CHANGE_SUCCESS
      })
  }).catch(error => {
    return dispatch({
      type: DISTRIBUTOR_CHANGE_FAILED,
      message: get(error, 'response.data.message', null),
      formErrors: get(error, 'response.data.form_errors', {})
    });
  });
};