import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditUser from "./views/EditUser";
import * as usersActions from "redux/modules/users";
import * as optionsActions from "redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.users.loading,
    success: state.users.success,
    saved: state.users.saved,
    message: state.users.message,
    fieldErrors: state.users.formErrors,
    access: state.users.access,
    userAccess: state.users.userAccess,
    id: state.users.id,
    viewData: state.users.viewData || {},
    reducerName: "users",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: usersActions.createUser,
      update: usersActions.updateUser,
      view: usersActions.getUserProfile,
      reset: usersActions.reset,
      resetForm: usersActions.resetForm,
      userEditApproval: usersActions.userEditApproval,
      getFormInfo: optionsActions.getFormInfo
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUser);
