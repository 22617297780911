import React, { Fragment, PureComponent } from "react";
import { Helmet } from "react-helmet";
import List from "Components/List/List";
import ContainerHeader from "Components/ContainerHeader";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import FileDownload from "@material-ui/icons/Description";
import FileUpload from "@material-ui/icons/CloudUpload";
import Done from "@material-ui/icons/Done";
import PendingIcon from "@material-ui/icons/AccessTime";
import Error from "@material-ui/icons/Error";
import Button from "@material-ui/core/Button/Button";
import FeedbackServiceOrder from "./FeedbackServiceOrder";
import { get } from "lodash";
import ModifyPermission from "Containers/Permissions/ModifyPermission";
import FuncPermission from "Containers/Permissions/FuncPermission";
import ModalConfirm from "Components/Modal/ModalConfirm";
import UploadButton from "Components/UploadButton";
import {
  bulkFeedback,
  submitFeedbackWrong,
  submitFeedbackOk,
  submitNotArrived,
  feedback,
  feedbackFields,
  submitFeedbackPending
} from "redux/modules/service-orders";
import authRules from "helpers/authRules";
import localize from "helpers/localize";

class ServiceOrdersList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      importFile: null,
      importError: null,
      feedbackItem: null,
      feedbackError: null,
      time: new Date().getTime()
    };
    this.modal = React.createRef();
    this.modalFeedback = React.createRef();
    this.modalFeedbackComp = React.createRef();
  }

  customIcons = obj => {
    return (
      <Fragment>
        {obj.status === "pending" && (
          <Tooltip
            disableFocusListener
            title={localize("serviceOrders", "Jóváhagyás")}
          >
            <Button
              className="jr-btn jr-btn-xs text-center"
              component="span"
              onClick={() => this.openFeedback(obj)}
            >
              <PendingIcon style={{ fill: "#e20074" }} />
            </Button>
          </Tooltip>
        )}
        {obj.status === "insufficient" && (
          <Button
            className="jr-btn jr-btn-xs text-center"
            component="span"
            onClick={() => this.openFeedback(obj)}
          >
            <span className="remaning">{obj.remaining_time}</span>
          </Button>
        )}
        {obj.status === "okay" && (
          <Button
            className="jr-btn jr-btn-xs"
            component="span"
            onClick={() => this.openFeedback(obj)}
          >
            <Done style={{ fill: "green" }} />
          </Button>
        )}
        {obj.status === "wrong" && (
          <Button
            className="jr-btn jr-btn-xs"
            component="span"
            onClick={() => this.openFeedback(obj)}
          >
            <Error style={{ fill: "#e20074" }} />
          </Button>
        )}
        {obj.status === "timeout" && (
          <Button
            className="jr-btn jr-btn-xs"
            component="span"
            onClick={() => this.openFeedback(obj)}
          >
            <Error />
          </Button>
        )}
        {obj.status === "not_arrived" && (
          <Button
            className="jr-btn jr-btn-xs not-arrived"
            component="div"
            onClick={() => this.openFeedback(obj)}
          >
            <Error style={{ fill: "#f44336" }} />
          </Button>
        )}
        <Tooltip title={localize("serviceOrders", "Letöltés")}>
          <Button
            className="jr-btn jr-btn-xs"
            target="_blank"
            href={
              process.env.REACT_APP_HOST +
              "/v1/service-orders/contract/" +
              obj.id +
              "?accessToken=" +
              localStorage.getItem("access_token").replace("Bearer ", "")
            }
            component="a"
          >
            <FileDownload />
          </Button>
        </Tooltip>
        <ModifyPermission
          isOwn={get(obj, "is_owner", false)}
          //match={this.props.match}
          module={this.props.module}
        >
          <Tooltip title={localize("serviceOrders", "Feltöltés")}>
            <Button
              className="jr-btn jr-btn-xs"
              disabled={
                [
                  "timeout",
                  "insufficient",
                  "wrong" /*, "not_arrived"*/
                ].indexOf(obj.status) < 0 &&
                !authRules.hasPermissionByKeyAndName(
                  "service-orders",
                  "service-orders.forcedsend"
                )
              }
              onClick={() =>
                this.props.history.push(
                  this.props.location.pathname + "/upload/" + obj.id
                )
              }
              component="span"
            >
              <FileUpload />
            </Button>
          </Tooltip>
        </ModifyPermission>
      </Fragment>
    );
  };

  onError = error => {
    //console.log("onError", error);
    this.setState({ importError: error });
  };

  /**
   * Csatol egy feltöltött dokumentumot
   */
  onUpload = file => {
    //console.log("onUpload", file);
    this.setState({
      importFile: file,
      importError: null
    });
    this.modal.error(null);
  };

  /**
   * Eltávolít egy csatolt dokumentumot
   */
  onRemove = type => {
    //console.log("onRemove", type);
    /*let documents = Object.assign({}, this.state.documents);
    documents[type].current = [];*/
    this.setState({
      importFile: null
    });
  };

  onAccept = () => {
    if (!this.state.importFile) {
      this.modal.error("Nincs fájl kiválasztva");
      return;
    }
    bulkFeedback({
      file_id: this.state.importFile.id
    })
      .then(res => {
        //console.log("bulkFeedback", res)
        this.modal.loading(false);
        this.modal.close();
        this.setState({
          importFile: null
        });
      })
      .catch(error => {
        //console.log("error", error.response);
        let errorMessage = this.handleImportError(error, "file_id");
        this.modal.loading(false);
        this.modal.error(errorMessage);
      });
  };

  handleImportError = (error, fieldName) => {
    let errors = get(error, `response.data.form_errors.${fieldName}`, []);
    //errors = ["rossz", "Mégrosszabb", "nagyon rossz"];
    if (errors.length === 0) {
      return get(
        error,
        "response.data.message",
        localize("basic", "Váratlan hiba")
      );
    }
    if (errors.length === 1) {
      return errors[0];
    }
    return (
      <div>
        Hibák:
        <br />
        <textarea style={{ width: "100%", height: 40 }}>
          {errors.join("\n")}
        </textarea>
      </div>
    );
  };

  onFeedback = () => {
    const {
      isValid,
      wrongCause,
      documents,
      warning
    } = this.modalFeedbackComp.state;
    if (isValid === null) {
      //Valamit ki kell tölteni
      this.modalFeedbackComp.setError(
        localize("serviceOrders", "Válasszon az opciók közül.")
      );
    }
    // Megfelel vagy nem érkezett be
    else if (isValid === "1") {
      submitFeedbackOk(this.state.feedbackItem.id)
        .then(res => {
          this.modalFeedback.close();
          this.setState({ time: new Date().getTime() });
        })
        .catch(error => {
          this.modalFeedbackComp.setError(error.response.data.message);
          this.modalFeedback.close();
        });
    } else if (isValid === "2") {
      submitNotArrived(this.state.feedbackItem.id, warning)
        .then(res => {
          //console.log("submitFeedbackWrong", res)
          this.modalFeedback.close();
          this.setState({ time: new Date().getTime() });
        })
        .catch(error => {
          this.modalFeedbackComp.setError(error.response.data.message);
        });
    } else if (isValid === "-1") {
      submitFeedbackPending(this.state.feedbackItem.id, warning)
        .then(res => {
          //console.log("submitFeedbackWrong", res)
          this.modalFeedback.close();
          this.setState({ time: new Date().getTime() });
        })
        .catch(error => {
          this.modalFeedbackComp.setError(error.response.data.message);
        });
    } else {
      let hasErrorMark = false;
      let askFors = [];
      Object.keys(documents).forEach(key => {
        if (documents[key] === true) {
          hasErrorMark = true;
          askFors.push(key);
        }
      });

      //if (hasErrorMark && wrongCause) {
      if (true) {
        //Király megy a szerverre
        submitFeedbackWrong(this.state.feedbackItem.id, askFors, wrongCause)
          .then(res => {
            //console.log("submitFeedbackWrong", res)
            this.modalFeedback.close();
            this.setState({ time: new Date().getTime() });
          })
          .catch(error => {
            //console.log("submitFeedbackWrongError", error.response)
            this.modalFeedbackComp.setError(error.response.data.message);
          });
      } else {
        //Error jelzés a usernek
        this.modalFeedbackComp.setError(
          "Válasszon dokumentumot és írja le az indoklást."
        );
      }
    }
  };

  openImport = () => {
    this.modal.open(
      localize("serviceOrders", "Szerződések frissítése"),
      //"Telekomos lista fájl leírása",
      null,
      null, //this.state.file.file_name,
      localize("serviceOrders", "Feldolgozás"),
      localize("basic", "Mégsem")
    );
    /*.then(() => {
        if(!this.state.importFile) {
          this.modal.error("Nincs fájl kiválasztva")
          return;
        }
        //this.modal.loading(true);
        bulkFeedback({
          file_id: this.state.importFile.id
        }).then(res => {
          //console.log("bulkFeedback", res)
          this.modal.loading(false);
          this.modal.close();
          this.setState({
            importFile: null
          });
        })*/
    /*this.props.fileDelete(this.state.file.id).then(res => {
          if (this.props.onRemove) {
            this.props.onRemove(res.data);
          }
        });*/
    // });
  };

  openFeedback = obj => {
    //console.log(obj);
    if (!this.modalFeedbackComp.setState) {
      return;
    }
    this.modalFeedbackComp.setState({
      documents: {
        AUTHORIZATION: null,
        CONTRACT_PAGE_1_5: null,
        FRAMEWORK_CONTRACT: null,
        SIGNATURE_SPECIMEN: null
      },
      wrongCause: null,
      error: null,
      isValid: null
    });
    this.setState({ feedbackItem: obj });

    feedbackFields(obj.id).then(fieldRes => {
      //console.log("fieldRes", fieldRes);
      if (obj.status === "pending") {
        this.modalFeedbackComp.setState({
          documentTypes: fieldRes.data.data.feedback
        });
        this.modalFeedback.open(
          localize("serviceOrders", "Jóváhagyás"),
          null,
          null,
          localize("serviceOrders", "Rögzítés"),
          localize("basic", "Mégsem")
        );
        return;
      }

      feedback(obj.id).then(feedbackRes => {
        //console.log("feedbackRes", feedbackRes.data.data);
        let documents = {
          AUTHORIZATION: null,
          CONTRACT_PAGE_1_5: null,
          FRAMEWORK_CONTRACT: null,
          SIGNATURE_SPECIMEN: null
        };
        feedbackRes.data.data.wrong_ask_for.forEach(element => {
          documents[element] = true;
        });
        this.modalFeedbackComp.setState({
          documentTypes: fieldRes.data.data.feedback,
          wrongCause: feedbackRes.data.data.wrong_cause,
          //isValid: feedbackRes.data.data.status === "okay" ? "1" : "0",
          error: null,
          documents: documents
        });
        this.modalFeedback.open(
          "Jóváhagyás",
          null,
          null,
          localize("serviceOrders", "Rögzítés"),
          localize("basic", "Mégsem")
        );
      });
    });

    /*
    feedback(obj.id).then(feedbackRes => {
      console.log("feedbackRes", feedbackRes.data.data);
      this.modalFeedbackComp.setState({
        wrongCause: feedbackRes.data.data.wrong_cause,
        isValid: feedbackRes.data.data.status === "okay" ? "1" : "0"
      });
    });*/
  };

  onCloseFeedback = () => {
    this.setState({ feedbackItem: null, feedbackError: null });
  };

  customButtons = () => {
    return (
      <Fragment>
        <FuncPermission
          module={this.props.module}
          permission="service-orders.feedback"
        >
          <div className="col-auto mr-auto">
            <Button
              //variant="contained"
              color="primary"
              onClick={this.openImport}
            >
              {localize("cashregisters", "Telekom lista feldolgozás")}
            </Button>
          </div>
        </FuncPermission>
      </Fragment>
    );
  };

  render() {
    const { feedbackItem, feedbackError } = this.state;
    //console.log(this.props);
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("serviceOrders", "Szerződések")}</title>
        </Helmet>
        <ContainerHeader title={localize("serviceOrders", "Szerződések")} />
        <ModalConfirm
          onRef={ref => (this.modal = ref)}
          disableIcon
          onAccept={this.onAccept}
          modalBodyComp={
            <div>
              <div>
                Feltöltés előtt:
                <br />
                Beszúrandó oszlop neve: Hiánypótlás, értékei az 1,2,3 illetve 4
                számok vesszővel elválasztva. (Ha nincs szükség hiánypótlásra,
                akkor a mezőt üresen kell hagyni.)
                <br />
                <ol style={{ textAlign: "left" }}>
                  <li>Szerződés hiba</li>
                  <li>Meghatalmazás hiba</li>
                  <li>Aláírási címpéldány hiba</li>
                  <li>Keretszerződés hiba</li>
                </ol>
                Válassza ki a telekomos csv-t a tallózás gomb segítségével, majd
                nyomja meg a feldolgozás gombot.
              </div>
              {this.state.importFile
                ? this.state.importFile.file_name
                : "Dokumentum tallózása"}
              <UploadButton
                name={"import_file"}
                label={""}
                onUpload={this.onUpload}
                onRemove={this.onRemove}
                file={this.state.importFile}
                onError={this.onError}
                disableDelete={false}
                acceptedFiles=".csv"
                type="upload"
              />
              {this.state.importError && <div>{this.state.importError}</div>}
            </div>
          }
        />
        <FuncPermission
          module={this.props.module}
          permission="service-orders.feedback"
        >
          <ModalConfirm
            onRef={ref => (this.modalFeedback = ref)}
            disableIcon
            onAccept={this.onFeedback}
            onClose={this.onCloseFeedback}
            modalBodyComp={
              <FeedbackServiceOrder
                innerRef={node => (this.modalFeedbackComp = node)}
                feedbackItem={feedbackItem}
                feedbackError={feedbackError}
              />
            }
          />
        </FuncPermission>
        <List
          key={this.state.time}
          {...this.props}
          customIcons={this.customIcons.bind(this)}
          actionColumnWidth={220}
          rowFormat={item => {
            if (item.status === "insufficient") {
              return {
                backgroundColor: "#fbe9e7"
              };
            }
            if (item.status === "okay") {
              return {
                backgroundColor: "#e8f5e9"
              };
            }
            if (item.status === "not_arrived") {
              return {
                backgroundColor: "#ffccbc"
              };
            }
            if (item.status === "wrong") {
              return {
                backgroundColor: "#ef9a9a"
              };
            }
            if (item.status === "timeout") {
              return {
                backgroundColor: "#fff9c4"
              };
            }
          }}
          disableEditIcon={true}
          customButtons={this.customButtons}
        />
      </div>
    );
  }
}

export default ServiceOrdersList;
