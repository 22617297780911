import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";


import List from "../../../../../Components/List/List";
import ContainerHeader from "../../../../../Components/ContainerHeader";
import Navbar from "../../Navbar";
import localize from "helpers/localize";
class EventTypesList extends PureComponent {
  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("cashregisters", "Esemény tipusok")}</title>
        </Helmet>
        <Navbar {...this.props} />
        <ContainerHeader title={localize("cashregisters", "Esemény tipusok")} />

        <List {...this.props} />
      </div>
    );
  }
}

export default EventTypesList;
