import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Typography,
  Toolbar,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import ContinueIcon from "@material-ui/icons/ChevronRight";
import BackIcon from "@material-ui/icons/ChevronLeft";
import { Link } from "react-router-dom";
import TextField from "Components/New/Input/TextField/TextField";
import Select from "Components/New/Input/Select/Select";
import localize from "helpers/localize";
/*import Tags from "Components/New/Input/Tags/Tags";
import Wysiwyg from "Components/New/Input/Wysiwyg/Wysiwyg";
import Image from "Components/New/Input/Image/Image";
import formatHelper from "helpers/formatHelper";*/

const styles = theme => ({});
const SetRentDataFrom = ({
  fieldProps,
  onChange,
  onItemChange,
  editItem,
  onSave,
  changeStep
}) => {
  return (
    <Fragment>
      <Grid item xs={12}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("name")} fullWidth />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Toolbar disableGutters>
          <Button
            color="primary"
            //variant="contained"
            onClick={onSave}
            style={{ marginLeft: "auto" }}
          >
            {localize("basic", "Mentés")}
          </Button>
        </Toolbar>
      </Grid>
    </Fragment>
  );
};
SetRentDataFrom.defaultProps = {};

SetRentDataFrom.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  fieldProps: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  changeStep: PropTypes.func,
  editItem: PropTypes.object
};

export default withStyles(styles, { withTheme: true })(SetRentDataFrom);
