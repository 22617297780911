import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import withRouter from "react-router/es/withRouter";
import Grid from "@material-ui/core/Grid";

import localize from "helpers/localize";

class DefaultFormButtons extends Component {
  goBack = () => {
    if (this.props.handleBack) {
      this.props.handleBack();
    } else {
      //this.props.history.goBack();
      //return;

      if (this.props.location.pathname.indexOf("/view") !== -1) {
        this.props.history.goBack();
        /*this.props.history.push(
          this.props.location.pathname.split("/view").shift()
        );*/
      } else if (this.props.location.pathname.indexOf("/edit") !== -1) {
        this.props.history.goBack();
        /*this.props.history.push(
          this.props.location.pathname.split("/edit").shift()
        );*/
      } else if (this.props.location.pathname.indexOf("/create") !== -1) {
        this.props.history.goBack();
        /*this.props.history.push(
          this.props.location.pathname.split("/create").shift()
        );*/
      }
    }
  };

  render() {
    const {
      pristine,
      submitting,
      isViewPage,
      isModalMode,
      loading,
      enabledButton,
      disableCancel,
      disableViewButton,
      disableSaveButton,
      texts,
      formInstance
    } = this.props;

    return (
      <Grid container>
        {!this.props.loading && (
          <Fragment>
            <Grid container item xs={12} justify="flex-end">
              {this.props.renderCustomButtons &&
                this.props.renderCustomButtons(formInstance)}
              {!disableCancel && !isViewPage && (
                <Button
                  style={{ marginLeft: 8 }}
                  size="small"
                  onClick={this.goBack.bind(this)}
                >
                  {!isViewPage
                    ? localize("basic", "Mégsem")
                    : localize("basic", "Vissza")}
                </Button>
              )}
              {!disableCancel &&
                isViewPage &&
                !isModalMode &&
                !disableViewButton && (
                  <Button
                    style={{ marginLeft: 8 }}
                    size="small"
                    onClick={this.goBack.bind(this)}
                  >
                    {!isViewPage
                      ? localize("basic", "Mégsem")
                      : localize("basic", "Vissza")}
                  </Button>
                )}
              {isViewPage && !this.props.disabledEditButton && (
                <Button
                  style={{ marginLeft: 8 }}
                  size="small"
                  color="primary"
                  onClick={() => {
                    this.props.history.push(
                      this.props.location.pathname.replace("view", "edit")
                    );
                  }}
                >
                  {localize("basic", "Szerkesztés")}
                </Button>
              )}
              {!isViewPage && !disableSaveButton && (
                <Button
                  type="submit"
                  size="small"
                  color="primary"
                  style={{ marginLeft: 8 }}
                  /*className="jr-fab-btn"*/ aria-label="add"
                  disabled={enabledButton ? false : pristine || submitting}
                >
                  {texts.saveButton
                    ? texts.saveButton
                    : localize("basic", "Mentés")}
                </Button>
              )}
            </Grid>
            {/*<div className="col-auto">
                {this.props.renderCustomButtons && this.props.renderCustomButtons()}
                  </div>*/}
          </Fragment>
        )}
        {loading && (
          <div className="text-center mt-2">
            <CircularProgress />
          </div>
        )}
      </Grid>
    );
  }
}

DefaultFormButtons.defaultProps = {
  texts: {}
};

export default withRouter(DefaultFormButtons);
