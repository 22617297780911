import React, {Fragment} from 'react';

import AeeRegForm from './AeeRegForm';
import EditPage from '../../../../../Components/Extends/EditPage';
import Edit from '../../../../../Components/Edit/Edit';

class EditAeeReg extends EditPage {

    handleGetFormInfo = () => {
        return this.props.getFormInfo('cashregisters');
    };

    createSavedRedirect = (id) => {
        this.props.forceRefreshList();
    };

  render() {
    return (
        <Fragment>
            <Edit
                createSavedRedirect={this.createSavedRedirect.bind(this)}
                handleGetFormInfo={this.handleGetFormInfo.bind(this)}
                //getPagetitle={false}
                isViewPage={false}
                {...this.props}
            >
                <AeeRegForm {...this.props} isViewPage={this.isViewPage()} />
            </Edit>
        </Fragment>

    );
  }

}

export default EditAeeReg;