import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ReregList from "./views/ReregList";
import * as eventsActions from "../../../../redux/modules/events";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.events.loading,
    success: state.events.success,
    message: state.events.message,
    data: state.events.data,
    total: state.events.total,
    limit: state.events.limit,
    page: state.events.page,
    order: state.events.order,
    orderMode: state.events.orderMode,
    deleted: state.events.deleted,
    deletedId: state.events.deletedId,
    token: state.events.token,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: eventsActions.EventsList,
      setLoading: eventsActions.setLoading,
      reset: eventsActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReregList);
