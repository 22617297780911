import React, { Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";

import InputField from "Components/Forms/Fields/Input";
import DocumentList from "Components/Forms/Fields/DocumentList";
import RadioCheckbox from "Components/Forms/Fields/RadioCheckbox";
import Validations from "Components/Forms/Validations";
import EditForm from "Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { apMask } from "helpers/maskHelper";
import Autocomplete from "Components/Forms/Fields/Autocomplete";
import DownloadIcon from "@material-ui/icons/Description";
import Link from "@material-ui/core/Link";
import { Link as RLink } from "react-router-dom";
import Select from "Components/Forms/Fields/Select";
import authrules from "helpers/authRules";
import localize from "helpers/localize";

class ChangesForm extends Component {
  handleDistributorSelect = (value, key, selected, name) => {
    const { dispatch, change } = this.props;
    const item = selected && selected.length > 0 ? selected[0] : null;
    if (item) {
      dispatch(change(name, item));
      dispatch(change(key, value));
    }
  };

  render() {
    const {
      handleInputChange,
      fieldErrors,
      enabledFields,
      baseFields,
      initialValues,
      status,
      accepting_distributor_id,
      cashregister_apn
    } = this.props;
    const isEdit = initialValues && initialValues.id ? true : false;
    const cashregister_id = get(this.props, "initialValues.cashregister_id");
    const isFeedback =
      isEdit && (initialValues && initialValues.status !== "declined");
    let documents_types = [];
    Object.keys(get(this.props, "baseFields.documents_type.data", {})).forEach(
      key => {
        documents_types.push({
          id: key,
          label: baseFields.documents_type.data[key]
        });
      }
    );
    const isViewPage =
      initialValues && initialValues.status === "accepted"
        ? true
        : this.props.isViewPage;
    return (
      <EditForm {...this.props} isViewPage={isViewPage}>
        <Grid item xs={12} xl={6}>
          <Field
            name="cashregister_apn"
            label={localize("cashregisters", "AP szám")}
            customViewValue={
              cashregister_id /*&& authrules.isRoot*/
                ? val => (
                  <RLink to={`/cashregisters/view/${cashregister_id}`}>
                    {val}
                  </RLink>
                )
                : undefined
            }
            fieldError={fieldErrors.cashregister_apn}
            onChange={() => handleInputChange("cashregister_apn")}
            isRequired={true}
            //validate={[Validations.required]}
            component={InputField}
            type="text"
            placeholder={localize("cashregisters", "AP szám")}
            isViewPage={isViewPage}
            readOnly={isEdit}
            {...apMask}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <Field
            name="accepting_distributor_id"
            label={localize("user", "Viszonteladó")}
            fieldError={fieldErrors.accepting_distributor_id}
            isViewPage={isViewPage}
            onChange={() => handleInputChange("accepting_distributor_id")}
            component={Autocomplete}
            mode="select"
            formName="serviceChanges"
            minChars={get(
              this.props,
              "baseFields.accepting_distributor_id.minChars",
              2
            )}
            url={get(
              this.props,
              "baseFields.accepting_distributor_id.url",
              null
            )}
            change={this.props.change}
            labelKey={"text"}
            disabled={isEdit}
            onSelect={this.handleDistributorSelect}
          />
        </Grid>
        <Grid item xs={12}>
          <Link
            target="_blank"
            href={
              process.env.REACT_APP_HOST +
              "/v1/service-changes/statement?accessToken=" +
              localStorage.getItem("access_token").replace("Bearer ", "") +
              (cashregister_apn ? `&cashregisterApn=${cashregister_apn}` : "") +
              (accepting_distributor_id
                ? `&acceptingDistributorId=${accepting_distributor_id}`
                : "")
            }
          >
            {localize("cashregisters", "Aláírandó nyilatkozat letöltése")}
          </Link>
        </Grid>
        <Grid item container spacing={16}>
          <Grid item xs={12} xl={6}>
            <Field
              name="documents"
              //rfName="documents"
              label={localize("cashregisters", "Dokumentumok")}
              fieldError={fieldErrors.status}
              //onChange={() => handleInputChange("documents")}
              //change={val => this.props.change("documents", val)}
              component={DocumentList}
              placeholder={localize("cashregisters", "Dokumentumok")}
              isRequired={true}
              documents={
                (initialValues && initialValues.documents_uploaded) ||
                [
                  /*{
                    type: "STATEMENT",
                    file_id: "1558693113-NFRbjxI0GCfNyrUc_OLZs-Oa8HvYYcW7.pdf"
                  },
                  {
                    type: "SIGNATURE_SPECIMEN",
                    file_id: "9999693113-NFRbjxI0GCfNyrUc_OLZs-Oa8HvYYcW7.pdf"
                  },
                  {
                    type: "STATEMENT",
                    file_id: "4444693113-NFRbjxI0GCfNyrUc_OLZs-Oa8HvYYcW7.pdf"
                  }*/
                ]
              }
              //validate={[Validations.required]}
              isViewPage={isViewPage}
              options={documents_types}
              disabled={isFeedback}
              readOnly={
                enabledFields
                  ? enabledFields.indexOf("status") !== -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
        </Grid>
        {isFeedback && !isViewPage && (
          <Grid item xs={12}>
            <h6 className="form-legend">
              {localize("cashregisters", "Jóváhagyás")}
            </h6>
          </Grid>
        )}
        {isFeedback && (
          <Grid item xs={12}>
            <Field
              name="status"
              label={isViewPage ? localize("cashregisters", "Státusz") : null}
              fieldError={fieldErrors.status}
              onChange={() => handleInputChange("status")}
              component={RadioCheckbox}
              boldValue={false}
              readOnlyValue={isViewPage ? initialValues.status_text : null}
              //placeholder={localize("cashregisters", "Státusz")}
              row
              isRequired={true}
              validate={[Validations.required]}
              isViewPage={isViewPage}
              //options={statuses}
              yesValue={"accepted"}
              yesText={"Elfogadva"}
              noValue={"declined"}
              noText={"Elutasítva"}
              readOnly={
                enabledFields
                  ? enabledFields.indexOf("status") !== -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
        )}
        {isFeedback && (
          <Grid item xs={12} xl={6}>
            <Field
              name="accepting_notes"
              label={localize("cashregisters", "Indoklás")}
              fieldError={fieldErrors.accepting_notes}
              onChange={() => handleInputChange("accepting_notes")}
              component={InputField}
              isRequired={status === "declined"}
              validate={status === "declined" ? Validations.required : null}
              isViewPage={isViewPage}
              //options={statuses}
              readOnly={
                enabledFields
                  ? enabledFields.indexOf("status") !== -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
        )}
        {isEdit && !isFeedback && (
          <Grid item xs={12} xl={6}>
            {localize("cashregisters", "Elutasítva")}:{" "}
            {initialValues.accepting_notes}
          </Grid>
        )}
      </EditForm>
    );
  }
}

ChangesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  isViewPage: PropTypes.bool,
  enabledFields: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  baseFields: PropTypes.object,
  initialValues: PropTypes.object,
  status: PropTypes.string
};

const UsersReduxForm = reduxForm({
  form: "serviceChanges",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(ChangesForm);

export default connect(
  state => {
    const selector = formValueSelector("serviceChanges");
    const status = selector(state, "status");
    const cashregister_apn = selector(state, "cashregister_apn");
    const accepting_distributor_id = selector(
      state,
      "accepting_distributor_id"
    );
    return {
      initialValues: state.serviceChanges.viewData,
      status,
      accepting_distributor_id,
      cashregister_apn
    };
  },
  null
)(UsersReduxForm);
