import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import Select from "Components/New/Input/Select/Select";
import { withRouter, Link, Route, Switch, Redirect } from "react-router-dom";
// import ReactDataSheet from "react-datasheet";
// Be sure to include styles at some point, probably during your bootstrapping
// import "react-datasheet/lib/react-datasheet.css";
import "handsontable/dist/handsontable.full.css";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import restHelper from "helpers/restHelper";
import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import DataForm from "Components/New/DataForm/DataForm";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  IconButton
} from "@material-ui/core";
import TextField from "Components/New/Input/TextField/TextField";

const SyntaxHighlight = props => {
  let json = props.json;
  if (typeof json != "string") {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/\\"/g, '"');
  let pretty = json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function(match) {
      var cls = "color:blue;"; //'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "color:black;"; //'key';
        } else {
          cls = "color:red; white-space: normal;"; //'string';
        }
      } else if (/true|false/.test(match)) {
        cls = "color:green;"; //'boolean';
      } else if (/null/.test(match)) {
        cls = "color:gray;"; //'null';
      }
      return '<span style="' + cls + '">' + match + "</span>";
    }
  );
  pretty = "<code><pre>" + pretty + "</pre></code>";
  return <div dangerouslySetInnerHTML={{ __html: pretty }} />;
};

const styles = theme => ({
  /*"@global": {
    ".rbt-input-hint": {
      //backgroundColor: "red!important",
      width: "100%",
      top: "2px!important"
    }
  },*/
  rowDeleted: {
    backgroundColor: "red!important",
    color: "white"
  },
  rowChanged: {
    backgroundColor: "green!important",
    color: "white"
  },
  actionCell: {
    width: 70,
    textAlign: "right"
  },
  actionCellIcon: {
    width: 24,
    height: 24
  },
  actionCellButton: {
    width: 28,
    height: 28,
    padding: 0
  },
  actionCellButtonDelete: {
    width: 28,
    height: 28,
    padding: 0,
    color: "red"
  },
  row: {
    height: "auto",
    "&:hover": {
      backgroundColor: "#ddd"
    }
  },
  backButton: {
    padding: 8
  },
  backIcon: {
    marginLeft: 4,
    marginRight: -4
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0
  },
  toolbarSpacer: {
    marginLeft: "auto"
  },
  filterCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2,
    "& :first-child": {
      paddingLeft: 0
    },
    "& :last-child": {
      paddingRight: 0
    }
  }
});

const ActionCellBase = props => {
  const {
    classes,
    row,
    rowIndex,
    onEdit,
    onView,
    onDelete,
    canRemove,
    canView,
    canEdit
  } = props;
  return (
    <TableCell className={classes.actionCell} padding="none">
      {canView && (
        <IconButton
          color="secondary"
          className={classes.actionCellButton}
          onClick={() => onView(row, rowIndex)}
        >
          <ViewIcon className={classes.actionCellIcon} />
        </IconButton>
      )}
      {canEdit && (
        <IconButton
          className={classes.actionCellButton}
          onClick={() => onEdit(row, rowIndex)}
        >
          <EditIcon className={classes.actionCellIcon} />
        </IconButton>
      )}
      {canRemove && (
        <IconButton
          className={classes.actionCellButtonDelete}
          onClick={() => onDelete(row, rowIndex)}
        >
          <DeleteIcon className={classes.actionCellIcon} />
        </IconButton>
      )}
    </TableCell>
  );
};

const ActionCell = withStyles(styles)(ActionCellBase);

class DataTable extends React.Component {
  render() {
    const { classes, ...formProps } = this.props;

    const {
      data,
      editItem,
      onEdit,
      onEndEdit,
      onDelete,
      onCreate,
      onView,
      title: titleProp,
      filterable,
      optionsError,
      error,
      readOnly,
      gridOptionsKey,
      canAdd,
      canEdit,
      canRemove,
      canView,
      canViewByRow,
      hideToolbar
    } = this.props;
    const grid = this.props.options ? this.props.options[gridOptionsKey] : null;
    //console.log("DataTable.render", this.props);
    if (!grid) {
      return "NINCS GRID";
    }
    const title =
      typeof titleProp === "function" ? titleProp(this.props) : titleProp;

    const visibleColumns = Object.keys(grid.columns).filter(key => {
      const col = grid.columns[key];
      if (col.hidden) {
        return false;
      }
      return true;
    });
    if (error || optionsError) {
      return (
        <div>
          {optionsError && <SyntaxHighlight json={optionsError} />}
          {error && <SyntaxHighlight json={error} />}
        </div>
      );
    }
    return (
      <Fragment>
        {!hideToolbar && (
          <Toolbar className={classes.toolbar}>
            {editItem && (
              <IconButton onClick={onEndEdit} className={classes.backButton}>
                <BackIcon className={classes.backIcon} />
              </IconButton>
            )}
            <Typography variant="h5">{title}</Typography>
            {editItem && (
              <Typography
                variant="subtitle1"
                style={{ marginTop: 2, marginLeft: 4 }}
              >
                {editItem.id ? " - szerkesztés" : " - felvitel"}
              </Typography>
            )}
            <div className={classes.toolbarSpacer} />
            {!readOnly && canAdd && (
              <IconButton
                className={classes.actionCellButton}
                onClick={onCreate}
              >
                <CreateIcon className={classes.actionCellIcon} />
              </IconButton>
            )}
          </Toolbar>
        )}
        {editItem ? (
          <DataForm {...formProps} />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {visibleColumns.map((key, colIndex) => {
                  const col = grid.columns[key];
                  const label = col.name || key;
                  return (
                    <TableCell
                      key={`header.${colIndex}`}
                      padding="none"
                      className={classes.filterCell}
                    >
                      {filterable ? (
                        <TextField fullWidth label={label} />
                      ) : (
                        label
                      )}
                    </TableCell>
                  );
                })}
                {!readOnly && (
                  <TableCell key={"header._action"} padding="none" />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, rowIndex) => {
                return (
                  <TableRow key={rowIndex} className={classes.row}>
                    {visibleColumns.map((key, colIndex) => {
                      const col = grid.columns[key];
                      return (
                        <TableCell
                          key={`${rowIndex}.${colIndex}`}
                          padding="none"
                        >
                          {row[key]}
                        </TableCell>
                      );
                    })}
                    {!readOnly && (
                      <ActionCell
                        row={row}
                        rowIndex={rowIndex}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onView={onView}
                        canRemove={canRemove}
                        canView={
                          canView && (canViewByRow ? canViewByRow(row) : true)
                        }
                        canEdit={canEdit}
                      />
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Fragment>
    );
  }
}

DataTable.defaultProps = {
  filterable: true,
  readOnly: false,
  canAdd: true,
  canRemove: true,
  canView: false,
  canEdit: true,
  gridOptionsKey: "grid"
};

DataTable.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  filterable: PropTypes.bool,
  readOnly: PropTypes.bool,
  gridOptionsKey: PropTypes.string,
  canAdd: PropTypes.bool,
  canRemove: PropTypes.bool,
  canView: PropTypes.bool,
  canEdit: PropTypes.bool,
  hideToolbar: PropTypes.bool
};

export default withStyles(styles)(DataTable);
