import { get } from "lodash";

const vatHelper = {
  vat: 0.27,
  bruttoPrice: (price, qty = 1) => {
    return price * qty * (1 + vatHelper.vat);
  },
  nettoPrice: (price, qty = 1) => {
    return price * qty;
  }
};
export default vatHelper;
