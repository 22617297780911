import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { get } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import inputStyle from "./inputStyle.js";
import Wrapper from "./Wrapper";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";

export const ColorIndicator = ({ color, size }) => (
  <div
    style={{
      display: "inline-block",
      backgroundColor: color,
      border: !color ? "solid 1px grey" : undefined,
      width: size,
      height: size,
      borderRadius: size / 2,
      marginRight: 4
    }}
  />
);
ColorIndicator.defaultProps = {
  size: 12
};

ColorIndicator.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};
class SelectField extends Component {
  showValue = () => {
    const { input, options, viewShowKey } = this.props;
    if (viewShowKey) {
      return input.value;
    } else {
      return (
        options.length !== 0 &&
        get(options.filter(row => row.id === input.value), "[0].label", "-")
      );
    }
  };

  render() {
    const {
      input,
      options,
      fieldError,
      label,
      readOnly,
      classes,
      isRequired,
      labelInfo,
      displayEmpty,
      emptyText,
      colors
    } = this.props;
    const { warning, touched, error } = this.props.meta;
    const InputProps = {
      InputProps: {
        classes: {
          root: label
            ? classes.bootstrapSelectRoot
            : classes.bootstrapSelectRootNoLabel,
          input: readOnly
            ? classes.bootstrapDisabledInput
            : classes.bootstrapInput
        }
      }
    };

    let value =
      this.props.forcedDefaultValue && (!input.value || input.value !== "")
        ? this.props.forcedDefaultValue
        : input.value || "";
    let labelAdvanced = label;

    if (typeof label === "string") {
      if (isRequired) {
        labelAdvanced = label + " *";
      }

      if (labelInfo) {
        labelAdvanced = (
          <div>
            {labelAdvanced}
            <Tooltip title={labelInfo}>
              <InfoIcon
                color="primary"
                style={{
                  marginLeft: 6,
                  marginTop: -5,
                  marginBottom: -5,
                  width: 20,
                  height: 20
                }}
              />
            </Tooltip>
          </div>
        );
      }
    }

    const renderValue = colors
      ? {
          renderValue: v => (
            <Fragment>
            <ColorIndicator color={colors[v]} />
            {options[v]}
          </Fragment>
        )
      }
      : undefined;

    return (
      <Wrapper {...this.props} value={this.showValue()}>
        <TextField
          {...input}
          select
          label={labelAdvanced}
          value={value}
          SelectProps={
            {
              /*SelectDisplayProps: colors
              ? { style: { backgroundColor: colors[value] } }
              : {}*/
            }
          }
          {...InputProps}
          InputLabelProps={{
            shrink: true,
            className: classes.selectLabel
          }}
          className={classes.root}
          //margin="normal"
          fullWidth
          autoComplete={"new-" + input.name}
          error={fieldError || (touched && (warning || (error ? true : false)))}
          helperText={fieldError || (touched && (warning || error))}
          inputProps={{
            readOnly: readOnly
          }}
          //{...(renderValue ? { renderValue } : {})}
          //renderValue={renderValue}
        >
          {displayEmpty && (
            <MenuItem value={""} className={classes.selectOption}>
              {colors && <ColorIndicator color={undefined} />}
              {emptyText}
            </MenuItem>
          )}
          {options.map(option => (
            <MenuItem
              key={option.label}
              value={option.id}
              className={classes.selectOption}
            >
              {colors && <ColorIndicator color={colors[option.id]} />}
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Wrapper>
    );
  }
}

SelectField.defaultProps = {
  displayEmpty: false,
  emptyText: " "
};

SelectField.propTypes = {
  colors: PropTypes.object,
  displayEmpty: PropTypes.bool,
  emptyText: PropTypes.string
};

export default withStyles(inputStyle)(SelectField);
