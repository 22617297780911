/* eslint-disable */
import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  AEEREGS_PERMISSION,
  ANNUAL_REVIEWS_PERMISSION,
  ARTICLES_PERMISSION,
  BANNED_CASHREGISTERS_PERMISSION,
  CASHREGISTERS_PERMISSION,
  CLIENTS_PERMISSION,
  CRULES_PERMISSION,
  DISTRIBUTORS_PERMISSION,
  DOWNLOADS_PERMISSION,
  EVENTS_PERMISSION,
  EVENT_TYPES_PERMISSION,
  EXAMINEE_EXAMS_PERMISSION,
  EXAMS_PERMISSION,
  INCOMINGSMS_PERMISSION,
  MY_EXAMS_PERMISSION,
  NOTIFICATION_PERMISSION,
  OUTGOINGSMS_PERMISSION,
  REREGISTER_PERMISSION,
  SERVICE_CHANGES_PERMISSION,
  SERVICE_ORDERS_PERMISSION,
  SMODE_PERMISSION,
  STICKERS_PERMISSION,
  USERS_INACTIVED_PERMISSION,
  USERS_NEW_PERMISSION,
  USERS_PERMISSION
} from "../constans/permissions";
import {
  AeeRegsListPage,
  AnnualReviewsListPage,
  ArticlesListPage,
  AuthenticatedLayout,
  BannedCashregistersListPage,
  CashregistersListPage,
  ChangesList,
  ClientsListPage,
  CrulesListPage,
  CWorksheetsListPage,
  DistributorPage,
  DistributorsListPage,
  DownloadsListtPage,
  EditAnnualReviewPage,
  EditArticlePage,
  EditBannedCashregistersPage,
  EditCashregisterPage,
  EditChange,
  EditClientPage,
  EditDistributorPage,
  EditDownloadPage,
  EditEventTypePage,
  EditExamineeExamPage,
  EditExamPage,
  EditMyExamPage,
  EditRightPage,
  EditServiceOrderPage,
  EditUserPage,
  EmailChangePage,
  EventsListPage,
  EventTypesListPage,
  ExamineeExamsPage,
  ExamsListPage,
  HomePage,
  IncomingSmsListPage,
  LoginPage,
  LostPasswordPage,
  MyExamsListPage,
  NotificationsListPage,
  OutgoingSmsListPage,
  Page404Page,
  PagePermission,
  PasswordActivatePage,
  ProfilePage,
  RegisterPage,
  ReregisterPage,
  ServiceOrdersListPage,
  SigningPage,
  SmodeListPage,
  StickersListPage,
  TokensListPage,
  UsersListPage
} from "../Containers";
import UploadServiceOrder from "../Containers/Pages/ServiceOrders/UploadServiceOrder";

import Accountants from "Containers/Pages/Accountants/Accountants";
import AnnualReviewPublic from "Containers/Pages/Cashregisters/AnnualReviews/AnnualReviewPublic";
import AccountingPublic from "Containers/Pages/Cloud/AccountingPublic";
import Cloud from "Containers/Pages/Cloud/Cloud";
import ControlPanel from "Containers/Pages/Cloud/ControlPanel";
import Email from "Containers/Pages/Email/Email";
import EditNotification from "Containers/Pages/Notifications/EditNotification";
import Shop from "Containers/Pages/Shop/Shop";
import WorksheetRoutes from "Containers/Pages/Worksheets/WorksheetRoutes";

export const MainRoutes = props => {
  return (
    <Switch /*location={props.location}*/>
      <Route
        path="/"
        exact
        render={props => (
          <AuthenticatedLayout {...props}>
            <HomePage {...props} />
          </AuthenticatedLayout>
        )}
      />
      <Route
        path="/eves/:sticker?/:apn?"
        exact
        render={props => <AnnualReviewPublic {...props} />}
      />
      {/* Cloud start */}
      <Route
        path="/cloud/:client_id(\d+)?/:menu?"
        render={props => (
          <AuthenticatedLayout {...props}>
            <Cloud {...props} />
          </AuthenticatedLayout>
        )}
      />
      <Route
        path="/cloud-control-panel/:menu?"
        render={props => (
          <AuthenticatedLayout {...props}>
            <ControlPanel {...props} />
          </AuthenticatedLayout>
        )}
      />
      {/* Cloud end */}
      {/* Shop start */}
      <Route
        path="/shop/:menu?"
        render={props => (
          <AuthenticatedLayout {...props}>
            <Shop {...props} />
          </AuthenticatedLayout>
        )}
      />
      {/* Shop end */}
      {/* Accountants start */}
      <Route
        path="/accountants/:menu?/:id?"
        render={props => (
          <AuthenticatedLayout {...props}>
            <Accountants {...props} />
          </AuthenticatedLayout>
        )}
      />
      {/* Accountants end */}
      {/* Email start */}
      <Route
        path="/email/:menu?"
        render={props => (
          <AuthenticatedLayout {...props}>
            <Email {...props} />
          </AuthenticatedLayout>
        )}
      />
      {/* Email end */}
      <Route
        exact
        path="/activate/password/:token"
        component={PasswordActivatePage}
      />
      <Route exact path="/activate/email/:token" component={EmailChangePage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/lost-password" component={LostPasswordPage} />
      <Route
        exact
        path="/profile"
        render={props => (
          <AuthenticatedLayout {...props}>
            <ProfilePage {...props} />
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/distributor"
        render={props => (
          <AuthenticatedLayout {...props}>
            <DistributorPage {...props} />
          </AuthenticatedLayout>
        )}
      />
      <Route exact path="/register/:token" component={RegisterPage} />

      <Route
        exact
        path="/users"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={USERS_PERMISSION}>
              <UsersListPage
                {...props}
                mode="active"
                perms={USERS_PERMISSION}
              />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/users/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={USERS_PERMISSION}>
              <EditUserPage {...props} mode="active" />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/users/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={USERS_PERMISSION}>
              <EditUserPage {...props} mode="active" />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/users/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={USERS_PERMISSION}>
              <EditUserPage {...props} mode="active" />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/new-users"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={USERS_NEW_PERMISSION}>
              <UsersListPage {...props} mode="new" />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/new-users/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={USERS_NEW_PERMISSION}>
              <EditUserPage {...props} mode="new" />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/new-users/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={USERS_NEW_PERMISSION}>
              <EditUserPage {...props} mode="new" />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/new-users/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={USERS_NEW_PERMISSION}>
              <EditUserPage {...props} mode="new" />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/inactivated-users"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={USERS_INACTIVED_PERMISSION}>
              <UsersListPage {...props} mode="inactivated" />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/inactivated-users/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={USERS_INACTIVED_PERMISSION}>
              <EditUserPage {...props} mode="inactivated" />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/inactivated-users/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={USERS_INACTIVED_PERMISSION}>
              <EditUserPage {...props} mode="inactivated" />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/articles"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={ARTICLES_PERMISSION}>
              <ArticlesListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/articles/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={ARTICLES_PERMISSION}>
              <EditArticlePage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/articles/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={ARTICLES_PERMISSION}>
              <EditArticlePage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/articles/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={ARTICLES_PERMISSION}>
              <EditArticlePage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/service-changes"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SERVICE_CHANGES_PERMISSION}>
              <ChangesList {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/service-changes/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SERVICE_CHANGES_PERMISSION}>
              <EditChange {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/service-changes/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SERVICE_CHANGES_PERMISSION}>
              <EditChange {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/service-changes/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SERVICE_CHANGES_PERMISSION}>
              <EditChange {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/distributors"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={DISTRIBUTORS_PERMISSION}>
              <DistributorsListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/distributors/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={DISTRIBUTORS_PERMISSION}>
              <EditDistributorPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/distributors/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={DISTRIBUTORS_PERMISSION}>
              <EditDistributorPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/distributors/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={DISTRIBUTORS_PERMISSION}>
              <EditDistributorPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/banned-cashregisters"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={BANNED_CASHREGISTERS_PERMISSION}>
              <BannedCashregistersListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/banned-cashregisters/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={BANNED_CASHREGISTERS_PERMISSION}>
              <EditBannedCashregistersPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/banned-cashregisters/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={BANNED_CASHREGISTERS_PERMISSION}>
              <EditBannedCashregistersPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      {/*<Route exact path="/banned-cashregisters/view/:id" render={props => <AuthenticatedLayout {...props}>*/}
      {/*<PagePermission {...props} module={BANNED_CASHREGISTERS_PERMISSION}>*/}
      {/*<EditBannedCashregistersPage {...props}/>*/}
      {/*</PagePermission>*/}
      {/*</AuthenticatedLayout>}/>*/}

      <Route
        exact
        path="/reregister"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={REREGISTER_PERMISSION}>
              <ReregisterPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/annual-reviews"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={ANNUAL_REVIEWS_PERMISSION}>
              <AnnualReviewsListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/annual-reviews/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={ANNUAL_REVIEWS_PERMISSION}>
              <EditAnnualReviewPage
                {...props}
                mode="active"
                isViewPage={true}
                disabledEditButton={true}
                getPagetitle={() => "Éves felülvizsgálat"}
              />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/event-types"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={EVENT_TYPES_PERMISSION}>
              <EventTypesListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/event-types/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={EVENT_TYPES_PERMISSION}>
              <EditEventTypePage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/event-types/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={EVENT_TYPES_PERMISSION}>
              <EditEventTypePage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/event-types/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={EVENT_TYPES_PERMISSION}>
              <EditEventTypePage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/aee-regs"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={AEEREGS_PERMISSION}>
              <AeeRegsListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/s-mode"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SMODE_PERMISSION}>
              <SmodeListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/c-rules"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={CRULES_PERMISSION}>
              <CrulesListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/downloads"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={DOWNLOADS_PERMISSION}>
              <DownloadsListtPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/downloads/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={DOWNLOADS_PERMISSION}>
              <EditDownloadPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/downloads/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={DOWNLOADS_PERMISSION}>
              <EditDownloadPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/downloads/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={DOWNLOADS_PERMISSION}>
              <EditDownloadPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/service-orders"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SERVICE_ORDERS_PERMISSION}>
              <ServiceOrdersListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/service-orders/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SERVICE_ORDERS_PERMISSION}>
              <EditServiceOrderPage {...props} isCreatePage={true} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/service-orders/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SERVICE_ORDERS_PERMISSION}>
              <EditServiceOrderPage {...props} isEditPage={true} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/service-orders/upload/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SERVICE_ORDERS_PERMISSION}>
              <UploadServiceOrder {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/service-orders/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SERVICE_ORDERS_PERMISSION}>
              <EditServiceOrderPage {...props} isViewPage={true} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        path="/worksheets"
        render={props => (
          <AuthenticatedLayout {...props}>
            <WorksheetRoutes {...props} />
          </AuthenticatedLayout>
        )}
      />
      {/*
            <Route exact path="/worksheets" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
                    <WorksheetsListPage {...props} />
                </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/new-worksheets" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
                    <WorksheetsListPage {...props} mode="new" />
                </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/in-progress-worksheets" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
                    <WorksheetsListPage {...props} mode="in_progress" />
                </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/done-worksheets" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
                    <WorksheetsListPage {...props} mode="done" />
                </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/waiting-worksheets" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
                    <WorksheetsListPage {...props} mode="waiting" />
                </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/closed-worksheets" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
                    <WorksheetsListPage {...props} mode="closed" />
                </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/worksheets/create" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
                    <EditWorksheetPage {...props} />
                </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/worksheets/edit/:id" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
                    <EditWorksheetPage {...props} />
                </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/worksheets/view/:id" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={WORKSHEETS_PERMISSION}>
                    <EditWorksheetPage {...props}/>
                </PagePermission>
            </AuthenticatedLayout>}/>

            <Route exact path="/workfees" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={WORKFEES_PERMISSION}>
                    <WorkfeesListPage {...props} />
                </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/workfees/create" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={WORKFEES_PERMISSION}>
                    <EditWorkfeePage {...props} />
                </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/workfees/edit/:id" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={WORKFEES_PERMISSION}>
                    <EditWorkfeePage {...props} />
                </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/workfees/view/:id" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={WORKFEES_PERMISSION}>
                    <EditWorkfeePage {...props}/>
                </PagePermission>
            </AuthenticatedLayout>}/>
 */}
      <Route
        exact
        path="/clients"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={CLIENTS_PERMISSION}>
              <ClientsListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/clients/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={CLIENTS_PERMISSION}>
              <EditClientPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/clients/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={CLIENTS_PERMISSION}>
              <EditClientPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/clients/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={CLIENTS_PERMISSION}>
              <EditClientPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/incoming-sms-messages"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={INCOMINGSMS_PERMISSION}>
              <IncomingSmsListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/outgoing-sms-messages"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={OUTGOINGSMS_PERMISSION}>
              <OutgoingSmsListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      {/*<Route exact path="/sms-list-items" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={SMS_LIST_ITEMS_PERMISSION}>
                    <SmsListItemsListPage {...props}/>
                </PagePermission>
</AuthenticatedLayout>}/>
            <Route exact path="/sms-list-items/create" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={SMS_LIST_ITEMS_PERMISSION}>
                    <EditSmsListItemPage {...props}/>
                </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/sms-list-items/edit/:id" render={props => <AuthenticatedLayout {...props}>
                <PagePermission {...props} module={SMS_LIST_ITEMS_PERMISSION}>
                    <EditSmsListItemPage {...props}/>
                </PagePermission>
            </AuthenticatedLayout>}/>*/}

      <Route
        exact
        path="/stickers"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={STICKERS_PERMISSION}>
              <StickersListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/tokens"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={STICKERS_PERMISSION}>
              <TokensListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/cashregister-worksheets"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={STICKERS_PERMISSION}>
              <CWorksheetsListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/cashregisters"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={CASHREGISTERS_PERMISSION}>
              <CashregistersListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/cashregisters/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={CASHREGISTERS_PERMISSION}>
              <EditCashregisterPage
                {...props}
                disableEmailAndPhoneRequiredValidation={true}
              />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/cashregisters/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={CASHREGISTERS_PERMISSION}>
              <EditCashregisterPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/cashregisters/view/:pid/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={CASHREGISTERS_PERMISSION}>
              <EditRightPage {...props} isViewPage={false} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/cashregisters/view/:pid/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={CASHREGISTERS_PERMISSION}>
              <EditRightPage {...props} isViewPage={false} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/exams"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={EXAMS_PERMISSION}>
              <ExamsListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/exams/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={EXAMS_PERMISSION}>
              <EditExamPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/exams/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={EXAMS_PERMISSION}>
              <EditExamPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/exams/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={EXAMS_PERMISSION}>
              <EditExamPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/examinee-exams"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={EXAMINEE_EXAMS_PERMISSION}>
              <ExamineeExamsPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/examinee-exams/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={EXAMINEE_EXAMS_PERMISSION}>
              <EditExamineeExamPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/user-exams"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={MY_EXAMS_PERMISSION}>
              <MyExamsListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/user-exams/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={MY_EXAMS_PERMISSION}>
              <EditMyExamPage {...props} isExam={false} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/user-exams/exam/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={MY_EXAMS_PERMISSION}>
              <EditMyExamPage {...props} isExam={true} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/events"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={EVENTS_PERMISSION}>
              <EventsListPage {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      {/* Shop client */}
      {/*<Route exact path="/shop/catalog" render={props => <AuthenticatedLayout {...props}>
              <PagePermission {...props} module={SHOP_PERMISSION}>
                <ProductList getListHeaderEndpoint="/shop-products" {...props} />
              </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/shop/catalog" render={props => <AuthenticatedLayout {...props}>
              <PagePermission  {...props} module={SHOP_PERMISSION}>
                <ProductList getListHeaderEndpoint="/shop-products"   {...props} />
              </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/shop/cart" render={props => <AuthenticatedLayout {...props}>
              <PagePermission {...props} module={SHOP_PERMISSION}>
                <Cart {...props} />
              </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/shop/catalog/product/:id" render={props => <AuthenticatedLayout {...props}>
              <ViewProduct {...props} module={SHOP_PERMISSION}>
                <EditAdminProduct {...props} />
              </ViewProduct>
</AuthenticatedLayout>}/>

          <Route exact path="/shop/orders" render={props => <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SHOP_ADMIN_PERMISSION}>
              <OrderList getListHeaderEndpoint="/shop-orders" {...props} />
            </PagePermission>
          </AuthenticatedLayout>}/>
          <Route exact path="/shop/orders/view/:id" render={props => <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SHOP_ADMIN_PERMISSION}>
              <ViewOrder {...props}/>
            </PagePermission>
</AuthenticatedLayout>}/>*/}

      {/* Shop admin */}
      {/*<Route exact path="/shop/admin/products" render={props => <AuthenticatedLayout {...props}>
              <PagePermission {...props} module={SHOP_ADMIN_PERMISSION}>
                <AdminProductList getListHeaderEndpoint="/shop-admin-products" {...props} />
              </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/shop/admin/products/create" render={props => <AuthenticatedLayout {...props}>
              <PagePermission {...props} module={SHOP_ADMIN_PERMISSION}>
                <EditAdminProduct {...props}/>
              </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/shop/admin/products/edit/:id" render={props => <AuthenticatedLayout {...props}>
              <PagePermission {...props} module={SHOP_ADMIN_PERMISSION}>
                <EditAdminProduct {...props}/>
              </PagePermission>
            </AuthenticatedLayout>}/>
            <Route exact path="/shop/admin/products/view/:id" render={props => <AuthenticatedLayout {...props}>
              <PagePermission {...props} module={SHOP_ADMIN_PERMISSION}>
                <EditAdminProduct {...props}/>
              </PagePermission>
</AuthenticatedLayout>}/>





            <Route exact path="/shop/admin/orders" render={props => <AuthenticatedLayout {...props}>
              <PagePermission {...props} module={SHOP_ADMIN_PERMISSION}>
                <AdminOrderList getListHeaderEndpoint="/shop-admin-orders" {...props} />
              </PagePermission>
            </AuthenticatedLayout>}/>*/}

      {/* Shap admin tags */}
      {/*<Route exact path="/shop/admin/tags" render={props => <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SHOP_ADMIN_PERMISSION}>
              <AdminTagsList getListHeaderEndpoint="/shop-admin-tags" {...props}/>
            </PagePermission>
          </AuthenticatedLayout>}/>
          <Route exact path="/shop/admin/tags/create" render={props => <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={SHOP_ADMIN_PERMISSION}>
              <CreateAdminTag {...props}/>
            </PagePermission>
          </AuthenticatedLayout>}/>
          */}

      <Route
        exact
        path="/notifications"
        render={props => (
          <AuthenticatedLayout {...props}>
            <NotificationsListPage {...props} />
          </AuthenticatedLayout>
        )}
      />

      <Route
        exact
        path="/notifications/create"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={NOTIFICATION_PERMISSION}>
              <EditNotification {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/notifications/edit/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={NOTIFICATION_PERMISSION}>
              <EditNotification {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />
      <Route
        exact
        path="/notifications/view/:id"
        render={props => (
          <AuthenticatedLayout {...props}>
            <PagePermission {...props} module={NOTIFICATION_PERMISSION}>
              <EditNotification {...props} />
            </PagePermission>
          </AuthenticatedLayout>
        )}
      />

      <Route exact path="/worksheet-signing-tools" component={SigningPage} />
      <Route exact path="/public-accounting" component={AccountingPublic} />

      <Route exact path="**" component={Page404Page} />
    </Switch>
  );
};

export default MainRoutes;
