import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { BASE_PATH_CATALOG } from "./../shopPath";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    //height: 22,
    //minHeight: 60,
    paddingRight: 20,
    position: "relative",
    borderRadius: 4,
    //overflow: "hidden",
    //width: 250,
    //whiteSpace: "nowrap",
    //textOverflow: "ellipsis!important",
    "& > *": {
      margin: theme.spacing.unit / 2
    }
  },
  rootOpen: {
    display: "block",
    paddingRight: 20,
    borderRadius: 4,
    webkitBoxShadow: "3px 4px 10px 2px rgba(0,0,0,0.59)",
    mozBoxShadow: "3px 4px 10px 2px rgba(0,0,0,0.59)",
    boxShadow: "3px 4px 10px 2px rgba(0,0,0,0.59)",
    overflow: "visible",
    backgroundColor: "white",
    zIndex: 5000,
    position: "absolute",
    "& > *": {
      margin: theme.spacing.unit / 2
    }
  },
  container: {
    height: 26,
    width: "100%",
    overflow: "hidden"
  },
  chip: {
    //display: "inline!important",
    height: "unset",
    cursor: "pointer"
  },
  chipMore: {
    //display: "inline!important",
    height: "unset",
    cursor: "pointer",
    position: "absolute",
    right: 0,
    top: 0,
    width: 20,
    "&>span": {
      display: "none"
    },
    "&>svg": {
      marginLeft: -2,
      marginRight: -2
    }
  }
});

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hasMore: false
    };
    this.containerRef = React.createRef();
    this.rootRef = React.createRef();
  }

  componentDidMount() {
    const containerHeight = this.containerRef.current.clientHeight;
    const rootHeight = this.rootRef.current.scrollHeight;
    const diff = rootHeight - containerHeight;
    //console.log(containerHeight, rootHeight, diff);
    if (diff > 5) {
      this.setState({ hasMore: true });
    }
  }

  toggleOpen = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.state.open) {
      this.containerRef.current.style.width = "unset";
      this.rootRef.current.style.width = "unset";
    } else {
      this.rootRef.current.style.width = `${
        this.containerRef.current.clientWidth
      }px`;
      this.containerRef.current.style.width = `${
        this.containerRef.current.clientWidth
      }px`;
    }
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes, tags, rootClassName } = this.props;
    const { open, hasMore } = this.state;
    return (
      <div ref={this.containerRef} className={classes.container}>
        <div
          ref={this.rootRef}
          className={`${
            open ? classes.rootOpen : classes.root
          } ${rootClassName}`}
        >
          {tags &&
            tags.map((tag, tagIndex) => {
              return (
                <Chip
                  to={`${BASE_PATH_CATALOG}#${tag}`}
                  component={Link}
                  key={tagIndex}
                  label={tag}
                  className={classes.chip}
                />
              );
            })}
          {hasMore && (
            <Chip
              //label="..."
              className={classes.chipMore}
              onClick={this.toggleOpen}
              icon={
                open ? (
                  <UpIcon fontSize="small" />
                ) : (
                  <DownIcon fontSize="small" />
                )
              }
            />
          )}
        </div>
      </div>
    );
  }
}

Tags.propTypes = {
  classes: PropTypes.object.isRequired,
  tags: PropTypes.array,
  rootClassName: PropTypes.string
};

export default withStyles(styles)(Tags);
