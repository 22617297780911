import React, { Component } from "react";
import PropTypes from "prop-types";
import localize from "helpers/localize";
import FileUploadMulti from "Components/New/FileUploadMulti/FileUploadMulti";
import { FormHelperText, Typography } from "@material-ui/core";
import restHelper from "helpers/aee/restHelper";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";

const LC = "cloud"; //Localization category
class AccountingPlus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  finalProcess = files => {
    const file_ids = files
      .filter(file => (file.id ? true : false))
      .map(file => file.id);
    console.log("onUploadEnd", file_ids);
    return restHelper
      .post(
        "cashregister-accounting/import",
        "CashregisterAccountingImportForm",
        { file_ids }
      )
      .then(response => {
        globalMessage.success(localize(LC, "Sikeres feltöltés."));
        //return localize(LC, "Sikeres feltöltés.");
      })
      .catch(error => {
        if (error && error.message) {
          globalMessage.fatalError(error.message);
        } else if (error && error.form_errors && error.form_errors.file_id) {
          globalMessage.fatalError(error.form_errors.file_id.join(", "));
        } else {
          globalMessage.error(localize(LC, "Hiba a feldolgozás közben"));
        }

        //throw localize(LC, "Hiba a feldolgozás közben");
      });
  };

  render() {
    return (
      <div style={{ width: "100%", position: "relative" }}>
        <Typography variant="h6">{localize(LC, "Könyvelés +")}</Typography>
        <FormHelperText>
          {localize(
            LC,
            "Xml pénztárgép állományok kézi feltöltésére szolgáló felület."
          )}
        </FormHelperText>
        <FileUploadMulti
          finalProcess={this.finalProcess}
          acceptedFiles="application/xml"
        />
      </div>
    );
  }
}

AccountingPlus.defaultProps = {};
AccountingPlus.propTypes = {};

export default AccountingPlus;
