import React, { Fragment } from "react";
import PropTypes from "prop-types";
import localize from "helpers/localize";
import restHelper from "helpers/aee/restHelper";
import withLock from "./withLock";
import authRules from "helpers/authRules";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Paper,
  Toolbar,
  Button
} from "@material-ui/core";
import Modal from "Components/Modal/Modal";
import { CLOUD_CASHREGISTER_AEEUNBLOCK } from "constans/permissions";

const LC = "cloud";

class AeeUnblock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      loading: false,
      unblockOpen: false,
      unblockKey: null
    };
  }

  componentDidMount() {
    /*const { setToolbarItems } = this.props;
    setToolbarItems(<div>LOL</div>);*/
    this.view();
  }

  view = () => {
    const { cashregister_id: ap, client_id: client } = this.props;
    this.setState({ loading: true });
    restHelper
      .view("cashregister-aee-unblock/view", null, { ap, client })
      .then(response => {
        this.setState({ item: response.data, loading: false });
      });
  };

  onUblockOpen = () => {
    this.setState({ unblockOpen: true, unblockKey: null });
  };

  onUblockClose = () => {
    this.setState({ unblockOpen: false, unblockKey: null });
  };

  unblock = () => {
    const { cashregister_id: ap, client_id: client } = this.props;
    this.setState({ loading: true });
    restHelper
      .create("cashregister-aee-unblock/update", null, null, { ap, client })
      .then(response => {
        this.props.onUpdated(() => {
          this.setState({
            loading: false,
            unblockKey: response.data.key
          });
        });
      });
  };

  buttonDisabled = () => {
    const { item } = this.state;
    if (!(item.block_type && item.block_type.value)) {
      // még ajax response előtti renderelés miatt
      return true;
    }

    if (
      item.block_type.value === "1" &&
      authRules.hasPermissionByKeyAndName(
        CLOUD_CASHREGISTER_AEEUNBLOCK,
        "cloud-cashregister-aee-unblock.block_type_1"
      )
      // blokkolás feloldás menüben, ha a block_type = 1, akkor a feloldást csak főforgalmazó végezheti, ha a block_type=3 akkor meg senki, mert az nav szerver blokkolás
    ) {
      return false;
    }

    return true;
  };

  render() {
    const { item, loading, unblockOpen, unblockKey } = this.state;
    const user = authRules.getUser();
    const timeLeft = this.props.getTimeLeft();
    console.log("timeLeft", timeLeft, this.props);
    return (
      <Grid container spacing={16}>
        {/*<Grid item xs={12}>
          <Typography variant="h6">AEE Teszt</Typography>
    </Grid>*/}
        <Grid item xs={12} md={8} lg={6}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{localize(LC, "Funkció")}</TableCell>
                  <TableCell>{localize(LC, "Eredmény")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(item || {}).map(key => {
                  return (
                    <TableRow key={key} style={{ height: "unset" }}>
                      <TableCell>{item[key].label}</TableCell>
                      <TableCell>
                        {item[key].value === "OK" ? (
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            {item[key].vale}
                          </span>
                        ) : (
                          item[key].value
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Toolbar>
              <Button
                disabled={this.buttonDisabled() || timeLeft > 0}
                style={{ marginLeft: "auto" }}
                variant="contained"
                color="primary"
                onClick={this.onUblockOpen}
              >
                {localize(LC, "Feloldás")}
              </Button>
            </Toolbar>
            <Modal
              loading={loading}
              onAccept={this.unblock}
              onCancel={this.onUblockClose}
              onClose={this.onUblockClose}
              disableOk={unblockKey ? true : false}
              noText={unblockKey ? "Bezár" : "Mégse"}
              open={unblockOpen}
              title="Blokkolás feloldása"
            >
              {unblockKey ? (
                <Fragment>
                  <div style={{ textAlign: "center" }}>
                    {localize(LC, "Igazolvány szám")}: <b>{user.card_number}</b>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    {localize(LC, "Generált kód")}: <b>{unblockKey}</b>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div>
                    {localize(
                      LC,
                      "Biztosan feloldaj a blokkolást az alábbi szervizes műszerész ig. számmal?"
                    )}
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <b>{user.card_number}</b>
                  </div>
                </Fragment>
              )}
            </Modal>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

AeeUnblock.propTypes = {
  cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

export default withLock(AeeUnblock, "AEE_UNBLOCK", {
  dataTitle: "Blokkolás feloldás",
  title: "Blokkolás feloldás",
  forceUpdate: false,
  hideEditButton: true
});
