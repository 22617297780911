import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import * as UIActions from "../../redux/modules/UIsettings";
import Notifications from "../Notifications/Notifications";
import CartNotification from "Containers/Pages/Shop/Cart/CartNotification";
import LanguageSelector from "Components/LanguageSelector";
const logo = require(`themes/${process.env.REACT_APP_THEME}/logo.png`);
const theme = require(`themes/${process.env.REACT_APP_THEME}/theme`);
class NavBar extends Component {
  onToggleCollapsedNav = e => {
    this.props.sidebarToogle &&
      this.props.sidebarToogle(!this.props.sideBarOpened);
  };

  render() {
    const { showNotification, showMenu, logoLink } = this.props;
    return (
      <AppBar className="app-main-header" color={theme.default.header.color}>
        <Toolbar className="app-toolbar" disableGutters={false}>
          {showMenu && (
            <Hidden lgUp>
              <IconButton
                //className="jr-menu-icon mr-3 d-block d-xl-none"
                color="inherit"
                aria-label="Menu"
                onClick={this.onToggleCollapsedNav}
              >
                {/*<span className="menu-icon" />*/}
                <MenuIcon />
              </IconButton>
            </Hidden>
          )}
          <Link
            className="mr-2 d-none d-sm-block"
            to={logoLink}
            style={{
              height: `calc(100% - ${theme.default.header.logoMargin}px)`
            }}
          >
            <img
              src={logo}
              alt=""
              style={{
                height: "100%"
              }}
            />
          </Link>
          <div style={{ marginLeft: "auto" }} />
          <LanguageSelector />
          <CartNotification />
          {showNotification && <Notifications {...this.props} />}
        </Toolbar>
      </AppBar>
    );
  }
}

NavBar.defaultProps = {
  showNotification: true,
  showMenu: true,
  logoLink: "/"
};

NavBar.propTypes = {
  showMenu: PropTypes.bool,
  showNotification: PropTypes.bool,
  sideBarOpened: PropTypes.bool,
  sidebarToogle: PropTypes.func,
  logoLink: PropTypes.string
};

const mapStateToProps = state => {
  return {
    sideBarOpened: state.UIsettings.sideBarOpened
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sidebarToogle: UIActions.sidebarToogle
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBar);
