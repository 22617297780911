import React from "react";
import PropTypes from "prop-types";
import NavBar from "Components/NavBar/NavBar";
import ContainerHeader from "Components/ContainerHeader";
import TextField from "@material-ui/core/TextField";
import { Button, Toolbar } from "@material-ui/core";
import { annualReviewPublic } from "redux/modules/annual-reviews";
import AnnualReviewFormPublic from "./views/AnnualReviewFormPublic";
import localize from "helpers/localize";

class AnnualReviewPublic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apn: props.match.params.apn,
      sticker: props.match.params.sticker,
      data: null,
      error: null
    };
  }

  componentDidMount() {
    const { sticker, apn } = this.state;
    this.fetchData(sticker, apn, true);
  }

  handleOnClick = () => {
    const { sticker, apn } = this.state;
    const newUrl = apn ? `/eves/${sticker}/${apn}` : `/eves/${sticker}`;
    if (newUrl !== this.props.match.url) {
      this.props.history.push(newUrl);
    }
    this.fetchData(sticker, apn);
  };

  handleOnChange = evt => {
    this.setState({ apn: evt.target.value });
  };

  handleInputKeypress = evt => {
    let keyCode = evt.which || evt.keyCode;
    if (keyCode === 13) {
      //Enter
      this.handleOnClick();
    }
  };

  fetchData = (sticker, apn, silent) => {
    if (sticker && apn) {
      annualReviewPublic(sticker, apn)
        .then(response => {
          this.setState({ data: response.data, error: null });
        })
        .catch(error => {
          this.setState({
            data: null,
            error: error.data.message || error.data.name
          });
        });
    } else {
      !silent &&
        this.setState({
          error: sticker
            ? localize("cashregisters", "Az APN szám megadása kötelező")
            : localize("cashregisters", "Hibás matricaszám az url-ben")
        });
    }
  };

  render() {
    const { apn, data, error } = this.state;
    return (
      <div>
        <NavBar {...this.props} showNotification={false} showMenu={false} />
        <div
          style={{
            padding: 16,
            paddingTop: 86,
            overflow: "auto",
            height: "100%"
          }}
        >
          <ContainerHeader
            title={localize("cashregisters", "ÉVES FELÜLVIZSGÁLATI ADATLAP")}
          />
          <Toolbar disableGutters>
            <TextField
              label={localize("cashregisters", "APN szám") + "*"}
              name="apn"
              variant="outlined"
              margin="dense"
              onChange={this.handleOnChange}
              value={apn || ""}
              onKeyPress={this.handleInputKeypress}
              error={error}
              helperText={error}
            />
            <Button
              onClick={this.handleOnClick}
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
            >
              {localize("cashregisters", "Lekérdezés")}
            </Button>
          </Toolbar>
          {data && (
            <AnnualReviewFormPublic
              initialValues={data}
              form_values={data.form_values}
              isViewPage
              disabledEditButton
              disableCancel
            />
          )}
        </div>
      </div>
    );
  }
}

AnnualReviewPublic.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default AnnualReviewPublic;
