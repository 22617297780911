import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Add";
import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import TextField from "Components/New/Input/TextField/TextField";
import dateHelper from "helpers/dateHelper";
import formatHelper from "helpers/formatHelper";
import CheckedIcon from "@material-ui/icons/CheckBox";
import UnCheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {
  Plugin,
  Template,
  TemplatePlaceholder
} from "@devexpress/dx-react-core";
import {
  SearchState,
  DataTypeProvider,
  FilteringState,
  PagingState,
  SortingState,
  CustomPaging
} from "@devexpress/dx-react-grid";

import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  Toolbar,
  SearchPanel,
  ColumnChooser,
  TableColumnVisibility,
  TableFilterRow,
  PagingPanel
} from "@devexpress/dx-react-grid-material-ui";
import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import ActionsColumn from "./ActionsColumn";
import MenuItem from "@material-ui/core/MenuItem";
import SelectMui from "@material-ui/core/Select";

const arr_diff = (a1, a2) => {
  var a = [],
    diff = [];

  for (let i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (let i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (var k in a) {
    diff.push(k);
  }

  return diff;
};
const HeaderRowComponent = props => (
  <Table.Row {...props} style={{ ...props.style, height: "unset" }} />
);

const CustomToolbarMarkup = props => {
  const {
    showRefreshIcon,
    fecthData,
    onCreate,
    loading,
    title,
    canCreate
  } = props;
  return (
    <Plugin name="customToolbarMarkup">
      <Template name="toolbarContent">
        <Typography variant="h6">{title}</Typography>
        <TemplatePlaceholder />
        {showRefreshIcon && !loading && (
          <Tooltip title={localize("basic", "Adatok frissítése")}>
            <IconButton color="primary" onClick={() => fecthData()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )}
        {loading && (
          <CircularProgress
            style={{
              padding: 15
            }}
            size={48}
          />
        )}

        {canCreate && (
          <Tooltip title={localize("basic", "Új sor felvitele")}>
            <IconButton color="primary" onClick={() => onCreate()}>
              <CreateIcon />
            </IconButton>
          </Tooltip>
        )}
      </Template>
    </Plugin>
  );
};
const FilterCell = ({
  column,
  columnFilterValues,
  setHeaderFilter,
  filter,
  children,
  onFilter,
  ...restProps
}) => {
  const filterValue = filter ? filter.value : null;

  const type =
    column.filter && column.filter.type ? column.filter.type : "text";

  return (
    <TableFilterRow.Cell {...restProps} column={column}>
      {type === "dropdown" && (
        <SelectMui
          fullWidth
          value={filterValue || ""}
          onChange={evt => {
            onFilter({
              columnName: column.name, //column.name.replace("_text", ""),
              value: evt.target.value,
              operation: "contains"
            });
            //getChangeFilter(evt.target.value);
          }}
        >
          <MenuItem />
          {column.filter.data.map((item, key) => {
            return (
              <MenuItem key={key} value={item.id}>
                {item.label}
              </MenuItem>
            );
          })}
        </SelectMui>
      )}
      {type === "text" && children}
      {type === "daterange" && children}
    </TableFilterRow.Cell>
  );
};
const styles = theme => ({
  tableStriped: {
    borderCollapse: "collapse",
    "& tr": {
      height: "unset"
    },
    "& tbody tr": {
      "&:hover": {
        backgroundColor: "#ddd"
      }
    },
    "& td": {
      border: "solid 1px #ddd",
      "& > div": {
        margin: 0
      }
    }
  },
  totalCell: {
    //backgroundColor: "green",
    "& > div": {
      margin: 0
    }
  }
});

const CustomTableBase = ({ classes, ...restProps }) => (
  <Table.Table
    {...restProps}
    className={classes.tableStriped}
    style={{ ...restProps.style, width: "calc(100% - 1px)" }}
  />
);

export const CustomTable = withStyles(styles, { name: "CustomTable" })(
  CustomTableBase
);
const CurrencyFormatter = ({ value }) => `${formatHelper.currenyFormat(value)}`;
const CurrencyTypeProvider = props => (
  <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
);
const CurrencyEurFormatter = ({ value }) =>
  `${formatHelper.currenyFormat(value)}`;
const CurrencyEurTypeProvider = props => (
  <DataTypeProvider formatterComponent={CurrencyEurFormatter} {...props} />
);

const BooleanFormatter = ({ value }) =>
  value ? (
    <CheckedIcon
      color="primary"
      style={{ marginBottom: -3, width: 16, height: 16 }}
    />
  ) : (
    <UnCheckedIcon
      style={{ marginBottom: -3, width: 16, height: 16 }}
      color="disabled"
    />
  );

const BooleanTypeProvider = props => (
  <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />
);

const RangeFormatter = ({ value }) =>
  value ? `${value[0]}-${value[1] ? value[1] : ""}` : "";

const RangeTypeProvider = props => (
  <DataTypeProvider formatterComponent={RangeFormatter} {...props} />
);

const TagsFormatter = ({ value /*, row, column*/ }) => {
  return value && value.join(", ");
};

const TagsTypeProvider = props => (
  <DataTypeProvider formatterComponent={TagsFormatter} {...props} />
);

const CustomFormatter = ({ value, row, column }) => {
  return column.render(value, row);
};

const CustomTypeProvider = props => (
  <DataTypeProvider formatterComponent={CustomFormatter} {...props} />
);

class DataTableDx extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      selectedRow: null,
      dates: this.defaultRange()
    };
  }

  defaultRange = () => {
    const defaultRange = dateHelper.getRange("today");
    const storageRange = dateHelper.datesToRange(
      JSON.parse(sessionStorage.getItem("accounting-range"))
    );
    return storageRange || defaultRange;
  };

  changeDates = (dates, range) => {
    this.setState({ dates });
    this.props.fetchData({
      from: dates.start.format("YYYY-MM-DD"),
      to: dates.end.format("YYYY-MM-DD")
    });
    if (dates) {
      sessionStorage.setItem(
        "accounting-range",
        JSON.stringify(dateHelper.rangeToDates(dates))
      );
    } else {
      sessionStorage.removeItem("accounting-range");
    }
  };

  onSelectionChange = selection => {
    const { data } = this.props;

    let newSelection = parseInt(arr_diff(selection, this.state.selection)[0]);
    if (newSelection === this.state.selection[0]) {
      newSelection = [];
      window.accountingSelectedRow = null;
      this.setState({ selection: [], selectedRow: null });
    } else {
      window.accountingSelectedRow = data[newSelection];
      this.setState({
        selection: [newSelection],
        selectedRow: data[newSelection]
      });
    }
  };

  onChangePage = page => {
    const { onChangePage } = this.props;
    onChangePage(page + 1);
  };

  onColumnWidthsChange = columnWidths => {
    this.props.onColumnWidthsChange(columnWidths);
  };

  onFiltersChange = filters => {
    //console.log(filters);
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.props.onChangeFilters(filters);
    }, 800);
  };

  setSorting = sorting => {
    //console.log("Rendezünk", sorting);
    this.props.onChangeSorting(sorting);
  };
  setHiddenColumnNames = hiddenColumnNames => {
    //console.log("Rendezünk", sorting);
    this.props.onChangeHiddenColumnNames(hiddenColumnNames);
  };

  setSearchValue = searchValue => {
    //console.log("Keresünk", searchValue);
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.props.onChangeSearchValue(searchValue);
    }, 800);
  };

  render() {
    const { classes, ...formProps } = this.props;

    const {
      data,
      onEdit,
      onView,
      onDelete,
      onCreate,
      filterable,
      showRefreshIcon,
      canEdit,
      canDelete,
      canView,
      canCreate,
      fetchData,
      filters,
      sorting,
      loading,
      perPage,
      total,
      page,
      searchValue,
      columnWidths,
      hiddenColumnNames,
      rowStyle,
      canDeleteFunc,
      title,
      customActions
    } = this.props;

    const { dates, selectedRow, selection } = this.state;
    const grid = this.props.options ? this.props.options.table : null;

    if (!grid) {
      return <CircularProgress size={24} />;
    }

    let visibleColumns = grid.columnsOrder.filter(key => {
      return grid.columns[key] && !grid.columns[key].hidden ? true : false;
    });

    Object.keys(grid.columns).forEach(key => {
      const col = grid.columns[key];
      if (col.hidden) {
        return false;
      }
      if (visibleColumns.indexOf(key) >= 0) {
        return false;
      }
      visibleColumns.push(key);
    });

    const columns = visibleColumns.map((key, index) => {
      const col = grid.columns[key];
      const label = col.name || key;
      return {
        name: key,
        title: label,
        render: col.render,
        filter: col.filter,
        filters: filters,
        onChangeFilters: this.props.onChangeFilters
      };
    });

    const defColumnWidths = visibleColumns.map((key, index) => {
      const col = grid.columns[key];
      return {
        columnName: key,
        width: col.width || 120
      };
    });

    //const currencyColumns = ["amount"];
    const currencyColumns = visibleColumns.filter(key => {
      const type = grid.columns[key] ? grid.columns[key].type : null;
      return type === "Ft";
    });

    const currencyEurColumns = visibleColumns.filter(key => {
      const type = grid.columns[key] ? grid.columns[key].type : null;
      return type === "Eur";
    });

    const boolColumns = visibleColumns.filter(key => {
      const type = grid.columns[key] ? grid.columns[key].type : null;
      return type === "bool";
    });

    const rangeColumns = visibleColumns.filter(key => {
      const type = grid.columns[key] ? grid.columns[key].type : null;
      return type === "range";
    });

    const tagsColumns = visibleColumns.filter(key => {
      const type = grid.columns[key] ? grid.columns[key].type : null;
      return type === "tags";
    });

    const customColumns = visibleColumns.filter(key => {
      return grid.columns[key] && grid.columns[key].render;
    });

    if (columns.length === 0) {
      return <CircularProgress size={24} />;
    }

    const actions = customActions ? [].concat(customActions) : [];

    canView &&
      actions.push({
        type: "view",
        icon: <ViewIcon fontSize="small" />,
        action: onView
      });

    canEdit &&
      actions.push({
        type: "edit",
        icon: <EditIcon fontSize="small" />,
        action: onEdit
      });
    canDelete &&
      actions.push({
        type: "delete",
        icon: <DeleteIcon fontSize="small" />,
        action: onDelete,
        canDeleteFunc: canDeleteFunc
      });
    //Fordítások
    let messages = {
      searchPanel: {
        searchPlaceholder: localize("basic", "Keresés")
      },
      tableFilterRow: {
        filterPlaceholder: localize("basic", "Keresés")
      },
      columnChooser: {
        showColumnChooser: localize("basic", "Oszlopválasztó megjelenítése")
      },
      table: {
        noData: localize("basic", "Nincs adat")
      },
      pagingPanel: {
        info: localize("basic", "{from}-{to} / {count}")
      }
    };

    return (
      <Grid rows={data} columns={columns} key={visibleColumns.join("_")}>
        <CurrencyTypeProvider for={currencyColumns} />
        <CurrencyEurTypeProvider for={currencyEurColumns} />
        <BooleanTypeProvider for={boolColumns} />
        <RangeTypeProvider for={rangeColumns} />
        <CustomTypeProvider for={customColumns} />
        <TagsTypeProvider for={tagsColumns} />
        <SearchState
          onValueChange={this.setSearchValue}
          defaultValue={searchValue}
        />
        <SortingState sorting={sorting} onSortingChange={this.setSorting} />
        <PagingState
          currentPage={page - 1}
          onCurrentPageChange={this.onChangePage}
          pageSize={perPage}
        />
        <CustomPaging totalCount={total} />
        {filterable && (
          <FilteringState
            onFiltersChange={this.onFiltersChange}
            defaultFilters={filters}
          />
        )}
        <Table
          messages={messages.table}
          rowComponent={props => {
            if (rowStyle) {
              return (
                <Table.Row
                  {...props}
                  style={{ ...props.style, ...rowStyle(props.row) }}
                />
              );
            } else {
              return <Table.Row {...props} />;
            }
          }}
          cellComponent={props => {
            let style = { ...props.style, paddingTop: 0, paddingBottom: 0 };
            return (
              <Table.Cell {...props} style={style}>
                {props.column.render
                  ? props.column.render(props.row[props.column.name], props.row)
                  : props.row[props.column.name]}
              </Table.Cell>
            );
          }}
          noDataCellComponent={props => {
            return (
              <Table.NoDataCell
                {...props}
                style={{ ...props.style, width: 0 }}
                colSpan={props.colSpan - 1}
              >
                {props.children}
              </Table.NoDataCell>
            );
          }}
          stubCellComponent={() => {
            return <td style={{ width: 0 }} />;
          }}
          stubHeaderCellComponent={() => {
            return <td style={{ width: 0 }} />;
          }}
          tableComponent={CustomTable}
        />

        <TableColumnResizing
          columnWidths={
            Object.keys(columnWidths).length > 0
              ? columnWidths
              : defColumnWidths
          }
          onColumnWidthsChange={this.onColumnWidthsChange}
        />
        <TableHeaderRow rowComponent={HeaderRowComponent} showSortingControls />
        <PagingPanel messages={messages.pagingPanel} />
        <ActionsColumn
          fetchData={fetchData}
          actions={actions}
          width={actions.length * 40}
        />
        {filterable && (
          <TableFilterRow
            messages={messages.tableFilterRow}
            cellComponent={FilterCell}
          />
        )}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={this.setHiddenColumnNames}
        />
        <Toolbar />
        <ColumnChooser messages={messages.columnChooser} />
        <SearchPanel messages={messages.searchPanel} />
        <CustomToolbarMarkup
          changeGroup={this.changeGroup}
          changeDates={this.changeDates}
          dates={dates}
          fecthData={fetchData}
          onCreate={onCreate}
          showRefreshIcon={showRefreshIcon}
          loading={loading}
          title={title}
          canCreate={canCreate}
        />
      </Grid>
    );
  }
}

DataTableDx.defaultProps = {
  filterable: true,
  readOnly: false,
  gridOptionsKey: "grid",
  showRefreshIcon: true,
  customContainer: false,
  alwaysShowDetailsComponent: false,
  columnsFormat: {},
  summaryItems: [],
  canEdit: true,
  canDelete: true,
  canView: true,
  canCreate: true
};

DataTableDx.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  filterable: PropTypes.bool,
  fetchData: PropTypes.func,
  onChangePage: PropTypes.func, //Lapozó függvény
  readOnly: PropTypes.bool,
  showRefreshIcon: PropTypes.bool,
  customContainer: PropTypes.bool,
  alwaysShowDetailsComponent: PropTypes.bool,
  gridOptionsKey: PropTypes.string,
  detailsComponent: PropTypes.func,
  columnsFormat: PropTypes.object,
  summaryItems: PropTypes.array,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  canView: PropTypes.bool,
  canCreate: PropTypes.bool
};

export default DataTableDx;
