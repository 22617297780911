import React, { Component } from "react";
import TextField from "@material-ui/core/TextField/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import inputStyle from "./inputStyle.js";
import { withStyles } from "@material-ui/core/styles";
import Wrapper from "./Wrapper";
import classNames from "classnames";
import PropTypes from "prop-types";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import MaskedInput from "react-text-mask";
import { inputMask, valueCorrector } from "helpers/maskHelper";

function TextMaskCustom(props) {
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      //mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      mask={inputMask(mask)}
      //mask={mobilNumberMask}
      //placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  mask: PropTypes.string.isRequired
};

class InputMaskedBlurField extends Component {
  static propTypes = {
    input: PropTypes.any.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.shape({
      touched: PropTypes.bool.isRequired,
      error: PropTypes.string,
      warning: PropTypes.string
    }),
    fieldError: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.input.value
    };
    this.input = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.
    if (nextProps.input.value !== this.state.value) {
      this.setState({ value: nextProps.input.value });
    }
  }

  handleBlur = value => {
    if (this.props.handleBlur) {
      this.props.handleBlur(value);
    }
  };

  focusMaskHack = evt => {
    const { mask } = this.props;
    const inp = this.input.current;
    let index = evt.target.value.indexOf("_");
    if (mask && index >= 0 /*&& !valueCorrector(mask, evt.target.value)*/) {
      if (inp.createTextRange) {
        var part = inp.createTextRange();
        part.move("character", index);
        part.select();
      } else if (inp.setSelectionRange) {
        inp.setSelectionRange(index, index);
      }
    }
  };
  onFocus = evt => {
    const {
      input: { onFocus }
    } = this.props;
    this.focusMaskHack(evt);
    onFocus(evt);
  };

  onClick = evt => {
    const {
      input: { onClick }
    } = this.props;
    this.focusMaskHack(evt);
    onClick && onClick(evt);
  };

  onBlur = evt => {
    const {
      input: { onBlur, onChange },
      mask
    } = this.props;
    if (mask) {
      evt.target = {
        id: evt.target.id,
        value: valueCorrector(mask, evt.target.value)
      };
    }
    onChange(evt);
    onBlur(evt);
  };
  render() {
    const {
      input: inputFull,
      type,
      fieldError,
      label,
      readOnly,
      classes,
      theme,
      labelBefore,
      isRequired,
      labelInfo,
      TextInputProps,
      mask
    } = this.props;

    const { warning, touched, error, autofilled } = this.props.meta;
    const { onChange, onBlur, onFocus, ...input } = inputFull;
    const { value } = this.state;
    const labelClass = classNames(
      classes.inputLabel,
      labelBefore ? classes.labelBefore : null
    );
    const inputRootClass = classNames(
      classes.bootstrapRoot,
      labelBefore ? classes.bootstrapRootBefore : null
    );

    const InputProps = {
      InputProps: {
        inputComponent: mask ? TextMaskCustom : undefined,
        classes: {
          root:
            inputRootClass +
            (this.props.inputRootClass
              ? " " + classes[this.props.inputRootClass]
              : ""),
          input: classes.bootstrapInput
        },
        ...TextInputProps,
        ...(type === "password" ? { type: "text" } : {})
      }
    };

    let labelAdvanced = label;

    if (typeof label === "string") {
      if (isRequired) {
        labelAdvanced = label + " *";
      }

      if (labelInfo) {
        labelAdvanced = (
          <div>
            {labelAdvanced}
            <Tooltip title={labelInfo}>
              <InfoIcon
                color="primary"
                style={{
                  marginLeft: 6,
                  marginTop: -5,
                  marginBottom: -5,
                  width: 20,
                  height: 20
                }}
              />
            </Tooltip>
          </div>
        );
      }
    }
    //console.log(this.props);;
    return (
      <Wrapper {...this.props} value={value}>
        {/*<FormControl
              className={classes.margin}
              fullWidth
            >
        <InputLabel shrink htmlFor="bootstrap-input" className={classes.bootstrapFormLabel}>
        {label}
        </InputLabel>
        <Input
        {...input}
          defaultValue={input.value || ''}
          classes={{
            root: classes.bootstrapRoot,
            input: classes.bootstrapInput,
          }}
          readOnly={readOnly}
          onBlur={(event) => this.handleBlur(event.target.value)}
          autoComplete={'new-'+input.name}
        />
      </FormControl>*/}
        <TextField
          inputRef={this.input}
          {...input}
          onFocus={this.onFocus}
          onChange={evt => this.setState({ value: evt.target.value })}
          onClick={this.onClick}
          onBlur={this.onBlur}
          label={labelAdvanced}
          value={value}
          variant="outlined"
          fullWidth
          //defaultValue={input.value || ''}
          //margin="normal"
          //className="mt-1 my-sm-3"
          disabled={readOnly ? true : false}
          type={type === "password" ? "text" : type}
          className={classes.root}
          autoComplete={"new-" + input.name}
          error={fieldError || (touched && (warning || (error ? true : false)))}
          helperText={fieldError || (touched && (warning || error))}
          inputProps={{
            mask: mask,
            disabled: readOnly ? true : false,
            style:
              type === "password"
                ? {
                    fontFamily: "text-security-disc"
                  }
                : {
                  backgroundColor: autofilled
                    ? theme.palette.autofill.background
                    : "auto"
                }
          }}
          //onBlur={event => this.handleBlur(event.target.value)}
          onKeyPress={this.props.onKeyPress}
          multiline={this.props.multiline}
          rows={this.props.rows}
          InputLabelProps={{
            shrink: true,
            className: labelClass
          }}
          FormHelperTextProps={{ className: classes.formHelperText }}
          {...InputProps}
        />
      </Wrapper>
    );
  }
}

export default withStyles(inputStyle, { withTheme: true })(
  InputMaskedBlurField
);
