import axios from 'axios';
import qs from 'qs';
import { get } from 'lodash';

const STICKERS_LIST_LOAD = 'STICKERS_LIST_LOAD';
const STICKERS_LIST_SUCCESS = 'STICKERS_LIST_SUCCESS';
const STICKERS_LIST_FAILED = 'STICKERS_LIST_FAILED';
const STICKERS_SET_LOADING = 'STICKERS_SET_LOADING';

const STICKERS_FORM_LOAD = 'STICKERS_FORM_LOAD';
const STICKERS_FORM_SUCCESS = 'STICKERS_FORM_SUCCESS';
const STICKERS_FORM_FAILED = 'STICKERS_FORM_FAILED';

const STICKER_DELETE_LOAD = 'STICKER_DELETE_LOAD';
const STICKER_DELETE_SUCCESS = 'STICKER_DELETE_SUCCESS';
const STICKER_DELETE_FAILED = 'STICKER_DELETE_FAILED';

const STICKER_EDIT_LOAD = 'STICKER_EDIT_LOAD';
const STICKER_EDIT_SUCCESS = 'STICKER_EDIT_SUCCESS';
const STICKER_EDIT_FAILED = 'STICKER_EDIT_FAILED';

const STICKER_VIEW_LOAD = 'STICKER_VIEW_LOAD';
const STICKER_VIEW_SUCCESS = 'STICKER_VIEW_SUCCESS';
const STICKER_VIEW_FAILED = 'STICKER_VIEW_FAILED';

const STICKERS_RESET = 'STICKERS_RESET';
const STICKERS_RESET_FORM = 'STICKERS_RESET_FORM';

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit:process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: '',
  orderMode: 'ASC',
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case STICKERS_RESET:
    //   return {
    //     ...initialState
    //   };
    case STICKERS_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case STICKER_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case STICKERS_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false
      };
    case STICKER_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case STICKER_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case STICKERS_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case STICKERS_FORM_FAILED:
    case STICKER_EDIT_FAILED:
    case STICKER_DELETE_FAILED:
    case STICKERS_LIST_FAILED:
    case STICKER_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false
      };
    case STICKERS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode
      };
      case STICKER_VIEW_SUCCESS:
        return {
            ...state,
            loading: false,
            success: true,
            failed: false,
            viewData: action.viewData,
            commentThread: action.commentThread || false,
            viewLoading: false
        };
    case STICKERS_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case STICKER_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case STICKER_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: action.id,
        viewLoading: false
      };
    case STICKERS_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
}

export const reset = () => dispatch => {
  dispatch({
    type: STICKERS_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: STICKERS_RESET_FORM
  });
};

export const updateStickers = (model,id,data) => dispatch => {
  dispatch({
    type: STICKER_EDIT_LOAD
  });

    let obj = {};
    obj[model] = data;

  return axios.put('/v1/stickers/'+id, obj).then(
      () => dispatch({
        type: STICKER_EDIT_SUCCESS
      })
  ).catch(error => {
    dispatch({
      type: STICKER_EDIT_FAILED,
      message: get(error, 'response.data.message', null),
      formErrors: get(error, 'response.data.form_errors', {})
    })
  });
};

export const viewStickers = (id) => dispatch => {
    dispatch({
        type: STICKER_VIEW_LOAD
    });
    return axios.get('/v1/stickers/'+id).then(
        response => dispatch({
            type: STICKER_VIEW_SUCCESS,
            viewData: get(response, 'data.data'),
            commentThread: get(response, 'data.commentThread', false)
        })
    ).catch(error => {
        dispatch({
            type: STICKER_VIEW_FAILED,
            message: get(error, 'response.data.message', null)
        })
    });
};

export const createStickers = (model, data) => dispatch => {
  dispatch({
    type: STICKER_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios.post('/v1/stickers', obj).then(response => dispatch({
    type: STICKER_EDIT_SUCCESS,
    id: get(response, 'data.data.id', null),
  })).catch(error => {
    dispatch({
      type: STICKER_EDIT_FAILED,
      message: get(error, 'response.data.message', null),
      formErrors: get(error, 'response.data.form_errors', {})
    })
  });
};

export const stickerDelete = (id) => dispatch => {
  dispatch({
    type: STICKER_DELETE_LOAD
  });
  return axios.delete('/v1/stickers/'+id)
      .then((response) => dispatch({
        type: STICKER_DELETE_SUCCESS,
        id
      })).catch(error => dispatch({
        type: STICKER_DELETE_FAILED,
        message: get(error, 'response.data.message', null)
      }));
};

export const setLoading = (loading) => dispatch => {
  dispatch({
    type: STICKERS_SET_LOADING,
    loading
  });
};

export const stickersList = (page, keyword, sort, filters, perPage) => dispatch => {
  dispatch({
    type: STICKERS_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    'per-page': perPage
  };

  params[filters.model] = filters.filters;

  return axios.get(
      '/v1/stickers',
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
  ).then(response => {
    let sort = get(response, 'data.sort', null),
        mode = 'ASC';
    if(sort && sort.indexOf('-') !== -1) {
      mode = 'DESC';
      sort = sort.substr(1);
    }
    return dispatch({
      type: STICKERS_LIST_SUCCESS,
      data: get(response, 'data.data', null),
      page: get(response, 'data.page', 0),
      limit: get(response, 'data.per-page', 0),
      total: get(response, 'data.total', 0),
      order: sort,
      orderMode: mode
    });
  })
  .catch(error => dispatch({
    type: STICKERS_LIST_FAILED,
    message: get(error, 'response.data.message', null)
  }));
};
