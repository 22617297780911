import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import AddToCartIcon from "@material-ui/icons/AddShoppingCart";
import TextField from "Components/New/Input/TextField/TextField";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { add } from "redux/modules/shopCart";
import localize from "helpers/localize";

const styles = theme => ({
  root: {
    paddingLeft: 8
  },
  rootRight: {
    paddingLeft: 8,
    textAlign: "right"
  },
  nextPrice: {
    //fontSize: "10px"
    fontStyle: "italic"
  },
  buttonIcon: {
    marginLeft: theme.spacing.unit
  }
});

class AddToCart extends React.Component {
  addToCart = () => {
    const { id, qty } = this.props;
    if (isNaN(qty)) {
      globalMessage.fatalError(
        `${localize(
          "shop",
          "A darabszám csak nullától nagyobb egész szám lehet."
        )} (id: ${id})`
      );
    } else {
      this.props.add(id, qty).then(response => {
        //console.log("addCart", response);
        globalMessage.success(
          `${qty} ${localize(
            "shop",
            "darab termék a kosarába került."
          )} (id: ${id})`
        );
      });
    }
  };

  onChangeQty = evt => {
    const { onChangeQty } = this.props;
    if (evt.target.value === "") {
      onChangeQty(qty);
      return;
    }
    let qty = parseInt(evt.target.value);
    qty = isNaN(qty) || qty < 1 ? 1 : qty;
    onChangeQty(qty);
  };

  render() {
    const { classes, qty, inputClassName, buttonClassName, type } = this.props;
    return (
      <Fragment>
        <div className={inputClassName}>
          <TextField
            value={qty || ""}
            onChanged={this.onChangeQty}
            style={{ minWidth: 40 }}
          />
        </div>

        {type === "icon" ? (
          <IconButton
            className={buttonClassName}
            onClick={this.addToCart}
            color="primary"
            //aria-expanded={expanded}
            aria-label="add to cart"
          >
            <AddToCartIcon />
          </IconButton>
        ) : (
          <Button
            className={buttonClassName}
            onClick={this.addToCart}
            color="primary"
            variant="contained"
            //aria-expanded={expanded}
            aria-label="add to cart"
          >
            {localize("shop", "Kosárba rak")}
            <AddToCartIcon className={classes.buttonIcon} />
          </Button>
        )}
      </Fragment>
    );
  }
}

AddToCart.defaultProps = {
  type: "icon"
};

AddToCart.propTypes = {
  classes: PropTypes.object.isRequired,
  qty: PropTypes.number,
  onChangeQty: PropTypes.func.isRequired,
  id: PropTypes.number,
  inputClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  type: PropTypes.string
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ add }, dispatch);
};

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps
  )(AddToCart)
);
