import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import modalStyle from "./modalStyle.jsx";

class Modal extends React.Component {
  render() {
    const {
      classes,
      title,
      loading,
      Icon,
      yesText,
      noText,
      onClose,
      onAccept,
      onCancel,
      disableIcon,
      disableCancel,
      disableOk,
      disableCloseIcon,
      keepMounted,
      maxWidth,
      open,
      customButtons,
      error,
      overflowVisibleBody,
      zIndex,
      positionTop,
      YesIcon,
      fullScreenMobile
    } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
          scrollPaper: positionTop
            ? classes.scrollPaperTop
            : classes.scrollPaper
        }}
        //contentStyle={{ width: "100%", maxWidth: "none" }}
        style={zIndex ? { zIndex } : {}}
        maxWidth={maxWidth}
        open={open}
        keepMounted={keepMounted}
        onClose={this.close}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={error ? classes.modalHeaderError : classes.modalHeader}
        >
          {disableCloseIcon !== true && (
            <Button
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="primary"
              onClick={onClose}
            >
              <CloseIcon className={classes.modalClose} />
            </Button>
          )}
          {!disableIcon && (
            <Icon className={classes.titleIcon} fontSize="small" />
          )}
          <Typography
            style={disableIcon ? { textIndent: 0, marginTop: 0 } : {}}
            className={classes.modalTitle}
            variant="subtitle1"
          >
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent
          className={
            (overflowVisibleBody
              ? classes.modalBodyOverflowVisible
              : classes.modalBody) +
            (fullScreenMobile ? ` ${classes.modalFullScreenMobile}` : "")
          }
        >
          {this.props.children}
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          {!disableCancel && (
            <Button
              onClick={onCancel}
              color="secondary"
              disabled={loading === true}
            >
              {noText}
            </Button>
          )}{" "}
          {customButtons && customButtons()}
          {!disableOk && (
            <Button onClick={onAccept} color="primary" disabled={loading}>
              {YesIcon && (
                <YesIcon fontSize="small" style={{ marginRight: 6 }} />
              )}
              {yesText}
            </Button>
          )}
          {loading && (
            <CircularProgress
              className={classes.progress}
              color="inherit"
              size={24}
            />
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

Modal.defaultProps = {
  yesText: "Ok",
  noText: "Mégsem",
  Icon: WarningIcon,
  keepMounted: true,
  maxWidth: "sm"
};

Modal.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  Icon: PropTypes.any,
  loading: PropTypes.bool,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  disableIcon: PropTypes.bool,
  open: PropTypes.bool,
  disableCancel: PropTypes.bool,
  disableOk: PropTypes.bool,
  keepMounted: PropTypes.bool,
  disableCloseIcon: PropTypes.bool,
  children: PropTypes.any,
  customButtons: PropTypes.func,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  error: PropTypes.bool,
  fullScreenMobile: PropTypes.bool,
  overflowVisibleBody: PropTypes.bool,
  zIndex: PropTypes.number,
  YesIcon: PropTypes.any,
  positionTop: PropTypes.bool //Felülre rakja am odalt
};

export default withStyles(modalStyle)(Modal);
