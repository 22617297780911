import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";


import List from "../../../../Components/List/List";
import ContainerHeader from "../../../../Components/ContainerHeader";
import { get } from "lodash";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import NotInterested from "@material-ui/icons/NotInterested";
import Done from "@material-ui/icons/Done";
import DeletePermission from "../../../Permissions/DeletePermission";
import Navbar from "./Navbar";
import Button from "@material-ui/core/Button/Button";
import localize from "helpers/localize";

class UsersList extends PureComponent {
  getListHeader = () => {
    return this.props.getListHeader("/users", `&section=${this.props.mode}`);
  };

  getList = (page, keyword, sort, filters, perPage) => {
    return this.props.getList(
      page,
      keyword,
      sort,
      filters,
      perPage,
      this.props.mode
    );
  };

  handleActivate = id => {
    this.props.userListApproval(id);
  };

  activatedTitle = () => {
    switch (this.props.mode) {
      case "new":
        return localize("users", "Jóváhagyás");
      case "inactivated":
        return localize("users", "Aktiválás");
      default:
        return localize("users", "Inaktiválás");
    }
  };

  customIcons = obj => {
    return (
      <DeletePermission
        isOwn={get(obj, "is_owner", false)}
        match={this.props.match}
        module={this.props.module}
      >
        <Tooltip title={this.activatedTitle()}>
          <Button
            className="jr-btn jr-btn-xs"
            onClick={this.handleActivate.bind(this, obj.id)}
            component="span"
          >
            {this.props.mode === "active" && <NotInterested />}
            {this.props.mode !== "active" && <Done />}
          </Button>
        </Tooltip>
      </DeletePermission>
    );
  };

  getTitle = () => {
    switch (this.props.mode) {
      case "new":
        return localize("menu", "Új Felhasználók");
      case "active":
        return localize("menu", "Felhasználók");
      case "inactivated":
        return localize("menu", "Inaktív felhasználók");
      default:
        return localize("menu", "Felhasználók");
    }
  };

  render() {
    return (
      <div
        className="animated slideInUpTiny animation-duration-3"
        key={this.props.mode}
      >
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>
        <Navbar />
        <ContainerHeader title={this.getTitle()} />

        <List
          {...this.props}
          perms={this.props.perms}
          getListHeader={this.getListHeader.bind(this)}
          getList={this.getList.bind(this)}
          disabledCreate={["inactivated", "new"].indexOf(this.props.mode) >= 0}
          customIcons={this.customIcons.bind(this)}
        />
      </div>
    );
  }
}

export default UsersList;
