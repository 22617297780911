import React from "react";
import PropTypes from "prop-types";
//import "react-table/react-table.css";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
//import TextField from "@material-ui/core/TextField";
import TextField from "Components/New/Input/TextField/TextField";
//import RDateRangePicker from "react-daterange-picker";
import RDateRangePicker from "./RDateRangePicker";
import "react-daterange-picker/dist/css/react-calendar.css"; // For some basic styling. (OPTIONAL)
import dateHelper from "helpers/dateHelper";
import moment from "moment";
import PaginationArrow from "react-daterange-picker/dist/PaginationArrow";
import { CircularProgress } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

/**
 * Formos adat megjelenítés és szerkesztés kezelése
 */
class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.parseValue(props.value),
      name: this.parseName(props.value),
      open: false,
      loading: false,
      loadedRanges: {},
      dateRanges: props.dateRanges || [],
      stateDefinitions: props.stateDefinitions || {}
    };
  }

  parseValue = value => {
    if (typeof value === "string") {
      return dateHelper.getRange(value);
    } else {
      return value;
    }
  };

  onPaginate = direction => {
    console.log("onPaginate", direction);
  };

  parseName = range => {
    if (typeof range === "string") {
      switch (range) {
        case "today":
          return "Ma";
        case "thisWeek":
          return "Ez a hét";
        case "thisMonth":
          return "Ez a hónap";
        case "lastWeek":
          return "Előző hét";
        case "lastMonth":
          return "Előző hónap";
        case "last7Days":
          return "Elmúlt 7 nap";
        case "last30Days":
          return "Elmúlt 30 nap";
        default:
          return "Egyéni időszak";
      }
    } else if (range) {
      return "Egyéni időszak";
    } else {
      return null;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    //this.setState({ value: nextProps.value });
  }

  onOpen = () => this.setState({ open: true });

  onClose = () => {
    //const v = value ? value : this.state.value;
    if (!this.unMounted) {
      // This is bad.
      this.setState({ open: false });
    }
    /*
    if (this.props.value !== v) {
      console.log("dirty", value, this.state.value);
      if (v) {
        //this.props.onChange(d);
      }

      this.setState({ value: v, open: false });
    } else {
      this.setState({ open: false });
    }*/
  };

  componentWillUnmount() {
    this.unMounted = true;
  }

  onSelect = (value, rangeKey) => {
    const { onChange } = this.props;
    onChange && onChange(value, typeof rangeKey === "string" ? rangeKey : null);
    this.onClose();
  };

  clearDates = evt => {
    evt.stopPropagation();
    this.props.onChange(null);
    this.onClose();
  };

  textValue = value => {
    if (!value) {
      return "";
    }
    const v = this.parseValue(value);
    if (!v || !v.start || !v.end) {
      return "";
    }

    const start = dateHelper.unixToDate(v.start.unix());
    const end = dateHelper.unixToDate(v.end.unix());
    return start + "-" + end;
  };

  fetchRanges = (start, end) => {
    const { fetchRanges } = this.props;
    clearTimeout(this.rangeTimeout);
    const range = `${start.format("YYY-MM-DD")}-${end.format("YYY-MM-DD")}`;
    let loadedRanges = Object.assign({}, this.state.loadedRanges);
    let dateRanges = this.state.dateRanges.slice();
    if (loadedRanges[range]) {
      return;
    } else {
      loadedRanges[range] = true;
    }

    this.rangeTimeout = setTimeout(() => {
      if (fetchRanges) {
        this.setState({ loading: true });
        const promise = fetchRanges(start, end);
        if (promise && promise.then) {
          promise
            .then(newDateRanges => {
              this.setState({
                dateRanges: dateRanges.concat(newDateRanges),
                loading: false,
                loadedRanges
              });
            })
            .catch(error => {
              this.setState({ loading: false });
            });
        }
      }
    }, 500);
  };

  render() {
    const {
      label,
      disableMaterailProps,
      showRangeName,
      showClearButton,
      ...other
    } = this.props;
    const InputComponent = this.props.InputComponent
      ? this.props.InputComponent
      : TextField;
    const { open, dateRanges, loading } = this.state;
    const value = this.parseValue(this.props.value);
    //console.log("YEEEE", value, this.props.value);
    const buttonProps = {
      size: "small",
      color: "primary",
      mini: true,
      //variant: "extendedFab",
      style: {
        display: "inline-block",
        fontSize: 10
      }
    };

    const materialProps = disableMaterailProps
      ? {}
      : {
          //margin: "normal",
          //variant: "outlined",
          helperText: this.props.helperText
            ? this.props.helperText
            : showRangeName
            ? this.state.name
            : null
        };

    return (
      <div>
        <InputComponent
          onClick={this.props.disabled ? null : this.onOpen}
          value={this.textValue(value)}
          label={label}
          error={this.props.helperText ? true : false}
          disabled={this.props.disabled}
          fullWidth
          {...materialProps}
        />
        <Dialog
          onClose={this.onClose}
          aria-labelledby="simple-dialog-title"
          open={open}
          //{...other}
          //fullWidth={true}
          maxWidth={false}
        >
          {label ? (
            <DialogTitle id="simple-dialog-title">{label}</DialogTitle>
          ) : (
            ""
          )}
          {loading && (
            <CircularProgress
              color="primary"
              size={30}
              style={{
                position: "absolute",
                left: "calc(50% - 15px)",
                top: 80
              }}
            />
          )}

          {open ? (
            <RDateRangePicker
              onSelect={this.onSelect}
              {...other}
              //value={dateRanges[0]}
              value={value}
              firstOfWeek={1}
              locale="hu"
              selectionType="range"
              numberOfCalendars={2}
              label="Időszak"
              singleDateRange={true}
              dateStates={dateRanges}
              onPageChange={(start, end) => {
                /*console.log(
                  "onPageChange",
                  start.format("YYYY-MM-DD"),
                  end.format("YYYY-MM-DD")
                );*/
                this.fetchRanges(start, end);
              }}
              /*paginationArrowComponent={props => (
                <PaginationArrow
                  {...props}
                  onTrigger={() => {
                    //console.log("onPaginate", props.direction);
                    this.onPaginate(props.direction);
                    props.onTrigger();
                  }}
                />
              )}*/
            />
          ) : (
            ""
          )}
          <div style={{ height: 60 }}>
            {showClearButton && (
              <Button
                {...buttonProps}
                onClick={this.clearDates}
                color="secondary"
              >
                Töröl
              </Button>
            )}
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(dateHelper.getRange("today"), "today")
              }
            >
              Ma
            </Button>
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(dateHelper.getRange("thisWeek"), "thisWeek")
              }
            >
              Ez a hét
            </Button>
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(dateHelper.getRange("lastWeek"), "lastWeek")
              }
            >
              Előző hét
            </Button>
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(dateHelper.getRange("last7Days"), "last7Days")
              }
            >
              Elmúlt 7 nap
            </Button>
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(dateHelper.getRange("thisMonth"), "thisMonth")
              }
            >
              Ez a hónap
            </Button>
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(dateHelper.getRange("last30Days"), "last30Days")
              }
            >
              Elmúlt 30 nap
            </Button>
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(dateHelper.getRange("lastMonth"), "lastMonth")
              }
            >
              Előző hónap
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

DateRangePicker.defaultProps = {
  showRangeName: false,
  showClearButton: true,
  defaultState: "default",
  stateDefinitions: {
    available: {
      color: green[200],
      label: "Available"
    },
    default: {
      //selectable: false,
      color: null,
      label: "Unavailable"
    }
  }
};
DateRangePicker.propTypes = {
  InputComponent: PropTypes.any,
  label: PropTypes.string,
  disableMaterailProps: PropTypes.bool,
  showRangeName: PropTypes.bool,
  showClearButton: PropTypes.bool,
  stateDefinitions: PropTypes.object,
  fetchRanges: PropTypes.func,
  defaultState: PropTypes.string
};
export default DateRangePicker;
