import axios from "axios";
import restHelper from "helpers/restHelper";
import { get, values, sumBy } from "lodash";

const CART_ORDER_FETCH_LOAD = "CART_ORDER_FETCH_LOAD";
const CART_ORDER_FETCH_SUCCESS = "CART_ORDER_FETCH_SUCCESS";
const CART_ORDER_FETCH_FAILED = "CART_ORDER_FETCH_FAILED";

const CART_ORDER_OPTIONS_FETCH_LOAD = "CART_ORDER_OPTIONS_FETCH_LOAD";
const CART_ORDER_OPTIONS_FETCH_SUCCESS = "CART_ORDER_OPTIONS_FETCH_SUCCESS";
const CART_ORDER_OPTIONS_FETCH_FAILED = "CART_ORDER_OPTIONS_FETCH_FAILED";

const CART_ORDER_CREATE_LOAD = "CART_ORDER_CREATE_LOAD";
const CART_ORDER_CREATE_SUCCESS = "CART_ORDER_CREATE_SUCCESS";
const CART_ORDER_CREATE_FAILED = "CART_ORDER_CREATE_FAILED";

const CART_ORDER_CHANGE_STEP = "CART_ORDER_CHANGE_STEP";
const CART_ORDER_CHANGE = "CART_ORDER_CHANGE";
const CART_ORDER_ERRORS_CHANGE = "CART_ORDER_ERRORS_CHANGE";

const initialState = {
  loading: false,
  success: true,
  message: null,
  errors: {},
  item: {},
  options: {},
  step: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Fetch options
    case CART_ORDER_OPTIONS_FETCH_LOAD:
      return {
        ...state,
        loading: true
      };
    case CART_ORDER_OPTIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: null,
        options: action.options
      };
    case CART_ORDER_OPTIONS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.message
      };
    //Fetch
    case CART_ORDER_FETCH_LOAD:
      return {
        ...state,
        loading: true
      };
    case CART_ORDER_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: null,
        item: action.item,
        options: action.options || state.options
      };
    case CART_ORDER_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.message
      };

    //Create
    case CART_ORDER_CREATE_LOAD:
      return {
        ...state,
        loading: true
      };
    case CART_ORDER_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: null,
        item: action.item
      };
    case CART_ORDER_CREATE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.message
      };
    //change order
    case CART_ORDER_CHANGE:
      return {
        ...state,
        item: action.item
      };
    //change error
    case CART_ORDER_ERRORS_CHANGE:
      return {
        ...state,
        errors: action.errors
      };
    //change order
    case CART_ORDER_CHANGE_STEP:
      return {
        ...state,
        step: action.step
      };

    default:
      return state;
  }
};

/**
 * Get list of cart items
 */
export const fetch = () => dispatch => {
  dispatch({
    type: CART_ORDER_FETCH_LOAD
  });
  return restHelper
    .view("shop/client")
    .then(response => {
      const item = get(response, "data", {});
      delete item.id;
      //Alapértelmezett értékek
      //item.delivery_method = "none";

      dispatch({
        type: CART_ORDER_FETCH_SUCCESS,
        item
      });

      return item;
    })
    .catch(error => {
      dispatch({
        type: CART_ORDER_FETCH_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const fetchOptions = () => dispatch => {
  dispatch({
    type: CART_ORDER_OPTIONS_FETCH_LOAD
  });
  return restHelper
    .view("shop/options")
    .then(response => {
      const options = get(response, "data", {});
      dispatch({
        type: CART_ORDER_OPTIONS_FETCH_SUCCESS,
        options
      });

      return options;
    })
    .catch(error => {
      dispatch({
        type: CART_ORDER_OPTIONS_FETCH_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const fetchWithOptions = () => dispatch => {
  dispatch({
    type: CART_ORDER_FETCH_LOAD
  });
  return restHelper
    .view("shop/options")
    .then(responseOptions => {
      const options = get(responseOptions, "data", {});

      return restHelper
        .view("shop/client")
        .then(response => {
          const item = get(response, "data", {});
          delete item.id;
          //Alapértelmezett értékek
          //item.delivery_method = "none";

          dispatch({
            type: CART_ORDER_FETCH_SUCCESS,
            item,
            options
          });

          return item;
        })
        .catch(error => {
          dispatch({
            type: CART_ORDER_FETCH_FAILED,
            message: get(error, "response.data.message", null)
          });
          throw error.response;
        });
    })
    .catch(errorOptions => {
      dispatch({
        type: CART_ORDER_OPTIONS_FETCH_FAILED,
        message: get(errorOptions, "response.data.message", null)
      });
      throw errorOptions.response;
    });
};

//TODO: Fake fetch
export const fetch2 = id => dispatch => {
  dispatch({
    type: CART_ORDER_FETCH_LOAD
  });
  return new Promise(function(resolve) {
    setTimeout(() => {
      const item = {
        tax_number: "10000001-1-13",
        name: "Molnár Bence",
        phone: "06-30-1234123",
        email: "aa@teszt.hu",
        zip: "1196",
        city: "Budapest",
        street: "Árpád",
        street_suffix: "út",
        street_details: "11",
        comment: "Dudáljon a futár.",
        delivery_phone: "06-30-2234123",
        delivery_zip: "1197",
        delivery_city: "Budapest",
        delivery_street: "Józsika",
        delivery_street_suffix: "utca",
        delivery_street_details: "23",
        delivery_method: "none"
      };
      dispatch({
        type: CART_ORDER_FETCH_SUCCESS,
        item
      });
      resolve(item);
    }, 1000);
  });
};

export const change = item => dispatch => {
  //console.log("change", item);
  dispatch({
    type: CART_ORDER_CHANGE,
    item
  });
  return item;
};

export const changeErrors = errors => dispatch => {
  dispatch({
    type: CART_ORDER_ERRORS_CHANGE,
    errors
  });
  return errors;
};

export const changeStep = step => dispatch => {
  dispatch({
    type: CART_ORDER_CHANGE_STEP,
    step
  });
  return step;
};

export const create = item => dispatch => {
  dispatch({
    type: CART_ORDER_CREATE_LOAD
  });
  return restHelper
    .post("shop/order", "ShopOrder", item)
    .then(response => {
      const item = get(response, "data", {});

      dispatch({
        type: CART_ORDER_CREATE_SUCCESS,
        item
      });

      return item;
    })
    .catch(error => {
      dispatch({
        type: CART_ORDER_CREATE_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const setStatus = (id, status) => {
  return restHelper
    .post(`shop-admin-orders/status/${id}`, "ShopOrderStatusForm", { status })
    .then(response => {
      const item = get(response, "data", {});
      return item;
    })
    .catch(error => {
      throw error.response;
    });
};

export const splitOrder = (id, item_ids) => {
  return restHelper
    .post(`shop-admin-orders/split/${id}`, "ShopOrderSplitForm", { item_ids })
    .then(response => {
      const item = get(response, "data", {});
      return item;
    })
    .catch(error => {
      throw error.response;
    });
};
