import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "handsontable/dist/handsontable.full.css";
import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import { Grid, TablePagination } from "@material-ui/core";
import CardItem from "./CardItem";
import ListItem from "./ListItem";
import { VIEW_MODE_CARD, VIEW_MODE_LIST } from "./Catalog";

const styles = theme => ({
  /*"@global": {
    ".rbt-input-hint": {
      //backgroundColor: "red!important",
      width: "100%",
      top: "2px!important"
    }
  },*/
  rowDeleted: {
    backgroundColor: "red!important",
    color: "white"
  },
  rowChanged: {
    backgroundColor: "green!important",
    color: "white"
  },
  actionCell: {
    width: 70,
    textAlign: "right"
  },
  actionCellIcon: {
    width: 24,
    height: 24
  },
  actionCellButton: {
    width: 28,
    height: 28,
    padding: 0
  },
  row: {
    height: "auto",
    "&:hover": {
      backgroundColor: "#ddd"
    }
  },
  backButton: {
    padding: 8
  },
  backIcon: {
    marginLeft: 4,
    marginRight: -4
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0
  },
  toolbarSpacer: {
    marginLeft: "auto"
  },
  filterCell: {
    paddingRight: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit / 2,
    "& :first-child": {
      paddingLeft: 0
    },
    "& :last-child": {
      paddingRight: 0
    }
  }
});

class Products extends React.Component {
  onChangePage = (evt, page) => {
    const { onChangePage } = this.props;
    onChangePage(page + 1);
  };
  onChangeRowsPerPage = evt => {
    const { onChangePerPage } = this.props;
    onChangePerPage(evt.target.value);
  };
  render() {
    const { classes, perPage, page, total } = this.props;

    const { data, viewMode } = this.props;

    return (
      <Fragment>
        <Grid container spacing={16}>
          {data.map((item, rowIndex) => {
            return viewMode === VIEW_MODE_CARD ? (
              <Grid key={rowIndex} item xs={12} sm={6} md={4} lg={3}>
                <CardItem item={item} />
              </Grid>
            ) : (
              <Grid key={rowIndex} item xs={12}>
                <ListItem item={item} />
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <TablePagination
              labelRowsPerPage={localize("shop", "Termék/oldal:")}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to === -1 ? count : to} / ${count}`
              }
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={total}
              rowsPerPage={parseInt(perPage)}
              page={parseInt(page) - 1}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true
              }}
              //onChangePage={() => {}}
              onChangePage={this.onChangePage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
              //ActionsComponent={TablePaginationActions}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

Products.defaultProps = {
  data: []
};

Products.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array,
  viewMode: PropTypes.string.isRequired
};

export default withStyles(styles)(Products);
