import React from "react";

import UsersForm from "./ArticlesForm";
import EditPage from "Components/Extends/EditPage";
import Edit from "Components/Edit/Edit";
import localize from "helpers/localize";

class EditArticle extends EditPage {
  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("articles", "Hír megtekintése");
    }
    return this.props.match.params.id
      ? localize("articles", "Hír szerkesztése")
      : localize("articles", "Hír létrehozása");
  };

  render() {
    return (
      <Edit
        getPagetitle={this.getPageTitle}
        isViewPage={this.isViewPage()}
        {...this.props}
        key={"EditArticle" + (this.isViewPage() ? "isViewPage" : "")}
      >
        <UsersForm {...this.props} isViewPage={this.isViewPage()} />
      </Edit>
    );
  }
}

export default EditArticle;
