/* eslint-disable */
import React, {Component, Fragment} from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from "prop-types";
import { get } from 'lodash';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReactCountdownClock from 'react-countdown-clock';
import SelectField from '../../../../Components/Forms/Fields/Select';
import HiddenInput from '../../../../Components/Forms/Fields/HiddenInput';
import Validations from '../../../../Components/Forms/Validations';
import EditForm from '../../../../Components/Forms/Builder/EditForm';
import {connect} from "react-redux";
import RadioField from "../../../../Components/Forms/Fields/Radio";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Image from "../../../../Components/Image/Image";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from "../../../../Components/Modal/Modal";
import localize from "helpers/localize";

class MyExamForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
  };

    state = {
        activeStep: 0,
        showSave: true,
        showResult: false,
        isExpired: false
    };

    componentWillMount() {
      // this for clear data
      this.props.resetSection();
      this.props.resetForm();
      this.props.reset();
    }

  handleNext = () => {
        this.setState({
            activeStep: this.state.activeStep + 1,
        });
    };

    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
    };

    componentWillReceiveProps = (nextProps) => {
      const {dispatch, change} = this.props;

      if (nextProps.isFailedExam) {
        this.props.history.push("/user-exams");
      }

      if (!nextProps.initialValues || !this.props.initialValues ||  !this.props.initialValues.questions) {
        return;
      }

      if ( this.props.initialValues.questions !== nextProps.initialValues.questions ) {
        nextProps.initialValues.questions.map((question, index) => dispatch(change(`answers.${index}.question_id`, question.id)));
      }
      if(this.props.result !== nextProps.result && nextProps.result !== null) {
        this.setState({
          showResult: true,
          showSave: false
        });
      }
    };

    handleRequestClose = () => {
        this.setState({showResult: false});
        this.props.history.push('/user-exams')
    };

  onCountDownEnd = () => {
    document.getElementById("submit-exam").click();
  };

  render() {
    const {
        handleInputChange,
        fieldErrors
    } = this.props;

      let exams = [];
      Object.keys(get(this.props, 'baseFields.category_id.data', {})).forEach(key => {
          exams.push({id: key, label: this.props.baseFields.category_id.data[key]});
      });

      const {activeStep} = this.state;

    return (
        <EditForm {...this.props} disableButtons={this.props.isExam}>
          <div className="col-12">
              {!this.props.isExam && <Field
                  name="category_id"
                  label={localize("exams", "Vizsga")}
                  fieldError={fieldErrors.category_id}
                  onChange={() => handleInputChange('category_id')}
                  component={SelectField}
                  placeholder={localize("exams", "Vizsga")}
                  isRequired={true} validate={[Validations.required]}
                  defaultValue={false}
                  options={exams}
              />}
              {this.props.isExam && this.props.initialValues && <Fragment>
                  <Dialog open={this.state.showResult} scroll="body">
                      <DialogTitle>
                          {this.props.status === "completed" && <Fragment>{localize("exams", "Vizsga eredménye.")}</Fragment>}
                          {!this.props.status !== "completed" && <Fragment>{localize("exams", "Vizsga eredménye.")}</Fragment>}
                      </DialogTitle>
                      <DialogContent>
                          <DialogContentText>
                              {localize("exams", "Az elért eredményed:")} {this.props.result}
                          </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                          <Button onClick={this.handleRequestClose} color="primary">
                              {localize("exams", "Bezár")}
                          </Button>
                      </DialogActions>
                  </Dialog>
                <h1>{this.props.initialValues.name}</h1>
                  <Field
                      name="exam_result_id"
                      component={HiddenInput}
                  />
                  <Stepper activeStep={activeStep} orientation="vertical">
                      {this.props.initialValues.questions.map((question, index) => {
                          return (
                              <Step key={index}>
                                  <StepLabel>{question.title}</StepLabel>
                                  <StepContent className="pb-3">
                                      <Typography>
                                          <Field
                                              name={`answers.${index}.question_id`}
                                              component={HiddenInput}
                                          />
                                          <div className="ml-3">
                                              {question.image && <Image style={{maxWidth: 600}} image={question.image}/>}
                                              <Field
                                                  name={`answers.${index}.answer`}
                                                  component={RadioField}
                                                  options={question.answers.filter(answer => answer).map((answer, index) => {return {
                                                      id: ''+index,
                                                      label: answer
                                                  }})}
                                              />
                                          </div>
                                      </Typography>
                                      <div className="mt-2">
                                          <div>
                                              <Button
                                                  disabled={activeStep === 0}
                                                  onClick={this.handleBack}
                                                  className="jr-btn"
                                              >
                                                  {localize("exams", "Elöző kérdés")}
                                              </Button>
                                              {activeStep !== this.props.initialValues.questions.length - 1 && <Button
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={this.handleNext}
                                                  className="jr-btn"
                                              >
                                                  {localize("exams", "Következő kérdés")}
                                              </Button>}
                                          </div>
                                      </div>
                                  </StepContent>
                              </Step>
                          );
                      })}
                  </Stepper>
                  {!this.props.loading && this.state.showSave && <Button type="submit"
                                                                         id="submit-exam"
                                                                         variant="contained"
                                                                         color="primary"
                                                                         aria-label="add">
                    {localize("exams", "Vizsga befejezése")}
                  </Button>}
                  {this.props.loading && <div className="text-center mt-2">
                      <CircularProgress />
                  </div>}
              </Fragment>}
              {this.props.isExam && this.state.showSave && get(this.props,'initialValues.duration', false) && <div className="timer">
                  <ReactCountdownClock
                      seconds={this.props.viewData.duration}
                      color="#8a5e87"
                      alpha={0.9}
                      size={75}
                      onComplete={this.onCountDownEnd}
                  />
              </div>}
          </div>
        </EditForm>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: 'my-exam',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(MyExamForm);

export default connect(
    state => ({
        initialValues: state.myExams.viewData
    }),
    null
)(UsersReduxForm);
