//import strings from "i18n/hu-HU.json";
import restHelper from "helpers/restHelper";

export const getLocale = () => {
  return localStorage.getItem("lang") || "hu-HU";
};
export const setLocale = loc => {
  localStorage.setItem("lang", loc);
};

export const fetchLocale = () => {
  return restHelper.index("locale/locales").then(responseLocales => {
    window.localeData.locales = responseLocales.data;
    return restHelper.index("locale/messages").then(responseMessages => {
      window.localeData.strings = responseMessages.data || {};
      //window.localeData.strings = Object.assign({}, strings);
      window.localeData.fetched = true;
      return true;
    });
  });
};

export const missingTranslations = messages => {
  return restHelper
    .post("locale/messages", "MessageForm", { messages })
    .then(response => {
      console.log("missingTranslation", messages, response);
      window.localeData.missing = [];
    });
};

if (!window.localeData) {
  window.localeData = {
    locales: {},
    locale: getLocale(),
    strings: {},
    fetched: false,
    missing: [],
    interval: null
  };
  //fetchLocale();
  window.localeData.interval = setInterval(() => {
    if (window.localeData.missing.length > 0) {
      missingTranslations(window.localeData.missing);
    }
  }, 5000);
}
export default (category, str, params) => {
  if (!str || str === "") {
    return "";
  }
  var message =
    window.localeData.strings[category] &&
    window.localeData.strings[category][str]
      ? window.localeData.strings[category][str]
      : str;

  //Hiányzó fordítások kezelése
  if (
    window.localeData.fetched &&
    (!window.localeData.strings[category] ||
      !window.localeData.strings[category][str])
  ) {
    if (!window.localeData.strings[category]) {
      window.localeData.strings[category] = {};
    }
    window.localeData.strings[category][str] = str;
    window.localeData.missing.push({ category, message: str });
    //console.log("missingTranslation", category, str);
  }

  if (typeof params === "object") {
    for (var key in params) {
      message = message.replace("{{" + key + "}}", params[key]);
      message = message.replace("{" + key + "}", params[key]);
    }
  }

  return message;
};
