export const USERS_PERMISSION = "users";
export const USERS_NEW_PERMISSION = "users-new";
export const USERS_INACTIVED_PERMISSION = "users-inactivated";
export const ARTICLES_PERMISSION = "articles";
export const SERVICE_CHANGES_PERMISSION = "service-changes";
export const DISTRIBUTORS_PERMISSION = "distributors";
export const BANNED_CASHREGISTERS_PERMISSION = "banned-cashregisters";
export const REREGISTER_PERMISSION = "cashregisters-reregs";
export const EVENT_TYPES_PERMISSION = "event-types";
export const AEEREGS_PERMISSION = "cashregisters-aeeregs";
export const DOWNLOADS_PERMISSION = "downloads";
export const INCOMINGSMS_PERMISSION = "incoming-sms-messages";
export const OUTGOINGSMS_PERMISSION = "outgoing-sms-messages";
export const STICKERS_PERMISSION = "stickers";
export const CASHREGISTERS_PERMISSION = "cashregisters";
export const EXAMS_PERMISSION = "exams";
export const EXAMINEE_EXAMS_PERMISSION = "examinee-exams";
export const SMS_LIST_ITEMS_PERMISSION = "sms-list-items";
export const ANNUAL_REVIEWS_PERMISSION = "annual-reviews"; //'cashregisters-annuals';
export const MY_EXAMS_PERMISSION = "user-exams";
export const SERVICE_ORDERS_PERMISSION = "service-orders";
export const CLIENTS_PERMISSION = "clients";
export const WORKSHEETS_PERMISSION = "worksheets";
export const EVENTS_PERMISSION = "events";
export const WORKFEES_PERMISSION = "workfees";
export const CRULES_PERMISSION = "c-rules";
export const SHOP_PERMISSION = "shop";
export const SHOP_ADMIN_PERMISSION = "shop-admin";
export const SHOP_ADMIN_PRODUCTS_PERMISSION = "shop-admin-products";
export const SHOP_ADMIN_ORDERS_PERMISSION = "shop-admin-orders";
export const SHOP_ADMIN_TAGS_PERMISSION = "shop-admin-tags";
export const SHOP_ADMIN_PRICES_PERMISSION = "shop-admin-prices";
export const SHOP_ADMIN_MENU_PERMISSION = "shop-admin-menu";
export const NOTIFICATION_PERMISSION = "notifications";
export const SMODE_PERMISSION = "smode";
export const CLOUD_CASHREGISTERS = "cloud-cashregisters";
export const CLOUD_PRODUCTS = "cloud-products";
export const CLOUD_QUEUE = "cloud-queue";
export const CLOUD_CONTROL_PANEL = "cloud-control-panel";
export const CLOUD_CASHREGISTER_ACCOUNTING = "cloud-cashregister-accounting";
export const CLOUD_CASHREGISTER_DEPARTMENTS = "cloud-cashregister-departments";
export const CLOUD_CASHREGISTER_PARADATA = "cloud-cashregister-paradata";
export const CLOUD_CASHREGISTER_SETRENTDATA = "cloud-cashregister-setrentdata";
export const CLOUD_CASHREGISTER_YEARLYGENERAL = "cashregister-yearly-general";
export const CLOUD_CASHREGISTER_AEEUNBLOCK = "cashregister-aee-unblock";
export const EMAIL_PERMISSION = "email";
export const EMAIL_TEMPLATES_PERMISSION = "email-templates";
export const EMAIL_SEND_PERMISSION = "email-send";
export const ACCOUNTANTS_PERMISSION = "accountants";
