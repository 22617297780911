import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditDownload from "./views/EditDownload";
import * as downloadsActions from "../../../redux/modules/downloads";

const mapStateToProps = state => {
  return {
    loading: state.downloads.loading,
    success: state.downloads.success,
    saved: state.downloads.saved,
    message: state.downloads.message,
    fieldErrors: state.downloads.formErrors,
    id: state.downloads.id,
    viewData: state.downloads.viewData,
    reducerName: "downloads",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: downloadsActions.createDownloads,
      update: downloadsActions.updateDownloads,
      view: downloadsActions.viewDownloads,
      reset: downloadsActions.reset,
      resetForm: downloadsActions.resetForm
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDownload);
