import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cookie from "react-cookies";
import { isIOS, isMobile } from "react-device-detect";

import * as authActions from "../../../../redux/modules/auth";
import NavBar from "../../../../Components/NavBar/NavBar";
import SideBar from "../../../../Components/SideBar/SideBar";

class AuthenticatedLayout extends Component {
  componentWillMount = () => {
    let access_token = false;
    try {
      access_token = localStorage.getItem("access_token");
    } catch (err) {
      try {
        access_token = cookie.load("access_token");
      } catch (err) {}
    }
    if (access_token && !this.props.logined) {
      this.props.setToken();
    } else if (!this.props.logined) {
      this.props.history.push("/login");
    }
  };

  componentWillReceiveProps = nextProps => {
    if (!nextProps.loading && !nextProps.logined) {
      this.props.history.push("/login");
    }
  };

  handleLogout = () => {
    this.props.logout();
  };
  render = () => {
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className="app-container mini-drawer">
        {!this.props.loading && this.props.user && (
          <Fragment>
            <SideBar
              logout={this.handleLogout.bind(this)}
              user={this.props.user}
              {...this.props}
            />
            <div className="app-main-container">
              <div className="app-header ">
                <NavBar {...this.props} />
              </div>
              <main className="app-main-content-wrapper">
                <div className="app-main-content">
                  <div className="app-wrapper">{this.props.children}</div>
                </div>
              </main>
            </div>
          </Fragment>
        )}
      </div>
    );
  };
}

const mapStateToProps = state => {
  return {
    logined: state.auth.logined,
    loading: state.auth.loading,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setToken: authActions.setToken,
      logout: authActions.logout
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticatedLayout);
