import { Chip, List, ListItem, ListItemText } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import Axios from "axios";
import localize from "helpers/localize";
import moment from "moment";
import React, { Fragment, useState } from "react";
export default function AnnualReviewHistory({
  cashregister_id,
  items,
  onSetMissingSuccess
}) {
  const [confirmOpen, setConfirmOpen] = useState();

  async function setMissing() {
    const { data } = await Axios.post(
      `/v1/annual-reviews/missing?cashregisterId=${cashregister_id}`,
      { AnnualReviewMissing: { year: confirmOpen } }
    );
    if (data && data.success) {
      setConfirmOpen(undefined);
      onSetMissingSuccess && onSetMissingSuccess();
    }
  }

  return (
    <Fragment>
      <List>
        {Object.keys(items).map(year => {
          const item = items[year];

          return (
            <ListItem key={year} divider>
              <ListItemText>{year}</ListItemText>
              {Array.isArray(item) ? (
                item.map((review, index) => {
                  return (
                    <Chip
                      icon={<CheckIcon style={{ color: "white" }} />}
                      style={{ backgroundColor: "green", color: "white" }}
                      key={index}
                      label={`${moment(review.review_date).format(
                        "YYYY.MM.DD"
                      )} - ${review.user_name}`}
                    />
                  );
                })
              ) : typeof item === "object" ? (
                <Chip
                  icon={<CheckIcon style={{ color: "white" }} />}
                  style={{ backgroundColor: "orange", color: "white" }}
                  label={`${moment(item.created_at).format("YYYY.MM.DD")} - ${
                    item.created_by_real_name
                  }`}
                />
              ) : item === false ? (
                <Chip
                  icon={<ErrorIcon style={{ color: "white" }} />}
                  style={{ backgroundColor: "red", color: "white" }}
                  label={"Hiányzik"}
                  onClick={() => setConfirmOpen(year)}
                />
              ) : (
                ""
              )}
            </ListItem>
          );
        })}
      </List>
      <Dialog
        open={!!confirmOpen}
        className="col-md-8 col-lg-6 col-12 modal-centered"
        onClose={() => setConfirmOpen(undefined)}
        scroll="body"
      >
        <DialogTitle>
          {localize("cashregisters", "Éves felülvizsgálat kihagyása")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {localize(
              "cashregisters",
              "Biztosan jóváhagyod az éves felülvizsgálat kihagyását?"
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmOpen(undefined)}
            variant="contained"
            color="default"
            component="span"
          >
            {localize("cashregisters", "Mégse")}
          </Button>
          <Button
            onClick={() => setMissing()}
            variant="contained"
            color="secondary"
            component="span"
          >
            {localize("cashregisters", "Igen")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
