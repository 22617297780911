import axios from "axios";
import { get } from "lodash";
import firbaseApp from "../../firebase-config";

export const FCM_INITALIZE = "FCM_INITALIZE";
export const FCM_DISCONNECT = "FCM_DISCONNECT";
export const FCM_SET_TOKEN = "FCM_SET_TOKEN";
export const FCM_GET_PERMISSION_SUCCESS = "FCM_GET_PERMISSION_SUCCESS";
export const FCM_ERROR = "FCM_ERROR";
export const FCM_INCOMMMING_MESSAGE = "FCM_INCOMMMING_MESSAGE";
export const FCM_SET_SUBSCRIBES = "FCM_SET_SUBSCRIBES";

export const FCM_TOKEN_LOAD = "FCM_TOKEN_LOAD";
export const FCM_TOKEN_SUCCESS = "FCM_TOKEN_SUCCESS";

const initialState = {
  messaging: null,
  hasPermission: false,
  error: null,
  token: null,
  loading: false,
  success: false
};

let subscribes = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case FCM_INITALIZE:
      return {
        ...state,
        messaging: action.messaging
      };
    case FCM_TOKEN_LOAD:
      return {
        ...state,
        loading: true
      };
    case FCM_TOKEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true
      };
    }
    case FCM_GET_PERMISSION_SUCCESS:
      return {
        ...state,
        hasPermission: true
      };
    case FCM_SET_TOKEN:
      return {
        ...state,
        token: action.token
      };
    case FCM_SET_SUBSCRIBES:
      subscribes = action.subscribes;
      return {
        ...state
      };
    case FCM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case FCM_DISCONNECT:
      subscribes = {};
      return initialState;
    default:
      return state;
  }
};

export const disconnect = () => dispatch => {
  dispatch({
    type: FCM_DISCONNECT
  });
};

export const initalize = disableSendServer => dispatch => {
  try {
    dispatch({
      type: FCM_INITALIZE,
      messaging: firbaseApp.messaging()
    });
    return dispatch(getPermissions(disableSendServer));
  } catch (e) {
    console.log(e);
  }
};

export const getPermissions = disableSendServer => (dispatch, getState) => {
  return getState()
    .fcm.messaging.requestPermission()
    .then(() => {
      dispatch({
        type: FCM_GET_PERMISSION_SUCCESS
      });
      return dispatch(getToken(disableSendServer));
    })
    .catch(err => {
      dispatch({
        type: FCM_ERROR,
        error: err.message
      });
    });
};

export const getToken = disableSendServer => (dispatch, getState) => {
  return getState()
    .fcm.messaging.getToken()
    .then(token => {
      if (token) {
        let disp = dispatch({
          type: FCM_SET_TOKEN,
          token
        });
        return disableSendServer ? disp : dispatch(sendToken());
      } else {
        return dispatch({
          type: FCM_ERROR,
          error:
            "No Instance ID token available. Request permission to generate one."
        });
      }
    })
    .catch(err =>
      dispatch({
        type: FCM_ERROR,
        error: err.message
      })
    );
};

export const sendToken = () => (dispatch, getState) => {
  dispatch({
    type: FCM_TOKEN_LOAD
  });
  return axios
    .post("/v1/user/fcm-token", {
      token: getState().fcm.token
    })
    .then(() => {
      dispatch({
        type: FCM_TOKEN_SUCCESS
      });
      return dispatch(onMessage());
    })
    .catch(error =>
      dispatch({
        type: FCM_ERROR,
        error: get(error, "response.data.message", null)
      })
    );
};

export const unsubscribe = category => {
  subscribes[category] = null;
  delete subscribes[category];
};

export const subscribe = (category, cb) => (dispatch, getState) => {
  subscribes[category] = cb;
  return dispatch({
    type: FCM_SET_SUBSCRIBES,
    subscribes
  });
};

export const isActiveSubscribe = category => {
  return subscribes[category] ? true : false;
};

export const onMessage = () => (dispatch, getState) => {
  getState().fcm.messaging.onMessage(payload => {
    console.log(payload);
    if (payload.data.category) {
      JSON.parse(payload.data.category).forEach(category => {
        let data = {};
        if (payload.data.payload) {
          data = JSON.parse(payload.data.payload);
        }
        if (get(subscribes, `${category}`)) {
          subscribes[category](get(data, `${category}`, null));
        }
      });
    }
    return dispatch({
      type: FCM_INCOMMMING_MESSAGE
    });
  });
};
