import React, { Fragment, PureComponent } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import _, { get } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import BodyClassName from "react-body-classname";
import logo from "../../../../../assets/img/prior_logo.png";
//import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import inputStyle from "../../../../../Components/Forms/Fields/inputStyle.js";
import { withStyles } from "@material-ui/core/styles";
import RegisterForm from "./RegisterForm";
import localize from "helpers/localize";

class Register extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount = () => {
    //this.props.reset();
    this.props.registrationTokenCheck(this.props.match.params.token);
  };

  componentWillUnmount = () => {
    //this.props.reset();
  };

  componentDidMount() {
    localStorage.removeItem("access_token");
  }

  /*
  componentWillReceiveProps = nextProps => {
    if (nextProps.success && nextProps.logined) {
      this.props.history.push("/");
    }
    if (!_.isEqual(this.props.formErrors, nextProps.formErrors)) {
      let error,
        error_user_password,
        error_user_username,
        error_user_real_name,
        error_user_phone,
        error_user_sealnumber_of_cashregister,
        error_company_name,
        error_registration_number,
        error_address,
        error_phone,
        error_email,
        error_contact_person;

      if (get(nextProps.formErrors, "user_username", []).length !== 0) {
        error = true;
        error_user_username = get(nextProps.formErrors, "user_username", [])[0];
      }
      if (get(nextProps.formErrors, "user_password", []).length !== 0) {
        error = true;
        error_user_password = get(nextProps.formErrors, "user_password", [])[0];
      }
      if (get(nextProps.formErrors, "user_real_name", []).length !== 0) {
        error = true;
        error_user_real_name = get(
          nextProps.formErrors,
          "user_real_name",
          []
        )[0];
      }
      if (get(nextProps.formErrors, "user_phone", []).length !== 0) {
        error = true;
        error_user_phone = get(nextProps.formErrors, "user_phone", [])[0];
      }
      if (
        get(nextProps.formErrors, "user_sealnumber_of_cashregister", [])
          .length !== 0
      ) {
        error = true;
        error_user_sealnumber_of_cashregister = get(
          nextProps.formErrors,
          "user_sealnumber_of_cashregister",
          []
        )[0];
      }

      if (
        get(nextProps.formErrors, "distributor_company_name", []).length !== 0
      ) {
        error = true;
        error_company_name = get(
          nextProps.formErrors,
          "distributor_company_name",
          []
        )[0];
      }
      if (
        get(nextProps.formErrors, "distributor_registration_number", [])
          .length !== 0
      ) {
        error = true;
        error_registration_number = get(
          nextProps.formErrors,
          "distributor_registration_number",
          []
        )[0];
      }
      if (get(nextProps.formErrors, "distributor_address", []).length !== 0) {
        error = true;
        error_address = get(nextProps.formErrors, "distributor_address", [])[0];
      }
      if (get(nextProps.formErrors, "distributor_phone", []).length !== 0) {
        error = true;
        error_phone = get(nextProps.formErrors, "distributor_phone", [])[0];
      }
      if (get(nextProps.formErrors, "distributor_email", []).length !== 0) {
        error = true;
        error_email = get(nextProps.formErrors, "distributor_email", [])[0];
      }
      if (
        get(nextProps.formErrors, "distributor_contact_person", []).length !== 0
      ) {
        error = true;
        error_contact_person = get(
          nextProps.formErrors,
          "distributor_contact_person",
          []
        )[0];
      }

      if (error) {
        this.setState({
          error_user_username,
          error_user_password,
          error_company_name,
          error_registration_number,
          error_address,
          error_phone,
          error_email,
          error_contact_person,
          error
        });
      }
    }
  };
*/
  handleSubmit = data => {
    const { token } = this.props.match.params;
    this.props
      .registration({
        token,
        ...data
      })
      .then(response => {
        if (response.success && response.logined) {
          this.props.history.push("/");
        }
      });
  };

  render() {
    const { classes, regTokenCheck, theme, formErrors } = this.props;
    console.log("regTokenCheck", regTokenCheck);
    return (
      <Grid
        container
        spacing={theme.spacing.unit * 2}
        justify="center"
        style={{ overflowY: "scroll" }}
      >
        <Helmet>
          <title>{localize("user", "Regisztráció")}</title>
        </Helmet>
        <Grid item xs={12} md={8} lg={6}>
          <Paper style={{ margin: theme.spacing.unit * 4 }}>
            <Grid container>
              <Hidden mdUp>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    padding: theme.spacing.unit * 3
                  }}
                  justify="center"
                  container
                >
                  <Link to="/" className="logo-lg">
                    <img src={logo} alt="" title="" width="177" />
                  </Link>
                </Grid>
              </Hidden>
              <Grid
                item
                xs={12}
                md={8}
                style={{ padding: theme.spacing.unit * 3 }}
              >
                <Typography
                  variant="h4"
                  style={{ marginBottom: theme.spacing.unit * 3 }}
                >
                  {localize("user", "Regisztráció")}
                </Typography>
                {Object.keys(this.props.formErrors).length === 0 &&
                  this.props.failed && (
                    <div
                      className="bg-danger shadow-lg text-white alert alert-success fade show"
                      role="alert"
                    >
                      {get(this.props, "message", false)
                        ? get(this.props, "message")
                        : localize("basic", "Sikertelen művelet")}
                    </div>
                  )}
                <Grid container spacing={theme.spacing.unit * 2}>
                  {regTokenCheck.success ? (
                    this.props.message && !this.props.failed ? (
                      <Grid container spacing={16}>
                        <Grid container item xs={12} justify="space-between">
                          <Typography
                            variant="h6"
                            style={{
                              color: theme.palette.success.main,
                              padding: theme.spacing.unit
                            }}
                          >
                            {this.props.message}
                          </Typography>
                          <Link style={{ padding: theme.spacing.unit }} to="/">
                            {localize("user", "Vissza a bejelentkezésre")}
                          </Link>
                        </Grid>
                      </Grid>
                    ) : (
                      <RegisterForm
                        //key={"form_reg"}
                        errors={formErrors}
                        onSubmit={this.handleSubmit}
                        hasDistributor={regTokenCheck.hasDistributor}
                        isDistributorUser={regTokenCheck.isDistributorUser}
                        role={regTokenCheck.role}
                      />
                    )
                  ) : (
                    <Grid container spacing={16}>
                      <Grid container item xs={12} justify="space-between">
                        <Typography
                          variant="body2"
                          style={{
                            color: theme.palette.error.main,
                            padding: theme.spacing.unit
                          }}
                        >
                          {regTokenCheck.error}
                        </Typography>
                        <Link style={{ padding: theme.spacing.unit }} to="/">
                          {localize("user", "Vissza a bejelentkezésre")}
                        </Link>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Hidden smDown>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    padding: theme.spacing.unit * 3
                  }}
                  justify="center"
                  container
                >
                  <Link to="/" className="logo-lg">
                    <img src={logo} alt="" title="" width="177" />
                  </Link>
                </Grid>
              </Hidden>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(inputStyle, { withTheme: true })(Register);
