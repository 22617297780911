import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditRight from "./views/EditRight";
import * as cashregisterRightsActions from "../../../../redux/modules/cashregister-rights";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.cashregisterRights.loading,
    success: state.cashregisterRights.success,
    saved: state.cashregisterRights.saved,
    message: state.cashregisterRights.message,
    fieldErrors: state.cashregisterRights.formErrors,
    id: state.cashregisterRights.id,
    viewData: state.cashregisterRights.viewData,
    reducerName: "cashregisterRights",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createRight: cashregisterRightsActions.createRight,
      updateRight: cashregisterRightsActions.updateRight,
      viewRight: cashregisterRightsActions.viewRight,
      reset: cashregisterRightsActions.reset,
      resetForm: cashregisterRightsActions.resetForm,
      getCustomInfo: optionsActions.getFormInfo
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRight);
