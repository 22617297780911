import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import localize from "helpers/localize";
import { get } from "lodash";
import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import logo from "../../../../../assets/img/prior_logo.png";

class EmailChange extends PureComponent {
  componentWillMount = () => {
    this.props.reset();
    this.props.emailChangeTokenCheck(this.props.match.params.token);
  };

  componentWillUnmount = () => {
    this.props.reset();
  };
  render() {
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("user", "Email változtatás")}</title>
        </Helmet>
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link to="/" className="logo-lg">
              <img src={logo} alt="" title="" width="177" />
            </Link>
          </div>
          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <span>{localize("user", "Email változtatás")}</span>
              </h1>
            </div>
            {this.props.tokenSuccess && (
              <div
                className="shadow-lg alert alert-success fade show"
                role="alert"
              >
                {get(this.props, "message", false)
                  ? get(this.props, "message")
                  : localize("basic", "Sikeres művelet")}
              </div>
            )}
            {this.props.failed && (
              <div
                className="bg-danger shadow-lg text-white alert alert-success fade show"
                role="alert"
              >
                {get(this.props, "message", false)
                  ? get(this.props, "message")
                  : localize("basic", "Sikertelen művelet")}
              </div>
            )}
            <div className="mb-3 d-flex align-items-center justify-content-between">
              <Link to="/">{localize("user", "Vissza a bejelentkezésre")}</Link>
            </div>
          </div>
        </div>
        {this.props.loading && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

export default EmailChange;
