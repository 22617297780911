import { reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AnnualReviewFormBase from "./AnnualReviewFormBase";
const AnnualReviewForm = reduxForm({
  form: "annualReviews",
  enableReinitialize: false,
  keepDirtyOnReinitialize: false,
  updateUnregisteredFields: false
})(AnnualReviewFormBase);

const form_values = {
  "1_1": "1",
  "1_2": "1",
  "1_3": "1",
  "1_4": "1",
  "1_5": "1",
  //"1_5_1": "1",
  "1_6": "1",
  //"1_6_1": "1",
  "1_7": "1",
  "1_8": "1",
  "1_9": "1",
  "1_10": "1",
  "1_11": "1",
  "1_12": "1",
  "1_13": "1",
  "1_14": "1",
  "1_15": "1",
  "1_16": "1"
};

export default connect(
  state => {
    if (state.annualReviews.viewData) {
      state.annualReviews.viewData.form_values = Object.assign(
        {},
        form_values,
        state.annualReviews.viewData.form_values
      );
      if (!state.annualReviews.viewData.operating_address_type) {
        state.annualReviews.viewData.operating_address_type =
          state.annualReviews.viewData.client_yard;
      }
    }
    return {
      initialValues: state.annualReviews.viewData
    };
  },
  null,
  null,
  { withRef: true }
)(AnnualReviewForm);
