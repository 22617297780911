import React, { Component, Fragment } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";
import Item from "./Item";
import InputField from "../../../../../Components/Forms/Fields/Input";
import SelectField from "../../../../../Components/Forms/Fields/Select";
import Validations from "../../../../../Components/Forms/Validations";
import EditForm from "../../../../../Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button/Button";
import SweetAlert from "react-bootstrap-sweetalert";
import localize from "helpers/localize";

class RenderItems extends Component {
  constructor(props) {
    super(props);
    props.data && props.data.forEach((row, index) => props.fields.push(row));
    if (this.props.fields.length === 0) {
      this.props.fields.push({});
    }
    this.state = {
      confirm: false
    };
  }

  handleNewItem = () => {
    this.props.fields.push({});
  };

  handleDelete = index => {
    this.setState({
      confirm: `${index}`
    });
  };
  handleComfirm = () => {
    this.props.fields.remove(this.state.confirm);
    this.handleClose();
  };
  handleClose = () => {
    this.setState({
      confirm: false
    });
  };
  render = () => {
    const itemsLenght = this.props.fields.length;
    return (
      <Fragment>
        <SweetAlert
          show={this.state.confirm}
          warning
          showCancel
          showLoaderOnConfirm
          confirmBtnText={localize("basic", "Igen")}
          cancelButtonText={localize("basic", "Nem")}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          timer={false}
          title={localize("basic", "Biztosan törölni akarja?")}
          onCancel={this.handleClose.bind(this)}
          onConfirm={this.handleComfirm.bind(this)}
        />
        {this.props.fields.map((question, index) => (
          <Item
            question={question}
            key={"fi-" + index}
            index={index}
            itemsLenght={itemsLenght}
            {...this.props}
            fieldError={get(this.props, `fieldsError[${index}]`, {})}
            handleDelete={this.handleDelete.bind(this)}
          />
        ))}
        {!this.props.isViewPage && (
          <div className="col-12">
            <hr />
            <Button
              onClick={this.handleNewItem.bind(this)}
              variant="contained"
              color="secondary"
            >
              {localize("exams", "Új kérdés hozzáadása")}
            </Button>
            <hr />
          </div>
        )}
      </Fragment>
    );
  };
}

class ExamForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  render() {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      enabledFields
    } = this.props;

    let certificates = [];
    Object.keys(get(this.props, "baseFields.certificate.data", {})).forEach(
      key => {
        certificates.push({
          id: key,
          label: this.props.baseFields.certificate.data[key]
        });
      }
    );

    return (
      <EditForm {...this.props}>
        <div className="col-12">
          <Field
            name="name"
            label={localize("exams", "Vizsga cím")}
            fieldError={fieldErrors.name}
            onChange={() => handleInputChange("name")}
            isRequired={true}
            validate={[Validations.required]}
            component={InputField}
            type="text"
            placeholder={localize("exams", "Vizsga cím")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("name") !== -1
                  ? false
                  : true
                : false
            }
          />
          <Field
            name="certificate"
            label={localize("exams", "Generált tanúsítvány")}
            fieldError={fieldErrors.certificate}
            onChange={() => handleInputChange("certificate")}
            isRequired={true}
            validate={[Validations.required]}
            component={SelectField}
            placeholder={localize("exams", "Generált tanúsítvány")}
            isViewPage={isViewPage}
            options={certificates}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("certificate") !== -1
                  ? false
                  : true
                : false
            }
          />
          <Field
            name="success_minimum"
            label={localize(
              "exams",
              "Helyes válaszok száma a sikeres vizsgához."
            )}
            // fieldError={fieldErrors.success_minimum}
            onChange={() => this.props.handleInputChange("success_minimum")}
            isRequired={true}
            validate={[Validations.required]}
            component={InputField}
            type="number"
            placeholder={localize(
              "exams",
              "Helyes válaszok száma a sikeres vizsgához."
            )}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("name") !== -1
                  ? false
                  : true
                : false
            }
          />
          <Field
            name="duration"
            label={localize("exams", "Vizsga hossza (perc)")}
            fieldError={fieldErrors.duration}
            onChange={() => this.props.handleInputChange("success_minimum")}
            isRequired={true}
            validate={[Validations.required]}
            component={InputField}
            type="number"
            placeholder={localize("exams", "Vizsga hossza (perc)")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("duration") !== -1
                  ? false
                  : true
                : false
            }
          />
          <div className="row">
            <FieldArray
              name="questions"
              component={RenderItems}
              fieldsError={fieldErrors.questions}
              isViewPage={isViewPage}
              data={get(this.props, "initialValues.questions", [])}
              change={this.props.change}
              dispatch={this.props.dispatch}
              initialValues={this.props.initialValues}
            />
          </div>
        </div>
      </EditForm>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "exam",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: true
})(ExamForm);

export default connect(
  state => ({
    initialValues: state.exams.viewData
  }),
  null
)(UsersReduxForm);
