import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { Form } from "reactstrap";
import DefaultFormButtons from "../Buttons/DefaultFormButtons";
import Grid from "@material-ui/core/Grid";

class EditForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    goBackAfterSubmit: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this._form = React.createRef();
  }

  goBack = isNew => {
    if (this.props.handleBack) {
      this.props.handleBack();
    } else {
      //this.props.history.goBack();
      //return;
      if (this.props.location.pathname.indexOf("/view") !== -1) {
        this.props.history.goBack();
        /*this.props.history.push(
          this.props.location.pathname.split("/view").shift()
        );*/
      } else if (this.props.location.pathname.indexOf("/edit") !== -1) {
        if (isNew) {
          this.props.history.go(-2);
        } else {
          this.props.history.goBack();
        }
        /*this.props.history.push(
          this.props.location.pathname.split("/edit").shift()
        );*/
      } else if (this.props.location.pathname.indexOf("/create") !== -1) {
        this.props.history.goBack();
        /*this.props.history.push(
          this.props.location.pathname.split("/create").shift()
        );*/
      }
    }
  };

  submit = () => {
    if (this._form) {
      this._form.submit();
    }
  };

  componentDidMount() {
    if (this.props.callSubmit) {
      this.props.callSubmit(() => {
        this.submit();
      });
    }
  }

  handleSubmit = (event, lol) => {
    console.log("mentés", event, lol);
    const promise = this.props.handleSubmit(event);
    if (promise && promise.then) {
      return promise
        .then(res => {
          const isNew = res.config.method === "post";
          console.log("RES", isNew);
          if (this.props.afterSave) {
            this.props.afterSave(res);
          }
          this.props.goBackAfterSubmit && this.goBack(isNew);
          return res;
        })
        .catch(error => {
          if (this.props.afterSave) {
            this.props.afterSave(error);
          }
          return error;
          //console.log("ROSSZ", error);
        });
    }
  };

  render() {
    const {
      //handleSubmit,
      pristine,
      submitting,
      reset,
      loading,
      isViewPage,
      disableButtons
    } = this.props;

    return (
      <Fragment>
        <Form ref={this._form} onSubmit={this.handleSubmit} className="form">
          <div className="form-body">
            <Grid container={true} spacing={16} style={{ paddingBottom: 16 }}>
              {this.props.children}
            </Grid>
          </div>
          {!disableButtons && (
            <DefaultFormButtons
              pristine={pristine}
              submitting={submitting}
              reset={reset}
              loading={loading}
              isViewPage={isViewPage}
              {...this.props}
              formInstance={this._form}
            />
          )}
        </Form>
      </Fragment>
    );
  }
}

EditForm.defaultProps = {
  goBackAfterSubmit: true
};
export default EditForm;
