import React, { Fragment } from "react";
//import ReactDOM from "react-dom";
import PropTypes from "prop-types";
//import TextField from "@material-ui/core/TextField";
import TextField from "Components/New/Input/TextField/TextField";
//import NumberFormat from "react-number-format";
import UploadButton from "Components/New/Button/UploadButton";
import DownloadButton from "Components/New/Button/DownloadButton";
import { withStyles } from "@material-ui/core/styles";
import Img from "Components/New/Image";
import noImage from "assets/img/no-image.png";
const styles = theme => ({
  fileInputRoot: {
    paddingRight: 0
  }
});

class Image extends React.Component {
  onKeyDown = evt => {
    if (evt.key === "Enter") {
      return this.props.onSave && this.props.onSave();
    } else if (evt.key === "Escape") {
      this.props.onCancel && this.props.onCancel();
      return false;
    }
    return false;
  };

  onUpload = (fileData, file) => {
    const { id, onChange } = this.props;
    console.log(fileData);
    onChange(fileData.data.id, fileData.data);
    return true;
  };

  onRemove = (fileData, file) => {
    const { id, onChange } = this.props;
    onChange(null, null);
  };

  render() {
    const {
      id,
      label,
      error,
      type,
      placeholder,
      access,
      dirty,
      classes,
      helperText,
      multiline,
      rows,
      item,
      isNew,
      value
    } = this.props;
    /*let InputProps = {
      classes: { root: classes.fileInputRoot },
      endAdornment: (
        <Fragment>
          {value && <DownloadButton id={value} color="primary" />}
          <UploadButton
            name={id}
            onUpload={this.onUpload}
            onRemove={this.onRemove}
            file={value ? { name: value, id: value } : null}
            //disableDelete={!isNew}
          />
        </Fragment>
      )
    };*/

    let ip = {
      component_type: type,
      autoComplete: "off",
      autoCapitalize: "off",
      autoCorrect: "off",
      spellCheck: "false",
      disabled: true
    };

    if (type === "password") {
      //ip.type = "password";
      ip.style = {
        fontFamily: "text-security-disc"
      };
    }

    return (
      <Fragment>
        <TextField
          //inputRef={ref => (this.email = ref)}
          id={id}
          label={label}
          helperText={error || helperText || ""}
          error={error ? true : false}
          //defaultValue={defaultValue}
          //InputLabelProps={inputLabelProps}
          //className={dirty && !error ? classes.dirtyTextField : ""}
          fullWidth
          disabled
          //defaultValue={value ? value : ""}
          //value={value ? value : ""}
          value={value ? value : ""}
          //onChange={evt => onChange({ [id]: evt.target.value })}
          //onKeyDown={this.onKeyDown}
          //margin="normal"
          //variant="outlined"
          //placeholder={placeholder}
          inputProps={ip}
          //multiline={multiline}
          rows={rows}
          // eslint-disable-next-line
        //InputProps={InputProps}
          endAdornment={
            <Fragment>
              {value && (
                <DownloadButton
                  buttonProps={{ style: { padding: 5 } }}
                  id={value}
                  color="primary"
                />
              )}
              <UploadButton
                name={id}
                onUpload={this.onUpload}
                onRemove={this.onRemove}
                file={value ? { name: value, id: value } : null}
                removeOnlyClient
                buttonProps={{ style: { padding: 5 } }}
                //iconProps={{ fontSize: "small" }}
                //disableDelete={!isNew}
              />
            </Fragment>
          }
        />
        <Img
          fileId={value}
          {...(!value ? { src: noImage } : {})}
          style={{ width: "100%", borderRadius: 4, marginTop: 8 }}
        />
      </Fragment>
    );
  }
}

Image.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  format: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  access: PropTypes.string,
  helperText: PropTypes.string,
  dirty: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number
};

export default withStyles(styles)(Image);
