import React from "react";
import DataTableDx from "Components/New/DataTable/DataTableDx/DataTableDx";
import DataForm from "Components/New/DataForm/DataFormNew";
import DataProvider from "Components/New/DataProvider/DataProviderNew";
import authRules from "helpers/authRules";
import {
  EMAIL_TEMPLATES_PERMISSION,
  SHOP_ADMIN_PRICES_PERMISSION
} from "constans/permissions";
import localize from "helpers/localize";
const LC = "emailtemplates"; //Localization category

class Templates extends React.Component {
  render() {
    //const { client, readOnly, afterSave } = this.props;

    return (
      <div style={{ width: "100%", position: "relative" }}>
        <DataProvider
          url="email-templates"
          //optionsUrl="email-templates/options"
          goBackAfterSave
          useUrlRouting
          //disableGetView
          tableComponent={DataTableDx}
          formComponent={DataForm}
          tableProps={{
            title: localize(LC, "Sablonok"),
            canView: false,
            canCreate: authRules.canModifyByKey(EMAIL_TEMPLATES_PERMISSION),
            canDelete: authRules.canDeleteByKey(EMAIL_TEMPLATES_PERMISSION),
            canEdit: authRules.canModifyByKey(EMAIL_TEMPLATES_PERMISSION),
            columns: {}
          }}
          formProps={{
            title: localize(LC, "Sablon szerkesztése"),
            titleNew: localize(LC, "Új sablon felvitele"),
            fields: {
              status: {
                name: localize(LC, "Státusz"),
                validators: ["required"],
                defaultValue: "active"
              },
              subject: {
                name: localize(LC, "Tárgy"),
                validators: ["required"],
                sizes: { xs: 12 }
              },
              content: {
                name: localize(LC, "Tartalom"),
                type: "wysiwyg",
                sizes: { xs: 12 },
                multiline: true,
                rowsMin: 20,
                validators: ["required"],
                menubar: true
                //toolbar: "undo redo | formatselect | bold italic backcolor"
              }
            },
            fieldsOrder: ["status", "subject", "content"],
            //component: CustomForm,
            autoRenderFields: true
          }}
        />
      </div>
    );
  }
}

export default Templates;
