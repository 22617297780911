import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import InvalidIcon from "@material-ui/icons/Block";
import classNames from "classnames";
import localize from "helpers/localize";
const FileItem = props => {
  const {
    file,
    history,
    classes,
    uploadedText,
    historyText,
    disableIcon
  } = props;
  if (!file) {
    return (
      <span className={classes.greyInfo}>
        {localize(
          "serviceOrders",
          "A gémpkapocs ikon megnyomásával csatolhat fájlt."
        )}
      </span>
    );
  }

  return (
    <Tooltip title={history ? historyText : uploadedText}>
      <a
        className={classNames(
          classes.fileItem,
          history ? classes.historyItem : null
        )}
        target="_blank"
        style={history ? { display: "block" } : {}}
        href={
          process.env.REACT_APP_HOST +
          file.download_url +
          "&accessToken=" +
          localStorage.getItem("access_token").replace("Bearer ", "")
        }
      >
        {history && !disableIcon && (
          <InvalidIcon className={classes.historyItemIcon} />
        )}
        {file.file_name + " " + file.created_at}
      </a>
    </Tooltip>
  );
};

FileItem.defaultProps = {
  historyText: localize("serviceOrders", "Visszautasított dokumentum."),
  uploadedText: localize("serviceOrders", "Aktuálisan felöltött dokumentum."),
  disableIcon: false
};

FileItem.propTypes = {
  classes: PropTypes.object.isRequired,
  file: PropTypes.object,
  history: PropTypes.bool,
  disableIcon: PropTypes.bool,
  historyText: PropTypes.string,
  uploadedText: PropTypes.string
};

export default FileItem;
