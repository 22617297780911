import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import { get } from "lodash";

import {
  documentFields,
  documents,
  attachDocument,
  dettachDocument,
  submitDocuments,
  submitContracts
} from "redux/modules/service-orders.js";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import WarningIcon from "@material-ui/icons/Warning";
import CheckIcon from "@material-ui/icons/Check";
import Divider from "@material-ui/core/Divider";
import Toolbar from "@material-ui/core/Toolbar";
import InfoIcon from "@material-ui/icons/Info";
import { Helmet } from "react-helmet";
import ContainerHeader from "Components/ContainerHeader";
import WaitIcon from "@material-ui/icons/AccessTime";
import classNames from "classnames";
import { toastr } from "helpers/CustomToastr";
import DocListItem from "./DocListItem";
import authRules from "helpers/authRules";
import localize from "helpers/localize";

const styles = theme => ({
  expandIcon: {
    width: 16,
    height: 16,
    cursor: "pointer",
    marginBottom: -4,
    marginLeft: 2,
    transform: "rotate(0deg)",
    transition: "all 0.3s ease",
    "&:hover": {
      fill: theme.palette.primary.main,
      transition: "all 0.3s ease",
      transform: "scale(1.2)"
    }
  },
  expandIconExpanded: {
    //rotate: "180deg",
    transform: "rotate(180deg)",
    transition: "rotate 0.3s ease",
    "&:hover": {
      fill: theme.palette.primary.main,
      transition: "all 0.3s ease",
      transform: "scale(1.2) rotate(180deg)"
    }
  },
  validAvatar: {
    backgroundColor: theme.palette.primary.main
  },
  toolbarStyle: {
    minHeight: "auto",
    paddingTop: 4,
    paddingBottom: 4
  },
  toolbarIconStyle: {
    marginLeft: 8,
    marginRight: 24
  },
  infoIconColor: {
    fill: theme.palette.info.main
  },
  successIconColor: {
    fill: theme.palette.success.main
  },
  warningIconColor: {
    fill: theme.palette.warning.main
  },
  errorIconColor: {
    fill: theme.palette.error.main
  },
  greyInfoIconColor: {
    fill: "#bbb"
  },
  info: {
    color: theme.palette.info.main,
    fontStyle: "italic"
  },
  greyInfo: {
    color: "#aaa",
    fontStyle: "italic"
  },
  error: {
    color: theme.palette.error.main
  },
  historyItem: {
    color: "#aaa!important",
    fontStyle: "italic",
    "&:hover": {
      color: "#aaa!important"
    },
    "&:visited": {
      color: "#aaa!important"
    }
  },
  fileItem: {
    /*color: theme.palette.info.main + "!important",
    "&:hover": {
      color: theme.palette.info.main + "!important",
    },
    "&:visited": {
      color: theme.palette.info.main + "!important",
    }*/
  },
  historyItemIcon: {
    fill: theme.palette.error.main,
    width: 12,
    height: 12,
    marginBottom: -2,
    marginRight: 2
  }
});

class UploadServiceOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: {
        AUTHORIZATION: null,
        //CONTRACT_PAGE_1_5: null,
        //FRAMEWORK_CONTRACT: null,
        SIGNATURE_SPECIMEN: null
      },
      contracts: {
        //AUTHORIZATION: null,
        CONTRACT_PAGE_1_5: null,
        FRAMEWORK_CONTRACT: null
        //SIGNATURE_SPECIMEN: null
      },
      documentTypes: {
        AUTHORIZATION: false,
        CONTRACT_PAGE_1_5: false,
        FRAMEWORK_CONTRACT: false,
        SIGNATURE_SPECIMEN: false
      },
      wrong_cause: null,
      status: "pending",
      contract_sent_at: null,
      submitted_at: null
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    documentFields(id).then(fieldRes => {
      documents(id).then(documentsRes => {
        //console.log("documentsRes", documentsRes);
        this.setState({
          documentTypes: fieldRes.data.data.attach,
          documents: documentsRes.data.data.documents,
          contracts: documentsRes.data.data.contracts,
          wrong_cause: documentsRes.data.data.wrong_cause,
          warning: documentsRes.data.data.warning,
          status: documentsRes.data.data.status,
          contract_sent_at: documentsRes.data.data.contract_sent_at,
          submitted_at: documentsRes.data.data.submitted_at
        });
      });
    });
  }

  /**
   * True, ha van az előző beküldésnél újabb feltöltött fájl
   */
  documentIsNeeded = type => {
    ////console.log("documentIsNeeded",type, get(this.state.documentTypes, type, false));
    return get(this.state.documentTypes, type, false);
  };

  documentIsUploaded = (group, type) => {
    return get(this.state, group + "." + type + ".current.file", false)
      ? true
      : false;
  };

  documentIsNew = (group, type) => {
    return get(this.state, group + "." + type + ".current.created_at", false)
      ? true
      : false;
    /*
    const contract = contracts[type];
    const created_at = get(contract, "current.created_at", null);
    let created_at_unix = 0;
    let contract_sent_at_unix = 0;
    if (created_at) {
      created_at_unix = Moment(created_at).unix();
    }
    if (contract_sent_at) {
      contract_sent_at_unix = Moment(contract_sent_at).unix();
    }
    //console.log(created_at_unix, contract_sent_at_unix);
    if (created_at_unix > contract_sent_at_unix) {
      return true;
    }

    return get(this.state, group + "." + type + ".current.file", false)
      ? true
      : false;*/
  };

  documentIsSubmitted = (group, type) => {
    const history = get(this.state, group + "." + type + ".history", []);
    if (!Array.isArray(history)) {
      return false;
    }
    if (history.length === 0) {
      return false;
    }
    return (this.state.status === "okay" || this.state.status === "pending" || !this.documentIsNeeded(type)) &&
      history[0].created_at &&
      !this.documentIsNew(group, type)
      ? true
      : false;
  };

  contractsIsSendable = () => {
    if (
      this.documentIsNeeded("CONTRACT_PAGE_1_5") &&
      !this.documentIsUploaded("contracts", "CONTRACT_PAGE_1_5")
    ) {
      return false;
    }
    if (
      this.documentIsNeeded("FRAMEWORK_CONTRACT") &&
      !this.documentIsUploaded("contracts", "FRAMEWORK_CONTRACT")
    ) {
      return false;
    }

    if (
      this.documentIsUploaded("contracts", "CONTRACT_PAGE_1_5") === false &&
      this.documentIsUploaded("contracts", "FRAMEWORK_CONTRACT") === false
    ) {
      return false;
    }
    return true;

    /*return (
      this.hasNewContract() ||
      (this.documentIsNeeded("CONTRACT_PAGE_1_5") &&
        this.documentIsUploaded("contracts", "CONTRACT_PAGE_1_5") &&
        (this.documentIsNeeded("FRAMEWORK_CONTRACT") &&
          this.documentIsUploaded("contracts", "FRAMEWORK_CONTRACT")))
    );*/
  };

  contractsIsUploaded = () => {
    return (
      (this.documentIsSubmitted("contracts", "CONTRACT_PAGE_1_5") ||
        this.documentIsUploaded("contracts", "CONTRACT_PAGE_1_5") ||
        !this.documentIsNeeded("CONTRACT_PAGE_1_5")) &&
      (this.documentIsSubmitted("contracts", "FRAMEWORK_CONTRACT") ||
        this.documentIsUploaded("contracts", "FRAMEWORK_CONTRACT") ||
        !this.documentIsNeeded("FRAMEWORK_CONTRACT"))
    );
  };

  contractsIsSubmitted = () => {
    return (
      this.state.status === "pending" ||
      this.state.status === "okay" ||
        (
          (this.documentIsSubmitted("contracts", "CONTRACT_PAGE_1_5")  || !this.documentIsNeeded("CONTRACT_PAGE_1_5")) &&
          (this.documentIsSubmitted("contracts", "FRAMEWORK_CONTRACT") || !this.documentIsNeeded("FRAMEWORK_CONTRACT"))
        )
    );
  };

  documentsIsSendable = () => {
    if (!this.contractsIsSubmitted()) {
      return false;
    }
    if (
      this.documentIsNeeded("AUTHORIZATION") &&
      !this.documentIsUploaded("documents", "AUTHORIZATION")
    ) {
      return false;
    }
    if (
      this.documentIsNeeded("SIGNATURE_SPECIMEN") &&
      !this.documentIsUploaded("documents", "SIGNATURE_SPECIMEN")
    ) {
      return false;
    }

    if (
      this.documentIsUploaded("documents", "AUTHORIZATION") === false &&
      this.documentIsUploaded("documents", "SIGNATURE_SPECIMEN") === false
    ) {
      return false;
    }

    return true;
  };

  documentsIsUploaded = () => {
    return (
      (this.documentIsSubmitted("documents", "AUTHORIZATION") ||
        this.documentIsUploaded("documents", "AUTHORIZATION") ||
        !this.documentIsNeeded("AUTHORIZATION")) &&
      (this.documentIsSubmitted("documents", "SIGNATURE_SPECIMEN") ||
        this.documentIsUploaded("documents", "SIGNATURE_SPECIMEN") ||
        !this.documentIsNeeded("SIGNATURE_SPECIMEN"))
    );
  };

  documentsIsSubmitted = () => {
    return (
      this.state.status === "pending" ||
      this.state.status === "okay" ||
      (
        (this.documentIsSubmitted("documents", "AUTHORIZATION")  || !this.documentIsNeeded("AUTHORIZATION")) &&
        (this.documentIsSubmitted("documents", "SIGNATURE_SPECIMEN") || !this.documentIsNeeded("SIGNATURE_SPECIMEN"))
      ));
  };

  canResendContracts = () => {
    const { contract_sent_at } = this.state;
    return authRules.hasPermissionByKeyAndName("service-orders", "service-orders.forcedsend")
    && contract_sent_at
    && !this.contractsIsSendable();

  }

  canResendDocuments = () => {
    const { submitted_at } = this.state;
    return authRules.hasPermissionByKeyAndName("service-orders", "service-orders.forcedsend")
    && submitted_at
    && !this.documentsIsSendable();

  }

  /**
   * Megnézi, ha a beküldés után lett-e újabb dokumentum feltöltve
   * Ettől függ, hogy a szerződés beküldése gomb aktív-e
   */
  hasNewContract = () => {
    const { contract_sent_at, contracts } = this.state;
    const contractKeys = Object.keys(contracts);
    for (let i in contractKeys) {
      const contract = contracts[contractKeys[i]];
      const created_at = get(contract, "current.created_at", null);
      let created_at_unix = 0;
      let contract_sent_at_unix = 0;
      if (created_at) {
        created_at_unix = Moment(created_at).unix();
      }
      if (contract_sent_at) {
        contract_sent_at_unix = Moment(contract_sent_at).unix();
      }
      //console.log(created_at_unix, contract_sent_at_unix);
      if (created_at_unix > contract_sent_at_unix) {
        return true;
      }
    }
    return false;
  };

  /**
   * Csatol egy feltöltött dokumentumot
   */
  onUpload = (file, type) => {
    //console.log("onUpload", type, file);
    const id = this.props.match.params.id;
    return attachDocument(id, {
      type: type,
      file_id: file.id
    }).then(attachRes => {
      //console.log("attachRes", attachRes);
      let documents = Object.assign({}, this.state.documents);
      const document_id = get(attachRes, "data.data.document_id", null);
      documents[type].current = {
        created_at: file.created_at,
        document_id: document_id,
        file: file
      };
      this.setState({
        documents
      });
    });
  };

  /**
   * Eltávolít egy csatolt dokumentumot
   */
  onRemove = type => {
    //console.log("onRemove", type);
    let documents = Object.assign({}, this.state.documents);
    documents[type].current = [];
    this.setState({
      documents
    });
  };

  /**
   * Csatol egy feltöltött szerződést
   */
  onUploadContract = (file, type) => {
    //console.log("onUploadContract", type, file);
    const id = this.props.match.params.id;
    return attachDocument(id, {
      type: type,
      file_id: file.id
    })
      .then(attachRes => {
        //console.log("attachRes", attachRes);
        const document_id = get(attachRes, "data.data.document_id", null);
        let contracts = Object.assign({}, this.state.contracts);
        contracts[type].current = {
          created_at: file.created_at,
          document_id: document_id,
          file: file
        };
        //console.log("onUploadContract-contracts", contracts);
        this.setState({
          contracts: contracts
        });
        const context = this;
        setTimeout(() => {
          //console.log("timed", context.state.contracts);
        }, 2000);
      })
      .catch(error => {
        //console.log(error);
        toastr.error(error);
        let contracts = Object.assign({}, this.state.contracts);
        contracts[type].current = {};
        this.setState({
          contracts
        });
      });
  };

  /**
   * Eltávolít egy csatolt Szerződést
   */
  onRemoveContract = type => {
    //console.log("onRemoveContract", type);
    let contracts = Object.assign({}, this.state.contracts);
    contracts[type].current = [];
    this.setState({
      contracts
    });
  };

  /**
   * Ellenőrzni, hogy minden dokumentum fel van-e töltve
   * @return {boolen}
   */
  validate = () => {
    //Pending státusz esetén valid
    if (this.state.status === "pendig") {
      return true;
    }
    const types = Object.keys(this.state.documentTypes);
    for (let i in types) {
      const type = types[i];
      if (["AUTHORIZATION", "SIGNATURE_SPECIMEN"].indexOf(type) < 0) {
        continue;
      }
      if (this.state.documentTypes[type]) {
        if (
          get(this.state.documents, type + ".current.file", false) === false
        ) {
          return false;
        }
      }
    }
    return true;
  };

  /**
   * Ellenőrzni, hogy minden dokumentum fel van-e töltve
   * @return {boolen}
   */
  validateContract = () => {
    //Pending státusz esetén valid
    /*if (this.state.status === "pendig") {
      return true;
    }*/
    const types = Object.keys(this.state.documentTypes);
    for (let i in types) {
      const type = types[i];
      if (["CONTRACT_PAGE_1_5", "FRAMEWORK_CONTRACT"].indexOf(type) < 0) {
        continue;
      }
      if (this.state.documentTypes[type]) {
        if (
          get(this.state.contracts, type + ".current.file", false) === false
        ) {
          return false;
        }
      }
    }
    return true;
  };

  onBack = () => {
    //this.props.history.push("/service-orders");
    this.props.history.goBack();
  };

  /**
   * Beküldi a csatolt dokumentumokat
   */
  onSubmitDocuments = () => {
    const id = this.props.match.params.id;
    submitDocuments(id)
      .then(res => {
        //console.log("onSubmitDocuments", res);

        let documents = Object.assign({}, this.state.documents); // get(this.state, "contracts", {});
        let AUTHORIZATION = get(
          this.state,
          "documents.AUTHORIZATION.current.document_id",
          null
        );
        let SIGNATURE_SPECIMEN = get(
          this.state,
          "documents.SIGNATURE_SPECIMEN.current.document_id",
          null
        );
        if (AUTHORIZATION) {
          documents.AUTHORIZATION.history.unshift(
            Object.assign(
              {},
              this.state.documents["AUTHORIZATION"]
                .current , {
                file_name: get(this.state.contracts["AUTHORIZATION"],"current.file.file_name", "")
              }
            )
          );
        }
        if (SIGNATURE_SPECIMEN) {
          documents.SIGNATURE_SPECIMEN.history.unshift(
            Object.assign(
              {},
              this.state.documents["SIGNATURE_SPECIMEN"]
                .current ,
              {
                file_name: get(this.state.contracts["SIGNATURE_SPECIMEN"],"current.file.file_name", "")
              }
            )
          );
        }
        documents.AUTHORIZATION.current = [];
        documents.SIGNATURE_SPECIMEN.current = [];

        if (res.data && res.data.success) {
          this.setState({
            documents: documents,
            status: "pending",
            wrong_cause: null,
            submitted_at: Moment().format("YYYY-MM-DD HH:mm:ss"),
            documentTypes: Object.assign({}, this.state.documentTypes, {
              AUTHORIZATION: false,
              SIGNATURE_SPECIMEN: false
            })
          });
        }
      })
      .catch(error => {
        toastr.error(
          get(
            error,
            "response.data.message",
            get(error, "response.statusText", localize("basic", "A manóba, hiba történt."))
          )
        );
      });
  };

  /**
   * Beküldi a csatolt szerződéseket
   */
  onSubmitContracts = () => {
    const id = this.props.match.params.id;
    /*console.log(
      "before",
      Object.assign({}, this.state.contracts["FRAMEWORK_CONTRACT"].current)
    );*/
    submitContracts(id)
      .then(res => {
        //console.log("after", Object.assign({}, this.state));
        //console.log("onSubmitContracts", res);

        //Átrakjuk a 2 szerződést a historyba, haogy a szervertől fog majd jönni

        let contracts = Object.assign({}, this.state.contracts); // get(this.state, "contracts", {});
        let CONTRACT_PAGE_1_5 = get(
          this.state,
          "contracts.CONTRACT_PAGE_1_5.current.document_id",
          null
        );
        let FRAMEWORK_CONTRACT = get(
          this.state,
          "contracts.FRAMEWORK_CONTRACT.current.document_id",
          null
        );
        if (CONTRACT_PAGE_1_5) {
          contracts.CONTRACT_PAGE_1_5.history.unshift(
            Object.assign(
              {},
              this.state.contracts["CONTRACT_PAGE_1_5"].current,
              {
                file_name: this.state.contracts["CONTRACT_PAGE_1_5"].current
                  .file.file_name
              }
            )
          );
        }
        if (FRAMEWORK_CONTRACT) {
          contracts.FRAMEWORK_CONTRACT.history.unshift(
            Object.assign(
              {},
              this.state.contracts["FRAMEWORK_CONTRACT"].current,
              {
                file_name: this.state.contracts["FRAMEWORK_CONTRACT"].current
                  .file.file_name
              }
            )
          );
        }
        contracts.CONTRACT_PAGE_1_5.current = [];
        contracts.FRAMEWORK_CONTRACT.current = [];

        if (res.data && res.data.success) {
          this.setState({
            contracts,
            wrong_cause: this.documentsIsSubmitted()
              ? null
              : this.state.wrong_cause,
            status: this.documentsIsSubmitted()
              ? "pending"
              : this.state.status,
            contract_sent_at: Moment().format("YYYY-MM-DD HH:mm:ss"),
            documentTypes: Object.assign({}, this.state.documentTypes, {
              CONTRACT_PAGE_1_5: false,
              FRAMEWORK_CONTRACT: false
            })
          });
        }
      })
      .catch(error => {
        toastr.error(
          get(
            error,
            "response.data.message",
            get(error, "response.statusText", localize("basic", "A manóba, hiba történt."))
          )
        );
      });
  };

  render() {
    const { classes,  isView } = this.props;
    const {
      documentTypes,
      documents,
      contracts,
      wrong_cause,
      warning,
      status,
      contract_sent_at,
      submitted_at
    } = this.state;
    /*const status =
      wrong_cause && this.state.status === "pending"
        ? "invalid_documents"
        : this.state.status;*/
    const isValid = this.validate(); //Minden dokumentum fel van töltve
    //const isValidContract = this.validateContract(); //Minden szerződés fel van töltve
    const id = this.props.match.params.id;
    //const disableUpload = !isValid || status === "pending" ? true : false;
    //const disableUploadContract = !this.hasNewContract();
    //!isValidContract || contract_sent_at ? true : false;
    //console.log(status, disableUpload);

    const DocListItemWrapper = props => {
      const history = documents[props.type]
        ? documents[props.type].history[0]
        : null;
      /*console.log(
        props.type,
        this.documentIsNeeded(props.type),
        this.documentIsUploaded("documents", props.type),
        this.documentIsSubmitted("documents", props.type)
      );*/
      return documentTypes[props.type] || history ? (
        <Fragment>
          <DocListItem
            id={id}
            name={props.type}
            label={localize("serviceOrders",  props.label)}
            onUpload={file => this.onUpload(file, props.type)}
            onRemove={() => this.onRemove(props.type)}
            documents={documents[props.type]}
            needed={documentTypes[props.type]}
            isNeeded={this.documentIsNeeded(props.type)}
            isUploaded={this.documentIsUploaded("documents", props.type)}
            isSubmitted={this.documentIsSubmitted("documents", props.type)}
            canRemove={!this.documentIsSubmitted("documents", props.type)}
            classes={classes}
            status={status}
            isView={isView}
            remove={dettachDocument}
            acceptedFiles="application/pdf,image/jpeg,image/jpg,image/png"
          />
          <Divider />
        </Fragment>
      ) : null;
    };
    const ContractListItemWrapper = props => {
      const history = contracts[props.type]
        ? contracts[props.type].history[0]
        : null;
      /*console.log(
        props.type,
        this.documentIsNeeded(props.type),
        this.documentIsUploaded("contracts", props.type),
        this.documentIsSubmitted("contracts", props.type)
      );*/
      return documentTypes[props.type] || history ? (
        <Fragment>
          <DocListItem
            id={id}
            name={props.type}
            label={localize("serviceOrders",  props.label)}
            onUpload={file => this.onUploadContract(file, props.type)}
            onRemove={() => this.onRemoveContract(props.type)}
            documents={contracts[props.type]}
            needed={documentTypes[props.type]}
            isNeeded={this.documentIsNeeded(props.type)}
            isUploaded={this.documentIsUploaded("contracts", props.type)}
            isSubmitted={this.documentIsSubmitted("contracts", props.type)}
            classes={classes}
            status={status}
            isView={isView /*|| (contract_sent_at ? true : false)*/}
            canRemove={
              true /*!this.documentIsSubmitted("contracts", props.type)*/
            }
            canReplace={
              status !== "pending" &&
              status !== "okay" &&
              !this.documentIsNew(
                "contracts",
                props.type
              ) /* || this.documentIsSubmitted("contracts", props.type) */
            }
            //confirmUpload={this.documentIsSubmitted("contracts", props.type)}
            isContract={true}
            remove={dettachDocument}
            acceptedFiles="application/pdf,image/jpeg,image/jpg,image/png"
          />
          <Divider />
        </Fragment>
      ) : null;
    };

    const ToolbarIcon = props =>
      props.valid ? (
        <CheckIcon
          className={classNames(
            classes.toolbarIconStyle,
            classes.successIconColor
          )}
        />
      ) : (
        <WarningIcon
          className={classNames(
            classes.toolbarIconStyle,
            classes.warningIconColor
          )}
        />
      );
    let title = wrong_cause ? localize("serviceOrders", "Dokumentumok pótlása") : localize("serviceOrders", "Dokumentumok beküldése");
    let titleContract = wrong_cause
      ? localize("serviceOrders", "Szerződés pótlása")
      : localize("serviceOrders", "Szerződés beküldése");

    if (isView) {
      return (
        <List dense={false}>
          <ContractListItemWrapper
            type="CONTRACT_PAGE_1_5"
            label="Szerződés 1. és 5. oldal"
          />
          <DocListItemWrapper type="AUTHORIZATION" label="Meghatalmazás" />
          <DocListItemWrapper
            type="SIGNATURE_SPECIMEN"
            label="Aláírási címpéldány / E.V. esetén személyi igazolvány"
          />
          <ContractListItemWrapper
            type="FRAMEWORK_CONTRACT"
            label="Keretszerződés"
          />
        </List>
      );
    }

    return (
      <Fragment>
        {
          <Helmet>
            <title>{title}</title>
          </Helmet>
        }
        <ContainerHeader title={titleContract} />
        <Grid container spacing={16}>
          <Grid item sm={12} md={10} lg={8} xl={6}>
            <Paper>
              <List dense={false}>
                <ContractListItemWrapper
                  type="CONTRACT_PAGE_1_5"
                  label="Szerződés 1. és 5. oldal"
                />
                <ContractListItemWrapper
                  type="FRAMEWORK_CONTRACT"
                  label="Keretszerződés"
                />
              </List>
              <Toolbar className={classes.toolbarStyle}>
                <ToolbarIcon valid={this.contractsIsUploaded()} />
                <Typography>1. {localize("serviceOrders", "Szerződések csatolása.")}</Typography>
              </Toolbar>
              <Toolbar className={classes.toolbarStyle}>
                <ToolbarIcon valid={this.contractsIsSubmitted()} />
                <Typography>2. {localize("serviceOrders", "Csatolt szerződések beküldése.")}</Typography>
                {contract_sent_at && (
                  <Typography variant="caption">
                    &nbsp;({contract_sent_at})
                  </Typography>
                )}
              </Toolbar>
              <Toolbar style={{ marginBottom: 8, paddingRight: 12 }}>
                <Hidden smDown>
                  {this.canResendContracts() && <Button
                    onClick={this.onSubmitContracts}
                    style={{ marginLeft: "auto" }}
                    variant="contained"
                    color="secondary"
                  >
                    {localize("serviceOrders", "Újraküldés")}
                  </Button>}
                  <Button
                    onClick={this.onSubmitContracts}
                    style={{ marginLeft: this.canResendContracts() ? "12px" : "auto" }}
                    variant="contained"
                    disabled={!this.contractsIsSendable()}
                    color="primary"
                  >
                    {localize("serviceOrders", "Csatolt szerződések beküldése")}
                  </Button>
                </Hidden>
              </Toolbar>
              <Hidden mdUp>
                <Toolbar style={{ marginBottom: 8, paddingRight: 12 }}>
                  {this.canResendContracts() && <Button
                    onClick={this.onSubmitContracts}
                    style={{ marginLeft: "auto" }}
                    variant="contained"
                    color="secondary"
                  >
                    {localize("serviceOrders", "Újraküldés")}
                  </Button>}
                  <Button
                    onClick={this.onSubmitContracts}
                    style={{ marginLeft: this.canResendContracts() ? "12px" : "auto" }}
                    variant="contained"
                    disabled={!this.contractsIsSendable()}
                    color="primary"
                  >
                    {localize("serviceOrders", "Csatolt szerződések beküldése")}
                  </Button>
                </Toolbar>
              </Hidden>
            </Paper>
          </Grid>
        </Grid>
        <ContainerHeader title={title} />
        <Grid container spacing={16}>
          <Grid item sm={12} md={10} lg={8} xl={6}>
            <Paper>
              <List dense={false}>
                <DocListItemWrapper
                  type="AUTHORIZATION"
                  label="Meghatalmazás"
                />
                <DocListItemWrapper
                  type="SIGNATURE_SPECIMEN"
                  label="Aláírási címpéldány / E.V. esetén személyi igazolvány"
                />
              </List>
              <Toolbar className={classes.toolbarStyle}>
                <ToolbarIcon valid={this.contractsIsSubmitted()} />
                <Typography>1. {localize("serviceOrders", "Szerződések beküldése.")}</Typography>
              </Toolbar>
              <Toolbar className={classes.toolbarStyle}>
                <ToolbarIcon valid={this.documentsIsUploaded()} />
                <Typography>2. {localize("serviceOrders", "Dokumentumok csatolása.")}</Typography>
              </Toolbar>
              <Toolbar className={classes.toolbarStyle}>
                <ToolbarIcon valid={this.documentsIsSubmitted()} />
                <Typography>3. {localize("serviceOrders", "Csatolt dokumentumok beküldése.")}</Typography>
                {submitted_at && (
                  <Typography variant="caption">
                      &nbsp;({submitted_at})
                  </Typography>
                )}
              </Toolbar>
              {status === "pending" && this.documentsIsSubmitted() && (
                <Toolbar className={classes.toolbarStyle}>
                  <WaitIcon
                    className={classNames(
                      classes.toolbarIconStyle,
                      classes.successIconColor
                    )}
                  />
                  <Typography>4. {localize("serviceOrders", "Várakozás az ellenőrzésre.")}</Typography>
                </Toolbar>
              )}
              <Toolbar style={{ marginBottom: 8, paddingRight: 12 }}>
                <InfoIcon
                  className={classNames(
                    classes.toolbarIconStyle,
                    classes.greyInfoIconColor
                  )}
                />
                <Typography className={classes.greyInfo}>
                  {localize("serviceOrders", "A beküldött dokumentumok módosítása nem lehetséges")}
                </Typography>
                <Hidden smDown>
                  {this.canResendDocuments() && <Button
                    onClick={this.onSubmitDocuments}
                    style={{ marginLeft: "auto" }}
                    variant="contained"
                    color="secondary"
                  >
                    {localize("serviceOrders", "Újraküldés")}
                  </Button>}
                  <Button
                    onClick={this.onSubmitDocuments}
                    style={{ marginLeft: this.canResendDocuments() ? "12px" : "auto" }}
                    variant="contained"
                    disabled={!this.documentsIsSendable()}
                    color="primary"
                  >
                    {localize("serviceOrders", "Csatolt dokumentumok beküldése")}
                  </Button>
                </Hidden>
              </Toolbar>
              <Hidden mdUp>
                <Toolbar style={{ marginBottom: 8, paddingRight: 12 }}>
                  {this.canResendDocuments() && <Button
                    onClick={this.onSubmitDocuments}
                    style={{ marginLeft: "auto" }}
                    variant="contained"
                    color="secondary"
                  >
                    {localize("serviceOrders", "Újraküldés")}
                  </Button>}
                  <Button
                    onClick={this.onSubmitDocuments}
                    style={{ marginLeft: this.canResendDocuments() ? "12px" : "auto" }}
                    variant="contained"
                    disabled={!this.documentsIsSendable()}
                    color="primary"
                  >
                    {localize("serviceOrders", "Csatolt dokumentumok beküldése")}
                  </Button>
                </Toolbar>
              </Hidden>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item sm={12} md={10} lg={8} xl={6}>
            <Paper>
              {status === "wrong" ? (
                <Toolbar
                  className={classes.toolbarStyle}
                  style={{ paddingTop: 16, paddingRight: 12 }}
                >
                  <InfoIcon
                    className={classNames(
                      classes.toolbarIconStyle,
                      classes.errorIconColor
                    )}
                  />
                  <Typography className={classes.error}>
                    {wrong_cause}
                  </Typography>
                </Toolbar>
              ) : null}
              {status !== "wrong" && warning ? (
                <Toolbar
                  className={classes.toolbarStyle}
                  style={{ paddingTop: 16, paddingRight: 12 }}
                >
                  <WarningIcon
                    className={classNames(
                      classes.toolbarIconStyle,
                      classes.warningIconColor
                    )}
                  />
                  <Typography className={classes.warning}>
                    {warning}
                  </Typography>
                </Toolbar>
              ) : null}
              <Toolbar style={{ marginBottom: 8, paddingRight: 12 }}>
                <Button
                  onClick={this.onBack}
                  style={{ marginLeft: "auto" }}
                  variant="contained"
                  //disabled={disableUpload}
                  color="default"
                >
                  {localize("basic", "Vissza")}
                </Button>
              </Toolbar>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

UploadServiceOrder.propTypes = {
  classes: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  file: PropTypes.object,
  onUpload: PropTypes.func,
  match: PropTypes.object,
  documents: PropTypes.object,
  isView: PropTypes.bool
};

export default withStyles(styles)(UploadServiceOrder);
