import React from "react";
import PropTypes from "prop-types";
import { merge, get } from "lodash";
import restHelper from "helpers/restHelper";
import localize from "helpers/localize";
import ModalConfirm from "Components/Modal/ModalConfirm";
import Validations from "Components/Forms/Validations";
import { withRouter } from "react-router-dom";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
import storageHelper from "helpers/storageHelper";

const baseState = (tableOptions, formOptions, viewOptions) => {
  let state = {
    data: [],
    editItem: null,
    optionsError: null,
    error: null,
    options: {
      table: { model: null, columns: {}, columnsOrder: [] },
      form: { model: null, fields: {}, fieldsOrder: [] },
      view: { model: null, fields: {}, fieldsOrder: [] }
    },
    errors: {},
    needOptionFetch: true,
    needFetch: true, //Ha egy sort módosítottak, akkor a listára való visszalépésnél frissíteni kell azt
    loading: true,
    isView: false
  };
  //table options merge
  const { model: tableModel, columns, columnsOrder } = tableOptions;
  state.options.table = merge({}, state.options.table, {
    model: tableModel,
    columns,
    columnsOrder
  });

  //Form options merge
  const { model: formModel, fields, fieldsOrder } = formOptions;
  state.options.form = merge({}, state.options.form, {
    model: formModel,
    fields,
    fieldsOrder
  });

  //View options merge
  const {
    model: viewModel,
    fields: viewFields,
    fieldsOrder: viewFieldsOrder
  } = viewOptions;
  state.options.view = merge({}, state.options.view, {
    model: viewModel,
    fields: viewFields,
    fieldsOrder: viewFieldsOrder
  });

  return state;
};

class DataProvider extends React.Component {
  constructor(props) {
    super(props);
    this.storage = storageHelper("session", props.url, this, [
      "filters",
      "sorting",
      "page",
      "searchValue",
      "columnWidths",
      "hiddenColumnNames"
    ]);
    this.state = {
      ...baseState(props.tableProps, props.formProps, props.viewProps),
      perPage: props.perPage,
      page: 1,
      total: 0,
      sorting: [],
      filters: [],
      searchValue: "",
      columnWidths: [],
      hiddenColumnNames: [],
      editItem: props.match.params.menu ? {} : null, //Ha CRUD-n vagyunk egy üres elemet adunk, hogy egyből a form jelenjen meg
      isView: props.viewSlug === props.match.params.menu,
      ...this.storage.get() //storage értékek feltöltése
    };
    this.lastPath = props.history.location.pathname;
    this.usedFields = {}; //Automatán felhasznált mezők
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { menu, id }
      }
    } = this.props;

    const {
      match: {
        params: { menu: nextMenu, id: nextId }
      }
    } = nextProps;
    console.log("componentWillReceiveProps", menu, id, nextMenu, nextId);
    if (nextMenu && (menu !== nextMenu || id !== nextId)) {
      this.handleCrudState(nextId, nextMenu);
    } else if (!nextMenu && !nextMenu) {
      if (this.state.editItem) {
        this.setState({ editItem: null, isView: false });
      }
      if (this.state.needFetch) {
        this.fetchData();
      }
    }
  }

  handleCrudState = (id, menu) => {
    console.log("handleCrudState", menu, id);
    const { editSlug, createSlug, viewSlug, disableOptions } = this.props;
    //Edit
    //console.log("DataProvider", menu, id);
    if (menu === editSlug && id) {
      if (this.state.needOptionFetch && !disableOptions) {
        this.fetchOptions().then(options => {
          this.view(id);
          /*if(this.state.data.length === 0) {
            this.setState({ needFetch: true });
          }*/
        });
      } else {
        this.view(id);
        //this.setState({ needFetch: true });
      }
      return true;
    }
    //View
    //console.log("DataProvider", menu, id);
    if (menu === viewSlug && id) {
      //this.setState({ isView: true });
      if (this.state.needOptionFetch && !disableOptions) {
        this.fetchOptions().then(options => {
          this.view(id, false, true);
          /*if(this.state.data.length === 0) {
            this.setState({ needFetch: true });
          }*/
        });
      } else {
        this.view(id, false, true);
        //this.setState({ needFetch: true });
      }
      return true;
    }
    //create
    console.log("menu", menu, createSlug);
    if (menu === createSlug) {
      const editItem = this.getDefaultValues();
      if (this.state.needOptionFetch && !disableOptions) {
        this.fetchOptions().then(options => {
          this.setState({ editItem, loading: false });
        });
      } else {
        this.setState({ editItem, loading: false });
      }
      return true;
    }
  };

  setUsedField = usedFields => {
    this.usedFields = usedFields;
  };

  componentWillUnmount() {
    this.storage.clearBrowser();
  }

  componentDidMount() {
    const { tableComponent } = this.props;
    const {
      disableOptions,
      match: {
        params: { menu, id }
      }
    } = this.props;
    console.log("componentDidMount", menu, id);
    if (this.handleCrudState(id, menu)) {
      return;
    }

    if (disableOptions) {
      tableComponent &&
        this.fetchData().then(data => {
          console.log("DataProvider.fetchData", data);
        });
    } else {
      this.fetchOptions().then(options => {
        console.log("DataProvider.fetchOptions", options);

        tableComponent &&
          this.fetchData().then(data => {
            console.log("DataProvider.fetchData", data);
            /*if (data.length > 0) {
          Object.keys(data[0]).forEach(key => {
            if (!options.grid.columns[key]) {
              options.grid.columns[key] = {};
            } else {
              //options.grid.columns[key].hidden = false;
            }
          });
          this.setState({ options });
        }*/
          });
      });
    }
  }

  fieldValue = fieldName => {
    if (this.state.editItem) {
      return this.state.editItem[fieldName];
    }
  };

  fieldError = fieldName => {
    const _validators = get(
      this.state,
      `options.form.fields.${fieldName}.validators`
    );
    const validators =
      typeof _validators === "function"
        ? _validators(this.state.editItem)
        : _validators;

    console.log("booo", _validators);
    if (validators) {
      console.log("yooo", validators);
      let errors = [];
      validators.forEach(element => {
        const error = Validations[element](this.fieldValue(fieldName));
        console.log(
          "fieldError",
          fieldName,
          element,
          error,
          this.fieldValue(fieldName)
        );
        if (error) {
          errors.push(error);
        }
      });
      return errors.length > 0 ? errors : null;
    } else {
      return null;
    }
  };

  isRequiredField = fieldName => {
    const _validators = get(
      this.state,
      `options.form.fields.${fieldName}.validators`
    );
    const validators =
      typeof _validators === "function"
        ? _validators(this.state.editItem)
        : _validators;
    if (validators) {
      return validators.indexOf("required") >= 0;
    } else {
      return false;
    }
  };

  validateField = fieldName => {
    let errors = Object.assign({}, this.state.errors);
    //const error = this.fieldError(fieldName);
    //console.log(this.usedFields);
    const error = this.usedFields[fieldName]
      ? this.fieldError(fieldName)
      : null;
    let valid = false;
    if (error) {
      errors[fieldName] = error;
      valid = false;
    } else {
      delete errors[fieldName];
      valid = true;
    }
    this.setState({ errors });
    return valid;
  };

  validateFields = () => {
    const fields = get(this.state, "options.form.fields", {});
    let valid = true;
    let errors = Object.assign({}, this.state.errors);
    Object.keys(fields).forEach(fieldName => {
      const _validators = get(
        this.state,
        `options.form.fields.${fieldName}.validators`
      );

      const validators =
        typeof _validators === "function"
          ? _validators(this.state.editItem)
          : _validators;
      //const error = this.fieldError(fieldName);
      console.log("used", fieldName, this.usedFields[fieldName]);
      const error = this.usedFields[fieldName]
        ? this.fieldError(fieldName)
        : null;
      //Csak azokat a hibaüzeneteket bántjuk, ahol van validátor, a többi bizonyára szerveroldali
      if (validators) {
        console.log("validators", fieldName, validators, error);
        if (error) {
          valid = false;
          //console.log(fieldName, "invalid");
          errors[fieldName] = error;
        } else {
          delete errors[fieldName];
        }
      }
    });
    this.setState({ errors });
    console.log(valid);
    return valid;
  };

  onChangePage = page => {
    this.storage.set({ page }, this.fetchData);
  };

  onChangePerPage = perPage => {
    this.storage.set({ perPage, page: 1 }, this.fetchData);
  };

  onChangeFilters = filters => {
    this.storage.set({ filters, page: 1 }, this.fetchData);
  };

  onChangeSorting = sorting => {
    this.storage.set({ sorting }, this.fetchData);
  };

  onChangeSearchValue = searchValue => {
    this.storage.set({ searchValue }, this.fetchData);
  };

  onColumnWidthsChange = columnWidths => {
    this.storage.set({ columnWidths }, this.fetchData);
  };
  onChangeHiddenColumnNames = hiddenColumnNames => {
    this.storage.set({ hiddenColumnNames }, this.fetchData);
  };

  fetchData = params => {
    const { url, restHelper, restHelperOptions, queryParams } = this.props;
    const { perPage, page, sorting, filters, searchValue } = this.state;
    const model = this.state.options.table.model;
    const sort = sorting[0];
    let newFilters = {};
    if (filters) {
      filters.forEach(filter => {
        //filter.operation
        if (filter.value && filter.value !== "") {
          let filterColumn = filter.columnName;
          //Find uri name
          if (
            this.state.options &&
            this.state.options.table &&
            this.state.options.table.columns &&
            this.state.options.table.columns[filter.columnName] &&
            this.state.options.table.columns[filter.columnName].uriName
          ) {
            filterColumn = this.state.options.table.columns[filter.columnName]
              .uriName;
          }
          newFilters[`${model}[${filterColumn}]`] = filter.value;
        }
      });
    }
    let newSorting = {};
    if (sort) {
      newSorting.sort =
        sort.direction === "desc" ? `-${sort.columnName}` : sort.columnName;
    }
    const mergedParams = Object.assign(
      {},
      { "per-page": perPage, page },
      { keyword: searchValue },
      queryParams,
      params,
      newFilters,
      newSorting
    );
    this.setState({ loading: true });
    return restHelper
      .index(url, mergedParams, restHelperOptions)
      .then(response => {
        this.setState({
          data: Array.isArray(response.data) ? response.data : [], // TODO: Sor csere, ha kész a backend -> data: response.data,
          page: response.page,
          total: response.total,
          perPage: response["per-page"],
          error: null,
          loading: false,
          needFetch: false
        });
        return response.data;
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  view = (id, isRaw = true, isView = false, silent = false) => {
    const { url, restHelper, restHelperOptions } = this.props;
    const params = null;
    !silent && this.setState({ loading: true });
    return restHelper
      .view(`${url}${isRaw ? "/raw" : ""}`, id, params, restHelperOptions)
      .then(response => {
        this.setState({
          editItem: response.data,
          error: null,
          errors: {},
          loading: false,
          isView
        });
        return response.data;
      })
      .catch(error => {
        this.setState({ error, isView });
      });
  };

  updateListItem = item => {
    this.setState({ data: this.updateDataItem(item) });
  };

  fetchOptions = () => {
    const {
      url: urlRaw,
      restHelper,
      restHelperOptions,
      optionsUrl,
      tableProps,
      formProps,
      viewProps,
      tableOptionsKey,
      formOptionsKey,
      viewOptionsKey
    } = this.props;
    let urlArray = urlRaw.split("?");
    let url = optionsUrl ? optionsUrl : `${urlRaw}/options`;
    if (urlArray.length > 1) {
      url = `${urlArray[0]}/options?${urlArray[1]}`;
    }

    return restHelper
      .index(url, null, restHelperOptions)
      .then(response => {
        //console.log(response.data);
        //baseFields rename to fields
        if (
          response.data[formOptionsKey] &&
          response.data[formOptionsKey].baseFields
        ) {
          response.data[formOptionsKey].fields =
            response.data[formOptionsKey].baseFields;
          delete response.data[formOptionsKey].baseFields;
        }
        if (
          response.data[viewOptionsKey] &&
          response.data[viewOptionsKey].baseFields
        ) {
          response.data[viewOptionsKey].fields =
            response.data[viewOptionsKey].baseFields;
          delete response.data[viewOptionsKey].baseFields;
        }
        const options = merge(
          {},
          baseState(tableProps, formProps, viewProps).options,
          {
            table: !Array.isArray(response.data[tableOptionsKey])
              ? response.data[tableOptionsKey]
              : {},
            form: !Array.isArray(response.data[formOptionsKey])
              ? response.data[formOptionsKey]
              : {},
            view: !Array.isArray(response.data[viewOptionsKey])
              ? response.data[viewOptionsKey]
              : {}
          }
        );
        //console.log(options);
        //return;
        this.setState({ options, optionsError: null, needOptionFetch: false });
        return options;
      })
      .catch(error => {
        this.setState({ optionsError: error });
      });
  };

  showGlobalValidationError = () => {
    if (this.props.disableGlobalValidationError) {
      return;
    }
    globalMessage.error(
      "Hiányos vagy rosszul kitöltött mezők vannak az űrlapon."
    );
  };

  goBack = () => {
    const { needFetch } = this.state;
    const {
      history,
      useUrlRouting,
      match: {
        url,
        params: { menu, id }
      }
    } = this.props;
    if (useUrlRouting) {
      const listPath = id
        ? url.replace(`/${menu}/${id}`, "")
        : url.replace(`/${menu}`, "");

      //console.log("this.lastPath", this.lastPath);
      if (this.lastPath === listPath) {
        console.log("history.goBack()");
        history.goBack();
      } else {
        //console.log(`history.push('${listPath}')`);
        history.push(listPath);
      }
    } else {
      if (needFetch) {
        this.setState({ editItem: null, isView: false }, this.fetchData);
      } else {
        this.setState({ editItem: null, isView: false });
      }
    }
  };

  onSave = () => {
    const {
      url,
      restHelper,
      restHelperOptions,
      goBackAfterSave,
      disableGetView,
      fieldsExcluded
    } = this.props;
    let { editItem } = this.state;
    const { model } = this.state.options.form;
    fieldsExcluded &&
      fieldsExcluded.forEach(fe => {
        editItem.hasOwnProperty(fe) && delete editItem[fe];
      });
    delete editItem.created_at;
    delete editItem.created_by;
    //Kliens oldali validáció
    if (!this.validateFields()) {
      this.showGlobalValidationError();
      return;
    }

    console.log("save", editItem);
    if (editItem.id) {
      restHelper
        .update(url, model, editItem.id, editItem, restHelperOptions)
        .then(response => {
          console.log("updated", response);
          const newItem = Object.assign({}, editItem, response.data);
          if (this.props.afterSave) {
            this.props.afterSave(newItem, false);
          }
          this.setState(
            {
              editItem: newItem,
              needFetch: !disableGetView,
              //Csak akkor kell manipulálni a listát, ha nincs külün getView request
              data: disableGetView
                ? this.updateDataItem(newItem)
                : this.state.data,
              errors: {}
            },
            () => {
              if (goBackAfterSave) {
                this.goBack();
              }
            }
          );
        })
        .catch(error => {
          if (
            error &&
            error.form_errors &&
            typeof error.form_errors === "object"
          ) {
            this.showGlobalValidationError();
            this.setState({ errors: error.form_errors });
          }
        });
    } else {
      restHelper
        .create(url, model, editItem, restHelperOptions)
        .then(response => {
          console.log("created", response);
          const newItem = Object.assign({}, editItem, response.data);

          if (this.props.afterSave) {
            this.props.afterSave(newItem, true);
          }
          //Csak akkor kell manipulálni a listát, ha nincs külün getView request
          let data = null;
          if (disableGetView) {
            data = this.state.data.slice();
            data.push(newItem);
          } else {
            data = this.state.data;
          }

          this.setState(
            {
              editItem: newItem,
              needFetch: !disableGetView,
              data: data,
              errors: {}
            },
            () => {
              if (goBackAfterSave) {
                this.goBack();
              }
            }
          );
        })
        .catch(error => {
          if (
            error &&
            error.form_errors &&
            typeof error.form_errors === "object"
          ) {
            this.showGlobalValidationError();
            this.setState({ errors: error.form_errors });
          }
        });
    }
    //this.setState({ editItem: row });
  };

  updateDataItem(item) {
    const { data } = this.state;
    const updatedData = data.map(element => {
      return element.id === item.id
        ? Object.assign({}, element, item)
        : Object.assign({}, element);
    });

    return updatedData;
  }

  onEdit = (row, rowIndex) => {
    const {
      disableGetView,
      useUrlRouting,
      history,
      editSlug,
      match: { url }
    } = this.props;
    //console.log(row, rowIndex);
    if (disableGetView) {
      this.setState({ editItem: row, errors: {}, loading: false });
    } else {
      if (useUrlRouting) {
        this.lastPath = url;
        history.push(`${url}/${editSlug}/${row.id}`);
      } else {
        this.view(row.id);
      }
    }
  };

  onView = (row, rowIndex) => {
    const {
      disableGetView,
      useUrlRouting,
      history,
      viewSlug,
      match: { url }
    } = this.props;
    //console.log(row, rowIndex);
    if (disableGetView) {
      this.setState({
        editItem: row,
        errors: {},
        isView: true,
        loading: false
      });
    } else {
      if (useUrlRouting) {
        this.lastPath = url;
        history.push(`${url}/${viewSlug}/${row.id}`);
      } else {
        this.view(row.id);
      }
    }
  };

  refreshView = (silent = false) => {
    const { editItem } = this.state;
    if (editItem && editItem.id) {
      return this.view(editItem.id, true, false, silent);
    }
  };

  onEndEdit = () => {
    this.goBack();
  };

  onCreate = () => {
    const {
      useUrlRouting,
      history,
      createSlug,
      match: { url }
    } = this.props;

    if (useUrlRouting) {
      this.lastPath = url;
      history.push(`${url}/${createSlug}`);
    } else {
      const editItem = this.getDefaultValues();
      this.setState({ editItem: editItem, loading: false });
    }
  };

  getDefaultValues = () => {
    const {
      options: {
        form: { fields }
      }
    } = this.state;

    let editItem = {};
    Object.keys(fields).forEach(fieldName => {
      if (fields[fieldName].hasOwnProperty("defaultValue")) {
        editItem[fieldName] = fields[fieldName].defaultValue;
      }
    });

    return editItem;
  };

  onChange = (key, value) => {
    let editItem = Object.assign({}, this.state.editItem);
    editItem[key] = value;
    this.setState({ editItem });
  };

  onDelete = (row, rowIndex) => {
    const {
      url,
      restHelper,
      restHelperOptions,
      customDelete,
      customDeleteTitle,
      customDeleteMessage
    } = this.props;
    const { model } = this.state.options.form;

    console.log(row, rowIndex);

    this.modal
      .open(
        customDeleteTitle || "Törlés megerősítése",
        customDeleteMessage || "Biztosan törli a sort?",
        null,
        "Igen",
        "Mégsem"
      )
      .then(res => {
        if (customDelete) {
          customDelete(row, restHelperOptions)
            .then(response => {
              this.modal.close();
              let data = this.state.data.slice();
              data.splice(rowIndex, 1);
              this.setState({ data });
            })
            .finally(() => {
              this.modal.close();
            });
        } else {
          restHelper
            .remove(url, row.id, restHelperOptions)
            .then(response => {
              let data = this.state.data.slice();
              data.splice(rowIndex, 1);
              this.setState({ data });
            })
            .finally(() => {
              this.modal.close();
            });
        }
      });
  };

  render() {
    const {
      tableComponent: Table,
      formComponent: Form,
      viewComponent: View,
      /* eslint-disable no-unused-vars */
      tableProps: {
        model: tableModel,
        columns,
        columnsOrder,
        ...restTableProps
      },
      formProps: { model: formModel, fields, fieldsOrder, ...restFormProps },
      viewProps: {
        model: viewModel,
        fields: viewFields,
        fieldsOrder: viewFieldsOrder,
        ...restViewProps
      },
      match: {
        params: { menu, id }
      }
      /* eslint-enable no-unused-vars */
    } = this.props;

    const { editItem, isView } = this.state;

    if (!Form && !Table && !View) {
      return "DataProvider has no component";
    }

    return (
      <div>
        {editItem && isView && View && (
          <View
            {...this.state}
            onEdit={this.onEdit}
            onView={this.onView}
            onDelete={this.onDelete}
            onEndEdit={this.onEndEdit}
            onCreate={this.onCreate}
            onSave={this.onSave}
            onChange={this.onChange}
            fetchData={this.fetchData}
            refreshView={this.refreshView}
            validateField={this.validateField}
            isRequiredField={this.isRequiredField}
            setUsedField={this.setUsedField}
            {...restViewProps}
          />
        )}
        {editItem && !isView && Form && (
          <Form
            {...this.state}
            onEdit={this.onEdit}
            onView={this.onView}
            onDelete={this.onDelete}
            onEndEdit={this.onEndEdit}
            onCreate={this.onCreate}
            onSave={this.onSave}
            onChange={this.onChange}
            fetchData={this.fetchData}
            refreshView={this.refreshView}
            validateField={this.validateField}
            isRequiredField={this.isRequiredField}
            setUsedField={this.setUsedField}
            {...restFormProps}
          />
        )}
        {!editItem && (
          <Table
            {...this.state}
            onEdit={this.onEdit}
            updateListItem={this.updateListItem}
            onView={this.onView}
            onDelete={this.onDelete}
            onEndEdit={this.onEndEdit}
            onCreate={this.onCreate}
            onSave={this.onSave}
            onChange={this.onChange}
            fetchData={this.fetchData}
            refreshView={this.refreshView}
            onChangePage={this.onChangePage}
            onChangePerPage={this.onChangePerPage}
            onChangeFilters={this.onChangeFilters}
            onChangeSorting={this.onChangeSorting}
            onChangeHiddenColumnNames={this.onChangeHiddenColumnNames}
            onChangeSearchValue={this.onChangeSearchValue}
            onColumnWidthsChange={this.onColumnWidthsChange}
            validateField={this.validateField}
            isRequiredField={this.isRequiredField}
            {...restTableProps}
          />
        )}
        <ModalConfirm onRef={ref => (this.modal = ref)} />
      </div>
    );
  }
}

DataProvider.defaultProps = {
  tableOptionsKey: "grid",
  formOptionsKey: "form",
  viewOptionsKey: "view",
  perPage: 20,
  restHelper: restHelper,
  tableProps: {},
  formProps: {},
  viewProps: {},
  editSlug: "edit",
  createSlug: "create",
  viewSlug: "view",
  fieldsExcluded: ["created_at", "updated_at", "created_by", "updated_by"]
};

DataProvider.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  tableComponent: PropTypes.any, // Táblázat komponens
  formComponent: PropTypes.any, // Form komponens
  viewComponent: PropTypes.any, // View komponens
  tableProps: PropTypes.object, //Táblázat paraméterei
  formProps: PropTypes.object, //Form paraméterei
  viewProps: PropTypes.object, //View paraméterei
  tableOptionsKey: PropTypes.string, //Table options kulcs default: grid
  formOptionsKey: PropTypes.string, //Form options kulcs default: form
  viewOptionsKey: PropTypes.string, //View options kulcs default: form
  perPage: PropTypes.number, //Oldalak száma
  restHelper: PropTypes.object,
  restHelperOptions: PropTypes.object,
  queryParams: PropTypes.object,
  optionsUrl: PropTypes.string, //Egyedi options url
  goBackAfterSave: PropTypes.bool,
  disableOptions: PropTypes.bool, //Az options request kihagyása
  disableGetView: PropTypes.bool, //Letiltja, a get kérést és a formnál a táblázat sor adatát használja
  useUrlRouting: PropTypes.bool, //Letiltja, a get kérést és a formnál a táblázat sor adatát használja
  editSlug: PropTypes.string, //Szerkesztés url rész
  createSlug: PropTypes.string, //Szerkesztés url rész
  viewSlug: PropTypes.string, //Szerkesztés url rész,
  fieldsExcluded: PropTypes.array, //Mezők kizárása a mentésből. alapértelmezett érték: ["created_at", "updated_at", "created_by", "updated_by"],
  disableGlobalValidationError: PropTypes.bool //Hibásan kitöltött mezők eseténb nem jelenik meg a popup.
};

export default withRouter(DataProvider);
