import React, { Component } from "react";
import Modal from "Components/Modal/Modal";
import ListIcon from "@material-ui/icons/List";
import { Typography } from "@material-ui/core";
import restHelper from "helpers/restHelper";
import StaticTable from "Components/New/DataTable/StaticTable/StaticTable";
import localize from "helpers/localize";

class OutgoingAddressesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: false
    };
  }

  componentDidMount() {
    //this.fetchAddresses(this.props.message_id);
  }

  fetchAddresses = id => {
    if (!id) {
      return;
    }
    this.setState({ loading: true });
    if (this.props.type === "recipients") {
      restHelper
        .view("outgoing-sms-messages/recipients", id)
        .then(response => {
          console.log(response);
          this.setState({ items: response.data || [], loading: false });
        })
        .catch(error => {
          this.setState({ items: [], loading: false });
        });
    }
    if (this.props.type === "client_tag") {
      restHelper
        .index("outgoing-sms-messages/client-tag-recipients", { keyword: id })
        .then(response => {
          console.log(response);
          this.setState({ items: response.data || [], loading: false });
        })
        .catch(error => {
          this.setState({ items: [], loading: false });
        });
    }
    if (this.props.type === "distributor_tag") {
      restHelper
        .index("outgoing-sms-messages/distributor-tag-recipients", {
          keyword: id
        })
        .then(response => {
          console.log(response);
          this.setState({ items: response.data || [], loading: false });
        })
        .catch(error => {
          this.setState({ items: [], loading: false });
        });
    }
    if (this.props.type === "distributor_id") {
      restHelper
        .index("outgoing-sms-messages/distributor-recipients", {
          id
        })
        .then(response => {
          console.log(response);
          this.setState({ items: response.data || [], loading: false });
        })
        .catch(error => {
          this.setState({ items: [], loading: false });
        });
    }
    if (this.props.type === "client_id") {
      restHelper
        .index("outgoing-sms-messages/client-recipients", {
          id
        })
        .then(response => {
          console.log(response);
          this.setState({ items: response.data || [], loading: false });
        })
        .catch(error => {
          this.setState({ items: [], loading: false });
        });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.onOpen();
    }
    /*if (nextProps.message_id !== this.props.message_id) {
      this.fetchAddresses(nextProps.message_id, nextProps.type);
    }*/
  }

  onOpen = () => {
    console.log("onOpen");
    this.setState({ items: [] }, () => {
      this.fetchAddresses(this.props.message_id);
    });
  };

  onAccept = () => {
    this.onClose();
  };

  onCancel = () => {
    this.onClose();
  };
  onClose = () => {
    const { onClose } = this.props;
    onClose && onClose();
  };

  columns = () => {
    const { type } = this.props;

    if (type === "recipients") {
      return [
        { id: "user_name", label: localize("sms", "Felhasználó") },
        {
          id: "number",
          label: localize("sms", "Telefonszám"),
          cellStyle: row =>
            !row.number || row.number === ""
              ? { backgroundColor: "red" }
              : undefined
        },
        { id: "type", label: localize("sms", "Típus") },
        { id: "owner_name", label: localize("sms", "Viszonteladó/Ügyfél") }
      ];
    } else {
      return [
        { id: "user_name", label: localize("sms", "Felhasználó") },
        {
          id: "number",
          label: localize("sms", "Telefonszám"),
          cellStyle: row =>
            !row.number || row.number === ""
              ? { backgroundColor: "red" }
              : undefined
        },
        { id: "owner_name", label: localize("sms", "Ügyfél") }
      ];
    }
  };

  render() {
    const { open } = this.props;
    const { items, loading } = this.state;

    return (
      <Modal
        title={localize("sms", "Címzettek listája")}
        yesText="Ok"
        disableCancel
        open={open}
        loading={loading}
        onCancel={this.onCancel}
        onClose={this.onClose}
        onAccept={this.onAccept}
        Icon={ListIcon}
        //YesIcon={SendIcon}
        maxWidth="md"
      >
        <StaticTable rows={items} columns={this.columns()} />
      </Modal>
    );
  }
}

export default OutgoingAddressesModal;
