import React, { PureComponent, Fragment } from "react";
import qs from "qs";
import _ from "lodash";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toastr } from "helpers/CustomToastr";
import Table from "../Table/Table";
import ListHeader from "../ListHeader/ListHeader";
import CardBox from "../CardBox";

import Modal from "Components/Modal/Modal";
import Settings from "./Settings";
import SettingsIcon from "@material-ui/icons/Settings";
import localize from "helpers/localize";

class List extends PureComponent {
  constructor(props) {
    super(props);

    const query = qs.parse(this.props.location.search.substring(1));
    const page = _.get(query, "page", 1),
      sort = _.get(query, "sort", ""),
      search = _.get(query, "search", ""),
      filters = _.get(query, "filters", {}),
      customFilters = _.get(query, "customFilters", {});
    this.state = {
      page: page,
      sort,
      search,
      //maxPage: page !== 1 ? page : 0,
      filters: filters,
      changed: false,
      settingsModal: false,
      customFilters
    };
    props.getListHeader(
      props.getListHeaderEndpoint || "/" + props.location.pathname.split("/")[1]
    );

    this.settingsForm = React.createRef();
    this.table = React.createRef();
  }

  afterSettingsSave = settings => {
    this.props.getListHeader(
      this.props.getListHeaderEndpoint ||
        "/" + this.props.location.pathname.split("/")[1]
    );
    var context = this;
    setTimeout(() => {
      context.resetColumnWidths();
    }, 500);
  };

  submit = () => {
    const instance = this.settingsForm.current.getWrappedInstance();
    if (instance) {
      instance.submit();
    }
  };

  handleSort = (field, mode) => {
    this.setState({
      sort: (mode === "DESC" ? "-" : "") + field,
      page: 1
      //maxPage: 0
    });
  };

  handleChangePage = (event, page) => {
    this.setState({
      page: page + 1
    });
  };

  handleSearch = (search, customFilters) => {
    this.setState({
      search,
      page: 1,
      customFilters
      // maxPage: 0
    });
  };

  getList = () => {
    let searchFilter = "",
      customSearchFilters = "",
      filters = {},
      customFilters = {};
    Object.keys(this.state.filters).forEach(key => {
      if (Array.isArray(this.state.filters[key])) {
        this.state.filters[key].forEach(k => {
          if (k) {
            searchFilter += `&filters[${key}][]=${k}`;
          }
        });
      } else {
        searchFilter += `&filters[${key}]=${this.state.filters[key]}`;
      }

      try {
        filters[key] =
          JSON.parse(this.state.filters[key]).id || this.state.filters[key];
      } catch (err) {
        filters[key] = this.state.filters[key];
      }
    });

    Object.keys(this.state.customFilters).forEach(key => {
      customSearchFilters += `&customFilters[${key}]=${
        this.state.customFilters[key]
      }`;
      customFilters[key] = this.state.customFilters[key];
    });

    this.props.history.replace(
      `${this.props.location.pathname}?page=${this.state.page}&sort=${
        this.state.sort
      }&search=${this.state.search}` +
        searchFilter +
        customSearchFilters,
      null,
      false
    );
    console.log("getlist", this.props.searchModel, customFilters, filters);
    this.props.getList(
      this.state.page,
      this.state.search,
      this.state.sort,
      {
        model: this.props.searchModel,
        filters,
        customFilters
      },
      this.props.limit
    );
  };

  needFetch = prevState => {
    let st = Object.assign({}, this.state);
    delete st.settingsModal;

    let prevSt = Object.assign({}, prevState);
    delete prevSt.settingsModal;

    return !_.isEqual(st, prevSt);
  };

  componentDidMount() {
    //this.getList();
    if (this.props.listRef) {
      this.props.listRef(this);
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.headerLoaded && !prevProps.headerLoaded) {
      this.getList();
    }
    if (
      this.props.headerLoaded &&
      !prevProps.headerLoaded &&
      !this.props.failed &&
      this.needFetch(prevState)
    ) {
      console.log("1 List");
      this.getList();
    } else if (!this.props.failed && this.needFetch(prevState)) {
      console.log("2 List");
      this.getList();
    }
    // else if (!this.props.failed && this.props.page && this.state.maxPage > this.props.page) {
    //   this.handleNextPage();
    // }
    else if (
      this.props.headerLoaded &&
      this.props.success &&
      this.props.data.length === 0
    ) {
      this.props.setLoading(false);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.location.search) {
      this.setState({
        search: "",
        sort: "",
        page: 1,
        // maxPage: 0,
        filters: {},
        customFilters: {}
      });
    } else {
      const query = qs.parse(nextProps.location.search.substring(1));
      if (!_.get(query, "filters", false)) {
        this.setState({
          filters: {}
        });
      }
    }
    /*if (!this.props.message && nextProps.message) {
      toastr.error(nextProps.message);
    }*/
    if (!this.props.settingsChanged && nextProps.settingsChanged) {
      this.props.getListHeader(
        this.props.getListHeaderEndpoint ||
          "/" + this.props.location.pathname.split("/")[1]
      );
    }
  }

  componentWillUnmount = () => {
    this.props.reset();
    this.props.optionsReset();
  };

  handleColumnFilterChange = (name, value) => {
    const filter = this.state.filters;
    filter[name] = value;

    this.setState({
      filters: filter,
      page: 1,
      // maxPage: 0,
      changed: !this.state.changed
    });
  };

  handleModuleEvent = (type, id) => {
    switch (type) {
      case "create":
        this.props.history.push(this.props.location.pathname + "/create");
        break;
      case "edit":
        this.props.history.push(this.props.location.pathname + "/edit/" + id);
        break;
      case "view":
        this.props.history.push(this.props.location.pathname + "/view/" + id);
        break;
      case "delete":
        this.props.delete(id).then(response => {
          this.getList();
        });
        break;
      default:
        return null;
    }
  };

  handleCloseModal = () => {
    this.setState({
      settingsModal: false
    });
  };

  handleOpenModal = () => {
    this.setState({
      settingsModal: true
    });
  };

  /**
   * resetColumnWidths meghívása a referencia tábla komponensen
   */
  resetColumnWidths = () => {
    if (this.table.current) {
      this.table.current.resetColumnWidths();
    }
  };

  render = () => {
    return (
      <Fragment>
        <Modal
          open={this.state.settingsModal}
          title={localize("settings", "Oszlop láthatóság")}
          onClose={this.handleCloseModal}
          onAccept={this.submit}
          onCancel={this.handleCloseModal}
          keepMounted={false}
          yesText="Mentés"
          Icon={SettingsIcon}
        >
          <Settings
            {...this.props}
            cardBox={false}
            onClose={this.handleCloseModal}
            disableButtons={true}
            ref={this.settingsForm}
            afterSave={this.afterSettingsSave}
          />
        </Modal>
        <CardBox
          styleName=""
          cardStyle="p-4"
          childrenStyle="list-card-body"
          disableBottomPadding={this.props.topPagination ? true : false}
          heading={
            <ListHeader
              handleModuleEvent={this.handleModuleEvent.bind(this)}
              handleSearch={this.handleSearch.bind(this)}
              keyword={this.state.search}
              customFiltersValues={this.state.customFilters}
              showSettingsModal={this.handleOpenModal.bind(this)}
              resetColumnWidths={this.resetColumnWidths.bind(this)}
              {...this.props}
            />
          }
        >
          <Fragment>
            {this.props.success && this.props.headerLoaded && (
              <Table
                ref={this.table}
                handleModuleEvent={
                  this.props.handleModuleEvent
                    ? this.props.handleModuleEvent.bind(this)
                    : this.handleModuleEvent.bind(this)
                }
                handleColumnFilterChange={this.handleColumnFilterChange.bind(
                  this
                )}
                handleSort={this.handleSort.bind(this)}
                handleChangePage={this.handleChangePage.bind(this)}
                filterValues={this.state.filters}
                {...this.props}
              />
            )}
            {this.props.loading && (
              <div className="text-center mt-2">
                <CircularProgress />
              </div>
            )}
            {!this.props.headerLoaded && (
              <div className="text-center mt-2">
                <CircularProgress />
              </div>
            )}
          </Fragment>
        </CardBox>
      </Fragment>
    );
  };
}

const mapStateToProps = state => {
  return {
    settingsChanged: state.options.saved_success
  };
};

export default connect(
  mapStateToProps,
  null,
  null,
  { withRef: true, forwardRef: true }
)(List);
