import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditserviceOrder from "./views/EditserviceOrder";
import * as serviceOrdersActions from "../../../redux/modules/service-orders";

const mapStateToProps = state => {
  return {
    loading: state.serviceOrders.loading,
    success: state.serviceOrders.success,
    saved: state.serviceOrders.saved,
    message: state.serviceOrders.message,
    fieldErrors: state.serviceOrders.formErrors,
    id: state.serviceOrders.id,
    viewData: state.serviceOrders.viewData,
    reducerName: "serviceOrders",

    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: serviceOrdersActions.createOrder,
      update: serviceOrdersActions.updateOrder,
      view: serviceOrdersActions.viewOrder,
      reset: serviceOrdersActions.reset,
      resetForm: serviceOrdersActions.resetForm,
      getRule: serviceOrdersActions.getRule
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)(EditserviceOrder);
