import React, { PureComponent, Fragment } from "react";

import { get } from "lodash";
import Validations from "Components/Forms/Validations";
import { Field } from "redux-form";
import InputField from "Components/Forms/Fields/Input";
import SelectField from "Components/Forms/Fields/Select";
import Button from "@material-ui/core/Button/Button";
import SimpleFileInput from "Components/Forms/Fields/SimpleFile";
import localize from "helpers/localize";

class Item extends PureComponent {
  handleFileChange = (remove, file) => {
    const { dispatch, change, question } = this.props;
    const file_id = get(file, "id", "");
    dispatch(change(`${question}.image_id`, remove ? null : file_id));
  };

  render() {
    const { isViewPage, fieldError, index, question } = this.props;
    const solutions = [
      { id: "0", label: localize("exams", "Első válasz") },
      { id: "1", label: localize("exams", "Második válasz") },
      { id: "2", label: localize("exams", "Harmadik válasz") },
      { id: "3", label: localize("exams", "Negyedik válasz") },
      { id: "4", label: localize("exams", "Ötödik válasz") }
    ];

    return (
      <Fragment>
        <div className="col-12">
          <hr />
          <Field
            name={`${this.props.question}.title`}
            label={localize("exams", "Kérdés")}
            isRequired={true}
            validate={[Validations.required]}
            fieldError={fieldError.title}
            component={InputField}
            placeholder={localize("exams", "Kérdés")}
            isViewPage={isViewPage}
          />
        </div>
        <div className="col-12">
          <SimpleFileInput
            name={`image_id_${index}`}
            label={localize("downloads", "Kép")}
            addedFile={this.handleFileChange.bind(this, false)}
            removeFile={this.handleFileChange.bind(this, true)}
            files={
              get(
                this.props,
                `initialValues.questions[${index}].image_id`,
                false
              )
                ? [
                  {
                      id: this.props.initialValues.questions[index].image_id,
                      url: this.props.initialValues.questions[index].image
                    }
                ]
                : []
            }
            maxFiles={1}
            isViewPage={isViewPage}
            acceptedFiles="image/*"
          />
        </div>
        <div className="col-12 col-md-6">
          <Field
            name={`${this.props.question}.answers[0]`}
            fieldError={get(fieldError, "answers[0]", false)}
            label={localize("exams", "Első válasz")}
            component={InputField}
            placeholder={localize("exams", "Első válasz")}
            isViewPage={isViewPage}
          />
        </div>
        <div className="col-12 col-md-6">
          <Field
            name={`${this.props.question}.answers[1]`}
            fieldError={get(fieldError, "answers[1]", false)}
            label={localize("exams", "Második válasz")}
            component={InputField}
            placeholder={localize("exams", "Második válasz")}
            isViewPage={isViewPage}
          />
        </div>
        <div className="col-12 col-md-6">
          <Field
            name={`${this.props.question}.answers[2]`}
            fieldError={get(fieldError, "answers[2]", false)}
            label={localize("exams", "Harmadik válasz")}
            component={InputField}
            placeholder={localize("exams", "Harmadik válasz")}
            isViewPage={isViewPage}
          />
        </div>
        <div className="col-12 col-md-6">
          <Field
            name={`${this.props.question}.answers[3]`}
            fieldError={get(fieldError, "answers[3]", false)}
            label={localize("exams", "Negyedik válasz")}
            component={InputField}
            placeholder={localize("exams", "Negyedik válasz")}
            isViewPage={isViewPage}
          />
        </div>
        <div className="col-12 col-md-6">
          <Field
            name={`${this.props.question}.answers[4]`}
            fieldError={get(fieldError, "answers[4]", false)}
            label={localize("exams", "Ötödik válasz")}
            component={InputField}
            placeholder={localize("exams", "Ötödik válasz")}
            isViewPage={isViewPage}
          />
        </div>
        <div className="col-12 col-md-6">
          <Field
            isRequired={true}
            validate={[Validations.required]}
            name={`${this.props.question}.solution`}
            label={localize("exams", "Megoldás")}
            fieldError={fieldError.solution}
            component={SelectField}
            placeholder={localize("exams", "Megoldás")}
            isViewPage={isViewPage}
            options={solutions}
          />
        </div>
        <div className="col-12 col-md-6">
          {this.props.itemsLenght !== 1 && !isViewPage && (
            <Button
              onClick={this.props.handleDelete.bind(this, index)}
              variant="contained"
              color="secondary"
            >
              {localize("exams", "Kérdés törlése")}
            </Button>
          )}
        </div>
      </Fragment>
    );
  }
}

export default Item;
