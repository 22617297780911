import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import InputField from "../../../../../Components/Forms/Fields/Input";
import CheckboxField from "../../../../../Components/Forms/Fields/Checkbox";
import Validations from "../../../../../Components/Forms/Validations";
import EditForm from "../../../../../Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import localize from "helpers/localize";

class EventTypeForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  render() {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      enabledFields
    } = this.props;

    return (
      <EditForm {...this.props}>
        <div className="col-12">
          <Field
            name="name"
            label={localize("cashregisters", "Esemény megnevezése")}
            fieldError={fieldErrors.number}
            onChange={() => handleInputChange("name")}
            isRequired={true}
            validate={[Validations.required]}
            component={InputField}
            type="text"
            placeholder={localize("cashregisters", "Esemény megnevezése")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("name") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
        <div className="col-12">
          <Field
            name="is_active"
            label={localize("cashregisters", "Aktív")}
            fieldError={fieldErrors.is_active}
            onChange={() => handleInputChange("is_active")}
            component={CheckboxField}
            placeholder={localize("cashregisters", "Aktív")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("is_active") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
      </EditForm>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "eventType",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(EventTypeForm);

export default connect(
  state => ({
    initialValues: state.eventTypes.viewData
  }),
  null
)(UsersReduxForm);
