import React, { Component } from 'react';
import {
    Input
} from 'reactstrap';

import Wrapper from './Wrapper';

class HiddenInput extends Component {

  render() {
    const { input } = this.props;
    return (
        <Wrapper {...this.props} value={input.value}>
          <Input {...input} type="hidden" />
        </Wrapper>
    );
  }
}

export default HiddenInput;