import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";


import List from "../../../../../Components/List/List";
import ContainerHeader from "../../../../../Components/ContainerHeader";
import Navbar from "../../Navbar";
import localize from "helpers/localize";

class SmsListItemsList extends PureComponent {
  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("sms", "Címlista")}</title>
        </Helmet>
        <Navbar />
        <ContainerHeader title={localize("sms", "Címlista")} />

        <List
          {...this.props}
          disableViewIcon={true}
          //disableEditIcon={true}
        />
      </div>
    );
  }
}

export default SmsListItemsList;
