import React, { Fragment } from "react";


import BannedCashregisterForm from "./SmsListItemForm";
import EditPage from "../../../../../Components/Extends/EditPage";
import Edit from "../../../../../Components/Edit/Edit";
import Navbar from "../../Navbar";
import localize from "helpers/localize";

class EditSmsListItem extends EditPage {
  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("sms", "Címlista megtekintése");
    }
    return this.props.match.params.id
      ? localize("sms", "Címlista szerkesztése")
      : localize("sms", "Címlista létrehozása");
  };

  createSavedRedirect = () => {
    this.props.history.push("/sms-list-items");
  };
  render() {
    return (
      <Fragment>
        <Navbar />
        <Edit
          getPagetitle={this.getPageTitle}
          isViewPage={this.isViewPage()}
          createSavedRedirect={this.createSavedRedirect.bind(this)}
          {...this.props}
        >
          <BannedCashregisterForm
            {...this.props}
            isViewPage={this.isViewPage()}
          />
          />
        </Edit>
      </Fragment>
    );
  }
}

export default EditSmsListItem;
