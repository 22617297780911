import React, { Fragment } from "react";
import UsersForm from "./UsersForm";
import EditPage from "Components/Extends/EditPage";
import Edit from "Components/Edit/Edit";
import Button from "@material-ui/core/Button";
import { get } from "lodash";
import Navbar from "./Navbar";
import FuncPermission from "Containers/Permissions/FuncPermission";
import Modal from "Components/Modal/Modal";
import Input from "Components/Input";
import InputField from "Components/Forms/Fields/Input";
import { changePassword } from "redux/modules/users";
import { toastr } from "helpers/CustomToastr";
import localize from "helpers/localize";

class EditUser extends EditPage {
  state = {
    changePasswordModalOpen: false,
    new_password: null,
    new_password_error: null
  };

  handleCloseChangePasswordModal = () => {
    this.setState({ changePasswordModalOpen: false });
  };

  handleChangeNewPassword = (value, fieldName) => {
    this.setState({ [fieldName]: value });
  };

  handleSaveNewPassword = () => {
    if (!this.state.new_password || this.state.new_password === "") {
      this.setState({
        new_password_error: localize("users", "A jelszó megadása kötelező")
      });
      return;
    }

    changePassword(this.props.match.params.id, this.state.new_password)
      .then(response => {
        if (response.success) {
          this.handleCloseChangePasswordModal();
          toastr.success(localize("users", "Sikeres jelszó változtatás."));
        } else {
          this.setState({ new_password_error: response.message });
        }
      })
      .catch(error => {
        this.setState({ new_password_error: error.message });
      });
  };

  handleOpenChangePasswordModal = () => {
    this.setState({
      changePasswordModalOpen: true,
      new_password: null,
      new_password_error: null
    });
  };

  getPagetitle = () => {
    if (this.isViewPage()) {
      return localize("users", "Felhasználó megtekintése");
    }
    return this.props.match.params.id
      ? localize("users", "Felhasználó szerkesztése")
      : localize("users", "Felhasználó létrehozása");
  };

  // eslint-disable-next-line react/display-name
  renderCustomButtons = () => {
    let label = "";
    if (this.props.match.params.id && get(this.props, "viewData.id", false)) {
      switch (this.props.viewData.status) {
        case "new":
          label = localize("users", "Jóváhagyás");
          break;
        case "inactivated":
          label = localize("users", "Aktiválás");
          break;
        default:
          label = localize("users", "Inaktiválás");
      }
    }
    const id = this.props.match.params.id
      ? get(this.props, "viewData.id", false)
      : false;
    return (
      <Fragment>
        {this.props.match.params.id && (
          <FuncPermission module="users" permission="users.password-set">
            <Button
              //variant="contained"
              color="primary"
              onClick={this.handleOpenChangePasswordModal}
            >
              {localize("users", "Jelszó változtatás")}
            </Button>
          </FuncPermission>
        )}
        {id ? (
          <Button
            //variant="contained"
            color="primary"
            onClick={this.handleApproval.bind(this)}
          >
            {label}
          </Button>
        ) : (
          ""
        )}
      </Fragment>
    );
  };

  handleApproval = () => {
    this.props.userEditApproval(this.props.match.params.id);
  };

  handleGetFormInfo = () => {
    return this.props.getFormInfo("users", `&section=${this.props.mode}`);
  };

  createSavedRedirect = id => {
    let path = "";
    switch (this.props.mode) {
      case "new":
        path = "new-users";
        break;
      case "inactivated":
        path = "inactivated-users";
        break;
      default:
        path = "users";
    }
    this.props.history.push(`/${path}/edit/${id}`);
  };

  render() {
    return (
      <Fragment>
        <Navbar />
        <Edit
          disableSettings={true}
          getPagetitle={this.getPagetitle}
          isViewPage={this.isViewPage()}
          handleGetFormInfo={this.handleGetFormInfo.bind(this)}
          createSavedRedirect={this.createSavedRedirect.bind(this)}
          {...this.props}
        >
          <UsersForm
            {...this.props}
            isViewPage={this.isViewPage()}
            renderCustomButtons={this.renderCustomButtons.bind(this)}
          />
        </Edit>
        <Modal
          title={localize("users", "Jelszó változtatás")}
          open={this.state.changePasswordModalOpen}
          onClose={this.handleCloseChangePasswordModal}
          keepMounted={false}
          onAccept={this.handleSaveNewPassword}
          onCancel={this.handleCloseChangePasswordModal}
          disableIcon
        >
          <Input
            name="new_password"
            label={localize("users", "Jelszó")}
            value={this.state.new_password}
            component={InputField}
            onChange={this.handleChangeNewPassword}
            error={this.state.new_password_error}
          />
        </Modal>
      </Fragment>
    );
  }
}

export default EditUser;
