import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import DownloadIcon from "@material-ui/icons/Description";
import IconButton from "@material-ui/core/IconButton";
import downloadButtonStyle from "styles/jss/downloadButtonStyle";
import classNames from "classnames";
class DownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("access_token").replace("Bearer ", "")
    };
  }

  /**
   * Letöltés url előállítása
   */
  getUrl = () => {
    const { fileId } = this.props;
    const { token } = this.state;
    return `${
      process.env.REACT_APP_HOST
    }/v1/files/download?id=${fileId}&accessToken=${token}`;
  };

  render() {
    const {
      classes,
      tooltip,
      Icon,
      buttonProps,
      iconProps,
      color
    } = this.props;

    //Az "a" komponens miatt kell a külön szín trükk, mert sajna mindig link színű volt az ikon (kék)
    const buttonClassName = classNames({
      [classes.colorPrimary]: color === "primary",
      [classes.colorSecondary]: color === "secondary",
      [classes.colorDefault]: color === "default"
    });

    return (
      <Tooltip disableFocusListener title={tooltip}>
        <IconButton
          color={"primary"}
          href={this.getUrl()}
          target="_blank"
          component="a"
          className={buttonClassName}
          {...buttonProps}
        >
          <Icon {...iconProps} />
        </IconButton>
      </Tooltip>
    );
  }
}

DownloadButton.defaultProps = {
  tooltip: "Letöltés",
  color: "default",
  Icon: DownloadIcon,
  buttonProps: {},
  iconProps: {}
};

DownloadButton.propTypes = {
  classes: PropTypes.object.isRequired,
  fileId: PropTypes.string, //A fájlvégpontnak beküldendő file_id
  tooltip: PropTypes.string, //A tooltip szövege, alapértelmezetten Letöltés
  color: PropTypes.oneOf(["primary", "secondary", "default"]), //Az ikon színe
  Icon: PropTypes.func, //Icon komponens
  buttonProps: PropTypes.object, //Button property-k
  iconProps: PropTypes.object //Icon property-k
};

export default withStyles(downloadButtonStyle)(DownloadButton);
