import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
import { get } from "lodash";

const errorResponseHandler = error => {
  // if has response show the error
  if (error.response) {
    if (error.response.status === 422 || error.response.status === 403) {
      if (
        error.response.data &&
        (error.response.data.message || error.response.data.form_errors)
      ) {
        globalMessage.validateError(
          error.response.data.message,
          error.response.data.form_errors
        );
      } else {
        const message = get(
          error,
          "response.data.message",
          "Egy belső szerver hiba történt."
        );
        globalMessage.fatalError(message);
      }
    } else if (
      error.response.status === 400 &&
      error.response.data &&
      error.response.data.message
    ) {
      globalMessage.fatalError(error.response.data.message);
    } else if (error.response.status !== 422 && error.response.status !== 401) {
      const message = get(
        error,
        "response.data.message",
        "Egy belső szerver hiba történt."
      );
      globalMessage.fatalError(message);
    }
  }

  return Promise.reject(error);
};

export default errorResponseHandler;
