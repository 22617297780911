import React from "react";
import AsyncCreatable from "react-select/async-creatable";
import Creatable from "react-select/creatable";
import ReactSelect, { components } from "react-select";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import restHelper from "helpers/restHelper";
import localize from "helpers/localize";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import { FormHelperText, Tooltip } from "@material-ui/core";
import { tableRowsWithHeading } from "@devexpress/dx-grid-core/dist/dx-grid-core.umd";

const styles = theme => ({
  label: { marginTop: -1 },
  formControl: {
    paddingTop: theme.spacing.unit * 2
  },
  formControlNoLabel: {
    paddingTop: 0
  }
});

const customStyles = theme => {
  return {
    option: (provided, state) => ({
      ...provided
      //borderBottom: "1px dotted pink",
      //color: state.isSelected ? "red" : "blue"
      //backgroundColor: state.isSelected ? "red" : "blue"
      //padding: 20
    }),
    menu: (provided, state) => ({
      ...provided,
      //borderBottom: "1px dotted pink",
      //color: state.isSelected ? "red" : "blue"
      //backgroundColor: state.isSelected ? "red" : "blue"
      zIndex: 2000
    }),
    control: (provided, state) => {
      let s = {};

      if (state.isFocused) {
        s.borderColor = theme.palette.primary.main;
        s.boxShadow = `${theme.palette.primary.main} 0 0 0 1px`;
        s["&:hover"] = {
          boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
          borderColor: theme.palette.primary.main
        };
      }

      if (state.selectProps.error) {
        s.borderColor = theme.palette.error.main;
        if (state.isFocused) {
          s.boxShadow = `${theme.palette.error.main} 0 0 0 1px`;
        }
        s["&:hover"] = {
          //boxShadow: `${theme.palette.error.main} 0 0 0 1px`,
          borderColor: theme.palette.error.main
        };
      }

      return {
        // none of react-select's styles are passed to <Control />
        ...provided,
        minHeight: "auto",
        ...s
      };
    },
    dropdownIndicator: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      paddingTop: 0,
      paddingBottom: 0
    }),
    clearIndicator: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      paddingTop: 0,
      paddingBottom: 0
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    }
  };
};

const createOption = label => ({
  label,
  value: label
});

const createableComps = {
  DropdownIndicator: null
};

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.transofrmValue(props.value),
      inputValue: ""
    };
  }

  componentDidMount() {
    //this.loadSelectedOption();
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.value !== JSON.stringify(nextProps.value))) {
      /*console.log(
        "componentWillReceiveProps.valueChanged",
        nextProps.value,
        this.transofrmValue(nextProps.value)
      );*/
      this.setState({ value: this.transofrmValue(nextProps.value) });
    }
  }

  transofrmValue = value => {
    const { valueKey, labelKey } = this.props;
    if (!value) {
      return [];
    }
    if (Array.isArray(value)) {
      return value.map(option => {
        return typeof option === "string"
          ? { [labelKey]: option, [valueKey]: option }
          : option;
      });
    } else if (typeof value === "object") {
      return Object.keys(value).map(key => {
        return { [labelKey]: value[key], [valueKey]: key };
      });
    }
    return [];
  };
  loadOptions = (inputValue, callback) => {
    const {
      restHelper,
      restHelperOptions,
      valueKey,
      url,
      labelKey,
      modelClass,
      requestParams,
      value,
      selectIfOne
    } = this.props;
    //Egyedi ajax függvény megadása
    if (this.props.loadOptions) {
      this.props.loadOptions(inputValue, callback);
      return;
    }

    const params = {
      //page: 1,
      //["per-page"]: 20,
      //sort: `${labelKey}`,
      //[`${modelClass}[${labelKey}]`]: inputValue
      keyword: inputValue || "a",
      //[valueKey]: value,
      ...requestParams
    };

    restHelper.index(url, params, restHelperOptions).then(response => {
      const data = Array.isArray(response.data) ? response.data : [];
      const options = data.map(item => {
        return typeof item === "string"
          ? { [labelKey]: item, [valueKey]: item }
          : item;
      });
      //console.log("loadOptions", options);
      callback(options); //TODO sima response.data, ha szerver találat nélkül nem {}-ot ad vissza, hanem null vagy []

      /*if (value && response.data && response.data.length > 0) {
        this.setState({
          value: options
        });
      }*/
    });
  };

  getOptionLabel = option => {
    const { labelKey, getOptionLabel } = this.props;
    return getOptionLabel ? getOptionLabel(option) : option[labelKey];
  };
  getOptionValue = option => {
    const { valueKey, getOptionValue } = this.props;
    return getOptionValue ? getOptionValue(option) : option[valueKey];
  };

  onChange = (option, evt) => {
    const { onChange, valueKey, labelKey } = this.props;
    this.setState({
      value: option
    });
    const value = option
      ? option.map(item => {
          return item[valueKey];
        })
      : null;
    //console.log("onCahnge", option, value, evt);
    onChange && onChange(value, option, valueKey, labelKey, evt);
  };

  getNewOptionData = (inputValue, optionLabel) => {
    const { valueKey, labelKey } = this.props;
    return { [labelKey]: optionLabel, [valueKey]: inputValue };
  };

  //Simple tags input functions start

  handleInputChange = inputValue => {
    this.setState({ inputValue });
  };

  validateNewValue = value => {
    const { validateNewValue } = this.props;
    return validateNewValue ? validateNewValue(value) : true;
  };

  handleKeyDown = event => {
    const { onChange, valueKey } = this.props;
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    if (event.key === "Enter" || event.key === "Tab") {
      //console.log(value, inputValue, valueKey);
      if (!this.validateNewValue(inputValue)) {
        return;
      }
      const duplicate = value
        ? value.filter(o => o[valueKey] === inputValue)
        : [];
      if (duplicate.length > 0) {
        return;
      }
      const options = [...value, createOption(inputValue)];
      this.setState({
        inputValue: "",
        value: options
      });
      const tags = options
        ? options.map(item => {
          return item[valueKey];
          })
        : null;
      onChange && onChange(tags, event);
      event.preventDefault();
    }
  };
  //Simple tags input functions end
  render() {
    /* eslint-disable no-unused-vars */
    const {
      classes,
      onChange,
      url,
      valueMap,
      labelKey,
      valueKey,
      modelClass,
      localizationCategory,
      getOptionLabel,
      getOptionValue,
      isClearable,
      fullWidth,
      id,
      value,
      label,
      type,
      options,
      error,
      helperText,
      theme,
      onMultiLabelClick,
      ...rest
    } = this.props;
    const { value: option, inputValue } = this.state;
    /* eslint-enable no-unused-vars */
    //console.log("value", option);

    const MultiValueLabel = props => {
      return (
        <div
          onClick={evt => {
            if (onMultiLabelClick) {
              evt.stopPropagation();
              evt.preventDefault();
              onMultiLabelClick(evt, props);
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <components.MultiValueLabel {...props} />
        </div>
      );
    };
    const renderInput = () => {
      switch (type) {
        case "autocomplete":
          return (
            <AsyncCreatable
              cacheOptions
              loadOptions={this.loadOptions}
              //defaultOptions
              isMulti
              //onInputChange={this.handleInputChange}
              isClearable={isClearable}
              onChange={this.onChange}
              labelKey="text"
              valueKey="id"
              getOptionLabel={this.getOptionLabel}
              getOptionValue={this.getOptionValue}
              loadingMessage={() => localize("select", "Betöltés")}
              noOptionsMessage={() => localize("select", "Nincs eredmény")}
              formatCreateLabel={inputValue =>
                `${localize("select", "Létrehozás")}: ${inputValue}`
              }
              placeholder={localize("select", "Válasszon...")}
              getNewOptionData={this.getNewOptionData}
              styles={customStyles(theme)}
              error={error ? true : false}
              value={option}
              {...rest}
            />
          );
        case "tagcomplete":
          return (
            <AsyncSelect
              cacheOptions
              components={onMultiLabelClick ? { MultiValueLabel } : {}}
              loadOptions={this.loadOptions}
              //defaultOptions
              isMulti
              //onInputChange={this.handleInputChange}
              isClearable={isClearable}
              onChange={this.onChange}
              labelKey="text"
              valueKey="id"
              getOptionLabel={this.getOptionLabel}
              getOptionValue={this.getOptionValue}
              loadingMessage={() => localize("select", "Betöltés")}
              noOptionsMessage={() => localize("select", "Nincs eredmény")}
              formatCreateLabel={inputValue =>
                `${localize("select", "Létrehozás")}: ${inputValue}`
              }
              placeholder={localize("select", "Válasszon...")}
              getNewOptionData={this.getNewOptionData}
              styles={customStyles(theme)}
              error={error ? true : false}
              value={option}
              {...rest}
            />
          );
        default:
          return (
            <Creatable
              cacheOptions
              //loadOptions={this.loadOptions}
              //defaultOptions
              isMulti
              menuIsOpen={false}
              //onInputChange={this.handleInputChange}
              components={createableComps}
              inputValue={inputValue}
              isClearable={isClearable}
              onChange={this.onChange}
              onInputChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown}
              placeholder={localize("select", "Gépeljen és nyomjon Entert...")}
              //labelKey="text"
              //valueKey="id"
              //getOptionLabel={this.getOptionLabel}
              //getOptionValue={this.getOptionValue}
              //loadingMessage={() => localize("select", "Betöltés")}
              //noOptionsMessage={() => localize("select", "Nincs eredmény")}
              /*formatCreateLabel={inputValue =>
                `${localize("select", "Létrehozás")}: ${inputValue}`
              }*/
              //placeholder={localize("select", "Válasszon...")}
              //getNewOptionData={this.getNewOptionData}
              styles={customStyles(theme)}
              error={error ? true : false}
              value={option}
              {...rest}
            />
          );
      }
    };

    return (
      <FormControl
        fullWidth={fullWidth}
        className={label ? classes.formControl : classes.formControlNoLabel}
        error={error ? true : false}
      >
        <InputLabel
          shrink
          htmlFor={id}
          className={classes.label}
          error={error ? true : false}
        >
          {label}
        </InputLabel>
        {renderInput()}
        {(error || helperText) && (
          <FormHelperText error={error ? true : false}>
            {error || helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

Tags.defaultProps = {
  id: "select-field",
  valueKey: "id",
  labelKey: "text",
  localizationCategory: "select",
  isClearable: true,
  type: "dropdown",
  restHelper: restHelper
};

Tags.propTypes = {
  onChange: PropTypes.func,
  theme: PropTypes.object,
  url: PropTypes.string,
  valueMap: PropTypes.func,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  modelClass: PropTypes.string,
  localizationCategory: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  isClearable: PropTypes.bool,
  type: PropTypes.oneOf(["dropdown", "autocomplete", "tagcomplete", "tag"]),
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  requestParams: PropTypes.object,
  restHelper: PropTypes.object,
  restHelperOptions: PropTypes.object,
  selectIfOne: PropTypes.bool, //Amennyiben true, akkor ha egy elemű lista jön le kiválasztja az elsőt
  loadOptions: PropTypes.func, //Egyedi ajax megadása loadOptions(inputValue, callback) ahol a callback benő paramétere a lista: callback(response.data)
  validateNewValue: PropTypes.func //Új érték validáció cimkelétrehozás esetén
};

export default withStyles(styles, { withTheme: true })(Tags);
