import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import { Toolbar } from "@material-ui/core";
import Price from "./../Catalog/Price";
import ChangeCartItem from "./ChangeCartItem";
import StockInfoList from "./../Catalog/StockInfoList";
import { Link } from "react-router-dom";
import Image from "Components/New/Image";
import Tags from "./../Catalog/Tags";
import ProductLink from "./../Catalog/ProductLink";
import { red } from "@material-ui/core/colors";
import formatHelper from "helpers/formatHelper";

const styles = theme => ({
  paper: {
    paddingRight: theme.spacing.unit * 2,
    position: "relative"
  },
  img: {
    height: 90,
    marginRight: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2
  },
  texts: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  addToCartButton: {},
  price: {
    marginLeft: "auto",
    textAlign: "right"
  },
  tags: {},
  avatar: {
    backgroundColor: red[500],
    marginRight: theme.spacing.unit * 2
  }
});

class CartItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  gotoDetails = () => {};

  render() {
    const { classes, item } = this.props;
    const url = `${process.env.REACT_APP_HOST}/v1/files/download/${
      item.image_id
    }?accessToken=${localStorage
      .getItem("access_token")
      .replace("Bearer ", "")}`;
    return (
      <Paper className={classes.paper} onClick={this.gotoDetails}>
        <Toolbar disableGutters>
          <StockInfoList
            stockInfo={item.product.stock_info}
            stockInfoText={item.product.stock_info_text}
          />
          <ProductLink id={item.id}>
            <Image
              onContextMenu={e => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
              className={classes.img}
              fileId={item.product.image_id}
            />
          </ProductLink>
          {/*<Avatar aria-label="recipe" className={classes.avatar}>
            %
    </Avatar>*/}
          <div className={classes.texts}>
            <ProductLink id={item.product.id}>
              <Typography variant="subtitle1">{item.product.name}</Typography>
              {/*<Typography variant="body1">{item.product.sku}</Typography>*/}
            </ProductLink>
            <Tags rootClassName={classes.tags} tags={item.product.tags} />
          </div>

          <Typography variant="h6" className={classes.price}>
            {formatHelper.currency(item.price * item.quantity)} + Áfa
          </Typography>
          <ChangeCartItem qty={item.quantity} id={item.id} />
        </Toolbar>
      </Paper>
    );
  }
}

CartItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired
};

export default withStyles(styles)(CartItem);
