/* eslint-disable */
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import { ColorIndicator } from "Components/Forms/Fields/Select";
import HistoryBox from "Components/HistoryBox/HistoryBox";
import worksheet_colors from "Containers/Pages/Worksheets/Worksheets/colors";
import formatHelper from "helpers/formatHelper";
import localize from "helpers/localize";
import vatHelper from "helpers/vatHelper";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment, PureComponent } from "react";
import { Link as RouterLink } from "react-router-dom";
import SignatureImage from "./SignatureImage";

class WorksheetDetails extends PureComponent {
  concatAddress = prefix => {
    const { worksheet } = this.props;
    return (
      (worksheet[`${prefix}_zip`] ? " " + worksheet[`${prefix}_zip`] : "") +
      (worksheet[`${prefix}_city`] ? " " + worksheet[`${prefix}_city`] : "") +
      (worksheet[`${prefix}_street`]
        ? " " + worksheet[`${prefix}_street`]
        : "") +
      (worksheet[`${prefix}_street_suffix`]
        ? " " + worksheet[`${prefix}_street_suffix`]
        : "") +
      (worksheet[`${prefix}_street_details`]
        ? " " + worksheet[`${prefix}_street_details`]
        : "")
    );
  };

  render() {
    const {
      worksheet,
      worksheetToken,
      showSecondSection,
      onPostEditSecondSection,
      onPostEditFirstSection,
      forceShowSecondSection,
      isAdmin,
      events
    } = this.props;

    //Szummák
    let sumWorkfeesPrice = 0;
    worksheet.workfees &&
      worksheet.workfees.forEach(element => {
        sumWorkfeesPrice += vatHelper.nettoPrice(
          element.price,
          element.quantity
        );
      });

    let sumShopProductsPrice = 0;
    worksheet.shop_products &&
      worksheet.shop_products.forEach(element => {
        sumShopProductsPrice += vatHelper.nettoPrice(
          element.price,
          element.quantity
        );
      });
    const sumPrice = sumWorkfeesPrice + sumShopProductsPrice;
    const tdStyle = {
      paddingLeft: 16
    };
    const tdStylePrice = {
      paddingLeft: 16,
      textAlign: "right"
    };
    const trStyleSum = {
      borderTop: "solid 1px black",
      fontWeight: "bold"
    };
    const sumStyle = {
      textAlign: "right"
    };
    const ViewField = props => {
      return (
        <Grid item xs={12} sm={6} md={3}>
          <label>{localize("worksheets", props.label)}</label>{" "}
          {worksheet[props.name]}
        </Grid>
      );
    };
    const TableField = props => {
      if (!worksheet[props.name]) {
        return null;
      }
      return (
        <tr style={props.rowStyle ? props.rowStyle : {}}>
          <td>
            <label>
              <b>{localize("worksheets", props.label)}:</b>
            </label>
          </td>
          <td style={tdStyle}>
            {Array.isArray(worksheet[props.name])
              ? worksheet[props.name].join(", ")
              : worksheet[props.name]}
          </td>
        </tr>
      );
    };
    const TableFieldPrice = props => {
      if (!worksheet[props.name]) {
        return null;
      }
      return (
        <tr style={props.rowStyle ? props.rowStyle : {}}>
          <td>
            <label>
              <b>{localize("worksheets", props.label)}:</b>
            </label>
          </td>
          <td style={tdStyle}>
            {formatHelper.currency(worksheet[props.name])}
          </td>
        </tr>
      );
    };
    const TableColorField = props => {
      if (!worksheet[props.name]) {
        return null;
      }
      return (
        <tr>
          <td>
            <label>
              <b>{localize("worksheets", props.label)}:</b>
            </label>
          </td>
          <td style={tdStyle}>
            <ColorIndicator color={worksheet_colors[worksheet[props.name]]} />
            {worksheet[`${props.name}_text`]}
          </td>
        </tr>
      );
    };

    const TableFieldLink = props => {
      if (!worksheet[props.name]) {
        return null;
      }
      return (
        <tr>
          <td>
            <label>
              <b>{localize("worksheets", props.label)}:</b>
            </label>
          </td>
          <td style={tdStyle}>
            <RouterLink to={props.to}>
              {Array.isArray(worksheet[props.name])
                ? worksheet[props.name].join(", ")
                : worksheet[props.name]}
            </RouterLink>
          </td>
        </tr>
      );
    };
    const TableAddressField = props => {
      const address = this.concatAddress(props.name);

      if (!address || address.trim() === "") {
        return null;
      }
      return (
        <tr>
          <td>
            <label>
              <b>{localize("worksheets", props.label)}:</b>
            </label>
          </td>
          <td style={tdStyle}>{address}</td>
        </tr>
      );
    };
    const TableDateField = props => {
      if (!worksheet[props.name]) {
        return null;
      }
      return (
        <tr>
          <td>
            <label>
              <b>{localize("worksheets", props.label)}:</b>
            </label>
          </td>
          <td style={tdStyle}>
            {moment(worksheet[props.name]).format("YYYY.MM.DD")}
          </td>
        </tr>
      );
    };
    const TableBoolField = props => {
      return (
        <tr>
          <td>
            <label>
              <b>{localize("worksheets", props.label)}:</b>
            </label>
          </td>
          <td style={tdStyle}>
            {worksheet[props.name]
              ? localize("basic", "Igen")
              : localize("basic", "Nem")}
          </td>
        </tr>
      );
    };

    const TableBoolListField = props => {
      const list = props.list
        .filter(item => worksheet[item.name])
        .map(item => localize("worksheets", item.label));
      if (list.length === 0) {
        return null;
      }
      return (
        <tr>
          <td>
            <label>
              <b>{localize("worksheets", props.label)}:</b>
            </label>
          </td>
          <td style={tdStyle}>{list.join(", ")}</td>
        </tr>
      );
    };

    const List = props => {
      return (
        <table>
          <thead>
            <tr>
              {props.columns.map((column, colIndex) => {
                return (
                  <th style={colIndex > 0 ? tdStyle : null} key={colIndex}>
                    <b>{localize("worksheets", column)}</b>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>{props.children}</tbody>
        </table>
      );
    };

    const Section = props => {
      return (
        <Fragment>
          <h6 className="form-legend">{localize("worksheets", props.title)}</h6>
          <div
            style={{
              border: "solid 1px #999",
              padding: 16,
              height: "calc(100% - 24px)",
              borderRadius: 2
            }}
          >
            {props.children}
          </div>
        </Fragment>
      );
    };
    return (
      <div style={{ marginBottom: 16 }}>
        <Grid container spacing={16} style={{ position: "relative" }}>
          {onPostEditFirstSection && (
            <Tooltip
              title={localize("worksheets", "Felvételi adatok módosítása")}
            >
              <IconButton
                onClick={onPostEditFirstSection}
                style={{ position: "absolute", right: 8, top: -16 }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Grid item xs={12} md={6} lg={isAdmin ? 4 : 6}>
            <Section title="Ügyféladatok">
              <table>
                <tbody>
                  <TableField label="Cégnév" name="client_name" />
                  <TableField label="Adószám" name="client_tax_number" />
                  <TableField
                    label="Cégjegyzékszám"
                    name="client_registration_no"
                  />
                  <TableField label="Mobiltelefonszám" name="client_phone" />
                  <TableField
                    label="Másodlagos telefonszám"
                    name="client_phone2"
                  />
                  <TableField label="Email cím" name="client_email" />
                  <TableField
                    label="Cégjegyzékszám / Nyilvántart. szám"
                    name="client_registration_no"
                  />
                  <TableField
                    label="Fizetési mód"
                    name="client_payment_method"
                  />
                  <TableField
                    label="Számlavezető bank neve"
                    name="client_bank_name"
                  />
                  <TableField
                    label="Bankszámlaszám"
                    name="client_bank_account_no"
                  />
                  <TableAddressField label="Cím" name="client" />
                  {/*<TableField label="Irányítószám" name="client_zip" />
                <TableField label="Település" name="client_city" />
      <TableField label="Cím" name="client_address" />*/}
                  <TableField label="Telephely" name="client_location_name" />
                  <TableAddressField
                    label="Telephely cím"
                    name="client_location"
                  />
                  <TableField
                    label="Telephely telefon"
                    name="client_location_phone"
                  />
                  <TableField
                    label="Telephely email"
                    name="client_location_email"
                  />
                  {!!worksheet.cashregister_id && (
                    <Fragment>
                      <TableDateField
                        label="Üzembe hely. ideje"
                        name="cashregister_dts"
                      />
                      <TableDateField
                        label="Átszemélyesítés ideje"
                        name="cashregister_reregister_last_datetime"
                      />
                      <TableDateField
                        label="Következő felülvizsgálat ideje"
                        name="cashregister_next_generalcheck_code_generation_time"
                      />
                    </Fragment>
                  )}
                </tbody>
              </table>
            </Section>
          </Grid>
          <Grid item xs={12} md={6} lg={isAdmin ? 4 : 6}>
            <Section title="Megrendelés adatai">
              <table>
                <tbody>
                  <TableField label="Munkalapszám" name="number" />
                  <TableField label="Eszköz megnevezése" name="erc" />
                  <TableField label="Gyári szám" name="serial_number" />
                  {!isAdmin ||
                    (!worksheet.cashregister_id && (
                      <TableField label="Apn" name="apn" />
                    ))}
                  {isAdmin && worksheet.cashregister_id && (
                    <TableFieldLink
                      label="Apn"
                      name="apn"
                      to={`/cashregisters/view/${worksheet.cashregister_id}`}
                    />
                  )}
                  {/*<TableField label="Bejelentett hiba" name="error" />*/}
                  {/*<TableBoolField label="Pénztárgép napló" name="machinebook" />*/}
                  {/*<TableBoolField label="Kulcs" name="key" />*/}
                  <TableFieldPrice
                    label="Javítási összeghatár"
                    name="price_limit"
                  />
                  <TableBoolListField
                    label="Átvett tartozékok"
                    list={[
                      { name: "machinebook", label: "Pénztárgép napló" },
                      { name: "adapter", label: "Adapter" },
                      { name: "spool", label: "Egyéb tartozék" },
                      { name: "no_accessories", label: "Nincs" }
                    ]}
                  />
                  <TableBoolListField
                    label="Átvett eszköz állapota"
                    list={[
                      { name: "device_undamaged", label: "Sérülés mentes" },
                      { name: "device_damaged", label: "Sérült" },
                      { name: "device_insufficient", label: "Hiányos" }
                    ]}
                  />
                  {/*<TableBoolField label="Adapter" name="adapter" />*/}
                  {/*<TableBoolField label="Egyéb tartozék" name="spool" />*/}
                  <TableField label="Munkavégzés alapja" name="payment" />
                  <TableField label="Munkavégzés típusa" name="error_type" />
                  {/*<<TableBoolField label="Sérülés mentes" name="device_undamaged" />*/}
                  {/*<<TableBoolField label="Sérült" name="device_damaged" />*/}
                  {/*<<TableBoolField label="Hiányos" name="device_insufficient" />*/}
                  <TableField label="Munkavégzés helye" name="work_type" />
                  <TableField label="Befejezés" name="delivery" />
                  <TableDateField
                    label="Szervizbe szállítás ideje"
                    name="date"
                  />
                  <TableField
                      label="Átvétel helye"
                      name="place_of_receipt"
                  />
                  <TableDateField label="Vállalt határidő" name="deadline" />
                  <TableField label="Státusz" name="status_text" />
                  {isAdmin && <TableField label="Cimkék" name="tags" />}
                  {isAdmin && (
                    <TableField
                      label="Fizetési státusz"
                      name="paid_status"
                      rowStyle={
                        worksheet.paid_status_value === "unpaid"
                          ? { backgroundColor: "red", color: "white" }
                          : undefined
                      }
                    />
                  )}
                  {isAdmin && <TableColorField label="Jelölés" name="color" />}
                </tbody>
              </table>
            </Section>
          </Grid>
          {isAdmin && (
            <Grid item xs={12} lg={4}>
              <Section title="Történet">
                <HistoryBox
                  /*url={`worksheets/events/${worksheet.id}`*/ events={events}
                />
              </Section>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6}>
            {worksheet.error && (
              <Section title="Bejelentett hiba">{worksheet.error}</Section>
            )}
          </Grid>
          {worksheet.collectors && worksheet.collectors.length !== 0 && (
            <Grid item xs={12} sm={6}>
              <Section title="Gyűjtők">
                <List columns={["Név", "Értékhatár", "Áfa"]}>
                  {worksheet.collectors.map((row, index) => (
                    <tr key={index}>
                      <td>{row.name}</td>
                      <td style={tdStyle}>{row.value_limit}</td>
                      <td style={tdStyle}>{row.tax}</td>
                    </tr>
                  ))}
                </List>
              </Section>
            </Grid>
          )}
          {isAdmin &&
            worksheet.attachments &&
            worksheet.attachments.length !== 0 && (
              <Grid item xs={12} sm={6}>
                <Section title="Dokumentumok">
                  <List columns={[localize("worksheets", "Fájlnév")]}>
                    {worksheet.attachments.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <Link
                            href={`${
                              process.env.REACT_APP_HOST
                            }/v1/files/download/${
                              row.id
                            }?accessToken=${localStorage
                              .getItem("access_token")
                              .replace("Bearer ", "")}`}
                          >
                            {row.file_name}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </List>
                </Section>
              </Grid>
            )}
        </Grid>
        {worksheet.signature_order && (
          <Grid item xs={12}>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={6} md={3}>
                <h6 className="form-legend">
                  {localize("worksheets", "Megrendelési aláírás")}
                </h6>
                <SignatureImage
                  worksheetToken={worksheetToken}
                  phase="order"
                  style={{
                    width: "100%",
                    marginBottom: 16,
                    border: "solid 1px #999",
                    padding: 16,
                    borderRadius: 2
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {((["DONE", "CLOSED"].indexOf(worksheet.status) >= 0 &&
          showSecondSection) ||
          forceShowSecondSection) && (
          <Grid container spacing={16} style={{ position: "relative" }}>
            {onPostEditSecondSection && (
              <Tooltip
                title={localize("worksheets", "Munka adatok módosítása")}
              >
                <IconButton
                  onClick={onPostEditSecondSection}
                  style={{ position: "absolute", right: 8, top: -16 }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}

            {(worksheet.details_of_completion_public ||
              worksheet.details_of_completion_private) && (
              <Grid item xs={12} sm={6}>
                <Section title="Elvégzett munka leírása">
                  {worksheet.details_of_completion_public}
                  {isAdmin && worksheet.details_of_completion_private && (
                    <div style={{ position: "relative" }}>
                      <hr />
                      <span
                        style={{
                          position: "absolute",
                          fontSize: "10px",
                          color: "#aaa",
                          top: -7,
                          backgroundColor: "white",
                          paddingRight: 6
                        }}
                      >
                        BELSŐ
                      </span>
                      {worksheet.details_of_completion_private}
                    </div>
                  )}
                </Section>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <Section title="Szervizelés adatai">
                <table>
                  <tbody>
                    <TableField label="Gépállás előtte" name="counter_before" />
                    <TableField label="Gépállás utána" name="counter_after" />
                    <TableField
                      label="Pecsétnyomó előtte"
                      name="postmark_before"
                    />
                    <TableField
                      label="Pecsétnyomó utána"
                      name="postmark_after"
                    />
                    <TableDateField
                      label="Javítás dátuma"
                      name="date_of_completion"
                    />
                    <TableField label="Számlaszám" name="invoice_no" />
                    <TableField label="Szervizes" name="mechanic_id" />
                  </tbody>
                </table>
              </Section>
            </Grid>

            {/* Munkadíjak, anygfelhasználás */}
            {worksheet.workfees && worksheet.workfees.length !== 0 && (
              <Grid item xs={12} sm={6}>
                <Section title="Munkadíjak">
                  <List
                    columns={[
                      localize("worksheets", "Név"),
                      localize("worksheets", "Menny."),
                      localize("worksheets", "Típus"),
                      localize("worksheets", "Egységár"),
                      localize("worksheets", "Nettó Ár"),
                      localize("worksheets", "Bruttó Ár")
                    ]}
                  >
                    {worksheet.workfees.map((row, index) => (
                      <tr key={index}>
                        <td>{row.name}</td>
                        <td style={tdStyle}>{row.quantity}</td>
                        <td style={tdStyle}>{row.type}</td>
                        <td style={tdStylePrice}>
                          {formatHelper.currency(row.price)}
                        </td>
                        <td style={tdStylePrice}>
                          {formatHelper.currency(
                            vatHelper.nettoPrice(row.price, row.quantity)
                          )}
                        </td>
                        <td style={tdStylePrice}>
                          {formatHelper.currency(
                            vatHelper.bruttoPrice(row.price, row.quantity)
                          )}
                        </td>
                      </tr>
                    ))}
                    <tr style={trStyleSum}>
                      <td colSpan={4}>{localize("worksheets", "Összesen")}:</td>
                      <td style={tdStylePrice}>
                        {formatHelper.currency(sumWorkfeesPrice)}
                      </td>
                      <td style={tdStylePrice}>
                        {formatHelper.currency(
                          vatHelper.bruttoPrice(sumWorkfeesPrice)
                        )}
                      </td>
                    </tr>
                  </List>
                </Section>
              </Grid>
            )}
            {worksheet.shop_products && worksheet.shop_products.length !== 0 && (
              <Grid item xs={12} sm={6}>
                <Section title="Felhasznált anyagok">
                  <List
                    columns={[
                      localize("worksheets", "Név"),
                      //"Menny.egys.",
                      localize("worksheets", "Menny."),
                      localize("worksheets", "Egységár"),
                      localize("worksheets", "Nettó Ár"),
                      localize("worksheets", "Bruttó Ár")
                    ]}
                  >
                    {worksheet.shop_products.map((row, index) => (
                      <tr key={index}>
                        <td>{row.name}</td>
                        {/*<td style={tdStyle}>{row.unit}</td>*/}
                        <td style={tdStyle}>{row.quantity}</td>
                        <td style={tdStylePrice}>
                          {formatHelper.currency(row.price)}
                        </td>
                        <td style={tdStylePrice}>
                          {formatHelper.currency(
                            vatHelper.nettoPrice(row.price, row.quantity)
                          )}
                        </td>
                        <td style={tdStylePrice}>
                          {formatHelper.currency(
                            vatHelper.bruttoPrice(row.price, row.quantity)
                          )}
                        </td>
                      </tr>
                    ))}
                    <tr style={trStyleSum}>
                      <td colSpan={3}>{localize("worksheets", "Összesen")}:</td>
                      <td style={tdStylePrice}>
                        {formatHelper.currency(sumShopProductsPrice)}
                      </td>
                      <td style={tdStylePrice}>
                        {formatHelper.currency(
                          vatHelper.bruttoPrice(sumShopProductsPrice)
                        )}
                      </td>
                    </tr>
                  </List>
                </Section>
              </Grid>
            )}
            {/*sumPrice > 0 && <Grid item xs={12} >
              <Typography style={sumStyle} variant="h4">Fizetendő összeg összesen: {formatHelper.currency(vatHelper.bruttoPrice(sumPrice))}</Typography>
              </Grid>*/}
            {worksheet.signature_takeover && (
              <Grid item xs={12}>
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={6} md={3}>
                    <h6 className="form-legend">
                      {localize("worksheets", "Átvételi aláírás")}
                    </h6>
                    <SignatureImage
                      worksheetToken={worksheetToken}
                      phase="takeover"
                      style={{
                        width: "100%",
                        marginBottom: 16,
                        border: "solid 1px #999",
                        padding: 16,
                        borderRadius: 2
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    );
  }
}

WorksheetDetails.defaultProps = {
  worksheet: {},
  showSecondSection: true
};

WorksheetDetails.propTypes = {
  worksheet: PropTypes.object,
  worksheetToken: PropTypes.string,
  showSecondSection: PropTypes.bool, //Csak ebben az esetben mutatja egyáltalán a második szekciót, ilyenkor is csak akkor, ha vannak adatok hozzá
  onPostEditFirstSection: PropTypes.func, //edit gomb callback, ha meg van adva, akkor megjeleni a ceruza ikon az első szekciónál
  onPostEditSecondSection: PropTypes.func, //edit gomb callback, ha meg van adva, akkor megjeleni a ceruza ikon a második szekciónál
  isAdmin: PropTypes.bool, //Bizonyos adatok csak ekkor látszanak
  forceShowSecondSection: PropTypes.bool, //Mindenképpen mutatjuk a második részt tipikusan az isVewPage-nél kell
  events: PropTypes.array //History adatok
};

export default WorksheetDetails;
