import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import documentListStyle from "styles/jss/documentListStyle";
import classNames from "classnames";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import DownloadButton from "Components/DownloadButton";
import UploadButton from "Components/UploadButton";
import Wrapper from "./Wrapper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastr } from "../../../helpers/CustomToastr";
import { get, clone } from "lodash";
import Button from "@material-ui/core/Button";
import * as fileActions from "../../../redux/modules/file";

import { FormGroup, Label } from "reactstrap";
import { Link } from "@material-ui/core";

class DocumentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      items: []
    };
  }

  onChangeType = evt => {
    this.setState({ type: evt.target.value });
  };

  renderValue = value => {
    const { options } = this.props;
    for (let i in options) {
      const option = options[i];
      if (option.id === value) {
        return option.label;
      }
    }
    return value;
  };

  onUpload = (data, file) => {
    let items = this.state.items.slice();
    const { type } = this.state;

    const item = {
      type: type,
      id: data.id,
      file_id: data.id,
      file_name: type,
      onlyClient: true
    };
    items.unshift(item);
    this.setState({ items, type: null });
    this.props.input.onChange(items);
    return false;
  };

  onRemove = (file, data) => {
    let items = this.state.items.slice();

    for (let i in items) {
      const item = items[i];
      if (item.file_id === data.file_id) {
        items.splice(i, 1);
      }
    }

    this.setState({ items });
    this.props.input.onChange(items);
  };

  render() {
    const {
      classes,
      isViewPage,
      label,
      options,
      input: rfInputProps,
      fieldError,
      readOnly,
      isRequired,
      labelInfo,
      documents,
      disabled
    } = this.props;
    const { items, type } = this.state;
    const { warning, touched, error } = this.props.meta;
    const InputProps = {
      InputProps: {
        classes: {
          root: classes.bootstrapSelectRoot,
          input: classes.bootstrapInput
        }
      }
    };

    const input = {
      name: rfInputProps.name + "_type",
      value: rfInputProps.value
    };

    const list = items.concat(documents);

    const headerClassName = classNames({
      [classes.thead]: true,
      [classes.theadEmpty]: list.length === 0
    });

    return (
      <Table padding="none" className={classes.table}>
        <TableHead className={headerClassName}>
          {isViewPage ? (
            <TableRow className={classes.tableRow}>
              <TableCell colSpan={2}>
                <label className={classes.isViewLabel}>{label}</label>
              </TableCell>
            </TableRow>
          ) : (
            <TableRow className={classes.tableRow}>
              <TableCell>
                <TextField
                  name={`${rfInputProps.name}_type`}
                  select
                  disabled={disabled}
                  label={label}
                  value={type || ""}
                  onChange={this.onChangeType}
                  SelectProps={{
                    placeholder: "Válasszon típust",
                    displayEmpty: true,
                    renderValue: value => {
                      return value ? (
                        this.renderValue(value)
                      ) : (
                        <span className={classes.placeholder}>
                          Válasszon típust a feltöltéshez
                        </span>
                      );
                    }
                  }}
                  {...InputProps}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.selectLabel
                  }}
                  className={classes.root}
                  fullWidth
                  autoComplete={"new-" + input.name}
                  error={
                    fieldError ||
                    (touched && (warning || (error ? true : false)))
                  }
                  helperText={fieldError || (touched && (warning || error))}
                  inputProps={{
                    readOnly: readOnly
                  }}
                >
                  {options.map(option => (
                    <MenuItem
                      key={option.label}
                      value={option.id}
                      className={classes.selectOption}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell className={classes.buttonCell}>
                <UploadButton
                  name={"change-doc-new"}
                  type="upload"
                  onUpload={this.onUpload}
                  onRemove={this.onRemove}
                  buttonProps={{ className: classes.uploadButton }}
                  disabled={type === null}
                />
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {list.map(item => {
            return (
              <TableRow key={item.file_id} className={classes.tableRow}>
                <TableCell>{this.renderValue(item.type)}</TableCell>
                <TableCell className={classes.buttonCell}>
                  {item.onlyClient ? (
                    <UploadButton
                      name={`change-doc-${item.file_id}`}
                      type="upload"
                      file={item}
                      onUpload={this.onUpload}
                      onRemove={this.onRemove}
                      buttonProps={{ className: classes.removeButton }}
                    />
                  ) : (
                    <DownloadButton
                      fileId={item.file_id}
                      buttonProps={{ className: classes.downloadButton }}
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

DocumentList.defaultProps = {};

DocumentList.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired //A dokumentum típusok listája
};

export default withStyles(documentListStyle)(DocumentList);
