import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { Form } from "reactstrap";
import InputField from "../../../../../Components/Forms/Fields/Input";
import DefaultFormButtons from "../../../../../Components/Forms/Buttons/DefaultFormButtons";
import Validations from "../../../../../Components/Forms/Validations";
import connect from "react-redux/es/connect/connect";
import { mobilNumberMask } from "helpers/maskHelper";
import Typography from "@material-ui/core/Typography";
import localize from "helpers/localize";

class DistributorForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    //this.fillForm();
  };

  // fillForm = () => {
  //   const {dispatch, change, distributor} = this.props;
  //   dispatch(change('username', distributor.username));
  //   dispatch(change('real_name', distributor.real_name));
  //   dispatch(change('card_number', distributor.card_number));
  //   dispatch(change('phone', distributor.phone));
  //   dispatch(change('sealnumber_of_cashregister', distributor.sealnumber_of_cashregister));
  //   dispatch(change('client_cashregister_apn', distributor.client_cashregister_apn));
  // };

  render() {
    const {
      handleSubmit,
      handleInputChange,
      fieldErrors,
      pristine,
      submitting,
      reset
    } = this.props;
    return (
      <Form onSubmit={handleSubmit} className="form">
        <div className="form-body">
          <div className="row">
            <div className="col-12">
              <Field
                name="company_name"
                label={localize("distributors", "Szervíz neve")}
                fieldError={fieldErrors.company_name}
                onChange={() => handleInputChange("company_name")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("distributors", "Szervíz neve")}
                false={false}
                readOnly={true}
              />
              <Field
                name="registration_number"
                label={localize("distributors", "Nyilvántartási száma")}
                fieldError={fieldErrors.registration_number}
                onChange={() => handleInputChange("registration_number")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("distributors", "Nyilvántartási száma")}
                false={false}
                readOnly={true}
              />
              <Field
                name="address"
                label={localize("distributors", "Székhelye")}
                fieldError={fieldErrors.address}
                onChange={() => handleInputChange("address")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("distributors", "Székhelye")}
                false={false}
                readOnly={true}
              />
              <Field
                name="phone"
                label={localize("distributors", "Telefonszám")}
                fieldError={fieldErrors.phone}
                onChange={() => handleInputChange("phone")}
                component={InputField}
                type="text"
                placeholder={localize("distributors", "Telefonszám")}
                false={false}
                validate={[Validations.required, Validations.mobile_number]}
                {...mobilNumberMask}
              />
              <Field
                name="email"
                label={localize("distributors", "Email cím")}
                fieldError={fieldErrors.email}
                onChange={() => handleInputChange("email")}
                validate={[Validations.required, Validations.email]}
                component={InputField}
                type="text"
                placeholder={localize("distributors", "Email cím")}
                false={false}
              />
              <Field
                name="contact_person"
                label={localize("distributors", "Kapcsolattartó")}
                fieldError={fieldErrors.contact_person}
                onChange={() => handleInputChange("contact_person")}
                component={InputField}
                type="text"
                placeholder={localize("distributors", "Kapcsolattartó")}
                false={false}
              />

              {this.props.initialValues &&
                this.props.initialValues.vendor_ap_filter &&
                (this.props.initialValues.vendor_ap_filter !== "" && (
                  <Field
                    name="vendor_ap_filter"
                    label={localize("distributors", "Ap tartományok")}
                    fieldError={fieldErrors.contact_person}
                    onChange={() => handleInputChange("vendor_ap_filter")}
                    component={InputField}
                    type="text"
                    readOnly={true}
                  />
                ))}
            </div>
          </div>
        </div>

        <DefaultFormButtons
          pristine={pristine}
          submitting={submitting}
          reset={reset}
          loading={this.props.profileLoading}
        />
      </Form>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "distributor",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(DistributorForm);

export default connect(
  state => ({
    initialValues: state.auth.distributor
  }),
  null
)(UsersReduxForm);
