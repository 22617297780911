import axios from "axios";
import { get } from "lodash";

const HOME_DASHBOARD_LOAD = "HOME_DASHBOARD_LOAD";
const HOME_DASHBOARD_SUCCESS = "HOME_DASHBOARD_SUCCESS";
const HOME_DASHBOARD_FAILED = "HOME_DASHBOARD_FAILED";

const HOME_EVENTS_LOAD = "HOME_EVENTS_LOAD";
const HOME_EVENTS_SUCCESS = "HOME_EVENTS_SUCCESS";
const HOME_EVENTS_FAILED = "HOME_EVENTS_FAILED";

const HOME_RESET = "HOME_RESET";

const initialState = {
  loading: false,
  success: false,
  failed: false,
  data: {},
  message: null
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case HOME_RESET:
      return {
        ...initialState
      };

    case HOME_DASHBOARD_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null
      };
    case HOME_DASHBOARD_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        failed: true,
        message: action.message
      };
    case HOME_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        data: action.data
      };
    default:
      return state;
  }
};

const initialStateEvents = {
  loading: false,
  success: false,
  failed: false,
  data: [],
  message: null
};
const events = (state = initialStateEvents, action) => {
  switch (action.type) {
    case HOME_EVENTS_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null
      };
    case HOME_EVENTS_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        failed: true,
        message: action.message
      };
    case HOME_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        data: action.data
      };
    default:
      return initialStateEvents;
  }
};

export default {
  dashboard,
  events
};

export const reset = () => dispatch => {
  dispatch({
    type: HOME_RESET
  });
};

export const eventList = panelId => dispatch => {
  dispatch({
    type: HOME_EVENTS_LOAD
  });

  return axios
    .get("v1/dashboard/events" + (panelId ? "?panelId=" + panelId : ""))
    .then(response => {
      const data = get(response, "data.data", []);
      dispatch({
        type: HOME_EVENTS_SUCCESS,
        data: Array.isArray(data) ? data : []
      });
    })
    .catch(error =>
      dispatch({
        type: HOME_EVENTS_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};
export const homeList = () => dispatch => {
  dispatch({
    type: HOME_DASHBOARD_LOAD
  });

  return axios
    .get("/v1/dashboard")
    .then(response => {
      dispatch({
        type: HOME_DASHBOARD_SUCCESS,
        data: get(response, "data.data", null)
      });
      dispatch({
        type: "SLIDERS_FETCH_SUCCESS",
        items: get(response, "data.data.slides.data", [])
      });
    })
    .catch(error =>
      dispatch({
        type: HOME_DASHBOARD_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};
