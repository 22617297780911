import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import ProductsIcon from "@material-ui/icons/Shop";
import SettingsIcon from "@material-ui/icons/Settings";
import { merge, get } from "lodash";
import restHelper from "helpers/restHelper";
import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import { forEach } from "@firebase/util";
import ModalConfirm from "Components/Modal/ModalConfirm";
import Validations from "Components/Forms/Validations";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";

const baseState = options => {
  let state = {
    data: [],
    editItem: null,
    optionsError: null,
    error: null,
    options: {
      grid: { model: null, columns: {}, columnsOrder: [] },
      form: { model: null, baseFields: {} }
    },
    errors: {},
    loading: false
  };
  state.options = merge({}, state.options, options);
  return state;
};
class DataProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...baseState(props.options)
    };
  }

  componentDidMount() {
    const { disableOptions } = this.props;
    if (disableOptions) {
      this.fetchData().then(data => {
        console.log("DataProvider.fetchData", data);
      });
    } else {
      this.fetchOptions().then(options => {
        console.log("DataProvider.fetchOptions", options);
        this.fetchData().then(data => {
          console.log("DataProvider.fetchData", data);
          /*if (data.length > 0) {
          Object.keys(data[0]).forEach(key => {
            if (!options.grid.columns[key]) {
              options.grid.columns[key] = {};
            } else {
              //options.grid.columns[key].hidden = false;
            }
          });
          this.setState({ options });
        }*/
        });
      });
    }
  }

  fieldValue = fieldName => {
    if (this.state.editItem) {
      return this.state.editItem[fieldName];
    }
  };

  fieldError = fieldName => {
    const validators = get(
      this.state,
      `options.form.baseFields.${fieldName}.validators`
    );
    if (validators) {
      let errors = [];
      validators.forEach(element => {
        const error = Validations[element](this.fieldValue(fieldName));
        if (error) {
          errors.push(error);
        }
      });
      return errors.length > 0 ? errors : null;
    } else {
      return null;
    }
  };

  isRequiredField = fieldName => {
    const validators = get(
      this.state,
      `options.form.baseFields.${fieldName}.validators`
    );
    if (validators) {
      return validators.indexOf("required") >= 0;
    } else {
      return false;
    }
  };

  validateField = fieldName => {
    let errors = Object.assign({}, this.state.errors);
    const error = this.fieldError(fieldName);
    let valid = false;
    if (error) {
      errors[fieldName] = error;
      valid = false;
    } else {
      delete errors[fieldName];
      valid = true;
    }
    this.setState({ errors });
    return valid;
  };

  validateFields = () => {
    const fields = get(this.state, "options.form.baseFields", {});
    let valid = true;
    let errors = Object.assign({}, this.state.errors);
    Object.keys(fields).forEach(fieldName => {
      const validators = get(
        this.state,
        `options.form.baseFields.${fieldName}.validators`
      );
      const error = this.fieldError(fieldName);
      //Csak azokat a hibaüzeneteket bántjuk, ahol van validátor, a többi bizonyára szerveroldali
      if (validators) {
        if (error) {
          valid = false;
          //console.log(fieldName, "invalid");
          errors[fieldName] = error;
        } else {
          delete errors[fieldName];
        }
      }
    });
    this.setState({ errors });
    console.log(valid);
    return valid;
  };

  fetchData = params => {
    const {
      url,
      perPage,
      restHelper,
      restHelperOptions,
      queryParams
    } = this.props;
    const mergedParams = Object.assign(
      {},
      { "per-page": perPage },
      queryParams,
      params
    );
    this.setState({ loading: true });
    return restHelper
      .index(url, mergedParams, restHelperOptions)
      .then(response => {
        this.setState({
          data: Array.isArray(response.data) ? response.data : [], // TODO: Sor csere, ha kész a backend -> data: response.data,
          error: null,
          loading: false
        });
        return response.data;
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  fetchOptions = () => {
    const {
      url: urlRaw,
      restHelper,
      restHelperOptions,
      optionsUrl,
      options: optionsProp
    } = this.props;
    let urlArray = urlRaw.split("?");
    let url = optionsUrl ? optionsUrl : `${urlRaw}/options`;
    if (urlArray.length > 1) {
      url = `${urlArray[0]}/options?${urlArray[1]}`;
    }

    return restHelper
      .index(url, null, restHelperOptions)
      .then(response => {
        //console.log(response.data);
        const options = merge(
          {},
          baseState(optionsProp).options,
          response.data
        );
        this.setState({ options, optionsError: null });
        return options;
      })
      .catch(error => {
        this.setState({ optionsError: error });
      });
  };

  showGlobalValidationError = () => {
    globalMessage.error(
      "Hiányos vagy rosszul kitöltött mezők vannak az űrlapon."
    );
  };

  onSave = () => {
    const {
      url,
      restHelper,
      restHelperOptions,
      goBackAfterSave,
      refreshAfterSave
    } = this.props;
    const { editItem } = this.state;
    const { model } = this.state.options.form;

    //Kliens oldali validáció
    if (!this.validateFields()) {
      this.showGlobalValidationError();
      return;
    }

    console.log("save", editItem);
    if (editItem.id) {
      restHelper
        .update(url, model, editItem.id, editItem, restHelperOptions)
        .then(response => {
          console.log("updated", response);
          const newItem = Object.assign({}, editItem, response.data);
          if (this.props.afterSave) {
            this.props.afterSave(newItem, false);
          }
          this.setState(
            {
              editItem: goBackAfterSave ? null : newItem,
              ...(refreshAfterSave
                ? {}
                : { data: this.updateDataItem(newItem) }),
              errors: {}
            },
            () => {
              if (refreshAfterSave) {
                this.fetchData();
              }
            }
          );
        })
        .catch(error => {
          if (
            error &&
            error.form_errors &&
            typeof error.form_errors === "object"
          ) {
            this.showGlobalValidationError();
            this.setState({ errors: error.form_errors });
          }
        });
    } else {
      restHelper
        .create(url, model, editItem, restHelperOptions)
        .then(response => {
          console.log("created", response);
          const newItem = Object.assign({}, editItem, response.data);
          let data = this.state.data.slice();
          data.push(newItem);
          if (this.props.afterSave) {
            this.props.afterSave(newItem, true);
          }
          this.setState(
            {
              editItem: goBackAfterSave ? null : newItem,
              ...(refreshAfterSave ? {} : { data }),
              errors: {}
            },
            () => {
              if (refreshAfterSave) {
                this.fetchData();
              }
            }
          );
        })
        .catch(error => {
          if (
            error &&
            error.form_errors &&
            typeof error.form_errors === "object"
          ) {
            this.showGlobalValidationError();
            this.setState({ errors: error.form_errors });
          }
        });
    }
    //this.setState({ editItem: row });
  };

  updateDataItem(item) {
    const { data } = this.state;
    const updatedData = data.map(element => {
      return element.id === item.id
        ? Object.assign({}, element, item)
        : Object.assign({}, element);
    });

    return updatedData;
  }

  onEdit = (row, rowIndex) => {
    console.log(row, rowIndex);
    this.setState({ editItem: row, errors: {} });
  };
  onEndEdit = () => {
    this.setState({ editItem: null });
  };
  onCreate = () => {
    let e = {};
    Object.keys(this.props.options.form.baseFields).forEach(colId => {
      const col = this.props.options.form.baseFields[colId];
      if (col.hasOwnProperty("defaultValue")) {
        e[colId] = col.defaultValue;
      }
    });
    this.setState({ editItem: e });
  };
  onChange = (key, value) => {
    let editItem = Object.assign({}, this.state.editItem);
    editItem[key] = value;
    this.setState({ editItem });
  };

  onView = (row, rowIndex) => {
    console.log("onView", row, rowIndex);
  };
  onDelete = (row, rowIndex) => {
    const { url, restHelper, restHelperOptions, customDelete } = this.props;
    const { model } = this.state.options.form;

    console.log(row, rowIndex);

    this.modal
      .open(
        "Törlés megerősítése",
        "Biztosan törli a sort?",
        null,
        "Igen",
        "Mégsem"
      )
      .then(res => {
        this.modal.loading(true);
        if (customDelete) {
          customDelete(row, restHelperOptions)
            .then(response => {
              this.modal.close();
              let data = this.state.data.slice();
              data.splice(rowIndex, 1);
              this.setState({ data });
            })
            .finally(() => {
              this.modal.loading(false);
              this.modal.close();
            });
        } else {
          restHelper
            .remove(url, row.id, restHelperOptions)
            .then(response => {
              let data = this.state.data.slice();
              data.splice(rowIndex, 1);
              this.setState({ data });
            })
            .finally(() => {
              this.modal.close();
              this.modal.loading(false);
            });
        }
      });
  };

  render() {
    const {
      component: Component,
      url,
      options,
      customView,
      ...componentProps
    } = this.props;
    if (!Component) {
      return "DataProvider has no component";
    }
    return (
      <Fragment>
        <Component
          {...this.state}
          onEdit={this.onEdit}
          onDelete={this.onDelete}
          onView={customView || this.onView}
          onEndEdit={this.onEndEdit}
          onCreate={this.onCreate}
          onSave={this.onSave}
          onChange={this.onChange}
          fetchData={this.fetchData}
          validateField={this.validateField}
          isRequiredField={this.isRequiredField}
          {...componentProps}
        />
        <ModalConfirm onRef={ref => (this.modal = ref)} />
      </Fragment>
    );
  }
}

DataProvider.defaultProps = {
  perPage: 10,
  restHelper: restHelper
};

DataProvider.propTypes = {
  url: PropTypes.string.isRequired,
  component: PropTypes.any.isRequired,
  perPage: PropTypes.number,
  restHelper: PropTypes.object,
  restHelperOptions: PropTypes.object,
  queryParams: PropTypes.object,
  optionsUrl: PropTypes.string,
  options: PropTypes.object,
  goBackAfterSave: PropTypes.bool,
  refreshAfterSave: PropTypes.bool,
  onlyBasefieldsOnForm: PropTypes.bool,
  disableOptions: PropTypes.bool //Az options request kihagyása
};

export default DataProvider;
