import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AnykList from "./views/AnykList";
import * as anykActions from "../../../../redux/modules/anyk";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.anyk.loading,
    success: state.anyk.success,
    message: state.anyk.message,
    data: state.anyk.data,
    total: state.anyk.total,
    limit: state.anyk.limit,
    page: state.anyk.page,
    order: state.anyk.order,
    orderMode: state.anyk.orderMode,
    deleted: state.anyk.deleted,
    deletedId: state.anyk.deletedId,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: anykActions.anykList,
      delete: anykActions.anykDelete,
      setLoading: anykActions.setLoading,
      reset: anykActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnykList);
