import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";
import SelectField from "../../../../../Components/Forms/Fields/Select";
import Validations from "../../../../../Components/Forms/Validations";
import EditForm from "../../../../../Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import localize from "helpers/localize";
class EventForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const { dispatch, change } = props;
    dispatch(change("cashregister_id", props.cashregister_id));
  }
  render() {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      enabledFields
    } = this.props;
    let msgs = [];
    Object.keys(get(this.props, "baseFields.msg.data", {})).forEach(key => {
      msgs.push({ id: key, label: this.props.baseFields.msg.data[key] });
    });

    return (
      <EditForm {...this.props} disabledEditButton={true} disableCancel={true}>
        <div className="col-12">
          <Field
            name="msg"
            label={localize("cashregisters", "Esemény")}
            fieldError={fieldErrors.msg}
            onChange={() => handleInputChange("msg")}
            isRequired={true}
            validate={[Validations.required]}
            component={SelectField}
            placeholder={localize("cashregisters", "Esemény")}
            isViewPage={isViewPage}
            options={msgs}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("msg") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>
      </EditForm>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "events",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(EventForm);

export default connect(
  state => ({
    initialValues: state.events.viewData
  }),
  null
)(UsersReduxForm);
