import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Accounting from "./Accounting";
import restHelper from "helpers/public/restHelper";
import Loader from "Components/New/Loader";
import NavBarPublic from "Components/NavBar/NavBarPublic";
import FileUploadMulti from "Components/New/FileUploadMulti/FileUploadMulti";
import {
  FormHelperText,
  Paper,
  Typography,
  SnackbarContent,
  withStyles,
  Toolbar,
  IconButton,
  Tooltip
} from "@material-ui/core";
import localize from "helpers/localize";
import storageHelper from "helpers/storageHelper";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ViewIcon from "@material-ui/icons/Visibility";
import { green } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
import { Helmet } from "react-helmet";

const LC = "cloud"; //Localization category

const styles = theme => ({
  success: {
    backgroundColor: green[600],
    //width: `calc(100% + ${theme.spacing.unit * 8}px)`,
    maxWidth: "initial",
    /*marginTop: theme.spacing.unit * -4,
    marginLeft: theme.spacing.unit * -4,
    marginRight: theme.spacing.unit * -4,
    marginBottom: theme.spacing.unit * 1,*/
    left: 0,
    right: 0,
    top: 0,
    zIndex: 300,
    //height: 64,
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    position: "absolute"
  },
  centerContainer: {
    textAlign: "center"
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  closeIcon: {
    fontSize: 20,
    opacity: 0.9
  },
  message: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    alignContent: "top",
    fontSize: 10,
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& span.file-details": {
      display: "none"
    },
    "&:hover": {
      "& span.file-base": {
        display: "none"
      },
      "& span.file-details": {
        display: "inline"
      }
    }
  },
  paper: {
    position: "relative",
    padding: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 8,
    margin: theme.spacing.unit * 4
  },
  clearButton: {}
});

class AccountingPublic extends Component {
  constructor(props) {
    super(props);
    this.storage = storageHelper("session", "accounting-public", this, [
      "client",
      "token",
      "files"
    ]);

    this.state = {
      client: null,
      token: null,
      loading: false,
      files: [],
      ...this.storage.get()
      //files: ["File1.xml", "File2.xml"]
    };
  }

  componentDidMount() {
    const { client } = this.state;
    if (!client) {
      this.getClient();
    }
  }

  getClient = () => {
    this.setState({ loading: true });
    restHelper
      .index("identity/public-client")
      .then(response => {
        this.storage.set({
          loading: false,
          ...response.data
        });
        console.log("getClient.response", response);
      })
      .catch(error => {
        this.storage.set({ loading: false });
        console.log("getClient.error", error);
      });
  };

  onClear = () => {
    this.storage.set({ files: [], client: null, token: null }, () => {
      this.getClient();
    });
  };

  finalProcess = files => {
    const { client, token } = this.state;
    const file_ids = files
      .filter(file => (file.id ? true : false))
      .map(file => file.id);
    console.log("onUploadEnd", file_ids);
    return restHelper
      .post(
        "cashregister-accounting/import",
        "CashregisterAccountingImportForm",
        { file_ids },
        { client }
      )
      .then(response => {
        globalMessage.success(localize(LC, "Sikeres feltöltés."));
        //return localize(LC, "Sikeres feltöltés.");
        const names = files.map(f => f.name);
        this.storage.set({ files: names });
      })
      .catch(error => {
        if (error && error.message) {
          globalMessage.fatalError(error.message);
        } else if (error && error.form_errors && error.form_errors.file_id) {
          globalMessage.fatalError(error.form_errors.file_id.join(", "));
        } else {
          globalMessage.error(localize(LC, "Hiba a feldolgozás közben"));
        }

        //throw localize(LC, "Hiba a feldolgozás közben");
      });
  };

  render() {
    const { classes } = this.props;
    const { client, loading, files } = this.state;
    return (
      <div
        style={{
          width: "100%",
          position: "relative",
          height: "100vh",
          paddingTop: 70,
          overflow: "auto"
        }}
      >
        <Helmet>
          <title>{localize(LC, "Könyvelés megtekintő")}</title>
        </Helmet>
        <NavBarPublic logoLink={"/public-accounting"} />
        <Paper className={classes.paper}>
          {loading && <Loader />}
          {files.length > 0 && (
            <SnackbarContent
              className={classes.success}
              classes={{
                message: classes.message
              }}
              message={
                <Fragment>
                  <CheckCircleIcon className={classes.icon} />
                  <span className="file-base">
                    {localize(LC, "Betöltött fájlok száma")}: {files.length}{" "}
                    {localize(LC, "darab")}
                  </span>
                  <span className="file-details">
                    {localize(LC, "Betöltött fájlok")}: {files.join(", ")}
                  </span>
                </Fragment>
              }
              action={[
                <Tooltip
                  key="close"
                  title={localize(
                    LC,
                    "Felület kiürítése, új fájlok feltöltése"
                  )}
                >
                  <IconButton
                    aria-label="Close"
                    color="inherit"
                    className={classes.clearButton}
                    onClick={this.onClear}
                  >
                    <CloseIcon className={classes.closeIcon} fontSize="small" />
                  </IconButton>
                </Tooltip>
              ]}
            />
          )}
          {!loading && files.length === 0 && (
            <div style={{ width: "100%", position: "relative" }}>
              <Typography variant="h6">
                {localize(LC, "Könyvelés megtekintő")}
              </Typography>
              <FormHelperText>
                {localize(
                  LC,
                  "Xml pénztárgép állományok elemzésére szolgáló felület."
                )}
              </FormHelperText>
              <FileUploadMulti
                restHelper={restHelper}
                restHelperOptions={{
                  client,
                  baseURL: process.env.REACT_APP_HOST + "/v1/"
                }}
                finalProcess={this.finalProcess}
                acceptedFiles="application/xml"
                uploadButtonText={localize(LC, "Megtekintés")}
                uploadButtonVariant="contained"
                uploadButtonIcon={ViewIcon}
              />
            </div>
          )}
          {client && !loading && files.length > 0 && (
            <Accounting
              client_id={client}
              restHelper={restHelper}
              height={"calc(100vh - 340px)"}
            />
          )}
        </Paper>
      </div>
    );
  }
}

AccountingPublic.defaultProps = {};
AccountingPublic.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AccountingPublic);
