import React from "react";
import { get } from "lodash";

export default class EditPage extends React.Component {
  isViewPage = () => {
    //return get(this.props, "match.path", "").indexOf("/view/") !== -1;
    //TODO: less render visszatenni a felsőt
    return (
      get(this.props, "history.location.pathname", "").indexOf("/view/") !== -1
    );
  };
}
