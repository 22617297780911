import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const ContainerHeader = ({ title, customHeaderButton }) => {
  return (
    <Grid container>
      <Grid item>
        <Typography variant="h6" component="span">
          {title}
        </Typography>
        {customHeaderButton && customHeaderButton()}
      </Grid>
    </Grid>
  );
};

ContainerHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  customHeaderButton: PropTypes.func
};

export default ContainerHeader;
