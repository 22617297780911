import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditToken from "./views/EditToken";
import * as tokensActions from "../../../../redux/modules/tokens";
import * as TokensOptionsActions from "../../../../redux/modules/TokensOptions";

const mapStateToProps = state => {
  return {
    loading: state.tokens.loading,
    success: state.tokens.success,
    saved: state.tokens.saved,
    message: state.tokens.message,
    fieldErrors: state.tokens.formErrors,
    id: state.tokens.id,
    viewData: state.tokens.viewData,
    reducerName: "tokens",
    model: state.TokensOptions.model,
    baseFields: state.TokensOptions.baseFields,
    enabledFields: state.TokensOptions.enabledFields,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: tokensActions.createTokens,
      update: tokensActions.updateTokens,
      view: tokensActions.viewTokens,
      reset: tokensActions.reset,
      resetForm: tokensActions.resetForm,
      getFormInfo: TokensOptionsActions.getFormInfo
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditToken);
