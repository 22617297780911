import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CartWrapper from "./CartWrapper";
import CartItem from "./CartItem";
import { Grid, Button, Paper, Typography } from "@material-ui/core";
import formatHelper from "helpers/formatHelper";
import ContinueIcon from "@material-ui/icons/KeyboardArrowRight";
import { Link, Switch, Route, withRouter, Redirect } from "react-router-dom";
import {
  BASE_PATH_CART,
  BASE_PATH_CART_CHECKOUT,
  BASE_PATH_CART_ORDER,
  BASE_PATH_CART_SUCCESS,
  SLUG_CHECKOUT,
  SLUG_ORDER
} from "./../shopPath";
import Products from "./Products";
import CheckOut from "./CheckOut";
import Steps from "./Steps";
import Order from "./Order";
import Success from "./Success";

const styles = theme => ({});

class Cart extends React.Component {
  render() {
    const {
      theme,
      classes,
      history: {
        location: { pathname }
      },
      order: { step }
    } = this.props;
    //console.log("Cart", pathname);
    const isNotCart = pathname !== BASE_PATH_CART;
    return (
      <div style={{ width: "100%", position: "relative" }}>
        {isNotCart && <Steps currentPath={pathname} />}
        <Switch history={this.props.history}>
          <Route
            path={BASE_PATH_CART}
            exact
            render={() => <Products {...this.props} />}
          />
          {step > 0 && (
            <Route
              path={`${BASE_PATH_CART_CHECKOUT}`}
              exact
              render={() => <CheckOut {...this.props} />}
            />
          )}
          {step > 1 && (
            <Route
              path={BASE_PATH_CART_ORDER}
              exact
              render={() => <Order {...this.props} />}
            />
          )}
          {step > 2 && (
            <Route
              path={BASE_PATH_CART_SUCCESS}
              exact
              render={() => <Success {...this.props} />}
            />
          )}
          {step === 0 && <Redirect to={BASE_PATH_CART} />}
          {step === 1 && <Redirect to={BASE_PATH_CART_CHECKOUT} />}
        </Switch>
      </div>
    );
  }
}

Cart.defaultProps = {};

Cart.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(CartWrapper(Cart));
