import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";

import InputField from "Components/Forms/Fields/Input";
import TagInput from "Components/Forms/Fields/AsyncTagInput";
import Validations from "Components/Forms/Validations";
import EditForm from "Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import {
  mobilNumberSimpleMask,
  mobilNumberMask,
  taxNumberMask
} from "helpers/maskHelper";
import ClientLocations from "./ClientLocations";
import localize from "helpers/localize";
import ClientUsers from "./ClientUsers";

class ClientForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    disableClientRegValidation: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this._form = React.createRef();
  }

  submit = () => {
    const instance = this._form.current.getWrappedInstance();
    if (instance) {
      instance.submit();
    }
  };

  handleSetTags = (name, tags) => {
    const { dispatch, change } = this.props;
    dispatch(change("tags", tags));
  };

  render() {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      enabledFields,
      initialValues,
      disableClientRegValidation
    } = this.props;

    let methods = [];
    Object.keys(
      get(this.props, "baseFields.client_payment_method.data", {})
    ).forEach(key => {
      methods.push({
        id: key,
        label: this.props.baseFields.client_payment_method.data[key]
      });
    });

    return (
      <Fragment>
        <EditForm {...this.props} ref={this._form}>
          <Fragment>
            <div className="col-12 col-md-6">
              <Field
                name="name"
                isRequired={true}
                validate={[Validations.required]}
                label={localize("clients", "Cégnév")}
                fieldError={fieldErrors.name}
                onChange={() => handleInputChange("name")}
                component={InputField}
                placeholder={localize("clients", "Cégnév")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("name") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <Field
                name="email"
                isRequired={true}
                validate={
                  this.props.disableEmailAndPhoneRequiredValidation
                    ? []
                    : [Validations.required, Validations.email]
                }
                label={localize("clients", "Email")}
                fieldError={fieldErrors.name}
                onChange={() => handleInputChange("email")}
                component={InputField}
                placeholder={localize("clients", "Email")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("email") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <Field
                name="phone"
                isRequired={true}
                validate={
                  this.props.disableEmailAndPhoneRequiredValidation
                    ? []
                    : [Validations.required, Validations.mobile_number]
                }
                {...mobilNumberMask}
                label={localize("clients", "Mobiltelefonszám")}
                fieldError={fieldErrors.phone}
                //onChange={() => handleInputChange("phone")}
                component={InputField}
                placeholder={localize("clients", "Mobiltelefonszám")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("phone") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <Field
                name="phone2"
                /*{...mobilNumberSimpleMask}
                validate={
                  this.props.disableEmailAndPhoneRequiredValidation
                    ? []
                    : [Validations.mobile_number]
                }*/
                label={localize("clients", "Másodlagoslagos telefonszám")}
                fieldError={fieldErrors.phone2}
                //onChange={() => handleInputChange("phone2")}
                component={InputField}
                placeholder={localize("clients", "Másodlagoslagos telefonszám")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("phone2") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <Field
                name="tax_number"
                isRequired={true}
                validate={[Validations.required, Validations.tax_number]}
                label={localize("clients", "Adószám")}
                fieldError={fieldErrors.tax_number}
                onChange={() => handleInputChange("tax_number")}
                component={InputField}
                placeholder={localize("clients", "Adószám")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("tax_number") !== -1
                      ? false
                      : true
                    : false
                }
                {...taxNumberMask}
              />
            </div>
            <div className="col-12 col-md-6">
              <Field
                name="registration_no"
                label={localize(
                  "clients",
                  "Cégjegyzékszám / Nyilvántart. szám"
                )}
                fieldError={fieldErrors.registration_no}
                onChange={() => handleInputChange("registration_no")}
                component={InputField}
                type="text"
                placeholder={localize(
                  "clients",
                  "Cégjegyzékszám / Nyilvántart. szám"
                )}
                //isRequired={disableClientRegValidation ? false : true}
                /*validate={
                  disableClientRegValidation ? [] : [Validations.required]
                }*/
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("registration_no") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </div>
            <div className="col-12 col-md-2">
              <Field
                name="zip"
                label={localize("clients", "Irányítószám")}
                fieldError={fieldErrors.zip}
                onChange={() => handleInputChange("zip")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("clients", "Irányítószám")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("zip") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </div>
            <div className="col-12 col-md-4">
              <Field
                name="city"
                label={localize("clients", "Település")}
                fieldError={fieldErrors.city}
                onChange={() => handleInputChange("city")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("clients", "Település")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("city") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <Field
                name="street"
                label={localize("clients", "Közterület neve")}
                fieldError={fieldErrors.address}
                onChange={() => handleInputChange("address")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("clients", "Közterület neve")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("street") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <Field
                name="street_suffix"
                label={localize("clients", "Közterület jellege")}
                fieldError={fieldErrors.address}
                onChange={() => handleInputChange("street_suffix")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("clients", "Közterület jellege")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("street_suffix") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <Field
                name="street_details"
                label={localize("clients", "Házszám")}
                fieldError={fieldErrors.address}
                onChange={() => handleInputChange("street_details")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("clients", "Házszám")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("street_details") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <Field
                name="tags"
                label={localize("distributors", "Címkék")}
                fieldError={fieldErrors.tags}
                //onChange={() => handleInputChange("tags")}
                onChangeTags={tags => this.handleSetTags("tags", tags)}
                component={TagInput}
                placeholder={localize("distributors", "Címkék")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("tags") !== -1
                      ? false
                      : true
                    : false
                }
                tagPath={get(this.props, "baseFields.tags.url", null)}
              />
            </div>
          </Fragment>
        </EditForm>
        {initialValues && initialValues.id && (
          <Fragment>
            <ClientLocations
              client={initialValues}
              readOnly={isViewPage}
              afterSave={this.props.afterLocationSave}
            />
            <ClientUsers
              client={initialValues}
              readOnly={isViewPage}
              //afterSave={this.props.afterLocationSave}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "clients",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(ClientForm);

export default connect(
  state => ({
    initialValues: state.clients.viewData
  }),
  null,
  null,
  { forwardRef: true, withRef: true }
)(UsersReduxForm);
