import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import UsersList from "./views/UsersList";
import * as usersActions from "../../../redux/modules/users";
import * as optionsActions from "../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.users.loading,
    success: state.users.success,
    message: state.users.message,
    data: state.users.data,
    total: state.users.total,
    limit: state.users.limit,
    page: state.users.page,
    order: state.users.order,
    orderMode: state.users.orderMode,
    deleted: state.users.deleted,
    deletedId: state.users.deletedId,
    token: state.users.token,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: usersActions.usersList,
      delete: usersActions.userDelete,
      setLoading: usersActions.setLoading,
      userListApproval: usersActions.userListApproval,
      reset: usersActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersList);
