import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditChange from "./views/EditChange";
import * as changesActions from "redux/modules/service-changes";

const mapStateToProps = state => {
  return {
    loading: state.serviceChanges.loading,
    success: state.serviceChanges.success,
    saved: state.serviceChanges.saved,
    message: state.serviceChanges.message,
    fieldErrors: state.serviceChanges.formErrors,
    id: state.serviceChanges.id,
    viewData: state.serviceChanges.viewData,
    reducerName: "serviceChanges",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: changesActions.createChanges,
      update: changesActions.updateChanges,
      view: changesActions.viewChanges,
      reset: changesActions.reset,
      resetForm: changesActions.resetForm
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditChange);
