import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";

import MobileDetect from "mobile-detect";

import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
//import "react-bootstrap-typeahead/css/Typeahead.css";
//import "react-dates/lib/css/_datepicker.css";
//import "react-tagsinput/react-tagsinput.css";
//import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
//import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles/jumbo.css";
import "./assets/css/style.css";
//import "react-dates/initialize";
import "text-security/dist/text-security.css";
import errorResponseHandler from "helpers/errorResponseHandler";
import { getLocale } from "helpers/localize";
let md = new MobileDetect(window.navigator.userAgent);

if (md.mobile()) {
  axios.defaults.headers.common["X-Device-Category"] = "Mobile";
} else if (md.tablet()) {
  axios.defaults.headers.common["X-Device-Category"] = "Tablet";
} else {
  axios.defaults.headers.common["X-Device-Category"] = "Desktop";
}

axios.defaults.headers.common["X-Locale"] = getLocale();

axios.defaults.baseURL = process.env.REACT_APP_HOST;
// apply interceptor on response
axios.interceptors.response.use(response => response, errorResponseHandler);

ReactDOM.render(<Root />, document.getElementById("app-site"));
serviceWorker.unregister();
