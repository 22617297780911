import React, { Component } from "react";
import PropTypes from "prop-types";
import FileUploadMultiList from "./FileUploadMultiList";
import UploadIcon from "@material-ui/icons/Search";
import AttachIcon from "@material-ui/icons/AttachFile";
import RemoveIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/CloudUpload";

import {
  Button,
  CircularProgress,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  withStyles
} from "@material-ui/core";
import restHelper from "helpers/restHelper";
import localize from "helpers/localize";
import Spacer from "Components/New/Spacer";
import Alert from "../Alert/Alert";

const styles = theme => ({
  sendIcon: {
    marginRight: theme.spacing.unit
  },
  attachIcon: {
    marginRight: theme.spacing.unit
  },
  margin: {
    margin: theme.spacing.unit
  }
});

class FileUploadMulti extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      loading: -1,
      finished: false,
      finalProcessLoading: false,
      finalProcessErrorMessage: null,
      finalProcessSuccessMessage: null
    };
  }

  reset = () => {
    this.setState({
      files: [],
      loading: -1,
      finished: false,
      finalProcessLoading: false,
      finalProcessErrorMessage: null,
      finalProcessSuccessMessage: null
    });
  };

  handleUpload = event => {
    let files = this.state.files.slice();
    const { uploadOnSelect } = this.props;
    if (event.target.files) {
      this.validatedFiles(event.target.files).forEach(file => {
        files.push(file);
      });
      this.setState({ files }, () => {
        if (uploadOnSelect) {
          this.onFilesChanged();
        }
      });
    }
  };

  onFilesChanged = () => {
    const { files, loading } = this.state;
    const { finalProcess } = this.props;

    if (files.length === 0) {
      return;
    }

    if (loading >= 0) {
      return;
    }
    let hasUpload = false;
    let hasProcessed = false;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (file.processed && !file.error) {
        hasProcessed = true;
      }
      if (!file.processed && !file.error) {
        //Nincs feltöltve
        hasUpload = true;
        this.upload(index);
        break;
      }
    }
    //Nincs több feltöltés, mehet az utolsó akció
    if (!hasUpload && hasProcessed) {
      this.setState(
        { finished: true, finalProcessLoading: finalProcess ? true : false },
        () => {
          finalProcess &&
            finalProcess(files)
              .then(message => {
                this.setState({
                  finalProcessSuccessMessage: message,
                  finalProcessLoading: false,
                  finalProcessErrorMessage: null
                });
              })
              .catch(error => {
                this.setState({
                  finalProcessErrorMessage: error,
                  finalProcessLoading: false,
                  finalProcessSuccessMessage: null
                });
              });
        }
      );
    } else if (!hasUpload) {
      this.setState({ finished: true });
    }
  };

  upload = index => {
    let files = this.state.files.slice();
    let file = files[index];

    this.setState({ loading: index }, () => {
      this.props.restHelper
        .upload(file, this.props.restHelperOptions)
        .then(response => {
          file.id = response.data.id;
          file.processed = true;
          file.index = index;
          files[index] = file;
          this.setState(
            {
              files,
              loading: -1
            },
            () => {
              this.onFilesChanged();
            }
          );
        })
        .catch(error => {
          file.processed = true;
          file.error = error.message;
          file.index = index;
          files[index] = file;
          this.setState(
            {
              files,
              loading: -1
            },
            () => {
              this.onFilesChanged();
            }
          );
        });
    });
  };

  validatedFiles = files => {
    for (let index = 0; index < files.length; index++) {
      let file = files[index];
      const error = this.validateExt(file.name);
      if (error) {
        file.error = error;
      }
    }
    return files;
  };

  validateExt = sFileName => {
    let acceptedFiles = this.props.acceptedFiles
      ? this.props.acceptedFiles.split(/\||,/)
      : null;
    //Nincs korlátozás
    if (!acceptedFiles) {
      return true;
    }

    if (sFileName.length > 0) {
      var blnValid = false;
      for (var j = 0; j < acceptedFiles.length; j++) {
        var sCurExtension = acceptedFiles[j]
          .replace("application/", "")
          .replace("image/", "");
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        const error =
          localize("files", "A formátum nem megfelelő, elfogadott formátumok") +
          ": " +
          acceptedFiles
            .join(", ")
            .replace(new RegExp("application/", "g"), "")
            .replace(new RegExp("[.]", "g"), "")
            .replace(new RegExp("image/", "g"), "");
        //this.setState({ error: error });
        return error;
      }
    }

    return undefined;
  };

  onDelete = index => {
    let files = this.state.files.slice();
    files.splice(index, 1);
    this.setState({ files });
  };

  render() {
    const {
      classes,
      id,
      ListComponent,
      uploadOnSelect,
      acceptedFiles,
      uploadButtonText,
      uploadButtonVariant,
      uploadButtonIcon
    } = this.props;
    const {
      files,
      loading,
      finished,
      finalProcessLoading,
      finalProcessSuccessMessage,
      finalProcessErrorMessage
    } = this.state;
    const Icon = uploadButtonIcon || SendIcon;
    return (
      <div style={{ width: "100%", position: "relative" }}>
        <Toolbar disableGutters>
          <Button
            disabled={loading >= 0 || finished}
            //size="small"
            //variant="contained"
            color="primary"
            component="label"
            htmlFor={id}
          >
            <input
              onChange={this.handleUpload}
              type="file"
              multiple
              id={id}
              accept={acceptedFiles}
              style={{ display: "none" }}
            />
            <AttachIcon className={classes.attachIcon} fontSize="small" />
            {localize(
              "files",
              files.length > 0 ? "További fájlok tallózása" : "Fájlok tallózása"
            )}
          </Button>
          <Spacer right />
          {files.length > 0 && (
            <Button color="secondary" onClick={this.reset}>
              {localize("files", "Alaphelyzetbe állítás")}
            </Button>
          )}
        </Toolbar>
        {files.length > 0 && (
          <ListComponent
            files={files}
            loading={loading}
            finished={finished}
            onDelete={this.onDelete}
          />
        )}
        {!uploadOnSelect && files.length > 0 && (
          <Toolbar disableGutters>
            <Button
              color="primary"
              variant={uploadButtonVariant ? uploadButtonVariant : undefined}
              onClick={this.onFilesChanged}
              disabled={finished || loading >= 0 || finalProcessLoading}
            >
              <Icon fontSize="small" className={classes.sendIcon} />{" "}
              {uploadButtonText || localize("files", "Feltöltés")}
            </Button>
            <Spacer w={2} />
            {finalProcessLoading && (
              <CircularProgress color="primary" size={20} />
            )}
            {/*finalProcessSuccessMessage && (
              <Alert variant="success" message={finalProcessSuccessMessage} />
            )}
            {finalProcessErrorMessage && (
              <Alert variant="error" message={finalProcessErrorMessage} />
            )*/}
          </Toolbar>
        )}
      </div>
    );
  }
}

FileUploadMulti.defaultProps = {
  classes: PropTypes.object.isRequired,
  id: `file_${new Date().getTime()}`,
  ListComponent: FileUploadMultiList,
  uploadOnSelect: false,
  restHelper
};
FileUploadMulti.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  ListComponent: PropTypes.func.isRequired,
  finalProcess: PropTypes.func,
  restHelper: PropTypes.any,
  restHelperOptions: PropTypes.object,
  uploadOnSelect: PropTypes.bool,
  acceptedFiles: PropTypes.string,
  uploadButtonText: PropTypes.string,
  uploadButtonIcon: PropTypes.any,
  uploadButtonVariant: PropTypes.oneOf([
    "default",
    "text",
    "outlined",
    "contained",
    "flat",
    "raised"
  ])
};

export default withStyles(styles)(FileUploadMulti);
