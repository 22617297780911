import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import WorksheetsList from "./views/WorksheetsList";
import * as worksheetsActions from "../../../../redux/modules/worksheets";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.worksheets.loading,
    success: state.worksheets.success,
    message: state.worksheets.message,
    data: state.worksheets.data,
    total: state.worksheets.total,
    limit: state.worksheets.limit,
    page: state.worksheets.page,
    order: state.worksheets.order,
    orderMode: state.worksheets.orderMode,
    deleted: state.worksheets.deleted,
    deletedId: state.worksheets.deletedId,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: worksheetsActions.worksheetsList,
      delete: worksheetsActions.worksheetDelete,
      setLoading: worksheetsActions.setLoading,
      reset: worksheetsActions.reset,
      changeRow: worksheetsActions.changeRow
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorksheetsList);
