import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PasswordActivate from "./views/PasswordActivate";
import * as authActions from "../../../../redux/modules/auth";

const mapStateToProps = state => {
  return {
    failed: state.auth.failed,
    success: state.auth.success,
    tokenSuccess: state.auth.tokenSuccess,
    loading: state.auth.loading,
    logined: state.auth.logined,
    message: state.auth.message
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reset: authActions.reset,
      resetForm: authActions.resetForm,
      checkToken: authActions.passwordRecoveryActivateCheck,
      passwordRecovery: authActions.passwordRecovery
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordActivate);
