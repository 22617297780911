import React from "react";
import PropTypes from "prop-types";
import restHelper from "helpers/restHelper";
import { Typography, Grid, withStyles, Hidden } from "@material-ui/core";

import WorksheetBillingDesktop from "./WorksheetBillingDesktop";
import WorksheetBillingMobile from "./WorksheetBillingMobile";
import localize from "helpers/localize";

const styles = theme => ({
  field: {
    display: "flex",
    alignItems: "center",
    "& span": {
      marginRight: theme.spacing.unit
    }
  }
});

class WorksheetBilling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      originalSum: 0,
      baseFields: {}
    };
  }

  componentDidMount() {
    this.fetchOptions();
  }

  fetchOptions() {
    restHelper
      .index("worksheets/options", { component: "form" }, false)
      .then(response => {
        this.setStateAdvanced({ baseFields: response.data.form.baseFields });
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id && nextProps.id !== this.props.id) {
      this.fetchData(nextProps.id);
    }
  }

  fetchData = id => {
    if (!id) {
      return;
    }
    restHelper.view("worksheets/raw", id).then(response => {
      let data = response.data || {};
      data.originalSum = this.getOriginalSum(data);
      this.setStateAdvanced({
        data
      });
    });
  };

  getOriginalSum = data => {
    if (!data) {
      return 0;
    }

    let sum = 0;
    data.shop_products &&
      data.shop_products.forEach(item => {
        sum += parseFloat(item.price || 0) * parseFloat(item.quantity || 0);
      });
    data.workfees &&
      data.workfees.forEach(item => {
        sum += parseFloat(item.price || 0) * parseFloat(item.quantity || 0);
      });

    return sum;
  };

  setStateAdvanced = (state, cb) => {
    const { onChange } = this.props;
    this.setState(state, () => {
      onChange && onChange(this.state.data);
      cb && cb();
    });
  };

  /* Workfees start */
  addNewWorkfee = () => {
    let data = Object.assign({}, this.state.data);
    data.workfees.push({});
    this.setStateAdvanced({ data });
  };

  deleteWorkfee = index => () => {
    let data = Object.assign({}, this.state.data);
    data.workfees.splice(index, 1);
    this.setStateAdvanced({ data });
  };

  onSelectWorkfeeType = index => (option, value) => {
    this.changeWorkfee(index, "type", value);
  };

  onSelectWorkfee = index => (option, value) => {
    if (option) {
      this.changeWorkfee(index, { name: value, price: option.price });
    } else {
      this.changeWorkfee(index, { name: null, price: null });
    }
  };

  changeWorkfee = (index, key, value) => {
    let data = Object.assign({}, this.state.data);
    if (typeof key === "object") {
      data.workfees[index] = Object.assign({}, data.workfees[index], key);
    } else {
      data.workfees[index][key] = value;
    }
    this.setStateAdvanced({ data });
  };

  onChangeWorkfeeQty = index => evt => {
    this.changeWorkfee(index, "quantity", evt.target.value);
  };

  onChangeWorkfeePrice = index => evt => {
    this.changeWorkfee(index, "price", evt.target.value);
  };
  /* Workfees end */

  /* Products start */
  addNewProduct = () => {
    let data = Object.assign({}, this.state.data);
    data.shop_products.push({});
    this.setStateAdvanced({ data });
  };

  deleteProduct = index => () => {
    let data = Object.assign({}, this.state.data);
    data.shop_products.splice(index, 1);
    this.setStateAdvanced({ data });
  };

  onSelectProduct = index => (option, value) => {
    let data = Object.assign({}, this.state.data);
    if (option) {
      const qty = data.shop_products[index].qty || 0;
      const price = this.getPrices(option.prices, qty || 1);
      this.changeProduct(index, { name: value, price });
    } else {
      this.changeProduct(index, { name: null, price: null });
    }
  };

  changeProduct = (index, key, value) => {
    let data = Object.assign({}, this.state.data);
    if (typeof key === "object") {
      data.shop_products[index] = Object.assign(
        {},
        data.shop_products[index],
        key
      );
    } else {
      data.shop_products[index][key] = value;
    }
    this.setStateAdvanced({ data });
  };

  onChangeProductQty = index => evt => {
    this.changeProduct(index, "quantity", evt.target.value);
  };

  onChangeProductPrice = index => evt => {
    this.changeProduct(index, "price", evt.target.value);
  };

  getPrices = (prices, baseQty) => {
    const qty = !baseQty || baseQty === "" || isNaN(baseQty) ? 0 : baseQty;
    let priceIndex = 0;

    for (let i in prices) {
      if (prices[i].from > qty) {
        break;
      } else {
        priceIndex = i;
      }
    }
    return prices && prices[priceIndex] ? prices[priceIndex].price : null;
  };
  /* Products end */

  concatAddress = () => {
    const { data } = this.state;
    return (
      (data["client_zip"] ? " " + data["client_zip"] : "") +
      (data["client_city"] ? " " + data["client_city"] : "") +
      (data["client_street"] ? " " + data["client_street"] : "") +
      (data["client_street_suffix"] ? " " + data["client_street_suffix"] : "") +
      (data["client_street_details"] ? " " + data["client_street_details"] : "")
    );
  };

  render() {
    const { classes } = this.props;
    const { data, baseFields } = this.state;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h6">{data.client_name}</Typography>
          <Typography variant="subtitle1">{this.concatAddress()}</Typography>
          <div className={classes.field}>
            <Typography variant="caption">
              {localize("worksheets", "Email")}:
            </Typography>
            <Typography variant="subtitle1">{data.client_email}</Typography>
          </div>
          <div className={classes.field}>
            <Typography variant="caption">
              {localize("worksheets", "Adószám")}:
            </Typography>
            <Typography variant="subtitle1">
              {data.client_tax_number}
            </Typography>
          </div>
          <div className={classes.field}>
            <Typography variant="caption">
              {localize("worksheets", "Munkalapszám")}:
            </Typography>
            <Typography variant="subtitle1">{data.number}</Typography>
          </div>
        </Grid>
        <Hidden smDown>
          <WorksheetBillingDesktop
            data={data}
            baseFields={baseFields}
            onSelectWorkfee={this.onSelectWorkfee}
            onChangeWorkfeeQty={this.onChangeWorkfeeQty}
            onChangeWorkfeePrice={this.onChangeWorkfeePrice}
            onSelectWorkfeeType={this.onSelectWorkfeeType}
            deleteWorkfee={this.deleteWorkfee}
            addNewProduct={this.addNewProduct}
            addNewWorkfee={this.addNewWorkfee}
            onSelectProduct={this.onSelectProduct}
            onChangeProductQty={this.onChangeProductQty}
            onChangeProductPrice={this.onChangeProductPrice}
            deleteProduct={this.deleteProduct}
          />
        </Hidden>
        <Hidden mdUp>
          <WorksheetBillingMobile
            data={data}
            baseFields={baseFields}
            onSelectWorkfee={this.onSelectWorkfee}
            onChangeWorkfeeQty={this.onChangeWorkfeeQty}
            onChangeWorkfeePrice={this.onChangeWorkfeePrice}
            onSelectWorkfeeType={this.onSelectWorkfeeType}
            deleteWorkfee={this.deleteWorkfee}
            addNewProduct={this.addNewProduct}
            addNewWorkfee={this.addNewWorkfee}
            onSelectProduct={this.onSelectProduct}
            onChangeProductQty={this.onChangeProductQty}
            onChangeProductPrice={this.onChangeProductPrice}
            deleteProduct={this.deleteProduct}
          />
        </Hidden>
      </Grid>
    );
  }
}

WorksheetBilling.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number,
  onChange: PropTypes.func
};

export default withStyles(styles)(WorksheetBilling);
