import React, {Component, Fragment} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import {DatePicker} from "material-ui-pickers";
import Table from "../../../../../Components/Table/Table";
import {worksheetsSgrid} from "../../../../../redux/modules/worksheets";

class WorksheetStatistic extends Component {
  state = {columns:{test:{name: ""}}, data: [], date: new Date(), page: 1};

  getTableData = data => {
    var rows = {};
    var columns = {};

    Object.entries(data).map(content => {
      const data = content[1];
      const columnId = "u" + data.userId;

      columns[columnId] = {
        hideable: true,
        name: data.userRealName,
        uriName: columnId
      };

      Object.entries(data.totalsByDate).map((totalByDate, index) => {
        const date = new Date(totalByDate[0]);

        if (!(index in rows)) {
          rows[index] = {};
        }

        rows[index][columnId] = this.generateCellInformation(totalByDate[1]);
      });
    });

    this.setState({data: Object.values(rows), columns: columns});
  }

  getStatistic = () => {
    const date = this.state.date.getFullYear() + "-" + (this.state.date.getMonth() + 1);

    this.props.getStatistic(date).then(result => {
      if (result.data) {
        this.getTableData(result.data);
      }
    });
  };

  componentDidMount() {
    if (this.props.match.params.date) {
      this.setState({date: (new Date(this.props.match.params.date))});
    }

    this.getStatistic();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.date !== this.state.date) {
      this.getStatistic();
    }
  }

  generateCellInformation = data => {
    return (
      <Fragment>
        <b>Létrehozva:</b> {data.created}<br/>
        <b>Lezárva:</b> {data.closed}<br/>
        <b>Befejezve:</b> {data.done}<br/>
      </Fragment>
    );
  };

  handleChangePage = (event, page) => {
    this.setState({
      page: page + 1
    });
  };

  handleDate = (date) => {
    this.setState({date: (new Date(date))});
  };

  render() {
    console.log(this.props);

    return (
      <Grid container spacing={24}>
        <Fragment>
          <Grid item xs={12}>
            <Card key="x">
              <CardContent>
                <DatePicker
                    value={this.state.date}
                    format="Y. MM."
                    onChange={this.handleDate}
                    views={["year", "month"]}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Table
                  {...this.props}
                  columns={this.state.columns}
                  columnsOrder={Object.keys(this.state.columns)}
                  disabledTools={true}
                  handleSort={(field, mode) => {}}
                  setLoading={loading => {}}
                  data={this.state.data}
                  handleModuleEvent={(type, id) => {}}
                  handleChangePage={this.handleChangePage.bind(this)}
                  filterValues={{}}
                />
              </CardContent>
            </Card>
          </Grid>
        </Fragment>
      </Grid>
    );
  }
}

export default WorksheetStatistic