import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  withStyles
} from "@material-ui/core";
import localize from "helpers/localize";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorIcon from "@material-ui/icons/Error";
import formatHelper from "helpers/formatHelper";
import UploadIcon from "@material-ui/icons/Search";

const styles = theme => ({
  checkIcon: {
    color: theme.palette.success.main
  },
  errorIcon: {
    color: theme.palette.error.main
  },
  primaryText: {
    fontSize: "0.8em"
  },
  secondaryText: {
    fontSize: "0.6em"
  },
  listItem: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit
  }
});

class FileUploadMultiList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, files, loading, finished, onDelete } = this.props;
    return (
      <List disablePadding>
        <ListSubheader disableGutters disableSticky>
          {localize("files", "Kiválasztott fájlok")}
          <ListItemSecondaryAction />
        </ListSubheader>
        {files.map((file, index) => (
          <Fragment key={`file-${index}`}>
            <ListItem
              disableGutters
              divider={index < files.length - 1}
              className={classes.listItem}
            >
              <ListItemIcon>
                {loading === index ? (
                  <CircularProgress size={18} color="primary" />
                ) : file.id ? (
                  <CheckCircleIcon
                    fontSize="small"
                    className={classes.checkIcon}
                  />
                ) : file.error ? (
                  <ErrorIcon fontSize="small" className={classes.errorIcon} />
                ) : (
                  <RadioButtonUncheckedIcon fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText
                primary={file.name}
                secondary={
                  formatHelper.fileSize(file.size) +
                  (file.error ? ` ${file.error}` : "")
                }
                primaryTypographyProps={{
                  className: classes.primaryText
                }}
                secondaryTypographyProps={{
                  className: classes.secondaryText,
                  color: file.error ? "error" : "default"
                }}
              />
              <ListItemSecondaryAction>
                <IconButton
                  color="primary"
                  disabled={finished}
                  onClick={() => onDelete(index)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {/*index < files.length - 1 && <Divider />*/}
          </Fragment>
        ))}
      </List>
    );
  }
}

FileUploadMultiList.defaultProps = {};
FileUploadMultiList.propTypes = {
  classes: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  files: PropTypes.array,
  loading: PropTypes.number,
  finished: PropTypes.bool
};

export default withStyles(styles)(FileUploadMultiList);
