import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get } from "lodash";

class FuncPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAccess: false
    };
  }

  componentWillMount = () => {
    let isAccess = false;
    if (this.props.user.root) {
      isAccess = true;
    } else {
      const module = this.props.module,
        permission = this.props.permission,
        perms = get(this.props.permissions, module + ".permissions", false);

      isAccess = perms && perms.indexOf(permission) !== -1;
    }
    this.setState({
      isAccess
    });
  };

  render = () => {
    return <Fragment>{this.state.isAccess && this.props.children}</Fragment>;
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    permissions: state.auth.permissions
  };
};

FuncPermission.defaultProps = {
  permissions: {}
};

FuncPermission.propTypes = {
  module: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  permissions: PropTypes.object,
  children: PropTypes.element
};

export default connect(mapStateToProps)(FuncPermission);
