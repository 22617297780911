import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import Select from "Components/New/Input/Select/Select";
import { withRouter, Link, Route, Switch, Redirect } from "react-router-dom";
// import ReactDataSheet from "react-datasheet";
// Be sure to include styles at some point, probably during your bootstrapping
// import "react-datasheet/lib/react-datasheet.css";
import "handsontable/dist/handsontable.full.css";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import restHelper from "helpers/restHelper";
import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import DataForm from "Components/New/DataForm/DataForm";

import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  IconButton
} from "@material-ui/core";
import TextField from "Components/New/Input/TextField/TextField";

const styles = theme => ({
  /*"@global": {
    ".rbt-input-hint": {
      //backgroundColor: "red!important",
      width: "100%",
      top: "2px!important"
    }
  },*/
  table: {
    borderCollapse: "collapse",
    "& td": {
      border: "solid 1px #ddd",
      paddingLeft: `${theme.spacing.unit / 2}px!important`,
      paddingRight: `${theme.spacing.unit / 2}px!important`
    },
    "& th": {
      border: "solid 1px #ddd",
      paddingLeft: `${theme.spacing.unit / 2}px!important`,
      paddingRight: `${theme.spacing.unit / 2}px!important`
    }
  },
  row: {
    height: "auto",
    "&:hover": {
      backgroundColor: "#ddd"
    }
  },
  headerRow: {
    height: "auto"
  }
});

class StaticTable extends React.Component {
  render() {
    const { classes, columns, rows, padding } = this.props;
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            {columns.map((column, colIndex) => {
              const label = column.label || column.id;
              return (
                <TableCell key={`header.${colIndex}`} padding={padding}>
                  {label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => {
            return (
              <TableRow key={rowIndex} className={classes.row}>
                {columns.map((column, colIndex) => {
                  return (
                    <TableCell
                      key={`${rowIndex}.${colIndex}`}
                      padding={padding}
                      style={
                        column.cellStyle ? column.cellStyle(row) : undefined
                      }
                    >
                      {column.url ? (
                        <Link to={column.url(row)}>{row[column.id]}</Link>
                      ) : (
                        row[column.id]
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

StaticTable.defaultProps = {
  padding: "none"
};

StaticTable.propTypes = {
  classes: PropTypes.object,
  columns: PropTypes.array,
  rows: PropTypes.array,
  padding: PropTypes.oneOf(["checkbox", "default", "dense", "none"])
};

export default withStyles(styles)(StaticTable);
