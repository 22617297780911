import React, { Component, Fragment } from "react";
import { FormGroup, Label } from "reactstrap";
import PropTypes from "prop-types";

class Wrapper extends Component {
  static propTypes = {
    input: PropTypes.any.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  };

  render() {
    const {
      input,
      label,
      value,
      children,
      isViewPage,
      isWysiwyg,
      fieldClass,
      groupStyle,
      boldValue,
      groupClass
    } = this.props;

    return (
      <FormGroup
        className={
          //(isViewPage ? "row" : "") +
          !isViewPage ? " field-group-class" : ""
        }
        style={
          groupStyle ? groupStyle : { display: "flex", flexDirection: "row" }
        }
      >
        {isViewPage && label && (
          <Label
            className={
              "label-control" +
              (fieldClass ? " " + fieldClass : "") /*" col-md-5"*/
            }
            style={{ flex: 1 }}
            for={input.name}
          >
            {label && label !== "" ? label : ""}
          </Label>
        )}
        {/*label && label !== "" && isViewPage ? ":" : ""*/}
        <div
          className={
            !isWysiwyg || (isWysiwyg && !isViewPage) ? "controls" : "col-12"
          }
          style={{
            ...((!isWysiwyg && label) || (isWysiwyg && !isViewPage)
              ? {
                  /*paddingLeft: 14*/
                }
              : {}),
            ...(boldValue && isViewPage ? { fontWeight: "bold" } : {}),
            ...(isViewPage ? { paddingLeft: 16, paddingRight: 16 } : {})
          }}
        >
          {!isViewPage && children}
          {isViewPage && !isWysiwyg && (
            <Fragment>{value ? value : "-"}</Fragment>
          )}
          {isViewPage && isWysiwyg && (
            <div dangerouslySetInnerHTML={{ __html: value ? value : "-" }} />
          )}
        </div>
      </FormGroup>
    );
  }
}

export default Wrapper;
