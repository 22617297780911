/* eslint-disable */
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import GenericForm from "../../../../../Components/Forms/GenericForm";
import { toastr } from "../../../../../helpers/CustomToastr";
import SendSmsForm from "./SendSmsForm";

import Button from "@material-ui/core/Button/Button";
import localize from "helpers/localize";
import { get } from "lodash";
import connect from "react-redux/es/connect/connect";
import CashregisterKeyGenerators from "../CashregisterKeyGenerators";
import RightsList from "../RightsList";
import SendErrorReportForm from "./SendErrorReportForm";
const styles = theme => ({
  root: {
    width: "100%",
    marginTop: "30px"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

class CashregisterActions extends Component {
  state = {
    expanded: null,
    smsPanel: new Date().getTime()
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  refreshHistory = () => {
    const id = get(this.props, "match.params.id", null);
    if (id) {
      this.props.historyList(id);
    }
  };

  handleSendSms = formData => {
    this.props
      .sendSms(this.props.match.params.id, {
        number: formData.number,
        message: formData.message
      })
      .then(res => {
        this.refreshHistory();
      });
  };

  handleChangeStatus = status => {
    this.props.setStatus(this.props.match.params.id, status);
  };

  handleSendErrorReporting = formData => {
    this.props
      .sendReport(this.props.match.params.id, {
        description: formData.description
      })
      .then(res => {
        this.refreshHistory();
      });
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.SMSsaved && nextProps.SMSsaved) {
      toastr.success(localize("basic", "Sikeres művelet"));
      this.setState({
        smsPanel: new Date().getTime()
      });
    }
    if (!this.props.SMSmessage && nextProps.SMSmessage) {
      toastr.error(nextProps.SMSmessage);
    }
    if (!this.props.Statussaved && nextProps.Statussaved) {
      toastr.success(localize("basic", "Sikeres művelet"));
    }
    if (!this.props.Statusmessage && nextProps.Statusmessage) {
      toastr.error(nextProps.Statusmessage);
    }
    if (!this.props.Reportsaved && nextProps.Reportsaved) {
      toastr.success(localize("basic", "Sikeres művelet"));
    }
    if (!this.props.Reportmessage && nextProps.Reportmessage) {
      toastr.error(nextProps.Reportmessage);
    }
  };

  render() {
    const { classes, canReregister } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
        {get(this.props, "initialValues.id") && (
          <Fragment>
            {this.props.initialValues.status === "data_stored" && (
              <ExpansionPanel
                expanded={expanded === "panel1"}
                onChange={this.handleChange("panel1")}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>
                    {localize("cashregisters", "Kulcsgenerálás")}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="row">
                  <CashregisterKeyGenerators
                    {...this.props}
                    canReregister={canReregister}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
            <ExpansionPanel
              expanded={expanded === "panel2"}
              onChange={this.handleChange("panel2")}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {localize("cashregisters", "Hiba jelentés")}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="row">
                {this.props.initialValues.status === "data_stored" && (
                  <GenericForm
                    onSubmit={this.handleSendErrorReporting.bind(this)}
                    fieldErrors={this.props.ReportfieldErrors}
                  >
                    <SendErrorReportForm
                      {...this.props}
                      loading={this.props.Reportloading}
                    />
                  </GenericForm>
                )}
                {this.props.initialValues.status !== "data_stored" && (
                  <Fragment>
                    <div className="col-12">
                      {this.props.initialValues.status === "error_reported" && (
                        <Fragment>
                          <Button
                            disabled={this.props.Statusloading}
                            onClick={this.handleChangeStatus.bind(
                              this,
                              "accepted_for_repair_distributor"
                            )}
                            variant="contained"
                            component="span"
                            color="secondary"
                          >
                            {localize(
                              "cashregisters",
                              "Javításra átvett (forgalmazó)"
                            )}
                          </Button>
                          {"\u00a0\u00a0"}
                        </Fragment>
                      )}
                      {this.props.initialValues.status !== "scrapped" && (
                        <Fragment>
                          <Button
                            disabled={this.props.Statusloading}
                            onClick={this.handleChangeStatus.bind(
                              this,
                              "scrapped"
                            )}
                            variant="contained"
                            component="span"
                            color="secondary"
                          >
                            {localize("cashregisters", "Selejtezve")}
                          </Button>
                          {"\u00a0\u00a0"}
                        </Fragment>
                      )}
                      <span>
                        {localize(
                          "cashregisters",
                          "Átvételi bizonylat nyomtatása"
                        )}
                      </span>
                    </div>
                    {get(this.props.initialValues, "error_desc", false) && (
                      <div className="col-12">
                        <br />
                        <b>{localize("cashregisters", "Hiba leírása")}:</b>
                        {this.props.initialValues.error_desc}
                      </div>
                    )}
                  </Fragment>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === "panel3"}
              onChange={this.handleChange("panel3")}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {localize("cashregisters", "SMS Küldés")}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="row">
                <GenericForm
                  onSubmit={this.handleSendSms.bind(this)}
                  fieldErrors={this.props.SMSfieldErrors}
                  key={this.state.smsPanel}
                >
                  <SendSmsForm
                    {...this.props}
                    loading={this.props.SMSloading}
                  />
                </GenericForm>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {this.props.initialValues.can_modify_cashregister_rights && (
              <ExpansionPanel
                expanded={expanded === "panel4"}
                onChange={this.handleChange("panel4")}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>
                    {localize("cashregisters", "Jogok kezelése")}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="row">
                  <div className="col-12">
                    <RightsList {...this.props} module="cashregister-rights" />
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

CashregisterActions.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  state => ({
    initialValues: state.cashregisters.viewData
  }),
  null
)(withStyles(styles)(CashregisterActions));
