import React from "react";
import PropTypes from "prop-types";

class PageMenuBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (!props.match.params.menu) {
      const path = props.defaultPath
        ? props.match.url + props.defaultPath
        : props.match.url + props.children[0].props.path;
      props.history.replace(path);
    }
  }

  /**
   * Routolás tabváltozás esetén
   */
  handleTabClick = (evt, path, params) => {
    //this.props.history.push(pathname);
    const { onChange } = this.props;
    onChange && onChange(evt, path, params);
  };

  /**
   * Gyerek property-k kiolvasása
   */
  getChildrenProps = () => {
    const props = this.props.children
      .filter(item => item !== false && item !== null)
      .map(child => {
        return (({ path, title, icon }) => ({
          path,
          title,
          icon
        }))(child.props);
      });
    return props;
  };

  /**
   * Gyerek szűrése
   */
  getChildrenVisible = () => {
    return this.props.children.filter(item => item !== false && item !== null);
  };
}

PageMenuBase.defaultProps = {
  basePath: ""
};

PageMenuBase.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  children: PropTypes.array,
  basePath: PropTypes.string,
  defaultPath: PropTypes.string,
  onChange: PropTypes.func
};

export default PageMenuBase;
