import React from "react";
import PropTypes from "prop-types";
import noImage from "assets/img/no-image.png";
/**
 * Tokent tartalmazó letöltési url előállítása
 * @param {String} fileId
 */
export const getUrl = (fileId, fallBackImage = noImage) =>
  fileId
    ? `${
      process.env.REACT_APP_HOST
    }/v1/files/download/${fileId}?accessToken=${localStorage
      .getItem("access_token")
      .replace("Bearer ", "")}`
    : fallBackImage;

/**
 * Egyszerű img wrapper. Biztonságos képmegjelenítéshez fileId alapján. Minden egyéb property továbbítva lesz az img tag-nek
 *
 * @param {Object} props react component properties. Továbbküldve az img tag-nek;
 *
 * @return <img />
 */
const Image = props => {
  const { fileId, fallBackImage, ...rest } = props;
  return <img src={getUrl(fileId, fallBackImage)} {...rest} />;
};

Image.defaultProps = {
  fallBackImage: noImage
};

Image.propTypes = {
  fileId: PropTypes.string, //A szerver által küldött file azonosító
  fallBackImage: PropTypes.string // Ha nincs file id ezt az urlt mutatja
};

export default Image;
