import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditSmsListItem from "./views/EditSmsListItem";
import * as listItemsSmsActions from "../../../../redux/modules/list-items-sms";

const mapStateToProps = state => {
  return {
    loading: state.listItemsSms.loading,
    success: state.listItemsSms.success,
    saved: state.listItemsSms.saved,
    message: state.listItemsSms.message,
    fieldErrors: state.listItemsSms.formErrors,
    id: state.listItemsSms.id,
    viewData: state.listItemsSms.viewData,
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: listItemsSmsActions.createSmsListItems,
      update: listItemsSmsActions.updateSmsListItems,
      view: listItemsSmsActions.viewSmsListItems,
      reset: listItemsSmsActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSmsListItem);
