import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditExam from "./views/EditExam";
import * as examsActions from "../../../../redux/modules/exams";

const mapStateToProps = state => {
  return {
    loading: state.exams.loading,
    success: state.exams.success,
    saved: state.exams.saved,
    message: state.exams.message,
    fieldErrors: state.exams.formErrors,
    id: state.exams.id,
    viewData: state.exams.viewData,
    reducerName: "exams",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: examsActions.createExams,
      update: examsActions.updateExams,
      view: examsActions.viewExams,
      reset: examsActions.reset,
      resetForm: examsActions.resetForm
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditExam);
