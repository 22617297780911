import * as React from "react";
import * as PropTypes from "prop-types";
import classNames from "classnames";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";

import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
  toggleCell: {
    textAlign: "center",
    textOverflow: "initial",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit
  },
  toggleCellButton: {
    verticalAlign: "middle",
    display: "inline-block",
    padding: 0,
    width: 16,
    height:  16
  },
  toggleCellIcon: {
    width: 16,
    height:  16
  }
});

const TableDetailToggleCellBase = ({
  style,
  expanded,
  classes,
  onToggle,
  tableColumn,
  tableRow,
  row,
  className,
  ...restProps
}) => {
  const handleClick = e => {
    e.stopPropagation();
    onToggle();
  };
  return (
    <TableCell
      className={classNames(classes.toggleCell, className)}
      style={style}
      {...restProps}
    >
      {((row.meta && row.meta.ITL) || row.raw_recipe) && (
        <IconButton className={classes.toggleCellButton} onClick={handleClick}>
          {expanded ? (
            <ExpandLess className={classes.toggleCellIcon} />
          ) : (
            <ExpandMore className={classes.toggleCellIcon} />
          )}
        </IconButton>
      )}
    </TableCell>
  );
};

TableDetailToggleCellBase.propTypes = {
  style: PropTypes.object,
  expanded: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  onToggle: PropTypes.func,
  className: PropTypes.string,
  tableColumn: PropTypes.object,
  tableRow: PropTypes.object,
  row: PropTypes.any
};

TableDetailToggleCellBase.defaultProps = {
  style: null,
  expanded: false,
  onToggle: () => {},
  className: undefined,
  tableColumn: undefined,
  tableRow: undefined,
  row: undefined
};

export default withStyles(styles, {
  name: "TableDetailToggleCell"
})(TableDetailToggleCellBase);
