import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import WorkfeesList from "./views/WorkfeesList";
import * as workfeesActions from "../../../../redux/modules/workfees";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.workfees.loading,
    success: state.workfees.success,
    message: state.workfees.message,
    data: state.workfees.data,
    total: state.workfees.total,
    limit: state.workfees.limit,
    page: state.workfees.page,
    order: state.workfees.order,
    orderMode: state.workfees.orderMode,
    deleted: state.workfees.deleted,
    deletedId: state.workfees.deletedId,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: workfeesActions.workfeesList,
      delete: workfeesActions.workfeesDelete,
      setLoading: workfeesActions.setLoading,
      reset: workfeesActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkfeesList);
