import React, { Fragment } from "react";

import Edit from "../../../../../Components/Edit/Edit";
import EditPage from "../../../../../Components/Extends/EditPage";
import Navbar from "../../Navbar";
import CashregisterForm from "./CashregisterForm";
//import CashregisterHistory from "./CashregisterHistory";
//import CashregisterMap from "./CashregisterMap";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Axios from "axios";
import authRules from "helpers/authRules";
import localize from "helpers/localize";
import { Link } from "react-router-dom";
import EditEvent from "../EditEvent";
import AnnualReviewHistory from "./AnnualReviewHistory";
import CashregisterActions from "./CashregisterActions";

class EditCashregister extends EditPage {
  constructor(props) {
    super(props);
    this.state = {
      eventModal: false,
      annualModal: false,
      history: {},
      canReregister: false
    };
  }

  componentDidMount() {
    this.loadAnnualHistory();
  }

  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("cashregisters", "Pénztárgép megtekintése");
    }
    return this.props.match.params.id
      ? localize("cashregisters", "Pénztárgép megtekintése")
      : localize("cashregisters", "Pénztárgép létrehozása");
  };

  handleGetFormInfo = () => {
    return this.props.getCustomInfo("manual", "cashregisters");
  };

  customHeaderButton = () => {
    return (
      <Fragment>
        <Button
          //variant="contained"
          color="primary"
          className="jr-btn jr-btn-label left jr-btn-sm"
          onClick={this.handleAddEventsModal.bind(this)}
        >
          {localize("cashregisters", "Esemény hozzáadása")}
        </Button>
        <Button
          //variant="contained"
          target="_blank"
          component={Link}
          to={`/worksheets/create?apn=${this.props.viewData &&
            this.props.viewData.apn}`}
          color="primary"
          className="jr-btn jr-btn-label left jr-btn-sm"
          //onClick={this.handleAddEventsModal.bind(this)}
        >
          {localize("cashregisters", "Új munkalap")}
        </Button>
        <Button
          color="primary"
          className="jr-btn jr-btn-label left jr-btn-sm"
          onClick={this.handleAddAnnualModal.bind(this)}
        >
          {localize("cashregisters", "Éves felülvizsgálatok")}
        </Button>
      </Fragment>
    );
  };

  handleAddEventsModal = () => {
    this.setState({
      eventModal: true
    });
  };

  handleCloseEvent = () => {
    this.setState({
      eventModal: false
    });
  };
  handleAddAnnualModal = () => {
    this.setState({
      annualModal: true
    });
  };

  handleCloseAnnual = () => {
    this.setState({
      annualModal: false
    });
  };

  loadAnnualHistory = async () => {
    const { data } = await Axios.get(
      `/v1/annual-reviews/history?cashregisterId=${this.props.match.params.id}`
    );
    if (data && data.success) {
      let canReregister = true;
      Object.keys(data.data || {}).forEach(year => {
        const item = data.data[year];
        if (item === false) {
          canReregister = false;
        }
      });
      this.setState({
        history: data.data || {},
        canReregister
      });
    }
  };

  render() {
    const isViewPage = this.props.match.params.id ? true : false;
    const { history, canReregister } = this.state;
    return (
      <Fragment>
        <Dialog
          open={this.state.eventModal}
          className="col-md-8 col-lg-6 col-12 modal-centered"
          onClose={this.handleCloseEvent.bind(this)}
          scroll="body"
        >
          <DialogTitle>
            {localize("cashregisters", "Esemény hozzáadása")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <EditEvent
                cashregister_id={this.props.match.params.id}
                forceCreateOnly={true}
                createSavedRedirect={this.handleCloseEvent.bind(this)}
                initalize={() => {
                  return false;
                }}
                {...this.props}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseEvent.bind(this)}
              variant="contained"
              color="secondary"
              component="span"
            >
              {localize("cashregisters", "Bezár")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.annualModal}
          className="col-md-8 col-lg-6 col-12 modal-centered"
          onClose={this.handleCloseAnnual.bind(this)}
          scroll="body"
        >
          <DialogTitle>
            {localize("cashregisters", "Éves felülvizsgálatok")}
          </DialogTitle>
          <DialogContent>
            <AnnualReviewHistory
              cashregister_id={this.props.match.params.id}
              items={history}
              onSetMissingSuccess={() => {
                this.loadAnnualHistory();
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseAnnual.bind(this)}
              variant="contained"
              color="secondary"
              component="span"
            >
              {localize("cashregisters", "Bezár")}
            </Button>
          </DialogActions>
        </Dialog>
        <Navbar {...this.props} />
        <Edit
          getPagetitle={this.getPageTitle}
          isViewPage={isViewPage}
          handleGetFormInfo={this.handleGetFormInfo.bind(this)}
          customHeaderButton={
            this.props.match.params.id
              ? this.customHeaderButton.bind(this)
              : null
          }
          disableViewClass={true}
          disableViewButton={true}
          cardBox={!isViewPage}
          {...this.props}
        >
          <CashregisterForm {...this.props} isViewPage={isViewPage} />
        </Edit>
        {isViewPage && (
          <Fragment>
            {/*<CashregisterHistory {...this.props} events={this.props.events}/>
                <CashregisterMap {...this.props}/>*/}
            {authRules.hasPermissionByKeyAndName(
              "cashregisters",
              "cashregisters.operating"
            ) && (
              <CashregisterActions
                {...this.props}
                canReregister={canReregister}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default EditCashregister;
