import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LostPassword from "./views/LostPassword";
import * as authActions from "../../../../redux/modules/auth";

const mapStateToProps = state => {
  return {
    failed: state.auth.failed,
    success: state.auth.success,
    loading: state.auth.loading,
    message: state.auth.message,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reset: authActions.reset,
      resetForm: authActions.resetForm,
      lostPassword: authActions.lostPassword
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LostPassword);
