import React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import HomeIcon from "@material-ui/icons/Home";
import { withStyles } from "@material-ui/core/styles";
import restHelper from "helpers/restHelper";
import Tags from "./Tags";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { IconButton } from "@material-ui/core";
import localize from "helpers/localize";

const sidebarMenuStyle = theme => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0
  },
  listRoot: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: 0,
    width: 300
  },
  adminListRoot: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: 0
    //width: 600
  },

  itemText: {
    display: "flex",
    flexWrap: "wrap",
    flex: "1 1",
    maxWidth: 240,
    "& > span": {
      lineHeight: "14px!important",
      fontSize: "14px!important"
    },
    alignItems: "center"
    //padding: 0
  },
  itemContainerOpened: {
    //backgroundColor: "#ddd",
    webkitUserSelect: "none",
    mozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    "& > div > span": {
      color: theme.palette.primary.main + "!important"
    },
    "& > a > div > span": {
      color: theme.palette.primary.main + "!important"
    },
    "& > div > svg": {
      fill: theme.palette.primary.main + "!important"
    },
    "& > a > div > svg": {
      fill: theme.palette.primary.main + "!important"
    }
  },
  itemContainerOpenedLeaf: {
    backgroundColor: theme.palette.primary.main + "!important",
    "& > div > span": {
      color: theme.palette.primary.contrastText + "!important"
    },
    "& > a > div > span": {
      color: theme.palette.primary.contrastText + "!important"
    },
    "& > div > svg": {
      fill: theme.palette.primary.contrastText + "!important"
    },
    "& > a > div > svg": {
      fill: theme.palette.primary.contrastText + "!important"
    },
    cursor: "default",
    "&:hover": {
      backgroundColor: theme.palette.primary.main + "!important"
    }
  },
  collapseContainer: {
    width: "100%"
  },
  itemLink: {
    display: "flex",
    flexFlow: "row wrap",
    alignContent: "space-between",
    justifyContent: "space-between",
    alignItems: "center",
    textDecoration: "none",
    paddingTop: "13px!important",
    paddingBottom: "13px!important"
  },
  itemContainer: {
    display: "flex",
    flex: "1 1",
    color: "inherit!important",
    /*flexFlow: "row wrap",
    alignContent: "space-between",
    justifyContent: "space-between",*/
    verticalAlign: "middle",
    padding: 0,
    borderTop: "solid 1px #ddd",
    //marginTop: -8,
    //marginBottom: -10,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    "&:last-child": {
      //borderTop: "none",
    },
    "&:hover": {
      backgroundColor: theme.palette.grey[300]
    },
    "& > div > svg": {
      fill: theme.palette.text.secondary
    },
    "& > a > div > svg": {
      fill: theme.palette.text.secondary
    }
  },
  item: {
    display: "flex",
    flexFlow: "row wrap",
    alignContent: "space-between",
    justifyContent: "space-between",
    padding: "0px!important",

    //borderTop: "solid 1px #ddd",
    /*"&:nth-child(1)": {
      borderTop: "none",
    }*/
    "& $item": {
      "& $itemContainer": {
        paddingLeft: 25
      },
      "& $item": {
        "& $itemContainer": {
          paddingLeft: 40
        },
        "& $item": {
          "& $itemContainer": {
            paddingLeft: 55
          },
          "& $item": {
            "& $itemContainer": {
              paddingLeft: 70
            }
          }
        }
      }
    }
  },
  itemIcon: {
    marginRight: 0
  },
  collapseButton: {
    padding: 0,
    marginRight: 4
  },
  collapseIconSelected: {
    color: "white"
  },
  adminTags: {
    marginTop: -8,
    marginBottom: -8,
    height: 19,
    alignSelf: "center",
    "&>div": {
      marginTop: 0,
      marginBottom: 0
    }
  }
});

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      opened: {},
      selected: {},
      ...this.getSelected()
    };
  }

  componentDidMount() {
    //console.log("Menu.componentDidMount");
    restHelper.index("shop/menu", undefined, false).then(response => {
      //console.log("Menu.index", response.data.items);
      this.setState({
        items: [
          {
            title: localize("shop", "Minden termék"),
            root: true,
            tags: [],
            items: response.data.items
          }
        ]
      });
    });
  }

  getSelected = () => {
    return this.props.selected;
  };

  UNSAFE_componentWillReceiveProps() {
    /*const opened = this.getSelected();
    this.setState({
      opened: opened.opened,
      selected: opened.selected
    });*/
  }

  /*
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
*/
  openCollapse = (id, item, parents, evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    const opened = Object.assign({}, this.state.opened);
    if (opened[id]) {
      delete opened[id];
    } else {
      opened[id] = true;
    }
    //selected[id] = !selected[id];
    this.setState({ opened }, () => {
      if (this.props.onClick) {
        this.props.onClick(this.state);
      }
    });
  };

  onClick = (id, item, parents, evt) => {
    evt.stopPropagation();
    //const selected = Object.assign({}, this.state.selected);
    const selected = { id, parents };
    //Csoport kinyitása selecten
    const opened = Object.assign({}, this.state.opened);
    if (opened[id]) {
      delete opened[id];
    } else {
      opened[id] = true;
    }
    //TODO: itt kéne bezárni az azonos szintűeket
    let temparr = [];
    const pars = parents
      .slice()
      .reverse()
      .map(p => {
        temparr = temparr.concat(p.tags);
        //console.log(temparr.join("|"));
        return temparr.join("|");
      });
    //console.log("pars", pars, opened);
    let opened2 = {};
    Object.keys(opened).forEach(open => {
      if (open === id) {
        opened2[open] = true;
      }
      if (Array.isArray(pars) && pars.indexOf(open) >= 0) {
        opened2[open] = true;
      }
    });

    this.setState({ selected, opened: opened2 }, () => {
      if (this.props.onClick) {
        this.props.onClick(this.state, true);
      }
    });
  };

  getMnuItems = (items, path = "", par = []) => {
    const { classes, isAdmin /*, color*/ } = this.props;

    return items.map((item, key) => {
      //console.log(item.tags);
      const slug =
        path === "" ? item.tags.join("|") : `${path}|${item.tags.join("|")}`;
      let parents = [];
      parents.unshift(item);
      parents = parents.concat(par);
      return item.items && item.items.length > 0 ? (
        <ListItem
          key={key}
          className={classes.item}
          style={{ paddingRight: 0, cursor: "pointer" }}
        >
          {/*<Link to={to} className={classes.itemLink} >*/}
          <div
            className={
              (item.root
                ? classes.itemLink + " " + classes.itemContainer
                : classes.itemContainer) +
              (this.state.opened[slug] || item.root
                ? " " + classes.itemContainerOpened
                : "") +
              (this.state.selected.id === slug
                ? " " + classes.itemContainerOpenedLeaf
                : "")
            }
            //onClick={event => this.openCollapse(slug, item, parents, event)}
            onClick={event => this.onClick(slug, item, parents, event)}
          >
            {/*<ListItemIcon className={classes.itemIcon}>
              {this.state.opened[slug] ? <FolderOpenIcon /> : <FolderIcon />}
          </ListItemIcon>*/}
            <ListItemText
              primary={item.title}
              /*secondary={}*/
              //disableTypography={true}
              className={classes.itemText}
            />
            {isAdmin && (
              <div style={{ flex: 1 }}>
                <Tags tags={item.tags} /*rootClassName={classes.adminTags}*/ />
              </div>
            )}
            <IconButton
              className={classes.collapseButton}
              onClick={event => this.openCollapse(slug, item, parents, event)}
            >
              {!item.root &&
                (this.state.opened[slug] ? (
                  <ExpandLess
                    className={
                      this.state.selected.id === slug
                        ? classes.collapseIconSelected
                        : ""
                    }
                  />
                ) : (
                  <ExpandMore
                    className={
                      this.state.selected.id === slug
                        ? classes.collapseIconSelected
                        : ""
                    }
                  />
                ))}
            </IconButton>
          </div>
          {/*</Link>*/}
          <Collapse
            in={this.state.opened[slug] || item.root}
            unmountOnExit
            className={classes.collapseContainer}
          >
            <List className={classes.list}>
              {this.getMnuItems(item.items, slug, parents)}
            </List>
          </Collapse>
        </ListItem>
      ) : (
        <ListItem key={key} className={classes.item}>
          <div
            /*className={classes.itemLink}*/ className={
              classes.itemLink +
              " " +
              classes.itemContainer +
              (this.state.selected.id === slug
                ? " " +
                  classes.itemContainerOpened +
                  " " +
                  classes.itemContainerOpenedLeaf
                : "")
            }
            onClick={event => this.onClick(slug, item, parents, event)}
          >
            {item.icon && (
              <ListItemIcon className={classes.itemIcon}>
                <item.icon />
              </ListItemIcon>
            )}
            <ListItemText
              primary={item.title}
              //disableTypography={true}
              className={classes.itemText}
            />
            {isAdmin && (
              <div style={{ flex: 1 }}>
                <Tags tags={item.tags} /*rootClassName={classes.adminTags}*/ />
              </div>
            )}
          </div>
        </ListItem>
      );
    });
  };

  render() {
    const {
      classes,
      isAdmin,
      /*color, items*/ onHomeClick,
      isHome
    } = this.props;
    const { items } = this.state;
    return (
      <List className={isAdmin ? classes.adminListRoot : classes.listRoot}>
        {!isAdmin && (
          <ListItem className={classes.item}>
            <div
              style={{ cursor: "pointer" }}
              /*className={classes.itemLink}*/ className={
                classes.itemContainer +
                (isHome
                  ? " " +
                    classes.itemContainerOpened +
                    " " +
                    classes.itemContainerOpenedLeaf
                  : "")
              }
              onClick={onHomeClick}
            >
              <ListItemIcon className={classes.itemIcon}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary={localize("shop", "Nyitólap")}
                //disableTypography={true}
                style={{ maxWidth: 280, paddingLeft: 8 }}
                className={classes.itemText}
              />
            </div>
          </ListItem>
        )}
        {this.getMnuItems(items)}
      </List>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  selected: PropTypes.object,
  isAdmin: PropTypes.bool,
  isHome: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose"
  ]),
  onClick: PropTypes.func,
  onHomeClick: PropTypes.func
};

export default withStyles(sidebarMenuStyle, { withTheme: true })(Menu);
