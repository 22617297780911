/* eslint-disable */
import React, {Fragment, PureComponent} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import { get } from 'lodash';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from '../../../../../assets/img/prior_logo.png';
import localize from "helpers/localize";


class LostPassword extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            email_error: false,
            error: false
        };
    }

    componentDidMount() {
      localStorage.removeItem("access_token");
    }

    componentWillMount = () => {
        this.props.reset();
    };

    componentWillUnmount = () => {
        this.props.reset();
    };

    handleInputKeypress = evt => {
      let keyCode = evt.which || evt.keyCode;
      if (keyCode === 13) {
        //Enter
        this.handleSubmit();
      }
    };

    handleSubmit = () => {
        this.props.reset();

        this.setState({
            email_error: false,
            error: false
        });

        let error, email_error;

        if (this.state.email === '') {
            error = true;
            email_error = localize("user", "Az email cím kötelező");
        }


        if (error) {
            this.setState({
                email_error,
                error
            });
        }
        else {
            this.props.lostPassword(
                this.state.email
            );
        }
    };

    render() {
        return (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <Helmet>
                    <title>{localize("user", "Jelszó visszaállítás")}</title>
                </Helmet>
                <div className="app-login-main-content">
                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link to="/" className="logo-lg">
                            <img src={logo} alt="" title="" width="177" />
                        </Link>
                    </div>
                    <div className="app-login-content">
                        <div className="app-login-header mb-4"><h1><span>{localize("user", "Jelszó visszaállítás")}</span></h1></div>

                        {this.props.success && <Fragment>
                            <div className="shadow-lg alert alert-success fade show" role="alert">{localize("user", "Jelszó visszaállító email elküldve")}</div>
                        </Fragment>}
                        {!this.props.success && <Fragment>
                            {this.props.failed && <div className="bg-danger shadow-lg text-white alert alert-success fade show" role="alert">{get(this.props, 'message',false) ? get(this.props, 'message') : localize("basic", "Sikertelen művelet")}</div>}
                            <div className="app-login-form">
                                <div>
                                    <fieldset>
                                        <TextField
                                            label={localize("user", "Email")}
                                            fullWidth
                                            onChange={(event) => this.setState({email: event.target.value})}
                                            defaultValue={this.state.email}
                                            margin="normal"
                                            className="mt-1 my-sm-3"
                                            helperText={this.state.email_error}
                                            error={this.state.email_error ? true : false}
                                            onKeyPress={this.handleInputKeypress}
                                        />
                                    </fieldset>
                                </div>
                            </div>
                        </Fragment>}
                        <div className="mb-3 d-flex align-items-center justify-content-between">
                            {!this.props.success && <Button disabled={this.props.loading} onClick={this.handleSubmit.bind(this)} variant="contained" color="primary">
                                {localize("basic", "Küldés")}
                            </Button>}

                            <Link to="/">
                                {localize("user", "Vissza a bejelentkezésre")}
                            </Link>
                        </div>
                    </div>
                </div>
                {this.props.loading && <div className="loader-view"><CircularProgress/></div>}
            </div>
        );
    }
}

export default LostPassword;