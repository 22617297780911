import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get } from "lodash";
import withRouter from "react-router/es/withRouter";

class PagePermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAccess: false
    };
  }

  componentWillMount = () => {
    let isAccess = false;
    if (this.props.user.root) {
      isAccess = true;
    } else {
      const urls = this.props.match.path.split("/"),
        module = this.props.module,
        perms = get(this.props.permissions, module, false);

      if (perms) {
        const access = get(perms, "access");
        if (access.indexOf("VIEW") !== -1) {
          isAccess = true;
        }
        if (access.indexOf("VIEW_OWN") !== -1) {
          isAccess = true;
        }
        if (urls[2] === "create" || urls[2] === "edit") {
          if (
            access.indexOf("MODIFY") !== -1 ||
            access.indexOf("MODIFY_OWN") !== -1
          ) {
            isAccess = true;
          } else {
            isAccess = false;
          }
        }
      }
    }
    this.setState({
      isAccess
    });
    if (!isAccess) {
      this.props.history.push("/");
    }
  };

  render = () => {
    return (
      <Fragment>
        {this.state.isAccess &&
          React.cloneElement(this.props.children, {
            module: this.props.module
          })}
      </Fragment>
    );
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    permissions: state.auth.permissions
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PagePermission));
