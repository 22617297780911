import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MyExamsList from "./views/MyExamsList";
import * as myExamsActions from "../../../redux/modules/myExams";
import * as optionsActions from "../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.myExams.loading,
    success: state.myExams.success,
    message: state.myExams.message,
    data: state.myExams.data,
    total: state.myExams.total,
    limit: state.myExams.limit,
    page: state.myExams.page,
    order: state.myExams.order,
    orderMode: state.myExams.orderMode,
    deleted: state.myExams.deleted,
    deletedId: state.myExams.deletedId,
    token: state.myExams.token,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: myExamsActions.examsList,
      downloadExam: myExamsActions.downloadExam,
      delete: myExamsActions.examDelete,
      setLoading: myExamsActions.setLoading,
      reset: myExamsActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyExamsList);
