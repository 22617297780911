import axios from "axios";
import MobileDetect from "mobile-detect";
import errorResponseHandler from "helpers/errorResponseHandler";
import { setupCache } from "axios-cache-adapter";
import { getLocale } from "helpers/localize";
// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  ignoreCache: true,
  exclude: {
    // {Array} List of regular expressions to match against request URLs.
    //paths: [],
    // {Boolean} Exclude requests with query parameters.
    query: false
    // {Function} Method which returns a `Boolean` to determine if request
    // should be excluded from cache.
    //filter: null
  }
});

let md = new MobileDetect(window.navigator.userAgent);
let category = "Desktop";
if (md.mobile()) {
  category = "Mobile";
} else if (md.tablet()) {
  category = "Tablet";
}

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: process.env.REACT_APP_HOST + "/v1/",
  headers: {
    //Authorization: localStorage.getItem("access_token"),
    //"X-Locale": "hu-HU",
    "X-Locale": getLocale(),
    "X-Device-Category": category,
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json; charset=UTF-8"
  },
  adapter: cache.adapter
});

// apply interceptor on response
client.interceptors.response.use(response => response, errorResponseHandler);
/**
 * Request Wrapper with default success/error actions
 */
const requestHelper = function(options) {
  const onSuccess = function(response) {
    return response;
  };

  const onError = function(error) {
    if (error.response) {
      if (error.response.status === 401) {
        //hanndle logout
      }
    } else {
      // Something else happened while setting up the request
      // triggered the error
      //console.error("Error Message:", error.message);
    }
    return Promise.reject(error.response || error.message);
  };
  options.url = decodeURI(options.url).replace(new RegExp("%2C", "g"), ",");
  if (!options.headers) {
    options.headers = {};
  }
  options["Content-Type"] = "application/json; charset=UTF-8";
  options.headers.Authorization = localStorage.getItem("access_token");
  if (!options.data) {
    options.data = {};
  }
  return client(options)
    .then(onSuccess)
    .catch(onError);
};

export default requestHelper;
