/* eslint-disable */
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
//import {toastr} from "../../helpers/CustomToastr";
import { toastr } from "../../helpers/CustomToastr";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { destroy } from "redux-form";
import GenericForm from "../Forms/GenericForm";
import { get } from "lodash";
import * as optionsActions from "../../redux/modules/options";
import CardBox from "../CardBox";
import ContainerHeader from "../ContainerHeader";
import classNames from "classnames";
import { CircularProgress, Grid } from "@material-ui/core";
import localize from "helpers/localize";

class Edit extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    model: PropTypes.string.isRequired,
    commentThread: PropTypes.string,
    settingModalTitle: PropTypes.string,
    message: PropTypes.string,
    saved: PropTypes.bool,
    isViewPage: PropTypes.bool.isRequired,
    isLocked: PropTypes.bool,
    disableSettings: PropTypes.bool,
    reset: PropTypes.func.isRequired,
    view: PropTypes.func,
    update: PropTypes.func,
    create: PropTypes.func,
    getPagetitle: PropTypes.func,
    handleSubmit: PropTypes.func,
    initalize: PropTypes.func,
    createSavedRedirect: PropTypes.func,
    reducerName: PropTypes.string,
    children: PropTypes.node.isRequired,
    neverUseRaw: PropTypes.bool,
    alwaysUseRaw: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    if (!this.props.isModalMode && Edit.propTypes) {
      Edit.propTypes.history = PropTypes.object.isRequired;
    }

    this.state={
      initialLoading: props.match && props.match.params.id ? true : false
    }
  }

  componentWillMount = () => {
    if (!this.props.disabledReset) {
      if (this.props.resetForm) {
        this.props.resetForm();
      }
    }

    let getFormInfo = this.props.handleGetFormInfo
      ? this.props.handleGetFormInfo()
      : this.props.getFormInfo(
        this.props.optionsKey ? this.props.optionsKey : get(this.props, "location.pathname", window.location.pathname).split(
          "/"
        )[1]
      );

    getFormInfo.then(r => {
      if (
        r.type === "FORM_SETTING_SUCCESS" ||
        r.type === "STICKERS_FORM_SETTING_SUCCESS"
      ) {
        if (this.props.match.params.id) {
          this.loadContent();
        } else if (this.props.initalize) {
          this.props.initalize();
        }
      }
    });
  };

  loadContent = () => {
    if (this.props.initalize) {
      this.props.initalize();
    } else {
      this.props.view(
        (this.props.alwaysUseRaw ? "raw/" : (this.props.isViewPage || this.props.neverUseRaw ? "" : "raw/")) + this.props.match.params.id
      );
    }
  };

  handleSubmit = formData => {
    return this.props.handleSubmit
      ? this.props.handleSubmit(formData)
      : this.props.match.params.id && !this.props.forceCreateOnly
      ? this.props
          .update(this.props.model, this.props.match.params.id, formData)
          .then(res => {
            this.props.afterSave && this.props.afterSave(res);
            return res;
          })
      : this.props.create(this.props.model, formData).then(res => {
          this.props.afterSave && this.props.afterSave(res);
          return res;
        });
  };

  componentWillUnmount = () => {
    //Form reset a komponens megszűnésekor. A redux formnál ki van kapcsolvan a destroyOnUnmount
    if (!this.props.disabledReset) {
      //this.props.reset();
      /*
        if(this.props.resetForm) {
          this.props.resetForm();
        }
*/
      if (this.props.reducerName) {
        this.props.destroyForm(this.props.reducerName);
      }
    }
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.saved && nextProps.saved) {
      toastr.success(localize("basic", "Sikeres művelet"));
      if (!this.props.match.params.id || this.props.forceCreateOnly) {
        this.props.createSavedRedirect
          ? this.props.createSavedRedirect(nextProps.id)
          : this.props.components
            ? this.props.history.push(
              `/${this.props.components}/edit/` + nextProps.id
            )
            : this.props.history.push(
              `/${window.location.pathname.split("/")[1]}/edit/` + nextProps.id
            );
      }
    }
    /*if (!this.props.message && nextProps.message) {
      toastr.error(nextProps.message);
    }*/
    //View és form esetén, ha betöltendő adat van, akkor a kezdeti betöltést kikapcsoljuk
    if(this.state.initialLoading && this.props.loading === true && nextProps.loading === false) {
      this.setState({initialLoading: false});
    }
  };

  render() {
    const cardBoxClassname = classNames({
      isViewPage: this.props.isViewPage && !this.props.disableViewClass,

    }, this.props.cardBoxStyle);

    return (
      <div
        className="animated slideInUpTiny animation-duration-3"
        //key={this.props.isViewPage ? "isViewPage" : ""} //TODO: less render
      >
        {this.props.getPagetitle && (
          <Helmet>
            <title>{this.props.getPagetitle()}</title>
          </Helmet>
        )}
        {this.props.getPagetitle && (
          <ContainerHeader
            title={this.props.getPagetitle()}
            customHeaderButton={this.props.customHeaderButton}
          />
        )}
        {this.props.cardBox ? (
          <div style={{position: "relative"}}>
            {this.state.initialLoading && <div style={{position: "absolute", backgroundColor: "white", top: 0, left: 0, right: 0, bottom:0, zIndex:5000}}>
              <div  style={{ textAlign: "center", marginTop: 32 }}>
              <CircularProgress />
              </div>
            </div>}
          <CardBox
            styleName={cardBoxClassname}
            cardStyle="p-4"
          >
            <Fragment>
              {!this.props.failed && this.props.baseFields && (
                <GenericForm
                  onSubmit={this.handleSubmit.bind(this)}
                  fieldErrors={this.props.fieldErrors}
                  key={
                    this.props.isViewPage
                      ? "isViewPageGenericform"
                      : "isViewPageGenericform"
                  }
                >
                  {React.cloneElement(this.props.children, {
                    isViewPage: this.props.isViewPage,
                    ...this.props
                  })}
                </GenericForm>
              )}
            </Fragment>
          </CardBox>
          </div>
        ) : (
          <Fragment>
            {!this.props.failed && this.props.baseFields && (
              <GenericForm
                onSubmit={this.handleSubmit.bind(this)}
                fieldErrors={this.props.fieldErrors}
                key={
                  this.props.isViewPage
                    ? "isViewPageGenericform"
                    : "isViewPageGenericform"
                }
              >
                {React.cloneElement(this.props.children, {
                  isViewPage: this.props.isViewPage,
                  ...this.props
                })}
              </GenericForm>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      getFormInfo: optionsActions.getFormInfo,
      destroyForm: destroy
    },
    dispatch
  );
};
Edit.defaultProps = {
  cardBox: true,
  initialValues: {},
  neverUseRaw: false
};

export default connect(
  null,
  mapDispatchToProps
)(Edit);
