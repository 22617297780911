import React, { Fragment } from "react";
import PropTypes from "prop-types";
import restHelper from "helpers/restHelper";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Grid,
  withStyles,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import Select from "Components/New/Input/Select/Select";
import TextField from "Components/New/Input/TextField/TextField";
import Image from "Components/New/Image";
import { get } from "lodash";
import formatHelper from "helpers/formatHelper";
import localize from "helpers/localize";

const styles = theme => ({
  actionCell: {
    width: 40,
    textAlign: "right"
  },
  table: {
    "& td": {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 2,
      paddingRight: 2
    },
    "& th": {
      paddingLeft: 2,
      paddingRight: 2
    },
    "& td:last-child": {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 2,
      paddingRight: 2
    },
    "& th:last-child": {
      paddingLeft: 2,
      paddingRight: 2
    },
    "& tr:last-child td": {
      borderBottom: "none"
    }
  },
  qtyCell: {
    width: 80
  },
  priceCell: {
    width: 120
  },
  typeCell: {
    width: 200
  },
  itemPriceCell: {
    width: 100
  },
  sumCell: {
    fontWeight: "bold"
  }
});

class WorksheetBillingDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      data,
      baseFields,
      addNewWorkfee,
      onSelectWorkfee,
      onChangeWorkfeeQty,
      onChangeWorkfeePrice,
      onSelectWorkfeeType,
      deleteWorkfee,
      addNewProduct,
      onSelectProduct,
      onChangeProductQty,
      onChangeProductPrice,
      deleteProduct
    } = this.props;

    let summFees = 0;
    let summProducts = 0;
    return (
      <Fragment>
        <Grid item xs={12}>
          <Typography variant="button">
            {localize("worksheets", "Munkadíjak")}
          </Typography>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{localize("worksheets", "Megnevezés")}</TableCell>
                <TableCell className={classes.qtyCell}>
                  {localize("worksheets", "Mennyiség")}
                </TableCell>
                <TableCell className={classes.priceCell}>
                  {localize("worksheets", "Egységár")}
                </TableCell>
                <TableCell>{localize("worksheets", "Típus")}</TableCell>
                <TableCell align="right" className={classes.itemPriceCell}>
                  {localize("worksheets", "Ár")}
                </TableCell>
                <TableCell className={classes.actionCell}>
                  <IconButton color="primary" onClick={addNewWorkfee}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.workfees &&
                data.workfees.map((item, index) => {
                  summFees +=
                    parseFloat(item.price || 0) *
                    parseFloat(item.quantity || 0);
                  return (
                    <TableRow key={`workfee-${index}`}>
                      <TableCell>
                        <Select
                          menuPosition="fixed"
                          type="autocomplete"
                          url="worksheets/workfees"
                          fullWidth
                          onChange={onSelectWorkfee(index)}
                          labelKey="name"
                          valueKey="name"
                          value={item.name}
                          defaultOptions
                        />
                      </TableCell>
                      <TableCell className={classes.qtyCell}>
                        <TextField
                          fullWidth
                          value={item.quantity}
                          onChanged={onChangeWorkfeeQty(index)}
                        />
                      </TableCell>
                      <TableCell className={classes.priceCell}>
                        <TextField
                          fullWidth
                          value={item.price}
                          onChanged={onChangeWorkfeePrice(index)}
                        />
                      </TableCell>
                      <TableCell className={classes.typeCell}>
                        <Select
                          fullWidth
                          onChange={onSelectWorkfeeType(index)}
                          options={get(baseFields, "workfee_type.data", {})}
                          value={item.type}
                        />
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.itemPriceCell}
                      >
                        {formatHelper.currenyFormat(
                          parseFloat(item.price || 0) *
                            parseFloat(item.quantity || 0)
                        )}
                      </TableCell>
                      <TableCell className={classes.actionCell}>
                        <IconButton
                          onClick={deleteWorkfee(index)}
                          color="primary"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow>
                <TableCell
                  colSpan={5}
                  align="right"
                  className={classes.sumCell}
                >
                  {localize("worksheets", "Összesen")}:{" "}
                  {formatHelper.currenyFormat(summFees)}
                </TableCell>
                <TableCell className={classes.actionCell} />
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="button">
            {localize("worksheets", "Termékek")}
          </Typography>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{localize("worksheets", "Megnevezés")}</TableCell>
                <TableCell className={classes.qtyCell}>
                  {localize("worksheets", "Mennyiség")}
                </TableCell>
                <TableCell className={classes.priceCell}>
                  {localize("worksheets", "Egységár")}
                </TableCell>
                <TableCell align="right" className={classes.itemPriceCell}>
                  {localize("worksheets", "Ár")}
                </TableCell>
                <TableCell className={classes.actionCell}>
                  <IconButton color="primary" onClick={addNewProduct}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.shop_products &&
                data.shop_products.map((item, index) => {
                  summProducts +=
                    parseFloat(item.price || 0) *
                    parseFloat(item.quantity || 0);
                  return (
                    <TableRow key={`shop-product-${index}`}>
                      <TableCell>
                        <Select
                          menuPosition="fixed"
                          type="autocomplete"
                          url="worksheets/shop-products"
                          requestParams={{ worksheetId: data.id }}
                          fullWidth
                          onChange={onSelectProduct(index)}
                          labelKey="name"
                          valueKey="name"
                          restHelperOptions={false}
                          value={item.name}
                          defaultOptions
                          getOptionLabel={option => {
                            return (
                              <Fragment>
                                <div className="hidden-on-list">
                                  {option.name}
                                </div>
                                <ListItem
                                  className="hidden-on-selected"
                                  component="div"
                                  style={{
                                    marginLeft: "-1.5rem",
                                    marginRight: "-1.5rem",
                                    marginTop: "-0.25rem",
                                    marginBottom: "-0.25rem",
                                    textDecoration: "none!important"
                                  }}
                                >
                                  <ListItemAvatar>
                                    <Avatar>
                                      <Image
                                        fileId={option.image_id}
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "100%"
                                        }}
                                      />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={option.name}
                                    secondaryTypographyProps={{
                                      className: "hidden-on-selected"
                                    }}
                                    secondary={option.tags && option.tags.join(", ")}
                                  />
                                </ListItem>
                              </Fragment>
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell className={classes.qtyCell}>
                        <TextField
                          fullWidth
                          value={item.quantity}
                          onChanged={onChangeProductQty(index)}
                        />
                      </TableCell>
                      <TableCell className={classes.priceCell}>
                        <TextField
                          fullWidth
                          value={item.price}
                          onChanged={onChangeProductPrice(index)}
                        />
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.itemPriceCell}
                      >
                        {formatHelper.currenyFormat(
                          parseFloat(item.price || 0) *
                            parseFloat(item.quantity || 0)
                        )}
                      </TableCell>
                      <TableCell className={classes.actionCell}>
                        <IconButton
                          onClick={deleteProduct(index)}
                          color="primary"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow>
                <TableCell
                  colSpan={4}
                  align="right"
                  className={classes.sumCell}
                >
                  {localize("worksheets", "Összesen")}:{" "}
                  {formatHelper.currenyFormat(summProducts)}
                </TableCell>
                <TableCell className={classes.actionCell} />
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="h6" align="right">
            {localize("worksheets", "Számla végösszege")}:{" "}
            {formatHelper.currenyFormat(summProducts + summFees)}
          </Typography>
        </Grid>
      </Fragment>
    );
  }
}

WorksheetBillingDesktop.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  baseFields: PropTypes.object.isRequired,
  addNewWorkfee: PropTypes.func.isRequired,
  onSelectWorkfee: PropTypes.func.isRequired,
  onChangeWorkfeeQty: PropTypes.func.isRequired,
  onChangeWorkfeePrice: PropTypes.func.isRequired,
  onSelectWorkfeeType: PropTypes.func.isRequired,
  deleteWorkfee: PropTypes.func.isRequired,
  addNewProduct: PropTypes.func.isRequired,
  onSelectProduct: PropTypes.func.isRequired,
  onChangeProductQty: PropTypes.func.isRequired,
  onChangeProductPrice: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired
};

export default withStyles(styles)(WorksheetBillingDesktop);
