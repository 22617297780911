import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getFormValues } from "redux-form";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { get } from "lodash";

import * as autocompleteActions from "../../../redux/modules/autocomplete";
import Wrapper from "./Wrapper";
import inputStyle from "./inputStyle.js";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import axios from "axios";
import localize from "helpers/localize";

class Autocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: "",
      isLoading: false,
      options: [],
      selected: [],
      error: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const val = get(
      nextProps,
      `formValues.${nextProps.input.name}_autocomplete`
    );
    const selected =
      nextProps.mode === "select" && typeof val === "object" ? [val] : null;
    if (selected) {
      //console.log("recive", selected);
      this.setState({
        selected: selected ? selected : []
      });
    }
  }
  handleSearch = keyword => {
    let { url, isTag } = this.props;

    let params = "";
    if (url && typeof url === "object") {
      if (url.params && typeof url.params === "object") {
        params = Object.keys(url.params)
          .map(key => key + "=" + url.params[key])
          .join("&");
      }
      url = url.path;
    }

    this.setState({ isLoading: true });
    //this.props.onChange && this.props.onChange(keyword);
    if (url.indexOf("?") >= 0) {
      url += "&";
    } else {
      url += "?";
    }

    axios
      .get(url + "keyword=" + keyword + (params && "&" + params))
      .then(response => {
        //let options = get(response, "data.data", []);
        let options = [],
          data = get(response, "data.data", []);
        data = data ? data : [];
        //console.log("data", data);
        if (isTag) {
          data.forEach(v => {
            options.push({ id: v, text: v });
          });
        } else if (typeof data === "object") {
          Object.keys(data).forEach(key => {
            options.push(data[key]);
          });
        } else {
          options = data;
        }

        //console.log("options", options);
        this.setState({ isLoading: false, options });
      })
      .catch(error => {
        //console.log("error", error);
        this.setState({
          isLoading: false,
          error: error.response ? error.response.data.message : error.message
        });
      });
  };

  handleBlur = evt => {
    const { input, labelKey, valueKey, mode } = this.props;
    const { selected } = this.state;
    const selectedItem = selected && selected.length > 0 ? selected[0] : null;
    if (mode === "autocomplete") {
      evt.target.value = selectedItem ? selectedItem[labelKey] : null;
    } else {
      evt.target.value = selectedItem
        ? valueKey
          ? selectedItem[valueKey]
          : parseInt(selectedItem["id"])
        : null;
    }
    return input.onBlur(evt);
  };

  handleChange = selected => {
    const { mode, onSelect, labelKey, valueKey, input } = this.props;
    const { name } = input;
    const selectedItem = selected && selected.length > 0 ? selected[0] : null;

    if (mode === "autocomplete") {
      const value = selectedItem ? selectedItem[labelKey] : null;

      const evt = { target: { value: value } };
      //console.log(input);
      input.onChange(evt);
      //onChange(evt);
      onSelect && onSelect(selectedItem, name);
    } else {
      const value = selectedItem
        ? valueKey
          ? selectedItem[valueKey]
          : parseInt(selectedItem["id"])
        : null;
      onSelect && onSelect(value, name, selectedItem, name + "_autocomplete");
    }
    this.setState({ selected: selected });
  };

  render() {
    const {
      classes,
      input,
      label,
      placeholder,
      disabled,
      minChars,
      delay,
      labelKey,
      fieldError,
      meta,
      mode,
      renderMenuItemChildren,
      formValues,
      isViewPage,
      fillDefaultValue,
      valueKey,
      showInTable,
      isRequired
    } = this.props;
    const { name, value } = input;
    const { options, selected, isLoading } = this.state;
    const error = fieldError || (meta.touched && (meta.error || meta.warning));
    //console.log(input.name, input.value);
    const wrapperValue = isViewPage ? formValues[`${input.name}_text`] : value;
    const defaultValue =
      fillDefaultValue && selected.length === 0
        ? typeof input.value === "object"
          ? input.value.target.vale
          : input.value
        : undefined;
    //console.log("selected", selected);
    return (
      <Wrapper {...this.props} value={wrapperValue}>
        <div
          className={
            showInTable
              ? classes.bootstrapTypeHeadLabelTable
              : classes.bootstrapTypeHeadLabel
          }
          style={disabled ? { color: "rgba(0, 0, 0, 0.38)" } : {}}
        >
          {label}
          {isRequired ? " *" : ""}
        </div>
        <AsyncTypeahead
          id={`autocomplete_${name}`}
          inputProps={{
            onDragStart: input.onDragStart,
            onDrop: input.onDrop,
            autoComplete: "off",
            style: disabled ? { color: "rgba(0, 0, 0, 0.38)" } : {}
          }}
          {...(mode === "autocomplete"
            ? {
                onInputChange: input.onChange,
                onBlur: input.onBlur
              }
            : { onBlur: this.handleBlur, selected: selected })}
          onFocus={evt => {
            if (this.props.loadOnFocus) {
              this.handleSearch("");
            }
            input.onFocus(evt);
          }}
          isLoading={isLoading}
          defaultInputValue={defaultValue}
          //bodyContainer
          positionFixed
          allowNew={false}
          options={options}
          disabled={disabled || defaultValue ? true : false}
          multiple={false}
          minLength={minChars}
          delay={delay}
          labelKey={labelKey || name}
          valueKey={valueKey || "id"}
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          emptyLabel={localize("basic", "Nem található")}
          isValid={error ? true : false}
          searchText={localize("basic", "Keresés")}
          promptText={localize("basic", "Gépeljen a kereséshez...")}
          placeholder={placeholder}
          renderMenuItemChildren={
            renderMenuItemChildren
              ? renderMenuItemChildren
              : (option, props) => {
                  return <div>{option[labelKey || name]}</div>;
                }
          }
          filterBy={(option, props) => {
            //Remove client filter totally
            return true;
          }}
          className={classes.bootstrapTypheadInput}
          /*renderMenu={(results, menuProps) => (
            <ul {...menuProps}>
              {results.map((result, index) => (
                <li option={result} position={index}>
                  {result.label}
                </li>
              ))}
            </ul>
          )}*/
        />
        {error && <p className="helper-text">{error}</p>}
      </Wrapper>
    );
  }
}

Autocomplete.defaultProps = {
  minChars: 3,
  delay: 1000,
  mode: "autocomplete"
};

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  placeholder: PropTypes.string,
  fieldError: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
  minChars: PropTypes.number,
  delay: PropTypes.number,
  onSelect: PropTypes.func,
  //onChange: PropTypes.func,
  renderMenuItemChildren: PropTypes.func,
  mode: PropTypes.oneOf(["select", "autocomplete"]),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  })
};

const mapStateToProps = (state, ownProps) => {
  return {
    formValues: getFormValues(ownProps.formName)(state) || {}
  };
};

export default withStyles(inputStyle, { withTheme: true })(
  connect(mapStateToProps)(Autocomplete)
);
