import React from "react";
import PropTypes from "prop-types";
import PageMenuBase from "./PageMenuBase";
import { withRouter, Link, Route, Switch } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import DefaultIcon from "@material-ui/icons/MoreHoriz";

class PageVerticalIconMenu extends PageMenuBase {
  render() {
    const { basePath, match } = this.props;
    const pathname = `${basePath}/${match.params.menu}`;
    const iconStyle = { marginRight: 0 };
    const childrenProps = this.getChildrenProps();
    const childrenVisible = this.getChildrenVisible();
    console.log("PageVerticalIconMenu", pathname, match.params);
    return (
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1, padding: 12 }}>
          <Switch>
            {childrenProps.map((item, index) => {
              let path = basePath + item.path;
              return (
                <Route
                  key={index}
                  path={path + "/:menu?/:id?"}
                  render={(/*props*/) => childrenVisible[index]}
                />
              );
            })}
          </Switch>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <List>
            {childrenProps.map((item, index) => {
              let path = basePath + item.path;
              const Icon = item.icon || DefaultIcon;
              return (
                <Tooltip key={index} title={item.title}>
                  <ListItem button>
                    <Link
                      to={path + (match.params.id ? `/${match.params.id}` : "")}
                      onClick={evt =>
                        this.handleTabClick(evt, path, match.params)
                      }
                      style={{
                        margin: "-11px -16px",
                        padding: "11px 16px"
                      }}
                    >
                      <ListItemIcon style={iconStyle}>
                        <Icon
                          color={pathname === path ? "primary" : "inherit"}
                        />
                      </ListItemIcon>
                    </Link>
                  </ListItem>
                </Tooltip>
              );
            })}
          </List>
        </div>
      </div>
    );
  }
}
PageVerticalIconMenu.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  children: PropTypes.array,
  basePath: PropTypes.string
};

export default withRouter(PageVerticalIconMenu);
