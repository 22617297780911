import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Register from "./views/Register";
import * as authActions from "redux/modules/auth";

const mapStateToProps = state => {
  return {
    failed: state.auth.failed,
    success: state.auth.success,
    regTokenCheck: state.auth.regTokenCheck,
    //tokenSuccess: state.auth.tokenSuccess,
    loading: state.auth.loading,
    logined: state.auth.logined,
    message: state.auth.message,
    formErrors: state.auth.formErrors,
    hasDistributor: state.auth.hasDistributor,
    isDistributorUser: state.auth.isDistributorUser,
    role: state.auth.role
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reset: authActions.reset,
      resetForm: authActions.resetForm,
      registrationTokenCheck: authActions.registrationTokenCheck,
      registration: authActions.registration,
      registrationDistributor: authActions.registrationDistributor
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
