import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import ProductsIcon from "@material-ui/icons/Shop";
import SettingsIcon from "@material-ui/icons/Settings";
import Select from "Components/New/Input/Select/Select";
import { withRouter, Link, Route, Switch, Redirect } from "react-router-dom";
import { renderToString } from "react-dom/server";
// import ReactDataSheet from "react-datasheet";
// Be sure to include styles at some point, probably during your bootstrapping
// import "react-datasheet/lib/react-datasheet.css";
import "handsontable/dist/handsontable.full.css";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import restHelper from "helpers/restHelper";
import * as numbro from "numbro";
import hungarianLanguage from "numbro/dist/languages/hu-HU.min";
import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
numbro.registerLanguage(hungarianLanguage);

const LC = "cloud"; //Localization category
const C = Handsontable.languages.dictionaryKeys;
//console.log(Handsontable.languages.getLanguageDictionary("en-US"));
Handsontable.languages.registerLanguageDictionary({
  languageCode: "hu-HU",
  // Your translation in the Morse code
  [C.CONTEXTMENU_ITEMS_NO_ITEMS]: "Nincs választható opció",
  [C.CONTEXTMENU_ITEMS_ROW_ABOVE]: "Sor beszúrása fölé",
  [C.CONTEXTMENU_ITEMS_ROW_BELOW]: "Sor beszúrása alá",
  [C.CONTEXTMENU_ITEMS_INSERT_LEFT]: "Oszlop beszúrása elé",
  [C.CONTEXTMENU_ITEMS_INSERT_RIGHT]: "Oszlop beszúrása mögé",
  [C.CONTEXTMENU_ITEMS_REMOVE_ROW]: "Sor(ok) eltávolítása",
  [C.CONTEXTMENU_ITEMS_REMOVE_COLUMN]: "Oszlop(ok) eltávolítása",
  [C.CONTEXTMENU_ITEMS_UNDO]: "Visszavonás",
  [C.CONTEXTMENU_ITEMS_REDO]: "Újra",
  [C.CONTEXTMENU_ITEMS_READ_ONLY]: "Csak olvasható",
  [C.CONTEXTMENU_ITEMS_CLEAR_COLUMN]: "Oszlop törlése",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT]: "Rendezés",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_LEFT]: "Balra",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_CENTER]: "Középre",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_RIGHT]: "Jobbra",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_JUSTIFY]: "Sorkizárt",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_TOP]: "Felülre",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_MIDDLE]: "Középre",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_BOTTOM]: "Alulra",
  [C.CONTEXTMENU_ITEMS_FREEZE_COLUMN]: "Oszlop rögzítése",
  [C.CONTEXTMENU_ITEMS_UNFREEZE_COLUMN]: "Oszlop feloldása",
  [C.CONTEXTMENU_ITEMS_BORDERS]: "Szegélyek",
  [C.CONTEXTMENU_ITEMS_BORDERS_TOP]: "Felső",
  [C.CONTEXTMENU_ITEMS_BORDERS_RIGHT]: "Jobb",
  [C.CONTEXTMENU_ITEMS_BORDERS_BOTTOM]: "Alsó",
  [C.CONTEXTMENU_ITEMS_BORDERS_LEFT]: "Bal",
  [C.CONTEXTMENU_ITEMS_REMOVE_BORDERS]: "Szegély(ek) eltávolítása",
  [C.CONTEXTMENU_ITEMS_ADD_COMMENT]: "Megjegyzés hozzáadása",
  [C.CONTEXTMENU_ITEMS_EDIT_COMMENT]: "Megjegyzés szerkesztése",
  [C.CONTEXTMENU_ITEMS_REMOVE_COMMENT]: "Delete comment",
  [C.CONTEXTMENU_ITEMS_READ_ONLY_COMMENT]: "Csak olvasható megjegyzés",
  [C.CONTEXTMENU_ITEMS_MERGE_CELLS]: "Cellák összevonása",
  [C.CONTEXTMENU_ITEMS_UNMERGE_CELLS]: "Cellák szétbontása",
  [C.CONTEXTMENU_ITEMS_COPY]: "Másol",
  [C.CONTEXTMENU_ITEMS_CUT]: "Kivág",
  [C.CONTEXTMENU_ITEMS_NESTED_ROWS_INSERT_CHILD]: "Gyerek sor bezúrása",
  [C.CONTEXTMENU_ITEMS_NESTED_ROWS_DETACH_CHILD]: "Lecsatolás a szülőről",
  [C.CONTEXTMENU_ITEMS_HIDE_COLUMN]: "Oszlop(ok) elrejtése",
  [C.CONTEXTMENU_ITEMS_SHOW_COLUMN]: "Oszlop(ok) mutatása",
  [C.CONTEXTMENU_ITEMS_HIDE_ROW]: "Sor(ok) elrejtése",
  [C.CONTEXTMENU_ITEMS_SHOW_ROW]: "Sor(ok) mutatása",
  [C.FILTERS_CONDITIONS_NONE]: "Nincs",
  [C.FILTERS_CONDITIONS_EMPTY]: "Üres",
  [C.FILTERS_CONDITIONS_NOT_EMPTY]: "Nem üres",
  [C.FILTERS_CONDITIONS_EQUAL]: "Egyenlő",
  [C.FILTERS_CONDITIONS_NOT_EQUAL]: "Nem egyenlő",
  [C.FILTERS_CONDITIONS_BEGINS_WITH]: "Kezdődik",
  [C.FILTERS_CONDITIONS_ENDS_WITH]: "Végződik",
  [C.FILTERS_CONDITIONS_CONTAINS]: "Tartalmaz",
  [C.FILTERS_CONDITIONS_NOT_CONTAIN]: "Nem tartalmaz",
  [C.FILTERS_CONDITIONS_GREATER_THAN]: "Nagyobb",
  [C.FILTERS_CONDITIONS_GREATER_THAN_OR_EQUAL]: "Nagyobb egyenlő",
  [C.FILTERS_CONDITIONS_LESS_THAN]: "Kisebb",
  [C.FILTERS_CONDITIONS_LESS_THAN_OR_EQUAL]: "Kisebb egyenlő",
  [C.FILTERS_CONDITIONS_BETWEEN]: "Intervallum",
  [C.FILTERS_CONDITIONS_NOT_BETWEEN]: "Intervallumon kívül",
  [C.FILTERS_CONDITIONS_AFTER]: "Utána",
  [C.FILTERS_CONDITIONS_BEFORE]: "Előtte",
  [C.FILTERS_CONDITIONS_TODAY]: "Ma",
  [C.FILTERS_CONDITIONS_TOMORROW]: "Holnap",
  [C.FILTERS_CONDITIONS_YESTERDAY]: "Tegnap",
  [C.FILTERS_VALUES_BLANK_CELLS]: "Üres cellák",
  [C.FILTERS_DIVS_FILTER_BY_CONDITION]: "Szűrő típus",
  [C.FILTERS_DIVS_FILTER_BY_VALUE]: "Keresés",
  [C.FILTERS_LABELS_CONJUNCTION]: "És",
  [C.FILTERS_LABELS_DISJUNCTION]: "Vagy",
  [C.FILTERS_BUTTONS_SELECT_ALL]: "Mindet kijelöl",
  [C.FILTERS_BUTTONS_CLEAR]: "Törlés",
  [C.FILTERS_BUTTONS_OK]: "Ok",
  [C.FILTERS_BUTTONS_CANCEL]: "Mégsem",
  [C.FILTERS_BUTTONS_PLACEHOLDER_SEARCH]: "Keresés",
  [C.FILTERS_BUTTONS_PLACEHOLDER_VALUE]: "Érték",
  [C.FILTERS_BUTTONS_PLACEHOLDER_SECOND_VALUE]: "Második érték"
});

const styles = theme => ({
  /*"@global": {
    ".rbt-input-hint": {
      //backgroundColor: "red!important",
      width: "100%",
      top: "2px!important"
    }
  },*/
  rowDeleted: {
    backgroundColor: "#ef9a9a!important"
    //color: "white!important"
  },
  rowChanged: {
    backgroundColor: "#fff9c4!important"
    //color: "white!important"
  },
  rowNew: {
    backgroundColor: "#e8f5e9!important"
    //color: "white!important"
  }
});

const STATUSES = {
  ORIGINAL: "Eredeti",
  CHANGED: "Módosított",
  DELTED: "Törölt",
  NEW: "Új",
  transformStatus: status => {
    switch (status) {
      case 0:
        return STATUSES.DELTED;
      case 1:
        return STATUSES.CHANGED;
      case 2:
        return STATUSES.NEW;
      default:
        return STATUSES.ORIGINAL;
    }
  },
  reverseStatus: status => {
    switch (status) {
      case STATUSES.DELTED:
        return 0;
      case STATUSES.CHANGED:
        return 1;
      case STATUSES.NEW:
        return 2;
      default:
        return -1;
    }
  }
};
/*
class CustomEditor extends Handsontable.editors.TextEditor {
  constructor(props) {
    super(props);
  }

  createElements() {
    super.createElements();

    this.TEXTAREA = document.createElement("input");
    this.TEXTAREA.setAttribute("placeholder", "Custom placeholder");
    this.TEXTAREA.className = "handsontableInput";
    this.textareaStyle = this.TEXTAREA.style;
    Handsontable.dom.empty(this.TEXTAREA_PARENT);
    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
  }
}
*/
/*export function myRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  Handsontable.renderers.TextRenderer.apply(this, arguments);
  td.innerHTML = "name";
}*/

const objectToString = o => {
  if (!o) {
    return o;
  }
  Object.keys(o).forEach(k => {
    if (typeof o[k] === "object") {
      return toString(o[k]);
    }

    o[k] = "" + o[k];
  });

  return o;
};

const isChanged = row => {
  if (!row) {
    return false;
  }
  const { _original, _status, ...newRow } = row;
  //console.log(_original, "=", newRow);
  return (
    JSON.stringify(objectToString(_original)) !==
    JSON.stringify(objectToString(newRow))
  );
};

class DataTableHandson extends React.Component {
  constructor(props) {
    super(props);

    this.id = "hot";
    this.hotTableSettings = {};
    this.hotTableComponent = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    //console.log("DataTableHandson.componentDidMount", this.props.match.params);
    //this.fetchData();
    this.props.hotTableRef(this.hotTableComponent);
  }

  componentWillUpdate(nextProps) {
    //Adatok vagy option fognak érkezni, beállítjuk a grid tulajdonságokat
    //console.log("componentWillUpdate", nextProps);
    //if (this.props.data.length !== nextProps.data.length) {
    if (
      JSON.stringify(this.props.options) !==
      JSON.stringify(nextProps.data.length)
    ) {
      /*const data = nextProps.data.map(item => {
        return {
          ...Object.assign({}, item),
          _original: Object.assign({}, item),
          _changed: false,
          _deleted: false
        };
      });*/
      //this.hotTableSettings = this.getHotTableSettings(data);
      this.hotTableSettings = this.getHotTableSettings();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.readOnly != prevProps.readOnly) {
      this.hotTableComponent.current.hotInstance.updateSettings({
        contextMenu: this.props.readOnly ? false : this.getContextMenu()
      });
    }

    //Adatok érkeztek
    if (this.props.data.length !== prevProps.data.length) {
      const data = this.props.data.map(item => {
        return {
          ...Object.assign({}, item),
          _original: Object.assign({}, item),
          _status: STATUSES.transformStatus(item.status)
        };
      });
      this.hotTableComponent.current.hotInstance.loadData(data);
    }
  }

  /*componentDidUpdate(prevProps) {
    //Adatok érkeztek
    if (this.props.data.length !== prevProps.data.length) {
      const data = this.props.data.map(item => {
        return {
          ...Object.assign({}, item),
          _original: Object.assign({}, item),
          _changed: false,
          _deleted: false
        };
      });
      this.hotTableComponent.current.hotInstance.loadData(data);
    }
  }*/
  getContextMenu() {
    return {
      items: {
        row_above: {},
        row_below: {},
        add_custom: {
          name: "50 új sor hozzáadása",
          callback: function(event_key, eventData, event) {
            const lastCoords = eventData[eventData.length - 1];
            const lastRow = lastCoords.end.row;
            this.alter("insert_row", lastRow, 50);
          }
        },
        remove_row: {},
        separator: Handsontable.plugins.ContextMenu.SEPARATOR,
        clear_custom: {
          name: "Eredeti sor(ok) visszaállítása",
          callback: function(event_key, eventData, event) {
            let changes = [];
            const commentsPlugin = this.getPlugin("comments");
            for (let i = 0; i < eventData.length; i++) {
              const coords = eventData[i];
              for (let j = coords.start.row; j <= coords.end.row; j++) {
                const original = this.getDataAtRowProp(j, "_original") || {};

                //TODO: Végigmenni a data kulcsokon és előállítani az eredeti rekordot majd beküldeni a gridnek egyszerre
                //console.log(original);
                //const hot = this;
                //let maxColIndex = 0;
                Object.keys(original).map(prop => {
                  changes.push([j, prop, original[prop]]);
                  const colIndex = this.propToCol(prop);

                  if (!isNaN(colIndex)) {
                    //commentsPlugin.removeCommentAtCell(j, colIndex);
                    const comment = commentsPlugin.getCommentAtCell(
                      j,
                      colIndex
                    );
                    if (comment) {
                      setTimeout(() => {
                        commentsPlugin.removeCommentAtCell(j, colIndex, false);
                      }, 100);
                    }

                    //maxColIndex = colIndex;
                  }
                  //  console.log(this.propToCol(prop));
                });
                changes.push([
                  j,
                  "_status",
                  STATUSES.transformStatus(original.status)
                ]);
                //changes.push([j, "_changed", false]);
                /*commentsPlugin.setRange({
                from: { row: j, col: 1 },
                to: { row: j, col: maxColIndex }
              });
              commentsPlugin.removeComment(true);*/
              }
            }
            /*commentsPlugin.setRange({
            from: { row: 0, col: 1 },
            to: { row: 0, col: 8 }
          });
*/
            this.setDataAtRowProp(changes);
            //commentsPlugin.removeComment(true);

            //removeCommentAtCell(row, column, forceRender)
            //this.clear();
          }
        }
      }
    };
  }

  getHotTableSettings = data => {
    const context = this;
    const {
      classes,
      //data,
      editItem,
      onEdit,
      onEndEdit,
      onDelete,
      onCreate,
      title: titleProp,
      filterable,
      optionsError,
      error,
      readOnly,
      gridOptionsKey
    } = this.props;

    const grid = this.props.options ? this.props.options[gridOptionsKey] : null;
    //console.log("DataTable.render", this.props);
    if (!grid) {
      //return "NINCS GRID";
    }

    const title =
      typeof titleProp === "function" ? titleProp(this.props) : titleProp;

    let visibleColumns = Object.keys(grid.columns).filter(key => {
      const col = grid.columns[key];
      if (col.hidden) {
        return false;
      }
      return true;
    });
    //visibleColumns.push("id");
    const colHeaders = visibleColumns
      .map((key, index) => {
        const col = grid.columns[key];
        const label = col.name || key;
        return label;
      })
      .concat(["Állapot"]);
    //console.log("colHeaders", colHeaders);
    const columns = visibleColumns
      .map((key, index) => {
        const col = grid.columns[key];
        const label = col.name || key;
        return Object.assign(
          {
            data: key
            //type: "numeric",
            //width: 40
          },
          this.props.columns[key]
        );
      })
      .concat([
        {
          data: "_status",
          //type: "checkbox",
          width: 36,
          readOnly: true
        }
      ]);
    //console.log("columns", columns);
    return {
      data: data ? data : undefined,
      columns: columns,
      language: "hu-HU",
      stretchH: "all",
      //width: "100%",
      autoWrapRow: true,
      height: "400",
      //maxRows: 20,
      //minRows: 20,
      rowHeaders: true,
      colHeaders: colHeaders,
      headerTooltips: {
        rows: false,
        columns: true,
        onlyTrimmed: true
      },
      //manualRowResize: true,
      //manualColumnResize: true,
      filters: true,
      dropdownMenu: true,
      //contextMenu: true,
      columnSorting: {
        indicator: true
      },
      //colWidths: [50, 150, 45, 40, 40, 40, 40, 40],
      /*autoColumnSize: {
        samplingRatio: 23
      },*/
      multiColumnSorting: {
        indicator: true
      },
      autoRowSize: false,
      autoColumnSize: false,
      comments: true,
      cells: function(row, column, prop) {
        const cellProperties = {};
        const vRow = this.instance.toVisualRow(row);
        /*const deleted =
          this.instance.getDataAtRowProp(vRow, "_deleted") || false;
        const changed =
          this.instance.getDataAtRowProp(vRow, "_changed") || false;*/
        const status = this.instance.getDataAtRowProp(vRow, "_status");
        //console.log(status);
        if (status === STATUSES.DELTED) {
          cellProperties.className = classes.rowDeleted;
        } else if (status === STATUSES.CHANGED) {
          cellProperties.className = classes.rowChanged;
          cellProperties.readOnly = context.props.columns[prop]
            ? context.props.columns[prop].readOnly
            : undefined;
        } else if (status === STATUSES.NEW) {
          cellProperties.className = classes.rowNew;
          cellProperties.readOnly = context.props.columns[prop]
            ? context.props.columns[prop].readOnly
            : undefined;
        } else {
          cellProperties.className = undefined;
          cellProperties.readOnly = context.props.columns[prop]
            ? context.props.columns[prop].readOnly
            : undefined;
        }
        /*if (["_deleted", "_changed"].indexOf(prop) >= 0) {
          cellProperties.className += " htCenter";
        }*/

        const original = this.instance.getDataAtRowProp(vRow, "_original");

        if (
          context.props.columns[prop] &&
          context.props.columns[prop].readOnlyOnOriginal
        ) {
          if (original && original[prop]) {
            cellProperties.readOnly = true;
          }
        }

        if (this.validationError) {
          cellProperties.comment = {
            value: this.validationError
          };
        } else if (
          original &&
          original[prop] &&
          original[prop] != this.instance.getDataAtRowProp(vRow, prop)
        ) {
          cellProperties.comment = {
            value: "Eredeti érték: " + original[prop]
          };
        }
        if (prop === "_status") {
          cellProperties.readOnly = true;
        }
        if (context.props.readOnly) {
          cellProperties.readOnly = true;
        }
        return cellProperties;
      },
      beforeRemoveRow: function(index, amount, physicalRows, source) {
        //console.log("beforeRemoveRow", index, amount, physicalRows, source);
        if (source === "background") {
          return;
        }
        physicalRows.forEach(row => {
          const pRow = this.toPhysicalRow(row);
          const sourceRow = this.getSourceDataAtRow(pRow);
          //console.log("beforeRemoveRow", row, pRow, sourceRow);
          if (sourceRow._status === STATUSES.NEW) {
            this.alter("remove_row", row, 1, "background");
          } else {
            this.setDataAtRowProp(
              row,
              "_status",
              STATUSES.DELTED,
              "background"
            );
          }
        });
        return false;
      },
      afterChange: function(changes, source) {
        //console.log("afterChange", changes, source);
        if (source === "background") {
          return;
        }
        if (changes) {
          changes.forEach(([row, prop, oldValue, newValue]) => {
            const pRow = this.toPhysicalRow(row);
            const sourceRow = this.getSourceDataAtRow(pRow);
            //console.log("afterChange", sourceRow);
            if (sourceRow._status !== STATUSES.NEW) {
              this.setDataAtRowProp(
                row,
                "_status",
                isChanged(sourceRow) ? STATUSES.CHANGED : STATUSES.ORIGINAL,
                "background"
              );
            }
          });
        }
      },
      afterCreateRow: function(index, amount, source) {
        //console.log("afterCreateRow", index, amount, source);
        if (source === "background") {
          return;
        }
        let changes = [];
        for (let i = index; i < index + amount; i++) {
          changes.push([i, "_status", STATUSES.NEW]);
        }
        this.setDataAtRowProp(changes, "background");

        /*
        if (changes) {
          changes.forEach(([row, prop, oldValue, newValue]) => {
            const pRow = this.toPhysicalRow(row);
            const sourceRow = this.getSourceDataAtRow(pRow);
            //console.log("afterChange", sourceRow);
            this.setDataAtRowProp(
              row,
              "_status",
              isChanged(sourceRow) ? STATUSES.CHANGED : STATUSES.ORIGINAL,
              "background"
            );
          });
        }*/
      },
      contextMenu: readOnly ? false : this.getContextMenu()
    };
  };

  render() {
    return (
      <div>
        <HotTable
          key={this.props.data.length}
          ref={this.hotTableComponent}
          id={this.id}
          settings={this.hotTableSettings}
          licenseKey="non-commercial-and-evaluation"
        />
      </div>
    );
  }
}

DataTableHandson.defaultProps = {
  filterable: true,
  readOnly: false,
  gridOptionsKey: "grid",
  showRefreshIcon: true,
  customContainer: false,
  alwaysShowDetailsComponent: false,
  columnsFormat: {},
  summaryItems: [],
  defaultColumnWidths: {}
};

DataTableHandson.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  filterable: PropTypes.bool,
  fetchData: PropTypes.func,
  readOnly: PropTypes.bool,
  showRefreshIcon: PropTypes.bool,
  customContainer: PropTypes.bool,
  alwaysShowDetailsComponent: PropTypes.bool,
  gridOptionsKey: PropTypes.string,
  detailsComponent: PropTypes.func,
  columnsFormat: PropTypes.object,
  summaryItems: PropTypes.array,
  defaultColumnWidths: PropTypes.object
};

export default withStyles(styles)(withRouter(DataTableHandson));
