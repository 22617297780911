import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { green, amber, red } from "@material-ui/core/colors";
import { Tooltip } from "@material-ui/core";
import localize from "helpers/localize";

const styles = theme => ({
  info: {
    display: "block",
    width: 20,
    height: 20,
    borderRadius: 10,
    margin: theme.spacing.unit,
    cursor: "pointer"
  },
  none: {
    backgroundColor: "#999"
  },
  green: {
    backgroundColor: green[500]
  },
  red: {
    backgroundColor: red[500]
  },
  yellow: {
    backgroundColor: amber[600]
  }
});

class StockInfo extends React.Component {
  getStockInfo = () => {
    const { classes, stockInfo, stockInfoText } = this.props;
    let info = {
      title: stockInfo ? stockInfoText : localize("shop", "Nincs információ"),
      className: classes.none
    };
    switch (stockInfo) {
      case "IN":
        info.className = classes.green;
        break;
      case "OUT":
        info.className = classes.red;
        break;
      case "LIMITED":
        info.className = classes.yellow;
        break;
    }

    return info;
  };

  render() {
    const { classes, rootClassName } = this.props;
    const stockInfo = this.getStockInfo();
    const className = classNames(
      rootClassName,
      classes.info,
      stockInfo.className
    );
    return (
      <Tooltip title={stockInfo.title}>
        <div className={className} />
      </Tooltip>
    );
  }
}

StockInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  stockInfo: PropTypes.string,
  stockInfoText: PropTypes.string,
  rootClassName: PropTypes.string
};

export default withStyles(styles)(StockInfo);
