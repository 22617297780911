import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "Components/New/Input/TextField/TextField";
import DatePicker from "Components/New/Input/DatePicker/DatePicker";
import Checkbox from "Components/New/Input/Checkbox/Checkbox";
import Select from "Components/New/Input/Select/Select";
import Wysiwyg from "Components/New/Input/Wysiwyg/Wysiwyg";
import Tags from "Components/New/Input/Tags/Tags";
import File from "Components/New/Input/File/File";
import Image from "Components/New/Input/Image/Image";
import SimpleArray2dArray from "Components/New/Input/SimpleArray2dArray/SimpleArray2dArray";
//import { withRouter, Link, Route, Switch, Redirect } from "react-router-dom";
//import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import { merge } from "lodash";
import {
  Button,
  Toolbar,
  Typography,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import { get } from "lodash";
import Validations from "Components/Forms/Validations";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";

const styles = (/*theme*/) => ({
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0
  },
  toolbarSpacer: {
    marginLeft: "auto"
  }
});

class DataForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usedFields: {}
    };
    this.usedFields = {}; //Automatán felhasznált mezők
  }
  componentDidUpdate() {
    const { setUsedField } = this.props;
    setUsedField && setUsedField(this.usedFields);
  }

  componentDidMount() {
    //Ha vannak felhasznált mezők eltűntetjük az auto renderből
    if (
      JSON.stringify(this.state.usedFields) !== JSON.stringify(this.usedFields)
    ) {
      this.setState({ usedFields: this.usedFields });
    }
  }

  fieldProps = (key, type) => {
    const {
      editItem,
      onChange,
      errors,
      validateField: validateFieldProvider,
      isRequiredField: isRequiredFieldProvider,
      disabled: disabledGlobal
    } = this.props;
    const form = this.props.options ? this.props.options.form : null;
    const validateField = validateFieldProvider || this.validateField;
    const isRequiredField = isRequiredFieldProvider || this.isRequiredField;
    //Ha nincs mező definíció kilépünk
    if (!form || !form.fields) {
      return {};
    }
    //console.log("frm", disabled);
    const field = form.fields[key];
    if (!field) {
      return {};
    }

    const error = errors[key] ? errors[key].join(",") : null;

    this.usedFields[key] = true;

    let disabled = false;

    if (disabledGlobal === true) {
      disabled = true;
    } else if (field.disabled && typeof field.disabled === "function") {
      disabled = field.disabled(editItem);
    } else if (field.disabled) {
      disabled = field.disabled;
    }

    let props = {
      id: key,
      label: field.name || key,
      //key: `field.${colIndex}`,
      value: editItem[key] || "",
      type: field.type,
      mask: field.mask,
      error: error,
      disabled: disabled,
      maxLength: field.maxLength,
      isRequired: isRequiredField(key),
      onBlur: () => validateField(key)
    };

    switch (type) {
      case "checkbox":
        //props.columns = field.columns;
        props.onChange = evt => onChange(key, evt.target.checked);
        break;
      case "date":
        //props.columns = field.columns;
        props.onChange = date => onChange(key, date);
        break;
      case "simple-2d-array":
        props.columns = field.columns;
        props.showLineNumbers = field.showLineNumbers;
        props.onChange = evt => onChange(key, evt.target.value);
        break;
      case "dropdown":
        props.onChange = (item, value) => onChange(key, value, item);
        props.options = field.data;
        break;
      case "autocomplete":
        props.onChange = (item, value) => onChange(key, value, item);
        props.url = field.url.path.replace("/v1/", "");
        props.requestParams = Array.isArray(field.url.params)
          ? {}
          : field.url.params;
        break;
      case "tags":
        props.onChange = (value, item) => onChange(key, value, item);
        props.type = "autocomplete";
        props.url = field.url.path.replace("/v1/", "");
        props.requestParams = Array.isArray(field.url.params)
          ? {}
          : field.url.params;
        break;
      case "wysiwyg":
        props.onChange = content => onChange(key, content);
        break;
      case "image":
      case "file":
        props.onChange = value => onChange(key, value);
        break;
      default:
        props.onChange = evt => onChange(key, evt.target.value);
        props.rowsMax = field.rowsMax;
        props.rowsMin = field.rowsMin;
        props.rows = field.rows;
        props.multiline = field.multiline;
        break;
    }

    return props;
  };

  isRequiredField = fieldName => {
    const _validators = get(
      this.props,
      `options.form.fields.${fieldName}.validators`
    );
    const validators =
      typeof _validators === "function"
        ? _validators(this.props.editItem)
        : _validators;
    if (validators) {
      return validators.indexOf("required") >= 0;
    } else {
      return false;
    }
  };

  validateField = fieldName => {
    let errors = Object.assign({}, this.props.errors);
    const error = this.usedFields[fieldName]
      ? this.fieldError(fieldName)
      : null;
    let valid = false;
    if (error) {
      errors[fieldName] = error;
      valid = false;
    } else {
      delete errors[fieldName];
      valid = true;
    }
    if (JSON.stringify(errors) !== JSON.stringify(this.props.errors)) {
      this.props.onChangeErrors &&
        this.props.onChangeErrors(errors, valid, fieldName);
    }
    return valid;
  };

  validateFields = () => {
    const fields = get(this.props, "options.form.fields", {});
    let valid = true;
    let errors = Object.assign({}, this.props.errors);
    Object.keys(fields).forEach(fieldName => {
      const _validators = get(
        this.props,
        `options.form.fields.${fieldName}.validators`
      );
      const validators =
        typeof _validators === "function"
          ? _validators(this.props.editItem)
          : _validators;
      const error = this.usedFields[fieldName]
        ? this.fieldError(fieldName)
        : null;
      //Csak azokat a hibaüzeneteket bántjuk, ahol van validátor, a többi bizonyára szerveroldali
      if (validators) {
        if (error) {
          valid = false;
          //console.log(fieldName, "invalid");
          errors[fieldName] = error;
        } else {
          delete errors[fieldName];
        }
      }
    });
    this.props.onChangeErrors && this.props.onChangeErrors(errors, valid);
    return valid;
  };

  fieldValue = fieldName => {
    if (this.props.editItem) {
      return this.props.editItem[fieldName];
    }
  };

  fieldError = fieldName => {
    const _validators = get(
      this.props,
      `options.form.fields.${fieldName}.validators`
    );
    const validators =
      typeof _validators === "function"
        ? _validators(this.props.editItem)
        : _validators;
    if (validators) {
      let errors = [];
      validators.forEach(element => {
        const error = Validations[element](this.fieldValue(fieldName));
        if (error) {
          errors.push(error);
        }
      });
      return errors.length > 0 ? errors : null;
    } else {
      return null;
    }
  };

  showGlobalValidationError = () => {
    globalMessage.error(
      "Hiányos vagy rosszul kitöltött mezők vannak az űrlapon."
    );
  };

  onSave = () => {
    //Kliens oldali validáció
    if (!this.validateFields()) {
      this.showGlobalValidationError();
      return;
    }
    this.props.onValidated && this.props.onValidated(this.props.editItem);
  };

  render() {
    const {
      classes,
      title,
      titleNew,
      editItem,
      onEndEdit,
      onSave: onSaveProvider,
      onChange,
      errors,
      options,
      validateField: validateFieldProvider,
      isRequiredField: isRequiredFieldProvider,
      component: FormComponent,
      autoRenderFields,
      disableHeader,
      disableButtons,
      disableCancelButton,
      disableSaveButton,
      componentProps,
      onItemChange,
      disabled: disabledGlobal,
      loading,
      refreshView
    } = this.props;
    const validateField = validateFieldProvider || this.validateField;
    const isRequiredField = isRequiredFieldProvider || this.isRequiredField;
    const onSave = onSaveProvider || this.onSave;

    const { usedFields } = this.state;

    const table = this.props.options ? this.props.options.table : null;
    const form = this.props.options ? this.props.options.form : null;

    this.usedFields = {};

    /*if (!table) {
      return "NINCS GRID";
    }*/
    if (!form) {
      return "NINCS FORM";
    }
    if (loading) {
      return (
        <div style={{ padding: 24, display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      );
    }
    const visibleColumnsBase = {};

    /*Object.keys(table.columns).forEach(key => {
      let col = table.columns[key];
      if (col.hidden) {
        return;
      }
      col.key = key;
      visibleColumnsBase[key] = col;
    });*/
    //console.log(form);;
    Object.keys(form.fields).forEach(key => {
      let col = Object.assign({}, form.fields[key]);

      if (col.hidden) {
        return;
      }
      col.key = key;
      if (visibleColumnsBase[key]) {
        visibleColumnsBase[key] = merge(visibleColumnsBase[key], col);
      } else {
        visibleColumnsBase[key] = col;
      }
    });

    let visibleColumns = [];
    form.fieldsOrder.forEach(key => {
      if (visibleColumnsBase[key]) {
        visibleColumns.push(visibleColumnsBase[key]);
      }
    });

    Object.keys(visibleColumnsBase).forEach(key => {
      if (form.fieldsOrder.indexOf(key) >= 0) {
        return false;
      }
      visibleColumns.push(visibleColumnsBase[key]);
    });
    const titleString = typeof title === "function" ? title(editItem) : title;
    return (
      <Fragment>
        <Grid container spacing={16}>
          {!disableHeader && (
            <Grid item xs={12}>
              <Toolbar disableGutters>
                <IconButton onClick={onEndEdit} style={{ padding: 8 }}>
                  <BackIcon
                    fontSize="small"
                    style={{ marginLeft: 4, marginRight: -4 }}
                  />
                </IconButton>
                <Typography variant="h6">
                  {editItem.id ? titleString : titleNew}
                </Typography>
              </Toolbar>
            </Grid>
          )}
          {FormComponent && (
            <FormComponent
              fieldProps={this.fieldProps}
              editItem={editItem}
              options={options}
              onSave={onSave}
              onChange={onChange}
              onItemChange={onItemChange}
              onEndEdit={onEndEdit}
              refreshView={refreshView}
              {...componentProps}
            />
          )}
          {autoRenderFields &&
            visibleColumns
              .filter(c => {
                if (usedFields[c.key]) {
                  return false;
                }

                if (c.hasOwnProperty("visible")) {
                  if (typeof c.visible === "function") {
                    return c.visible(editItem);
                  } else {
                    return c.visible;
                  }
                }

                return true;
              })
              .map((col, colIndex) => {
                const key = col.key;
                const error = errors[key] ? errors[key].join(",") : null;
                const sizes = col.sizes || { xs: 12, sm: 6, md: 4, lg: 3 };
                const val =
                  editItem[key] || editItem[key] === 0 ? editItem[key] : "";

                let disabled = false;

                if (disabledGlobal === true) {
                  disabled = true;
                } else if (col.disabled && typeof col.disabled === "function") {
                  disabled = col.disabled(editItem);
                } else if (col.disabled) {
                  disabled = col.disabled;
                }
                //console.log(col.id, col);
                return (
                  <Fragment key={colIndex}>
                    {col.newLine && <Grid container />}
                    <Grid key={colIndex} item {...sizes}>
                      {col.type === "dropdown" && (
                        <Select
                          id={key}
                          label={col.name || key}
                          fullWidth
                          key={`field.${colIndex}`}
                          value={val}
                          onChange={(item, value) => onChange(key, value, item)}
                          type={col.type}
                          options={col.data}
                          error={error}
                          isRequired={isRequiredField(key)}
                          onBlur={() => validateField(key)}
                          disabled={disabled}
                        />
                      )}
                      {col.type === "autocomplete" && (
                        <Select
                          id={key}
                          label={col.name || key}
                          fullWidth
                          defaultOptions
                          key={`field.${colIndex}`}
                          value={val}
                          onChange={(item, value) => onChange(key, value, item)}
                          url={col.url.path.replace("/v1/", "")}
                          //labelKey="name"
                          type={col.type}
                          requestParams={
                            Array.isArray(col.url.params) ? {} : col.url.params
                          }
                          error={error}
                          isRequired={isRequiredField(key)}
                          onBlur={() => validateField(key)}
                          disabled={disabled}
                        />
                      )}
                      {col.type === "tags" && (
                        <Tags
                          id={key}
                          label={col.name || key}
                          fullWidth
                          key={`field.${colIndex}`}
                          value={val}
                          onChange={(value, item) => onChange(key, value, item)}
                          url={col.url.path.replace("/v1/", "")}
                          //labelKey="name"
                          type={"autocomplete"}
                          requestParams={
                            Array.isArray(col.url.params) ? {} : col.url.params
                          }
                          error={error}
                          isRequired={isRequiredField(key)}
                          onBlur={() => validateField(key)}
                          disabled={disabled}
                        />
                      )}
                      {col.type === "wysiwyg" && (
                        <Wysiwyg
                          id={key}
                          label={col.name || key}
                          fullWidth
                          key={`field.${colIndex}`}
                          value={val}
                          onChange={content => onChange(key, content)}
                          error={error}
                          mask={col.mask}
                          menubar={col.menubar}
                          toolbar={col.toolbar}
                          plugins={col.plugins}
                          isRequired={isRequiredField(key)}
                          onBlur={() => validateField(key)}
                          disabled={disabled}
                        />
                      )}
                      {col.type === "Ft" && (
                        <TextField
                          id={key}
                          label={col.name || key}
                          fullWidth
                          format={"currency"}
                          key={`field.${colIndex}`}
                          value={val}
                          onChange={evt => onChange(key, evt.target.value)}
                          error={error}
                          mask={col.mask}
                          isRequired={isRequiredField(key)}
                          onBlur={() => validateField(key)}
                          disabled={disabled}
                        />
                      )}
                      {col.type === "file" && (
                        <File
                          id={key}
                          label={col.name || key}
                          fullWidth
                          key={`field.${colIndex}`}
                          value={val}
                          onChange={(id, file) => onChange(key, id)}
                          error={error}
                          mask={col.mask}
                          isRequired={isRequiredField(key)}
                          onBlur={() => validateField(key)}
                          disabled={disabled}
                        />
                      )}
                      {col.type === "image" && (
                        <Image
                          id={key}
                          label={col.name || key}
                          fullWidth
                          key={`field.${colIndex}`}
                          value={val}
                          onChange={(id, file) => onChange(key, id)}
                          error={error}
                          mask={col.mask}
                          isRequired={isRequiredField(key)}
                          onBlur={() => validateField(key)}
                          disabled={disabled}
                        />
                      )}
                      {col.type === "simple-2d-array" && (
                        <SimpleArray2dArray
                          id={key}
                          label={col.name || key}
                          fullWidth
                          key={`field.${colIndex}`}
                          maxLength={col.maxLength}
                          value={val}
                          onChange={evt => onChange(key, evt.target.value)}
                          error={error}
                          mask={col.mask}
                          columns={col.columns}
                          showLineNumbers={col.showLineNumbers}
                          isRequired={isRequiredField(key)}
                          onBlur={() => validateField(key)}
                          disabled={disabled}
                        />
                      )}
                      {col.type === "checkbox" && (
                        <Checkbox
                          id={key}
                          label={col.name || key}
                          //fullWidth
                          key={`field.${colIndex}`}
                          value={val}
                          onChange={evt => onChange(key, evt.target.checked)}
                          error={error}
                          mask={col.mask}
                          columns={col.columns}
                          isRequired={isRequiredField(key)}
                          onBlur={() => validateField(key)}
                          disabled={disabled}
                        />
                      )}
                      {col.type === "date" && (
                        <DatePicker
                          id={key}
                          label={col.name || key}
                          fullWidth
                          key={`field.${colIndex}`}
                          value={val ? val : null}
                          onChange={date => onChange(key, date)}
                          error={error}
                          isRequired={isRequiredField(key)}
                          onBlur={() => validateField(key)}
                          disabled={disabled}
                        />
                      )}
                      {!col.type && (
                        <TextField
                          id={key}
                          label={col.name || key}
                          fullWidth
                          key={`field.${colIndex}`}
                          value={val}
                          onChange={evt => onChange(key, evt.target.value)}
                          error={error}
                          rowsMax={col.rowsMax}
                          rowsMin={col.rowsMin}
                          rows={col.rowsMin}
                          multiline={col.multiline}
                          mask={col.mask}
                          maxLength={col.maxLength}
                          isRequired={isRequiredField(key)}
                          onBlur={() => validateField(key)}
                          disabled={disabled}
                        />
                      )}
                    </Grid>
                  </Fragment>
                );
              })}
        </Grid>
        {!disableButtons && (
          <Toolbar className={classes.toolbar}>
            <div className={classes.toolbarSpacer} />
            {!disableCancelButton && (
              <Button onClick={onEndEdit} size="small">
                Mégsem
              </Button>
            )}
            {!disableSaveButton && (
              <Button
                onClick={onSave}
                size="small"
                color="primary"
                disabled={disabledGlobal}
              >
                Mentés
              </Button>
            )}
          </Toolbar>
        )}
      </Fragment>
    );
  }
}
DataForm.defaultProps = {
  autoRenderFields: true
};

DataForm.propTypes = {
  classes: PropTypes.object,
  validateField: PropTypes.func, //Mező validáló fgv.
  isRequiredField: PropTypes.func, //Kötelezőséget meghatórozó fgv.
  editItem: PropTypes.object, //Éppen szereksztendő adat. Pl. a táblázat egy sora
  componentProps: PropTypes.object, //Az egyedi form komponensnek átadott plusz property-k
  options: PropTypes.object, //Options a form és a table paraméterek (oszlopok, mezők)
  onEndEdit: PropTypes.func, //Szerkesztés befejezése. Kinullozza az editItem értékét
  onSave: PropTypes.func, //Mentés, az edititem értékét lementi az adatbázisba
  onChange: PropTypes.func.isRequired, //Egy mező értékének megváltoztatása. Az editItem tulajdonságait állítja kulcs alapján
  onItemChange: PropTypes.func, //Sok mező értékének megváltoztatása. Az editItem tulajdonságait állítja merge metódussal
  errors: PropTypes.object, //Validáviós hibákat tartalmazó objecktum. A kulcsok a mezőnevek
  component: PropTypes.any, //Egyedi form komponens
  autoRenderFields: PropTypes.bool, //Renderelje-e automatikusan a mezőket
  disableHeader: PropTypes.bool, //Renderelje-e a headert
  disableButtons: PropTypes.bool, //Renderelje-e a gombokat
  disableSaveButton: PropTypes.bool, //Renderelje-e a mentés gombokat
  disableCancelButton: PropTypes.bool, //Renderelje-e a cancel gombokat
  disabled: PropTypes.bool //Csak olvasható
};

export default withStyles(styles)(DataForm);
