import { createTextMask } from "redux-form-input-masks";

export const taxNumberMask = createTextMask({
  pattern: "99999999-9-99",
  stripMask: false
});

export const mobilNumberMask = createTextMask({
  pattern: "06-99-9999999",
  stripMask: false
});

export const mobilNumberSimpleMask = createTextMask({
  pattern: "99-99-9999999",
  stripMask: false
});

export const DepMask = createTextMask({
  pattern: "999",
  stripMask: false
});

export const dateTimeMask = createTextMask({
  pattern: "9999-99-99 99:99:99",
  stripMask: false
});

export const apMask = createTextMask({
  pattern: "A99999999",
  maskDefinitions: {
    9: {
      regExp: /[0-9]/
    },
    A: {
      regExp: /[A-Za-z]/,
      transform: char => char.toUpperCase()
    }
  }
});

export const apMask0 = createTextMask({
  pattern: "A99999999",
  maskDefinitions: {
    9: {
      regExp: /[0-9]/
    },
    A: {
      regExp: /[A-Za-z0]/,
      transform: char => char.toUpperCase()
    }
  }
});

export const apMaskAY0 = createTextMask({
  pattern: "A99999999",
  maskDefinitions: {
    9: {
      regExp: /[0-9]/
      //regExp: /[AaYy0]/
    },
    A: {
      regExp: /[AaYy0]/,
      transform: char => char.toUpperCase()
    }
  }
});

/**
 * Üres maszk értékeket hivatott korrigánlni.
 * Ez akkor fontos, ha valami nem kötelező, de maszkolva van.
 * Ilyenkor elmegy a szerver felé a maszk sajnos
 *
 * @param {String} type Maszk típusa
 * @param {String} value Érték
 */
export const valueCorrector = (type, value) => {
  switch (type) {
    case "mobile_phone":
      if (value === "06-__-_______") {
        return null;
      }
      return value;
    case "apn":
      if (value === "_________") {
        return null;
      }
      return value;
    case "tax_number":
      if (value === "________-_-__") {
        return null;
      }
      return value;
    case "dep_collector":
      if (value === "___") {
        return null;
      }
      return value;
    default:
      return value;
  }
};

export const inputMask = type => {
  switch (type) {
    case "mobile_phone":
      return [
        "0",
        "6",
        "-",
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ];
    case "apn":
      return [/[A-Z]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    case "tax_number": //"99999999-9-99"
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        "-",
        /\d/,
        /\d/
      ];
    case "dep_collector": //" [ABCDE]\d\d"
      return [/[ABCDE]/, /\d/, /\d/];

    default:
      console.error("inputMask not defined for type ", type);
      return [];
  }
};
