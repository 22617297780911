import React from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import localize from "helpers/localize";

const Section = props => {
  const { values, title, captions } = props;

  return (
    <div className="table-responsive-material reregisterSection">
      <h4 className="reregisterSection-title">{title}</h4>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{localize("cashregisters", "Adat")}</TableCell>
            <TableCell>{localize("cashregisters", "Régi")}</TableCell>
            <TableCell>{localize("cashregisters", "Új")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(captions).map(key => {
            const item = values[key];
            return (
              <TableRow
                key={key}
                className={item.changed ? "reregisterSection-item-changed" : ""}
              >
                <TableCell>{captions[key]}</TableCell>
                <TableCell>{item.old}</TableCell>
                <TableCell>{item.new}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
class CashRegisterReregisters extends React.Component {
  render() {
    const { loading, items, success, message } = this.props;

    const captions = {
      cashregister_status: localize("cashregisters", "Státusz"), // Státusz
      cashregister_client_name: localize("cashregisters", "Cégnév"), // Cégnév
      cashregister_client_home: localize("cashregisters", "Székhely"), // Székhely
      cashregister_client_yard: localize("cashregisters", "Telephely"), // Telephely
      cashregister_client_yard_name: localize("cashregisters", "Telephelynév"), // Telephelynév
      cashregister_client_tax_number: localize("cashregisters", "Adószám"), // Adószám
      cashregister_DTS: localize("cashregisters", "Időbélyeg (GSM idő)"), // Időbélyeg (GSM idő)
      cashregister_DFT: localize("cashregisters", "AEE sw update date"), // AEE sw update date
      cashregister_VCC: localize("cashregisters", "Üzembehelyezési kód"), // Üzembehelyezési kód
      cashregister_SSG: localize("cashregisters", "Térerő"), // Térerő
      cashregister_last_update_user_id: localize(
        "cashregisters",
        "Utolsó módosító felhaszáló"
      ), // Utolsó módosító felhaszáló
      cashregister_last_update_datetime: localize(
        "cashregisters",
        "Utolsó módosítás ideje"
      ), // Utolsó módosítás ideje
      cashregister_xml_filename: localize(
        "cashregisters",
        "Feldolgozott xml fájl neve"
      ) // Feldolgozott xml fájl neve
    };

    if (loading) {
      return (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      );
    }

    if (!success) {
      return <div style={{ textAlign: "center", color: "red" }}>{message}</div>;
    }

    return items.map((item, key) => (
      <Section
        key={key}
        title={item.datetime}
        values={item.values}
        captions={captions}
      />
    ));
  }
}

CashRegisterReregisters.defaultProps = {
  loading: false,
  items: [],
  success: false,
  message: null
};

CashRegisterReregisters.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.array,
  success: PropTypes.bool,
  message: PropTypes.any
};

export default CashRegisterReregisters;
