import React from "react";
import PropTypes from "prop-types";
import DataProvider from "Components/New/DataProvider/DataProviderNew";
import Grid from "@material-ui/core/Grid";
import Menu from "./Menu";
import { Hidden, Drawer } from "@material-ui/core";
import storageHelper from "helpers/storageHelper";
import Products from "./Products";
import Breadcrumbs from "./Breadcrumbs";
import Search from "./Search";
import Product from "./Product";
import { BASE_PATH_CATALOG } from "./../shopPath";
export const VIEW_MODE_CARD = "VIEW_MODE_CARD";
export const VIEW_MODE_LIST = "VIEW_MODE_LIST";

class Catalog extends React.Component {
  constructor(props) {
    super(props);
    this.storage = storageHelper("session", "shop-catalog", this);
    this.state = {
      menuOpen: false,
      searchInputValue: "",
      filter: {},
      viewMode: "list",
      sortMode: null,
      menuState: {},
      isHome: true,
      ...this.storage.get() //storage értékek feltöltése
    };
  }
  handleMenuClick = open => () => {
    console.log("handleMenuClick", open);
    this.storage.set({ menuOpen: open });
  };
  handleViewModeClick = () => {
    this.storage.set({
      viewMode:
        this.state.viewMode === VIEW_MODE_CARD ? VIEW_MODE_LIST : VIEW_MODE_CARD
    });
  };

  handleSortModeClick = mode => {
    this.storage.set({
      sortMode: mode
    });
  };
  componentWillMount() {
    this.processHash();
  }

  processHash = () => {
    const { hash } = this.props.history.location;
    if (hash && hash !== this.hash) {
      this.hash = hash;
      const tag = decodeURI(hash.replace("#", ""));
      this.storage.set({
        isHome: false,
        searchInputValue: tag,
        filter: { keyword: tag },
        menuState: {}
      });
    }
  };

  handleSearchInputChange = evt => {
    this.storage.set({ searchInputValue: evt.target.value });
  };
  handleSelectedMenuChange = (selected, shouldCloseMenu) => {
    console.log("handleSelectedMenuChange", selected);
    this.storage.set(
      {
        isHome: false,
        menuState: selected,
        ...(shouldCloseMenu ? { searchInputValue: "" } : {}),
        ...(shouldCloseMenu ? { menuOpen: false } : {}) //Menü bezárása
      },
      () => {
        this.changePathIfNeeded();
        this.setFilter();
      }
    );
  };
  handleSearchClick = () => {
    //console.log("handleSearchClick");
    this.changePathIfNeeded();
    this.setFilter();
  };

  changePathIfNeeded = () => {
    const {
      history: {
        push,
        location: { pathname }
      }
    } = this.props;

    if (pathname !== BASE_PATH_CATALOG) {
      push(BASE_PATH_CATALOG);
    }
  };

  createMenuStateFromTag = () => {};

  UNSAFE_componentWillReceiveProps(nextProps) {
    /*const s = JSON.parse(sessionStorage.getItem("shop-catalog-refer"));
    console.log("receive", s);
    if (s && s.filter && s.filter.keyword) {
      sessionStorage.removeItem("shop-catalog-refer");
      this.storage.set({
        ...s,
        menuState: {}
      });
    }*/
    this.processHash();
  }

  componentWillUnmount() {
    this.storage.setStorage({
      searchInputValue: "",
      menuState: {},
      filter: {},
      isHome: true
    });
  }

  onHomeClick = () => {
    this.storage.set({
      isHome: true,
      searchInputValue: "",
      menuState: {},
      filter: {}
    });
  };

  setFilter = () => {
    const { menuState, searchInputValue } = this.state;
    let objFilter = {};
    let filter = "";
    if (
      menuState.selected &&
      menuState.selected.id !== "" &&
      menuState.selected.parents
    ) {
      //filter = `?tags[]=${menuState.selected.id.replace(/\|/g, "&tags[]=")}`; //Így minden szülő címkéjét elküldjük
      let parents = menuState.selected.parents;
      filter = `?tags[]=${parents[0].tags.join("&tags[]=")}`; //Így csak a kiválasztott elem címkéit
      //objFilter.tags = menuState.selected.parents[0].tags;
      let i = 0;
      parents[0].tags.forEach(tag => {
        objFilter[`tags[${i}]`] = tag;
        i++;
      });
    }
    if (searchInputValue && searchInputValue !== "") {
      filter += `${filter === "" ? "?" : "&"}keyword=${searchInputValue}`;
      objFilter.keyword = searchInputValue;
    }
    //console.log("setFilter", filter, objFilter);
    this.storage.set({ filter: objFilter });
  };
  render() {
    const { history } = this.props;
    //console.log(this.props.match.params);
    const {
      menuOpen,
      searchInputValue,
      menuState,
      viewMode,
      filter,
      sortMode,
      isHome
    } = this.state;

    const queryParams = {
      ...filter,
      ...(sortMode ? { sort: sortMode } : {}),
      ...(isHome ? { section: "home" } : {})
    };
    return (
      <div style={{ width: "100%", position: "relative" }}>
        <div
          className="parent"
          style={{
            display: "flex",
            alignTtems: "center"
          }}
        >
          <Hidden mdUp>
            <Drawer open={menuOpen} onClose={this.handleMenuClick(false)}>
              <Menu
                onHomeClick={this.onHomeClick}
                isHome={isHome}
                key={JSON.stringify(menuState.selected)}
                onClick={this.handleSelectedMenuChange}
                selected={menuState}
              />
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <div className="left" style={{ paddingRight: 16 }}>
              <Menu
                onHomeClick={this.onHomeClick}
                isHome={isHome}
                key={JSON.stringify(menuState.selected)}
                onClick={this.handleSelectedMenuChange}
                selected={menuState}
              />
            </div>
          </Hidden>

          <div
            className="right"
            style={{
              flexGrow: 1
            }}
          >
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Breadcrumbs item={menuState.selected} />
                <Search
                  onMenuclick={this.handleMenuClick(true)}
                  onChange={this.handleSearchInputChange}
                  value={searchInputValue}
                  onSearchClick={this.handleSearchClick}
                  onEnter={this.handleSearchClick}
                  onViewModeClick={this.handleViewModeClick}
                  onSortModeClick={this.handleSortModeClick}
                  viewMode={viewMode}
                  sortMode={sortMode}
                />
              </Grid>
              <Grid item xs={12}>
                <DataProvider
                  key={JSON.stringify(queryParams)}
                  disableOptions
                  url={"shop-products"}
                  //url={`shop-products${filter}`}
                  queryParams={queryParams}
                  tableComponent={Products}
                  viewComponent={Product}
                  tableProps={{
                    viewMode
                  }}
                  viewProps={{
                    history
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

Catalog.propTypes = {
  history: PropTypes.object.isRequired
};
export default Catalog;
