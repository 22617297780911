import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get } from "lodash";

class ModifyPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAccess: false
    };
  }

  componentWillMount = () => {
    let isAccess = false;
    if (this.props.user.root) {
      isAccess = true;
    } else {
      const perms = get(this.props.permissions, this.props.module, false);
      //console.log(this.props);
      if (perms) {
        const access = get(perms, "access");
        if (access.indexOf("MODIFY") !== -1) {
          isAccess = true;
        } else if (
          access.indexOf("MODIFY_OWN") !== -1 &&
          get(this.props, "isOwn", true)
        ) {
          isAccess = true;
        }
      }
    }
    this.setState({
      isAccess
    });
  };

  render = () => {
    return <Fragment>{this.state.isAccess && this.props.children}</Fragment>;
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    permissions: state.auth.permissions
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyPermission);
