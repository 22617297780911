import React, { PureComponent } from "react";

import Validations from "Components/Forms/Validations";
import { Field } from "redux-form";
import InputField from "Components/Forms/Fields/Input";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import SelectField from "Components/Forms/Fields/Select";

class Item extends PureComponent {
  render() {
    const {
      isViewPage,
      fieldError,
      index,
      collector,
      taxes,
      deleteCell
    } = this.props;

    return (
      <TableRow>
        <TableCell>
          <Field
            name={`${collector}.name`}
            fieldError={fieldError.name}
            component={InputField}
            isViewPage={isViewPage}
            inputRootClass={"simpleImputRoot"}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${collector}.value_limit`}
            fieldError={fieldError.value_limit}
            component={InputField}
            isViewPage={isViewPage}
            inputRootClass={"simpleImputRoot"}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${collector}.tax`}
            fieldError={fieldError.tax}
            component={SelectField}
            isViewPage={isViewPage}
            options={taxes}
          />
        </TableCell>
        {deleteCell(index)}
      </TableRow>
    );
  }
}

export default Item;
