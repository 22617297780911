import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import Wrapper from "./Wrapper";

import PropTypes from "prop-types";

export default class Wysiwyg extends Component {
  static propTypes = {
    input: PropTypes.any.isRequired,
    label: PropTypes.string,
    meta: PropTypes.shape({
      touched: PropTypes.bool.isRequired,
      error: PropTypes.string,
      warning: PropTypes.string
    }),
    fieldError: PropTypes.string
  };

  constructor(props) {
    super(props);
    /*const html = this.props.input.value;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState
      };
    }*/
    this.state = {
      editorState: EditorState.createEmpty()
    };
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.input.value !== this.props.input.value) {
      const currentValue = draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      );

      //HACK: Csak először firssítjük a komponenst. Sajnos a redux input onChange eseményére ez a függvény mindig meghívódik.
      if (currentValue.trim() !== "<p></p>") {
        return;
      }
      const contentBlock = htmlToDraft(nextProps.input.value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
          editorState
        });
      }
    }
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  render() {
    const { input, label, minHeight } = this.props;
    const { editorState } = this.state;
    return (
      <Wrapper {...this.props} value={input.value}>
        <label>{label}</label>
        <Editor
          editorStyle={{
            width: "100%",
            minHeight: minHeight || 100,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "lightgray"
          }}
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          onEditorStateChange={this.onEditorStateChange}
          onChange={evt => {
            input.onChange(draftToHtml(evt));
          }}
        />
      </Wrapper>
    );
  }
}
