import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
//import TextField from "@material-ui/core/TextField";
import TextField from "Components/New/Input/TextField/TextField";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import Select from "Components/New/Input/Select/Select";
import { withRouter, Link, Route, Switch, Redirect } from "react-router-dom";
// import ReactDataSheet from "react-datasheet";
// Be sure to include styles at some point, probably during your bootstrapping
// import "react-datasheet/lib/react-datasheet.css";
import "handsontable/dist/handsontable.full.css";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import restHelper from "helpers/restHelper";
import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import { merge } from "lodash";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  IconButton,
  Button,
  Toolbar
} from "@material-ui/core";

const styles = theme => ({
  /*"@global": {
    ".rbt-input-hint": {
      //backgroundColor: "red!important",
      width: "100%",
      top: "2px!important"
    }
  },*/
  rowDeleted: {
    backgroundColor: "red!important",
    color: "white"
  },
  rowChanged: {
    backgroundColor: "green!important",
    color: "white"
  },
  actionCell: {
    width: 70
  },
  actionCellIcon: {
    width: 24,
    height: 24
  },
  actionCellButton: {
    width: 28,
    height: 28,
    padding: 0
  },
  row: {
    height: "auto",
    "&:hover": {
      backgroundColor: "#ddd"
    }
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0
  },
  toolbarSpacer: {
    marginLeft: "auto"
  }
});

const ActionCellBase = props => {
  const { classes, row, rowIndex, onEdit, onDelete } = props;
  return (
    <TableCell className={classes.actionCell} padding="none">
      <IconButton
        className={classes.actionCellButton}
        onClick={() => onEdit(row, rowIndex)}
      >
        <EditIcon className={classes.actionCellIcon} />
      </IconButton>
      <IconButton
        className={classes.actionCellButton}
        onClick={() => onDelete(row, rowIndex)}
      >
        <DeleteIcon className={classes.actionCellIcon} />
      </IconButton>
    </TableCell>
  );
};

const ActionCell = withStyles(styles)(ActionCellBase);

class DataForm extends React.Component {
  render() {
    const {
      classes,
      data,
      editItem,
      onEdit,
      onDelete,
      onEndEdit,
      onSave,
      onChange,
      errors,
      onlyBasefieldsOnForm,
      validateField,
      isRequiredField
    } = this.props;

    const grid = this.props.options ? this.props.options.grid : null;
    const form = this.props.options ? this.props.options.form : null;
    //console.log("DataForm.render", this.props);
    if (!grid) {
      return "NINCS GRID";
    }
    if (!form) {
      return "NINCS FORM";
    }
    const visibleColumnsBase = {};

    if (!onlyBasefieldsOnForm) {
      Object.keys(grid.columns).forEach(key => {
        let col = grid.columns[key];
        if (col.hidden) {
          return;
        }
        col.key = key;
        visibleColumnsBase[key] = col;
      });
    }
    Object.keys(form.baseFields).forEach(key => {
      let col = Object.assign({}, form.baseFields[key]);

      if (col.hidden) {
        return;
      }
      col.key = key;
      if (visibleColumnsBase[key]) {
        visibleColumnsBase[key] = merge(visibleColumnsBase[key], col);
      } else {
        visibleColumnsBase[key] = col;
      }
    });

    const visibleColumns = Object.keys(visibleColumnsBase).map(key => {
      return visibleColumnsBase[key];
    });

    return (
      <Fragment>
        <Grid container spacing={16}>
          {visibleColumns.map((col, colIndex) => {
            const key = col.key;
            /*const col = merge(
              {},
              (grid.columns && grid.columns[key]) || {},
              (form.baseFields && form.baseFields[key]) || {}
            );*/
            //console.log(col);
            const error = errors[key] ? errors[key].join(",") : null;
            return (
              <Grid key={colIndex} item xs={12} sm={6} md={4} lg={3}>
                {col.type === "dropdown" && (
                  <Select
                    id={key}
                    label={col.name || key}
                    fullWidth
                    variant="outlined"
                    key={`header.${colIndex}`}
                    padding="none"
                    value={editItem[key] || ""}
                    onChange={(value, item) => onChange(key, value, item)}
                    url="clients"
                    labelKey="name"
                    type={col.type}
                    options={col.data}
                    error={error}
                  />
                )}
                {col.type === "autocomplete" && (
                  <Select
                    id={key}
                    label={col.name || key}
                    fullWidth
                    defaultOptions
                    variant="outlined"
                    key={`header.${colIndex}`}
                    padding="none"
                    value={editItem[key] || ""}
                    onChange={(item, value) => onChange(key, value, item)}
                    url={col.url.path.replace("/v1/", "")}
                    //labelKey="name"
                    type={col.type}
                    requestParams={col.url.params}
                    error={error}
                  />
                )}
                {!col.type && (
                  <TextField
                    id={key}
                    label={col.name || key}
                    fullWidth
                    variant="outlined"
                    key={`header.${colIndex}`}
                    padding="none"
                    value={editItem[key] || ""}
                    onChange={evt => onChange(key, evt.target.value)}
                    error={error}
                    mask={col.mask}
                    isRequired={isRequiredField(key)}
                    onBlur={() => validateField(key)}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarSpacer} />
          <Button onClick={onEndEdit} size="small">
            Mégsem
          </Button>
          <Button onClick={onSave} size="small" color="primary">
            Mentés
          </Button>
        </Toolbar>
      </Fragment>
    );
  }
}

DataForm.propTypes = {
  classes: PropTypes.object,
  validateField: PropTypes.func.isRequired,
  isRequiredField: PropTypes.func.isRequired
};

export default withStyles(styles)(DataForm);
