import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as annualReviewsActions from "../../../../redux/modules/annual-reviews";
import * as cashregistersActions from "../../../../redux/modules/cashregisters";
import * as optionsActions from "../../../../redux/modules/options";
import CashregisterKeyGenerators from "./views/CashregisterKeyGenerators";

const mapStateToProps = state => {
  return {
    loading: state.cashregisters.key_loading,
    success: state.cashregisters.key_success,
    saved: state.cashregisters.key_saved,
    message: state.cashregisters.key_message,
    fieldErrors: state.cashregisters.key_formErrors,
    checkKeyErrors: state.annualReviews.formErrors,
    generated_code: state.cashregisters.generated_code,
    annual_review_data: state.annualReviews.viewData,
    annual_loading: state.annualReviews.loading,
    annual_success: state.annualReviews.success,
    annual_message: state.annualReviews.message,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: cashregistersActions.sendKey,
      checkKey: annualReviewsActions.checkKey,
      checkTokenKey: annualReviewsActions.checkTokenKey,
      setViewData: annualReviewsActions.setViewData,
      resetCode: cashregistersActions.resetGeneratedCode,
      getFormInfo: optionsActions.getFormInfo
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)(CashregisterKeyGenerators);
