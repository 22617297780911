import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EventTypesList from "./views/EventTypesList";
import * as eventTypesActions from "../../../../redux/modules/event-types";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.eventTypes.loading,
    success: state.eventTypes.success,
    message: state.eventTypes.message,
    data: state.eventTypes.data,
    total: state.eventTypes.total,
    limit: state.eventTypes.limit,
    page: state.eventTypes.page,
    order: state.eventTypes.order,
    orderMode: state.eventTypes.orderMode,
    deleted: state.eventTypes.deleted,
    deletedId: state.eventTypes.deletedId,
    token: state.eventTypes.token,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: eventTypesActions.EventsList,
      delete: eventTypesActions.EventsDelete,
      setLoading: eventTypesActions.setLoading,
      reset: eventTypesActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventTypesList);
