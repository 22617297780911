import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ExamineeExamsList from "./views/ExamineeExamsList";
import * as examineeExamsActions from "../../../../redux/modules/examinee-exams";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.examineeExams.loading,
    success: state.examineeExams.success,
    message: state.examineeExams.message,
    data: state.examineeExams.data,
    total: state.examineeExams.total,
    limit: state.examineeExams.limit,
    page: state.examineeExams.page,
    order: state.examineeExams.order,
    orderMode: state.examineeExams.orderMode,
    deleted: state.examineeExams.deleted,
    deletedId: state.examineeExams.deletedId,
    reload: state.examineeExams.reload,
    token: state.examineeExams.token,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: examineeExamsActions.examsList,
      aproveExam: examineeExamsActions.aproveExam,
      declineExam: examineeExamsActions.declineExam,
      downloadExam: examineeExamsActions.downloadExam,
      delete: examineeExamsActions.examDelete,
      setLoading: examineeExamsActions.setLoading,
      reset: examineeExamsActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamineeExamsList);
