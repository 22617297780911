import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Toolbar,
  Paper,
  Typography,
  InputLabel,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Checkbox,
  TableHead
} from "@material-ui/core";
import HistoryBox from "Components/HistoryBox/HistoryBox";
import restHelper from "helpers/restHelper";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SplitIcon from "@material-ui/icons/CallSplit";
import formatHelper from "helpers/formatHelper";
import ProductLink from "./../Catalog/ProductLink";
import ModalConfirm from "Components/Modal/ModalConfirm";
import { Link } from "react-router-dom";
import { BASE_PATH_CART_SUCCESS, BASE_PATH_CART_CHECKOUT } from "./../shopPath";
import { get } from "lodash";
import { splitOrder } from "redux/modules/shopOrder";
import TextField from "Components/New/Input/TextField/TextField";
import Select from "Components/New/Input/Select/Select";
import localize from "helpers/localize";
const styles = theme => ({});

class OrderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      splitMode: false,
      selectedIds: [],
      editingProductIndex: null
    };
    this.modal = React.createRef();
  }

  componentDidMount() {
    this.props.onlyProductsAndHistory === false &&
      this.loadHistory(this.props.order.item.id);
  }

  loadHistory = id => {
    const { isAdmin } = this.props;
    if (id) {
      const context = this;
      const controller = isAdmin ? "shop-admin-orders" : "shop-orders";
      this.historyTimeout = setTimeout(() => {
        restHelper.index(`${controller}/events/${id}`).then(response => {
          //console.log("RESP", response.data);
          context.setState({ events: response.data });
        });
      }, 200);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.order.item) !==
      JSON.stringify(nextProps.order.item)
    ) {
      //Változtak az adatok
      this.props.onlyProductsAndHistory === false &&
        this.loadHistory(nextProps.order.item.id);
    }
    //Ez talán egy nézetről szerkesztésre váltás
    /*console.log(
      this.props.isViewPage,
      nextProps.isViewPage,
      this.props.history.location.pathname,
      nextProps.history.location.pathname
    );
    if (this.props.isViewPage && !nextProps.isViewPage) {
      const { initialize, initialValues } = this.props;
      console.log("NA MOST");
      setTimeout(() => {
        initialize(initialValues);
      }, 200);
    }*/
  }

  concatAddress = prefix => {
    const {
      order: { item }
    } = this.props;
    return (
      (item[`${prefix}zip`] ? " " + item[`${prefix}zip`] : "") +
      (item[`${prefix}city`] ? " " + item[`${prefix}city`] : "") +
      (item[`${prefix}street`] ? " " + item[`${prefix}street`] : "") +
      (item[`${prefix}street_suffix`]
        ? " " + item[`${prefix}street_suffix`]
        : "") +
      (item[`${prefix}street_details`]
        ? " " + item[`${prefix}street_details`]
        : "")
    );
  };

  toggleSplit = value => () => {
    this.setState({ splitMode: value, selectedIds: [] });
  };

  onSplit = () => {
    const { selectedIds } = this.state;
    const { order, refreshView } = this.props;
    if (selectedIds.length > 0) {
      this.modal
        .open(
          localize("shop", "Bontás megerősítése"),
          localize("shop", "Biztosan szétbontja a rendelést?"),
          null, //this.state.file.file_name,
          localize("basic", "Ok"),
          localize("basic", "Mégsem")
        )
        .then(() => {
          this.modal.loading(true);
          //console.log("Mehet a bontás", selectedIds);
          splitOrder(order.item.id, selectedIds).then(res => {
            //console.log(res);

            refreshView(true).then(() => {
              this.modal && this.modal.loading(false);
              this.modal && this.modal.close();
              this.toggleSplit(false)();
            });
          });
        });
    } else {
      this.modal
        .open(
          localize("shop", "Figyelmeztetés"),
          localize("shop", "Nincs termék kiválasztva."),
          null, //this.state.file.file_name,
          localize("basic", "Ok"),
          false
        )
        .then(() => {
          this.modal.close();
        });
    }
  };
  onSelectionChange = item => evt => {
    let ids = this.state.selectedIds.slice(0);
    let index = ids.indexOf(item.id);
    {
      if (index >= 0) {
        if (!evt.target.checked) {
          ids.splice(index, 1);
        }
      } else {
        if (evt.target.checked) {
          ids.push(item.id);
        }
      }
    }
    this.setState({ selectedIds: ids });
  };

  render() {
    const {
      theme,
      classes,
      items,
      total,
      totalPrice,
      order,
      changeStep,
      onChange,
      isAdmin,
      isAdminView,
      onlyProductsAndHistory
    } = this.props;
    const colorProps = {};
    const { events, splitMode, selectedIds, editingProductIndex } = this.state;
    if (theme.header.iconColor) {
      colorProps.color = theme.header.iconColor;
    }
    //console.log("REnd", this.props);
    const getOption = name =>
      get(order, `options.order.baseFields.${name}.data`, {});
    const getOptionDefault = name => {
      const defaultValue = get(
        order,
        `options.order.baseFields.${name}.default`,
        null
      );
      const defaultLabel = get(
        order,
        `options.order.baseFields.${name}.data.${defaultValue}`,
        null
      );
      return {
        value: parseInt(defaultValue),
        label: defaultLabel
      };
    };
    const payment_methods = getOption("payment_method");
    const statuses = getOption("status");
    const shipping_methods = Object.assign({}, getOption("shipping_method"));
    const shipping_fee = getOptionDefault("shipping_fee");
    shipping_methods.COURIER += ` ${shipping_fee.label}`;

    const OrderField = ({ name, title, options }) => {
      const value = options ? options[order.item[name]] : order.item[name];
      if (!value || value === "") {
        return null;
      }
      return (
        <TableRow>
          <TableCell>
            <InputLabel>{localize("shop", title)}</InputLabel>
          </TableCell>
          <TableCell>{value}</TableCell>
        </TableRow>
      );
    };
    const OtherOrdersField = ({ titleChildren, titleParent }) => {
      const parent = order.item.parent_order_id;
      const children = order.item.child_order_ids;

      if (!parent && !children) {
        return null;
      }
      return (
        <Fragment>
          {parent && (
            <TableRow>
              <TableCell>
                <InputLabel>{localize("shop", titleParent)}</InputLabel>
              </TableCell>
              <TableCell>
                <Link to={`/shop/admin-orders/edit/${parent}`}>#{parent}</Link>
              </TableCell>
            </TableRow>
          )}
          {children && children.length > 0 && (
            <TableRow>
              <TableCell>
                <InputLabel>{localize("shop", titleChildren)}</InputLabel>
              </TableCell>
              <TableCell>
                {children.map((child, key) => {
                  return (
                    <Fragment key={key}>
                      <Link to={`/shop/admin-orders/edit/${child}`}>
                        #{child}
                      </Link>
                      {key < children.length - 1 ? ", " : ""}
                    </Fragment>
                  );
                })}
              </TableCell>
            </TableRow>
          )}
        </Fragment>
      );
    };
    const OrderAddressField = ({ title, prefix }) => {
      const pr = prefix || "";
      const value = this.concatAddress(pr);
      if (!value || value === "") {
        return null;
      }
      return (
        <TableRow>
          <TableCell>
            <InputLabel>{title}</InputLabel>
          </TableCell>
          <TableCell>{value}</TableCell>
        </TableRow>
      );
    };

    return (
      <Fragment>
        {onlyProductsAndHistory === false && (
          <Grid item xs={12}>
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" align="center">
                      {localize("shop", "Számlázási adatok")}
                    </Typography>
                  </Grid>
                  {/*Számlázási mezők start*/}
                  <Grid item xs={12}>
                    <Paper>
                      <Table padding="dense">
                        <TableBody>
                          <OrderField name="tax_number" title="Adószám" />
                          <OrderField name="name" title="Név" />
                          <OrderField name="phone" title="Mobil" />
                          <OrderField name="email" title="Email" />
                          <OrderAddressField title="Cím" />
                          <OrderField name="comment" title="Megjegyzés" />
                          {(isAdmin || isAdminView) && (
                            <OrderField
                              name="admin_comment_private"
                              title="Belső megjegyzés"
                            />
                          )}
                          <OrderField
                            name="admin_comment_public"
                            title="Áruház megjegyzése"
                          />
                          {isAdmin && (
                            <OtherOrdersField
                              titleChildren="Bontott rendelések"
                              titleParent="Fő rendelés"
                            />
                          )}
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                  {/*Számlázási mezők end*/}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" align="center">
                      {localize("shop", "Szállítási / fizetési adatok")}
                    </Typography>
                  </Grid>
                  {/*Szállítási mezők start*/}
                  <Grid item xs={12}>
                    <Paper>
                      <Table padding="dense">
                        <TableBody>
                          <OrderField
                            name="payment_method"
                            options={payment_methods}
                            title="Fizetési mód"
                          />
                          <OrderField
                            name="shipping_method"
                            options={shipping_methods}
                            title="Szállítási mód"
                          />
                          <OrderField name="delivery_phone" title="Mobil" />
                          <OrderAddressField title="Cím" prefix="delivery_" />
                          <OrderField
                            name="status"
                            options={statuses}
                            title="Státusz"
                          />
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                  {/*Szállítási mezők end*/}
                  {events && events.length > 0 && (
                    <Fragment>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" align="center">
                          {localize("shop", "Történet")}
                        </Typography>
                      </Grid>
                      {/*Történet mezők start*/}
                      <Grid item xs={12}>
                        <Paper>
                          <HistoryBox
                            /*url={`worksheets/events/${worksheet.id}`*/ events={
                              events
                            }
                          />
                        </Paper>
                      </Grid>
                    </Fragment>
                  )}
                  {/*történet mezők end*/}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} style={{ position: "relative" }}>
          <Typography variant="subtitle2" align="center">
            {localize("shop", "Termékek")}
          </Typography>
          {isAdmin &&
            ["NEW", "PREPARING", "OUT_OF_STOCK"].indexOf(order.item.status) >=
              0 && (
              <Fragment>
                {splitMode ? (
                  <Fragment>
                    <Tooltip
                      title={localize("shop", "Rendelés felosztása")}
                      disableFocusListener
                    >
                      <IconButton
                        style={{
                          position: "absolute",
                          right: 85,
                          top: 0,
                          padding: 7
                        }}
                        onClick={this.onSplit}
                      >
                        <SplitIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={localize("shop", "Kilépés")}
                      disableFocusListener
                    >
                      <IconButton
                        style={{
                          position: "absolute",
                          right: 49,
                          top: 0,
                          padding: 7
                        }}
                        onClick={this.toggleSplit(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Fragment>
                ) : (
                  <Tooltip
                    title={localize(
                      "shop",
                      "Kiválasztott termékek szétbontása"
                    )}
                    disableFocusListener
                  >
                    <IconButton
                      style={{
                        position: "absolute",
                        right: 49,
                        top: 0,
                        padding: 7
                      }}
                      onClick={this.toggleSplit(true)}
                    >
                      <SplitIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  title={localize("shop", "Új termék hozzáadása")}
                  disableFocusListener
                >
                  <IconButton
                    style={{
                      position: "absolute",
                      right: 8,
                      top: 0,
                      padding: 7
                    }}
                    onClick={() => {
                      let newItems = order.item.items.slice();
                      newItems.push({
                        original_price: null,
                        price: null,
                        quantity: null
                      });
                      onChange("items", newItems);
                      this.setState({
                        editingProductIndex: newItems.length - 1
                      });
                    }}
                  >
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
              </Fragment>
            )}
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Table padding="dense">
              <TableHead>
                <TableRow>
                  <TableCell>{localize("shop", "Név")}</TableCell>
                  <TableCell>{localize("shop", "Kód")}</TableCell>
                  <TableCell>{localize("shop", "Mennyiség")}</TableCell>
                  <TableCell>{localize("shop", "Lista ár")}</TableCell>
                  <TableCell>{localize("shop", "Ár")}</TableCell>
                  <TableCell>{localize("shop", "Tétel ár")}</TableCell>
                  <TableCell colSpan={2} />
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => {
                  //console.log(index, item.product.name, item);
                  return (
                    <TableRow key={index}>
                      {splitMode && (
                        <TableCell style={{ width: 48, padding: 0 }}>
                          <Checkbox
                            onChange={this.onSelectionChange(item)}
                            checked={selectedIds.indexOf(item.id) >= 0}
                            style={{ padding: 9 }}
                          />
                        </TableCell>
                      )}
                      {editingProductIndex === index && !item.id ? (
                        <TableCell>
                          <Select
                            value={item.product.id}
                            url="shop-admin-orders/autocomplete"
                            type="autocomplete"
                            labelKey="name"
                            fullWidth
                            //defaultOptions
                            onChange={option => {
                              let newItems = order.item.items.slice();
                              newItems[index].shop_product_id = option
                                ? option.id
                                : null;
                              newItems[index].shop_product_name = option
                                ? option.name
                                : null;
                              newItems[index].price = option
                                ? option.price
                                : null;
                              newItems[index].original_price = option
                                ? option.price
                                : null;
                              onChange("items", newItems);
                            }}
                          />
                        </TableCell>
                      ) : (
                        <TableCell>
                          <ProductLink id={item.product.id}>
                            {item.product.name}
                          </ProductLink>
                        </TableCell>
                      )}
                      <TableCell style={{ color: "#aaa" }}>
                        <ProductLink id={item.product.id}>
                          {item.product.sku}
                        </ProductLink>
                      </TableCell>
                      {editingProductIndex === index ? (
                        <TableCell>
                          <TextField
                            style={{ width: 80 }}
                            value={item.quantity}
                            type="number"
                            onChange={evt => {
                              let newItems = order.item.items.slice();
                              newItems[index].quantity = evt.target.value;
                              onChange("items", newItems);
                            }}
                          />{" "}
                          <span
                            style={{
                              lineHeight: "32px"
                            }}
                          >
                            {localize("shop", "db")}
                          </span>
                        </TableCell>
                      ) : (
                        <TableCell>
                          {item.quantity} {localize("shop", "db")}
                        </TableCell>
                      )}
                      <TableCell>
                        {formatHelper.currency(
                          !item.original_price ||
                            item.original_price < item.price
                            ? item.price
                            : item.original_price
                        )}{" "}
                        + {localize("shop", "Áfa")}
                      </TableCell>
                      {editingProductIndex === index ? (
                        <TableCell>
                          <TextField
                            style={{ width: 100 }}
                            value={item.price}
                            type="number"
                            onChange={evt => {
                              let newItems = order.item.items.slice();
                              newItems[index].price = evt.target.value;
                              onChange("items", newItems);
                            }}
                          />{" "}
                          <span
                            style={{
                              lineHeight: "32px"
                            }}
                          >
                            + {localize("shop", "Áfa")}
                          </span>
                        </TableCell>
                      ) : (
                        <TableCell
                          style={{
                            color:
                              item.original_price &&
                              item.original_price > item.price
                                ? "green"
                                : undefined
                          }}
                        >
                          {formatHelper.currency(item.price)} +{" "}
                          {localize("shop", "Áfa")}
                        </TableCell>
                      )}
                      <TableCell>
                        {formatHelper.currency(item.price * item.quantity)} +
                        {localize("shop", "Áfa")}
                      </TableCell>
                      {isAdmin && (
                        <TableCell align="right" style={{ paddingRight: 0 }}>
                          {editingProductIndex === index ? (
                            <Tooltip
                              title={localize(
                                "shop",
                                "Szerkesztés kikapcsolása"
                              )}
                            >
                              <IconButton
                                key={index}
                                style={{
                                  padding: 7
                                }}
                                onClick={() => {
                                  this.setState({
                                    editingProductIndex: null
                                  });
                                }}
                                //style={{ padding: 0, marginLeft: index > 0 ? 8 : 0 }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title={localize("shop", "Szerkesztés")}>
                              <IconButton
                                key={index}
                                style={{
                                  padding: 7
                                }}
                                onClick={() => {
                                  this.setState({
                                    editingProductIndex: index //order.item.items[index]
                                  });
                                }}
                                //style={{ padding: 0, marginLeft: index > 0 ? 8 : 0 }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title={localize("shop", "Törlés")}>
                            <IconButton
                              key={index}
                              style={{
                                padding: 7
                              }}
                              onClick={() => {
                                let newItems = order.item.items.slice();
                                newItems.splice(index, 1);
                                onChange("items", newItems);
                                this.setState({
                                  editingProductIndex: null
                                });
                              }}
                              //style={{ padding: 0, marginLeft: index > 0 ? 8 : 0 }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {order.item.shipping_method &&
          order.item.shipping_method !== "NONE" ? (
            <Fragment>
              <Typography variant="subtitle1" align="right">
                {localize("shop", "Termékek összesen")}:{" "}
                  {formatHelper.currency(totalPrice)} + {localize("shop", "Áfa")}
              </Typography>
              <Typography variant="subtitle1" align="right">
                {localize("shop", "Szállítási díj")}:{" "}
                  {formatHelper.currency(shipping_fee.value)} +
                {localize("shop", "Áfa")}
              </Typography>
              <Typography variant="h6" align="right">
                {localize("shop", "Összesen")}:{" "}
                {formatHelper.currency(totalPrice + shipping_fee.value)} +{" "}
                  {localize("shop", "Áfa")}
              </Typography>
            </Fragment>
          ) : (
            <Typography variant="h6" align="right">
              {localize("shop", "Összesen")}:{" "}
                {formatHelper.currency(totalPrice)} + {localize("shop", "Áfa")}
            </Typography>
          )}
        </Grid>
        <ModalConfirm onRef={ref => (this.modal = ref)} />
      </Fragment>
    );
  }
}

OrderView.defaultProps = {
  onlyProductsAndHistory: false
};

OrderView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  items: PropTypes.array,
  total: PropTypes.number,
  isAdmin: PropTypes.bool,
  onlyProductsAndHistory: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(OrderView);
