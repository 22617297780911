import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AnnualReviewsList from "./views/AnnualReviewsList";
import * as annualReviewsActions from "../../../../redux/modules/annual-reviews";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.annualReviews.loading,
    success: state.annualReviews.success,
    message: state.annualReviews.message,
    data: state.annualReviews.data,
    total: state.annualReviews.total,
    limit: state.annualReviews.limit,
    page: state.annualReviews.page,
    order: state.annualReviews.order,
    orderMode: state.annualReviews.orderMode,
    deleted: state.annualReviews.deleted,
    deletedId: state.annualReviews.deletedId,
    token: state.annualReviews.token,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: annualReviewsActions.annualReviewsList,
      delete: annualReviewsActions.annualReviewDelete,
      setLoading: annualReviewsActions.setLoading,
      reset: annualReviewsActions.reset,
      view: annualReviewsActions.viewAnnualReviews
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnualReviewsList);
