import React, { Component } from "react";
import PropTypes from "prop-types";
import WorksheetDetails from "Containers/Pages/Signing/WorksheetDetails";
import restHelper from "helpers/restHelper";
import { CircularProgress } from "@material-ui/core";
import fieldHelper from "helpers/fieldHelper";

class WorksheetModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      worksheet: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      this.loadWorksheet(nextProps.id);
    }
  }

  loadWorksheet = id => {
    this.setState({ loading: true });
    restHelper
      .view("worksheets/options", id)
      .then(optionsResponse => {
        const fh = fieldHelper(optionsResponse.data.form.baseFields);
        restHelper
          .view("worksheets", id)
          .then(response => {
            response.data.delivery = fh.optionName(
              "delivery",
              response.data.delivery
            );
            response.data.work_type = fh.optionName(
              "work_type",
              response.data.work_type
            );
            response.data.error_type = fh.optionName(
              "error_type",
              response.data.error_type
            );
            response.data.mechanic_id = fh.optionName(
              "mechanic_id",
              response.data.mechanic_id
            );
            response.data.payment = fh.optionName(
              "payment",
              response.data.payment
            );
            response.data.status_text = fh.optionName(
              "status",
              response.data.status
            );
            this.setState({ worksheet: response.data });
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, worksheet } = this.state;

    return loading ? (
      <CircularProgress />
    ) : (
      worksheet && (
        <WorksheetDetails
          worksheet={worksheet}
          worksheetToken={worksheet.view_token}
          forceShowSecondSection
        />
      )
    );
  }
}

WorksheetModalContent.propTypes = {
  id: PropTypes.number //Worksheet id
};

export default WorksheetModalContent;
