import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MainRoutes from "../../routes/MainRoutes";
import { Layout } from "../index";

import { fetchLocale } from "helpers/localize";
class App extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      langLoaded: false
    };
  }

  componentDidMount() {
    fetchLocale().then(success => {
      this.setState({ langLoaded: true });
    });
  }

  render = () => (
    <Layout {...this.props}>
      <MainRoutes />
    </Layout>
  );
}
const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
