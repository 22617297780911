import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ArticlesList from "./views/ArticlesList";
import * as articlesActions from "redux/modules/articles";
import * as optionsActions from "redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.articles.loading,
    success: state.articles.success,
    message: state.articles.message,
    data: state.articles.data,
    total: state.articles.total,
    limit: state.articles.limit,
    page: state.articles.page,
    order: state.articles.order,
    orderMode: state.articles.orderMode,
    deleted: state.articles.deleted,
    deletedId: state.articles.deletedId,
    reload: state.articles.reload,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: articlesActions.articlesList,
      delete: articlesActions.articleDelete,
      setLoading: articlesActions.setLoading,
      articlesHightLight: articlesActions.articlesHightLight,
      reset: articlesActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticlesList);
