import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import List from "Components/List/List";
import ContainerHeader from "Components/ContainerHeader";
import Navbar from "Containers/Pages/Cashregisters/Navbar";
import { Link } from "react-router-dom";
import localize from "helpers/localize";

class ChangesList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      keyword: null,
      search: null,
      filters: null,
      perPage: null
    };
  }
  componentWillReceiveProps = nextProps => {
    if (!this.props.reload && nextProps.reload) {
      const { page, keyword, sort, filters, perPage } = this.state;
      this.getList(page, keyword, sort, filters, perPage);
    }
  };

  getList = (page, keyword, sort, filters, perPage) => {
    this.setState({
      page,
      keyword,
      sort,
      filters,
      perPage
    });
    return this.props.getList(page, keyword, sort, filters, perPage);
  };

  customIcons = obj => {};

  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Navbar {...this.props} />
        <Helmet>
          <title>{localize("cashregisters", "Szerviz váltás")}</title>
        </Helmet>
        <ContainerHeader title={localize("cashregisters", "Szerviz váltás")} />
        <List
          {...this.props}
          disableDeleteIcon
          //disableViewIcon
          //customIcons={this.customIcons.bind(this)}
          getList={this.getList.bind(this)}
          renderCell={(row, rowIndex, colIndex, header) => {
            if (
              header._key === "cashregister_apn" &&
              row.cashregister_id &&
              row.can_ap_view
            ) {
              return (
                <Link to={`/cashregisters/view/${row.cashregister_id}`}>
                  {row.cashregister_apn}
                </Link>
              );
            }
            return false;
          }}
          rowFormat={item => {
            if (item.status === "accepted") {
              return {
                backgroundColor: "#e8f5e9"
              };
            }
            if (item.status === "declined") {
              return {
                backgroundColor: "#ffcdd2"
              };
            }
            if (item.status === "pending") {
              return {
                backgroundColor: "#fff9c4"
              };
            }
          }}
        />
      </div>
    );
  }
}

ChangesList.propTypes = {
  getList: PropTypes.func.isRequired,
  //articlesHightLight: PropTypes.func.isRequired,
  reload: PropTypes.bool
};

export default ChangesList;
