import React, {Component, Fragment} from 'react';
import * as fileActions from "../../redux/modules/file";

class Image extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: false
        };
    }

    componentWillMount = () => {
        fileActions.fetchFile(this.props.image).then(uint8Array => {
            if(uint8Array){
                const ext = this.props.image.split('.').pop().toLowerCase();
                const raw = uint8Array.reduce((data, byte) => data + String.fromCharCode(byte), '');
                const b64 = btoa(raw);
                this.setState({
                    image: "data:image/"+ext+";base64,"+b64
                });

            }
        });
    };


    render() {
        return  <Fragment>
            {this.state.image && <Fragment><img src={this.state.image} style={this.props.style} className="img-fluid mb-1" alt=""/></Fragment>}
        </Fragment>
    }
}

export default Image;
