import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import { TABLE_HEADING_TYPE } from "@devexpress/dx-grid-core";
import { Getter, Template, Plugin } from "@devexpress/dx-react-core";
import { Table } from "@devexpress/dx-react-grid-material-ui";

const pluginDependencies = [{ name: "Table" }];

const ACTIONS_COLUMN_TYPE = "actionsColumnType";

function tableColumnsWithActions(tableColumns, width) {
  return [
    ...tableColumns,
    { key: ACTIONS_COLUMN_TYPE, type: ACTIONS_COLUMN_TYPE, width: width }
  ];
}

function isHeadingActionsTableCell(tableRow, tableColumn) {
  return (
    tableRow.type === TABLE_HEADING_TYPE &&
    tableColumn.type === ACTIONS_COLUMN_TYPE
  );
}

function isActionsTableCell(tableRow, tableColumn) {
  return (
    tableRow.type !== TABLE_HEADING_TYPE &&
    tableColumn.type === ACTIONS_COLUMN_TYPE
  );
}

class ActionsColumn extends React.PureComponent {
  render() {
    const { actions, width, canDeleteFunc, fetchData } = this.props;
    const tableColumnsComputed = ({ tableColumns }) =>
      tableColumnsWithActions(tableColumns, width);

    return (
      <Plugin name="ActionsColumn" dependencies={pluginDependencies}>
        <Getter name="tableColumns" computed={tableColumnsComputed} />

        <Template
          name="tableCell"
          predicate={({ tableRow, tableColumn }) =>
            isHeadingActionsTableCell(tableRow, tableColumn)
          }
        >
          <Table.Cell />
        </Template>
        <Template
          name="tableCell"
          predicate={({ tableRow, tableColumn }) =>
            isActionsTableCell(tableRow, tableColumn)
          }
        >
          {params => (
            <Table.Cell
              {...params}
              row={params.tableRow.row}
              style={{
                ...params.style,
                paddingTop: 0,
                paddingBottom: 0,
                textAlign: "right"
              }}
            >
              {actions.map((action, index) => {
                if (!params.tableRow.row) {
                  return "";
                }

                if (action.type === "delete") {
                  if (
                    action.canDeleteFunc &&
                    action.canDeleteFunc(params.tableRow.row) === false
                  ) {
                    return "";
                  }
                }

                return (
                  <IconButton
                    disabled={
                      action.type === "delete"
                        ? params.tableRow.row.can_delete === false
                          ? true
                          : undefined
                        : undefined
                    }
                    key={index}
                    onClick={() =>
                      action.action(
                        params.tableRow.row,
                        params.tableRow.rowId,
                        fetchData
                      )
                    }
                    style={{
                      padding: 0,
                      marginLeft: index > 0 ? 8 : 0,
                      ...(action.style ? action.style(params.tableRow.row) : {})
                    }}
                  >
                    {action.icon}
                  </IconButton>
                );
              })}
            </Table.Cell>
          )}
        </Template>
      </Plugin>
    );
  }
}
ActionsColumn.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.PropTypes.shape({
      icon: PropTypes.node,
      action: PropTypes.func.isRequired
    })
  ).isRequired,
  width: PropTypes.number
};
ActionsColumn.defaultProps = {
  width: 120
};

export default ActionsColumn;
