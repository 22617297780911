/* eslint-disable */
import React, {PureComponent} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import { get } from 'lodash';
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from '../../../../../assets/img/prior_logo.png';
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import localize from "helpers/localize";


class PasswordActivate extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            passwordConfirm: '',
            error_password: false,
            error_passwordConfirm: false,
            error: false
        };
    }

    componentWillMount() {
        this.props.reset();
        this.props.checkToken(this.props.match.params.token);
    };

    componentWillUnmount() {
        this.props.reset();
    };

    componentDidMount() {
      localStorage.removeItem("access_token");
    }

    handleInputKeypress = evt => {
      let keyCode = evt.which || evt.keyCode;
      if (keyCode === 13) {
        //Enter
        this.handleSubmit();
      }
    };

    handleSubmit = () => {
        this.setState({
            error_password: false,
            error_passwordConfirm: false,
            error: false
        });

        let error, error_password, error_passwordConfirm;

        if (this.state.password === '') {
            error = true;
            error_password = localize("user", "A jelszó kötelező");
        }else if (this.state.passwordConfirm === '') {
          error = true;
          error_passwordConfirm = localize("user", "Jelszó ismétlése kötelező");
        }
        else if (this.state.password !== this.state.passwordConfirm) {
            error = true;
            error_passwordConfirm = localize("user", "A jelszavak nem egyeznek");
        }


        if (error) {
            this.setState({
                error_password,
                error_passwordConfirm,
                error
            });
        }
        else {
            this.props.passwordRecovery(
                this.props.match.params.token,
                this.state.password
            )
        }
    };

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.success && nextProps.logined) {
            this.props.history.push('/')
        }
    };

    render() {

        return (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <Helmet>
                    <title>{localize("user", "Jelszó visszaállítás")}</title>
                </Helmet>
                <div className="app-login-main-content">
                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link to="/" className="logo-lg">
                            <img src={logo} alt="" title="" width="177" />
                        </Link>
                    </div>
                    <div className="app-login-content">
                        <div className="app-login-header mb-4"><h1><span>{localize("user", "Jelszó visszaállítás")}</span></h1></div>

                        {this.props.failed && <div className="bg-danger shadow-lg text-white alert alert-success fade show">{get(this.props, 'message',false) ? get(this.props, 'message') : localize("user", "Hibás jelszó token")}</div>}

                        {this.props.tokenSuccess && <div className="app-login-form">
                            <form>
                                <fieldset>
                                    <TextField
                                        type="password"
                                        label={localize("user", "Jelszó")}
                                        fullWidth
                                        onChange={(event) => this.setState({password: event.target.value})}
                                        defaultValue={this.state.password}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                        helperText={this.state.error_password}
                                        error={this.state.error_password ? true : false}
                                        onKeyPress={this.handleInputKeypress}
                                    />
                                    <TextField
                                        type="password"
                                        label={localize("user", "Jelszó ismétlése")}
                                        fullWidth
                                        onChange={(event) => this.setState({passwordConfirm: event.target.value})}
                                        defaultValue={this.state.passwordConfirm}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                        helperText={this.state.error_passwordConfirm}
                                        error={this.state.error_passwordConfirm ? true : false}
                                        onKeyPress={this.handleInputKeypress}
                                    />
                                </fieldset>
                            </form>
                        </div>}

                        <div className="mb-3 d-flex align-items-center justify-content-between">
                            {this.props.tokenSuccess  && <Button disabled={this.props.loading} onClick={this.handleSubmit.bind(this)} variant="contained" color="primary">
                                {localize("basic", "Küldés")}
                            </Button>}

                            <Link to="/">
                                {localize("user", "Vissza a bejelentkezésre")}
                            </Link>
                        </div>
                    </div>
                </div>
                {this.props.loading && <div className="loader-view"><CircularProgress/></div>}
            </div>
        );
    }
}

export default PasswordActivate;