import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import localize from "helpers/localize";

class Page404 extends PureComponent {
  render() {
    return (
      <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("page404", "404")}</title>
        </Helmet>
        <div className="page-error-content">
          <div className="error-code mb-4 animated zoomInDown">
            {localize("page404", "404")}
          </div>
          <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
            {localize(
              "page404",
              "Oops, an error has occurred. Page not found!"
            )}
          </h2>

          <p className="text-center zoomIn animation-delay-20 animated">
            <Link className="btn btn-primary" to="/">
              {localize("page404", "Főoldal")}
            </Link>
          </p>
        </div>
      </div>
    );
  }
}
export default Page404;
