export const getUrl = place => {
  let p = Array.isArray(place) ? place[0] : place;
  let obj = Array.isArray(place) ? place[1] : {};
  switch (p) {
    case "service-changes":
      return "/service-changes";
    case "shop-order":
      return `/shop/orders/view/${obj.id}`;
    case "shop-orders":
      return "/shop/orders";
    case "shop-admin-orders":
      return "/shop/admin-orders" + `${obj.id ? `/view/${obj.id}` : ""}`;
    default:
      return `/${p}${obj.id ? `/view/${obj.id}` : ""}`;
  }
};