import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CashregistersList from "./views/CashregistersList";
import * as cashregistersActions from "../../../../redux/modules/cashregisters";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.cashregisters.loading,
    success: state.cashregisters.success,
    message: state.cashregisters.message,
    data: state.cashregisters.data,
    total: state.cashregisters.total,
    limit: state.cashregisters.limit,
    page: state.cashregisters.page,
    order: state.cashregisters.order,
    orderMode: state.cashregisters.orderMode,
    deleted: state.cashregisters.deleted,
    deletedId: state.cashregisters.deletedId,
    token: state.cashregisters.token,
    receivedOpen: state.cashregisters.receivedOpen,
    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder,
    file_loading: state.file.loading,
    file_message: state.file.message,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: cashregistersActions.cashregisterssList,
      delete: cashregistersActions.cashregistersDelete,
      setLoading: cashregistersActions.setLoading,
      reset: cashregistersActions.reset,
      exportList: cashregistersActions.exportList,
      changeRow: cashregistersActions.changeRow
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CashregistersList);
