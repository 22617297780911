import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";


import List from "../../../../../Components/List/List";
import ContainerHeader from "../../../../../Components/ContainerHeader/index";
import Navbar from "../../Navbar";
import localize from "helpers/localize";

class WorkfeesList extends PureComponent {
  getListHeader = () => {
    return this.props.getListHeader("/workfees");
  };
  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("workfees", "Munkadíjak")}</title>
        </Helmet>
        {/*<Navbar location={this.props.location} history={this.props.history} />*/}
        <ContainerHeader title={localize("workfees", "Munkadíjak")} />

        <List {...this.props} getListHeader={this.getListHeader.bind(this)} />
      </div>
    );
  }
}

export default WorkfeesList;
