import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import Validations from "../Forms/Validations";

import { get } from "lodash";
import InputField from "../Forms/Fields/InputMaskedBlur";
import Grid from "@material-ui/core/Grid";
import { taxNumberMask, mobilNumberMask } from "helpers/maskHelper";
import Autocomplete from "Components/Forms/Fields/Autocomplete";
import localize from "helpers/localize";

class CustomerSection extends React.Component {
  render = () => {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      enabledFields,
      baseFields,
      handleTaxOnBlur,
      regNoRequired,
      change,
      enableEmailValidation,
      companyNameAutocomplete,
      handleClientFound
    } = this.props;

    let methods = [];
    Object.keys(
      get(this.props, "baseFields.order_payment_method.data", {})
    ).forEach(key => {
      methods.push({
        id: key,
        label: baseFields.order_payment_method.data[key]
      });
    });

    return (
      <Fragment>
        <Grid item xs={12}>
          <h6 className="form-legend">Cég</h6>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={isViewPage ? 3 : 4}>
              <Field
                name="client_tax_number"
                isRequired={true}
                validate={[Validations.required, Validations.tax_number]}
                label={localize("serviceOrders", "Adószám")}
                fieldError={fieldErrors.client_tax_number}
                onChange={() => handleInputChange("client_tax_number")}
                url="v1/service-orders/autocomplete-client"
                component={InputField}
                change={change}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("client_tax_number") !== -1
                      ? false
                      : true
                    : false
                }
                onBlur={handleTaxOnBlur}
                //{...taxNumberMask}
                mask="tax_number"
                labelInfo={localize(
                  "serviceOrders",
                  "Az alábbi formátum kötelező: xxxxxxxx-x-xx"
                )}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4} lg={isViewPage ? 3 : 4}>
              {companyNameAutocomplete ? (
                <Field
                  name="client_name"
                  isRequired={true}
                  validate={[Validations.required]}
                  label={localize("serviceOrders", "Cégnév")}
                  fieldError={fieldErrors.client_name}
                  onChange={(a, b) => {
                    //console.log(a, b);
                    handleInputChange("client_name");
                  }}
                  onSelect={(item, name) => {
                    handleClientFound && handleClientFound(item);
                  }}
                  url="/v1/cashregisters/client"
                  labelKey="client_name"
                  component={Autocomplete}
                  isViewPage={isViewPage}
                  disabled={
                    enabledFields
                      ? enabledFields.indexOf("client_name") !== -1
                        ? false
                        : true
                      : false
                  }
                />
              ) : (
                <Field
                  name="client_name"
                  isRequired={true}
                  validate={[Validations.required]}
                  label={localize("serviceOrders", "Cégnév")}
                  fieldError={fieldErrors.client_name}
                  onChange={() => handleInputChange("client_name")}
                  url="v1/service-orders/autocomplete-client"
                  labelKey="client_name"
                  component={InputField}
                  isViewPage={isViewPage}
                  disabled={
                    enabledFields
                      ? enabledFields.indexOf("client_name") !== -1
                        ? false
                        : true
                      : false
                  }
                />
              )}
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4} lg={isViewPage ? 3 : 4}>
              <Field
                name="client_registration_no"
                label={localize(
                  "serviceOrders",
                  "Cégjegyzékszám / Nyilvántart. szám"
                )}
                fieldError={fieldErrors.client_registration_no}
                onChange={() => handleInputChange("client_registration_no")}
                component={InputField}
                labelInfo={localize(
                  "serviceOrders",
                  "Cég esetén a hivatalos cégjegyzékszám, E.V. esetén a nyilvántartási szám"
                )}
                type="text"
                placeholder={localize(
                  "serviceOrders",
                  "Cégjegyzékszám / Nyilvántart. szám"
                )}
                validate={regNoRequired ? [Validations.required] : []}
                isRequired={regNoRequired ? true : false}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("client_registration_no") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h6 className="form-legend">Székhely</h6>
          <Grid container spacing={16}>
            <Grid item={true} xs={12} sm={6} md={4} lg={isViewPage ? 3 : 1}>
              <Field
                name="client_zip"
                label={localize("serviceOrders", "Irányítószám")}
                fieldError={fieldErrors.client_zip}
                onChange={() => handleInputChange("client_zip")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("serviceOrders", "Irányítószám")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("client_zip") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4} lg={isViewPage ? 3 : 2}>
              <Field
                name="client_city"
                label={localize("serviceOrders", "Település")}
                fieldError={fieldErrors.client_city}
                onChange={() => handleInputChange("client_city")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("serviceOrders", "Település")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("client_city") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4} lg={3}>
              <Field
                name="client_street"
                label={localize("serviceOrders", "Utca")}
                fieldError={fieldErrors.client_street}
                onChange={() => handleInputChange("client_street")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("serviceOrders", "Utca")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("client_street") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4} lg={3}>
              <Field
                name="client_street_suffix"
                label={localize("serviceOrders", "Közterület jellege")}
                fieldError={fieldErrors.client_street_suffix}
                onChange={() => handleInputChange("client_street_suffix")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("serviceOrders", "Közterület jellege")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("client_street_suffix") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4} lg={3}>
              <Field
                name="client_street_details"
                label={localize("serviceOrders", "Házszám (emelet, ajtó)")}
                fieldError={fieldErrors.client_street_details}
                onChange={() => handleInputChange("client_street_details")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize(
                  "serviceOrders",
                  "Házszám (emelet, ajtó)"
                )}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("client_street_details") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h6 className="form-legend">Elérhetőségek</h6>
          <Grid container spacing={16}>
            <Grid item={true} xs={12} sm={6} md={4} lg={3}>
              <Field
                name="client_email"
                label={localize("serviceOrders", "Email cím")}
                isRequired={
                  enableEmailValidation
                    ? true
                    : this.props.disableEmailAndPhoneRequiredValidation
                    ? false
                    : true
                }
                validate={
                  enableEmailValidation
                    ? [Validations.required, Validations.email]
                    : this.props.disableEmailAndPhoneRequiredValidation
                    ? []
                    : [Validations.required, Validations.email]
                }
                fieldError={fieldErrors.client_email}
                onChange={() => handleInputChange("client_email")}
                component={InputField}
                type="text"
                placeholder={localize("serviceOrders", "Email cím")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("client_email") !== -1
                      ? false
                      : true
                    : false
                }
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4} lg={3}>
              <Field
                name="client_phone"
                label={localize("serviceOrders", "Mobiltelefonszám")}
                isRequired={false}
                validate={
                  this.props.disableEmailAndPhoneRequiredValidation
                    ? []
                    : [Validations.email]
                }
                fieldError={fieldErrors.client_phone}
                onChange={() => handleInputChange("client_phone")}
                component={InputField}
                type="text"
                placeholder={localize("serviceOrders", "Mobiltelefonszám")}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("client_phone") !== -1
                      ? false
                      : true
                    : false
                }
                //{...mobilNumberMask}
                mask="mobile_phone"
                labelInfo={localize(
                  "serviceOrders",
                  "Az alábbi formátum kötelező: 06-30-1234567"
                )}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4} lg={3}>
              <Field
                name="client_phone2"
                label={localize("serviceOrders", "Másodlagos telefonszám")}
                fieldError={fieldErrors.client_phone2}
                onChange={() => handleInputChange("client_phone2")}
                component={InputField}
                type="text"
                placeholder={localize(
                  "serviceOrders",
                  "Másodlagos telefonszám"
                )}
                isViewPage={isViewPage}
                readOnly={
                  enabledFields
                    ? enabledFields.indexOf("client_phone2") !== -1
                      ? false
                      : true
                    : false
                }
                /*labelInfo={localize(
                  "serviceOrders",
                  "Az alábbi formátum kötelező: 06-30-1234567"
                )}*/
              />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  };
}

CustomerSection.defaultProps = {
  regNoRequired: false
};

CustomerSection.propTypes = {
  handleInputChange: PropTypes.func,
  handleTaxOnBlur: PropTypes.func,
  fieldErrors: PropTypes.object,
  isViewPage: PropTypes.bool,
  enabledFields: PropTypes.any,
  baseFields: PropTypes.object,
  regNoRequired: PropTypes.bool
};

export default CustomerSection;
