const annualReviewFormStyle = theme => ({
  container: {
    //paddingLeft:theme.spacing.unit * 2,
    //paddingRight:theme.spacing.unit * 2,
    marginLeft: 0, //theme.spacing.unit * 2,
    marginRight: 0, //theme.spacing.unit * 2,
    "& > div": {
      padding: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
      borderBottom: "solid 1px " + theme.palette.grey[500]
    },
    "& > div:nth-child(even)": {
      backgroundColor: theme.palette.grey[200]
    }
  },
  dark: {
    backgroundColor: theme.palette.grey[200]
  }
});

export default annualReviewFormStyle;
