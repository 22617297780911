import { get } from "lodash";

const fieldHelper = baseFields => {
  return {
    options: filedName =>
      Object.keys(get(baseFields, `${filedName}.data`, {})).map(key => ({
        id: key,
        label: baseFields[filedName].data[key]
      })),
    optionsFiltered: (filedName, excludes) =>
      Object.keys(get(baseFields, `${filedName}.data`, {}))
        .filter(key => {
          return excludes.indexOf(key) < 0;;
        })
        .map(key => ({
          id: key,
          label: baseFields[filedName].data[key]
        })),
    optionName: (filedName, fieldValue) =>
      get(baseFields, `${filedName}.data.${fieldValue}`, null)
  };
};
export default fieldHelper;
