import React, { Component, Fragment } from 'react';

export default class DropDown extends Component {

  handleChange = (event) => {
    this.props.onChange(
        this.props.name,
        event.target.value
    );
  };

  render() {
    return (
      <Fragment>
        <select className="form-control" name={this.props.name} onChange={this.handleChange} value={this.props.value}>
          <option value="" /*selected={!this.props.value}*/></option>
          {this.props.options && Object.keys(this.props.options).map((key, index) => <option key={key+index} value={this.props.options[key].id}>{this.props.options[key].label}</option> )}
        </select>
      </Fragment>
    );
  }
}