import React, { Component } from "react";
import moment from "moment";
import { get } from "lodash";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
//import MomentUtils from "material-ui-pickers/utils/moment-utils";
import MomentUtils from "@date-io/moment";

import "moment/locale/hu";
import "moment/locale/en-gb";
import Wrapper from "./Wrapper";
import inputStyle from "./inputStyle.js";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
//import PropTypes from "prop-types";
import localize from "helpers/localize";

moment.locale("hu");
class DatePickerInput extends Component {
  constructor(props) {
    super(props);
    const date = get(props, "input.value", null);
    this.state = {
      date: this.parseDate(date)
    };
  }

  parseDate = dateStr => {
    if (dateStr) {
      return moment(dateStr);
    } else {
      return null;
    }
  };

  componentWillReceiveProps(nextProps) {
    const date = get(nextProps, "input.value", null);
    const newDate = this.parseDate(date);
    if (this.state.date !== newDate) {
      this.setState({ date: newDate });
    }
  }

  render = () => {
    const {
      input,
      fieldError,
      label,
      readOnly,
      classes,
      labelBefore,
      isRequired
    } = this.props;
    const { warning, touched, error } = this.props.meta;

    //moment.locale('hu');
    const labelClass = classNames(
      classes.inputLabelDate,
      labelBefore ? classes.labelBefore : null
    );
    const inputRootClass = classNames(
      classes.bootstrapRoot,
      labelBefore ? classes.bootstrapRootBefore : null
    );

    const InputProps = {
      InputProps: {
        classes: {
          root: inputRootClass,
          input: classes.bootstrapInput
        }
      }
    };
    return (
      <Wrapper {...this.props} value={input.value}>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          locale="hu"
          moment={moment}
        >
          <div className={classes.datePickerContainer}>
            <DatePicker
              {...input}
              label={isRequired ? label + " *" : label}
              format={localize("moment", "Y.MM.DD")}
              fullWidth
              disabled={readOnly}
              locale={"hu"}
              okLabel={localize("moment", "Ok")}
              cancelLabel={localize("moment", "Mégsem")}
              clearLabel={localize("moment", "Törlés")}
              value={this.state.date}
              animateYearScrolling={false}
              leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
              rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
              clearable
              error={
                fieldError || (touched && (warning || (error ? true : false)))
              }
              onChange={date => {
                this.setState({ date });
                input.onChange(date ? date.format("YYYY-MM-DD") : null);
              }}
              inputProps={{
                readOnly: readOnly
              }}
              InputLabelProps={{
                shrink: true,
                className: labelClass
              }}
              {...InputProps}
            />
          </div>
        </MuiPickersUtilsProvider>
      </Wrapper>
    );
  };
}
export default withStyles(inputStyle)(DatePickerInput);
