import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { Form } from "reactstrap";
import InputField from "../../../../../Components/Forms/Fields/Input";
import DefaultFormButtons from "../../../../../Components/Forms/Buttons/DefaultFormButtons";
import Validations from "../../../../../Components/Forms/Validations";
import { mobilNumberMask } from "helpers/maskHelper";
import Grid from "@material-ui/core/Grid";
import localize from "helpers/localize";
import { Fragment } from "react";
import UserClients from "Containers/Pages/Users/views/UserClients";

class ChangeProfileForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    this.fillForm();
  };

  fillForm = () => {
    const { dispatch, change, user } = this.props;
    dispatch(change("username", user.username));
    dispatch(change("real_name", user.real_name));
    dispatch(change("card_number", user.card_number));
    dispatch(change("phone", user.phone));
    dispatch(
      change("sealnumber_of_cashregister", user.sealnumber_of_cashregister)
    );
    dispatch(change("client_cashregister_apn", user.client_cashregister_apn));
  };

  render() {
    const {
      handleSubmit,
      handleInputChange,
      fieldErrors,
      pristine,
      submitting,
      reset
    } = this.props;
    console.log(this.props);
    return (
      <Fragment>
        <Form onSubmit={handleSubmit} className="form">
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Field
                name="username"
                label={localize("user", "Felhasználónév")}
                fieldError={fieldErrors.username}
                onChange={() => handleInputChange("username")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("user", "Felhasználónév")}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Field
                name="real_name"
                label={localize("user", "Valódi név")}
                fieldError={fieldErrors.real_name}
                onChange={() => handleInputChange("real_name")}
                component={InputField}
                type="text"
                placeholder={localize("user", "Valódi név")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Field
                name="phone"
                label={localize("user", "Telefonszám")}
                fieldError={fieldErrors.phone}
                onChange={() => handleInputChange("phone")}
                component={InputField}
                type="text"
                isRequired
                placeholder={localize("user", "Telefonszám")}
                validate={[Validations.required, Validations.mobile_number]}
                {...mobilNumberMask}
              />
            </Grid>
            {this.props.user.role !== "user" && (
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Field
                  name="card_number"
                  label={localize("user", "Igazolvány száma")}
                  fieldError={fieldErrors.card_number}
                  onChange={() => handleInputChange("card_number")}
                  component={InputField}
                  type="text"
                  placeholder={localize("user", "Igazolvány száma")}
                  readOnly={true}
                />
              </Grid>
            )}
            {/*["distributor", "mainvendor", "vendor", "root"].indexOf(
            this.props.userRole
          ) >= 0*/ this
                .props.user.role !== "user" && (
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Field
                    name="sealnumber_of_cashregister"
                    label={localize("user", "Pénztárgép Plombanyomó száma")}
                    fieldError={fieldErrors.sealnumber_of_cashregister}
                    onChange={() =>
                    handleInputChange("sealnumber_of_cashregister")
                  }
                    component={InputField}
                    type="text"
                    placeholder={localize("user", "Pénztárgép Plombanyomó száma")}
                    readOnly={true}
                  />
                </Grid>
              )}
          </Grid>

          <DefaultFormButtons
            pristine={pristine}
            submitting={submitting}
            reset={reset}
            loading={this.props.profileLoading}
            saveId="profileChange"
            disableCancel={true}
          />
        </Form>

        {this.props.user &&
          this.props.user.id &&
          this.props.user.role === "user" && (
            <Fragment>
              <UserClients
                user={this.props.user}
                readOnly
                //afterSave={this.props.afterLocationSave}
              />
            </Fragment>
          )}
      </Fragment>
    );
  }
}

export default reduxForm({
  form: "profile",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(ChangeProfileForm);
