/* eslint-disable no-console */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
//import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import restHelper from "helpers/aee/restHelper";
import SimpleArray2dArray from "Components/New/Input/SimpleArray2dArray/SimpleArray2dArray";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import ClearIcon from "@material-ui/icons/CancelPresentation";
import PasteIcon from "@material-ui/icons/FileCopy";
import ModalConfirm from "Components/Modal/ModalConfirm";
import { Grid } from "@material-ui/core";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tags from "Components/New/Input/Tags/Tags";
import localize from "helpers/localize";
const LC = "cloud"; //Localization category
const copy = arr => (arr ? arr.map(item => Object.assign({}, item)) : null);

const styles = theme => ({
  /*"@global": {
    ".rbt-input-hint": {
      //backgroundColor: "red!important",
      width: "100%",
      top: "2px!important"
    }
  },*/
  rowDeleted: {
    backgroundColor: "red!important",
    color: "white"
  },
  rowChanged: {
    backgroundColor: "green!important",
    color: "white"
  },
  marginLeftAuto: {
    marginLeft: "auto"
  },
  marginLeft: {
    marginLeft: theme.spacing.unit
  },
  marginLeftRight: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing.unit
  },
  infoIcon: {
    color: theme.palette.info.main,
    marginRight: theme.spacing.unit
  },
  infoText: {
    fontStyle: "italic"
  },
  modalQueue: {
    marginLeft: theme.spacing.unit,
    fontWeight: "bold"
  },
  uptoDateIcon: {
    fill: theme.palette.primary.main
  }
});

class PrintEndReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      sendToall: false,
      clientIds: null,
      clients: null,
      clientsError: null
    };
    this.modal = null;
  }

  componentDidMount() {
    document.addEventListener("paste", this.paste);
    this.view();
  }

  componentWillUnmount() {
    document.removeEventListener("paste", this.paste);
  }

  view = () => {
    const { cashregister_id: ap, client_id: client } = this.props;
    this.setState({ loading: true });
    restHelper
      .view("cashregister-print-end/view", null, { ap, client })
      .then(response => {
        const lines =
          response && response.data && response.data.lines
            ? response.data.lines
            : [];
        this.setState({ rows: lines, loading: false });
      });
  };

  onSaveGlobal = () => {
    const { clientIds, rows, sendToall } = this.state;
    const data = {
      client_ids: clientIds,
      lines: rows
    };

    if (!sendToall && (!clientIds || clientIds.length === 0)) {
      this.setState({
        clientsError: localize(LC, "Legalább egy ügyfél kiválasztása kötelező.")
      });
      return;
    }

    this.modal
      .open(
        localize("basic", "Megerősítés"),
        sendToall
          ? localize(LC, "Biztosan kiküldi az összes ügyfélnek?")
          : localize(
            LC,
            "Biztosan kiküldi a kiválasztott {{count}} darab ügyfélnek?",
            { count: clientIds.length }
          ),
        null,
        "Ok",
        "Mégsem"
      )
      .then(() => {
        this.setState({ loading: true, clientsError: null });
        restHelper
          .create("cashregister-print-end/create", null, data)
          .then((/*response*/) => {
            this.modal.hide();
            this.setState({ loading: false });
          })
          .catch(error => {
            this.modal.hide();
            this.setState({ loading: false });
          });
      });
  };

  onSave = () => {
    const {
      cashregister_id: ap,
      client_id: client,
      onUpdated,
      isGlobal
    } = this.props;
    this.setState({ loading: true });
    //Globális mentés
    if (isGlobal) {
      this.onSaveGlobal();
      return;
    }

    restHelper
      .create("cashregister-print-end/create", null, this.state.rows, {
        ap,
        client
      })
      .then((/*response*/) => {
        onUpdated(() => {
          this.setState({ loading: false });
        });
      });
  };

  onPaste = () => {
    navigator.clipboard
      .readText()
      .then(text => {
        this.setRowsFromText(text);
      })
      .catch(err => {
        //console.error("Failed to read clipboard contents: ", err);
        globalMessage.error(localize(LC, "A vágólap nincs engedélyezve"));
      });
  };
  setRowsFromText = text => {
    const lines = text.split("\n");
    const rows = copy(this.state.rows);
    lines.forEach(line => {
      rows.push({ line, fomat: 0 });
    });
    this.setState({ rows });
  };
  paste = e => {
    if (e.target.tagName !== "INPUT") {
      if (e.clipboardData.types.indexOf("text/plain") > -1) {
        var text = e.clipboardData.getData("text/plain");
        this.setRowsFromText(text);
        e.preventDefault();
      }
    }
  };

  onClear = () => {
    this.modal
      .open(
        localize("basic", "Megerősítés"),
        localize(LC, "Biztosan törli az összes sort?"),
        null,
        localize("basic", "Ok"),
        localize("basic", "Mégsem")
      )
      .then(() => {
        this.setState({ rows: [] });
        this.modal.hide();
      });
  };

  onSendToallClick = evt => {
    const newState = { sendToall: evt.target.checked };
    if (evt.target.checked) {
      newState.clientIds = null;
      newState.clients = null;
      newState.clientsError = null;
    }
    this.setState(newState);
  };
  onChangeClientIds = (clientIds, clients, key) => {
    const newState = { clientIds, clients };
    if (clientIds) {
      newState.clientsError = null;
    }
    this.setState(newState);
  };

  render() {
    const {
      classes,
      cashregister_id,
      client_id,
      editMode,
      isGlobal
    } = this.props;
    const { rows, sendToall, clients, clientsError } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              {editMode && (
                <Toolbar disableGutters>
                  {/*<Typography variant="h6">
                  Végfelhasználói bizonylat nyomtatása
    </Typography>*/}
                  {isGlobal && (
                    <Fragment>
                      <Tags
                        url="clients/autocomplete"
                        type="tagcomplete"
                        requestParams={{ attribute: "name" }}
                        onChange={this.onChangeClientIds}
                        value={clients}
                        placeholder={localize(LC, "Válasszon ügyfeleket...")}
                        isDisabled={sendToall}
                        error={clientsError}
                        fullWidth
                      />
                      <FormControlLabel
                        style={{ whiteSpace: "nowrap", marginLeft: 16 }}
                        control={
                          <Checkbox
                            checked={sendToall}
                            onChange={this.onSendToallClick}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label={localize(LC, "Összes ügyfélnek")}
                      />
                    </Fragment>
                  )}
                  {editMode && (
                    <Tooltip
                      title={localize(LC, "Beillesztés vágólapról")}
                      disableFocusListener
                    >
                      <IconButton
                        color="primary"
                        style={{ marginLeft: "auto" }}
                        onClick={this.onPaste}
                      >
                        <PasteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {editMode && (
                    <Tooltip
                      title={localize(LC, "Összes sor törlése")}
                      disableFocusListener
                    >
                      <IconButton
                        color="primary"
                        //style={{ marginLeft: "auto" }}
                        onClick={this.onClear}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Toolbar>
              )}

              <SimpleArray2dArray
                disabled={!editMode}
                showLineNumbers
                fullWidth
                useKeyEvents
                value={rows}
                onChange={evt => {
                  this.setState({ rows: evt.target.value });
                }}
                columns={[
                  {
                    id: "line",
                    label: localize(LC, "Szöveg"),
                    maxlength: 100,
                    insertNewRowOnenter: true,
                    arrowNavigationDown: true,
                    arrowNavigationUp: true,
                    clearRowOnBackSpace: true,
                    showCharLeft: true,
                    /*style: item => {
                      switch (item.format) {
                        case "1":
                          return { textAlign: "center" };
                        case "2":
                          return { fontWeight: "bold" };
                        case "3":
                          return { fontWeight: "bold", textAlign: "center" };
                        default:
                          return undefined;
                      }
                    }*/
                    style: item => ({
                      textAlign: item.center
                        ? "center"
                        : item.right
                          ? "right"
                          : "left",
                      fontWeight: item.bold ? "bold" : "normal",
                      fontSize: item.dbl_height ? "28px" : "14px",
                      textDecoration: item.underline ? "underline" : "none"
                    })
                    //width: 350
                  },
                  {
                    id: "char_left",
                    render: (row, disabled) => (
                      <div
                        key={disabled}
                        style={{
                          textAlign: "center",
                          color:
                            disabled === true
                              ? "rgba(0, 0, 0, 0.38)"
                              : "rgba(0, 0, 0, 0.87)"
                        }}
                      >
                        {100 - (row.line ? row.line.length : 0)}
                      </div>
                    ),
                    label: localize(LC, "Fentmaradó"),
                    type: "calc",
                    width: 40
                  },
                  {
                    id: "bold",
                    label: localize(LC, "Félkövér"),
                    type: "checkbox",
                    width: 40
                  },
                  {
                    id: "dbl_height",
                    label: localize(LC, "Dupla"),
                    type: "checkbox",
                    width: 40
                  },
                  {
                    id: "underline",
                    label: localize(LC, "Aláhúzott"),
                    type: "checkbox",
                    width: 40
                  },
                  {
                    id: "left",
                    label: localize(LC, "Balra"),
                    type: "checkbox",
                    defaultValue: true,
                    changeValues: row => {
                      if (!row.left && !row.center && !row.right) {
                        row.left = true;
                        return;
                      }
                      if (row.right) {
                        row.right = false;
                      }
                      if (row.center) {
                        row.center = false;
                      }
                    },
                    width: 40
                  },
                  {
                    id: "center",
                    label: localize(LC, "Középre"),
                    type: "checkbox",
                    changeValues: row => {
                      if (!row.left && !row.center && !row.right) {
                        row.center = true;
                        return;
                      }
                      if (row.right) {
                        row.right = false;
                      }
                      if (row.left) {
                        row.left = false;
                      }
                    },
                    width: 40
                  },
                  {
                    id: "right",
                    label: localize(LC, "Jobbra"),
                    type: "checkbox",
                    changeValues: row => {
                      if (!row.left && !row.center && !row.right) {
                        row.right = true;
                        return;
                      }
                      if (row.center) {
                        row.center = false;
                      }
                      if (row.left) {
                        row.left = false;
                      }
                    },
                    width: 40
                  }
                ]}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Toolbar disableGutters>
                <Button
                  disabled={!editMode || rows.length === 0}
                  color="primary"
                  style={{ marginLeft: "auto" }}
                  onClick={this.onSave}
                >
                  {localize(LC, "Küldés")}
                </Button>
              </Toolbar>
            </Grid>
          </Grid>
        </Grid>
        <ModalConfirm onRef={ref => (this.modal = ref)} />
      </Grid>
    );
  }
}

PrintEndReceipt.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //Ez igazából az AP szám
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isGlobal: PropTypes.bool //true esetén minden pénztárgépre kiküldésre kerül
};

export default withStyles(styles)(PrintEndReceipt);
