/* eslint-disable */
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { get } from "lodash";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as UIActions from "../../redux/modules/UIsettings";

import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import authRules from "helpers/authRules";
import localize from "helpers/localize";
import {
  ACCOUNTANTS_PERMISSION,
  AEEREGS_PERMISSION,
  ANNUAL_REVIEWS_PERMISSION,
  ARTICLES_PERMISSION,
  BANNED_CASHREGISTERS_PERMISSION,
  CASHREGISTERS_PERMISSION,
  CLIENTS_PERMISSION,
  CLOUD_CASHREGISTERS,
  CLOUD_CONTROL_PANEL,
  CLOUD_PRODUCTS,
  CLOUD_QUEUE,
  CRULES_PERMISSION,
  DISTRIBUTORS_PERMISSION,
  DOWNLOADS_PERMISSION,
  EMAIL_TEMPLATES_PERMISSION,
  EVENT_TYPES_PERMISSION,
  EXAMINEE_EXAMS_PERMISSION,
  EXAMS_PERMISSION,
  INCOMINGSMS_PERMISSION,
  MY_EXAMS_PERMISSION,
  REREGISTER_PERMISSION,
  SERVICE_CHANGES_PERMISSION,
  SERVICE_ORDERS_PERMISSION,
  SHOP_ADMIN_MENU_PERMISSION,
  SHOP_ADMIN_ORDERS_PERMISSION,
  SHOP_ADMIN_PRICES_PERMISSION,
  SHOP_ADMIN_PRODUCTS_PERMISSION,
  SHOP_ADMIN_TAGS_PERMISSION,
  SHOP_PERMISSION,
  SMODE_PERMISSION,
  STICKERS_PERMISSION,
  USERS_PERMISSION,
  WORKFEES_PERMISSION,
  WORKSHEETS_PERMISSION
} from "../../constans/permissions";
import MenuPermission from "../../Containers/Permissions/MenuPermission";
import * as signingActions from "../../redux/modules/signing";
import CustomScrollbars from "../CustomScrollbars/CustomScrollbars";
import Connect from "./Connect";
class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      anchorEl: null,
      open: false,
      selectedMenu: "none", //window.location.pathname.split('/')[1] || '',
      connectOpen: false
    };
  }

  handleSelectMenu = menu => {
    this.setState({
      selectedMenu: menu
    });
    this.props.history.push("/" + menu);
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleConnectClose = () => {
    this.setState({ connectOpen: false });
  };

  onToggleCollapsedNav = e => {
    this.props.sidebarToogle(!this.props.sideBarOpened);
  };

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.setState({
        width: window.innerWidth
      });
    });
  }

  isSelected = menu => {
    const { selectedMenu } = this.state;

    if (!selectedMenu) {
      return false;
    }

    if (selectedMenu === menu) {
      return true;
    }
    const menuLength = menu.length;
    if (selectedMenu.substring(0, menuLength) === menu) {
      return true;
    }
    return false;
  };

  toggleMenu = menu => {
    const { selectedMenu } = this.state;
    this.setState({ selectedMenu: menu === selectedMenu ? null : menu });
  };

  getCloudSubmenuLink = submenu => {
    const { match } = this.props;
    if (match && match.params && match.params.client_id) {
      return `/cloud/${match.params.client_id}/${submenu}`;
    } else {
      return `/cloud/${submenu}`;
    }
  };

  render() {
    const { selectedMenu } = this.state;

    let drawerStyle = "d-xl-flex";
    let type = "permanent";
    if (this.state.width < 1200) {
      type = "temporary";
    }

    return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Dialog
          open={this.state.connectOpen}
          className="col-md-6 col-lg-4 col-12 modal-centered"
          onClose={this.handleConnectClose.bind(this)}
          scroll="body"
        >
          <DialogTitle>
            {localize("connecting", "Eszköz csatlakoztatása")}
          </DialogTitle>
          <DialogContent>
            <Connect
              {...this.props}
              closeModal={this.handleConnectClose.bind(this)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleConnectClose.bind(this)} size="small">
              {localize("connecting", "Bezár")}
            </Button>
          </DialogActions>
        </Dialog>

        <Drawer
          className="app-sidebar-content"
          variant={type}
          open={type.includes("temporary") ? this.props.sideBarOpened : true}
          onClose={this.onToggleCollapsedNav}
          onMouseLeave={() => {
            this.setState({ selectedMenu: "none" });
          }}
          classes={{
            paper: "side-nav"
          }}
        >
          <div className="user-profile d-flex flex-row align-items-center">
            <div className="user-detail">
              <h4 className="user-name" onClick={this.handleClick}>
                {get(this.props, "user.real_name", "")}{" "}
                <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
              </h4>
            </div>
            <Menu
              className="user-info"
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleRequestClose}
              PaperProps={{
                style: {
                  width: 230,
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }}
            >
              <MenuItem
                onClick={() => {
                  this.handleRequestClose();
                  this.props.history.push("/profile");
                }}
              >
                <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
                {localize("user", "Saját profilom")}
              </MenuItem>
              {(this.props.distributor || true) &&
                this.props.user.role !== "user" && (
                  <MenuItem
                    onClick={() => {
                      this.handleRequestClose();
                      this.props.history.push("/distributor");
                    }}
                  >
                    <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
                    {localize("user", "Viszonteladói adataim")}
                  </MenuItem>
                )}
              {this.props.user.role !== "user" && (
                <MenuPermission module={WORKSHEETS_PERMISSION}>
                  <MenuItem
                    onClick={() => {
                      this.handleRequestClose();
                      this.props.connected
                        ? this.props.disconnect()
                        : this.setState({ connectOpen: true });
                    }}
                  >
                    <i className="zmdi zmdi-cast-connected zmdi-hc-fw mr-2" />
                    {!this.props.connected && (
                      <Fragment>
                        {localize("user", "Eszköz csatlakoztatása")}
                      </Fragment>
                    )}
                    {this.props.connected && (
                      <Fragment>
                        {localize("user", "Eszköz leválasztása")} (
                        {this.props.code})
                      </Fragment>
                    )}
                  </MenuItem>
                </MenuPermission>
              )}
              <MenuItem
                onClick={() => {
                  this.handleRequestClose();
                  this.props.logout();
                }}
              >
                <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
                {localize("user", "Kijelentkezés")}
              </MenuItem>
            </Menu>
          </div>

          <CustomScrollbars className=" scrollbar">
            <ul className="nav-menu">
              <li className="nav-header">{localize("menu", "Menü")}</li>
              <li
                className={
                  "menu no-arrow " + (selectedMenu === "" ? "open" : "")
                }
                onClick={() => {
                  this.handleSelectMenu("");
                }}
              >
                <NavLink to="/">
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                  <span className="nav-text">
                    {localize("menu", "Főoldal")}
                  </span>
                </NavLink>
              </li>
              <MenuPermission module={CASHREGISTERS_PERMISSION}>
                <li
                  className={
                    "menu collapse-box " +
                    (selectedMenu === "cashregisters" ? "open" : "")
                  }
                >
                  <span
                    className="arrowPos"
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        selectedMenu:
                          selectedMenu === "cashregisters"
                            ? null
                            : "cashregisters"
                      });
                    }}
                  />
                  <Button
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      this.handleSelectMenu("cashregisters");
                    }}
                  >
                    <i className="zmdi zmdi-collection-bookmark zmdi-hc-fw zmdi-hc-rotate-90" />
                    <span className="nav-text">
                      {localize("menu", "Pénztárgépek")}
                    </span>
                  </Button>

                  <ul className="sub-menu">
                    <MenuPermission module={CASHREGISTERS_PERMISSION}>
                      <li>
                        <NavLink to="/cashregisters">
                          <span className="nav-text">
                            {localize("menu", "Pénztárgépek")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={BANNED_CASHREGISTERS_PERMISSION}>
                      <li>
                        <NavLink to="/banned-cashregisters">
                          <span className="nav-text">
                            {localize("menu", "Tiltott AP számok")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={AEEREGS_PERMISSION}>
                      <li>
                        <NavLink to="/aee-regs">
                          <span className="nav-text">
                            {localize("menu", "AEE Regisztráció")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={SMODE_PERMISSION}>
                      <li>
                        <NavLink to="/s-mode">
                          <span className="nav-text">
                            {localize("menu", "S-Mód")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={REREGISTER_PERMISSION}>
                      <li>
                        <NavLink to="/reregister">
                          <span className="nav-text">
                            {localize("menu", "Átszemélyesítés")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={ANNUAL_REVIEWS_PERMISSION}>
                      <li>
                        <NavLink to="/annual-reviews">
                          <span className="nav-text">
                            {localize("menu", "Éves Felülvizsgálat")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={EVENT_TYPES_PERMISSION}>
                      <li>
                        <NavLink to="/event-types">
                          <span className="nav-text">
                            {localize("menu", "Események Tipusok")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={STICKERS_PERMISSION}>
                      <li>
                        <NavLink to="/stickers">
                          <span className="nav-text">
                            {localize("menu", "Matrica Adatok")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={STICKERS_PERMISSION}>
                      <li>
                        <NavLink to="/tokens">
                          <span className="nav-text">
                            {localize("menu", "Token Adatok")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={CRULES_PERMISSION}>
                      <li>
                        <NavLink to="/c-rules">
                          <span className="nav-text">
                            {localize("menu", "Adatpárok")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={SERVICE_CHANGES_PERMISSION}>
                      <li>
                        <NavLink to="/service-changes">
                          <span className="nav-text">
                            {localize("menu", "Szerviz váltás")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={CASHREGISTERS_PERMISSION}>
                      <li>
                        <NavLink to="/cashregister-worksheets">
                          <span className="nav-text">
                            {localize("menu", "Munkalapok")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                  </ul>
                </li>
              </MenuPermission>
              {/* Cloud start */}

              <MenuPermission module={CLOUD_CASHREGISTERS}>
                <li
                  className={
                    "menu collapse-box " +
                    (this.isSelected("cloud") ? "open" : "")
                  }
                >
                  <span
                    className="arrowPos"
                    onClick={e => {
                      e.preventDefault();
                      this.toggleMenu("cloud");
                    }}
                  />
                  <Button
                    href="/cloud"
                    onClick={e => {
                      e.preventDefault();
                      this.handleSelectMenu("cloud");
                    }}
                  >
                    <i className="zmdi zmdi-cloud zmdi-hc-fw" />
                    <span className="nav-text">
                      {localize("menu", "Felhő")}
                    </span>
                  </Button>

                  <ul className="sub-menu">
                    <MenuPermission module={CLOUD_CASHREGISTERS}>
                      <li>
                        <NavLink to={this.getCloudSubmenuLink("cashregisters")}>
                          <span className="nav-text">
                            {localize("menu", "Pénztárgépek")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={CLOUD_PRODUCTS}>
                      <li>
                        <NavLink to={this.getCloudSubmenuLink("products")}>
                          <span className="nav-text">
                            {localize("menu", "Termékek")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={CLOUD_QUEUE}>
                      <li>
                        <NavLink to={this.getCloudSubmenuLink("processes")}>
                          <span className="nav-text">
                            {localize("menu", "Folyamatok")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={CLOUD_CONTROL_PANEL}>
                      <li>
                        <NavLink to="/cloud-control-panel">
                          <span className="nav-text">
                            {localize("menu", "Gépház")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                  </ul>
                </li>
              </MenuPermission>

              {/* Cloud end */}
              <MenuPermission module={SERVICE_ORDERS_PERMISSION}>
                <li
                  className={
                    "menu no-arrow " +
                    (selectedMenu === "service-orders" ? "open" : "")
                  }
                  onClick={() => {
                    this.handleSelectMenu("service-orders");
                  }}
                >
                  <NavLink to="/service-orders">
                    <i className="zmdi zmdi-device-hub zmdi-hc-fw" />
                    <span className="nav-text">
                      {localize("menu", "Szerződések")}
                    </span>
                  </NavLink>
                </li>
              </MenuPermission>
              <MenuPermission module={WORKSHEETS_PERMISSION}>
                <li
                  className={
                    "menu collapse-box " +
                    (selectedMenu === "worksheets" ? "open" : "")
                  }
                >
                  <span
                    className="arrowPos"
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        selectedMenu:
                          selectedMenu === "worksheets" ? null : "worksheets"
                      });
                    }}
                  />
                  <Button
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      this.handleSelectMenu("worksheets");
                    }}
                  >
                    <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                    <span className="nav-text">
                      {localize("menu", "Munkalapok")}
                    </span>
                  </Button>
                  <ul className="sub-menu">
                    <MenuPermission module={WORKSHEETS_PERMISSION}>
                      <li>
                        <NavLink to="/worksheets">
                          <span className="nav-text">
                            {localize("menu", "Munkalapok")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={WORKSHEETS_PERMISSION}>
                      <li>
                        <NavLink to="/worksheets/new">
                          <span className="nav-text">
                            {localize("menu", "Új m.")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={WORKSHEETS_PERMISSION}>
                      <li>
                        <NavLink to="/worksheets/in-progress">
                          <span className="nav-text">
                            {localize("menu", "Folyamatban lévő m.")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={WORKSHEETS_PERMISSION}>
                      <li>
                        <NavLink to="/worksheets/done">
                          <span className="nav-text">
                            {localize("menu", "Elkészült m.")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={WORKSHEETS_PERMISSION}>
                      <li>
                        <NavLink to="/worksheets/waiting">
                          <span className="nav-text">
                            {localize("menu", "Ügyfélre váró m.")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={WORKSHEETS_PERMISSION}>
                      <li>
                        <NavLink to="/worksheets/closed">
                          <span className="nav-text">
                            {localize("menu", "LEzárt m.")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={WORKFEES_PERMISSION}>
                      <li>
                        <NavLink to="/worksheets/workfees">
                          <span className="nav-text">
                            {localize("menu", "Munkadíjak")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={WORKFEES_PERMISSION}>
                      <li>
                        <NavLink to="/worksheets/anyk-exports">
                          <span className="nav-text">
                            {localize("menu", "ANYK")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                  </ul>
                </li>
              </MenuPermission>
              <MenuPermission module={SHOP_PERMISSION}>
                <li
                  className={
                    "menu collapse-box " +
                    (this.isSelected("shop") ? "open" : "")
                  }
                >
                  <span
                    className="arrowPos"
                    onClick={e => {
                      e.preventDefault();
                      this.toggleMenu("shop");
                    }}
                  />
                  <Button
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      this.handleSelectMenu("shop/catalog");
                    }}
                  >
                    <i className="zmdi zmdi-case zmdi-hc-fw" />
                    <span className="nav-text">{localize("menu", "Shop")}</span>
                  </Button>
                  <ul className="sub-menu">
                    <MenuPermission module={SHOP_PERMISSION}>
                      <li>
                        <NavLink to="/shop/catalog">
                          <span className="nav-text">
                            {localize("menu", "Katalógus")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={SHOP_PERMISSION}>
                      <li>
                        <NavLink to="/shop/cart">
                          <span className="nav-text">
                            {localize("menu", "Kosár")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={SHOP_PERMISSION}>
                      <li>
                        <NavLink to="/shop/orders">
                          <span className="nav-text">
                            {localize("menu", "Rendeléseim")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={SHOP_ADMIN_PRODUCTS_PERMISSION}>
                      <li>
                        <NavLink to="/shop/admin-products">
                          <span className="nav-text">
                            {localize("menu", "Admin - termékek")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={SHOP_ADMIN_ORDERS_PERMISSION}>
                      <li>
                        <NavLink to="/shop/admin-orders">
                          <span className="nav-text">
                            {localize("menu", "Admin - rendelések")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={SHOP_ADMIN_TAGS_PERMISSION}>
                      <li>
                        <NavLink to="/shop/admin-tags">
                          <span className="nav-text">
                            {localize("menu", "Admin - címkék")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={SHOP_ADMIN_PRICES_PERMISSION}>
                      <li>
                        <NavLink to="/shop/admin-custom-prices">
                          <span className="nav-text">
                            {localize("menu", "Admin - egyedi árak")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={SHOP_ADMIN_MENU_PERMISSION}>
                      <li>
                        <NavLink to="/shop/admin-menu">
                          <span className="nav-text">
                            {localize("menu", "Admin - menü")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                  </ul>
                </li>
              </MenuPermission>
              <MenuPermission module={EXAMINEE_EXAMS_PERMISSION}>
                <li
                  className={
                    "menu collapse-box " +
                    (selectedMenu === "examinee-exams" ? "open" : "")
                  }
                >
                  <span
                    className="arrowPos"
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        selectedMenu:
                          selectedMenu === "examinee-exams"
                            ? null
                            : "examinee-exams"
                      });
                    }}
                  />
                  <Button
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      this.handleSelectMenu("examinee-exams");
                    }}
                  >
                    <i className="zmdi zmdi-collection-bookmark zmdi-hc-fw zmdi-hc-rotate-90" />
                    <span className="nav-text">
                      {localize("menu", "Vizsgák")}
                    </span>
                  </Button>
                  <ul className="sub-menu">
                    <MenuPermission module={EXAMINEE_EXAMS_PERMISSION}>
                      <li>
                        <NavLink to="/examinee-exams">
                          <span className="nav-text">
                            {localize("menu", "Vizsgák")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={EXAMS_PERMISSION}>
                      <li>
                        <NavLink to="/exams">
                          <span className="nav-text">
                            {localize("menu", "Vizsga kérdések")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                  </ul>
                </li>
              </MenuPermission>
              <MenuPermission module={MY_EXAMS_PERMISSION}>
                <li
                  className={
                    "menu no-arrow " +
                    (selectedMenu === "user-exams" ? "open" : "")
                  }
                  onClick={() => {
                    this.handleSelectMenu("user-exams");
                  }}
                >
                  <NavLink to="/user-exams">
                    <i className="zmdi zmdi-collection-bookmark zmdi-hc-fw zmdi-hc-rotate-90" />
                    <span className="nav-text">
                      {localize("menu", "Vizsgáim")}
                    </span>
                  </NavLink>
                </li>
              </MenuPermission>
              {/* Szereplők start */}
              {authRules.canViewByKeys([
                USERS_PERMISSION,
                DISTRIBUTORS_PERMISSION,
                CLIENTS_PERMISSION,
                ACCOUNTANTS_PERMISSION
              ]) && (
                <li
                  className={
                    "menu collapse-box " +
                    (this.isSelected("users") ? "open" : "")
                  }
                >
                  <span
                    className="arrowPos"
                    onClick={e => {
                      e.preventDefault();
                      this.toggleMenu("users");
                    }}
                  />
                  <Button
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      this.handleSelectMenu("users");
                    }}
                  >
                    <i className="zmdi zmdi-account-box zmdi-hc-fw" />
                    <span className="nav-text">
                      {localize("menu", "Szereplők")}
                    </span>
                  </Button>
                  <ul className="sub-menu">
                    <MenuPermission module={USERS_PERMISSION}>
                      <li
                        className={
                          "menu no-arrow " +
                          (selectedMenu === "users" ? "open" : "")
                        }
                        onClick={() => {
                          this.handleSelectMenu("users");
                        }}
                      >
                        <NavLink to="/users">
                          <span className="nav-text">
                            {localize("menu", "Felhasználók")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={DISTRIBUTORS_PERMISSION}>
                      <li
                        className={
                          "menu no-arrow " +
                          (selectedMenu === "distributors" ? "open" : "")
                        }
                        onClick={() => {
                          this.handleSelectMenu("distributors");
                        }}
                      >
                        <NavLink to="/distributors">
                          <span className="nav-text">
                            {localize("menu", "Viszonteladók")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={CLIENTS_PERMISSION}>
                      <li
                        className={
                          "menu no-arrow " +
                          (selectedMenu === "clients" ? "open" : "")
                        }
                        onClick={() => {
                          this.handleSelectMenu("clients");
                        }}
                      >
                        <NavLink to="/clients">
                          <span className="nav-text">
                            {localize("menu", "Ügyfelek")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={ACCOUNTANTS_PERMISSION}>
                      <li
                        className={
                          "menu no-arrow " +
                          (selectedMenu === "accountants" ? "open" : "")
                        }
                        onClick={() => {
                          this.handleSelectMenu("accountants");
                        }}
                      >
                        <NavLink to="/accountants">
                          <span className="nav-text">
                            {localize("menu", "Könyvelők")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                  </ul>
                </li>
              )}
              {/* Szereplők end */}

              {/* Egyéb start */}
              {(authRules.canViewByKeys([
                INCOMINGSMS_PERMISSION,
                ARTICLES_PERMISSION,
                DOWNLOADS_PERMISSION
              ]) ||
                authRules.hasPermissionByKeyAndName(
                  EMAIL_TEMPLATES_PERMISSION,
                  "email-templates.send"
                )) && (
                <li
                  className={
                    "menu collapse-box " +
                    (this.isSelected("incoming-sms-messages") ? "open" : "")
                  }
                >
                  <span
                    className="arrowPos"
                    onClick={e => {
                      e.preventDefault();
                      this.toggleMenu("incoming-sms-messages");
                    }}
                  />
                  <Button
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      this.handleSelectMenu("incoming-sms-messages");
                    }}
                  >
                    <i className="zmdi zmdi-account-box zmdi-hc-fw" />
                    <span className="nav-text">
                      {localize("menu", "Egyebek")}
                    </span>
                  </Button>
                  <ul className="sub-menu">
                    <MenuPermission module={INCOMINGSMS_PERMISSION}>
                      <li>
                        <NavLink to="/incoming-sms-messages">
                          <span className="nav-text">
                            {localize("menu", "SMS")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={ARTICLES_PERMISSION}>
                      <li
                        className={
                          "menu no-arrow " +
                          (selectedMenu === "articles" ? "open" : "")
                        }
                        onClick={() => {
                          this.handleSelectMenu("articles");
                        }}
                      >
                        <NavLink to="/articles">
                          <span className="nav-text">
                            {localize("menu", "Hírek")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    <MenuPermission module={DOWNLOADS_PERMISSION}>
                      <li
                        className={
                          "menu no-arrow " +
                          (selectedMenu === "downloads" ? "open" : "")
                        }
                        onClick={() => {
                          this.handleSelectMenu("downloads");
                        }}
                      >
                        <NavLink to="/downloads">
                          <span className="nav-text">
                            {localize("menu", "Letöltések")}
                          </span>
                        </NavLink>
                      </li>
                    </MenuPermission>
                    {authRules.hasPermissionByKeyAndName(
                      EMAIL_TEMPLATES_PERMISSION,
                      "email-templates.send"
                    ) && (
                      <li>
                        <NavLink to="/email/send">
                          <span className="nav-text">
                            {localize("menu", "Email")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {/* Egyéb end */}
            </ul>
          </CustomScrollbars>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sideBarOpened: state.UIsettings.sideBarOpened,
    distributor: state.auth.distributor,
    connected: state.signing.connected,
    code: state.signing.code
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sidebarToogle: UIActions.sidebarToogle,
      disconnect: signingActions.disconnect
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBar);
