import axios from "axios";
import qs from "qs";
import { get } from "lodash";
import * as fileActions from "./file";
import moment from "moment";

const SERVICEORDER_LIST_LOAD = "SERVICEORDER_LIST_LOAD";
const SERVICEORDER_LIST_SUCCESS = "SERVICEORDER_LIST_SUCCESS";
const SERVICEORDER_LIST_FAILED = "SERVICEORDER_LIST_FAILED";
const SERVICEORDER_SET_LOADING = "SERVICEORDER_SET_LOADING";

const SERVICEORDER_FORM_LOAD = "SERVICEORDER_FORM_LOAD";
const SERVICEORDER_FORM_SUCCESS = "SERVICEORDER_FORM_SUCCESS";
const SERVICEORDER_FORM_FAILED = "SERVICEORDER_FORM_FAILED";

const SERVICEORDER_DELETE_LOAD = "SERVICEORDER_DELETE_LOAD";
const SERVICEORDER_DELETE_SUCCESS = "SERVICEORDER_DELETE_SUCCESS";
const SERVICEORDER_DELETE_FAILED = "SERVICEORDER_DELETE_FAILED";

const SERVICEORDER_EDIT_LOAD = "SERVICEORDER_EDIT_LOAD";
const SERVICEORDER_EDIT_SUCCESS = "SERVICEORDER_EDIT_SUCCESS";
const SERVICEORDER_EDIT_FAILED = "SERVICEORDER_EDIT_FAILED";

const SERVICEORDER_VIEW_LOAD = "SERVICEORDER_VIEW_LOAD";
const SERVICEORDER_VIEW_SUCCESS = "SERVICEORDER_VIEW_SUCCESS";
const SERVICEORDER_VIEW_FAILED = "SERVICEORDER_VIEW_FAILED";

const SERVICEORDER_TAX_LOAD = "SERVICEORDER_TAX_LOAD";
const SERVICEORDER_TAX_SUCCESS = "SERVICEORDER_TAX_SUCCESS";
const SERVICEORDER_TAX_FAILED = "SERVICEORDER_TAX_FAILED";

const SERVICEORDER_RESET = "SERVICEORDER_RESET";
const SERVICEORDER_RESET_FORM = "SERVICEORDER_RESET_FORM";

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit: process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: "",
  orderMode: "ASC",
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null,
  taxClient: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICEORDER_RESET:
      return {
        ...initialState
      };
    case SERVICEORDER_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case SERVICEORDER_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case SERVICEORDER_TAX_LOAD:
      return {
        ...state,
        taxClient: false,
        message: null,
        formErrors: {}
      };
    case SERVICEORDER_TAX_SUCCESS:
      return {
        ...state,
        taxClient: action.taxClient,
        message: null,
        formErrors: {}
      };
    case SERVICEORDER_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false
      };
    case SERVICEORDER_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case SERVICEORDER_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case SERVICEORDER_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case SERVICEORDER_FORM_FAILED:
    case SERVICEORDER_EDIT_FAILED:
    case SERVICEORDER_DELETE_FAILED:
    case SERVICEORDER_LIST_FAILED:
    case SERVICEORDER_TAX_FAILED:
    case SERVICEORDER_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false
      };
    case SERVICEORDER_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode
      };
    case SERVICEORDER_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        viewData: action.viewData,
        viewLoading: false
      };
    case SERVICEORDER_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case SERVICEORDER_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case SERVICEORDER_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: action.id,
        viewLoading: false
      };
    case SERVICEORDER_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: SERVICEORDER_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: SERVICEORDER_RESET_FORM
  });
};

export const resetTaxNumber = () => dispatch =>
  dispatch({ type: SERVICEORDER_TAX_LOAD });

export const checkTaxNumber = tax_number => dispatch => {
  dispatch({
    type: SERVICEORDER_TAX_LOAD
  });
  return axios
    .get("/v1/cashregisters/client?taxNumber=" + tax_number)
    .then(response => {
      let taxClient = get(response, "data.data", false);
      dispatch({
        type: SERVICEORDER_TAX_SUCCESS,
        taxClient: taxClient.client_id ? taxClient : false
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: SERVICEORDER_TAX_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const updateOrder = (model, id, data) => dispatch => {
  dispatch({
    type: SERVICEORDER_EDIT_LOAD
  });

  let obj = {
    ServiceOrder: data
  };

  return axios
    .put("/v1/service-orders/attach-document/" + id, obj)
    .then(response => {
      dispatch({
        type: SERVICEORDER_EDIT_SUCCESS
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: SERVICEORDER_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const viewOrder = id => dispatch => {
  dispatch({
    type: SERVICEORDER_VIEW_LOAD
  });
  return axios
    .get("/v1/service-orders/" + id)
    .then(response => {
      dispatch({
        type: SERVICEORDER_VIEW_SUCCESS,
        viewData: get(response, "data.data"),
        commentThread: get(response, "data.commentThread", false)
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: SERVICEORDER_VIEW_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

/**
 * Szerződéshez csatoldokumentumok jóváhagyásakor megjelenítendő dokumentum típusok
 *
 * @param {Integer} id
 * @return {Object} response
 *
 */
export const feedbackFields = id => {
  return axios.get("/v1/service-orders/options?component=feedback&id=" + id);
};

/**
 * Szerződés csatolt dokumentumainak beküldése
 *
 * @param {Integer} id
 * @return {Object} response
 *
 */
export const submitFeedbackOk = id => {
  return axios.put("/v1/service-orders/feedback/" + id, {
    ServiceOrderFeedbackForm: {
      status: "okay"
    }
  });
};
/**
 * Szerződés csatolt dokumentumainak beküldése
 *
 * @param {Integer} id
 * @return {Object} response
 *
 */
export const feedback = id => {
  return axios.get("/v1/service-orders/feedback/" + id);
};
/**
 * Szerződés csatolt dokumentumainak beküldése
 *
 * @param {Integer} id
 * @return {Object} response
 *
 */
export const submitFeedbackWrong = (id, documents, wrongCause, warning) => {
  return axios.put("/v1/service-orders/feedback/" + id, {
    ServiceOrderFeedbackForm: {
      status: "wrong",
      wrong_ask_for: documents,
      wrong_cause: wrongCause,
      warning
    }
  });
};

export const submitFeedbackPending = (id, warning) => {
  return axios.put("/v1/service-orders/feedback/" + id, {
    ServiceOrderFeedbackForm: {
      status: "pending",
      warning
    }
  });
};

export const submitNotArrived = (id, warning) => {
  return axios.put("/v1/service-orders/feedback/" + id, {
    ServiceOrderFeedbackForm: {
      status: "not_arrived",
      warning
    }
  });
};

/**
 * Szerződéshez csatolható dokumentumok típusok listája
 *
 * @param {Integer} id
 * @return {Object} response
 *
 */
export const documentFields = id => {
  return axios.get("/v1/service-orders/options?component=attach&id=" + id);
};

/**
 * Szerződés dokumentumai
 *
 * @param {Integer} id
 * @return {Object} response
 *
 */
export const documents = id => {
  return axios.get("/v1/service-orders/documents/" + id);
};

/**
 * Szerződés csatolt dokumentumainak beküldése
 *
 * @param {Integer} id
 * @return {Object} response
 *
 */
export const submitDocuments = id => {
  return axios.post("/v1/service-orders/submit-documents/" + id);
};

/**
 * Szerződés csatolt dokumentumainak beküldése
 *
 * @param {Integer} id
 * @return {Object} response
 *
 */
export const submitContracts = id => {
  return axios.post("/v1/service-orders/submit-contracts/" + id);
};

/**
 * Szerződés dokumentum csatoláas
 *
 * @param {Integer} id
 * @param {Object} ServiceOrderAttachForm { "type": "CONTRACT_PAGE_1_5", "file_id": "lusCwz9C8d.pdf"}
 *
 */
export const attachDocument = (id, ServiceOrderAttachForm) => {
  return axios.post("/v1/service-orders/documents/" + id, {
    ServiceOrderAttachForm
  });
};

/**
 * Szerződés dokumentum csatoláas
 *
 * @param {Integer} id
 * @param {Object} ServiceOrderAttachForm { "type": "CONTRACT_PAGE_1_5", "file_id": "lusCwz9C8d.pdf"}
 *
 */
export const dettachDocument = id => {
  return axios.delete("/v1/service-orders/document/" + id);
};
/**
 * Csoportos telekom import
 *
 * @param {Integer} id
 * @param {Object} ServiceOrderBulkFeedbackForm { "type": "CONTRACT_PAGE_1_5", "file_id": "lusCwz9C8d.pdf"}
 *
 */
export const bulkFeedback = ServiceOrderBulkFeedbackForm => {
  return axios.post("/v1/service-orders/feedback", {
    ServiceOrderBulkFeedbackForm
  });
};

export const createOrder = (model, data) => dispatch => {
  dispatch({
    type: SERVICEORDER_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .post("/v1/service-orders", obj)
    .then(response => {
      dispatch({
        type: SERVICEORDER_EDIT_SUCCESS,
        id: get(response, "data.data.id", null)
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: SERVICEORDER_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const OrdersDelete = id => dispatch => {
  dispatch({
    type: SERVICEORDER_DELETE_LOAD
  });
  return axios
    .delete("/v1/service-orders/" + id)
    .then(response => {
      dispatch({
        type: SERVICEORDER_DELETE_SUCCESS,
        id
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: SERVICEORDER_DELETE_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SERVICEORDER_SET_LOADING,
    loading
  });
};

export const OrdersList = (
  page,
  keyword,
  sort,
  filters,
  perPage
) => dispatch => {
  dispatch({
    type: SERVICEORDER_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    "per-page": perPage
  };

  params[filters.model] = filters.filters;

  return axios
    .get(
      "/v1/service-orders",
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then(response => {
      let sort = get(response, "data.sort", null),
        mode = "ASC";
      if (sort && sort.indexOf("-") !== -1) {
        mode = "DESC";
        sort = sort.substr(1);
      }
      dispatch({
        type: SERVICEORDER_LIST_SUCCESS,
        data: get(response, "data.data", null),
        page: get(response, "data.page", 0),
        limit: get(response, "data.per-page", 0),
        total: get(response, "data.total", 0),
        order: sort,
        orderMode: mode
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: SERVICEORDER_LIST_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const downloadFile = id => dispatch => {
  return dispatch(
    fileActions.download(
      "/v1/service-orders/contract/" + id,
      `servideorder_${moment().format("YYYY-MM-DD")}.pdf`
    )
  );
};
export const getRule = data => dispatch => {
  return axios
    .get(
      "/v1/cashregisters/rule",
      {
        params: data,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      dispatch({
        // type: SERVICEORDER_LIST_FAILED,
        // message: get(error, 'response.data.message', null)
      });
      throw error.response;
    });
};
