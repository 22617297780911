import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText, IconButton, Checkbox } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import ReactDOM from "react-dom";
import Radio from "@material-ui/core/Radio";

const copy = arr =>
  Array.isArray(arr) ? arr.map(item => Object.assign({}, item)) : null;

const styles = theme => ({
  table: {
    width: "100%",
    border: "none",
    borderStyle: "hidden",
    "& td": {
      border: "1px solid #ced4da",
      padding: "2px 9px"
    },
    "& td.input": {
      border: "1px solid #ced4da",
      padding: 0
    },
    "& th": {
      border: "1px solid #ced4da",
      padding: "2px 9px",
      width: "calc(50% - 10px)"
    },
    "& tr > td:first-child input": {
      marginLeft: 1
    }
  },
  root: {
    //borderCollapse: "collapse",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 14,
    marginTop: 16,
    //width: "auto",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
      borderColor: theme.palette.primary.main
    },
    "&[aria-invalid=true]": {
      borderColor: theme.palette.error.main,
      "&:focus": {
        boxShadow: `${theme.palette.error.main} 0 0 0 1px`
      }
    }
  },
  input: {
    display: "flex",
    "& input": {
      padding: "4px 9px",
      //borderCollapse: "collapse",
      width: "100%",
      display: "flex",
      position: "relative",
      backgroundColor: theme.palette.common.white,
      fontSize: 14,
      //padding: 0,
      //width: "auto",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(","),
      "&:focus": {
        boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
        borderColor: theme.palette.primary.main
      },
      "&[aria-invalid=true]": {
        borderColor: theme.palette.error.main,
        "&:focus": {
          boxShadow: `${theme.palette.error.main} 0 0 0 1px`
        }
      }
    }
  },
  inputDropdown: {
    padding: "4px 9px",
    //borderCollapse: "collapse",
    width: "100%",
    display: "flex",
    position: "relative",
    //border: "none",
    backgroundColor: theme.palette.common.white,
    border: "solid 1px white",
    fontSize: 14,
    //padding: 0,
    //width: "auto",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    boxShadow: "none !important",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      outline: "none",
      boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
      borderColor: theme.palette.primary.main
    },
    "&[aria-invalid=true]": {
      borderColor: theme.palette.error.main,
      "&:focus": {
        boxShadow: `${theme.palette.error.main} 0 0 0 1px`
      }
    }
  },
  icon: {
    padding: 0
  },
  actionCell: {
    width: "20px!important"
  },
  lineNumberCell: {
    width: "20px!important"
  },
  checkbox: {
    display: "flex",
    padding: 0
  },
  headerDisabled: {
    "& th": {
      color: "rgba(0, 0, 0, 0.54)"
    }
  }
});

class SimpleArray2dArray extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: copy(props.value)
    };
  }

  componentDidMount() {
    const { useKeyEvents } = this.props;
    if (useKeyEvents) {
      this.node = ReactDOM.findDOMNode(this);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.value) !== JSON.stringify(this.state.value)) {
      this.setState({ value: copy(nextProps.value) });
    }
  }

  getEmptyRow = () => {
    const { columns } = this.props;
    let row = {};
    columns.forEach(column => {
      row[column.id] = column.hasOwnProperty("defaultValue")
        ? column.defaultValue
        : null;
    });
    return row;
  };

  addNewRow = (cb, atIndex) => {
    const { onChange } = this.props;
    let newValue = copy(this.state.value);
    if (!newValue) {
      newValue = [this.getEmptyRow()];
    } else {
      if (atIndex) {
        newValue.splice(atIndex, 0, this.getEmptyRow());
      } else {
        newValue.push(this.getEmptyRow());
      }
    }
    this.setState({ value: newValue }, () => {
      if (typeof cb === "function") {
        cb();
      }
      onChange && onChange({ target: { value: copy(newValue) } });
    });
  };

  deleteRow = (index, cb) => {
    const { onChange } = this.props;
    const newValue = copy(this.state.value);
    newValue.splice(index, 1);
    this.setState({ value: newValue }, () => {
      cb && cb();
      onChange && onChange({ target: { value: copy(newValue) } });
    });
  };

  onChange = (index, column) => evt => {
    const { id, type } = column;
    const { onChange } = this.props;
    const newValue = copy(this.state.value);
    let castedValue = null;
    switch (type) {
      case "number":
        castedValue = parseFloat(evt.target.value);
        if (isNaN(castedValue)) {
          castedValue = null;
        }
        break;

      default:
        castedValue = evt.target.value;
        break;
    }

    newValue[index][id] = castedValue === "" ? null : castedValue;

    clearTimeout(this.timeout);
    this.setState({ value: newValue }, () => {
      this.timeout = setTimeout(() => {
        onChange && onChange({ target: { value: copy(newValue) } });
      }, 500);
    });
  };

  onChangeCheckBox = (index, column) => evt => {
    const { id } = column;
    const { onChange } = this.props;
    const newValue = copy(this.state.value);
    newValue[index][id] = evt.target.checked;
    if (column.changeValues) {
      column.changeValues(newValue[index]);
    }
    this.setState({ value: newValue }, () => {
      onChange && onChange({ target: { value: copy(newValue) } });
    });
  };

  onKeyDown = evt => {
    const column = this.props.columns[
      parseInt(evt.target.getAttribute("columnindex"))
    ];
    evt.persist();
    let keyCode = evt.which || evt.keyCode;
    if (keyCode === 13 && column.insertNewRowOnenter) {
      //Enter
      //const node = ReactDOM.findDOMNode(this);
      const tabIndex = parseInt(evt.target.tabIndex);
      this.addNewRow(() => {
        evt.preventDefault();
        const child = this.node.querySelector(
          `input[tabindex="${tabIndex + 1}"]`
        );
        child && child.focus();
      }, tabIndex);
    }
    if (keyCode === 40 && column.arrowNavigationDown) {
      //Le
      //const node = ReactDOM.findDOMNode(this);
      const tabIndex = parseInt(evt.target.tabIndex);
      evt.preventDefault();
      const child = this.node.querySelector(
        `input[tabindex="${tabIndex + 1}"]`
      );
      child && child.focus();
    }
    if (keyCode === 38 && column.arrowNavigationUp) {
      //fel
      //const node = ReactDOM.findDOMNode(this);
      const tabIndex = parseInt(evt.target.tabIndex);
      evt.preventDefault();
      const child = this.node.querySelector(
        `input[tabindex="${tabIndex - 1}"]`
      );
      child && child.focus();
    }
    if (keyCode === 8 && column.clearRowOnBackSpace) {
      //Törlés
      if (evt.target.value === "") {
        //const node = ReactDOM.findDOMNode(this);
        const tabIndex = parseInt(evt.target.tabIndex);
        this.deleteRow(tabIndex - 1, () => {
          evt.preventDefault();
          const child = this.node.querySelector(
            `input[tabindex="${tabIndex - 1}"]`
          );
          child && child.focus();
        });
      }
    }
  };

  onBlur = evt => {
    const { value } = this.state;
    const { onChange, onBlur } = this.props;
    evt.persist();
    evt.target.value = copy(value);
    clearTimeout(this.timeout);
    if (JSON.stringify(this.state.value) !== JSON.stringify(this.props.value)) {
      onChange && onChange({ target: { value: copy(value) } });
    }
    onBlur && onBlur(evt);
  };

  render() {
    const {
      id,
      classes,
      label,
      error,
      helperText,
      fullWidth,
      isRequired,
      columns,
      showLineNumbers,
      useKeyEvents,
      maxLength,
      disabled
    } = this.props;
    const { value } = this.state;

    return (
      <FormControl
        fullWidth={fullWidth}
        error={error ? true : false} /*className={classes.margin}*/
      >
        <InputLabel
          shrink
          htmlFor={id}
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontWeight: isRequired ? "bold" : "normal"
          }}
          nolabel={label ? undefined : 1}
          error={error ? true : false}
        >
          {label}
          {isRequired ? " *" : ""}
        </InputLabel>
        <div className={classes.root}>
          <table className={classes.table}>
            <thead>
              <tr className={disabled ? classes.headerDisabled : undefined}>
                {showLineNumbers && (
                  <th className={classes.lineNumberCell}>#</th>
                )}
                {columns.map((column, columnIndex) => (
                  <th
                    key={columnIndex}
                    style={column.width ? { width: column.width } : {}}
                  >
                    {column.label}
                  </th>
                ))}
                {!disabled && (
                  <th className={classes.actionCell}>
                    {(!value || maxLength > value.length) && (
                      <IconButton
                        onClick={this.addNewRow}
                        color="primary"
                        className={classes.icon}
                        size="small"
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                    )}
                  </th>
                )}
              </tr>
            </thead>
            {value ? (
              <tbody>
                {value.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {showLineNumbers && <td>{rowIndex + 1}</td>}
                    {columns.map((column, columnIndex) => {
                      const style =
                        typeof column.style === "function"
                          ? column.style(row)
                          : column.style;
                      return (
                        <td key={columnIndex} className="input">
                          {column.type === "dropdown" && (
                            <select
                              className={classes.inputDropdown}
                              value={row[column.id] || ""}
                              type={column.type}
                              onChange={this.onChange(rowIndex, column)}
                            >
                              {Object.keys(column.options).map(
                                (optionValue, optionIdex) => {
                                  const optionLabel =
                                    column.options[optionValue];
                                  return (
                                    <option
                                      key={optionIdex}
                                      value={optionValue}
                                    >
                                      {optionLabel}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          )}
                          {column.type === "checkbox" && (
                            <Checkbox
                              color="primary"
                              className={classes.checkbox}
                              checked={row[column.id] || false}
                              onChange={this.onChangeCheckBox(rowIndex, column)}
                              disabled={disabled}
                            />
                          )}
                          {column.type === "radio" && (
                            <Radio
                              color="primary"
                              className={classes.checkbox}
                              checked={row[column.id] || false}
                              name={column.name}
                              onChange={this.onChangeCheckBox(rowIndex, column)}
                              disabled={disabled}
                            />
                          )}
                          {column.type === "calc" &&
                            column.render(row, disabled)}
                          {(!column.type ||
                            column.type === "number" ||
                            column.type === "date") && (
                              <InputBase
                                className={classes.input}
                                inputProps={{
                                  value: row[column.id] || "",
                                  maxLength: column.maxlength || undefined,
                                  tabIndex: rowIndex + 1,
                                  style,
                                  columnindex: columnIndex,
                                  rowindex: rowIndex
                                }}
                                onKeyDown={
                                  useKeyEvents ? this.onKeyDown : undefined
                                }
                                disabled={disabled}
                                type={column.type}
                                onChange={this.onChange(rowIndex, column)}
                                onWheel={evt => evt.target.blur()}
                                onBlur={this.onBlur}
                              />
                            )}
                        </td>
                      );
                    })}
                    {!disabled && (
                      <td className={classes.actionCell}>
                        <IconButton
                          onClick={() => this.deleteRow(rowIndex)}
                          color="primary"
                          className={classes.icon}
                          size="small"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody />
            )}
          </table>
        </div>
        {(error || helperText) && (
          <FormHelperText error={error ? true : false}>
            {error || helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

SimpleArray2dArray.defaultProps = {
  id: `simple-array-2d_${new Date().getTime().toString()}`,
  maxLength: 100
};

SimpleArray2dArray.propTypes = {
  classes: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  id: PropTypes.string,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  columns: PropTypes.array.isRequired,
  value: PropTypes.any,
  showLineNumbers: PropTypes.bool,
  useKeyEvents: PropTypes.bool,
  maxLength: PropTypes.number
};

export default withStyles(styles)(SimpleArray2dArray);
