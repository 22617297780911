import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import connect from "react-redux/es/connect/connect";
import { Form } from "reactstrap";
import { Field, reduxForm } from "redux-form";

import { FormHelperText } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DefaultFormButtons from "Components/Forms/Buttons/DefaultFormButtons";
import DateField from "Components/Forms/Fields/Date";
import InputField from "Components/Forms/Fields/Input";
import Validations from "Components/Forms/Validations";
import localize from "helpers/localize";
import { apMask } from "helpers/maskHelper";

const theme = require(`themes/${process.env.REACT_APP_THEME}/theme.js`);

class CashregisterKeyGeneratorForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  render() {
    const {
      handleInputChange,
      fieldErrors,
      loading,
      handleSubmit,
      annual_loading,
      canReregister
    } = this.props;

    const load = loading ? true : annual_loading;

    return (
      <Fragment>
        <Form
          onSubmit={handleSubmit}
          className="form"
          style={{ width: "100%" }}
        >
          <div className="form-body">
            <Grid container={true} spacing={16}>
              <Grid container={true} item={true} spacing={16} xs={12}>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    isRequired={true}
                    validate={[Validations.required]}
                    name="input_date_param"
                    label={localize("cashregisters", "Dátum")}
                    fieldError={fieldErrors.input_date_param}
                    component={DateField}
                    placeholder={localize("cashregisters", "Dátum")}
                    onChange={() => handleInputChange("input_date_param")}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="apn"
                    label={localize("cashregisters", "AP szám")}
                    fieldError={fieldErrors.apn}
                    onChange={() => handleInputChange("apn")}
                    component={InputField}
                    type="text"
                    placeholder={localize("cashregisters", "AP szám")}
                    readOnly={true}
                    {...apMask}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="muszereszIgazolvanySzam"
                    label={localize(
                      "cashregisters",
                      "Szervizes műszerész ig. száma"
                    )}
                    fieldError={fieldErrors.muszereszIgazolvanySzam}
                    onChange={() =>
                      handleInputChange("muszereszIgazolvanySzam")
                    }
                    component={InputField}
                    type="text"
                    placeholder={localize(
                      "cashregisters",
                      "Szervizes műszerész ig. száma"
                    )}
                    readOnly={true}
                  />
                </Grid>
                {this.props.type === "recent" && (
                  <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                    <Field
                      isRequired={true}
                      validate={[Validations.required]}
                      name="param"
                      label={localize("cashregisters", "Partner kód")}
                      fieldError={fieldErrors.param}
                      onChange={() => handleInputChange("param")}
                      component={InputField}
                      type="text"
                      placeholder={localize("cashregisters", "Partner kód")}
                    />
                  </Grid>
                )}
                {this.props.type === "generalcheck" && (
                  <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                    <Field
                      isRequired={true}
                      validate={[Validations.required]}
                      name="param"
                      label={localize("cashregisters", "Matrica szám")}
                      fieldError={fieldErrors.param}
                      onChange={() => handleInputChange("param")}
                      component={InputField}
                      type="text"
                      placeholder={localize("cashregisters", "Matrica szám")}
                    />
                  </Grid>
                )}
                {[/*"pwdreregister", */ "battery", "rs232"].includes(
                  this.props.type
                ) && (
                  <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                    <Field
                      isRequired={true}
                      validate={[Validations.required]}
                      name="param"
                      label={localize("cashregisters", "Token")}
                      fieldError={fieldErrors.param}
                      onChange={() => handleInputChange("param")}
                      component={InputField}
                      type="text"
                      placeholder={localize("cashregisters", "Token")}
                    />
                  </Grid>
                )}
                {this.props.generated_code && (
                  <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                    <Paper
                      className="app-main-header"
                      style={{
                        padding: 16,
                        marginTop: 8,
                        marginBottom: 8,
                        backgroundColor: theme.default.palette.primary.main,
                        color: "white"
                      }}
                    >
                      <div className="controls">
                        <label style={{ width: 40 }}>
                          {localize("cashregisters", "Kód:")}
                        </label>{" "}
                        {this.props.generated_code}
                      </div>
                      {this.props.type === "generalcheck" && (
                        <Fragment>
                          <hr style={{ marginTop: 4, marginBottom: 4 }} />
                          <div className="controls">
                            <label style={{ width: 40 }}>
                              {localize("cashregisters", "Link:")}
                            </label>{" "}
                            {this.props.annual_review_data ? (
                              <a
                                style={{ color: "white" }}
                                target="_blank"
                                href={
                                  this.props.annual_review_data.public_view
                                    ? this.props.annual_review_data.public_view
                                    : "#"
                                }
                              >
                                {this.props.annual_review_data.public_view
                                  ? this.props.annual_review_data.public_view
                                  : "Hiányzó link"}
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </Fragment>
                      )}
                    </Paper>
                  </Grid>
                )}
              </Grid>

              {this.props.generated_code ? (
                ""
              ) : (
                <Grid container={true} item={true} spacing={16} xs={12}>
                  <Grid item={true} xs={12}>
                    {!canReregister && this.props.type === "pwdreregister" && (
                      <FormHelperText error style={{ textAlign: "right" }}>
                        {localize(
                          "cashregisters",
                          "Hiba: Nem megfelelő számú éves felülvizsgálat került elvégzésre, lépjen kapcsolatba a forgalmazóval."
                        )}
                      </FormHelperText>
                    )}
                    <DefaultFormButtons
                      enabledButton={
                        this.props.type !== "pwdreregister" ||
                        (this.props.type === "pwdreregister" && canReregister)
                      }
                      loading={load}
                      {...this.props}
                      isViewPage={false}
                      disableCancel={true}
                      texts={{ saveButton: localize("basic", "Generálás") }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        </Form>
      </Fragment>
    );
  }
}

const UsersReduxForm = reduxForm({
  enableReinitialize: false,
  keepDirtyOnReinitialize: false,
  updateUnregisteredFields: false
})(CashregisterKeyGeneratorForm);

export default connect(
  (state, props) => ({
    form: `keygenerator${props.type}`,
    initialValues: {
      apn: state.cashregisters.viewData.apn,
      input_date_param: moment().format("YYYY-MM-DD"),
      muszereszIgazolvanySzam: state.auth.user.card_number
    }
  }),
  null
)(UsersReduxForm);
