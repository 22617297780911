import React, { PureComponent } from "react";

import { Field } from "redux-form";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Link from "@material-ui/core/Link";
import { get } from "lodash";

const FileField = props => {
  const {
    input: { value },
    fileId
  } = props;
  console.log(props);
  const url = `${
    process.env.REACT_APP_HOST
  }/v1/files/download/${fileId}?accessToken=${localStorage
    .getItem("access_token")
    .replace("Bearer ", "")}`;

  return <Link href={url}>{value}</Link>;
};

class Item extends PureComponent {
  render() {
    const { index, collector, deleteCell, data } = this.props;
    const fileId = get(data, `[${index}].id`);

    return (
      <TableRow>
        <TableCell>
          <Field
            name={`${collector}.file_name`}
            fileId={fileId}
            //fieldError={fieldError.name}
            component={FileField}
            //isViewPage={true}
            //inputRootClass={"simpleImputRoot"}
          />
        </TableCell>
        {deleteCell(index)}
      </TableRow>
    );
  }
}

export default Item;
