const radioCheckboxStyle = theme => ({
  root: {
    display: "flex"
  },
  /*wrapper: {
    "& .controls": {
      width: "100%"
    }
  },*/
  label: { marginLeft: 0, width: "100%" },
  labelRow: {
    marginLeft: 0,
    width: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  radioGrouplabelStart: {
    marginLeft: "auto"
  },
  radioGrouplabelStartLabel: {
    marginLeft: 0,
    marginRight: 0
  },
  radio: {
    padding: theme.spacing.unit / 4
  }
});

export default radioCheckboxStyle;
