import React, { Component } from "react";
import TextField from "@material-ui/core/TextField/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import inputStyle from "./inputStyle.js";
import { withStyles } from "@material-ui/core/styles";
import Wrapper from "./Wrapper";
import classNames from "classnames";
import PropTypes from "prop-types";
import InfoIcon from "@material-ui/icons/InfoOutlined";

class InputField extends Component {
  static propTypes = {
    input: PropTypes.any.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.shape({
      touched: PropTypes.bool.isRequired,
      error: PropTypes.string,
      warning: PropTypes.string
    }),
    fieldError: PropTypes.string
  };

  handleBlur = value => {
    if (this.props.handleBlur) {
      this.props.handleBlur(value);
    }
  };

  render() {
    const {
      input,
      type,
      fieldError,
      label,
      readOnly,
      classes,
      theme,
      labelBefore,
      isRequired,
      labelInfo,
      TextInputProps,
      customViewValue
    } = this.props;
    const { warning, touched, error, autofilled } = this.props.meta;
    const labelClass = classNames(
      classes.inputLabel,
      labelBefore ? classes.labelBefore : null
    );
    const inputRootClass = classNames(
      classes.bootstrapRoot,
      labelBefore ? classes.bootstrapRootBefore : null
    );

    const InputProps = {
      InputProps: {
        classes: {
          root:
            inputRootClass +
            (this.props.inputRootClass
              ? " " + classes[this.props.inputRootClass]
              : ""),
          input: classes.bootstrapInput
        },
        ...TextInputProps,
        ...(type === "password" ? { type: "text" } : {})
      }
    };

    let labelAdvanced = label;

    if (typeof label === "string") {
      if (isRequired) {
        labelAdvanced = label + " *";
      }

      if (labelInfo) {
        labelAdvanced = (
          <div>
            {labelAdvanced}
            <Tooltip title={labelInfo}>
              <InfoIcon
                color="primary"
                style={{
                  marginLeft: 6,
                  marginTop: -5,
                  marginBottom: -5,
                  width: 20,
                  height: 20
                }}
              />
            </Tooltip>
          </div>
        );
      }
    }
    //console.log(this.props);;
    return (
      <Wrapper
        {...this.props}
        value={customViewValue ? customViewValue(input.value) : input.value}
      >
        {/*<FormControl
              className={classes.margin}
              fullWidth
            >
        <InputLabel shrink htmlFor="bootstrap-input" className={classes.bootstrapFormLabel}>
        {label}
        </InputLabel>
        <Input
        {...input}
          defaultValue={input.value || ''}
          classes={{
            root: classes.bootstrapRoot,
            input: classes.bootstrapInput,
          }}
          readOnly={readOnly}
          onBlur={(event) => this.handleBlur(event.target.value)}
          autoComplete={'new-'+input.name}
        />
      </FormControl>*/}
        <TextField
          {...input}
          label={labelAdvanced}
          variant="outlined"
          fullWidth
          //defaultValue={input.value || ''}
          //margin="normal"
          //className="mt-1 my-sm-3"
          disabled={readOnly ? true : false}
          type={type === "password" ? "text" : type}
          className={classes.root}
          autoComplete={"new-" + input.name}
          error={fieldError || (touched && (warning || (error ? true : false)))}
          helperText={fieldError || (touched && (warning || error))}
          inputProps={{
            disabled: readOnly ? true : false,
            style:
              type === "password"
                ? {
                  fontFamily: "text-security-disc"
                }
                : {
                    backgroundColor: autofilled
                      ? theme.palette.autofill.background
                      : "auto"
                  }
          }}
          //onBlur={event => this.handleBlur(event.target.value)}
          onKeyPress={this.props.onKeyPress}
          multiline={this.props.multiline}
          rows={this.props.rows}
          InputLabelProps={{
            shrink: true,
            className: labelClass
          }}
          FormHelperTextProps={{ className: classes.formHelperText }}
          {...InputProps}
        />
      </Wrapper>
    );
  }
}

export default withStyles(inputStyle, { withTheme: true })(InputField);
