import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Home from "./views/Home";
import * as homeActions from "redux/modules/home";
import * as fileActions from "redux/modules/file";
import * as sliderActions from "redux/modules/slider";

const mapStateToProps = state => {
  return {
    dashboard: state.homeDashBoard,
    events: state.homeEvents,
    events_loading: state.homeEvents.loading,
    slider: state.slider
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reset: homeActions.reset,
      getList: homeActions.homeList,
      eventList: homeActions.eventList,
      index: sliderActions.index,
      update: sliderActions.update,
      fileUpload: fileActions.upload,
      fileDelete: fileActions.fileDelete,
      fileDownload: fileActions.download
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
