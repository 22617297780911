import React from "react";
import PropTypes from "prop-types";
import localize from "helpers/localize";
import PageMenu from "Components/New/Menu/PageMenu/PageMenu";
import PageMenuContent from "Components/New/Menu/PageMenu/PageMenuContent";
import Templates from "./Templates/Templates";
import Send from "./Send/Send";
import * as emailPath from "./emailPath";
import authrules from "helpers/authRules";
import {
  EMAIL_PERMISSION,
  EMAIL_TEMPLATES_PERMISSION,
  EMAIL_SEND_PERMISSION
} from "constans/permissions";
const LC = "emailtemplates"; //Localization category

class Email extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //console.log("Shop.render", this.props.match.params);
    //const { menu } = this.props.match.params;
    return (
      <div style={{ position: "relative" }}>
        <PageMenu
          basePath={emailPath.PATH_BASE}
          onChange={this.handleTabChange}
          defaultPath={emailPath.PATH_SEND}
          color="primary"
        >
          {authrules.hasPermissionByKeyAndName(
            EMAIL_TEMPLATES_PERMISSION,
            "email-templates.send"
          ) && (
              <PageMenuContent
                path={emailPath.PATH_SEND}
                title={localize(LC, "Küldés")}
              >
                <Send />
              </PageMenuContent>
            )}
          {authrules.canModifyByKey(EMAIL_TEMPLATES_PERMISSION) && (
            <PageMenuContent
              path={emailPath.PATH_TEMPLATES}
              title={localize(LC, "Sablonok")}
            >
              <Templates />
            </PageMenuContent>
          )}
        </PageMenu>
      </div>
    );
  }
}

Email.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default Email;
