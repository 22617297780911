import axios from "axios";
import qs from "qs";
import { get } from "lodash";

const ANYK_LIST_LOAD = "ANYK_LIST_LOAD";
const ANYK_LIST_SUCCESS = "ANYK_LIST_SUCCESS";
const ANYK_LIST_FAILED = "ANYK_LIST_FAILED";
const ANYK_SET_LOADING = "ANYK_SET_LOADING";

const ANYK_FORM_LOAD = "ANYK_FORM_LOAD";
const ANYK_FORM_SUCCESS = "ANYK_FORM_SUCCESS";
const ANYK_FORM_FAILED = "ANYK_FORM_FAILED";

const WORKFEE_DELETE_LOAD = "WORKFEE_DELETE_LOAD";
const WORKFEE_DELETE_SUCCESS = "WORKFEE_DELETE_SUCCESS";
const WORKFEE_DELETE_FAILED = "WORKFEE_DELETE_FAILED";

const WORKFEE_EDIT_LOAD = "WORKFEE_EDIT_LOAD";
const WORKFEE_EDIT_SUCCESS = "WORKFEE_EDIT_SUCCESS";
const WORKFEE_EDIT_FAILED = "WORKFEE_EDIT_FAILED";

const WORKFEE_VIEW_LOAD = "WORKFEE_VIEW_LOAD";
const WORKFEE_VIEW_SUCCESS = "WORKFEE_VIEW_SUCCESS";
const WORKFEE_VIEW_FAILED = "WORKFEE_VIEW_FAILED";

const ANYK_RESET = "ANYK_RESET";
const ANYK_RESET_FORM = "ANYK_RESET_FORM";

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit: process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: "",
  orderMode: "ASC",
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ANYK_RESET:
      return {
        ...initialState
      };
    case ANYK_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case WORKFEE_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case ANYK_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false
      };
    case WORKFEE_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case WORKFEE_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case ANYK_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case ANYK_FORM_FAILED:
    case WORKFEE_EDIT_FAILED:
    case WORKFEE_DELETE_FAILED:
    case ANYK_LIST_FAILED:
    case WORKFEE_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false
      };
    case ANYK_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode
      };
    case WORKFEE_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        viewData: action.viewData,
        commentThread: action.commentThread || false,
        viewLoading: false
      };
    case ANYK_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case WORKFEE_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case WORKFEE_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: action.id,
        viewLoading: false
      };
    case ANYK_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: ANYK_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: ANYK_RESET_FORM
  });
};

export const updateAnyk = (model, id, data) => dispatch => {
  dispatch({
    type: WORKFEE_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .put("/v1/anyk-exports/" + id, obj)
    .then(() =>
      dispatch({
        type: WORKFEE_EDIT_SUCCESS
      })
    )
    .catch(error => {
      dispatch({
        type: WORKFEE_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
    });
};

export const viewAnyk = id => dispatch => {
  dispatch({
    type: WORKFEE_VIEW_LOAD
  });
  return axios
    .get("/v1/anyk-exports/" + id)
    .then(response =>
      dispatch({
        type: WORKFEE_VIEW_SUCCESS,
        viewData: get(response, "data.data"),
        commentThread: get(response, "data.commentThread", false)
      })
    )
    .catch(error => {
      dispatch({
        type: WORKFEE_VIEW_FAILED,
        message: get(error, "response.data.message", null)
      });
    });
};

export const createAnyk = (model, data) => dispatch => {
  dispatch({
    type: WORKFEE_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .post("/v1/anyk-exports", obj)
    .then(response =>
      dispatch({
        type: WORKFEE_EDIT_SUCCESS,
        id: get(response, "data.data.id", null)
      })
    )
    .catch(error => {
      dispatch({
        type: WORKFEE_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
    });
};

export const eportAnyk = type => {
  return axios
    .post(`/v1/anyk-exports?errorType=${type}`)
    .then(response => get(response, "data", null))
    .catch(error => {
      throw error.response;
    });
};

export const anykDelete = id => dispatch => {
  dispatch({
    type: WORKFEE_DELETE_LOAD
  });
  return axios
    .delete("/v1/anyk-exports/" + id)
    .then(response =>
      dispatch({
        type: WORKFEE_DELETE_SUCCESS,
        id
      })
    )
    .catch(error =>
      dispatch({
        type: WORKFEE_DELETE_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: ANYK_SET_LOADING,
    loading
  });
};

export const anykList = (page, keyword, sort, filters, perPage) => dispatch => {
  dispatch({
    type: ANYK_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    "per-page": perPage
  };

  params[filters.model] = filters.filters;

  return axios
    .get(
      "/v1/anyk-exports",
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then(response => {
      let sort = get(response, "data.sort", null),
        mode = "ASC";
      if (sort && sort.indexOf("-") !== -1) {
        mode = "DESC";
        sort = sort.substr(1);
      }
      return dispatch({
        type: ANYK_LIST_SUCCESS,
        data: get(response, "data.data", null),
        page: get(response, "data.page", 0),
        limit: get(response, "data.per-page", 0),
        total: get(response, "data.total", 0),
        order: sort,
        orderMode: mode
      });
    })
    .catch(error =>
      dispatch({
        type: ANYK_LIST_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};

export const markAnyk = id => {
  return axios
    .post(`/v1/anyk-exports/mark/${id}`)
    .then(response => get(response, "data", null))
    .catch(error => {
      throw error.response;
    });
};
