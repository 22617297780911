import axios from 'axios';
import qs from 'qs';
import { get } from 'lodash';
import * as fileActions from "./file";
import moment from "moment";

const EXAMINEE_EXAMS_LIST_LOAD = 'EXAMINEE_EXAMS_LIST_LOAD';
const EXAMINEE_EXAMS_LIST_SUCCESS = 'EXAMINEE_EXAMS_LIST_SUCCESS';
const EXAMINEE_EXAMS_LIST_FAILED = 'EXAMINEE_EXAMS_LIST_FAILED';
const EXAMINEE_EXAMS_SET_LOADING = 'EXAMINEE_EXAMS_SET_LOADING';

const EXAMINEE_EXAMS_FORM_LOAD = 'EXAMINEE_EXAMS_FORM_LOAD';
const EXAMINEE_EXAMS_FORM_SUCCESS = 'EXAMINEE_EXAMS_FORM_SUCCESS';
const EXAMINEE_EXAMS_FORM_FAILED = 'EXAMINEE_EXAMS_FORM_FAILED';

const EXAMINEE_EXAM_DELETE_LOAD = 'EXAMINEE_EXAM_DELETE_LOAD';
const EXAMINEE_EXAM_DELETE_SUCCESS = 'EXAMINEE_EXAM_DELETE_SUCCESS';
const EXAMINEE_EXAM_DELETE_FAILED = 'EXAMINEE_EXAM_DELETE_FAILED';

const EXAMINEE_EXAM_EDIT_LOAD = 'EXAMINEE_EXAM_EDIT_LOAD';
const EXAMINEE_EXAM_EDIT_SUCCESS = 'EXAMINEE_EXAM_EDIT_SUCCESS';
const EXAMINEE_EXAM_EDIT_FAILED = 'EXAMINEE_EXAM_EDIT_FAILED';

const EXAMINEE_EXAM_VIEW_LOAD = 'EXAMINEE_EXAM_VIEW_LOAD';
const EXAMINEE_EXAM_VIEW_SUCCESS = 'EXAMINEE_EXAM_VIEW_SUCCESS';
const EXAMINEE_EXAM_VIEW_FAILED = 'EXAMINEE_EXAM_VIEW_FAILED';

const EXAMINEE_EXAMS_RESET = 'EXAMINEE_EXAMS_RESET';
const EXAMINEE_EXAMS_RESET_FORM = 'EXAMINEE_EXAMS_RESET_FORM';

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit:process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: '',
  orderMode: 'ASC',
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null,
  reload: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXAMINEE_EXAMS_RESET:
      return {
        ...initialState
      };
    case EXAMINEE_EXAMS_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case EXAMINEE_EXAM_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case EXAMINEE_EXAMS_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false,
          reload: false
      };
    case EXAMINEE_EXAM_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case EXAMINEE_EXAM_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case EXAMINEE_EXAMS_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case EXAMINEE_EXAMS_FORM_FAILED:
    case EXAMINEE_EXAM_EDIT_FAILED:
    case EXAMINEE_EXAM_DELETE_FAILED:
    case EXAMINEE_EXAMS_LIST_FAILED:
    case EXAMINEE_EXAM_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false
      };
    case EXAMINEE_EXAMS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode
      };
      case EXAMINEE_EXAM_VIEW_SUCCESS:
        return {
            ...state,
            loading: false,
            success: true,
            failed: false,
            viewData: action.viewData,
            commentThread: action.commentThread || false,
            viewLoading: false
        };
    case EXAMINEE_EXAMS_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case EXAMINEE_EXAM_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id,
          reload: action.reload
      };
    case EXAMINEE_EXAM_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: action.id,
        viewLoading: false
      };
    case EXAMINEE_EXAMS_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
}

export const reset = () => dispatch => {
  dispatch({
    type: EXAMINEE_EXAMS_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: EXAMINEE_EXAMS_RESET_FORM
  });
};

export const updateExams = (model,id,data) => dispatch => {
  dispatch({
    type: EXAMINEE_EXAM_EDIT_LOAD
  });

    let obj = {};
    obj[model] = data;

  return axios.put('/v1/examinee-exams/'+id, obj).then(
      () => dispatch({
        type: EXAMINEE_EXAM_EDIT_SUCCESS
      })
  ).catch(error => {
    dispatch({
      type: EXAMINEE_EXAM_EDIT_FAILED,
      message: get(error, 'response.data.message', null),
      formErrors: get(error, 'response.data.form_errors', {})
    })
  });
};

export const viewExams = (id) => dispatch => {
    dispatch({
        type: EXAMINEE_EXAM_VIEW_LOAD
    });
    return axios.get('/v1/examinee-exams/'+id).then(
        response => dispatch({
            type: EXAMINEE_EXAM_VIEW_SUCCESS,
            viewData: get(response, 'data.data'),
            commentThread: get(response, 'data.commentThread', false)
        })
    ).catch(error => {
        dispatch({
            type: EXAMINEE_EXAM_VIEW_FAILED,
            message: get(error, 'response.data.message', null)
        })
    });
};

export const createExams = (model, data) => dispatch => {
  dispatch({
    type: EXAMINEE_EXAM_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios.post('/v1/examinee-exams', obj).then(response => dispatch({
    type: EXAMINEE_EXAM_EDIT_SUCCESS,
    id: get(response, 'data.data.id', null),
  })).catch(error => {
    dispatch({
      type: EXAMINEE_EXAM_EDIT_FAILED,
      message: get(error, 'response.data.message', null),
      formErrors: get(error, 'response.data.form_errors', {})
    })
  });
};

export const downloadExam = (path) => dispatch => {
    return dispatch(fileActions.download(path, `exam_${moment().format('YYYY-MM-DD')}.pdf`));
};

export const aproveExam = (id) => dispatch => {
    dispatch({
        type: EXAMINEE_EXAM_DELETE_LOAD
    });
    return axios.put('/v1/examinee-exams/approve/'+id)
        .then(() => dispatch({
            type: EXAMINEE_EXAM_DELETE_SUCCESS,
            reload: true
        })).catch(error => dispatch({
            type: EXAMINEE_EXAM_DELETE_FAILED,
            message: get(error, 'response.data.message', null)
        }));
};

export const declineExam = (id) => dispatch => {
    dispatch({
        type: EXAMINEE_EXAM_DELETE_LOAD
    });
    return axios.put('/v1/examinee-exams/decline/'+id)
        .then(() => dispatch({
            type: EXAMINEE_EXAM_DELETE_SUCCESS,
            reload: true
        })).catch(error => dispatch({
            type: EXAMINEE_EXAM_DELETE_FAILED,
            message: get(error, 'response.data.message', null)
        }));
};

export const examDelete = (id) => dispatch => {
  dispatch({
    type: EXAMINEE_EXAM_DELETE_LOAD
  });
  return axios.delete('/v1/examinee-exams/'+id)
      .then(() => dispatch({
        type: EXAMINEE_EXAM_DELETE_SUCCESS,
        id
      })).catch(error => dispatch({
        type: EXAMINEE_EXAM_DELETE_FAILED,
        message: get(error, 'response.data.message', null)
      }));
};

export const setLoading = (loading) => dispatch => {
  dispatch({
    type: EXAMINEE_EXAMS_SET_LOADING,
    loading
  });
};

export const examsList = (page, keyword, sort, filters, perPage) => dispatch => {
  dispatch({
    type: EXAMINEE_EXAMS_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    'per-page': perPage
  };

  params[filters.model] = filters.filters;

  return axios.get(
      '/v1/examinee-exams',
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
  ).then(response => {
    let sort = get(response, 'data.sort', null),
        mode = 'ASC';
    if(sort && sort.indexOf('-') !== -1) {
      mode = 'DESC';
      sort = sort.substr(1);
    }
    return dispatch({
      type: EXAMINEE_EXAMS_LIST_SUCCESS,
      data: get(response, 'data.data', null),
      page: get(response, 'data.page', 0),
      limit: get(response, 'data.per-page', 0),
      total: get(response, 'data.total', 0),
      order: sort,
      orderMode: mode
    });
  })
  .catch(error => dispatch({
    type: EXAMINEE_EXAMS_LIST_FAILED,
    message: get(error, 'response.data.message', null)
  }));
};
