import React from "react";
import PropTypes from "prop-types";
import AccountingIcon from "@material-ui/icons/AccountBalance";
import ProductsIcon from "@material-ui/icons/Shop";
import QueueIcon from "@material-ui/icons/CloudQueue";
import DepartmentsIcon from "@material-ui/icons/BusinessCenter";
import RentIcon from "@material-ui/icons/MonetizationOn";
import YearlyGeneralIcon from "@material-ui/icons/CalendarToday";
import PrintEndReceiptIcon from "@material-ui/icons/Print";
import AeeTestIcon from "@material-ui/icons/BugReport";
import SettingsIcon from "@material-ui/icons/Settings";
import SellIcon from "@material-ui/icons/Money";
import Typography from "@material-ui/core/Typography";
import Select from "Components/New/Input/Select/Select";
import { withRouter } from "react-router-dom";
import localize from "helpers/localize";
import Products from "./Products";
import Queue from "./Queue";
import PrintEndReceipt from "./PrintEndReceipt";
import Aee from "./Aee";
import SetRentData from "./SetRentData";
import DownloadParaData from "./DownloadParaData";
import Departments from "./Departments";
import Accounting from "./Accounting";
import YearlyGeneral from "./YearlyGeneral";
import PageMenu from "Components/New/Menu/PageMenu/PageMenu";
import PageMenuContent from "Components/New/Menu/PageMenu/PageMenuContent";
import restHelper from "helpers/aee/restHelper";
import withLock from "./withLock";
import SignOperation from "./SignOperation";
import {
  CLOUD_CASHREGISTER_DEPARTMENTS,
  CLOUD_CASHREGISTER_PARADATA,
  CLOUD_CASHREGISTER_SETRENTDATA,
  CLOUD_CASHREGISTER_YEARLYGENERAL
} from "constans/permissions";
import authRules from "helpers/authRules";

const LC = "cloud"; //Localization category
const PATH_ACCOUNTING = "/accounting";
const PATH_PRODUCTS = "/products";
const PATH_DEPARTMENTS = "/departments";
const PATH_SETTINGS = "/settings";
const PATH_RENT = "/rent";
const PATH_YEARLY_GENERAL = "/yearly-general";
const PATH_PRINT_END_RECEIPT = "/print-end-receipt";
const PATH_SELL = "/sign-operation";
const PATH_AEE_TEST = "/aee-test";
const PATH_QUEUE = "/queue";

const PrintEndReceiptWithLock = withLock(PrintEndReceipt, "END_RECEIPT", {
  dataTitle: "Bizonylat",
  title: "Bizonylat nyomtatása",
  forceUpdate: false,
  disableRefreshButton: true
});

class Cashregisters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cashregister: null
    };
  }

  getCashregisterId = () => {
    const { match } = this.props;
    //return match.params.id ? `/${match.params.id}` : "";
    return match.params.id ? match.params.id : null;
  };

  onChasregisterChange = cashregister => {
    const { match } = this.props;

    this.setState({ cashregister });
    if (cashregister) {
      let menuSlug = PATH_ACCOUNTING;
      //Van kiválasztott menü
      if (
        match.params &&
        match.params.menu &&
        ["accounting", "products", "settings", "queue"].indexOf(
          match.params.menu
        ) >= 0
      ) {
        menuSlug = "/" + match.params.menu;
      }
      const pathname =
        this.props.history.location.pathname
          .split("/")
          .splice(0, 4)
          .join("/") +
        menuSlug +
        `/${cashregister.apn}`;
      this.props.history.push(pathname);
      //sessionStorage.setItem("cloud_selected_cashregister_id", cashregister.id);
      //this.props.changeCashregisterProps({ id: cashregister.id });
    } else {
      const pathname =
        this.props.history.location.pathname
          .split("/")
          .splice(0, 4)
          .join("/") + PATH_ACCOUNTING;
      this.props.history.push(pathname);
      //sessionStorage.removeItem("cloud_selected_cashregister_id");
      //this.props.changeCashregisterProps({ id: null });
    }
  };

  render() {
    const { client_id } = this.props;
    const cashregister_id = this.getCashregisterId();
    /*const LeftToolBar = () => (
      <div
        style={{
          width: 200,
          display: "flex",
          flexDirection: "column",
          marginTop: -10,
          marginLeft: 16
          //flexBasis: "100%",
          //flex: 1
        }}
      >
        <Select
          key={cashregister_id}
          fullWidth
          type="autocomplete"
          url="identity/cashregisters"
          restHelper={restHelper}
          restHelperOptions={{ client: client_id }}
          onChange={this.onChasregisterChange}
          defaultOptions
          value={cashregister_id}
          valueKey="apn"
          selectIfOne
        />
      </div>
    );*/
    console.log(
      "Cashregister.render",
      this.props.match.params,
      cashregister_id
    );
    return (
      <div>
        {!cashregister_id && (
          <div>
            <Select
              key={cashregister_id}
              label={localize(LC, "Pénztárgép kiválasztása")}
              fullWidth
              type="autocomplete"
              url="identity/cashregisters"
              restHelper={restHelper}
              restHelperOptions={{ client: client_id }}
              onChange={this.onChasregisterChange}
              defaultOptions
              value={cashregister_id}
              valueKey="apn"
              selectIfOne
            />
          </div>
        )}

        {cashregister_id && (
          <PageMenu
            key={cashregister_id}
            LeftToolBar={
              cashregister_id && (
                <div
                  style={{
                    width: 200,
                    display: "flex",
                    flexDirection: "column",
                    //marginTop: -10,
                    alignSelf: "center",
                    marginLeft: 16
                    //flexBasis: "100%",
                    //flex: 1
                  }}
                >
                  <Select
                    key={cashregister_id}
                    fullWidth
                    type="autocomplete"
                    url="identity/cashregisters"
                    restHelper={restHelper}
                    restHelperOptions={{ client: client_id }}
                    onChange={this.onChasregisterChange}
                    defaultOptions
                    value={cashregister_id}
                    valueKey="apn"
                    selectIfOne
                  />
                </div>
              )
            }
            //type="verticalIcon"
            basePath={`/cloud/${client_id}/cashregisters`}
            onChange={this.handleTabChange}
            defaultPath={PATH_ACCOUNTING}
            showContainer={false}
          >
            <PageMenuContent
              path={PATH_ACCOUNTING}
              icon={AccountingIcon}
              title={localize(LC, "Könyvelés")}
            >
              <Accounting
                client_id={client_id}
                cashregister_id={cashregister_id}
              />
            </PageMenuContent>
            <PageMenuContent
              path={PATH_PRODUCTS}
              icon={ProductsIcon}
              variant="subtitle2"
              title={localize(LC, "Termékek")}
            >
              <Products
                client_id={client_id}
                cashregister_id={cashregister_id}
              />
            </PageMenuContent>
            {authRules.canViewByKey(CLOUD_CASHREGISTER_DEPARTMENTS) && (
              <PageMenuContent
                path={PATH_DEPARTMENTS}
                icon={DepartmentsIcon}
                variant="subtitle2"
                title={localize(LC, "Gyűjtők")}
              >
                <Departments
                  client_id={client_id}
                  cashregister_id={cashregister_id}
                />
              </PageMenuContent>
            )}
            {authRules.canViewByKey(CLOUD_CASHREGISTER_PARADATA) && (
              <PageMenuContent
                path={PATH_SETTINGS}
                icon={SettingsIcon}
                title={localize(LC, "Beállítások")}
              >
                <DownloadParaData
                  client_id={client_id}
                  cashregister_id={cashregister_id}
                />
              </PageMenuContent>
            )}
            {authRules.canViewByKey(CLOUD_CASHREGISTER_SETRENTDATA) && (
              <PageMenuContent
                path={PATH_RENT}
                icon={RentIcon}
                title={localize(LC, "Bérlés")}
              >
                <SetRentData
                  client_id={client_id}
                  cashregister_id={cashregister_id}
                />
              </PageMenuContent>
            )}
            {authRules.canViewByKey(CLOUD_CASHREGISTER_YEARLYGENERAL) && (
              <PageMenuContent
                path={PATH_YEARLY_GENERAL}
                icon={YearlyGeneralIcon}
                title={localize(LC, "Éves felülvizsg.")}
              >
                <YearlyGeneral
                  client_id={client_id}
                  cashregister_id={cashregister_id}
                />
              </PageMenuContent>
            )}
            <PageMenuContent
              path={PATH_PRINT_END_RECEIPT}
              icon={PrintEndReceiptIcon}
              title={localize(LC, "Biz. nyomt.")}
            >
              <PrintEndReceiptWithLock
                client_id={client_id}
                cashregister_id={cashregister_id}
              />
            </PageMenuContent>
            <PageMenuContent
              path={PATH_SELL}
              icon={SellIcon}
              title={localize(LC, "Eladás")}
            >
              <SignOperation
                client_id={client_id}
                cashregister_id={cashregister_id}
              />
            </PageMenuContent>
            <PageMenuContent
              path={PATH_AEE_TEST}
              icon={AeeTestIcon}
              title={localize(LC, "Aee")}
            >
              <Aee client_id={client_id} cashregister_id={cashregister_id} />
            </PageMenuContent>
            <PageMenuContent
              path={PATH_QUEUE}
              icon={QueueIcon}
              variant="subtitle2"
              title={localize(LC, "Folyamatok")}
            >
              <Queue client_id={client_id} cashregister_id={cashregister_id} />
            </PageMenuContent>
          </PageMenu>
        )}
      </div>
    );
  }
}

Cashregisters.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

export default withRouter(Cashregisters);
