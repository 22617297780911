import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { Form } from "reactstrap";
import InputField from "Components/Forms/Fields/Input";
import DefaultFormButtons from "Components/Forms/Buttons/DefaultFormButtons";
import Validations from "Components/Forms/Validations";
import Grid from "@material-ui/core/Grid";
import localize from "helpers/localize";

class ChangeEmailForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    this.fillForm();
  };

  fillForm = () => {
    const { dispatch, change, user } = this.props;
    dispatch(change("email", user.email));
    dispatch(change("password", ""));
  };

  render() {
    const {
      handleSubmit,
      handleInputChange,
      fieldErrors,
      pristine,
      submitting,
      reset
    } = this.props;
    return (
      <Form onSubmit={handleSubmit} className="form">
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Field
              name="email"
              label={localize("user", "E-mail")}
              fieldError={fieldErrors.email}
              onChange={() => handleInputChange("email")}
              isRequired={true}
              validate={[Validations.required, Validations.email]}
              component={InputField}
              type="text"
              placeholder={localize("user", "E-mail")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Field
              name="password"
              label={localize("user", "Jelszó")}
              fieldError={fieldErrors.password}
              onChange={() => handleInputChange("password")}
              isRequired={true}
              validate={[Validations.required]}
              component={InputField}
              type="password"
              placeholder={localize("user", "Jelszó")}
            />
          </Grid>
        </Grid>
        <DefaultFormButtons
          pristine={pristine}
          submitting={submitting}
          reset={reset}
          loading={this.props.emailLoading}
          saveId="emailChange"
          disableCancel={true}
        />
      </Form>
    );
  }
}

export default reduxForm({
  form: "emailChange",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(ChangeEmailForm);
