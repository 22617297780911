import axios from "axios";
import qs from "qs";
import { get } from "lodash";

const PRODUCTS_LIST_LOAD = "PRODUCTS_LIST_LOAD";
const PRODUCTS_LIST_SUCCESS = "PRODUCTS_LIST_SUCCESS";
const PRODUCTS_LIST_FAILED = "PRODUCTS_LIST_FAILED";

const PRODUCT_LOAD = "PRODUCT_LOAD";
const PRODUCT_SUCCESS = "PRODUCT_SUCCESS";
const PRODUCT_FAILED = "PRODUCT_FAILED";

const ADDTOCART_LOAD = "ADDTOCART_LOAD";
const ADDTOCART_SUCCESS = "ADDTOCART_SUCCESS";
const ADDTOCART_FAILED = "ADDTOCART_FAILED";

const PRODUCTS_SET_LOADING = "PRODUCTS_SET_LOADING";
const PRODUCTS_LIST_HIGHTLIGHT = "PRODUCTS_LIST_HIGHTLIGHT";

const PRODUCTS_FORM_LOAD = "PRODUCTS_FORM_LOAD";
const PRODUCTS_FORM_SUCCESS = "PRODUCTS_FORM_SUCCESS";
const PRODUCTS_FORM_FAILED = "PRODUCTS_FORM_FAILED";

const PRODUCT_DELETE_LOAD = "PRODUCT_DELETE_LOAD";
const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
const PRODUCT_DELETE_FAILED = "PRODUCT_DELETE_FAILED";

const PRODUCT_EDIT_LOAD = "PRODUCT_EDIT_LOAD";
const PRODUCT_EDIT_SUCCESS = "PRODUCT_EDIT_SUCCESS";
const PRODUCT_EDIT_FAILED = "PRODUCT_EDIT_FAILED";

const PRODUCT_VIEW_LOAD = "PRODUCT_VIEW_LOAD";
const PRODUCT_VIEW_SUCCESS = "PRODUCT_VIEW_SUCCESS";
const PRODUCT_VIEW_FAILED = "PRODUCT_VIEW_FAILED";

const PRODUCTS_RESET = "PRODUCTS_RESET";
const PRODUCTS_RESET_FORM = "PRODUCTS_RESET_FORM";

const initialState = {
  product: {},
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit: process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: "",
  orderMode: "ASC",
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null,
  reload: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_RESET:
      return {
        ...initialState
      };

    case PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        success: true,
        message: false,
        product: action.product
      };
    case ADDTOCART_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        success: true,
        message: false
      };
    case ADDTOCART_FAILED:
    case PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        failed: true,
        message: action.message
      };

    case PRODUCTS_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case PRODUCT_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case PRODUCTS_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false,
        reload: false
      };
    case PRODUCT_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case PRODUCT_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case PRODUCTS_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case PRODUCTS_FORM_FAILED:
    case PRODUCT_EDIT_FAILED:
    case PRODUCT_DELETE_FAILED:
    case PRODUCTS_LIST_FAILED:
    case PRODUCT_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false
      };
    case PRODUCTS_LIST_HIGHTLIGHT:
      return {
        ...state,
        success: true,
        failed: false,
        loading: false,
        reload: true
      };
    case PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode,
        loading: false
      };
    case PRODUCT_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        viewData: action.viewData,
        commentThread: action.commentThread || false,
        viewLoading: false
      };
    case PRODUCTS_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case PRODUCT_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: action.id,
        viewLoading: false
      };
    case PRODUCTS_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: PRODUCTS_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: PRODUCTS_RESET_FORM
  });
};

export const updateProducts = (model, id, data) => dispatch => {
  dispatch({
    type: PRODUCT_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .put("/v1/shop-products/" + id, obj)
    .then(response => {
      dispatch({
        type: PRODUCT_EDIT_SUCCESS
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: PRODUCT_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const viewProduct = id => dispatch => {
  dispatch({
    type: PRODUCT_VIEW_LOAD
  });
  return axios
    .get("/v1/shop-products/" + id)
    .then(response => {
      dispatch({
        type: PRODUCT_VIEW_SUCCESS,
        viewData: get(response, "data.data"),
        commentThread: get(response, "data.commentThread", false)
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: PRODUCT_VIEW_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const createProducts = (model, data) => dispatch => {
  dispatch({
    type: PRODUCT_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .post("/v1/shop-products", obj)
    .then(response => {
      dispatch({
        type: PRODUCT_EDIT_SUCCESS,
        id: get(response, "data.data.id", null)
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: PRODUCT_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const productDelete = id => dispatch => {
  dispatch({
    type: PRODUCT_DELETE_LOAD
  });
  return axios
    .delete("/v1/shop-products/" + id)
    .then(response => {
      dispatch({
        type: PRODUCT_DELETE_SUCCESS,
        id
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: PRODUCT_DELETE_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: PRODUCTS_SET_LOADING,
    loading
  });
};

export const productsList = (
  page,
  keyword,
  sort,
  filters,
  perPage
) => dispatch => {
  dispatch({
    type: PRODUCTS_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    "per-page": perPage
  };

  params[filters.model] = filters.filters;

  return axios
    .get(
      "/v1/shop-products",
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then(response => {
      let sort = get(response, "data.sort", null),
        mode = "ASC";
      if (sort && sort.indexOf("-") !== -1) {
        mode = "DESC";
        sort = sort.substr(1);
      }
      dispatch({
        type: PRODUCTS_LIST_SUCCESS,
        data: get(response, "data.data", null),
        page: get(response, "data.page", 0),
        limit: get(response, "data.per-page", 0),
        total: get(response, "data.total", 0),
        order: sort,
        orderMode: mode
      });
      return response; // todo  use response.data
    })
    .catch(error => {
      dispatch({
        type: PRODUCTS_LIST_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const getProductById = productId => dispatch => {
  const _url = `/v1/shop/product/${productId}`;

  dispatch({
    type: PRODUCT_LOAD
  });
  return axios
    .get(_url)
    .then(response => {
      const cartItems = get(response, "data.data", []);

      dispatch({
        type: PRODUCT_SUCCESS,
        product: cartItems
      });

      return response.data.data;
    })
    .catch(error => {
      dispatch({
        type: PRODUCT_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};
export const addToCart = cartItem => dispatch => {
  dispatch({
    type: ADDTOCART_LOAD
  });
  return axios
    .post("/v1/shop/cart", {
      CartForm: {
        product_id: cartItem.id,
        // quantity: cartItem.quantity ? cartItem.quantity : 1
      }
    })
    .then(response => {
      dispatch({
        type: ADDTOCART_SUCCESS
      });
      return response.data.data;
    })
    .catch(error => {
      dispatch({
        type: ADDTOCART_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};
