import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ClientsList from "./views/clientsList";
import * as clientsActions from "../../../redux/modules/clients";
import * as optionsActions from "../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.clients.loading,
    success: state.clients.success,
    message: state.clients.message,
    data: state.clients.data,
    total: state.clients.total,
    limit: state.clients.limit,
    page: state.clients.page,
    order: state.clients.order,
    orderMode: state.clients.orderMode,
    deleted: state.clients.deleted,
    deletedId: state.clients.deletedId,
    reload: state.clients.reload,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: clientsActions.clientsList,
      delete: clientsActions.clientDelete,
      setLoading: clientsActions.setLoading,
      reset: clientsActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientsList);
