import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationsActions from "redux/modules/notifications";

import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Link } from "react-router-dom";
import { toastr } from "helpers/CustomToastr";
import ReadNotificationIcon from "@material-ui/icons/FiberManualRecord";
import NotificationIcon from "@material-ui/icons/Notifications";
import NotificationActiveIcon from "@material-ui/icons/NotificationsActive";
import MoreIcon from "@material-ui/icons/More";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import notificationstStyle from "styles/jss/notificationstStyle.js";
import soundPlayer from "helpers/soundPlayer";
import notificationMp3 from "assets/audio/notification.mp3";
import ModalConfirm from "Components/Modal/ModalConfirm";
import Modal from "Components/Modal/Modal";
import DialogContent from "@material-ui/core/DialogContent";
import notificationsEnableHelpImg from "assets/img/notifications_enable_help.jpg";
import { getUrl } from "helpers/placeHelper";
import localize from "helpers/localize";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      time: new Date().getTime()
    };
    props.subscribe();
    this.anchorEl = React.createRef();
    this.cardRef = React.createRef();
    this.soundPlayer = soundPlayer(notificationMp3);
    this.modal = React.createRef();
  }

  componentDidMount = () => {
    this.props.getNotificationsCount();
    this.props.getPopoupNotifications();
    document.body.addEventListener("click", this.clickOutside);
  };

  componentWillUnmount() {
    document.body.removeEventListener("click", this.clickOutside);
  }

  clickOutside = event => {
    if (this.cardRef.current && this.cardRef.current.contains(event.target)) {
      return;
    }
    if (this.anchorEl.current && this.anchorEl.current.contains(event.target)) {
      return;
    }
    this.close();
  };

  componentWillReceiveProps = nextProps => {
    /* if (!this.props.message && nextProps.message) {
      toastr.error(nextProps.message);
    }*/

    //TODO Esetleg sessionStorage-be letárolni, hogy csak egyszer legyen hang, ne minden lapfrissítéskor.
    if (this.props.notificationsCount < nextProps.notificationsCount) {
      this.onNewNotification(nextProps.notificationsCount);
    }
  };

  togglePopper = () => {
    if (!this.state.open) {
      this.props.getNotifications();
    }
    this.setState({
      open: !this.state.open
    });
  };

  close = () => {
    this.setState({
      open: false
    });
  };

  /*closeModal = () => {
    this.modal.close();
  };*/

  onNewNotification = notificationCount => {
    this.playSound();
    this.setState({ time: new Date().getTime() });
    if (this.state.open) {
      this.props.getNotifications();
    }
  };

  playSound = () => {
    this.soundPlayer.play();
  };

  onPopupClose = () => {
    //this.setState({ popupNotifications: false });
    this.props.clearPopoupNotifications();
  };

  onPopupReadAll = () => {
    const { popupNotifications, panelReadItem } = this.props;

    popupNotifications.forEach(notification => {
      if (notification.unread) {
        panelReadItem(notification.id);
      }
    });
    this.props.clearPopoupNotifications();
  };

  render() {
    const {
      classes,
      theme,
      notificationsCount,
      notifications,
      loading,
      message,
      popupNotifications,
      popupLoading,
      popupMessage,
      panelReadAll,
      panelReadItem,
      fcmError
    } = this.props;

    const { open, time, popupOpen } = this.state;

    const notificationList = (
      <List className={classes.list}>
        {notifications.map((notification, index) => {
          const url = notification.place ? getUrl(notification.place) : null;
          const hasContent =
            notification.content_extended &&
            notification.content_extended.trim() !== "";
          const color = notification.unread ? "secondary" : "default";
          const disabled =
            !notification.can_setseen || (!hasContent && !notification.unread);
          return (
            <Fragment key={index}>
              <ListItem
                className={classes.listItem}
                ContainerProps={{
                  className: classes.listItemContainer
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    className: classes.listItemTextPrimary
                  }}
                  secondaryTypographyProps={{
                    className: classes.listItemTextSecondary
                  }}
                  primary={
                    url ? (
                      <Link
                        to={url}
                        className={classes.listItemTextPrimaryLink}
                        onClick={this.close}
                      >
                        {notification.content}
                      </Link>
                    ) : (
                      notification.content
                    )
                  }
                  secondary={notification.created_at}
                />
                {(hasContent || notification.unread) && (
                  <ListItemSecondaryAction>
                    <Tooltip
                      disableFocusListener
                      title={localize(
                        "dashboard",
                        notification.unread
                          ? "Olvasottnak jelöl"
                          : "Értesítés megtekintése"
                      )}
                      disabled={loading || disabled}
                    >
                      <div>
                        <IconButton
                          color={color}
                          size="small"
                          className={classes.readIconButton}
                          onClick={() => {
                            if (notification.unread) {
                              panelReadItem(notification.id);
                            }

                            if (hasContent) {
                              this.modal
                                .open(
                                  notification.content,
                                  notification.content_extended,
                                  null,
                                  "Ok",
                                  false,
                                  false
                                )
                                .then(() => {
                                  this.modal.close();
                                });
                            }
                          }}
                          //disabled={loading}
                        >
                          {notification.unread ? (
                            <ReadNotificationIcon />
                          ) : (
                            <MoreIcon />
                          )}
                        </IconButton>
                      </div>
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              {notifications.length > index + 1 && <Divider />}
            </Fragment>
          );
        })}
      </List>
    );

    const colorProps = {};

    if (theme.header.iconColor) {
      colorProps.color = theme.header.iconColor;
    }

    return (
      <Fragment>
        <Badge
          key={`badge_${time}`}
          badgeContent={notificationsCount}
          color="error"
          className={classes.badge}
        >
          <IconButton
            className={classes.toggleIconButton}
            onClick={this.togglePopper}
            buttonRef={this.anchorEl}
          >
            {notificationsCount > 0 ? (
              <NotificationActiveIcon
                key={`icon_${time}`}
                className={classes.bell}
                {...colorProps}
              />
            ) : (
              <NotificationIcon {...colorProps} />
            )}
          </IconButton>
        </Badge>
        <Popper
          open={open}
          anchorEl={this.anchorEl.current}
          transition
          className={classes.popper}
          placement="bottom-end"
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div ref={this.cardRef}>
                <Card>
                  <CardHeader
                    title={localize("dashboard", "Értesítések")}
                    className={classes.cardHeader}
                    //subheader={news.created_at}
                  />
                  {loading && (
                    <CircularProgress size={16} className={classes.progress} />
                  )}
                  <CardContent className={classes.cardContent}>
                    {notificationList}
                    {message && (
                      <Typography variant="body2" color="error">
                        {message}
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      className={classes.btnAllNotification}
                      color="primary"
                      size="small"
                      //disabled={loading}
                    >
                      <NavLink
                        to="/notifications"
                        onClick={this.close}
                        className={classes.navLink}
                      >
                        {localize("dashboard", "Összes értesítés")}
                      </NavLink>
                    </Button>
                    {/*<Button
                      className={classes.btnReadAll}
                      color="primary"
                      size="small"
                      onClick={panelReadAll}
                      //disabled={loading}
                    >
                      {localize("dashboard", "Mind elolvasva")}
                    </Button>*/}
                  </CardActions>
                </Card>
              </div>
            </Fade>
          )}
        </Popper>
        {/*{fcmError && (
          <Modal
            title={localize("dashboard", "Az értesítések le vannak tiltva!")}
            open
            disableCancel
            disableOk
            disableCloseIcon
            onClose={() => {}}
          >
            <Typography variant="body2" color="error">
              1. Az értesítéseket engedélyezni kell a böngészőben a rendszer
              használatához.
            </Typography>
            <img src={notificationsEnableHelpImg} />
            <Typography variant="body2" color="error">
              2. Lap frissítése (F5)
            </Typography>
          </Modal>
        )}*/}
        <Modal
          title={localize("dashboard", "Értesítések")}
          open={popupNotifications.length > 0}
          yesText={localize("dashboard", "Elolvasva")}
          noText={localize("dashboard", "Bezár")}
          //disableCancel
          //disableOk
          onClose={this.onPopupClose}
          onAccept={this.onPopupReadAll}
          onCancel={this.onPopupClose}
          Icon={NotificationActiveIcon}
          maxWidth="lg"
        >
          <DialogContent>
            {popupLoading ? (
              <CircularProgress />
            ) : (
              popupNotifications.map((item, index) => {
                return (
                  <div key={index}>
                    <Typography variant="h5">{item.content}</Typography>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.content_extended
                      }}
                    />
                  </div>
                );
              })
            )}
            {popupMessage && (
              <Typography variant="body1" color="error">
                {popupMessage}
              </Typography>
            )}
          </DialogContent>
        </Modal>
        <ModalConfirm
          onRef={ref => (this.modal = ref)}
          disableIcon
          disableAccept
          htmlContent
          //onAccept={() => {this.setState}}
        />
      </Fragment>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  getNotifications: PropTypes.func.isRequired,
  getNotificationsCount: PropTypes.func.isRequired,
  clearPopoupNotifications: PropTypes.func.isRequired,
  getPopoupNotifications: PropTypes.func.isRequired,
  panelReadItem: PropTypes.func.isRequired,
  panelReadAll: PropTypes.func.isRequired,
  subscribe: PropTypes.func.isRequired,

  history: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  message: PropTypes.string,
  popupNotifications: PropTypes.array.isRequired,
  popupLoading: PropTypes.bool,
  popupMessage: PropTypes.string,
  fcmError: PropTypes.string,
  notificationsCount: PropTypes.number
};

const mapStateToProps = state => {
  return {
    notifications: state.notifications.panel_list,
    notificationsCount: state.notifications.panel_list_count,
    loading: state.notifications.panel_loading,
    message: state.notifications.panel_message,
    popupNotifications: state.notifications.popup_list,
    popupLoading: state.notifications.popup_loading,
    popupMessage: state.notifications.popup_message,
    fcmError: state.fcm.error
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getPopoupNotifications: notificationsActions.notificationsPopupList,
      clearPopoupNotifications:
        notificationsActions.notificationsPopupListclear,
      getNotifications: notificationsActions.notificationsPanelList,
      getNotificationsCount: notificationsActions.notificationsPanelListCount,
      panelReadItem: notificationsActions.panelReadItem,
      panelReadAll: notificationsActions.panelReadAll,
      subscribe: notificationsActions.subscribe
    },
    dispatch
  );
};

export default withStyles(notificationstStyle, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Notifications)
);
