import React from "react";
import PropTypes from "prop-types";
import localize from "helpers/localize";
import restHelper from "helpers/aee/restHelper";
import DataForm from "Components/New/DataForm/DataFormNew";
import withLock from "./withLock";
import DownloadParaDataFrom from "./DownloadParaDataForm";
const LC = "cloud"; //Localization category

class DownloadParaData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: { flag: 4 },
      errors: {},
      loading: false
    };
  }

  componentDidMount() {
    /*const { setToolbarItems } = this.props;
    setToolbarItems(<div>LOL</div>);*/
    this.view();
  }

  view = () => {
    const { cashregister_id: ap, client_id: client } = this.props;
    this.setState({ loading: true });

    restHelper
      .view("cashregister-para-data/view", null, { ap, client })
      .then(response => {
        let data = response.data;

        for (var key in data.sign_operation) {
          data[`sign_operation_${key}`] = data.sign_operation[key];
        }

        this.setState({ item: data, loading: false });
      });
  };

  onSave = data => {
    const { cashregister_id: ap, client_id: client, onUpdated } = this.props;
    this.setState({ loading: true });

    data.sign_operation = {
      day_open: data.sign_operation_day_open,
      fiscal_receipt_issue: data.sign_operation_fiscal_receipt_issue,
      day_close: data.sign_operation_day_close,
      storno_or_return_receipt: data.sign_operation_storno_or_return_receipt,
      ecr_block: data.sign_operation_ecr_block,
      aee_block: data.sign_operation_aee_block,
      service_done: data.sign_operation_service_done,
      sign_server_manually: data.sign_operation_sign_server_manually
    };

    restHelper
      .update("cashregister-para-data/update", null, null, data, {
        ap,
        client
      })
      .then((/*response*/) => {
        onUpdated(() => {
          this.setState({ loading: false });
        });
      });
  };

  onChange = (key, value) => {
    const item = Object.assign({}, this.state.item, { [key]: value });
    this.setState({ item });
  };

  onItemChange = values => {
    const item = Object.assign({}, this.state.item, values);
    this.setState({ item });
  };

  onChangeErrors = errors => {
    this.setState({ errors });
  };

  toggleEdit = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  render() {
    const { editMode } = this.props;
    const { item, errors, loading } = this.state;
    return (
      <DataForm
        editItem={item}
        disabled={!editMode}
        disableHeader
        disableCancelButton
        errors={errors}
        onChangeErrors={this.onChangeErrors}
        onChange={this.onChange}
        onItemChange={this.onItemChange}
        onValidated={this.onSave}
        loading={loading}
        options={{
          form: {
            //model: "Akármi",
            fieldsOrder: [],
            fields: {
              notify_freq: {
                name: localize(
                  LC,
                  "Bejelentkezési gyakoriság (perc)"
                ) /*, validators: ["required"]*/
              },
              sign_operation_day_open: {
                name: localize(LC, "Napnyitás"),
                type: "checkbox"
              },
              sign_operation_fiscal_receipt_issue: {
                name: localize(LC, "Nyugta"),
                type: "checkbox"
              },
              sign_operation_storno_or_return_receipt: {
                name: localize(LC, "Sztornó vagy visszáru"),
                type: "checkbox"
              },
              sign_operation_day_close: {
                name: localize(LC, "Napi zárás"),
                type: "checkbox"
              },
              sign_operation_service_done: {
                name: localize(LC, "Éves felülvizsgálat"),
                type: "checkbox"
              },
              sign_operation_aee_block: {
                name: localize(LC, "AEE blokkolás"),
                type: "checkbox"
              },
              sign_operation_ecr_block: {
                name: localize(LC, "Pénztárgép blokkolás"),
                type: "checkbox"
              },
              sign_operation_sign_server_manually: {
                name: localize(LC, "Értékesítési parancs"),
                type: "checkbox"
              },
              advertise: {
                name: localize(LC, "Reklám"),
                /*, validators: ["required"]*/
                maxLength: 48
              },
              footer: {
                name: localize(LC, "Lábléc"),
                type: "simple-2d-array",
                maxLength: 5,
                //rowsMax: 5 /*, validators: ["required"]*/,
                //rows: 5,
                //multiline: true
                showLineNumbers: true,
                fullWidth: true,
                useKeyEvents: true,
                columns: [
                  {
                    id: "line",
                    label: localize(LC, "Szöveg"),
                    maxlength: 100,
                    insertNewRowOnenter: true,
                    arrowNavigationDown: true,
                    arrowNavigationUp: true,
                    clearRowOnBackSpace: true,
                    showCharLeft: true,
                    /*style: item => {
                      switch (item.format) {
                        case "1":
                          return { textAlign: "center" };
                        case "2":
                          return { fontWeight: "bold" };
                        case "3":
                          return { fontWeight: "bold", textAlign: "center" };
                        default:
                          return undefined;
                      }
                    }*/
                    style: item => ({
                      textAlign: item.center
                        ? "center"
                        : item.right
                        ? "right"
                        : "left",
                      fontWeight: item.bold ? "bold" : "normal",
                      fontSize: item.dbl_height ? "28px" : "14px"
                    })
                    //width: 350
                  },
                  {
                    id: "char_left",
                    render: (row, disabled) => (
                      <div
                        key={disabled}
                        style={{
                          textAlign: "center",
                          color:
                            disabled === true
                              ? "rgba(0, 0, 0, 0.38)"
                              : "rgba(0, 0, 0, 0.87)"
                        }}
                      >
                        {100 - (row.line ? row.line.length : 0)}
                      </div>
                    ),
                    label: localize(LC, "Fentmaradó"),
                    type: "calc",
                    width: 40
                  },
                  {
                    id: "bold",
                    label: localize(LC, "Félkövér"),
                    type: "checkbox",
                    width: 40
                  },
                  {
                    id: "dbl_height",
                    label: localize(LC, "Dupla"),
                    type: "checkbox",
                    width: 40
                  },
                  {
                    id: "center",
                    label: localize(LC, "Középre"),
                    type: "checkbox",
                    changeValues: row => {
                      if (row.right) {
                        row.right = false;
                      }
                    },
                    width: 40
                  },
                  {
                    id: "right",
                    label: localize(LC, "Jobbra"),
                    type: "checkbox",
                    changeValues: row => {
                      if (row.center) {
                        row.center = false;
                      }
                    },
                    width: 40
                  }
                ]
              },
              exchange_mode: {
                name: localize(LC, "Pénzváltás"),
                type: "checkbox"
              },
              ft_euro_rate: {
                name: localize(
                  LC,
                  "Ft/€ árfolyam"
                ) /*, validators: ["required"]*/
              },
              ex_collector: {
                name: localize(LC, "Gyűjtő"),
                mask: "dep_collector",
                validators: item =>
                  item.exchange_mode ? ["required"] : undefined
              },
              ex_converFlag: {
                name: localize(LC, "Konverziós"),
                type: "checkbox"
              },
              ex_bank_name: {
                name: localize(LC, "Bank neve"),
                validators: item =>
                  item.exchange_mode ? ["required"] : undefined
              },
              ex_bank_check: {
                name: localize(LC, "Csekk sorszáma")
              },
              ex_fee_percent: {
                name: localize(LC, "Jutalék")
              },
              currency_clear: {
                name: localize(LC, "Valuták törlése"),
                type: "checkbox"
              },
              currency_para: {
                name: localize(LC, "Valuták"),
                type: "simple-2d-array",
                showLineNumbers: true,
                disabled: item => (item.currency_clear ? true : false),
                maxLength: 40,
                //TODO: FORDÍTÁS
                columns: [
                  { id: "sign", label: "Sign" },
                  { id: "rate_buy", label: "Rate Buy", type: "number" },
                  { id: "rate_sell", label: "Rate Sell", type: "number" }
                ]
              }
            }
          },
          //fieldsOrder: ["name"],
          //component: DownloadParaDataFrom,
          //autoRenderFields: true,
          //disableHeader: true,
          //disableButtons: true,
          //disableCanelButton: true,
          disabled: !editMode
        }}
        autoRenderFields={true}
        history={this.props.history}
        component={DownloadParaDataFrom}
      />
    );
  }
}

DownloadParaData.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  editMode: PropTypes.bool.isRequired,
  setToolbarItems: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
  cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

export default withLock(DownloadParaData, "DOWNLOAD_PARA_DATA", {
  dataTitle: "Beállítások",
  title: "Beállítások",
  forceUpdate: false
});
