/* eslint-disable */
import React, {PureComponent} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
    EXAMINEE_EXAMS_PERMISSION, EXAMS_PERMISSION
} from "../../../constans/permissions";
import MenuPermission from "../../Permissions/MenuPermission";
import withRouter from "react-router/es/withRouter";
import localize from "helpers/localize";


class Navbar extends PureComponent {

    constructor(props) {
        super(props);
        let value = 0;
        switch (window.location.pathname.split('/')[1]) {
            case 'exams': value = 1; break;
            default: value = 0;
        }
        this.state = {
            value
        };
    }

    handleChange = (value) => {
        this.props.history.push(`/${value}`);
    };

    render() {
        return <AppBar className="mb-3" position="static" key={this.state.value}>
            <Tabs
                variant="scrollable"
                scrollButtons="on"
                value={this.state.value}
            >
                <MenuPermission module={EXAMINEE_EXAMS_PERMISSION}>
                    <Tab label={localize("menu, 'Jelentkezések')} onChange={this.handleChange.bind(this", "examinee-exams")} />
                </MenuPermission>
                <MenuPermission module={EXAMS_PERMISSION}>
                    <Tab label={localize("menu, 'Vizsgák')} onChange={this.handleChange.bind(this", "exams")} />
                </MenuPermission>
            </Tabs>
        </AppBar>
    }
}


export default withRouter(Navbar);