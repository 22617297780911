import axios from 'axios';
import qs from 'qs';
import { get } from 'lodash';

const SMS_LIST_ITEMS_LIST_LOAD = 'SMS_LIST_ITEMS_LIST_LOAD';
const SMS_LIST_ITEMS_LIST_SUCCESS = 'SMS_LIST_ITEMS_LIST_SUCCESS';
const SMS_LIST_ITEMS_LIST_FAILED = 'SMS_LIST_ITEMS_LIST_FAILED';
const SMS_LIST_ITEMS_SET_LOADING = 'SMS_LIST_ITEMS_SET_LOADING';

const SMS_LIST_ITEMS_FORM_LOAD = 'SMS_LIST_ITEMS_FORM_LOAD';
const SMS_LIST_ITEMS_FORM_SUCCESS = 'SMS_LIST_ITEMS_FORM_SUCCESS';
const SMS_LIST_ITEMS_FORM_FAILED = 'SMS_LIST_ITEMS_FORM_FAILED';

const SMS_LIST_ITEMS_DELETE_LOAD = 'SMS_LIST_ITEMS_DELETE_LOAD';
const SMS_LIST_ITEMS_DELETE_SUCCESS = 'SMS_LIST_ITEMS_DELETE_SUCCESS';
const SMS_LIST_ITEMS_DELETE_FAILED = 'SMS_LIST_ITEMS_DELETE_FAILED';

const SMS_LIST_ITEMS_EDIT_LOAD = 'SMS_LIST_ITEMS_EDIT_LOAD';
const SMS_LIST_ITEMS_EDIT_SUCCESS = 'SMS_LIST_ITEMS_EDIT_SUCCESS';
const SMS_LIST_ITEMS_EDIT_FAILED = 'SMS_LIST_ITEMS_EDIT_FAILED';

const SMS_LIST_ITEMS_VIEW_LOAD = 'SMS_LIST_ITEMS_VIEW_LOAD';
const SMS_LIST_ITEMS_VIEW_SUCCESS = 'SMS_LIST_ITEMS_VIEW_SUCCESS';
const SMS_LIST_ITEMS_VIEW_FAILED = 'SMS_LIST_ITEMS_VIEW_FAILED';

const SMS_LIST_ITEMS_RESET = 'SMS_LIST_ITEMS_RESET';

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit:process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: '',
  orderMode: 'ASC',
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SMS_LIST_ITEMS_RESET:
      return {
        ...initialState
      };
    case SMS_LIST_ITEMS_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case SMS_LIST_ITEMS_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false
      };
    case SMS_LIST_ITEMS_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case SMS_LIST_ITEMS_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case SMS_LIST_ITEMS_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case SMS_LIST_ITEMS_FORM_FAILED:
    case SMS_LIST_ITEMS_EDIT_FAILED:
    case SMS_LIST_ITEMS_DELETE_FAILED:
    case SMS_LIST_ITEMS_LIST_FAILED:
    case SMS_LIST_ITEMS_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false
      };
    case SMS_LIST_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode
      };
      case SMS_LIST_ITEMS_VIEW_SUCCESS:
        return {
            ...state,
            loading: false,
            success: true,
            failed: false,
            viewData: action.viewData,
            viewLoading: false
        };
    case SMS_LIST_ITEMS_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case SMS_LIST_ITEMS_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case SMS_LIST_ITEMS_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: action.id,
        viewLoading: false
      };
    case SMS_LIST_ITEMS_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
}

export const reset = () => dispatch => {
  dispatch({
    type: SMS_LIST_ITEMS_RESET
  });
};

export const updateSmsListItems = (model,id,data) => dispatch => {
  dispatch({
    type: SMS_LIST_ITEMS_EDIT_LOAD
  });

    let obj = {};
    obj[model] = data;

  return axios.put('/v1/sms-list-items/'+id, obj).then(
      () => dispatch({
        type: SMS_LIST_ITEMS_EDIT_SUCCESS
      })
  ).catch(error => {
    dispatch({
      type: SMS_LIST_ITEMS_EDIT_FAILED,
      message: get(error, 'response.data.message', null),
      formErrors: get(error, 'response.data.form_errors', {})
    })
  });
};

export const viewSmsListItems = (id) => dispatch => {
    dispatch({
        type: SMS_LIST_ITEMS_VIEW_LOAD
    });
    return axios.get('/v1/sms-list-items/'+id).then(
        response => dispatch({
            type: SMS_LIST_ITEMS_VIEW_SUCCESS,
            viewData: get(response, 'data.data'),
            commentThread: get(response, 'data.commentThread', false)
        })
    ).catch(error => {
        dispatch({
            type: SMS_LIST_ITEMS_VIEW_FAILED,
            message: get(error, 'response.data.message', null)
        })
    });
};

export const createSmsListItems = (model, data) => dispatch => {
  dispatch({
    type: SMS_LIST_ITEMS_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios.post('/v1/sms-list-items', obj).then(response => dispatch({
    type: SMS_LIST_ITEMS_EDIT_SUCCESS,
    id: get(response, 'data.data.id', null),
  })).catch(error => {
    dispatch({
      type: SMS_LIST_ITEMS_EDIT_FAILED,
      message: get(error, 'response.data.message', null),
      formErrors: get(error, 'response.data.form_errors', {})
    })
  });
};

export const SmsListItemDelete = (id) => dispatch => {
  dispatch({
    type: SMS_LIST_ITEMS_DELETE_LOAD
  });
  return axios.delete('/v1/sms-list-items/'+id)
      .then((response) => dispatch({
        type: SMS_LIST_ITEMS_DELETE_SUCCESS,
        id
      })).catch(error => dispatch({
        type: SMS_LIST_ITEMS_DELETE_FAILED,
        message: get(error, 'response.data.message', null)
      }));
};

export const setLoading = (loading) => dispatch => {
  dispatch({
    type: SMS_LIST_ITEMS_SET_LOADING,
    loading
  });
};

export const SmsListItemList = (page, keyword, sort, filters, perPage) => dispatch => {
  dispatch({
    type: SMS_LIST_ITEMS_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    'per-page': perPage
  };

  params[filters.model] = filters.filters;

  return axios.get(
      '/v1/sms-list-items',
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
  ).then(response => {
    let sort = get(response, 'data.sort', null),
        mode = 'ASC';
    if(sort && sort.indexOf('-') !== -1) {
      mode = 'DESC';
      sort = sort.substr(1);
    }
    return dispatch({
      type: SMS_LIST_ITEMS_LIST_SUCCESS,
      data: get(response, 'data.data', null),
      page: get(response, 'data.page', 0),
      limit: get(response, 'data.per-page', 0),
      total: get(response, 'data.total', 0),
      order: sort,
      orderMode: mode
    });
  })
  .catch(error => dispatch({
    type: SMS_LIST_ITEMS_LIST_FAILED,
    message: get(error, 'response.data.message', null)
  }));
};
