import PropTypes from "prop-types";

const PageMenuContent = props => props.children;

PageMenuContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.element
  ])
};

export default PageMenuContent;
