import React, { PureComponent} from 'react';

import List from '../../../../../Components/List/List';

class RightsList extends PureComponent {

    getListHeader = () => {
        this.props.getListHeader(`/cashregisters/${this.props.initialValues.id}/cashregister-rights`);
    };

    getList = (page, keyword, sort, filters, perPage) => {
        return this.props.getList(this.props.initialValues.id, page, keyword, sort, filters, perPage)
    };

    delete = (id) => {
        return this.props.deleteRigth(
            this.props.initialValues.id,
            id
        );
    };

  render() {
    return (
        <div className="animated slideInUpTiny animation-duration-3">
          <List
              {...this.props}
              getListHeader={this.getListHeader.bind(this)}
              getList={this.getList.bind(this)}
              disableViewIcon={true}
              delete={this.delete.bind(this)}
              disableGridSetting={true}
              id="cashregisters-rights"
          />
        </div>
    );
  }
}

export default RightsList;