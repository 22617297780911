import Moment from "moment";
import "moment/locale/hu";
Moment.locale("hu");
const dateHelper = {
  currenyFormat: x => {
    if (!x) return "0 Ft";
    return (
      Math.round(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ft"
    );
  },
  formatDateRange: function(range) {
    if (range.start.year() === Moment().year()) {
      if (
        range.start.month() === range.end.month() &&
        range.start.date() === range.end.date()
      ) {
        return (
          range.start.format("MMMM DD.") +
          " " +
          range.start.format("HH:mm") +
          " - " +
          range.end.format("HH:mm")
        );
      }
      if (range.start.month() === range.end.month()) {
        return (
          range.start.format("MMMM DD.") +
          " " +
          range.start.format("HH:mm") +
          " - " +
          range.end.format("DD. HH:mm")
        );
      }
      return (
        range.start.format("MMMM DD. HH:mm") +
        " - " +
        range.end.format("MMMM DD. HH:mm")
      );
    } else {
      return (
        range.start.format("YYYY MMMM DD. HH:mm") +
        " - " +
        range.end.format("YYYY MMMM DD. HH:mm")
      );
    }
  },

  unixToDateTime(unix) {
    return unix
      ? Moment.unix(parseInt(unix, 10)).format("YYYY.MM.DD HH:mm:ss")
      : "";
  },

  unixToDateTimeMid(unix) {
    return Moment.unix(parseInt(unix, 10)).format("YYYY.MM.DD HH:mm");
  },

  stringToRelative(str_date) {
    return str_date ? Moment(str_date).fromNow() : "";
  },

  unixToRelative(unix) {
    return unix ? Moment.unix(parseInt(unix, 10)).fromNow() : "";
  },

  unixToDate(unix) {
    return unix ? Moment.unix(parseInt(unix, 10)).format("YYYY.MM.DD") : "";
  },

  unixToMoment(unix) {
    return unix ? Moment.unix(parseInt(unix, 10)) : null;
  },

  dateToUnix(value) {
    let unix = value;
    if (value) {
      const date = new Date(value);
      unix = date.getTime() / 1000;
    }
    return unix === "" ? null : unix;
  },

  dateToMoment(value) {
    let d = null;
    if (value) {
      d = Moment(value);
    }
    return d;
  },

  dateAddUnit(value, shift, unit) {
    let d = null;
    if (value) {
      d = Moment(value);
      d.add(shift, unit);
      return d.format("YYYY.MM.DD");
    }
    return d;
  },

  dateAddDay(value, shift) {
    return dateHelper.dateAddUnit(value, shift, "days");
  },

  unixToTime(unix) {
    return unix || unix === 0
      ? Moment.unix(parseInt(unix, 10))
          .utc(false)
        .format("HH:mm")
      : "";
  },

  unixToTimeMinaSeconds(unix) {
    return unix || unix === 0
      ? Moment.unix(parseInt(unix, 10))
          .utc(false)
        .format("mm:ss")
      : "";
  },

  timeToUnix(value) {
    let unix = value;
    if (value) {
      let hour = value.replace(":", "").substring(0, 2);
      let minute = value.replace(":", "").substring(2, 4);
      if (minute.length === 0) {
        minute = "00";
      }
      if (minute.length === 1) {
        minute += "0";
      }

      const date = new Moment("1970-01-01 " + hour + ":" + minute);
      unix = date.utc(true).unix();
    }
    return unix === "" ? null : unix;
  },

  toDate(val) {
    return val ? Moment(val).format("YYYY.MM.DD") : "";
  },

  unixToDateTimeShort(unix) {
    return unix ? Moment.unix(parseInt(unix, 10)).format("MM.DD HH:mm") : "";
  },

  unixRangeToInterval(from, to, type) {
    return (
      Math.round(
        Moment.unix(parseInt(to, 10)).diff(
          Moment.unix(parseInt(from, 10)),
          type,
          true
        ) * 100
      ) / 100
    );
  },
  unixRangeToTimestamp(from, to) {
    // parse time using 24-hour clock and use UTC to prevent DST issues
    var start = Moment.utc(Moment.unix(parseInt(from, 10)), "HH:mm");
    var end = Moment.utc(Moment.unix(parseInt(to, 10)), "HH:mm");

    // account for crossing over to midnight the next day
    if (end.isBefore(start)) end.add(1, "day");

    // calculate the duration
    var d = Moment.duration(end.diff(start));

    // subtract the lunch break
    //d.subtract(30, 'minutes')

    // format a string result
    return Moment.utc(+d).format("HH:mm:ss");
  },

  unixToRange(range) {
    if (range) {
      return Moment.range(
        Moment.unix(parseInt(range.start, 10)),
        Moment.unix(parseInt(range.end, 10))
      );
    } else {
      return null;
    }
  },
  datesToRange(range) {
    if (range) {
      return Moment.range(Moment(range.start), Moment(range.end));
    } else {
      return null;
    }
  },

  rangeToUnix(range) {
    if (range) {
      return {
        start: Moment(range.start).unix(),
        end: Moment(range.end).unix()
      };
    } else {
      return null;
    }
  },

  rangeToDates(range) {
    if (range) {
      return {
        start: Moment(range.start).format("YYYY-MM-DD"),
        end: Moment(range.end).format("YYYY-MM-DD")
      };
    } else {
      return null;
    }
  },

  getRange(range) {
    const today = new Moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    const startOfWeek = Moment().startOf("isoWeek");
    const startOfMonth = Moment().startOf("Month");
    const endOfWeek = Moment().endOf("isoWeek");
    const endOfMonth = Moment().endOf("Month");
    //startOfWeek.add(7, "days")
    switch (range) {
      case "today":
        return Moment.range(today, today);
      case "thisWeek":
        return Moment.range(startOfWeek, endOfWeek);
      case "thisMonth":
        return Moment.range(startOfMonth, endOfMonth);
      case "lastWeek":
        return Moment.range(
          startOfWeek.clone().subtract(7, "days"),
          endOfWeek.clone().subtract(7, "days")
        );
      case "lastMonth":
        return Moment.range(
          startOfMonth.clone().subtract(1, "months"),
          startOfMonth.clone().subtract(1, "days")
        );
      case "last7Days":
        return Moment.range(today.clone().subtract(6, "days"), today);
      case "last30Days":
        return Moment.range(today.clone().subtract(29, "days"), today);
      default:
        return Moment.range(today, today);
    }
  }
};

export default dateHelper;
