import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CartWrapper from "./CartWrapper";
import {
  Grid,
  Button,
  Paper,
  Typography,
  SnackbarContent
} from "@material-ui/core";
import formatHelper from "helpers/formatHelper";
import ContinueIcon from "@material-ui/icons/KeyboardArrowRight";
import { Link } from "react-router-dom";
import { BASE_PATH_CATALOG } from "./../shopPath";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import localize from "helpers/localize";
const styles = theme => ({
  success: {
    backgroundColor: green[600],
    minWidth: 100,
    display: "inline-block"
  },
  centerContainer: {
    textAlign: "center"
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

class Success extends React.Component {
  render() {
    const { theme, classes, items, total, totalPrice, order } = this.props;
    const colorProps = {};

    if (theme.header.iconColor) {
      colorProps.color = theme.header.iconColor;
    }

    return (
      <Fragment>
        <Grid container spacing={16} justify="center">
          <Grid item xs={12} md={10}>
            <Grid container spacing={16}>
              <Grid item xs={12} className={classes.centerContainer}>
                <SnackbarContent
                  className={classes.success}
                  message={
                    <span id="client-snackbar" className={classes.message}>
                      <CheckCircleIcon className={classes.icon} />
                      {localize(
                        "shop",
                        "Megrendelését sikeresen rögzítettük. Köszönjük."
                      )}
                    </span>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" align="center">
                  {localize("shop", "Megrendelés azonosító")}:{" "}
                  {order.item.number || `#${order.item.id}`}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.centerContainer}>
                <Button
                  color="default"
                  variant="contained"
                  to={BASE_PATH_CATALOG}
                  component={Link}
                >
                  {localize("shop", "Vissza a katalógusra")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

Success.defaultProps = {};

Success.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  items: PropTypes.array,
  total: PropTypes.number
};

export default withStyles(styles, { withTheme: true })(Success);
