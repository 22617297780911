import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Toolbar,
  Typography,
  CircularProgress
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/ChevronLeft";
import { Link } from "react-router-dom";
import OrderView from "./OrderView";
import { BASE_PATH_CART_SUCCESS, BASE_PATH_CART_CHECKOUT } from "./../shopPath";
import localize from "helpers/localize";
const styles = theme => ({});
class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  acceptOrder = () => {
    const { history, changeStep, createOrder, order, clear } = this.props;
    //console.log("acceptOrder");
    this.setState({ loading: true });

    createOrder(order.item)
      .then(response => {
        this.setState({ loading: false });
        //console.log("acceptOrder.response", response);
        clear();
        changeStep(3);

        history.push(BASE_PATH_CART_SUCCESS);
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      theme,
      classes,
      items,
      total,
      totalPrice,
      order,
      changeStep
    } = this.props;
    const colorProps = {};

    const { loading } = this.state;

    if (theme.header.iconColor) {
      colorProps.color = theme.header.iconColor;
    }
    return (
      <Fragment>
        <Grid container spacing={16} justify="center">
          <Grid item xs={12} md={10}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  {localize("shop", "Megrendelés")}
                </Typography>
              </Grid>
              <OrderView {...this.props} />
              <Grid item xs={12}>
                <Toolbar disableGutters>
                  <Button
                    color="default"
                    variant="contained"
                    size="large"
                    onClick={() => changeStep(1)}
                    to={BASE_PATH_CART_CHECKOUT}
                    component={Link}
                  >
                    <BackIcon style={{ marginLeft: -8 }} />
                    {localize("basic", "Vissza")}
                  </Button>
                  <Button
                    disabled={loading}
                    color="secondary"
                    variant="contained"
                    size="large"
                    onClick={this.acceptOrder}
                    style={{ marginLeft: "auto" }}
                  >
                    {localize("shop", "Megrendelem")}
                    {loading && (
                      <CircularProgress
                        size={16}
                        color="primary"
                        style={{ marginLeft: 16 }}
                      />
                    )}
                  </Button>
                </Toolbar>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

Order.defaultProps = {};

Order.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  items: PropTypes.array,
  total: PropTypes.number
};

export default withStyles(styles, { withTheme: true })(Order);
