import React, { PureComponent } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  USERS_INACTIVED_PERMISSION,
  USERS_NEW_PERMISSION,
  USERS_PERMISSION
} from "../../../../constans/permissions";
import MenuPermission from "../../../Permissions/MenuPermission";
import withRouter from "react-router/es/withRouter";
import localize from "helpers/localize";

class Navbar extends PureComponent {
  constructor(props) {
    super(props);
    let value = 0;
    switch (window.location.pathname.split("/")[1]) {
      case "inactivated-users":
        value = 2;
        break;
      case "new-users":
        value = 1;
        break;
      default:
        value = 0;
    }
    this.state = {
      value
    };
  }

  handleChange = value => {
    this.props.history.push(`/${value}`);
  };

  render() {
    return (
      <AppBar className="mb-3" position="static" key={this.state.value}>
        <Tabs value={this.state.value}>
          <MenuPermission module={USERS_PERMISSION}>
            <Tab
              label={localize("menu", "Felhasználók")}
              onChange={this.handleChange.bind(this, "users")}
            />
          </MenuPermission>
          <MenuPermission module={USERS_NEW_PERMISSION}>
            <Tab
              label={localize("menu", "Új felhasználók")}
              onChange={this.handleChange.bind(this, "new-users")}
            />
          </MenuPermission>
          <MenuPermission module={USERS_INACTIVED_PERMISSION}>
            <Tab
              label={localize("menu", "Inaktív felhasználók")}
              onChange={this.handleChange.bind(this, "inactivated-users")}
            />
          </MenuPermission>
        </Tabs>
      </AppBar>
    );
  }
}
export default withRouter(Navbar);
