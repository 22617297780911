import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "Components/New/Input/TextField/TextField";
import localize from "helpers/localize";
import { Grid, IconButton, Paper, Toolbar } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";

const LC = "emailtemplates"; //Localization category

const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

class SendParamsEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      parameters,
      email,
      setParam,
      setTo,
      removeEmail,
      count,
      errors
    } = this.props;
    return (
      <Paper
        style={{
          marginTop: 8,
          marginBottom: 8,
          padding: 8,
          paddingRight: count > 1 ? 0 : 8
        }}
      >
        <Toolbar
          disableGutters
          //style={{ borderTop: "solid 1px black", marginTop: 8, paddingTop: 8 }}
        >
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id={"to"}
                label={localize(LC, "Email")}
                fullWidth
                value={email.to}
                onChange={evt => setTo(evt.target.value)}
                error={errors.__to}
              />
            </Grid>
            {parameters &&
              parameters.map((parameter, index) => {
                const value =
                  (email.parameters && email.parameters[parameter]) || "";
                return (
                  <Grid
                    item
                    key={`parameter.${index}`}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                  >
                    <TextField
                      id={parameter}
                      label={capitalize(parameter)}
                      fullWidth
                      value={value}
                      onChange={evt => setParam(parameter, evt.target.value)}
                      error={errors[parameter]}
                    />
                  </Grid>
                );
              })}
          </Grid>
          {count > 1 && (
            <IconButton onClick={removeEmail} style={{ marginTop: 8 }}>
              <RemoveIcon />
            </IconButton>
          )}
        </Toolbar>
      </Paper>
    );
  }
}

SendParamsEditor.defaultProps = {
  errors: {}
};

SendParamsEditor.propTypes = {
  parameters: PropTypes.array,
  email: PropTypes.object,
  errors: PropTypes.object,
  setParam: PropTypes.func.isRequired,
  setTo: PropTypes.func.isRequired,
  removeEmail: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired
};

export default SendParamsEditor;
