import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastr } from "../helpers/CustomToastr";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import UploadIcon from "@material-ui/icons/Search";
import AttachIcon from "@material-ui/icons/AttachFile";
import RemoveIcon from "@material-ui/icons/Clear";
import * as fileActions from "../redux/modules/file";
import DeleteIcon from "@material-ui/icons/Delete";
import ModalConfirm from "./Modal/ModalConfirm";
import localize from "helpers/localize";

class UploadButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: props.file,
      confirm: false,
      success: false,
      error: null,
      loading: false
    };
    this.modal = React.createRef();
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      (!this.props.file && nextProps.file) ||
      this.props.file !== nextProps.file
    ) {
      this.setState({
        file: nextProps.file
      });
    }
  };

  showErrorMessage = message => {
    toastr.error(message);
  };

  validateExt = sFileName => {
    let acceptedFiles = this.props.acceptedFiles
      ? this.props.acceptedFiles.split(/\||,/)
      : null;
    //Nincs korlátozás
    if (!acceptedFiles) {
      return true;
    }

    if (sFileName.length > 0) {
      var blnValid = false;
      for (var j = 0; j < acceptedFiles.length; j++) {
        var sCurExtension = acceptedFiles[j]
          .replace("application/", "")
          .replace("image/", "");
        /*console.log(
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase(),
          "==",
          sCurExtension.toLowerCase()
        );*/
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        const error =
          sFileName +
          " formátuma nem megfelelő, elfogadott formátumok: " +
          acceptedFiles
            .join(", ")
            .replace(new RegExp("application/", "g"), "")
            .replace(new RegExp("[.]", "g"), "")
            .replace(new RegExp("image/", "g"), "");
        this.setState({ error: error });
        this.props.onError && this.props.onError(error);
        return false;
      }
    }

    return true;
  };

  handleUpload = event => {
    const { texts, confirmUpload } = this.props;

    if (confirmUpload) {
      event.persist();
      this.modal
        .open(
          texts.confirmUploadTitle,
          texts.confirmUploadMsg,
          null, //this.state.file.file_name,
          "Ok",
          "Mégsem"
        )
        .then(() => {
          this.upload(event);
        });
    } else {
      this.upload(event);
    }
  };

  upload = event => {
    let file = event.target.files[0];
    if (!file) {
      return;
    }
    //console.log(file);
    if (!this.validateExt(file.name)) {
      return false;
    }
    this.setState({ loading: true });
    this.props.upload(file).then(result => {
      if (result.type === "FILE_UPLOAD_SUCCESS") {
        file.id = result.id;
        //console.log("upload", result);
        if (this.props.onUpload) {
          let success = this.props.onUpload(result.data, file);
          if (this.props.onlyUpload) {
            this.setState({
              loading: false
            });
          } else {
            this.setState({
              file: success !== false ? file : null,
              loading: false
            });
          }
        } else {
          if (this.props.onlyUpload) {
            this.setState({
              loading: false
            });
          } else {
            this.setState({
              file: file,
              loading: false
            });
          }
        }

        //toastr.success(localize("basic", "Sikeres feltöltés"));
      } else {
        this.setState({ loading: false });
        this.showErrorMessage(
          result.message || localize("basic", "Sikertelen művelet")
        );
      }
    });
  };

  handleRemove = () => {
    const { ignoreRemove, removeOnlyOnClient } = this.props;
    const remove = this.props.remove
      ? this.props.remove
      : this.props.fileDelete;
    //console.log("handleRemove", this.state.file_name);
    //Upload esetén nincs kérdés a törléshez
    if (this.props.type === "upload" && !this.props.showDeleteConfirm) {
      //Eltávolítás csak kliens oldalon
      if (removeOnlyOnClient) {
        if (this.props.onRemove) {
          this.props.onRemove(null, this.state.file);
        }
        this.setState({ file: null, loading: false });
        return;
      }
      this.setState({ loading: true });
      remove(this.state.file.id).then(res => {
        if (this.props.onRemove) {
          this.props.onRemove(res.data, this.state.file);
        }
        this.setState({ file: null, loading: false });
      });
      return;
    }

    this.modal
      .open(
        "Törlés megerősítése",
        this.props.deleteConfirmText || "Biztosan eltávolítja a fájlt?",
        this.state.file.file_name,
        "Ok",
        "Mégsem"
      )
      .then(() => {
        if (removeOnlyOnClient) {
          if (this.props.onRemove) {
            this.props.onRemove(null, this.state.file);
          }
          this.setState({ file: null, loading: false });
          this.modal.close();
          return;
        }
        remove(this.state.file.id).then(res => {
          if (this.props.onRemove) {
            this.props.onRemove(res.data, this.state.file);
          }
          this.setState({ file: null, loading: false });
          this.modal.close();
        });
      });
  };

  render() {
    const {
      /*label,*/ name,
      disableDelete,
      disableUpload /*, texts*/,
      type,
      canReplace,
      isNeeded,
      buttonProps,
      disabled,
      size,
      IconUpload,
      uploadTitle
    } = this.props;
    const { file, loading } = this.state;
    const id = name + "_file";

    if (loading) {
      return <CircularProgress size={24} />;
    }

    const deleteBtn = (
      <Tooltip
        key={id + "tooltip_1"}
        id={id + "tooltip_1"}
        disableFocusListener
        title={
          file && file.file_name
            ? file.file_name + " eltávolítása."
            : "Fájl eltávolítása."
        }
      >
        <IconButton
          key={file ? file.id : "new"}
          {...buttonProps}
          onClick={this.handleRemove}
          aria-label="Delete"
          color="primary"
          disabled={disabled}
        >
          {type === "attach" ? <DeleteIcon /> : <RemoveIcon />}
        </IconButton>
      </Tooltip>
    );

    const UIcon = IconUpload
      ? IconUpload
      : type === "attach"
        ? AttachIcon
        : UploadIcon;

    const uploadBtn = (
      <Tooltip
        key={id + "tooltip_2"}
        id={id + "tooltip_2"}
        disableFocusListener
        title={
          uploadTitle
            ? uploadTitle
            : type === "upload"
              ? "Fájl tallózása"
              : "Fájl csatolása"
        }
      >
        <IconButton
          //size="small"
          {...buttonProps}
          variant="contained"
          color={canReplace && !isNeeded ? "default" : "primary"}
          component="label"
          htmlFor={id}
          disabled={disabled}
        >
          <input
            onChange={this.handleUpload}
            type="file"
            id={id}
            accept={this.props.acceptedFiles}
            style={{ display: "none" }}
          />
          <UIcon fontSize={size} />
        </IconButton>
      </Tooltip>
    );
    return (
      <Fragment>
        <ModalConfirm onRef={ref => (this.modal = ref)} />
        {file ? !disableDelete && deleteBtn : !disableUpload && uploadBtn}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    success: state.file.success,
    //file: state.file.file,
    id: state.file.id,
    url: state.file.url,
    loading: state.file.loading
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      upload: fileActions.upload,
      download: fileActions.download,
      fileDelete: fileActions.fileDelete
    },
    dispatch
  );
};

UploadButton.defaultProps = {
  texts: {},
  type: "attach",
  confirmUpload: false,
  disabled: false,
  buttonProps: {},
  name: "file"
};

UploadButton.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  fileDelete: PropTypes.func,
  file: PropTypes.object,
  onError: PropTypes.func,
  disableDelete: PropTypes.bool,
  canReplace: PropTypes.bool,
  disableUpload: PropTypes.bool,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  isNeeded: PropTypes.bool,
  acceptedFiles: PropTypes.string,
  uploadTitle: PropTypes.string,
  texts: PropTypes.object,
  type: PropTypes.oneOf(["attach", "upload"]),
  confirmUpload: PropTypes.bool, //True esetben megerősítő kérdés jelenik meg feltöltés előtt. A szövegét a texts.confirmUploadTitle és texts.confirmUploadMsg-ből veszi
  remove: PropTypes.func,
  buttonProps: PropTypes.object,
  IconUpload: PropTypes.func,
  removeOnlyOnClient: PropTypes.bool, //Eltávolítás csak kliens oldalon
  onlyUpload: PropTypes.bool, //Ez esetben nem állítódik a state, mindig a feltöltés icon látszik
  showDeleteConfirm: PropTypes.bool, //Mindenképpen van törlési megerősítés
  deleteConfirmText: PropTypes.string //Törlést megerősítő szöveg
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadButton);
