import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditBannedCashregister from "./views/EditBannedCashregister";
import * as bannedCashregistersActions from "../../../../redux/modules/banned-cashregisters";

const mapStateToProps = state => {
  return {
    loading: state.bannedCashregisters.loading,
    success: state.bannedCashregisters.success,
    saved: state.bannedCashregisters.saved,
    message: state.bannedCashregisters.message,
    fieldErrors: state.bannedCashregisters.formErrors,
    id: state.bannedCashregisters.id,
    viewData: state.bannedCashregisters.viewData,
    reducerName: "bannedCashregisters",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: bannedCashregistersActions.createBannedCashregisters,
      update: bannedCashregistersActions.updateBannedCashregisters,
      view: bannedCashregistersActions.viewBannedCashregisters,
      reset: bannedCashregistersActions.reset,
      resetForm: bannedCashregistersActions.resetForm
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBannedCashregister);
