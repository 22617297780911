import React from "react";


import UsersForm from "./DownloadsForm";
import EditPage from "../../../../Components/Extends/EditPage";
import Edit from "../../../../Components/Edit/Edit";
import localize from "helpers/localize";

class EditDownload extends EditPage {
  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("downloads", "Letöltés megtekintése");
    }
    return this.props.match.params.id
      ? localize("downloads", "Letöltés szerkesztése")
      : localize("downloads", "Letöltés létrehozása");
  };
  render() {
    return (
      <Edit
        getPagetitle={this.getPageTitle}
        isViewPage={this.isViewPage()}
        {...this.props}
      >
        <UsersForm {...this.props} isViewPage={this.isViewPage()} />
      </Edit>
    );
  }
}

export default EditDownload;
