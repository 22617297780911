import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "Components/New/Input/TextField/TextField";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { update, remove } from "redux/modules/shopCart";
import localize from "helpers/localize";

const styles = theme => ({
  root: {
    paddingLeft: 8
  },
  rootRight: {
    paddingLeft: 8,
    textAlign: "right"
  },
  nextPrice: {
    //fontSize: "10px"
    fontStyle: "italic"
  },
  input: {
    width: 40,
    marginLeft: theme.spacing.unit * 2,
    "& input": {
      textAlign: "center"
    }
  },
  buttonIcon: {
    marginLeft: theme.spacing.unit
  }
});

class ChangeCartItem extends React.Component {
  updateQty = qty => {
    const { id, update } = this.props;
    if (isNaN(qty)) {
      globalMessage.fatalError(
        `${localize(
          "shop",
          "A darabszám csak nullától nagyobb egész szám lehet."
        )} (id: ${id})`
      );
    } else {
      update(id, qty).then(response => {
        //console.log("updateQty", response);
      });
    }
  };

  remove = () => {
    const { id, remove } = this.props;
    remove(id).then(response => {
      //console.log("remove", response);
    });
  };

  onChangeQty = evt => {
    const updateQty = this.updateQty;
    const { qty } = this.props;
    const value = evt.target.value;

    if (value === "") {
      return;
    }
    let quantity = parseInt(value);
    if (isNaN(quantity) || quantity <= 0) {
      evt.target.value = "";
      return;
    } else {
      evt.target.value = quantity;
    }
    if (qty === quantity) {
      return;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      updateQty(quantity);
    }, 1000);
  };

  onBlurQty = evt => {
    const { qty } = this.props;
    const value = evt.target.value;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    let quantity = parseInt(value);
    if (value === "" || isNaN(quantity) || quantity <= 0 || qty === quantity) {
      evt.target.value = qty;
    } else {
      this.updateQty(quantity);
    }
  };

  render() {
    const { classes, qty } = this.props;
    return (
      <Fragment>
        <div className={classes.input}>
          <TextField
            value={qty}
            onChanged={this.onChangeQty}
            onBlur={this.onBlurQty}
          />
        </div>
        <IconButton
          className={classes.buttonIcon}
          onClick={this.remove}
          color="primary"
          //aria-expanded={expanded}
          aria-label="add to cart"
        >
          <DeleteIcon />
        </IconButton>
      </Fragment>
    );
  }
}

ChangeCartItem.defaultProps = {};

ChangeCartItem.propTypes = {
  classes: PropTypes.object.isRequired,
  qty: PropTypes.number,
  id: PropTypes.number.isRequired
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ update, remove }, dispatch);
};

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps
  )(ChangeCartItem)
);
