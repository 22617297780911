import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditAeeReg from "./views/EditAeeReg";
import * as cashregistersActions from "../../../../redux/modules/smode";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.cashregisters.loading,
    success: state.cashregisters.success,
    saved: state.cashregisters.saved,
    message: state.cashregisters.message,
    fieldErrors: state.cashregisters.formErrors,
    id: state.cashregisters.id,
    viewData: state.cashregisters.viewData,
    reducerName: "cashregisters",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: cashregistersActions.createCashregisters,
      reset: cashregistersActions.reset,
      resetForm: cashregistersActions.resetForm,
      getFormInfo: optionsActions.getFormInfo
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAeeReg);
