import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "Components/Modal/Modal";
import MessageIcon from "@material-ui/icons/Message";
import SendIcon from "@material-ui/icons/Send";
import TextField from "Components/New/Input/TextField/TextField";
import Tags from "Components/New/Input/Tags/Tags";
import Select from "Components/New/Input/Select/Select";
import { FormHelperText, Grid } from "@material-ui/core";
import localize from "helpers/localize";
import restHelper from "helpers/restHelper";
import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
class OutgoingSmsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      message_error: null,
      number: "",
      number_error: null,

      client_id: null,
      client_tag: null,
      client_id_error: null,
      client_tag_error: null,

      distributor_id: null,
      distributor_tag: null,
      distributor_id_error: null,
      distributor_tag_error: null,

      baseFields: {},
      model: null,
      optionsLoaded: false,
      optionsLoading: false
    };
  }

  componentDidMount() {
    this.fetchOptions();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.onOpen();
    }
  }

  onOpen = () => {
    this.setState({
      message: "",
      number: null,
      client_id: null,
      client_tag: null,
      distributor_id: null,
      distributor_tag: null,
      message_error: null,
      number_error: null,
      client_id_error: null,
      client_tag_error: null
    });
  };

  fetchOptions = () => {
    this.setState({ optionsLoading: true });
    restHelper
      .index("outgoing-sms-messages/options", { component: "form" })
      .then(response => {
        console.log("smsOptions", response);
        this.setState({
          ...response.data.form,
          optionsLoaded: true,
          optionsLoading: false
        });
      });
  };

  getAddresseesByType = (type, attribute) => {
    const items = this.state[type];
    let adressees = [];
    if (!items) {
      return adressees;
    }
    items.forEach(value => {
      adressees.push({
        type,
        value: attribute ? value[attribute] : value
        // sendToOwner: true
      });
    });
    return adressees;
  };

  getAddressees = () => {
    let adressees = [];
    adressees = adressees.concat(
      this.getAddresseesByType("client_id", "id"),
      this.getAddresseesByType("client_tag"),
      this.getAddresseesByType("distributor_id", "id"),
      this.getAddresseesByType("distributor_tag"),
      this.getAddresseesByType("number")
    );
    return adressees;
  };

  onAccept = () => {
    const { sendSMS } = this.props;
    const { message } = this.state;

    if (this.validate()) {
      const sms = {
        message,
        addressees: this.getAddressees()
      };
      console.log("Send SMS", sms);
      sendSMS && sendSMS(sms);
    }
  };

  onCancel = () => {
    this.onClose();
  };
  onClose = () => {
    const { onClose } = this.props;
    onClose && onClose();
  };
  onChangeMessage = evt => {
    console.log("onChangeMessage", evt.target.value);
    this.setState({ message: evt.target.value });
  };
  onChangeNumber = options => {
    console.log("onChangeNumber", options);
    this.setState({ number: options });
  };
  onChangeClientId = options => {
    console.log("onChangeClientId", options);
    this.setState({ client_id: options });
  };
  onChangeClientTag = options => {
    console.log("onChangeClientTag", options);
    this.setState({ client_tag: options });
  };

  onChangeDistributorId = options => {
    console.log("onChangeDistributorId", options);
    this.setState({ distributor_id: options });
  };
  onChangeDistributorTag = options => {
    console.log("onChangeDistributorTag", options);
    this.setState({ distributor_tag: options });
  };

  validate = () => {
    const {
      message,
      client_id,
      client_tag,
      distributor_id,
      distributor_tag,
      number
    } = this.state;
    let valid = true;
    let errors = {
      message_error: null,
      client_id_error: null,
      client_tag_error: null,
      distributor_id_error: null,
      distributor_tag_error: null
    };
    if (message.length === 0) {
      errors.message_error = localize("sms", "Az üzenet kitöltése kötelező");
      valid = false;
    }
    if (
      (!client_id || client_id.length === 0) &&
      (!client_tag || client_tag.length === 0) &&
      (!distributor_id || distributor_id.length === 0) &&
      (!distributor_tag || distributor_tag.length === 0) &&
      (!number || number.length === 0)
    ) {
      errors.client_id_error = localize(
        "sms",
        "Legalább egy címzett megadása kötelező"
      );
      valid = false;
    }
    this.setState({ ...errors });
    return valid;
  };

  validateNumber = number => {
    if (!number) {
      this.setState({ number_error: null });
      return true;
    }
    if (number === "") {
      this.setState({ number_error: null });
      return true;
    }
    let countryCode = false;
    let numberWithoutCountryCode = "";
    if (number.substr(0, 3) === "+36") {
      countryCode = true;
      numberWithoutCountryCode = number.substr(3);
    } else if (number.substr(0, 2) === "06") {
      numberWithoutCountryCode = number.substr(2);
      countryCode = true;
    }

    if (!countryCode) {
      this.setState({
        number_error: localize(
          "sms",
          "Az ország hívókód megadása kötelező (06 vagy +36)"
        )
      });
      return false;
    }

    let provider = false;
    let numberOnly = "";
    if (
      ["20", "30", "70", "31"].indexOf(numberWithoutCountryCode.substr(0, 2)) >=
      0
    ) {
      provider = true;
      numberOnly = numberWithoutCountryCode.substr(2);
    }
    if (!provider) {
      this.setState({
        number_error: localize(
          "sms",
          "A mobil körzetszám megadása kötlező (20, 30, 31, 70)"
        )
      });
      return false;
    }

    if (numberOnly.length < 7) {
      this.setState({
        number_error: localize("sms", "A mobil szám nem 7 számjegyű")
      });
      return false;
    }

    if (isNaN(numberOnly)) {
      this.setState({
        number_error: localize("sms", "A mobil szám csak számokat tartalmazhat")
      });
      return false;
    }

    if (String(parseInt(numberOnly)) !== numberOnly) {
      this.setState({
        number_error: localize("sms", "A mobil szám csak számokat tartalmazhat")
      });
      return false;
    }
    this.setState({ number_error: null });
    return true;
  };

  render() {
    const {
      open,
      loading,
      onClientTagClick,
      onDistributorTagClick,
      onClientClick,
      onDistributorClick
    } = this.props;
    const {
      message,
      message_error,
      number,
      number_error,

      client_id,
      client_tag,
      client_id_error,
      client_tag_error,

      distributor_id,
      distributor_tag,
      distributor_id_error,
      distributor_tag_error,
      baseFields,
      optionsLoaded,
      optionsLoading
    } = this.state;

    var x = window.matchMedia("(min-width: 960px)");

    return (
      <Modal
        title={localize("sms", "SMS küldés")}
        yesText={localize("sms", "Küldés")}
        open={open}
        loading={loading}
        onCancel={this.onCancel}
        onClose={this.onClose}
        onAccept={this.onAccept}
        Icon={MessageIcon}
        YesIcon={SendIcon}
        maxWidth="md"
        fullScreenMobile
      >
        <Grid
          container
          spacing={16}
          style={{ minWidth: x.matches ? 960 - 33 : "calc(100vw - 33px)" }}
        >
          {/*<Grid item xs={12}>
            <TextField
              onChange={this.onChangeNumber}
              label={localize("sms", "Telfonszámok")}
              fullWidth
              value={number}
              error={number_error}
            />
    </Grid>*/}
          <Grid item xs={12}>
            <Tags
              key={`client_tag${String(optionsLoaded)}`}
              id="number"
              label={localize("sms", "Telefonszámok")}
              fullWidth
              //type="tagcomplete"
              //disableCreate
              validateNewValue={this.validateNumber}
              isMulti
              onChange={this.onChangeNumber}
              labelKey="label"
              valueKey="value"
              value={number}
              error={number_error}
              onBlur={() => {
                this.setState({ number_error: null });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              key={`client_id${String(optionsLoaded)}`}
              id="client_id"
              label={localize("sms", "Ügyfelek")}
              fullWidth
              defaultOptions={baseFields.client_id ? true : false}
              type="autocomplete"
              isMulti
              onChange={this.onChangeClientId}
              requestParams={{
                attribute: baseFields.client_id
                  ? baseFields.client_id.url.params.attribute
                  : null
              }}
              valueKey="id"
              url={
                baseFields.client_id
                  ? baseFields.client_id.url.path.replace("/v1/", "")
                  : null
              }
              value={client_id}
              error={client_id_error}
              onMultiLabelClick={(evt, props) => {
                onClientTagClick && onClientClick(props.data.id);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Tags
              key={`client_tag${String(optionsLoaded)}`}
              id="client_tag"
              label={localize("sms", "Ügyfél cimkék")}
              fullWidth
              type="tagcomplete"
              defaultOptions={baseFields.client_tag ? true : false}
              disableCreate
              isMulti
              onChange={this.onChangeClientTag}
              //labelKey="name"
              //valueKey="id"
              url={
                baseFields.client_tag
                  ? baseFields.client_tag.url.path.replace("/v1/", "")
                  : null
              }
              value={client_tag}
              error={client_tag_error}
              onMultiLabelClick={(evt, props) => {
                onClientTagClick && onClientTagClick(props.data.id);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              key={`distributor_id${String(optionsLoaded)}`}
              id="distributor_id"
              label={localize("sms", "Viszonteladók")}
              fullWidth
              defaultOptions={baseFields.distributor_id ? true : false}
              type="autocomplete"
              isMulti
              onChange={this.onChangeDistributorId}
              requestParams={{
                attribute: baseFields.distributor_id
                  ? baseFields.distributor_id.url.params.attribute
                  : null
              }}
              valueKey="id"
              url={
                baseFields.distributor_id
                  ? baseFields.distributor_id.url.path.replace("/v1/", "")
                  : null
              }
              value={distributor_id}
              error={distributor_id_error}
              onMultiLabelClick={(evt, props) => {
                onDistributorClick && onDistributorClick(props.data.id);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Tags
              key={`distributor_tag${String(optionsLoaded)}`}
              id="distributor_tag"
              label={localize("sms", "viszonteladó cimkék")}
              fullWidth
              type="tagcomplete"
              defaultOptions={baseFields.distributor_tag ? true : false}
              disableCreate
              isMulti
              onChange={this.onChangeDistributorTag}
              //labelKey="name"
              //valueKey="id"
              url={
                baseFields.distributor_tag
                  ? baseFields.distributor_tag.url.path.replace("/v1/", "")
                  : null
              }
              value={distributor_tag}
              error={distributor_tag_error}
              onMultiLabelClick={(evt, props) => {
                onDistributorTagClick && onDistributorTagClick(props.data.id);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={this.onChangeMessage}
              label={localize("sms", "Üzenet")}
              multiline
              rows={5}
              fullWidth
              value={message}
              error={message_error}
              maxLength={160}
              showRemainingChars
            />
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

export default OutgoingSmsModal;

OutgoingSmsModal.propTypes = {
  sendSMS: PropTypes.func,
  loading: PropTypes.bool,
  onClientTagClick: PropTypes.func,
  onDistributorTagClick: PropTypes.func,
  onClientClick: PropTypes.func,
  onDistributorClick: PropTypes.func
};
