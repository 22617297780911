import React, { Fragment } from "react";
import PropTypes from "prop-types";
import DataTableDx from "Components/New/DataTable/DataTableDx/DataTableDx";
import DataForm from "Components/New/DataForm/DataFormNew";
import DataProvider from "Components/New/DataProvider/DataProviderNew";
import { Grid } from "@material-ui/core";
import Select from "Components/New/Input/Select/Select";
import TextField from "Components/New/Input/TextField/TextField";
import SimpleArray2dArray from "Components/New/Input/SimpleArray2dArray/SimpleArray2dArray";
import Tags from "Components/New/Input/Tags/Tags";
import Wysiwyg from "Components/New/Input/Wysiwyg/Wysiwyg";
import Image from "Components/New/Input/Image/Image";
import formatHelper from "helpers/formatHelper";
import restHelper from "helpers/restHelper";
import { Link } from "react-router-dom";
import StarIcon from "@material-ui/icons/Star";
import authRules from "helpers/authRules";
import { SHOP_ADMIN_PRODUCTS_PERMISSION } from "constans/permissions";
import localize from "helpers/localize";

const CustomForm = ({ fieldProps }) => {
  //console.log("form", JSON.stringify(editItem.prices));
  return (
    <Fragment>
      <Grid item xs={12}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("name")} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("sku")} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("price")} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("price_retail")} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <SimpleArray2dArray
              {...fieldProps("prices", "simple-2d-array")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Select {...fieldProps("stock_info", "dropdown")} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Tags {...fieldProps("tags", "tags")} fullWidth />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5} lg={3}>
        <Image {...fieldProps("image_id", "image")} fullWidth />
      </Grid>
      <Grid item xs={12} sm={7} lg={9}>
        <Wysiwyg {...fieldProps("description", "wysiwyg")} fullWidth />
      </Grid>
    </Fragment>
  );
};

CustomForm.propTypes = {
  fieldProps: PropTypes.object
};

class AdminProducts extends React.Component {
  render() {
    //const { client, readOnly, afterSave } = this.props;

    return (
      <div style={{ width: "100%", position: "relative" }}>
        <DataProvider
          url={"shop-admin-products"}
          //url={"cashregisters"}
          goBackAfterSave
          useUrlRouting
          //disableGetView
          tableComponent={DataTableDx}
          formComponent={DataForm}
          tableProps={{
            customActions: [
              {
                icon: <StarIcon fontSize="small" />,
                style: item => {
                  if (item.highlighted == 1) {
                    return { color: "#ff9800" };
                  }
                  return {};
                },
                action: (item, index, fetchData) => {
                  //console.log(item);
                  if (item.highlighted == 1) {
                    restHelper
                      .remove("shop-admin-products/highlight", item.id)
                      .then(() => {
                        //console.log(response);
                        fetchData();
                      });
                  } else {
                    restHelper
                      .post(`shop-admin-products/highlight/${item.id}`)
                      .then(response => {
                        //console.log(response);
                        if (response.success) {
                          fetchData();
                        }
                      });
                  }
                }
              }
            ],
            title: localize("shop", "Termékek"),
            canView: false,
            canCreate: authRules.canModifyByKey(SHOP_ADMIN_PRODUCTS_PERMISSION),
            canDelete: authRules.canDeleteByKey(SHOP_ADMIN_PRODUCTS_PERMISSION),
            canEdit: authRules.canModifyByKey(SHOP_ADMIN_PRODUCTS_PERMISSION),
            columns: {
              name: {
                width: 200,
                render: (value, row) => {
                  return (
                    <Link to={`/shop/catalog/view/${row.id}`}>{value}</Link>
                  );
                }
              },
              price: { hidden: true, type: "Ft" },
              prices: {
                width: 200,
                render: value => {
                  return value
                    ? value
                      .map(
                        v => `${v.from}<=${formatHelper.currency(v.price)}`
                      )
                      .join(", ")
                    : "";
                }
              },
              tags: {
                width: 200,
                type: "tags"
              },
              sku: {},
              created_at: { width: 160 }
            }
          }}
          formProps={{
            title: localize("shop", "Termék szerkesztése"),
            titleNew: localize("shop", "Új termék felvitele"),
            fields: {
              name: { name: localize("shop", "Név"), validators: ["required"] },
              sku: { name: localize("shop", "Azonosító") },
              price: {
                name: localize("shop", "Nagyker ár"),
                type: "Ft"
              },
              price_retail: {
                name: localize("shop", "Kisker ár"),
                type: "Ft"
              },
              prices: {
                name: localize("shop", "Sávos nagyker ár"),
                type: "simple-2d-array",
                columns: [
                  {
                    id: "from",
                    label: localize("shop", "Darabtól"),
                    type: "number"
                  },
                  {
                    id: "price",
                    label: localize("shop", "Ár"),
                    type: "number"
                  }
                ]
              },
              tags: {
                name: localize("shop", "Címkék"),
                type: "tags",
                url: { path: "/v1/shop-admin-products/tags" }
              },
              stock_info: {
                name: localize("shop", "Készlet info")
                //type: "dropdown"
                //url: { path: "/v1/shop-admin-products/tags" }
              },
              description: {
                name: localize("shop", "Leírás"),
                type: "wysiwyg",
                sizes: { xs: 12 }
              },
              image_id: {
                name: localize("shop", "Kép"),
                type: "image"
              }
            },
            fieldsOrder: [
              "name",
              "sku",
              "price",
              "tags",
              "image_id",
              "description"
            ],
            component: CustomForm,
            autoRenderFields: false
          }}
        />
      </div>
    );
  }
}

export default AdminProducts;
