import axios from "axios";
import qs from "qs";
import { get } from "lodash";
import * as fileActions from "./file";
import moment from "moment";
import * as fcmActions from "./fcm";

const CASHREGISTERS_LIST_LOAD = "CASHREGISTERS_LIST_LOAD";
const CASHREGISTERS_LIST_SUCCESS = "CASHREGISTERS_LIST_SUCCESS";
const CASHREGISTERS_LIST_FAILED = "CASHREGISTERS_LIST_FAILED";
const CASHREGISTERS_SET_LOADING = "CASHREGISTERS_SET_LOADING";

const CASHREGISTERS_FORM_LOAD = "CASHREGISTERS_FORM_LOAD";
const CASHREGISTERS_FORM_SUCCESS = "CASHREGISTERS_FORM_SUCCESS";
const CASHREGISTERS_FORM_FAILED = "CASHREGISTERS_FORM_FAILED";

const CASHREGISTER_DELETE_LOAD = "CASHREGISTER_DELETE_LOAD";
const CASHREGISTER_DELETE_SUCCESS = "CASHREGISTER_DELETE_SUCCESS";
const CASHREGISTER_DELETE_FAILED = "CASHREGISTER_DELETE_FAILED";

const CASHREGISTER_EDIT_LOAD = "CASHREGISTER_EDIT_LOAD";
const CASHREGISTER_EDIT_SUCCESS = "CASHREGISTER_EDIT_SUCCESS";
const CASHREGISTER_EDIT_FAILED = "CASHREGISTER_EDIT_FAILED";

const CASHREGISTER_SMS_LOAD = "CASHREGISTER_SMS_LOAD";
const CASHREGISTER_SMS_SUCCESS = "CASHREGISTER_SMS_SUCCESS";
const CASHREGISTER_SMS_FAILED = "CASHREGISTER_SMS_FAILED";

const CASHREGISTER_REPORT_LOAD = "CASHREGISTER_REPORT_LOAD";
const CASHREGISTER_REPORT_SUCCESS = "CASHREGISTER_REPORT_SUCCESS";
const CASHREGISTER_REPORT_FAILED = "CASHREGISTER_REPORT_FAILED";

const CASHREGISTER_KEY_LOAD = "CASHREGISTER_KEY_LOAD";
const CASHREGISTER_KEY_SUCCESS = "CASHREGISTER_KEY_SUCCESS";
const CASHREGISTER_KEY_FAILED = "CASHREGISTER_KEY_FAILED";

const CASHREGISTER_HISTORY_LOAD = "CASHREGISTER_HISTORY_LOAD";
const CASHREGISTER_HISTORY_SUCCESS = "CASHREGISTER_HISTORY_SUCCESS";
const CASHREGISTER_HISTORY_FAILED = "CASHREGISTER_HISTORY_FAILED";

const CASHREGISTER_STATUS_LOAD = "CASHREGISTER_STATUS_LOAD";
const CASHREGISTER_STATUS_SUCCESS = "CASHREGISTER_STATUS_SUCCESS";
const CASHREGISTER_STATUS_FAILED = "CASHREGISTER_STATUS_FAILED";

const CASHREGISTER_READ_LOAD = "CASHREGISTER_READ_LOAD";
const CASHREGISTER_READ_SUCCESS = "CASHREGISTER_READ_SUCCESS";
const CASHREGISTER_READ_START = "CASHREGISTER_READ_START";
const CASHREGISTER_READ_FAILED = "CASHREGISTER_READ_FAILED";

const CASHREGISTER_VIEW_LOAD = "CASHREGISTER_VIEW_LOAD";
const CASHREGISTER_VIEW_SUCCESS = "CASHREGISTER_VIEW_SUCCESS";
const CASHREGISTER_VIEW_FAILED = "CASHREGISTER_VIEW_FAILED";

const CASHREGISTER_REREGISTERS_LOAD = "CASHREGISTER_REREGISTERS_LOAD";
const CASHREGISTER_REREGISTERS_SUCCESS = "CASHREGISTER_REREGISTERS_SUCCESS";
const CASHREGISTER_REREGISTERS_FAILED = "CASHREGISTER_REREGISTERS_FAILED";

const CASHREGISTERS_RESET = "CASHREGISTERS_RESET";
const CASHREGISTERS_RESET_FORM = "CASHREGISTERS_RESET_FORM";

const CASHREGISTERS_RESET_CODE = "CASHREGISTERS_RESET_CODE";

const CASHREGISTER_RECEIVED_OPEN = "CASHREGISTER_RECEIVED_OPEN";
const CASHREGISTER_RECEIVED_CLOSE = "CASHREGISTER_RECEIVED_CLOSE";

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit: process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: "",
  orderMode: "ASC",
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  events: [],
  viewLoading: false,
  commentThread: false,
  generated_code: null,
  id: null,
  receivedOpen: false,

  reregisters: [],
  reregisters_loading: false,
  reregisters_success: false,
  reregisters_message: null,
  sms_loading: false,
  sms_success: false,
  sms_saved: false,
  sms_failed: false,
  sms_formErrors: {},
  sms_message: null,

  status_loading: false,
  status_success: false,
  status_saved: false,
  status_failed: false,
  status_formErrors: {},
  status_message: null,

  report_loading: false,
  report_success: false,
  report_saved: false,
  report_failed: false,
  report_formErrors: {},
  report_message: null,

  key_loading: false,
  key_success: false,
  key_saved: false,
  key_failed: false,
  key_formErrors: {},
  key_message: null,

  history_loading: false,
  history_failed: false,
  history_data: [],
  history_message: null,

  read_loading: false,
  read_success: false,
  read_saved: false,
  read_failed: false,
  read_message: null,
  read_id: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CASHREGISTER_RECEIVED_OPEN:
      return {
        ...state,
        receivedOpen: true
      };

    case CASHREGISTER_RECEIVED_CLOSE:
      return {
        ...state,
        receivedOpen: false
      };

    case CASHREGISTERS_RESET:
      return {
        ...initialState,
        read_loading: state.read_loading,
        read_success: state.read_success,
        read_saved: state.read_saved,
        read_failed: state.read_failed,
        read_message: state.read_message,
        read_id: state.read_id,
        viewData: state.viewData
      };
    case CASHREGISTERS_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case CASHREGISTERS_RESET_CODE:
      return {
        ...state,
        generated_code: null
      };
    case CASHREGISTER_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case CASHREGISTERS_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false
      };

    case CASHREGISTER_SMS_LOAD:
      return {
        ...state,
        sms_loading: true,
        sms_failed: false,
        sms_message: null,
        sms_formErrors: {}
      };
    case CASHREGISTER_REPORT_LOAD:
      return {
        ...state,
        report_loading: true,
        report_failed: false,
        report_message: null,
        report_formErrors: {}
      };
    case CASHREGISTER_STATUS_LOAD:
      return {
        ...state,
        status_loading: true,
        status_failed: false,
        status_message: null,
        status_formErrors: {}
      };

    case CASHREGISTER_READ_LOAD:
      return {
        ...state,
        read_loading: true,
        read_failed: false,
        read_message: null
      };

    case CASHREGISTER_READ_START:
      return {
        ...state,
        read_id: action.id
      };

    case CASHREGISTER_KEY_LOAD:
      return {
        ...state,
        key_loading: true,
        key_failed: false,
        key_message: null,
        key_formErrors: {},
        generated_code: null
      };

    case CASHREGISTER_HISTORY_LOAD:
      return {
        ...state,
        history_loading: true,
        history_failed: false,
        history_message: null
      };

    case CASHREGISTER_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false,
        generated_code: null
      };
    case CASHREGISTER_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case CASHREGISTER_REREGISTERS_LOAD:
      return {
        ...state,
        reregisters_loading: true,
        reregisters_success: false
      };
    case CASHREGISTERS_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case CASHREGISTERS_FORM_FAILED:
    case CASHREGISTER_EDIT_FAILED:
    case CASHREGISTER_REREGISTERS_FAILED:
    case CASHREGISTER_SMS_FAILED:
    case CASHREGISTER_KEY_FAILED:
    case CASHREGISTER_HISTORY_FAILED: //case CASHREGISTER_READ_FAILED:
    case CASHREGISTER_REPORT_FAILED:
    case CASHREGISTER_STATUS_FAILED:
    case CASHREGISTER_DELETE_FAILED:
    case CASHREGISTERS_LIST_FAILED:
    case CASHREGISTER_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false,

        sms_loading: false,
        sms_failed: true,
        sms_message: action.sms_message,
        sms_formErrors: action.sms_formErrors || {},

        status_loading: false,
        status_failed: true,
        status_message: action.status_message,
        status_formErrors: action.status_formErrors || {},

        report_loading: false,
        report_failed: true,
        report_message: action.report_message,
        report_formErrors: action.report_formErrors || {},

        key_loading: false,
        key_failed: true,
        key_message: action.key_message,
        key_formErrors: action.key_formErrors || {},

        history_loading: false,
        history_failed: true,
        history_message: action.history_message,

        read_loading: false,
        read_failed: true,
        read_message: action.read_message,

        reregisters_message: action.message,
        reregisters_loading: false,
        reregisters_success: false
      };
    case CASHREGISTER_READ_FAILED:
      return {
        ...state,
        read_loading: false,
        read_failed: true,
        read_message: action.read_message
      };
    case CASHREGISTERS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode
      };
    case CASHREGISTER_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        viewData: action.viewData,
        events: action.viewData && (action.viewData.events || []),
        viewLoading: false
      };
    case CASHREGISTER_REREGISTERS_SUCCESS:
      return {
        ...state,
        reregisters: action.data,
        reregisters_loading: false,
        reregisters_success: true,
        reregisters_message: null
      };
    case CASHREGISTERS_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case CASHREGISTER_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case CASHREGISTER_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: action.id,
        viewLoading: false,
        generated_code: action.generated_code
      };
    case CASHREGISTER_SMS_SUCCESS:
      return {
        ...state,
        sms_loading: false,
        sms_saved: true,
        sms_failed: false,
        sms_message: null,
        sms_formErrors: {}
      };

    case CASHREGISTER_READ_SUCCESS:
      return {
        ...state,
        read_saved: true,
        read_failed: false,
        read_message: null,
        read_loading: false,
        viewData: action.viewData,
        events: action.viewData && (action.viewData.events || [])
      };

    case CASHREGISTER_REPORT_SUCCESS:
      return {
        ...state,
        report_loading: false,
        report_saved: true,
        report_failed: false,
        report_message: null,
        report_formErrors: {},
        viewData: action.viewData,
        events: action.viewData && (action.viewData.events || [])
      };

    case CASHREGISTER_KEY_SUCCESS:
      return {
        ...state,
        key_loading: false,
        key_saved: true,
        key_failed: false,
        key_message: null,
        key_formErrors: {},
        generated_code: action.generated_code,
        viewData: action.viewData,
        events: action.viewData && (action.viewData.events || [])
      };
    case CASHREGISTER_HISTORY_SUCCESS:
      return {
        ...state,
        history_loading: false,
        history_failed: false,
        history_message: null,
        history_data: action.data
      };

    case CASHREGISTER_STATUS_SUCCESS:
      return {
        ...state,
        status_loading: false,
        status_saved: true,
        status_failed: false,
        status_message: null,
        status_formErrors: {},
        viewData: action.viewData,
        events: action.viewData && (action.viewData.events || [])
      };
    case CASHREGISTERS_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
};

export const startCashregisterRead = () => dispatch => {
  dispatch({
    type: CASHREGISTER_READ_LOAD
  });

  return axios
    .post("/v1/cashregisters/read")
    .then(response => {
      dispatch(subscribeReceived());
      dispatch({
        type: CASHREGISTER_READ_START,
        id: get(response, "data.data.id")
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTER_READ_FAILED,
        read_message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const endCashregisterRead = () => (dispatch, getState) => {
  dispatch(unSubscribeReceived());
  //return axios.delete('/v1/cashregisters/read/'+getState().cashregisters.read_id);
};

export const readCashregister = () => (dispatch, getState) => {
  dispatch({
    type: CASHREGISTER_READ_LOAD
  });
  dispatch(endCashregisterRead());

  return axios
    .get("/v1/cashregisters/read/" + getState().cashregisters.read_id)
    .then(response => {
      const success = get(response, "data.success", false);
      if (success) {
        dispatch({
          type: CASHREGISTER_READ_SUCCESS,
          viewData: get(response, "data.data")
        });
      } else {
        dispatch({
          type: CASHREGISTER_READ_FAILED,
          read_message: "Sikertelen beolvasás"
        });
      }

      return success;
    })
    .catch(error => {
      var msg = get(error, "response.data.message", null);
      if (!msg || msg === "") {
        msg = get(error, "response.data.name", null);
      }
      dispatch({
        type: CASHREGISTER_READ_FAILED,
        read_message: msg
      });
      throw error.response;
    });
};

export const sendKey = data => (dispatch, getState) => {
  dispatch({
    type: CASHREGISTER_KEY_LOAD
  });

  let obj = {
    KeyGenerator: data
  };

  let events = getState().cashregisters.viewData;

  return axios
    .post("/v1/cashregisters/key", obj)
    .then(response => {
      events.events = get(response, "data.data.historyLogs", []);
      dispatch({
        type: CASHREGISTER_KEY_SUCCESS,
        generated_code: get(response, "data.data.key", null),
        viewData: events
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTER_KEY_FAILED,
        key_message: get(error, "response.data.message", null),
        key_formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const historyList = id => dispatch => {
  dispatch({
    type: CASHREGISTER_HISTORY_LOAD
  });
  //let events = getState().cashregisters.viewData;

  return axios
    .get("/v1/cashregisters/events/" + id)
    .then(response => {
      //events.events = get(response, "data.data.historyLogs", []);
      const data = get(response, "data.data", []);
      dispatch({
        type: CASHREGISTER_HISTORY_SUCCESS,
        data: data
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTER_HISTORY_FAILED,
        history_message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const sendReport = (id, data) => dispatch => {
  dispatch({
    type: CASHREGISTER_REPORT_LOAD
  });

  let obj = {
    ErrorReportForm: data
  };

  return axios
    .post("/v1/cashregisters/error-report/" + id, obj)
    .then(response => {
      dispatch({
        type: CASHREGISTER_REPORT_SUCCESS,
        viewData: get(response, "data.data.cashregister", {})
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTER_REPORT_FAILED,
        report_message: get(error, "response.data.message", null),
        report_formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const sendSms = (id, data) => dispatch => {
  dispatch({
    type: CASHREGISTER_SMS_LOAD
  });

  let obj = {
    SmsOut: data
  };

  return axios
    .post("/v1/cashregisters/sms/" + id, obj)
    .then(response => {
      dispatch({
        type: CASHREGISTER_SMS_SUCCESS
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTER_SMS_FAILED,
        sms_message: get(error, "response.data.message", null),
        sms_formErrors: get(error, "response.data.form_errors", {})
      });
      return error.response;
    });
};

export const setStatus = (id, status) => dispatch => {
  dispatch({
    type: CASHREGISTER_STATUS_LOAD
  });

  let obj = {
    CashregisterStatusForm: {
      status
    }
  };

  return axios
    .post("/v1/cashregisters/status/" + id, obj)
    .then(response => {
      dispatch({
        type: CASHREGISTER_STATUS_SUCCESS,
        viewData: get(response, "data.data", {})
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTER_STATUS_FAILED,
        status_message: get(error, "response.data.message", null),
        status_formErrors: get(error, "response.data.form_errors", {})
      });
      return error.response;
    });
};

export const reset = () => dispatch => {
  dispatch({
    type: CASHREGISTERS_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: CASHREGISTERS_RESET_FORM
  });
};

export const resetGeneratedCode = () => dispatch => {
  dispatch({
    type: CASHREGISTERS_RESET_CODE
  });
};

export const updateCashregisters = (model, id, data) => dispatch => {
  dispatch({
    type: CASHREGISTER_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .put("/v1/cashregisters/" + id, obj)
    .then(response => {
      dispatch({
        type: CASHREGISTER_EDIT_SUCCESS
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTER_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const viewCashregisters = id => dispatch => {
  dispatch({
    type: CASHREGISTER_VIEW_LOAD
  });
  return axios
    .get("/v1/cashregisters/" + id)
    .then(response => {
      dispatch({
        type: CASHREGISTER_VIEW_SUCCESS,
        viewData: get(response, "data.data"),
        commentThread: get(response, "data.commentThread", false)
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTER_VIEW_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const listCashregisterReregisters = id => dispatch => {
  dispatch({
    type: CASHREGISTER_REREGISTERS_LOAD
  });
  return axios
    .get("/v1/cashregisters/reregisters/" + id)
    .then(response => {
      dispatch({
        type: CASHREGISTER_REREGISTERS_SUCCESS,
        data: get(response, "data.data")
        //commentThread: get(response, 'data.commentThread', false)
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTER_REREGISTERS_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const importCashregister = (model, data) => dispatch => {
  dispatch({
    type: CASHREGISTER_EDIT_LOAD
  });

  let obj = { CashregisterLogImportForm: data };

  return axios
    .post("/v1/cashregisters/read-log", obj)
    .then(response => {
      dispatch({
        type: CASHREGISTER_EDIT_SUCCESS,
        id: get(response, "data.data.id", null)
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTER_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const createCashregisters = (model, data) => dispatch => {
  const _model = "KeyGenerator";

  dispatch({
    type: CASHREGISTER_EDIT_LOAD
  });

  let obj = {};
  obj[_model] = data;
  obj[_model].type = "prior_fiscat";

  return axios
    .post("/v1/cashregisters/key", obj)
    .then(response => {
      dispatch({
        type: CASHREGISTER_EDIT_SUCCESS,
        id: get(response, "data.data.id", null),
        generated_code: get(response, "data.data.key", null)
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTER_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const createCashregistersManual = (model, data) => dispatch => {
  dispatch({
    type: CASHREGISTER_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .post("/v1/cashregisters/manual", obj)
    .then(response => {
      dispatch({
        type: CASHREGISTER_EDIT_SUCCESS,
        id: get(response, "data.data.id", null),
        generated_code: get(response, "data.data.aeereg_generated_code", null)
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTER_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const cashregistersDelete = id => dispatch => {
  dispatch({
    type: CASHREGISTER_DELETE_LOAD
  });
  return axios
    .delete("/v1/cashregisters/" + id)
    .then(response => {
      dispatch({
        type: CASHREGISTER_DELETE_SUCCESS,
        id
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTER_DELETE_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: CASHREGISTERS_SET_LOADING,
    loading
  });
};

export const exportList = (page, keyword, sort, filters) => dispatch => {
  let params = {
    page,
    keyword,
    sort
  };

  params[filters.model] = filters.filters;

  return dispatch(
    fileActions.download(
      "/v1/cashregisters/export",
      `cashregister_${moment().format("YYYY-MM-DD")}.csv`,
      params
    )
  );
};

export const cashregisterssList = (
  page,
  keyword,
  sort,
  filters,
  perPage
) => dispatch => {
  dispatch({
    type: CASHREGISTERS_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    "per-page": perPage
  };

  params[filters.model] = filters.filters;

  return axios
    .get(
      "/v1/cashregisters",
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then(response => {
      let sort = get(response, "data.sort", null),
        mode = "ASC";
      if (sort && sort.indexOf("-") !== -1) {
        mode = "DESC";
        sort = sort.substr(1);
      }
      dispatch({
        type: CASHREGISTERS_LIST_SUCCESS,
        data: get(response, "data.data", null),
        page: get(response, "data.page", 0),
        limit: get(response, "data.per-page", 0),
        total: get(response, "data.total", 0),
        order: sort,
        orderMode: mode
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CASHREGISTERS_LIST_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const subscribeReceived = () => dispatch => {
  return dispatch(
    fcmActions.subscribe("clientDataReceived", payload => {
      //console.log(payload);
      return dispatch(readCashregister());
    })
  );
};

export const unSubscribeReceived = () => dispatch => {
  fcmActions.unsubscribe("clientDataReceived");
};

export const deleteSubscriberReadId = () => (dispatch, getState) => {
  if (fcmActions.isActiveSubscribe("clientDataReceived")) {
    return axios.delete(
      "/v1/cashregisters/read/" + getState().cashregisters.read_id
    );
  }
};

export const getCachRegisterStatusConfig = () => {
  return axios.get("/v1/cashregisters/options?component=status");
};
