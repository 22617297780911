import React from "react";

import UsersForm from "./DistributorsForm";
import EditPage from "../../../../Components/Extends/EditPage";
import Edit from "../../../../Components/Edit/Edit";
import localize from "helpers/localize";

class EditDistributor extends EditPage {
  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("distributors", "Viszonteladó megtekintése");
    }
    return this.props.match.params.id
      ? localize("distributors", "Viszonteladó szerkesztése")
      : localize("distributors", "Viszonteladó létrehozása");
  };
  render() {
    return (
      <Edit
        getPagetitle={this.getPageTitle}
        isViewPage={this.isViewPage()}
        {...this.props}
      >
        <UsersForm {...this.props} isViewPage={this.isViewPage()} />
      </Edit>
    );
  }
}

export default EditDistributor;
