import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import restHelper from "helpers/aee/restHelper";
import requestHelper from "helpers/aee/requestHelper";
import DataProvider from "Components/New/DataProvider/DataProvider";
import DataTableDevExpress from "Components/New/DataTable/DataTableDevExpress/DataTableDevExpress";
import DataTable from "Components/New/DataTable/DataTable/DataTable";
import authRules from "helpers/authRules";
import Modal from "Components/Modal/Modal";
import JsonSyntaxHighlight from "helpers/JsonSyntaxHighlight";
const LC = "cloud";
const styles = theme => ({
  /*"@global": {
    ".rbt-input-hint": {
      //backgroundColor: "red!important",
      width: "100%",
      top: "2px!important"
    }
  },*/
  rowDeleted: {
    backgroundColor: "red!important",
    color: "white"
  },
  rowChanged: {
    backgroundColor: "green!important",
    color: "white"
  },
  marginLeftAuto: {
    marginLeft: "auto"
  },
  marginLeft: {
    marginLeft: theme.spacing.unit
  }
});

class QueueLogins extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      selectedRow: null
    };
  }

  componentDidMount() {
    console.log("Products.componentDidMount", this.props.match.params);
  }

  toggleEdit = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  customDelete = (row, restHelperOptions) => {
    const { cashregister_id, client_id } = this.props;
    return requestHelper({
      url: `cashregister-queue/delete?function=${row.function}`,
      method: "GET",
      //...restHelperOptions
      ap: cashregister_id || row.apn,
      client: client_id
    });
  };
  customView = (row, rowIndex) => {
    this.setState({ selectedRow: row });
    return;
    console.log("customView", row, rowIndex);
    const { cashregister_id, client_id } = this.props;
    requestHelper({
      url: `cashregister-queue/xml?function=${row.function}`,
      method: "GET",
      ap: cashregister_id || row.apn,
      client: client_id
    }).then(response => {
      //console.log("customView.response", response);
      if (response.data && response.data.data && response.data.data.xml) {
        let blob = new Blob(
          [
            `<?xml version="1.0" encoding="UTF-8"?><root>${
              response.data.data.xml
            }</root>`
          ],
          { type: "text/xml" }
        );
        let url = URL.createObjectURL(blob);
        window.open(url);
        URL.revokeObjectURL(url); //Releases the resources
      }
    });
  };

  render() {
    const { classes, cashregister_id, client_id } = this.props;
    const { selectedRow } = this.state;
    return (
      <div style={{ paddingTop: 8 }}>
        <DataProvider
          filterable={false}
          hideToolbar
          disableOptions
          url={"cashregister-aee-notify"}
          restHelperOptions={{ ap: cashregister_id, client: client_id }}
          restHelper={restHelper}
          component={DataTable}
          //customDelete={this.customDelete}
          canRemove={false}
          customView={this.customView}
          canAdd={false}
          canEdit={false}
          canView={authRules.hasPermissionByKeyAndName(
            "cloud",
            "queue.viewxml"
          )}
          //canViewByRow={row => row.has_xml}
          options={{
            grid: {
              columns: {
                created_at: {
                  //type: "numeric",
                  name: localize(LC, "Létrehozva"),
                  readOnly: true
                }
              }
            }
          }}
        />
        <Modal
          title={localize(LC, "Adatok")}
          open={selectedRow ? true : false}
          disableOk
          noText={localize(LC, "Bezár")}
          onClose={() => this.setState({ selectedRow: null })}
          onCancel={() => this.setState({ selectedRow: null })}
          onAccept={() => this.setState({ selectedRow: null })}
          maxWidth="lg"
        >
          <JsonSyntaxHighlight json={selectedRow ? selectedRow.data : null} />
        </Modal>
      </div>
    );
  }
}

QueueLogins.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

export default withStyles(styles)(withRouter(QueueLogins));
