const formatHelper = {
  currenyFormat: (x, defaultValue) => {
    if (!x) {
      return defaultValue ? defaultValue : "";
    }
    return (
      Math.round(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ft"
    );
  },
  currenyEurFormat: (x, defaultValue) => {
    if (!x) {
      return defaultValue ? defaultValue : "";
    }
    return (
      Math.round(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "  Eur"
    );
  },
  currency: (val, defaultValue) => {
    if (!val) {
      return defaultValue ? defaultValue : ""; //"0 Ft";
    }
    val = Math.round(val * 100) / 100;
    val += "";
    let x = val.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1 $2");
    }
    return x1 + x2 + " Ft";
  },
  fileSize: value => {
    const val = parseInt(value);
    if (isNaN(val)) {
      return "0 Byte";
    }

    if (val < 1024) {
      return val + " Byte";
    } else if (val < 1048576) {
      return Math.round((val / 1024) * 100) / 100 + " KByte";
    } else {
      return Math.round((val / 1048576) * 100) / 100 + " MByte";
    }
  }
};

export default formatHelper;
