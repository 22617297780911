import React from "react";
import PropTypes from "prop-types";
import {
  fade,
  withStyles,
  makeStyles,
  createMuiTheme
} from "@material-ui/core/styles";
import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  Checkbox as MUICheckbox
} from "@material-ui/core";

const styles = theme => ({
  label: {
    fontSize: 12,
    color: theme.palette.text.secondary
  }
});

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = evt => {
    const { onChange, id } = this.props;
    onChange && onChange(evt);
  };

  render() {
    const {
      id,
      classes,
      fullWidth,
      onKeyPress,
      onEnter,
      error,
      helperText,
      onChange,
      value,
      label,
      isRequired,
      ...rest
    } = this.props;
    /* eslint-disable no-unused-vars */

    /* eslint-enable no-unused-vars */
    return (
      <FormControl
        fullWidth={fullWidth}
        error={error ? true : false} /*className={classes.margin}*/
      >
        <FormControlLabel
          classes={{ label: classes.label }}
          control={
            <MUICheckbox
              //tabIndex={-1}
              color="primary"
              id={id}
              checked={value ? true : false}
              //onClick={() => props.onToggle(props.name)}
              onChange={this.onChange}
              //checkedIcon={<Check className={props.classes.checkedIcon} />}
              //icon={<Check className={props.classes.uncheckedIcon} />}
              //classes={{ checked: props.classes.checked }}
              {...rest}
            />
          }
          //classes={{ label: props.classes.label }}
          label={label}
        />
        {(error || helperText) && (
          <FormHelperText error={error ? true : false}>
            {error || helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

Checkbox.defaultProps = {
  id: "bootstrap-input"
};

Checkbox.propTypes = {};

//export default withStyles(styles)(Checkbox);
export default withStyles(styles)(Checkbox);
