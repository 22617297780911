import axios from "axios";
import qs from "qs";
import { get } from "lodash";

const SLIDERS_FETCH_START = "SLIDERS_FETCH_START";
const SLIDERS_FETCH_SUCCESS = "SLIDERS_FETCH_SUCCESS";
const SLIDERS_FETCH_FAILED = "SLIDERS_FETCH_FAILED";

const SLIDERS_UPDATE_START = "SLIDERS_UPDATE_START";
const SLIDERS_UPDATE_SUCCESS = "SLIDERS_UPDATE_SUCCESS";
const SLIDERS_UPDATE_FAILED = "SLIDERS_UPDATE_FAILED";

const initialState = {
  loading: false,
  error: null,
  items: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SLIDERS_FETCH_START:
      return {
        ...state,
        loading: true
      };
    case SLIDERS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.items
      };
    case SLIDERS_FETCH_FAILED:
      return {
        ...state,
        error: action.error
      };
    case SLIDERS_UPDATE_START:
      return {
        ...state,
        loading: true
      };
    case SLIDERS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.items
      };
    case SLIDERS_UPDATE_FAILED:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export const index = () => dispatch => {
  dispatch({
    type: SLIDERS_FETCH_START
  });
  return axios
    .get("/v1/dashboard/slides")
    .then(response => {
      const items = get(response, "data.data", []);
      dispatch({
        type: SLIDERS_FETCH_SUCCESS,
        items: items
      });
      return items;
    })
    .catch(response => {
      const error = get(response, "response.data.message", "A manóba. :(");
      dispatch({
        type: SLIDERS_FETCH_FAILED,
        error: error
      });
      throw error;
    });
};

export const update = items => dispatch => {
  dispatch({
    type: SLIDERS_UPDATE_START
  });
  return axios
    .put("/v1/dashboard/slides", { data: items })
    .then(response => {
      //const newItems = get(response, "data.data", []);
      //TODO: a szervertől jó volna vissza a válaszként, amit beküldtem
      const newItems = items;
      dispatch({
        type: SLIDERS_UPDATE_SUCCESS,
        items: newItems
      });
      return newItems;
    })
    .catch(response => {
      const error = get(response, "response.data.message", "A manóba. :(");
      dispatch({
        type: SLIDERS_UPDATE_FAILED,
        error: error
      });
      throw error;
    });
};
