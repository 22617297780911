import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { get } from "lodash";
import {
  DatePicker as MUIDatePicker,
  MuiPickersUtilsProvider
} from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/hu";
import "moment/locale/en-gb";
import TextField from "Components/New/Input/TextField/TextField";
import { IconButton } from "@material-ui/core";
import DateIcon from "@material-ui/icons/CalendarToday";
moment.locale("hu");

class DatePicker extends Component {
  constructor(props) {
    super(props);
    const date = get(props, "input.value", null);
    this.state = {
      date: this.parseDate(date)
    };
    this.picker = React.createRef();
  }

  parseDate = dateStr => {
    if (dateStr) {
      return moment(dateStr);
    } else {
      return null;
    }
  };

  componentWillReceiveProps(nextProps) {
    const date = get(nextProps, "input.value", null);
    const newDate = this.parseDate(date);
    if (this.state.date !== newDate) {
      this.setState({ date: newDate });
    }
  }

  handleDateOnclick = () => {
    if (this.picker && this.picker.current && this.picker.current.open) {
      this.picker.current.open();
    }
  };

  handleInputChange = evt => {
    const { onChange } = this.props;
    if (evt.target && evt.target.value) {
      if (moment(evt.target.value, "YYYY.MM.DD", true).isValid()) {
        onChange(moment(evt.target.value).format("YYYY.MM.DD"));
      } else if (evt.target.value === "") {
        onChange(null);
      }
    } else {
      onChange(null);
    }
  };
  render = () => {
    const {
      classes,
      id,
      label,
      fullWidth,
      onKeyPress,
      onEnter,
      error,
      mask,
      isRequired,
      onChange,
      inputFormat,
      valueFormat,
      inline,
      ...rest
    } = this.props;

    const { date } = this.state;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale="hu" moment={moment}>
        <MUIDatePicker
          style={{ marginTop: inline ? 0 : 16 }}
          ref={this.picker}
          label={isRequired ? label + " *" : label}
          format={inputFormat}
          TextFieldComponent={TextField}
          fullWidth
          locale={"hu"}
          okLabel="Ok"
          cancelLabel="Mégsem"
          clearLabel="Törlés"
          animateYearScrolling={false}
          disableOpenOnEnter
          variant="inline"
          clearable
          onClick={() => {}}
          value={date}
          //error={error ? true : false}
          error={error}
          inputProps={{
            inputProps: {
              "aria-autocomplete": "none",
              autoComplete: "off"
            },
            onChange: this.handleInputChange
          }}
          helperText={undefined}
          endAdornment={
            <IconButton onClick={this.handleDateOnclick} style={{ margin: -5 }}>
              <DateIcon fontSize="small" />
            </IconButton>
          }
          onChange={date => {
            this.setState({ date });
            onChange(date ? date.format(valueFormat) : null);
          }}
          {...rest}
        />
      </MuiPickersUtilsProvider>
    );
  };
}

DatePicker.defaultProps = {
  inputFormat: "Y.MM.DD",
  valueFormat: "YYYY-MM-DD"
};

DatePicker.propTypes = {
  inputFormat: PropTypes.string,
  valueFormat: PropTypes.string,
  onChange: PropTypes.func,
  inline: PropTypes.bool
};

export default DatePicker;
