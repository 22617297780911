import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core/styles";
import inputStyle from "./inputStyle.js";
import Wrapper from "./Wrapper";

class RadioInput extends Component {
  static propTypes = {
    input: PropTypes.any.isRequired,
    label: PropTypes.string,
    fieldError: PropTypes.string
  };

  render() {
    const { input, label, options, fieldError, classes, inline } = this.props;
    const { warning, touched, error } = this.props.meta;
    const hasError =
      fieldError || (touched && (warning || (error ? true : false)));
    const errorMessage = fieldError || (touched && (warning || error));
    return (
      <Wrapper {...this.props}>
        <FormControl component="fieldset" error={hasError}>
          {label && (
            <FormLabel className={classes.radioLabel} component="legend">
              {label}
            </FormLabel>
          )}
          <RadioGroup
            className={classes.radioRoot}
            value={get(input, "value", false)}
            {...input}
            style={inline ? { flexDirection: "row" } : null}
          >
            {options &&
              options.map((data, index) => (
                <FormControlLabel
                  className={classes.radioRow}
                  style={inline ? { paddingRight: 16 } : null}
                  key={index}
                  value={data.id}
                  control={
                    <Radio className={classes.radioButton} color="primary" />
                  }
                  label={data.label}
                />
              ))}
          </RadioGroup>
          <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
      </Wrapper>
    );
  }
}

export default withStyles(inputStyle)(RadioInput);
