import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
//import logo from "../../../../../assets/img/prior_logo.png";
//import bgImage from "assets/img/szervizkod-kezdolap-1920x1080-csak-logo.jpg";

import { withStyles } from "@material-ui/core/styles";
import loginStyle from "styles/jss/loginStyle";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography
} from "@material-ui/core";
import localize from "helpers/localize";

const logo = require(`themes/${process.env.REACT_APP_THEME}/logo.png`);
const logo2 = require(`themes/${process.env.REACT_APP_THEME}/logo2.png`);
const bg = require(`themes/${process.env.REACT_APP_THEME}/bg.jpg`);
const strings = require(`themes/${process.env.REACT_APP_THEME}/strings`);

class Login extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      error_username: false,
      error_password: false,
      error: false,
      shouldShrink: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      try {
        if (this.email && this.email.matches(":-webkit-autofill")) {
          this.setState({ shouldShrink: true });
        } else {
          setTimeout(() => {
            if (this.email && this.email.matches(":-webkit-autofill")) {
              this.setState({ shouldShrink: true });
            }
          }, 500);
        }
      } catch (error) {}
    }, 500);
  }

  componentWillMount = () => {
    this.props.reset();
  };

  componentWillUnmount = () => {
    this.props.reset();
  };

  handleInputKeypress = evt => {
    let keyCode = evt.which || evt.keyCode;
    if (keyCode === 13) {
      //Enter
      this.handleLogin();
    }
  };

  handleLogin = () => {
    this.props.reset();

    this.setState({
      error_username: false,
      error_password: false,
      error: false
    });

    let error, error_username, error_password;

    if (this.state.username === "") {
      error = true;
      error_username = localize("user", "A felhasználónév kötelező");
    }
    if (this.state.password === "") {
      error = true;
      error_password = localize("user", "A jelszó kötelező");
    }

    if (error) {
      this.setState({
        error_username,
        error_password,
        error
      });
    } else {
      this.props.login(this.state.username, this.state.password);
    }
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.success && !this.props.success) {
      this.props.history.push("/");
    }
  };

  render() {
    const { classes, theme } = this.props;
    const { username, password } = this.state;

    let inputLabelProps = {
      className: classes.textFieldLabel
    };
    if (this.state.shouldShrink) {
      inputLabelProps.shrink = true;
    }
    return (
      <Grid
        container
        style={{
          background: "url(" + bg + ") center top",
          backgroundSize: "100% 100%",
          overflowY: "scroll"
        }}
        alignItems="center"
        justify="center"
      >
        <Helmet title={localize("user", "Bejelentkezés")} />
        <Grid item xs={12} md={8} lg={6}>
          <Grid
            container
            item
            alignItems="center"
            justify="center"
            className={classes.topLogoContainer}
          >
            <img className={classes.topLogo} src={logo2} alt="" title="" />
          </Grid>
          <Card>
            <Grid container>
              <Grid item xs={8} className={classes.leftPane}>
                <CardHeader title={localize("user", "Bejelentkezés")} />
                <CardContent>
                  <TextField
                    label={localize("user", "Felhasználónév")}
                    fullWidth
                    onChange={event =>
                      this.setState({ username: event.target.value })
                    }
                    defaultValue={username}
                    helperText={this.state.error_username || " "}
                    error={this.state.error_username ? true : false}
                    onKeyPress={this.handleInputKeypress}
                    autoFocus
                    inputRef={ref => (this.email = ref)}
                    InputLabelProps={inputLabelProps}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    inputProps={{ className: classes.textFieldInput }}
                  />
                  <TextField
                    type="password"
                    label={localize("user", "Jelszó")}
                    fullWidth
                    onChange={event =>
                      this.setState({ password: event.target.value })
                    }
                    defaultValue={password}
                    helperText={this.state.error_password || " "}
                    error={this.state.error_password ? true : false}
                    onKeyPress={this.handleInputKeypress}
                    inputRef={ref => (this.password = ref)}
                    InputLabelProps={inputLabelProps}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    inputProps={{ className: classes.textFieldInput }}
                  />
                  {this.props.failed && (
                    <Typography color="error">
                      {localize("user", "Hibás bejelentkezés")}
                    </Typography>
                  )}
                </CardContent>
                <CardActions>
                  <Button
                    disabled={this.props.loading}
                    onClick={() => {
                      this.handleLogin();
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {localize("basic", "Küldés")}
                  </Button>
                  {this.props.loading && <CircularProgress size={24} />}
                  <Link className={classes.link} to="/lost-password">
                    {localize("user", "Jelszó visszaállítás")}
                  </Link>
                </CardActions>
              </Grid>
              <Grid item xs={4} className={classes.rightPane}>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  className={classes.rightLogoContainer}
                >
                  <Grid item>
                    <Link to="/" className={classes.rightLogoLink}>
                      <img
                        className={classes.rightLogo}
                        src={logo}
                        alt=""
                        title=""
                      />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired
};

export default withStyles(loginStyle, { withTheme: true })(Login);
