import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';

class DeletePermission extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isAccess: false
    };
  }

  componentWillMount = () => {
    let isAccess = false;
    if(this.props.user.root) {
      isAccess = true;
    }
    else {
      const module = this.props.module;
      const perms = get(this.props.permissions, module, false);
      if(perms) {
        const access = get(perms, 'access');
        if(access.indexOf('DELETE') !== -1 ) {
          isAccess = true;
        }
        else if(access.indexOf('DELETE_OWN') !== -1 && this.props.isOwn ) {
          isAccess = true;
        }
      }
    }
    this.setState({
      isAccess
    });

  };

  render = () => {
    return <Fragment>{this.state.isAccess && this.props.children}</Fragment>
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    permissions: state.auth.permissions
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
      {

      },
      dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletePermission);
