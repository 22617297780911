import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { withRouter, Link } from "react-router-dom";
import localize from "helpers/localize";
import { withStyles } from "@material-ui/core/styles";
import restHelper from "helpers/aee/restHelper";
import requestHelper from "helpers/aee/requestHelper";
import DataProvider from "Components/New/DataProvider/DataProvider";
import DataTableDevExpress from "Components/New/DataTable/DataTableDevExpress/DataTableDevExpress";
import DataTable from "Components/New/DataTable/DataTable/DataTable";
import authRules from "helpers/authRules";
import QueueList from "./QueueList";
import QueueLogins from "./QueueLogins";

const LC = "cloud";

const styles = theme => ({
  /*"@global": {
    ".rbt-input-hint": {
      //backgroundColor: "red!important",
      width: "100%",
      top: "2px!important"
    }
  },*/
  rowDeleted: {
    backgroundColor: "red!important",
    color: "white"
  },
  rowChanged: {
    backgroundColor: "green!important",
    color: "white"
  },
  marginLeftAuto: {
    marginLeft: "auto"
  },
  marginLeft: {
    marginLeft: theme.spacing.unit
  }
});

class Queue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false
    };
  }

  componentDidMount() {
    console.log("Products.componentDidMount", this.props.match.params);
  }

  toggleEdit = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  customDelete = (row, restHelperOptions) => {
    const { cashregister_id, client_id } = this.props;
    return requestHelper({
      url: `cashregister-queue/delete?function=${row.function}`,
      method: "GET",
      //...restHelperOptions
      ap: cashregister_id || row.apn,
      client: client_id
    });
  };
  customView = (row, rowIndex) => {
    console.log("customView", row, rowIndex);
    const { cashregister_id, client_id } = this.props;
    requestHelper({
      url: `cashregister-queue/xml?function=${row.function}`,
      method: "GET",
      ap: cashregister_id || row.apn,
      client: client_id
    }).then(response => {
      //console.log("customView.response", response);
      if (response.data && response.data.data && response.data.data.xml) {
        let blob = new Blob(
          [
            `<?xml version="1.0" encoding="UTF-8"?><root>${
              response.data.data.xml
            }</root>`
          ],
          { type: "text/xml" }
        );
        let url = URL.createObjectURL(blob);
        window.open(url);
        URL.revokeObjectURL(url); //Releases the resources
      }
    });
  };

  render() {
    const { classes, cashregister_id, client_id } = this.props;
    const hash = this.props.history.location.hash || "#list";

    return (
      <div style={{ paddingTop: 8 }}>
        <Link to="#list" style={{ textDecoration: "none" }}>
          <Button
            size="small"
            variant="contained"
            color={hash === "#list" ? "primary" : "default"}
            style={{ margin: 8 }}
          >
            {localize(LC, "Várólista")}
          </Button>
        </Link>
        <Link to="#logins" style={{ textDecoration: "none" }}>
          <Button
            size="small"
            variant="contained"
            color={hash === "#aee-info" ? "primary" : "default"}
            style={{ margin: 8 }}
          >
            {localize(LC, "Bejelentkezések")}
          </Button>
        </Link>

        {hash === "#list" && (
          <QueueList client_id={client_id} cashregister_id={cashregister_id} />
        )}
        {hash === "#logins" && (
          <QueueLogins
            client_id={client_id}
            cashregister_id={cashregister_id}
          />
        )}
      </div>
    );
  }
}

Queue.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

export default withStyles(styles)(withRouter(Queue));
