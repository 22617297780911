import React, { Component } from "react";
import PropTypes from "prop-types";

//import { toastr } from "helpers/CustomToastr";
import CashregisterReadView from "./CashregisterReadView";
import Edit from "Components/Edit/Edit";
import Modal from "Components/Modal/Modal";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "@material-ui/core/Toolbar";
import localize from "helpers/localize";

class CashregisterRead extends Component {
  /*constructor(props) {
    super(props);
    this.state = {
      open: false
     };
  }*/

  componentWillUnmount = () => {
    this.endRead();
  };

  endRead = () => {
    this.props.deleteSubscriberReadId();
    this.props.endRead();
  };

  startRead = () => {
    this.props.read();
  };
  componentWillReceiveProps(nextProps) {
    /*if (!this.props.message && nextProps.message) {
      toastr.error(nextProps.message);
    }*/

    //On open
    if (this.props.open != nextProps.open && nextProps.open) {
      this.startRead();
    }
    //On close
    if (this.props.open != nextProps.open && !nextProps.open) {
      this.endRead();
    }
  }

  handleGetFormInfo = () => {
    return this.props.getCustomInfo("manual", "cashregisters");
  };
  render() {
    const { loading, open, onClose, message } = this.props;

    return (
      <Modal
        open={open}
        onClose={onClose}
        onAccept={onClose}
        disableIcon
        disableCancel
        title={localize("cashregisters", "Ügyféladat beolvasás")}
        maxWidth="lg"
      >
        {loading ? (
          <Toolbar>
            <CircularProgress size={16} style={{ marginRight: 16 }} />
            <Typography>
              {localize("cashregisters", "Várakozás az olvasásra")}
            </Typography>
          </Toolbar>
        ) : message ? (
          message
        ) : (
          <Edit
            isViewPage={true}
            handleGetFormInfo={this.handleGetFormInfo}
            {...this.props}
            disableCancel={true}
          >
            <CashregisterReadView {...this.props} isViewPage={true} />
          </Edit>
        )}
      </Modal>
    );
  }
}

CashregisterRead.propTypes = {
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool,
  message: PropTypes.string,
  getCustomInfo: PropTypes.func.isRequired,
  deleteSubscriberReadId: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  endRead: PropTypes.func.isRequired,
  read: PropTypes.func.isRequired
};

export default CashregisterRead;
