import axios from "axios";
import qs from "qs";
import { get } from "lodash";

const CLIENTS_LIST_LOAD = "CLIENTS_LIST_LOAD";
const CLIENTS_LIST_SUCCESS = "CLIENTS_LIST_SUCCESS";
const CLIENTS_LIST_FAILED = "CLIENTS_LIST_FAILED";
const CLIENTS_SET_LOADING = "CLIENTS_SET_LOADING";

const CLIENTS_FORM_LOAD = "CLIENTS_FORM_LOAD";
const CLIENTS_FORM_SUCCESS = "CLIENTS_FORM_SUCCESS";
const CLIENTS_FORM_FAILED = "CLIENTS_FORM_FAILED";

const CLIENT_DELETE_LOAD = "CLIENT_DELETE_LOAD";
const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
const CLIENT_DELETE_FAILED = "CLIENT_DELETE_FAILED";

const CLIENT_EDIT_LOAD = "CLIENT_EDIT_LOAD";
const CLIENT_EDIT_SUCCESS = "CLIENT_EDIT_SUCCESS";
const CLIENT_EDIT_FAILED = "CLIENT_EDIT_FAILED";

const CLIENT_VIEW_LOAD = "CLIENT_VIEW_LOAD";
const CLIENT_VIEW_SUCCESS = "CLIENT_VIEW_SUCCESS";
const CLIENT_VIEW_FAILED = "CLIENT_VIEW_FAILED";

const CLIENTS_RESET = "CLIENTS_RESET";
const CLIENTS_RESET_FORM = "CLIENTS_RESET_FORM";

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit: process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: "",
  orderMode: "ASC",
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null,
  reload: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLIENTS_RESET:
      return {
        ...initialState
      };
    case CLIENTS_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case CLIENT_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case CLIENTS_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false,
        reload: false
      };
    case CLIENT_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case CLIENT_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case CLIENTS_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case CLIENTS_FORM_FAILED:
    case CLIENT_EDIT_FAILED:
    case CLIENT_DELETE_FAILED:
    case CLIENTS_LIST_FAILED:
    case CLIENT_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false
      };
    case CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode,
        loading: false
      };
    case CLIENT_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        viewData: action.viewData,
        commentThread: action.commentThread || false,
        viewLoading: false
      };
    case CLIENTS_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case CLIENT_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case CLIENT_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: action.id,
        viewLoading: false
      };
    case CLIENTS_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: CLIENTS_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: CLIENTS_RESET_FORM
  });
};

export const updateClients = (model, id, data) => dispatch => {
  dispatch({
    type: CLIENT_EDIT_LOAD
  });

  //numberformat hack
  if (data && data.phone2 === "__-__-_______") {
    data.phone2 = null;
  }
  let d = Object.assign({}, data);
  delete d.created_at;
  delete d.updated_at;
  let obj = {};
  obj["ClientForm"] = d;

  return axios
    .put("/v1/clients/" + id, obj)
    .then(response => {
      dispatch({
        type: CLIENT_EDIT_SUCCESS
      });
      return response.data;
    })
    .catch(error => {
      dispatch({
        type: CLIENT_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const viewClients = id => dispatch => {
  dispatch({
    type: CLIENT_VIEW_LOAD
  });
  return axios
    .get("/v1/clients/" + id)
    .then(response => {
      dispatch({
        type: CLIENT_VIEW_SUCCESS,
        viewData: get(response, "data.data"),
        commentThread: get(response, "data.commentThread", false)
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CLIENT_VIEW_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const createClients = (model, data) => dispatch => {
  dispatch({
    type: CLIENT_EDIT_LOAD
  });
  //numberformat hack
  if (data && data.phone2 === "__-__-_______") {
    data.phone2 = null;
  }
  let obj = {};
  obj[model] = data;
  return axios
    .post("/v1/clients", obj)
    .then(response => {
      dispatch({
        type: CLIENT_EDIT_SUCCESS,
        id: get(response, "data.data.id", null)
      });
      return get(response, "data.data", null);
    })
    .catch(error => {
      dispatch({
        type: CLIENT_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const clientDelete = id => dispatch => {
  dispatch({
    type: CLIENT_DELETE_LOAD
  });
  return axios
    .delete("/v1/clients/" + id)
    .then(response => {
      dispatch({
        type: CLIENT_DELETE_SUCCESS,
        id
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CLIENT_DELETE_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: CLIENTS_SET_LOADING,
    loading
  });
};

export const clientsList = (
  page,
  keyword,
  sort,
  filters,
  perPage
) => dispatch => {
  dispatch({
    type: CLIENTS_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    "per-page": perPage
  };

  params[filters.model] = filters.filters;

  return axios
    .get(
      "/v1/clients",
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then(response => {
      let sort = get(response, "data.sort", null),
        mode = "ASC";
      if (sort && sort.indexOf("-") !== -1) {
        mode = "DESC";
        sort = sort.substr(1);
      }
      dispatch({
        type: CLIENTS_LIST_SUCCESS,
        data: get(response, "data.data", null),
        page: get(response, "data.page", 0),
        limit: get(response, "data.per-page", 0),
        total: get(response, "data.total", 0),
        order: sort,
        orderMode: mode
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: CLIENTS_LIST_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};
