import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ServiceOrdersList from "./views/ServiceOrdersList";
import * as serviceOrdersActions from "../../../redux/modules/service-orders";
import * as optionsActions from "../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.serviceOrders.loading,
    success: state.serviceOrders.success,
    message: state.serviceOrders.message,
    data: state.serviceOrders.data,
    total: state.serviceOrders.total,
    limit: state.serviceOrders.limit,
    page: state.serviceOrders.page,
    order: state.serviceOrders.order,
    orderMode: state.serviceOrders.orderMode,
    deleted: state.serviceOrders.deleted,
    deletedId: state.serviceOrders.deletedId,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: serviceOrdersActions.OrdersList,
      delete: serviceOrdersActions.OrdersDelete,
      setLoading: serviceOrdersActions.setLoading,
      downloadFile: serviceOrdersActions.downloadFile,
      reset: serviceOrdersActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceOrdersList);
