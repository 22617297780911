import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";
import PropTypes from "prop-types";
import NotInterested from "@material-ui/icons/NotInterested";
import Done from "@material-ui/icons/Done";
import EditForm from "../../../../../Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import Image from "../../../../../Components/Image/Image";
import { Typography } from "@material-ui/core";

import { get, values } from "lodash";
import localize from "helpers/localize";

class ExamForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    viewData: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  render() {
    let questions = get(this.props, "viewData.category_questions", null);
    questions = values(questions);

    return (
      <EditForm {...this.props} disabledEditButton={true}>
        {this.props.viewData && (
          <div className="col-12">
            <Typography>
              {localize("exam", "Kategória név: ")}{" "}
              {this.props.viewData.category_name}
            </Typography>
            <Typography>
              {localize("exam", "Vizsga elfogadás dátuma: ")}{" "}
              {this.props.viewData.exam_apply_datetime}
            </Typography>
            <Typography>
              {localize("exam", "Vizsga dátum: ")}{" "}
              {this.props.viewData.exam_date}
            </Typography>
            <Typography>
              {localize("exam", "Végeredmény: ")}{" "}
              {this.props.viewData.exam_result}
            </Typography>
          </div>
        )}
        <div className="col-12">
          {questions &&
            questions.map((question, index) => {
              const icon =
                parseInt(question.answer, 10) ===
                parseInt(question.solution, 10) ? (
                  <Done />
                ) : (
                  <NotInterested />
                );
              const _answer = this.props.viewData.form_values.find(
                value => value.question_id === question.id
              );
              const incorrectAnswer =
                question.answers[parseInt(_answer.answer, 10)];

              return (
                <div className="col-4" key={index}>
                  <Fragment>
                    <div>
                      <b>{question.title}</b>
                    </div>
                    {question.image && <Image image={question.image} />}
                    {question.answers &&
                      question.answers.map((answer, index) => {
                        return (
                          <div key={index} className={"col-12 col-md-12"}>
                            {index == question.solution && (
                              <div>
                                {localize("exam", "Válasz")}:
                                {_answer &&
                                  _answer.answer == question.solution && (
                                    <span style={{ color: "green" }}>
                                      {" "}
                                      {answer} ({localize("exam", "helyes")})
                                    </span>
                                  )}
                                {_answer &&
                                  _answer.answer != question.solution && (
                                    <span style={{ color: "red" }}>
                                      {" "}
                                      {incorrectAnswer} (
                                      {localize("exam", "helytelen")})
                                    </span>
                                  )}
                                {!_answer && (
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    ({localize("exam", "nincs válasz")})
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </Fragment>
                </div>
              );
            })}
        </div>
      </EditForm>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "examinee",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(ExamForm);

export default connect(
  state => ({
    initialValues: state.exams.viewData
  }),
  null
)(UsersReduxForm);
