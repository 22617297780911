import axios from 'axios';
import { get } from 'lodash';

const CRULES_GRID_SETTING_LOAD = 'CRULES_GRID_SETTING_LOAD';
const CRULES_GRID_SETTING_SUCCESS = 'CRULES_GRID_SETTING_SUCCESS';
const CRULES_GRID_SETTING_FAILED = 'CRULES_GRID_SETTING_FAILED';

const CRULES_FORM_SETTINGS_LOAD = 'CRULES_FORM_SETTINGS_LOAD';
const CRULES_FORM_SETTING_SUCCESS = 'CRULES_FORM_SETTING_SUCCESS';
const CRULES_FORM_SETTING_FAILED = 'CRULES_FORM_SETTING_FAILED';

const CRULES_FORM_SETTINGS_SAVING = 'CRULES_FORM_SETTINGS_SAVING';
const CRULES_FORM_SETTING_SAVED = 'CRULES_FORM_SETTING_SAVED';
const CRULES_FORM_SETTING_FAILURE = 'CRULES_FORM_SETTING_FAILURE';

const CRULES_OPTIONS_RESET = 'CRULES_OPTIONS_RESET';

const initialState = {
  saving: false,
  saved_success: false,
  saved_failed: false,

  loading: false,
  success: false,
  failed: false,
  message: false,
  formErrors: {},
  fields: [],
  baseFields: {},
    defaultValues: {},
  enabledFields: false,

  columns: {},
  columnsOrder: [],
  attachments: {},
  translate: '',
  searchModel: '',
  headerLoaded: false,


  model: ''

};

export default (state = initialState, action) => {
  switch (action.type) {
    case CRULES_OPTIONS_RESET:
      return {
          ...initialState
      };
    case CRULES_FORM_SETTINGS_SAVING:
      return {
          ...state,
        saving: true,
        saved_success: false,
        saved_failed: false,
        message: false,
        formErrors: {}
      };
    case CRULES_FORM_SETTINGS_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        success: false,
        message: false,
        formErrors: {}
      };
    case CRULES_FORM_SETTING_FAILURE:
      return {
        ...state,
        saving: false,
        saved_success: false,
        saved_failed: true,
        message: action.message,
        formErrors: action.formErrors
      };
    case CRULES_GRID_SETTING_LOAD:
      return {
        ...state,
        headerLoaded: false
      };
    case CRULES_GRID_SETTING_FAILED:
    case CRULES_FORM_SETTING_FAILED:
      return {
        ...state,
        headerLoaded: true,
        loading: false,
        success: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors
      };
      case CRULES_FORM_SETTING_SAVED:
      return {
        ...state,
        saving: false,
        saved_success: true,
        saved_failed: false,
        fields: action.fields,
        baseFields: action.baseFields,
        enabledFields: action.enabledFields,
        model: action.model
      };
    case CRULES_FORM_SETTING_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        baseFields: action.baseFields,
        enabledFields: action.enabledFields,
          defaultValues: action.defaultValues,
        model: action.model,
        loading: false,
        success: true,
        failed: false
      };
    case CRULES_GRID_SETTING_SUCCESS:
      return {
        ...state,
        columns: action.columns,
        columnsOrder: action.columnsOrder,
        translate: action.translate,
        searchModel: action.searchModel,
        attachments: action.attachments,
        headerLoaded: true
      };

    default:
      return state;
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: CRULES_OPTIONS_RESET
  });
};

// export const saveFormInfo = (form, fields) => dispatch => {
//   dispatch({
//     type: CRULES_FORM_SETTINGS_SAVING
//   });
//   return axios.patch(`/v1/${form}/options?component=form`, fields)
//     .then((response) =>  dispatch({
//       type: CRULES_FORM_SETTING_SAVED,
//       fields: get(response, 'data.data.form.fields', []),
//       baseFields: get(response, 'data.data.form.baseFields', {}),
//       model: get(response, 'data.data.form.model', '')
//     })).catch(error => dispatch({
//       type: CRULES_FORM_SETTING_FAILURE,
//       message: get(error, 'response.data.message', null)
//     }));
// };

export const getCustomInfo = (option,form, params = '') => dispatch => {
    dispatch({
        type: CRULES_FORM_SETTINGS_LOAD
    });
    return axios.get(`/v1/${form}/options?component=${option}${params}`)
        .then((response) =>  dispatch({
            type: CRULES_FORM_SETTING_SUCCESS,
            fields: get(response, `data.data.${option}.fields`, []),
            baseFields: get(response, `data.data.${option}.baseFields`, {}),
            defaultValues: get(response, `data.data.${option}.defaultValues`, {}),
            enabledFields: get(response, `data.data.${option}.enabledFields`, false),
            model: get(response, `data.data.${option}.model`, [])
        })).catch(error => dispatch({
            type: CRULES_FORM_SETTING_FAILED,
            message: get(error, 'response.data.message', null)
        }));
};

export const getFormInfo = (form, params = '') => dispatch => {
  dispatch({
    type: CRULES_FORM_SETTINGS_LOAD
  });
  return axios.get(`/v1/${form}/options?component=form${params}`)
    .then((response) =>  dispatch({
      type: CRULES_FORM_SETTING_SUCCESS,
      fields: get(response, 'data.data.form.fields', []),
      baseFields: get(response, 'data.data.form.baseFields', {}),
        defaultValues: get(response, 'data.data.form.defaultValues', {}),
      enabledFields: get(response, 'data.data.form.enabledFields', false),
      model: get(response, 'data.data.form.model', [])
    })).catch(error => dispatch({
      type: CRULES_FORM_SETTING_FAILED,
      message: get(error, 'response.data.message', null)
    }));
};

export const getGridInfo = (grid, params = '') => dispatch => {
  dispatch({
    type: CRULES_GRID_SETTING_LOAD
  });
  return axios.get(`/v1${grid}/options?component=grid${params}`)
  .then((response) =>  dispatch({
    type: CRULES_GRID_SETTING_SUCCESS,
    columns: get(response, 'data.data.grid.columns', {}),
    columnsOrder: get(response, 'data.data.grid.columnsOrder', {}),
    translate: get(response, 'data.data.grid.translationCategory', ''),
    searchModel: get(response, 'data.data.grid.model', ''),
  })).catch(error => dispatch({
    type: CRULES_GRID_SETTING_FAILED,
    message: get(error, 'response.data.message', null)
  }));
};

export const getAllHeaderOptions = (uri, key) => dispatch => {
  dispatch({
    type: CRULES_GRID_SETTING_LOAD
  });

  return axios.get(`/v1${uri}/options`, {})
    .then((response) =>  dispatch({
      type: CRULES_GRID_SETTING_SUCCESS,
      columns: get(response, `data.data.${key}.columns`, {}),
      columnsOrder: get(response, `data.data.${key}.columnsOrder`, {}),
      translate: get(response, `data.data.${key}.translationCategory`, ''),
      searchModel: get(response, `data.data.${key}.model`, ''),
      attachments: get(response, `data.data.attachments`, {}),
    })).catch(error => dispatch({
      type: CRULES_GRID_SETTING_FAILED,
      message: get(error, 'response.data.message', null)
    }));
}