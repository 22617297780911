import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ChangesList from "./views/ChangesList";
import * as changesActions from "redux/modules/service-changes";
import * as optionsChanges from "redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.serviceChanges.loading,
    success: state.serviceChanges.success,
    message: state.serviceChanges.message,
    data: state.serviceChanges.data,
    total: state.serviceChanges.total,
    limit: state.serviceChanges.limit,
    page: state.serviceChanges.page,
    order: state.serviceChanges.order,
    orderMode: state.serviceChanges.orderMode,
    deleted: state.serviceChanges.deleted,
    deletedId: state.serviceChanges.deletedId,
    reload: state.serviceChanges.reload,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsChanges.getGridInfo,
      optionsReset: optionsChanges.reset,
      getList: changesActions.changesList,
      delete: changesActions.changesDelete,
      setLoading: changesActions.setLoading,
      reset: changesActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangesList);
