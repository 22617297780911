import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import IncomingSmsList from "./views/IncomingSmsList";
import * as incomingSmsActions from "../../../../redux/modules/incoming-sms";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.incomingSms.loading,
    success: state.incomingSms.success,
    message: state.incomingSms.message,
    data: state.incomingSms.data,
    total: state.incomingSms.total,
    limit: state.incomingSms.limit,
    page: state.incomingSms.page,
    order: state.incomingSms.order,
    orderMode: state.incomingSms.orderMode,
    deleted: state.incomingSms.deleted,
    deletedId: state.incomingSms.deletedId,
    token: state.incomingSms.token,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: incomingSmsActions.incomingSmsList,
      delete: incomingSmsActions.incomingSmsDelete,
      setLoading: incomingSmsActions.setLoading,
      reset: incomingSmsActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncomingSmsList);
