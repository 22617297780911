import { toastr as toastrR } from "react-redux-toastr";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
export const toastr = {
  error(title, message, options) {
    const defaultOptions = {
      timeOut: 0,
      //showCloseButton: false,
      position: "top-center"
    };
    const customOptions = Object.assign(
      {},
      options ? options : {},
      defaultOptions
    );
    toastrR.error(title, message, customOptions);
  },

  errorOriginal(title, message, options) {
    toastrR.error(title, message, options);
  },

  success(title, message, options) {
    globalMessage.success(title);
    //toastrR.success(title, message, options);
  },

  warning(title, message, options) {
    toastrR.warning(title, message, options);
  },

  info(title, message, options) {
    toastrR.info(title, message, options);
  }
};
