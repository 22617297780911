import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { withRouter, Link } from "react-router-dom";
import AeeTest from "./AeeTest";
import AeeInfo from "./AeeInfo";
import AeeUnblock from "./AeeUnblock";
import AeeLastLogin from "./AeeLastLogin";
import localize from "helpers/localize";
import {
  CLOUD_CASHREGISTER_AEEUNBLOCK,
  CLOUD_CASHREGISTER_ACCOUNTING
} from "constans/permissions";
import authRules from "helpers/authRules";
const LC = "cloud"; //Localization category

class Aee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { cashregister_id, client_id } = this.props;

    const hash = this.props.history.location.hash || "#aee-test";
    return (
      <div style={{ paddingTop: 8 }}>
        <Link to="#aee-test" style={{ textDecoration: "none" }}>
          <Button
            size="small"
            variant="contained"
            color={hash === "#aee-test" ? "primary" : "default"}
            style={{ margin: 8 }}
          >
            {localize(LC, "Teszt")}
          </Button>
        </Link>
        <Link to="#aee-info" style={{ textDecoration: "none" }}>
          <Button
            size="small"
            variant="contained"
            color={hash === "#aee-info" ? "primary" : "default"}
            style={{ margin: 8 }}
          >
            {localize(LC, "Eszköz info")}
          </Button>
        </Link>
        {authRules.canViewByKey(CLOUD_CASHREGISTER_AEEUNBLOCK) && (
          <Link to="#aee-unblock" style={{ textDecoration: "none" }}>
            <Button
              size="small"
              variant="contained"
              color={hash === "#aee-unblock" ? "primary" : "default"}
              style={{ margin: 8 }}
            >
              {localize(LC, "Blokkolás feloldás")}
            </Button>
          </Link>
        )}
        <Link to="#aee-lastlogin" style={{ textDecoration: "none" }}>
          <Button
            size="small"
            variant="contained"
            color={hash === "#aee-lastlogin" ? "primary" : "default"}
            style={{ margin: 8 }}
          >
            {localize(LC, "Utolsó bejelentkezés")}
          </Button>
        </Link>
        {hash === "#aee-test" && (
          <AeeTest client_id={client_id} cashregister_id={cashregister_id} />
        )}
        {hash === "#aee-info" && (
          <AeeInfo client_id={client_id} cashregister_id={cashregister_id} />
        )}
        {hash === "#aee-unblock" &&
          authRules.canViewByKey(CLOUD_CASHREGISTER_ACCOUNTING) && (
            <AeeUnblock
              client_id={client_id}
              cashregister_id={cashregister_id}
            />
          )}
        {hash === "#aee-lastlogin" && (
          <AeeLastLogin
            client_id={client_id}
            cashregister_id={cashregister_id}
          />
        )}
      </div>
    );
  }
}

Aee.propTypes = {
  classes: PropTypes.object,
  //history: PropTypes.object.isRequired,
  //match: PropTypes.object.isRequired,
  cashregister_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

export default withRouter(Aee);
