import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";
import InputField from "Components/Forms/Fields/Input";
import SelectField from "Components/Forms/Fields/Select";
import Checkbox from "Components/Forms/Fields/Checkbox";
import Wysiwyg from "Components/Forms/Fields/Wysiwyg";
import Validations from "Components/Forms/Validations";
import EditForm from "Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import localize from "helpers/localize";

class ArticlesForm extends Component {
  render() {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      enabledFields,
      baseFields
    } = this.props;

    let statuses = [];
    Object.keys(get(this.props, "baseFields.status.data", {})).forEach(key => {
      statuses.push({ id: key, label: baseFields.status.data[key] });
    });

    return (
      <EditForm {...this.props}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Field
              name="title"
              label={localize("articles", "Cím")}
              fieldError={fieldErrors.title}
              onChange={() => handleInputChange("title")}
              isRequired={true}
              validate={[Validations.required]}
              component={InputField}
              type="text"
              placeholder={localize("articles", "Cím")}
              isViewPage={isViewPage}
              readOnly={
                enabledFields
                  ? enabledFields.indexOf("title") !== -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Field
              name="status"
              label={localize("articles", "Státusz")}
              fieldError={fieldErrors.status}
              onChange={() => handleInputChange("status")}
              component={SelectField}
              placeholder={localize("articles", "Státusz")}
              isRequired={true}
              validate={[Validations.required]}
              isViewPage={isViewPage}
              options={statuses}
              readOnly={
                enabledFields
                  ? enabledFields.indexOf("status") !== -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Field
              name="user_can_see"
              label={localize("articles", "Felhasználó láthatja")}
              fieldError={fieldErrors.user_can_see}
              onChange={() => handleInputChange("user_can_see")}
              isRequired={true}
              component={Checkbox}
              isViewPage={isViewPage}
              readOnly={
                enabledFields
                  ? enabledFields.indexOf("user_can_see") !== -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Field
              name="distributor_can_see"
              label={localize("articles", "viszonteladó láthatja")}
              fieldError={fieldErrors.distributor_can_see}
              onChange={() => handleInputChange("distributor_can_see")}
              isRequired={true}
              component={Checkbox}
              isViewPage={isViewPage}
              readOnly={
                enabledFields
                  ? enabledFields.indexOf("distributor_can_see") !== -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="summary"
              label={localize("articles", "Összefoglaló")}
              fieldError={fieldErrors.summary}
              onChange={() => handleInputChange("summary")}
              component={Wysiwyg}
              placeholder={localize("articles", "Összefoglaló")}
              isViewPage={isViewPage}
              isWysiwyg={true}
              key={"summary" + isViewPage}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="text"
              label={localize("articles", "Teljes szöveg")}
              fieldError={fieldErrors.text}
              onChange={() => handleInputChange("text")}
              component={Wysiwyg}
              placeholder={localize("articles", "Teljes szöveg")}
              isViewPage={isViewPage}
              isWysiwyg={true}
              key={"text" + isViewPage}
            />
          </Grid>
        </Grid>
      </EditForm>
    );
  }
}

ArticlesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  isViewPage: PropTypes.bool,
  enabledFields: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  baseFields: PropTypes.object
};

const UsersReduxForm = reduxForm({
  form: "articles",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(ArticlesForm);

export default connect(
  state => ({
    initialValues: state.articles.viewData
  }),
  null
)(UsersReduxForm);
