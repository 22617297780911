import React, { PureComponent } from "react";

import Validations from "Components/Forms/Validations";
import { Field } from "redux-form";
import InputField from "Components/Forms/Fields/Input";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Typography from "@material-ui/core/Typography/Typography";
import SelectField from "Components/Forms/Fields/Select";
import Autocomplete from "Components/Forms/Fields/Autocomplete";
import { get } from "lodash";
import Popover from "@material-ui/core/Popover";
import Popper from "@material-ui/core/Popper";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import Image from "Components/New/Image";

class ProductItem extends PureComponent {
  state = {
    anchorEl: null,
    prices: null,
    qty: null
  };

  getPrices = (prices, baseQty) => {
    const qty = !baseQty || baseQty === "" || isNaN(baseQty) ? 0 : baseQty;
    let priceIndex = 0;

    for (let i in prices) {
      if (prices[i].from > qty) {
        break;
      } else {
        priceIndex = i;
      }
    }
    return prices && prices[priceIndex] ? prices[priceIndex].price : null;
  };

  changePrice = price => {
    const { dispatch, change, collector } = this.props;
    dispatch(change(`${collector}.price`, parseInt(price)));
  };
  changeQty = qty => {
    const { dispatch, change, collector } = this.props;
    dispatch(change(`${collector}.quantity`, parseInt(qty)));
  };

  onSelect = (label, name, data) => {
    const { qty } = this.state;
    if (data) {
      this.setState({ prices: data.prices });
      const price = this.getPrices(data.prices, qty || 1);
      this.changePrice(price);
    } else {
      this.setState({ prices: null });
    }
  };

  onQuantityChange = evt => {
    const { prices } = this.state;
    const qty = evt.target.value ? parseInt(evt.target.value) : 1;
    this.setState({ qty }, () => {
      this.changeQty(qty);
      if (prices) {
        const price = this.getPrices(prices, qty);
        this.changePrice(price);
      }
    });
  };

  handlePopoverOpen = event => {
    //console.log("hover", event.currentTarget);
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      isViewPage,
      fieldError,
      index,
      collector,
      products,
      deleteCell,
      shop_product_units,
      shop_product_id,
      shop_products,
      shop_product,
      worksheetId
    } = this.props;
    const { anchorEl } = this.state;
    const productUrlParams = get(shop_product, "url", null);
    if (productUrlParams) {
      productUrlParams.params = { worksheetId: worksheetId };
    }
    return (
      <TableRow>
        <TableCell style={{ overflow: "visible" }}>
          {/*<Field
            name={`${collector}.shop_product_id`}
            fieldError={fieldError.shop_product_id}
            component={SelectField}
            isViewPage={isViewPage}
            validate={[Validations.required]}
            options={shop_products}
            //viewShowKey
          />*/}
          {
            <Field
              name={`${collector}.name`}
              fieldError={fieldError.products}
              component={Autocomplete}
              isViewPage={isViewPage}
              validate={[Validations.required]}
              renderMenuItemChildren={(option, props) => {
                return (
                  <ListItem
                    component="div"
                    style={{
                      marginLeft: "-1.5rem",
                      marginRight: "-1.5rem",
                      marginTop: "-0.25rem",
                      marginBottom: "-0.25rem",
                      textDecoration: "none!important"
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Image
                          fileId={option.image_id}
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.name}
                      secondary={
                        option.tags && Array.isArray(option.tags)
                          ? option.tags.join(", ")
                          : undefined
                      }
                    />
                  </ListItem>
                );
              }}
              formName="worksheet"
              //onChange={() => handleInputChange("distributor_id")}
              onSelect={this.onSelect}
              mode="select"
              minChars={0} //{get(workfee, "minChars", 2)}
              loadOnFocus
              showInTable
              fillDefaultValue
              url={productUrlParams}
              //change={this.props.change}
              labelKey="name"
              valueKey="name"
              //onSelect={this.handleDistributorSelect}
            />
          }
        </TableCell>
        {/*<TableCell>
          <Field
            name={`${collector}.unit`}
            fieldError={fieldError.product}
            component={SelectField}
            isViewPage={isViewPage}
            validate={[Validations.required]}
            options={shop_product_units}
            //viewShowKey
          />
        </TableCell>*/}
        <TableCell>
          <Field
            name={`${collector}.quantity`}
            fieldError={fieldError.qty}
            component={InputField}
            onChange={this.onQuantityChange}
            type="number"
            isViewPage={isViewPage}
            validate={[Validations.required]}
            inputRootClass={"simpleImputRoot"}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${collector}.price`}
            fieldError={fieldError.price}
            component={InputField}
            type="number"
            isViewPage={isViewPage}
            validate={[Validations.required]}
            inputRootClass={"simpleImputRoot"}
          />
        </TableCell>
        {deleteCell(index)}
      </TableRow>
    );
  }
}

export default ProductItem;
