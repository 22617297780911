import * as _ from "lodash";

const authRules = {
  permissions: {},
  user: {},
  isRoot: false,
  setIsRoot: isRoot => {
    authRules.isRoot = isRoot;
  },
  setUser: user => {
    authRules.user = user;
  },
  getUser: () => {
    return authRules.user;
  },
  roleIn: roles => {
    if (!authRules.user) {
      return false;
    }
    if (!roles) {
      return false;
    }

    return roles.indexOf(authRules.user.role) >= 0;
  },
  getIsRoot: () => {
    return authRules.isRoot;
  },
  setPermissions: permissions => {
    authRules.permissions = permissions;
  },
  getPermissions: () => {
    return authRules.permissions;
  },
  hasPermissionByKeyAndName: (key, permissionName) => {
    if (authRules.isRoot) {
      return true;
    }

    const relevantPermission = _.get(
      authRules.permissions,
      key + ".permissions",
      null
    );

    return (
      relevantPermission && relevantPermission.indexOf(permissionName) > -1
    );
  },
  can: (key, rule) => {
    if (authRules.isRoot) {
      return true;
    }

    const relevantAccess = _.get(authRules.permissions, key + ".access", null);

    return relevantAccess && relevantAccess.indexOf(rule) > -1;
  },
  canModifyByKey: key => {
    return authRules.can(key, "MODIFY");
  },
  canDeleteByKey: key => {
    return authRules.can(key, "DELETE");
  },
  canViewByKey: key => {
    return authRules.can(key, "VIEW");
  },
  canViewByKeys: keys => {
    let hasAny = false;
    for (let i in keys) {
      const key = keys[i];
      if (authRules.can(key, "VIEW")) {
        hasAny = true;
        break;
      }
    }
    return hasAny;
  }
};

export default authRules;
