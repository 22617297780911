import React from "react";
import PropTypes from "prop-types";
import localize from "helpers/localize";
import PageMenu from "Components/New/Menu/PageMenu/PageMenu";
import PageMenuContent from "Components/New/Menu/PageMenu/PageMenuContent";
import Catalog from "./Catalog/Catalog";
import Cart from "./Cart/Cart";
import Orders from "./Orders";
import AdminProducts from "./Admin/AdminProducts";
import AdminOrders from "./Admin/AdminOrders";
import AdminTags from "./Admin/AdminTags";
import AdminProductCustomPrices from "./Admin/AdminProductCustomPrices";
import AdminMenu from "./Admin/AdminMenu";
import * as shopPath from "./shopPath";
import authrules from "helpers/authRules";
import {
  SHOP_ADMIN_MENU_PERMISSION,
  SHOP_ADMIN_ORDERS_PERMISSION,
  SHOP_ADMIN_PRICES_PERMISSION,
  SHOP_ADMIN_PRODUCTS_PERMISSION,
  SHOP_ADMIN_TAGS_PERMISSION
} from "constans/permissions";
const LC = "shop"; //Localization category

class Cloud extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //console.log("Shop.render", this.props.match.params);
    //const { menu } = this.props.match.params;
    return (
      <div style={{ position: "relative" }}>
        <PageMenu
          basePath={shopPath.PATH_BASE}
          onChange={this.handleTabChange}
          defaultPath={shopPath.PATH_CATALOG}
          color="primary"
        >
          <PageMenuContent
            path={shopPath.PATH_CATALOG}
            title={localize(LC, "Katalógus")}
          >
            <Catalog history={this.props.history} />
          </PageMenuContent>
          <PageMenuContent
            path={shopPath.PATH_CART}
            title={localize(LC, "Kosár")}
          >
            <Cart />
          </PageMenuContent>
          <PageMenuContent
            path={shopPath.PATH_ORDERS}
            title={localize(LC, "Rendeléseim")}
          >
            <Orders />
          </PageMenuContent>
          {authrules.canViewByKey(SHOP_ADMIN_PRODUCTS_PERMISSION) && (
            <PageMenuContent
              path={shopPath.PATH_ADMIN_PRODUCTS}
              title={localize(LC, "Admin termékek")}
            >
              <AdminProducts />
            </PageMenuContent>
          )}
          {authrules.canViewByKey(SHOP_ADMIN_ORDERS_PERMISSION) && (
            <PageMenuContent
              path={shopPath.PATH_ADMIN_ORDERS}
              title={localize(LC, "Admin rendelések")}
            >
              <AdminOrders />
            </PageMenuContent>
          )}
          {authrules.canViewByKey(SHOP_ADMIN_TAGS_PERMISSION) && (
            <PageMenuContent
              path={shopPath.PATH_ADMIN_TAGS}
              title={localize(LC, "Admin címkék")}
            >
              <AdminTags />
            </PageMenuContent>
          )}
          {authrules.canViewByKey(SHOP_ADMIN_PRICES_PERMISSION) && (
            <PageMenuContent
              path={shopPath.PATH_ADMIN_CUSTOM_PRICES}
              title={localize(LC, "Admin egyedi árak")}
            >
              <AdminProductCustomPrices />
            </PageMenuContent>
          )}
          {authrules.canViewByKey(SHOP_ADMIN_MENU_PERMISSION) && (
            <PageMenuContent
              path={shopPath.PATH_ADMIN_MENU}
              title={localize(LC, "Admin menü")}
            >
              <AdminMenu />
            </PageMenuContent>
          )}
        </PageMenu>
      </div>
    );
  }
}

Cloud.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default Cloud;
