import axios from "axios";
import { get, values } from "lodash";

const CART_FETCH_LOAD = "CART_FETCH_LOAD2";
const CART_FETCH_SUCCESS = "CART_FETCH_SUCCESS2";
const CART_FETCH_FAILED = "CART_FETCH_FAILED2";

const CART_CREATE_LOAD = "CART_CREATE_LOAD2";
const CART_CREATE_SUCCESS = "CART_CREATE_SUCCESS2";
const CART_CREATE_FAILED = "CART_CREATE_FAILED2";

const CART_UPDATE_LOAD = "CART_UPDATE_LOAD2";
const CART_UPDATE_SUCCESS = "CART_UPDATE_SUCCESS2";
const CART_UPDATE_FAILED = "CART_UPDATE_FAILED2";

const CART_REMOVE_LOAD = "CART_REMOVE_LOAD2";
const CART_REMOVE_SUCCESS = "CART_REMOVE_SUCCESS2";
const CART_REMOVE_FAILED = "CART_REMOVE_FAILED2";

const CART_CLEAR = "CART_CLEAR2";

const initialState = {
  loading: false,
  success: true,
  message: null,
  items: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Fetch
    case CART_FETCH_LOAD:
      return {
        ...state,
        loading: true
      };
    case CART_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: null,
        items: action.items
      };
    case CART_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.message
      };
    //Create
    case CART_CREATE_LOAD:
      return {
        ...state,
        loading: true
      };
    case CART_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: null,
        items: state.items.slice().push(action.item)
      };
    case CART_CREATE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.message
      };
    //update
    case CART_UPDATE_LOAD:
      return {
        ...state,
        loading: true
      };
    case CART_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: null,
        items: state.items.map(element =>
          element.id === action.item.id ? { ...action.item } : { ...element }
        )
      };
    case CART_UPDATE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.message
      };
    //remove
    case CART_REMOVE_LOAD:
      return {
        ...state,
        loading: true
      };
    case CART_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: null,
        items: state.items.filter(element => element.id === action.item.id)
      };
    case CART_REMOVE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.message
      };
    //clear
    case CART_CLEAR:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export const update = item => dispatch => {
  const _url = "/v1/shop/cart";

  dispatch({
    type: CART_UPDATE_LOAD
  });
  return axios
    .post(_url, {
      CartForm: {
        id: item.id,
        quantity: item.quantity
      }
    })
    .then(response => {
      const cart = get(response, "data.data", {});
      const cartItems = values(cart);

      dispatch({
        type: CART_UPDATE_SUCCESS,
        cartItems,
        loading: false
      });

      return response.data.data;
    })
    .catch(error => {
      dispatch({
        type: CART_UPDATE_FAILED,
        totalCartItems: 0,
        loading: false,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const remove = id => dispatch => {
  dispatch({
    type: CART_REMOVE_LOAD
  });
  return axios
    .delete(`/v1/shop/cart-remove/${id}`)
    .then(response => {
      const cartItems = get(response, "data.data", []);

      dispatch({
        type: CART_FETCH_SUCCESS,
        totalCartItems: response.data.total,
        cartItems: cartItems
      });

      return response.data.data;
    })
    .catch(error => {
      dispatch({
        type: CART_FETCH_FAILED,
        totalCartItems: 0,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const getCartItems = () => dispatch => {
  const _url = "/v1/shop/cart";

  dispatch({
    type: CART_FETCH_LOAD
  });
  return axios
    .get(_url)
    .then(response => {
      const cart = get(response, "data.data", {});
      const cartItems = values(cart);

      dispatch({
        type: CART_FETCH_SUCCESS,
        totalCartItems: cartItems.length,
        cartItems: cartItems
      });

      return response.data.data;
    })
    .catch(error => {
      dispatch({
        type: CART_FETCH_FAILED,
        totalCartItems: 0,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};
export const order = userOrderData => dispatch => {
  const _url = "/v1/shop/order";

  return axios
    .post(_url, {
      ShopOrder: userOrderData
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => {
      throw error.response;
    });
};
