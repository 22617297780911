import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";
import localize from "helpers/localize";

/*import {
  WORKFEES_PERMISSION,
  WORKSHEETS_PERMISSION
} from "../../../constans/permissions";
import MenuPermission from "../../Permissions/MenuPermission";*/

const TabLink = props => {
  const { value, label, ...rest } = props;
  return (
    <Tab
      label={
        <Link
          to={value}
          style={{
            width: "100%",
            display: "inline-flex",
            /*position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,*/
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            color: "inherit",
            textDecoration: "none",
            verticalAlign: "middle"
          }}
        >
          {label}
        </Link>
      }
      {...rest}
    />
  );
};

TabLink.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

const validateTabValue = value => {
  const validValues = [
    "/worksheets",
    "/worksheets/new",
    "/worksheets/in-progress",
    "/worksheets/done",
    "/worksheets/waiting",
    "/worksheets/closed",
    "/worksheets/bank_transfer",
    "/worksheets/workfees",
    "/worksheets/anyk",
    "/worksheets/anyk-exports",
    "/worksheets/deleted"
  ];

  return validValues.indexOf(value) >= 0 ? value : false;
};

class Navbar extends PureComponent {
  render() {
    const slugs = this.props.location
      ? this.props.location.pathname.split("/")
      : [];
    const menu = `/${slugs[1]}`;
    const sub = slugs.length > 2 ? `/${slugs[2]}` : "";
    const value = validateTabValue(menu + sub);

    return (
      <AppBar className="mb-3" position="static">
        <Tabs variant="scrollable" scrollButtons="on" value={value}>
          <TabLink
            label={localize("menu", "Összes munkalap")}
            value="/worksheets"
          />
          <TabLink label={localize("menu", "Új m.")} value="/worksheets/new" />
          <TabLink
            label={localize("menu", "Folyamatban lévő m.")}
            value="/worksheets/in-progress"
          />
          <TabLink
            label={localize("menu", "Ügyfélre váró m.")}
            value="/worksheets/waiting"
          />
          <TabLink
            label={localize("menu", "Elkészült m.")}
            value="/worksheets/done"
          />
          <TabLink
            label={localize("menu", "Lezárt m.")}
            value="/worksheets/closed"
          />
          <TabLink
            label={localize("menu", "Fiz.re vár.")}
            value="/worksheets/bank_transfer"
          />
          <TabLink
            label={localize("menu", "Munkadíjak")}
            value="/worksheets/workfees"
          />
          <TabLink
            label={localize("menu", "ANYK aktuális")}
            value="/worksheets/anyk"
          />
          <TabLink
            label={localize("menu", "ANYK")}
            value="/worksheets/anyk-exports"
          />
          <TabLink
            label={localize("menu", "Törölt")}
            value="/worksheets/deleted"
          />
          <TabLink
            label={localize("menu", "Statisztika")}
            value="/worksheets/statistic"
          />
        </Tabs>
      </AppBar>
    );
  }
}
Navbar.propTypes = {
  location: PropTypes.object.isRequired
};

export default Navbar;
