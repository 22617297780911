import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import RightsList from "./views/RightsList";
import * as cashregisterRightsActions from "../../../../redux/modules/cashregister-rights";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.cashregisterRights.loading,
    success: state.cashregisterRights.success,
    message: state.cashregisterRights.message,
    data: state.cashregisterRights.data,
    total: state.cashregisterRights.total,
    limit: state.cashregisterRights.limit,
    page: state.cashregisterRights.page,
    order: state.cashregisterRights.order,
    orderMode: state.cashregisterRights.orderMode,
    deleted: state.cashregisterRights.deleted,
    deletedId: state.cashregisterRights.deletedId,
    token: state.cashregisterRights.token,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: cashregisterRightsActions.rightsList,
      deleteRigth: cashregisterRightsActions.rightsDelete,
      setLoading: cashregisterRightsActions.setLoading,
      reset: cashregisterRightsActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RightsList);
