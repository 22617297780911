import axios from "axios";
import MobileDetect from "mobile-detect";
import errorResponseHandler from "helpers/errorResponseHandler";

let md = new MobileDetect(window.navigator.userAgent);
let category = "Desktop";
if (md.mobile()) {
  category = "Mobile";
} else if (md.tablet()) {
  category = "Tablet";
}
/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: process.env.REACT_APP_AEE_HOST + "/v1/",
  headers: {
    //Authorization: localStorage.getItem("access_token"),
    "X-Device-Category": category,
    Space: process.env.REACT_APP_SPACE,
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json; charset=UTF-8"
  }
});
// apply interceptor on response
client.interceptors.response.use(response => response, errorResponseHandler);
/**
 * Request Wrapper with default success/error actions
 */
const requestHelper = function(options) {
  const onSuccess = function(response) {
    return response;
  };

  const onError = function(error) {
    if (error.response) {
      if (error.response.status === 401) {
        //hanndle logout
      }
    } else {
      // Something else happened while setting up the request
      // triggered the error
      //console.error("Error Message:", error.message);
    }
    return Promise.reject(error.response || error.message);
  };

  options.url = decodeURI(options.url).replace(new RegExp("%2C", "g"), ",");
  if (!options.headers) {
    options.headers = {};
  }
  options["Content-Type"] = "application/json; charset=UTF-8";
  options.headers.Authorization = localStorage.getItem("access_token");

  //Egyedi header adatok
  if (options.client) {
    options.headers.Client = options.client;
    delete options.client;
  }

  if (options.customToken) {
    options.headers.Authorization = `Bearer ${options.customToken}`;
    delete options.customToken;
  }

  if (options.ap) {
    if (!options.headers) {
      options.headers = {};
    }
    options.headers.APN = options.ap;
    delete options.ap;
  }
  if (!options.data) {
    options.data = {};
  }
  return client(options)
    .then(onSuccess)
    .catch(onError);
};

export default requestHelper;
