import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditMyExam from "./views/EditMyExam";
import * as myExamsActions from "../../../redux/modules/myExams";

const mapStateToProps = state => {
  return {
    loading: state.myExams.loading,
    success: state.myExams.success,
    saved: state.myExams.saved,
    message: state.myExams.message,
    fieldErrors: state.myExams.formErrors,
    id: state.myExams.id,
    viewData: state.myExams.viewData,
    reducerName: "myExams",
    result: state.myExams.result,
    certificate: state.myExams.certificate,
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields,
    isFailedExam: state.myExams.isFailedExam
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: myExamsActions.createExams,
      update: myExamsActions.updateExams,
      view: myExamsActions.viewExams,
      reset: myExamsActions.reset,
      resetForm: myExamsActions.resetForm
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMyExam);
