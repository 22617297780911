import React, { Component, Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Search from "@material-ui/icons/Search";

import Header from "./Header/Header";
import Body from "./Body/Body";
import ReactDOM from "react-dom";
import ColumnResizer from "column-resizer"; //https://github.com/MonsantoCo/column-resizer
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import localize from "helpers/localize";

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5
  }
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true
})(TablePaginationActions);
class TableData extends Component {
  constructor(props) {
    super(props);
    let header = [];
    if (props.columnsOrder.length !== 0) {
      props.columnsOrder.forEach(_key => {
        if (props.columns[_key]) {
          header.push({
            ...props.columns[_key],
            _key
          });
        }
      });
    }

    this.state = {
      header
    };
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.columnsOrder !== nextProps.columnsOrder) {
      let header = [];
      if (nextProps.columnsOrder.length !== 0) {
        nextProps.columnsOrder.forEach(_key => {
          if (nextProps.columns[_key]) {
            header.push({
              ...nextProps.columns[_key],
              _key
            });
          }
        });
      }

      this.setState({
        header
      });
    }
  };

  componentDidMount() {
    if (this.props.resizable) {
      window.addEventListener("resize", this.onTableResize);
      this.enableResize();
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 200);
    }
  }

  componentWillUnmount() {
    if (this.props.resizable) {
      window.removeEventListener("resize", this.onTableResize);
      this.disableResize();
    }
  }

  componentDidUpdate() {
    if (this.props.resizable) {
      this.enableResize();
    }
  }

  getTableId = () => {
    const generetedId =
      "tbl_" + this.props.location.pathname.replace(new RegExp("/", "g"), "");

    return this.props.id || generetedId;
  };

  getTable = () => {
    if (!this.table) {
      const tableId = this.getTableId();

      this.table = ReactDOM.findDOMNode(this).querySelector("#" + tableId);
    }
    return this.table;
  };

  onTableResize = (/*evt*/) => {
    const waitForResizeEndsMs = 500;
    if (this.tableResizeTimeout) {
      clearTimeout(this.tableResizeTimeout);
    }
    //várunk, hogy frissüljön az érték a sessionStorage-ban
    this.tableResizeTimeout = setTimeout(() => {
      //console.log("onTableResize", this.table.offsetWidth)
      this.enableResize();
    }, waitForResizeEndsMs);
  };

  /**
   * Alapértelmezett oszlopszélességek kiszámolása
   *
   * @returns Array Oszlopszélességek
   */
  getDefaultColumnWidths = () => {
    //TODO: actionColumnWidth számolása a gombok száma alapján vagy paraméternek kéne lennie
    const { actionColumnWidth } = this.props;
    var table = this.getTable();

    const columnWidth = Math.ceil(
      (table.offsetWidth - actionColumnWidth) / this.state.header.length
    );

    let defaultWidths = [];
    this.state.header.forEach(() => {
      defaultWidths.push(columnWidth);
    });

    //Akció oszlop szélessége az utolsó
    defaultWidths.push(actionColumnWidth);
    defaultWidths.push(table.offsetWidth - 7);

    return defaultWidths;
  };

  /**
   * Méretezés bekapcsolása
   */
  enableResize() {
    var table = this.getTable();

    //LocalStorage-ból átemelés a sessionStorage-ba
    const widths = localStorage.getItem(this.getTableId());
    if (widths) {
      sessionStorage.setItem(this.getTableId(), widths);
    }

    const options = {
      widths: widths ? [] : this.getDefaultColumnWidths(),
      onResize: this.onResize,
      minWidth: 40,
      headerOnly: true,
      resizeMode: "overflow"
    };
    if (!this.resizer) {
      this.resizer = new ColumnResizer(table, options);
    } else {
      this.resizer.reset(options);
    }
  }

  /**
   * Oszlop méretezéskor eltároljuk a localStorage-ban a szélességeket.
   * A komponens ugyanis csak a session-ben dolgozik.
   */
  onResize = (/*evt, data*/) => {
    const waitForResizeEndsMs = 1000;
    const tableId = this.getTableId();
    //console.log(evt, data)
    //várunk, hogy frissüljön az érték a sessionStorage-ban
    setTimeout(() => {
      const widths = sessionStorage.getItem(tableId);
      if (widths) {
        localStorage.setItem(this.getTableId(), widths);
      }
    }, waitForResizeEndsMs);
  };

  /**
   * Méretezhetőség kikapcsolása.
   * A sessionStorage-ból eltűnik az érték
   */
  disableResize() {
    if (this.resizer) {
      this.resizer.reset({ disable: true });
    }
  }

  /**
   * Kitörli a storageből az oszlopszélességeket.
   */
  resetColumnWidths = () => {
    //console.log("Table resetColumnWidths");
    localStorage.removeItem(this.getTableId());
    sessionStorage.removeItem(this.getTableId());
    if (this.resizer) {
      this.resizer.reset({ flush: true, disable: true });
      this.enableResize();
      window.dispatchEvent(new Event("resize"));
    }
  };

  render() {
    const PaginationComponent = prop => (
      <TablePagination
        className="tbl_pagination"
        classes={{ spacer: prop.component ? "spacer_display_one" : undefined }}
        count={this.props.total}
        component={prop.component}
        rowsPerPage={parseInt(this.props.limit)}
        page={parseInt(this.props.page, 10) - 1}
        onChangePage={this.props.handleChangePage}
        rowsPerPageOptions={[this.props.limit]}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} megjelenítve a ${count}-ból`
        }
        ActionsComponent={TablePaginationActionsWrapped}
      />
    );
    return (
      <Fragment>
        {this.state.header.length !== 0 && (
          <div className="aflex-auto">
            <div
              className="table-responsive-material"
              style={{ width: "100%" }}
            >
              {this.props.topPagination && (
                <div
                  style={{
                    zIndex: 500,
                    marginLeft: -16,
                    //marginTop: -16,
                    color: "rgba(0, 0, 0, 0.47)"
                  }}
                >
                  <PaginationComponent component="div" />
                </div>
              )}
              <Table
                className=""
                id={this.getTableId()}
                //width="100%"
                style={{ minWidth: this.state.header.length * 40 }}
                key={this.state.time}
              >
                <Header
                  header={this.state.header}
                  {...this.props}
                  resetColumnWidths={this.resetColumnWidths}
                  paginationComponent={PaginationComponent}
                />
                <Body header={this.state.header} {...this.props} />
                <TableFooter>
                  <TableRow>
                    <PaginationComponent />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </div>
        )}
        {this.state.header.length === 0 && (
          <div>
            <Search /> {localize("basic", "Nem található")}
          </div>
        )}
      </Fragment>
    );
  }
}

TableData.defaultProps = {
  resizable: true,
  actionColumnWidth: 180
};

TableData.propTypes = {
  resizable: PropTypes.bool,
  actionColumnWidth: PropTypes.number,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  location: PropTypes.object.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  page: PropTypes.number,
  columnsOrder: PropTypes.array.isRequired,
  columns: PropTypes.object.isRequired
};

export default TableData;
