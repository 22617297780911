import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditDistributor from "./views/EditDistributor";
import * as distributorsActions from "../../../redux/modules/distributors";

const mapStateToProps = state => {
  return {
    loading: state.distributors.loading,
    success: state.distributors.success,
    saved: state.distributors.saved,
    message: state.distributors.message,
    fieldErrors: state.distributors.formErrors,
    id: state.distributors.id,
    viewData: state.distributors.viewData,
    //TODO: Ezt minden formhoz betenni a destroy-hoz. A formnak tudnia kell a saját reducer-ét
    reducerName: "distributors",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: distributorsActions.createDistributors,
      update: distributorsActions.updateDistributors,
      view: distributorsActions.viewDistributors,
      reset: distributorsActions.reset,
      resetForm: distributorsActions.resetForm
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDistributor);
