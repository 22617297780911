import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { attachDocument } from "redux/modules/service-orders.js";
import UploadButton from "Components/UploadButton";
import Typography from "@material-ui/core/Typography";
import DocumentIcon from "@material-ui/icons/Description";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import FileItem from "./FileItem";
import localize from "helpers/localize";

class DocListItem extends React.Component {
  state = {
    expand: false,
    error: null
  };

  /**
   * Eltávolít egy csatolt dokumentumot (Nem használt)
   */
  remove = type => {
    console.log("onRemove", type);
    return attachDocument(this.props.id, {
      type: type,
      file_id: null
    }).then(attachRes => {
      console.log("attachRes", attachRes);
      this.props.onRemove && this.props.onRemove(type);
    });
  };

  expand = () => {
    this.setState({ expand: !this.state.expand });
  };

  onError = error => {
    console.log("onError", error);
    this.setState({ error: error });
  };

  //["display4","display3","display2","display1","headline","title","subheading","body2","body1","caption","button
  render() {
    const {
      classes,
      name,
      label,
      onRemove,
      onUpload,
      documents,
      status,
      needed,
      isView,
      disableDelete,
      canReplace,
      confirmUpload,
      remove,
      isContract,
      isNeeded,
      isUploaded,
      isSubmitted,
      acceptedFiles
    } = this.props;
    const { expand, error } = this.state;
    let currentFile = get(documents, "current.file", null)
      ? Object.assign({}, get(documents, "current.file", null))
      : null;
    let history = get(documents, "history", []).slice();
    const expandIconClass = classNames(
      classes.expandIcon,
      expand ? classes.expandIconExpanded : null
    );
    let document_id = get(documents, "current.document_id", null);
    //Beadott pending státusz esetén a history utsója a curernt és nem lehet törölni
    if (
      status === "pending" ||
      status === "okay" ||
      canReplace ||
      (!needed && status === "wrong") ||
      isView
    ) {
      if (!currentFile || currentFile.length === 0) {
        currentFile = history[0];
        history.shift();
      }
    }
    //console.log();
    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar className={currentFile ? classes.validAvatar : null}>
            <DocumentIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={<Typography variant="subtitle1">{label}</Typography>}
          secondary={
            <Fragment>
              {error ? (
                <span className={classes.error}>{error}</span>
              ) : isView && !currentFile ? (
                ""
              ) : (
                <FileItem file={currentFile} classes={classes} />
              )}
              {history.length > 0 && (
                <Fragment>
                  <ExpandMoreIcon
                    className={expandIconClass}
                    onClick={this.expand}
                  />
                  <Collapse in={expand}>
                    {history.length > 0 &&
                      history.map((historyFile, index) => {
                        return (
                          <FileItem
                            key={index}
                            file={historyFile}
                            history={true}
                            classes={classes}
                            historyText={
                              isContract
                                ? localize(
                                    "serviceOrders",
                                    "Már beküldött dokumentum"
                                  )
                                : localize(
                                    "serviceOrders",
                                    "Visszautasított dokumentum."
                                  )
                            }
                            disableIcon={isContract}
                          />
                        );
                      })}
                  </Collapse>
                </Fragment>
              )}
            </Fragment>
          }
        />
        <ListItemSecondaryAction>
          {!isView && (
            <UploadButton
              name={name}
              label={label}
              onUpload={file => onUpload(file, name)}
              onRemove={onRemove}
              file={canReplace ? null : currentFile}
              canReplace={canReplace}
              isNeeded={isNeeded}
              onError={this.onError}
              /*disableDelete={
                disableDelete ||
                status === "pending" ||
                (!needed && status === "wrong")
              }*/
              disableDelete={isSubmitted}
              //disableUpload={status === "pending"}
              confirmUpload={confirmUpload}
              remove={() => remove(document_id)}
              acceptedFiles={acceptedFiles}
              texts={{
                confirmUploadTitle: localize(
                  "serviceOrders",
                  "Biztosan új fájlt csatol?"
                ),
                confirmUploadMsg: localize(
                  "serviceOrders",
                  "Korábban már beküldött egy fájlt."
                )
              }}
            />
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

DocListItem.defaultProps = {
  confirmUpload: false,
  canReplace: false,
  isContract: false,
  acceptedFiles: "application/pdf"
};

DocListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  onUpload: PropTypes.func,
  documents: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.string,
  needed: PropTypes.bool,
  isNeeded: PropTypes.bool,
  isUploaded: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  isContract: PropTypes.bool,
  isView: PropTypes.bool,
  disableDelete: PropTypes.bool,
  canReplace: PropTypes.bool,
  confirmUpload: PropTypes.bool, //True esetben megerősítő kérdés jelenik meg feltöltés előtt. A szövegét a texts.confirmUploadTitle és texts.confirmUploadMsg-ből veszi
  remove: PropTypes.func,
  acceptedFiles: PropTypes.string
};

export default DocListItem;
