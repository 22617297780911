import localize from "helpers/localize";
import { apMask0 } from "helpers/maskHelper";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import EditForm from "../../../../../Components/Forms/Builder/EditForm";
import DateField from "../../../../../Components/Forms/Fields/Date";
import InputField from "../../../../../Components/Forms/Fields/Input";
import Validations from "../../../../../Components/Forms/Validations";

class AeeRegForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  state = { generated_code: null };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.generated_code !== nextProps.generated_code) {
      this.setState({ generated_code: nextProps.generated_code });
    }
  }

  componentDidMount() {
    this.props.initialize(this.props.initialValues);
  }

  render() {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      enabledFields
    } = this.props;

    return (
      <EditForm
        {...this.props}
        disableCancel={true}
        texts={{ saveButton: localize("cashregisters", "Generálás") }}
      >
        <div className="col-12 col-md-4">
          <Field
            name="input_date_param"
            label={localize("cashregisters", "Dátum")}
            isRequired={true}
            validate={[Validations.required]}
            fieldError={fieldErrors.input_date_param}
            component={DateField}
            placeholder={localize("cashregisters", "Dátum")}
            onChange={() => handleInputChange("input_date_param")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("input_date_param") !== -1
                  ? false
                  : true
                : false
            }
          />
        </div>

        <div className="col-12 col-md-4">
          <Field
            name="apn"
            label={localize("cashregisters", "AP szám")}
            fieldError={fieldErrors.apn}
            onChange={() => handleInputChange("apn")}
            isRequired={true}
            validate={[Validations.required]}
            component={InputField}
            type="text"
            placeholder={localize("cashregisters", "apn")}
            isViewPage={isViewPage}
            readOnly={
              enabledFields
                ? enabledFields.indexOf("apn") !== -1
                  ? false
                  : true
                : false
            }
            {...apMask0}
          />
        </div>
        {this.state.generated_code && (
          <div className="col-12 col-md-4">
            <div className="position-relative form-group">
              <div className="controls">
                <label className="mt-3">
                  {localize("cashregisters", "Generált kód:")}
                </label>
                <p>
                  <b>{this.state.generated_code}</b>
                </p>
              </div>
            </div>
          </div>
        )}
      </EditForm>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "aeereg",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(AeeRegForm);

export default connect(
  state => ({
    initialValues: {
      input_date_param: moment().format("YYYY-MM-DD"),
      ...state.cashregisters.viewData
    },
    generated_code: state.cashregisters.generated_code
  }),
  null
)(UsersReduxForm);
