import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import AddIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import SweetAlert from "react-bootstrap-sweetalert";
import TrashIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import inputStyle from "./inputStyle.js";
import UploadButton from "Components/UploadButton";
import localize from "helpers/localize";

class TableField extends Component {
  constructor(props) {
    super(props);
    //props.data.splice(0, props.data.length);
    //console.log(props.data);
    //props.data && props.data.forEach((row, index) => props.fields.push(row));
    if (this.props.fields.length === 0) {
      //this.props.fields.push({});
    }
    this.state = {
      confirm: false
    };
  }

  deleteCell = index => {
    const { readOnly } = this.props;
    const isViewPage = this.props.isViewPage || readOnly ? true : false;
    return (
      <TableCell align="right">
        {this.props.fields.length > 0 && !isViewPage && (
          <Tooltip title={localize("basic", "Sor törlése")}>
            <IconButton
              style={{ width: 24, height: 24, padding: 0 }}
              onClick={evt => this.handleDelete(index)}
              color="secondary"
            >
              <TrashIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    );
  };

  handleNewItem = () => {
    this.props.fields.push({});
  };

  uploadNewItem = (data, file) => {
    this.props.fields.push({ id: data.id, file_name: data.file_name });
  };

  handleDelete = index => {
    this.setState({
      confirm: `${index}`
    });
  };

  handleComfirm = () => {
    this.props.fields.remove(this.state.confirm);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({
      confirm: false
    });
  };

  render = () => {
    const {
      //isViewPage: isView,
      getRule,
      dispatch,
      change,
      taxes,
      fields,
      Item,
      columns,
      itemProps,
      classes,
      readOnly,
      fileItem,
      fullWidth
    } = this.props;

    const isViewPage = this.props.isViewPage || readOnly ? true : false;

    return (
      <Fragment>
        <SweetAlert
          show={this.state.confirm ? true : false}
          warning
          showCancel
          showLoaderOnConfirm
          confirmBtnText={localize("basic", "Igen")}
          cancelButtonText={localize("basic", "Nem")}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          timer={false}
          title={localize("basic", "Biztosan törölni akarja?")}
          onCancel={this.handleClose.bind(this)}
          onConfirm={this.handleComfirm.bind(this)}
        />
        <div
          className={"table-responsive-material" + (isViewPage ? " view" : "")}
          style={{ overflow: "visible" }}
        >
          <Table
            padding="checkbox"
            style={fullWidth ? {} : { width: "auto" }}
            className={readOnly ? classes.tableFieldDisabled : null}
          >
            <TableHead>
              <TableRow>
                {columns.map((column, index) => {
                  return (
                    <TableCell
                      key={index}
                      style={column.width ? { width: column.width } : null}
                    >
                      {column.name}
                    </TableCell>
                  );
                })}
                <TableCell style={{ width: 20 }} align="right">
                  {!isViewPage && !fileItem && (
                    <Tooltip title={localize("basic", "Új hozzáadása")}>
                      <IconButton
                        style={{ width: 24, height: 24, padding: 0 }}
                        onClick={this.handleNewItem.bind(this)}
                        color="primary"
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!isViewPage && fileItem && (
                    <UploadButton
                      size="small"
                      buttonProps={{
                        style: { width: 24, height: 24, padding: 0 }
                      }}
                      onlyUpload
                      onUpload={this.uploadNewItem.bind(this)}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((collector, index) => (
                <Item
                  collector={collector}
                  key={"fi-" + index}
                  index={index}
                  {...this.props}
                  fieldError={get(this.props, `fieldsError[${index}]`, {})}
                  getRule={getRule}
                  dispatch={dispatch}
                  change={change}
                  handleDelete={this.handleDelete.bind(this)}
                  {...itemProps}
                  deleteCell={this.deleteCell}
                  isViewPage={isViewPage}
                />
              ))}
            </TableBody>
          </Table>
        </div>

        {/*!this.props.isViewPage && this.props.fields.length < 42 && this.props.contractType === 'aeereg' && <div className="col-12">
              <Button onClick={this.handleNewItem.bind(this)} variant="contained" color="secondary">
                  { localize("serviceOrders", "Új pénztárgép")}
              </Button>
              <hr />
          </div>*/}
      </Fragment>
    );
  };
}

export default withStyles(inputStyle)(TableField);
