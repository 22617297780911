import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditCrule from "./views/EditCrule";
import * as crulesActions from "../../../../redux/modules/crules";
import * as CrulesOptionsActions from "../../../../redux/modules/CrulesOptions";

const mapStateToProps = state => {
  return {
    loading: state.crules.loading,
    success: state.crules.success,
    saved: state.crules.saved,
    message: state.crules.message,
    fieldErrors: state.crules.formErrors,
    id: state.crules.id,
    viewData: state.crules.viewData,
    reducerName: "crules",

    model: state.CrulesOptions.model,
    baseFields: state.CrulesOptions.baseFields,
    enabledFields: state.CrulesOptions.enabledFields,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: crulesActions.createCrules,
      update: crulesActions.updateCrules,
      view: crulesActions.viewCrules,
      reset: crulesActions.reset,
      resetForm: crulesActions.resetForm,
      getFormInfo: CrulesOptionsActions.getFormInfo
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCrule);
