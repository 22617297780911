import { Typography } from "@material-ui/core";
import TextField from "Components/New/Input/TextField/TextField";
import localize from "helpers/localize";
import React, { Component, Fragment } from "react";

class TokenRangeDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      error: null
    };
  }

  setValue = evt => {
    this.setState({ value: evt.target.value });
  };

  getCount = () => {
    const params = this.getValueParams();

    if (!params) {
      return 0;
    }

    return params.count;
  };
  getValueParams = () => {
    const { value } = this.state;
    if (!value) {
      return null;
    }
    let start = null;
    let end = null;
    let count = 0;
    let error = null;

    if (value.indexOf("-") >= 0) {
      const [_start, _end] = value.split("-");
      start = parseInt(_start.trim());
      end = parseInt(_end.trim());
      count = end - start + 1;
      if (end === 0) {
        error = true;
      }
    } else if (value.indexOf(",") >= 0) {
      const [_start, _count] = value.split(",");
      start = parseInt(_start.trim());
      count = parseInt(_count.trim());
      end = start + count - 1;
      if (count === 0) {
        error = true;
      }
    } else {
      return { start: parseInt(value), end: parseInt(value), count: 0 };
    }
    if (isNaN(count)) {
      count = 0;
      error = true;
    }
    return { start, end, count, error };
  };

  validateValue = () => {
    const params = this.getValueParams();
    if (!params) {
      this.setState({
        error: localize("tokens", "A tartomány megadása kötelező.")
      });
      return false;
    }
    if (params.error) {
      this.setState({ error: localize("tokens", "Hibás tartomány") });
      return false;
    } else if (params.count < 0) {
      this.setState({ error: localize("tokens", "Negatív tartomány") });
      return false;
    }
    this.setState({ error: null });

    return true;
  };

  needConfirm = () => {
    const { value } = this.state;

    let hasError = false;

    const params = this.getValueParams(value);
    if (params && params.count > 50) {
      hasError = true;
    }

    return hasError;
  };

  render() {
    const { value, error } = this.state;

    return (
      <Fragment>
        <Typography
          variant="caption"
          color="textSecondary"
          style={{ marginBottom: 16 }}
        >
          * - {localize("tokens", "A mező 2 féle tartományt fogad el.")} 1.: [
          {localize("tokens", "kezdő token")}]-[
          {localize("tokens", "befejezőtoken")}] {localize("tokens", "pl.")}:{" "}
          <b style={{ marginRight: 16 }}>170510-170550</b> 2.: [
          {localize("tokens", "kezdő token")}],[
          {localize("tokens", "darabszám")}] {localize("tokens", "pl.")}:
          <b>170510,40</b>
        </Typography>
        <TextField
          label="Tartomány *"
          value={value}
          fullWidth
          error={error}
          onChanged2={this.setValue}
        />
      </Fragment>
    );
  }
}

TokenRangeDelete.propTypes = {};

export default TokenRangeDelete;
