import React, { Fragment } from "react";
import PropTypes from "prop-types";
import restHelper from "helpers/restHelper";
import CircularProgress from "@material-ui/core/CircularProgress";
import localize from "helpers/localize";

class ClientPopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      client: null
    };
  }

  componentDidMount() {
    const { id } = this.props;
    this.fetchClient(id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id && nextProps.id !== this.props.id) {
      this.fetchClient(nextProps.id);
    }
  }

  fetchClient = id => {
    this.setState({ loading: true });
    restHelper.index("cashregisters/client", { id }, false).then(response => {
      this.setState({ loading: false, client: response.data[0] });
    });
  };

  concatAddress = data => {
    return (
      data.client_zip +
      (data.client_city ? " " + data.client_city : "") +
      (data.client_street ? " " + data.client_street : "") +
      (data.client_street_suffix ? " " + data.client_street_suffix : "") +
      (data.client_street_details ? " " + data.client_street_details : "")
    );
  };

  render() {
    const { loading, client } = this.state;

    if (loading) {
      return <CircularProgress color="primary" />;
    }

    if (!client) {
      return "";
    }

    return (
      <Fragment>
        <table style={{ margin: 6 }} cellPadding={1}>
          <tbody>
            <tr style={{ borderBottom: "solid 1px #aaa" }}>
              <td colSpan={2}>
                <b>{client.client_name}</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>{localize("cashregisters", "Cím")}:</b>
              </td>
              <td>{this.concatAddress(client)}</td>
            </tr>
            {client.client_tax_number && (
              <tr>
                <td>
                  <b>{localize("cashregisters", "Adószám")}:</b>
                </td>
                <td>{client.client_tax_number}</td>
              </tr>
            )}
            {client.client_phone && (
              <tr>
                <td>
                  <b>{localize("cashregisters", "Telefonszám")}:</b>
                </td>
                <td>{client.client_phone}</td>
              </tr>
            )}
            {client.client_registration_no && (
              <tr>
                <td>
                  <b>{localize("cashregisters", "Cégjegyzékszám")}:</b>
                </td>
                <td>{client.client_registration_no}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

ClientPopover.propTypes = {
  id: PropTypes.number
};

export default ClientPopover;
