import axios from 'axios';
import qs from 'qs';
import { get } from 'lodash';

const EVENTS_LIST_LOAD = 'EVENTS_LIST_LOAD';
const EVENTS_LIST_SUCCESS = 'EVENTS_LIST_SUCCESS';
const EVENTS_LIST_FAILED = 'EVENTS_LIST_FAILED';
const EVENTS_SET_LOADING = 'EVENTS_SET_LOADING';

const EVENTS_LIST_EXPORT_CHECK_LOAD = 'EVENTS_LIST_EXPORT_CHECK_LOAD';
const EVENTS_LIST_EXPORT_CHECK_SUCCESS = 'EVENTS_LIST_EXPORT_CHECK_SUCCESS';
const EVENTS_LIST_EXPORT_CHECK_FAILED = 'EVENTS_LIST_EXPORT_CHECK_FAILED';

const EVENTS_FORM_LOAD = 'EVENTS_FORM_LOAD';
const EVENTS_FORM_SUCCESS = 'EVENTS_FORM_SUCCESS';
const EVENTS_FORM_FAILED = 'EVENTS_FORM_FAILED';

const EVENT_DELETE_LOAD = 'EVENT_DELETE_LOAD';
const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS';
const EVENT_DELETE_FAILED = 'EVENT_DELETE_FAILED';

const EVENT_EDIT_LOAD = 'EVENT_EDIT_LOAD';
const EVENT_EDIT_SUCCESS = 'EVENT_EDIT_SUCCESS';
const EVENT_EDIT_FAILED = 'EVENT_EDIT_FAILED';

const EVENT_VIEW_LOAD = 'EVENT_VIEW_LOAD';
const EVENT_VIEW_SUCCESS = 'EVENT_VIEW_SUCCESS';
const EVENT_VIEW_FAILED = 'EVENT_VIEW_FAILED';

const EVENTS_RESET = 'EVENTS_RESET';
const EVENTS_RESET_FORM = 'EVENTS_RESET_FORM';

const initialState = {
    loading: false,
    success: false,
    saved: false,
    failed: false,
    data: [],
    message: null,
    total: 0,
    limit:process.env.REACT_APP_LIST_LIMIT,
    page: null,
    order: '',
    orderMode: 'ASC',
    deleted: false,
    deletedId: null,
    formErrors: {},
    viewData: null,
    viewLoading: false,
    commentThread: false,
    id: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case EVENTS_RESET:
            return {
                ...initialState
            };
        case EVENTS_RESET_FORM:
            return {
              ...state,
              deleted: false,
              deletedId: null,
              formErrors: {},
              viewData: null,
              viewLoading: false,
              commentThread: false,
              id: null
            };
        case EVENT_DELETE_LOAD:
            return {
                ...state,
                deleted: false,
                deletedId: null,
                viewLoading: false
            };
        case EVENTS_LIST_LOAD:
            return {
                ...state,
                loading: true,
                failed: false,
                message: null,
                viewLoading: false
            };
        case EVENT_EDIT_LOAD:
            return {
                ...state,
                loading: true,
                failed: false,
                message: null,
                formErrors: {},
                viewLoading: false
            };
        case EVENT_VIEW_LOAD:
            return {
                ...state,
                loading: true,
                failed: false,
                message: null,
                formErrors: {},
                viewLoading: true
            };
        case EVENTS_FORM_LOAD:
            return {
                ...state,
                loading: false,
                failed: false,
                message: null,
                viewLoading: false
            };
        case EVENTS_LIST_EXPORT_CHECK_LOAD:
            return {
                ...state,
                loading: false,
                failed: false,
                message: null,
                viewLoading: false
            };
        case EVENTS_FORM_FAILED:
        case EVENT_EDIT_FAILED:
        case EVENT_DELETE_FAILED:
        case EVENTS_LIST_FAILED:
        case EVENTS_LIST_EXPORT_CHECK_FAILED:
        case EVENT_VIEW_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                saved: false,
                failed: true,
                message: action.message,
                formErrors: action.formErrors || {},
                deleted: true,
                deletedId: null,
                viewLoading: false
            };
        case EVENTS_LIST_SUCCESS:
            return {
                ...state,
                success: true,
                failed: false,
                data: action.data,
                total: action.total,
                limit: action.limit,
                page: action.page,
                order: action.order,
                orderMode: action.orderMode
            };
        case EVENT_VIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                failed: false,
                viewData: action.viewData,
                viewLoading: false
            };
        case EVENTS_FORM_SUCCESS:
            return {
                ...state,
                fields: action.fields,
                loading: false,
                success: true,
                failed: false,
                viewLoading: false
            };
        case EVENT_DELETE_SUCCESS:
            return {
                ...state,
                deleted: true,
                deletedId: action.id
            };
        case EVENT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                saved: true,
                failed: false,
                message: null,
                formErrors: {},
                id: action.id,
                viewLoading: false
            };
        case EVENTS_LIST_EXPORT_CHECK_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                failed: false,
                message: null,
                viewLoading: false
            };
        case EVENTS_SET_LOADING:
            return {
                ...state,
                loading: action.loading,
                viewLoading: false
            };
        default:
            return state;
    }
}

export const reset = () => dispatch => {
    dispatch({
        type: EVENTS_RESET
    });
};

export const resetForm = () => dispatch => {
    dispatch({
        type: EVENTS_RESET_FORM
    });
};

export const updateEvent = (model,id,data) => dispatch => {
    dispatch({
        type: EVENT_EDIT_LOAD
    });

    let obj = {};
    obj[model] = data;

    return axios.put('/v1/events/'+id, obj).then(
        () => dispatch({
            type: EVENT_EDIT_SUCCESS
        })
    ).catch(error => {
        dispatch({
            type: EVENT_EDIT_FAILED,
            message: get(error, 'response.data.message', null),
            formErrors: get(error, 'response.data.form_errors', {})
        })
    });
};

export const viewEvent = (id) => dispatch => {
    dispatch({
        type: EVENT_VIEW_LOAD
    });
    return axios.get('/v1/events/'+id).then(
        response => dispatch({
            type: EVENT_VIEW_SUCCESS,
            viewData: get(response, 'data.data'),
            commentThread: get(response, 'data.commentThread', false)
        })
    ).catch(error => {
        dispatch({
            type: EVENT_VIEW_FAILED,
            message: get(error, 'response.data.message', null)
        })
    });
};

export const createEvent = (model, data) => dispatch => {
    dispatch({
        type: EVENT_EDIT_LOAD
    });

    let obj = {};
    obj[model] = data;

    return axios.post('/v1/events', obj).then(response => dispatch({
        type: EVENT_EDIT_SUCCESS,
        id: get(response, 'data.data.id', null),
    })).catch(error => {
        dispatch({
            type: EVENT_EDIT_FAILED,
            message: get(error, 'response.data.message', null),
            formErrors: get(error, 'response.data.form_errors', {})
        })
    });
};

export const EventsDelete = (id) => dispatch => {
    dispatch({
        type: EVENT_DELETE_LOAD
    });
    return axios.delete('/v1/events/'+id)
        .then((response) => dispatch({
            type: EVENT_DELETE_SUCCESS,
            id
        })).catch(error => dispatch({
            type: EVENT_DELETE_FAILED,
            message: get(error, 'response.data.message', null)
        }));
};

export const setLoading = (loading) => dispatch => {
    dispatch({
        type: EVENTS_SET_LOADING,
        loading
    });
};

export const EventsList = (page, keyword, sort, filters, perPage, section) => dispatch => {
    dispatch({
        type: EVENTS_LIST_LOAD
    });

    let params = {
        page,
        keyword,
        sort,
        'per-page': perPage,
        section
    };

    params[filters.model] = filters.filters;

    return axios.get(
        '/v1/events',
        {
            params: params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { encode: false });
            }
        },
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }
    ).then(response => {
        let sort = get(response, 'data.sort', null),
            mode = 'ASC';
        if(sort && sort.indexOf('-') !== -1) {
            mode = 'DESC';
            sort = sort.substr(1);
        }
        return dispatch({
            type: EVENTS_LIST_SUCCESS,
            data: get(response, 'data.data', null),
            page: get(response, 'data.page', 0),
            limit: get(response, 'data.per-page', 0),
            total: get(response, 'data.total', 0),
            order: sort,
            orderMode: mode
        });
    })
        .catch(error => dispatch({
            type: EVENTS_LIST_FAILED,
            message: get(error, 'response.data.message', null)
        }));
};
export const checkIsExportAvailableByFilters = (filters) => dispatch => {
    dispatch({
        type: EVENTS_LIST_EXPORT_CHECK_LOAD
    });

    let params = {
        [filters.model]: filters.filters
    };

    return axios.get(`/v1/events/export`,
        {
            params: params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { encode: false });
            }
        }
    ).then(response => {
        dispatch({
            type: EVENTS_LIST_EXPORT_CHECK_SUCCESS,
            success: get(response, 'data.success', false)
        });

        return response.data;
    }).catch(error => {
        dispatch({
            type: EVENTS_LIST_EXPORT_CHECK_FAILED,
            message: get(error, 'error.data.message', null)
        });

        throw error.response;
    });
};
