import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import WorksheetsList from "./views/WorksheetsList";
import * as worksheetsActions from "../../../../redux/modules/cashregisters-worksheets";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.cashregistersWorksheets.loading,
    success: state.cashregistersWorksheets.success,
    message: state.cashregistersWorksheets.message,
    data: state.cashregistersWorksheets.data,
    total: state.cashregistersWorksheets.total,
    limit: state.cashregistersWorksheets.limit,
    page: state.cashregistersWorksheets.page,
    order: state.cashregistersWorksheets.order,
    orderMode: state.cashregistersWorksheets.orderMode,
    deleted: state.cashregistersWorksheets.deleted,
    deletedId: state.cashregistersWorksheets.deletedId,
    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,
      getList: worksheetsActions.worksheetsList,
      setLoading: worksheetsActions.setLoading
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorksheetsList);
