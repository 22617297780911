import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
//import {Router} from 'react-router-dom';
import { createBrowserHistory as createHistory } from "history";
import { syncHistoryWithStore } from "react-router-redux";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
//import MomentUtils from "material-ui-pickers/utils/moment-utils";
//import MuiPickersUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import store from "./redux/store";
import { App } from "./Containers";
import ScrollToTop from "./Components/scrollToTop/ScrollToTop";
import { PROFILE_PASSWORD_SUCCESS } from "redux/modules/profile";
//import themeIndigo from "./themes/indigo/theme";
//import themeGreen from "./themes/green/theme";
import GlobalMessaging from "Components/New/GlobalMessaging/GlobalMessaging";
const currentTheme = require(`themes/${process.env.REACT_APP_THEME}/theme.js`);
const history = createHistory();
const syncedHistory = syncHistoryWithStore(history, store);

class Root extends Component {
  render() {
    const theme = createMuiTheme(currentTheme.default);
    console.log("theme", process.env.REACT_APP_THEME, theme);
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router /*history={syncedHistory}*/>
              <ScrollToTop>
                <App />
                <ReduxToastr preventDuplicates position="bottom-right" />
                <GlobalMessaging />
              </ScrollToTop>
            </Router>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default Root;
