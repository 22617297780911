import React, { Fragment, PureComponent } from "react";
import { Helmet } from "react-helmet";


import List from "../../../../../Components/List/List";
import ContainerHeader from "../../../../../Components/ContainerHeader";
import Navbar from "../../Navbar";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import NotInterested from "@material-ui/icons/NotInterested";
import Done from "@material-ui/icons/Done";
import FileDownload from "@material-ui/icons/CloudDownload";
import { get } from "lodash";
import ModifyPermission from "../../../../Permissions/ModifyPermission";
import Visibility from "@material-ui/icons/Visibility";
import Button from "@material-ui/core/Button/Button";
import localize from "helpers/localize";
class ExamineeExamsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      keyword: null,
      search: null,
      filters: null,
      perPage: null
    };
  }

  getList = (page, keyword, sort, filters, perPage) => {
    this.setState({
      page,
      keyword,
      sort,
      filters,
      perPage
    });
    return this.props.getList(page, keyword, sort, filters, perPage);
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.reload && nextProps.reload) {
      const { page, keyword, sort, filters, perPage } = this.state;
      this.getList(page, keyword, sort, filters, perPage);
    }
  };

  customIcons = obj => {
    return (
      <Fragment>
        {obj.status === "completed" && (
          <Tooltip title={localize("basic", "Megtekintés")}>
            <Button
              className="jr-btn jr-btn-xs text-secondary"
              onClick={() =>
                this.props.history.push(
                  this.props.location.pathname + "/view/" + obj.id
                )
              }
              component="span"
            >
              <Visibility />
            </Button>
          </Tooltip>
        )}
        {obj.status === "pending" && (
          <Fragment>
            <ModifyPermission
              isOwn={get(obj, "is_owner", false)}
              match={this.props.match}
              module={"examinee-exams"}
            >
              <Tooltip title={localize("exams", "Jováhagyás")}>
                <Button
                  className="jr-btn jr-btn-xs text-success"
                  onClick={() => this.props.aproveExam(obj.id)}
                  component="span"
                >
                  <Done />
                </Button>
              </Tooltip>
            </ModifyPermission>
            <ModifyPermission
              isOwn={get(obj, "is_owner", false)}
              match={this.props.match}
              module={"examinee-exams"}
            >
              <Tooltip title={localize("exams", "Elutasítás")}>
                <Button
                  className="jr-btn jr-btn-xs text-danger"
                  onClick={() => this.props.declineExam(obj.id)}
                  component="span"
                >
                  <NotInterested />
                </Button>
              </Tooltip>
            </ModifyPermission>
          </Fragment>
        )}
        {obj.certificate && (
          <Tooltip title={localize("exams", "Letöltés")}>
            <Button
              className="jr-btn jr-btn-xs"
              onClick={() => this.props.downloadExam(obj.certificate)}
              component="span"
            >
              <FileDownload />
            </Button>
          </Tooltip>
        )}
      </Fragment>
    );
  };

  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("exams", "Vizsgák")}</title>
        </Helmet>
        <Navbar />
        <ContainerHeader title={localize("exams", "Vizsgák")} />
        <List
          {...this.props}
          disabledCreate={true}
          disableEditIcon={true}
          disableDeleteIcon={true}
          disableViewIcon={true}
          customIcons={this.customIcons.bind(this)}
          getList={this.getList.bind(this)}
        />
      </div>
    );
  }
}

export default ExamineeExamsList;
