import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Profile from "./views/Profile";
import * as profileActions from "../../../../redux/modules/profile";
import * as authActions from "../../../../redux/modules/auth";

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    loading: state.profile.loading,
    success: state.profile.success,
    saved: state.profile.saved,
    message: state.profile.message,
    fieldErrors: state.profile.formErrors,
    profileLoading: state.profile.profileLoading,
    emailLoading: state.profile.emailLoading,
    passwordLoading: state.profile.passwordLoading,
    token: state.profile.token
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      changeEmail: profileActions.changeEmail,
      changePassword: profileActions.changePassword,
      changeProfile: profileActions.changeProfile,
      me: authActions.me
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
