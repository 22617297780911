import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import LanguageSelector from "Components/LanguageSelector";
const logo = require(`themes/${process.env.REACT_APP_THEME}/logo.png`);
const theme = require(`themes/${process.env.REACT_APP_THEME}/theme`);

class NavBarPublic extends Component {
  render() {
    const { logoLink } = this.props;
    return (
      <AppBar className="app-main-header" color={theme.default.header.color}>
        <Toolbar className="app-toolbar" disableGutters={false}>
          <Link
            className="mr-2 d-none d-sm-block"
            to={logoLink}
            style={{
              height: `calc(100% - ${theme.default.header.logoMargin}px)`
            }}
          >
            <img
              src={logo}
              alt=""
              style={{
                height: "100%"
              }}
            />
          </Link>
          <div style={{ marginLeft: "auto" }} />
          <LanguageSelector />
        </Toolbar>
      </AppBar>
    );
  }
}

NavBarPublic.defaultProps = {
  logoLink: "/"
};

NavBarPublic.propTypes = {
  logoLink: PropTypes.string
};

export default NavBarPublic;
