import React, { Component, Fragment } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";
import InputField from "Components/Forms/Fields/Input";
import Autocomplete from "Components/Forms/Fields/Autocomplete";
import SelectField from "Components/Forms/Fields/Select";
import Validations from "Components/Forms/Validations";
import EditForm from "Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { mobilNumberMask } from "helpers/maskHelper";
import FuncPermission from "Containers/Permissions/FuncPermission";
import localize from "helpers/localize";
import UserClients from "./UserClients";
import authRules from "helpers/authRules";

class UsersForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
  };

  handleChangeAutocomplete = (name, id) => {
    const { dispatch, change } = this.props;
    dispatch(change(name, id));
  };

  handleDistributorSelect = (value, key, selected, name) => {
    const { dispatch, change } = this.props;
    const item = selected && selected.length > 0 ? selected[0] : null;
    if (item) {
      dispatch(change(name, item));
      dispatch(change(key, value));
    }
  };

  handleClientSelect = (value, key, selected, name) => {
    const { dispatch, change } = this.props;
    const item = selected && selected.length > 0 ? selected[0] : null;
    if (item) {
      dispatch(change(name, item));
      dispatch(change(key, value));
    }
  };

  render() {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      enabledFields,
      viewData,
      initialValues
    } = this.props;

    const isAccountant = this.props.userRole === "accountant";
    const isUser = this.props.userRole === "user";
    let roles = [];

    if (isAccountant) {
      roles.push({ id: "accountant", label: localize("user", "Könyvelő") });
    } else {
      Object.keys(get(this.props, "baseFields.role.data", {})).forEach(key => {
        roles.push({ id: key, label: this.props.baseFields.role.data[key] });
      });
    }

    return (
      <Fragment>
        <EditForm {...this.props}>
          {/*<FuncPermission module="users" permission="users.invite-role">*/}
          {authRules.roleIn([
            "distributor",
            "mainvendor",
            "vendor",
            "root"
          ]) && (
              <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <Field
                  name="role"
                  label={localize("user", "Jogosultság")}
                  fieldError={fieldErrors.role}
                  onChange={() => handleInputChange("role")}
                  isRequired={true}
                  validate={[Validations.required]}
                  component={SelectField}
                  placeholder={localize("user", "Jogosultság")}
                  isViewPage={isViewPage}
                  options={roles}
                  readOnly={
                    enabledFields && viewData.id
                      ? enabledFields.indexOf("role") !== -1
                        ? false
                        : true
                      : isAccountant
                        ? true
                    : false
                  }
                />
              </Grid>
            )}
          {/*</FuncPermission>*/}
          {window.location.href.indexOf("create") === -1 && (
            <Fragment>
              <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <Field
                  name="username"
                  label={localize("user", "Felhasználónév")}
                  fieldError={fieldErrors.username}
                  onChange={() => handleInputChange("username")}
                  isRequired={true}
                  validate={[Validations.required]}
                  component={InputField}
                  type="text"
                  placeholder={localize("user", "Felhasználónév")}
                  isViewPage={isViewPage}
                  readOnly={
                    enabledFields && viewData.id
                      ? enabledFields.indexOf("username") !== -1
                        ? false
                        : true
                      : false
                  }
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <Field
                  name="real_name"
                  label={localize("user", "Valódi név")}
                  fieldError={fieldErrors.real_name}
                  onChange={() => handleInputChange("real_name")}
                  component={InputField}
                  type="text"
                  placeholder={localize("user", "Valódi név")}
                  isViewPage={isViewPage}
                  readOnly={
                    enabledFields && viewData.id
                      ? enabledFields.indexOf("real_name") !== -1
                        ? false
                        : true
                      : false
                  }
                />
              </Grid>
            </Fragment>
          )}
          {["distributor", "mainvendor", "vendor"].indexOf(
            this.props.userRole
          ) >= 0 && (
            <Grid item={true} xs={12} sm={6} md={4} lg={3}>
              <Field
                name="distributor_id"
                label={localize("user", "Viszonteladó")}
                error={fieldErrors.distributor_id}
                validate={[Validations.required]}
                formName="users"
                //onChange={() => handleInputChange("distributor_id")}
                component={Autocomplete}
                mode="select"
                isViewPage={isViewPage}
                minChars={get(
                  this.props,
                  "baseFields.distributor_id.minChars",
                  2
                )}
                url={get(this.props, "baseFields.distributor_id.url", null)}
                change={this.props.change}
                labelKey={"text"}
                disabled={
                  enabledFields
                    ? enabledFields.indexOf("distributor_id") !== -1
                      ? false
                      : true
                    : false
                }
                onSelect={this.handleDistributorSelect}
              />
              {/*<Field
              name="distributor_id"
                label={localize("user", "Viszonteladó")}
              fieldError={fieldErrors.distributor_id}
              onChange={() => handleInputChange("distributor_id")}
              component={Autocomplete}
              //placeholder={localize("user", "Viszonteladó")}
              //placeholder=""
              isViewPage={isViewPage}
              minChars={get(
                this.props,
                "baseFields.distributor_id.minChars",
                2
              )}
              url={get(this.props, "baseFields.distributor_id.url", null)}
              labelKey={"distributor_name"}
                readOnly={
                  enabledFields && viewData.id
                  ? enabledFields.indexOf("distributor_id") !== -1
                    ? false
                      : true
                    : false
              }
              handleChangeAutocomplete={this.handleChangeAutocomplete.bind(
                this
              )}
              />*/}
            </Grid>
          )}

          {(this.props.userRole === "user" || authRules.roleIn("user")) &&
            window.location.href.indexOf("create") >= 0 && (
              <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <Field
                  name="client_id"
                  label={localize("user", "Ügyfél")}
                  error={fieldErrors.client_id}
                  //validate={[Validations.required]}
                  formName="users"
                  //onChange={() => handleInputChange("distributor_id")}
                  component={Autocomplete}
                  mode="select"
                  isViewPage={isViewPage}
                  minChars={get(this.props, "baseFields.client_id.minChars", 2)}
                  url={get(this.props, "baseFields.client_id.url", null)}
                  change={this.props.change}
                  labelKey={"text"}
                  disabled={
                    enabledFields
                      ? enabledFields.indexOf("client_id") !== -1
                        ? false
                        : true
                      : false
                  }
                  onSelect={this.handleClientSelect}
                />
              </Grid>
            )}
          <Grid item={true} xs={12} sm={6} md={4} lg={3}>
            <Field
              name="email"
              label={localize("user", "E-mail")}
              fieldError={fieldErrors.email}
              onChange={() => handleInputChange("email")}
              isRequired={true}
              validate={[Validations.required, Validations.email]}
              component={InputField}
              type="text"
              placeholder={localize("user", "E-mail")}
              isViewPage={isViewPage}
              readOnly={
                enabledFields && viewData.id
                  ? enabledFields.indexOf("email") !== -1
                    ? false
                    : true
                  : false
              }
            />
          </Grid>
          {window.location.href.indexOf("create") === -1 && !isAccountant && (
            <Fragment>
              {!isUser && (
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    name="card_number"
                    label={localize("user", "Igazolvány száma")}
                    fieldError={fieldErrors.card_number}
                    onChange={() => handleInputChange("card_number")}
                    component={InputField}
                    type="text"
                    placeholder={localize("user", "Igazolvány száma")}
                    isViewPage={isViewPage}
                    readOnly={
                      enabledFields && viewData.id
                        ? enabledFields.indexOf("card_number") !== -1
                          ? false
                          : true
                        : false
                    }
                  />
                </Grid>
              )}
              <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <Field
                  name="phone"
                  label={localize("user", "Telefonszám")}
                  fieldError={fieldErrors.phone}
                  onChange={() => handleInputChange("phone")}
                  isRequired
                  validate={[Validations.required, Validations.mobile_number]}
                  {...mobilNumberMask}
                  component={InputField}
                  type="text"
                  placeholder={localize("user", "Telefonszám")}
                  isViewPage={isViewPage}
                  readOnly={
                    enabledFields && viewData.id
                      ? enabledFields.indexOf("phone") !== -1
                        ? false
                        : true
                      : false
                  }
                />
              </Grid>
              {["distributor", "mainvendor", "vendor", "root"].indexOf(
                this.props.userRole
              ) >= 0 && (
                  <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <Field
                      name="sealnumber_of_cashregister"
                    label={localize("user", "Pénztárgép Plombanyomó száma")}
                      fieldError={fieldErrors.sealnumber_of_cashregister}
                      onChange={() =>
                      handleInputChange("sealnumber_of_cashregister")
                    }
                      component={InputField}
                      type="text"
                      placeholder={localize(
                        "user",
                        "Pénztárgép Plombanyomó száma"
                      )}
                      isViewPage={isViewPage}
                    readOnly={
                        enabledFields && viewData.id
                          ? enabledFields.indexOf(
                            "sealnumber_of_cashregister"
                          ) !== -1
                            ? false
                          : true
                          : false
                      }
                    />
                  </Grid>
              )}
              {/*<Grid item={true} xs={12} sm={6} md={4} lg={3}>
                <Field
                    name="client_cashregister_apn"
                    label={localize("user", "Ügyfél pénztárgépe")}
                    fieldError={fieldErrors.client_cashregister_apn}
                    onChange={() => handleInputChange('client_cashregister_apn')}
                    component={InputField}
                    type="text"
                    placeholder={localize("user", "Ügyfél pénztárgépe")}
                    isViewPage={isViewPage}
                    readOnly={enabledFields ? enabledFields.indexOf('client_cashregister_apn') !== -1 ? false : true : false}
                    {...apMask}
                />
    </Grid>*/}
            </Fragment>
          )}
        </EditForm>
        {initialValues && initialValues.id && (
          <Fragment>
            <UserClients
              user={initialValues}
              readOnly={isViewPage}
              //afterSave={this.props.afterLocationSave}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const UsersReduxForm = reduxForm({
  form: "users",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(UsersForm);

const selector = formValueSelector("users");

export default connect(state => {
  const userRole = selector(state, "role");
  return {
    initialValues: state.users.viewData,
    userRole
  };
})(UsersReduxForm);
