import React from "react";
import localize from "helpers/localize";
import restHelper from "helpers/restHelper";
import Select from "Components/New/Input/Select/Select";
import SendParamsEditorList from "./SendParamsEditorList";

import {
  Button,
  CircularProgress,
  Toolbar,
  Typography
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
import Validations from "Components/Forms/Validations";

const LC = "emailtemplates"; //Localization category

class Send extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      template_id: null,
      template: null,
      sending: false,
      errors: [],
      emails: [] //[{email: "", parameters: null}]
    };
  }

  componentDidMount() {
    this.fetchTemplates();
  }

  fetchTemplates = () => {
    restHelper.index("email-templates/templates").then(response => {
      let templates = {};
      (response.data || []).forEach(template => {
        templates[template.id] = template.subject;
      });
      this.setState({ templates });
    });
  };

  fetchTemplate = template_id => {
    restHelper.view("email-templates", template_id).then(response => {
      const emails = [{ to: "", parameters: null }];
      this.setState({ template: response.data, emails });
    });
  };

  onChangeTemplate = (item, value) => {
    this.setState({ template_id: value });
    this.fetchTemplate(value);
  };

  addEmail = () => {
    let { emails } = this.state;
    emails.push({ to: "", parameters: null });
    this.setState({ emails });
  };

  removeEmail = index => () => {
    let { emails } = this.state;
    emails.splice(index, 1);
    this.setState({ emails });
  };

  setParam = index => (parameter, value) => {
    let { emails } = this.state;
    if (!emails || !emails[index]) {
      return;
    }
    if (!emails[index].parameters) {
      emails[index].parameters = {};
    }
    emails[index].parameters[parameter] = value;
    this.setState({ emails });
  };

  setTo = index => value => {
    let { emails } = this.state;
    if (!emails || !emails[index]) {
      return;
    }
    emails[index].to = value;
    this.setState({ emails });
  };

  validate = () => {
    const { template, emails } = this.state;
    if (!template) {
      return false;
    }
    const errors = [];
    let valid = true;
    emails.forEach(email => {
      const e = this.validateEmail(email);
      if (e) {
        errors.push(e);
        valid = false;
      } else {
        errors.push(null);
      }
    });
    this.setState({ errors });
    return valid;
  };

  validateEmail = email => {
    const { template } = this.state;
    if (!template) {
      return false;
    }
    let errors = {};
    if (!email.to || email.to.trim() === "") {
      errors.__to = localize(LC, "Kötelező mező");
    } else {
      const emailVal = Validations.email(email.to.trim());
      if (emailVal) {
        errors.__to = localize(LC, "Hibás email formátum");
      }
    }

    if (template.parameters) {
      template.parameters.forEach(param => {
        if (
          !email.parameters ||
          !email.parameters[param] ||
          email.parameters[param].trim() === ""
        ) {
          errors[param] = localize(LC, "Kötelező mező");
        }
      });
    }
    return Object.keys(errors).length > 0 ? errors : null;
  };

  send = () => {
    const { emails, template_id } = this.state;
    if (!this.validate()) {
      return;
    }
    this.setState({ sending: true });
    restHelper
      .post(`email-templates/send/${template_id}`, "EmailForm", {
        parameters: emails
      })
      .then(response => {
        this.setState({ sending: false });
        globalMessage.success("Sikeres küldés");
      })
      .catch(error => {
        this.setState({ sending: false });
      });
  };

  render() {
    //const { client, readOnly, afterSave } = this.props;
    const {
      templates,
      template_id,
      template,
      emails,
      sending,
      errors
    } = this.state;

    return (
      <div style={{ width: "100%", position: "relative" }}>
        <Toolbar>
          <Typography variant="h6">
            {localize(LC, "Email kiküldése")}
          </Typography>
        </Toolbar>
        <Select
          id="template_id"
          label={localize(LC, "Sablon")}
          fullWidth
          value={template_id}
          onChange={this.onChangeTemplate}
          type="dropdown"
          options={templates}
          //error={error}
          isRequired
        />
        <SendParamsEditorList
          emails={emails}
          parameters={template && template.parameters}
          addEmail={this.addEmail}
          removeEmail={this.removeEmail}
          setParam={this.setParam}
          setTo={this.setTo}
          template_id={template_id}
          errors={errors}
        />
        <Toolbar disableGutters>
          <div style={{ marginLeft: "auto" }} />
          <Button
            disabled={sending || !template_id}
            color="primary"
            size="small"
            onClick={this.send}
          >
            {localize(LC, "Küldés")}{" "}
            {sending ? (
              <CircularProgress size={16} style={{ marginLeft: 12 }} />
            ) : (
              <SendIcon fontSize="small" style={{ marginLeft: 8 }} />
            )}
          </Button>
        </Toolbar>
      </div>
    );
  }
}

export default Send;
