import axios from "axios";
import qs from "qs";
import { get } from "lodash";

const USERLIST_LOAD = "USERLIST_LOAD";
const USERLIST_SUCCESS = "USERLIST_SUCCESS";
const USERLIST_FAILED = "USERLIST_FAILED";
const USERLIST_SET_LOADING = "USERLIST_SET_LOADING";

const USERDELETE_LOAD = "USERDELETE_LOAD";
const USERDELETE_SUCCESS = "USERDELETE_SUCCESS";
const USERDELETE_FAILED = "USERDELETE_FAILED";

const USER_EDIT_LOAD = "USER_EDIT_LOAD";
const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
const USER_EDIT_FAILED = "USER_EDIT_FAILED";

const USER_AUTHS_LOAD = "USER_AUTHS_LOAD";
const USER_AUTHS_SUCCESS = "USER_AUTHS_SUCCESS";
const USER_AUTHS_FAILED = "USER_AUTHS_FAILED";

const USERS_RESET = "USERS_RESET";
const USERS_RESET_FORM = "USERS_RESET_FORM";

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit: process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: "",
  orderMode: "ASC",
  deleted: false,
  deletedId: null,
  formErrors: {},
  access: {},
  userAccess: false,
  token: null,
  viewData: null,
  commentThread: false,
  id: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USERS_RESET:
      return {
        ...initialState,
        token: state.token
      };
    case USERS_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case USERDELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null
      };
    case USER_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {}
      };
    case USER_AUTHS_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        access: {},
        userAccess: false
      };
    case USERLIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null
      };
    case USER_AUTHS_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        access: {},
        userAccess: false
      };
    case USER_EDIT_FAILED:
    case USERDELETE_FAILED:
    case USERLIST_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors,
        deleted: true,
        deletedId: null
      };
    case USER_AUTHS_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        access: action.access,
        userAccess: action.userAccess || false,
        viewData: action.viewData || false,
        commentThread: action.commentThread || false
      };
    case USER_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        token: action.token,
        id: action.id
      };
    case USERDELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case USERLIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode,
        loading: false
      };
    case USERLIST_SET_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    default:
      return state;
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: USERS_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: USERS_RESET_FORM
  });
};

export const getUserProfile = id => dispatch => {
  dispatch({
    type: USER_EDIT_LOAD
  });
  return axios
    .get("/v1/users/" + id)
    .then(response =>
      dispatch({
        type: USER_AUTHS_SUCCESS,
        viewData: get(response, "data.data", false)
      })
    )
    .catch(error => {
      dispatch({
        type: USER_AUTHS_FAILED,
        message: get(error, "response.data.message", null)
      });
    });
};

export const authorizations = (withID, isViewPage) => dispatch => {
  dispatch({
    type: USER_AUTHS_LOAD
  });
  return axios
    .get("/v1/users/authorization")
    .then(response => {
      // if(withID) {
      //     return dispatch(
      //         getUserAuthorization(
      //             withID,
      //             isViewPage,
      //             get(response, 'data.data', {})
      //         )
      //     )
      // }
      // else {
      return dispatch({
        type: USER_AUTHS_SUCCESS,
        access: get(response, "data.data", {})
      });
      // }
    })
    .catch(error => {
      dispatch({
        type: USER_AUTHS_FAILED,
        message: get(error, "response.data.message", null)
      });
    });
};

export const updateUser = (model, id, data) => dispatch => {
  dispatch({
    type: USER_EDIT_LOAD
  });
  let obj = {};
  obj[model] = data;
  return axios
    .put("/v1/users/" + id, obj)
    .then(response => {
      dispatch({
        type: USER_EDIT_SUCCESS
      });
      return response.data;
    })
    .catch(error => {
      dispatch({
        type: USER_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const createUser = (model, data) => dispatch => {
  dispatch({
    type: USER_EDIT_LOAD
  });
  let obj = {};
  obj[model] = data;
  return axios
    .post("/v1/users/invite", obj)
    .then(response => {
      dispatch({
        type: USER_EDIT_SUCCESS,
        id: get(response, "data.data.id", null)
      });
      return response.data;
    })
    .catch(error => {
      dispatch({
        type: USER_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const userEditApproval = id => (dispatch, getState) => {
  dispatch({
    type: USER_EDIT_LOAD
  });

  return axios
    .post("/v1/users/approval/" + id)
    .then(response => {
      return dispatch({
        type: USER_EDIT_SUCCESS,
        viewData: Object.assign(getState().users.viewData, {
          status: get(response, "data.data.status", "active")
        })
      });
    })
    .catch(error => {
      return dispatch({
        type: USER_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: {}
      });
    });
};

export const userListApproval = id => dispatch => {
  dispatch({
    type: USERDELETE_LOAD
  });
  return axios
    .post("/v1/users/approval/" + id)
    .then(response =>
      dispatch({
        type: USERDELETE_SUCCESS,
        id
      })
    )
    .catch(error =>
      dispatch({
        type: USERDELETE_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};

export const userDelete = id => dispatch => {
  dispatch({
    type: USERDELETE_LOAD
  });
  return axios
    .delete("/v1/users/" + id)
    .then(response =>
      dispatch({
        type: USERDELETE_SUCCESS,
        id
      })
    )
    .catch(error =>
      dispatch({
        type: USERDELETE_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};
export const changePassword = (id, password) => {
  return axios
    .post("/v1/users/password-change/" + id, { User: { password: password } })
    .then(response => response.data)
    .catch(error => {
      throw error.response;
    });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: USERLIST_SET_LOADING,
    loading
  });
};

export const usersList = (
  page,
  keyword,
  sort,
  filters,
  perPage,
  section
) => dispatch => {
  dispatch({
    type: USERLIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    "per-page": perPage,
    section
  };

  params[filters.model] = filters.filters;

  return axios
    .get(
      "/v1/users",
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then(response => {
      let sort = get(response, "data.sort", null),
        mode = "ASC";
      if (sort && sort.indexOf("-") !== -1) {
        mode = "DESC";
        sort = sort.substr(1);
      }
      return dispatch({
        type: USERLIST_SUCCESS,
        data: get(response, "data.data", null),
        page: get(response, "data.page", 0),
        limit: get(response, "data.per-page", 0),
        total: get(response, "data.total", 0),
        order: sort,
        orderMode: mode
      });
    })
    .catch(error =>
      dispatch({
        type: USERLIST_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};
