import React from "react";
import PropTypes from "prop-types";
import ContainerHeader from "Components/ContainerHeader/index";
import Cashregisters from "./Cashregisters";
import ClientSelector from "./ClientSelector";
import localize from "helpers/localize";
import PageMenu from "Components/New/Menu/PageMenu/PageMenu";
import PageMenuContent from "Components/New/Menu/PageMenu/PageMenuContent";
import Accounting from "./Accounting";
import Products from "./Products";
import Queue from "./Queue";
import authRules from "helpers/authRules";
import {
  CLOUD_CASHREGISTER_ACCOUNTING,
  CLOUD_QUEUE
} from "constans/permissions";
import Modal from "Components/Modal/Modal";
import restHelper from "helpers/aee/restHelper";

const LC = "cloud"; //Localization category
const PATH_BASE = "/cloud";
const PATH_CASHREGISTER = "/cashregisters";
const PATH_PRODUCTS = "/products";
const PATH_ACCOUNTING = "/accounting";
const PATH_QUEUE = "/queue";

class Cloud extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAccepted: false,
      termsLoading: false,
      termsAcceptLoading: false
    };
  }

  /**
   * Routolás tabváltozás esetén
   */
  handleTabChange = (evt, pathname, params) => {
    /* console.log("Cloud.handleTabChange", evt, pathname, params);
    const { submenu, id } = this.cashregisterProps;
    let p = pathname;
    //Pénztárgépmenü esetén hozzáadjuk a megjegyzett paramétereket
    if (pathname === PATH_CASHREGISTER) {
      if (submenu) {
        p += submenu;
      }
      if (id) {
        p += "/" + id;
      }
      this.props.history.replace(p);
    }*/
  };

  componentDidUpdate(prevProps) {
    const { client_id } = this.props.match.params;
    const { client_id: client_id_prev } = prevProps.match.params;

    //Ügyfél változott
    if (
      client_id &&
      client_id !== client_id_prev &&
      this.state.termsLoading === false
    ) {
      this.getStatementAccept();
    }
  }

  componentDidMount() {
    const { client_id } = this.props.match.params;
    if (client_id) {
      this.getStatementAccept();
    }
  }

  getStatementAccept = () => {
    const { client_id } = this.props.match.params;
    this.setState({ termsLoading: true, termsAccepted: false });
    restHelper
      .index("identity/terms", null, { client: client_id })
      .then(response => {
        if (response && response.data && response.data.accepted) {
          this.setState({ termsLoading: false, termsAccepted: true });
        } else {
          this.setState({ termsLoading: false, termsAccepted: false });
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ termsLoading: false, termsAccepted: false });
      });
  };

  onAcceptStatement = () => {
    const { client_id } = this.props.match.params;
    restHelper
      .post("identity/terms", null, null, { client: client_id })
      .then(response => {
        this.setState({
          termsAcceptLoading: false,
          termsAccepted: true
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          termsAcceptLoading: false,
          termsAccepted: false
        });
      });
  };

  onCancelStatement = () => {
    this.setState({ termsAccepted: false }, () => {
      this.props.history.push("/");
    });
  };

  render() {
    console.log("Cloud.render", this.props.match.params);
    const { client_id, menu } = this.props.match.params;
    const { termsLoading, termsAccepted, termsAcceptLoading } = this.state;

    /*const LeftToolBar = () => (
      <div
        style={{
          width: 300,
          display: "flex",
          flexDirection: "column",
          marginTop: -10,
          marginLeft: 16
          //flexBasis: "100%",
          //flex: 1
        }}
      >
        <ClientSelector match={this.props.match} history={this.props.history} />
      </div>
    );*/

    return (
      <div style={{ position: "relative" }}>
        <ContainerHeader title={localize(LC, "Felhő")} />
        {!client_id && (
          <ClientSelector
            match={this.props.match}
            history={this.props.history}
          />
        )}
        {!termsLoading && client_id && (
          <Modal
            loading={termsAcceptLoading}
            open={!termsAccepted}
            yesText={localize(LC, "Elfogadom")}
            noText={localize(LC, "Vissza a főoldalra")}
            title={localize(LC, "Felhő feltételek elfogadása.")}
            onAccept={this.onAcceptStatement}
            onCancel={this.onCancelStatement}
            onClose={this.onCancelStatement}
          >
            {localize(LC, "Belegyezik?")}
          </Modal>
        )}
        {client_id && termsAccepted && (
          <PageMenu
            key={client_id}
            LeftToolBar={
              client_id && (
                <div
                  style={{
                    width: 300,
                    display: "flex",
                    flexDirection: "column",
                    //marginTop: -10,
                    alignSelf: "center",
                    marginLeft: 16
                    //flexBasis: "100%",
                    //flex: 1
                  }}
                >
                  <ClientSelector
                    match={this.props.match}
                    history={this.props.history}
                  />
                </div>
              )
            }
            label={localize(LC, "Ügyfél kiválasztása")}
            basePath={`${PATH_BASE}/${client_id}`}
            onChange={this.handleTabChange}
            defaultPath={PATH_CASHREGISTER}
            color="primary"
          >
            <PageMenuContent
              path={PATH_CASHREGISTER}
              title={localize(LC, "Pénztárgépek")}
            >
              <Cashregisters client_id={client_id} />
            </PageMenuContent>
            {authRules.canViewByKey(CLOUD_CASHREGISTER_ACCOUNTING) && (
              <PageMenuContent
                path={PATH_ACCOUNTING}
                title={localize(LC, "Könyvelés")}
              >
                <Accounting client_id={client_id} />
              </PageMenuContent>
            )}
            <PageMenuContent
              path={PATH_PRODUCTS}
              title={localize(LC, "Termékek")}
            >
              <Products client_id={client_id} />
            </PageMenuContent>
            {authRules.canViewByKey(CLOUD_QUEUE) && (
              <PageMenuContent
                path={PATH_QUEUE}
                title={localize(LC, "Folyamatok")}
              >
                <Queue client_id={client_id} />
              </PageMenuContent>
            )}
          </PageMenu>
        )}
      </div>
    );
  }
}

Cloud.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default Cloud;
