import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CartWrapper from "./CartWrapper";
import CartIcon from "@material-ui/icons/ShoppingCart";
import { Badge, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { BASE_PATH_CART } from "Containers/Pages/Shop/shopPath";
const styles = theme => ({
  bell: {
    WebkitAnimation: "ring 4s .7s ease-in-out 2",
    WebkitTransformOrigin: "50% 4px",
    MozAnimation: "ring 4s .7s ease-in-out 2",
    MozTransformOrigin: "50% 4px",
    animationName: "$ring 4s .7s ease-in-out 2",
    transformOrigin: "50% 4px"
  },
  "@keyframes ring": {
    "0%": { transform: "rotate(0)" },
    "1%": { transform: "rotate(30deg)" },
    "3%": { transform: "rotate(-28deg)" },
    "5%": { transform: "rotate(34deg)" },
    "7%": { transform: "rotate(-32deg)" },
    "9%": { transform: "rotate(30deg)" },
    "11%": { transform: "rotate(-28deg)" },
    "13%": { transform: "rotate(26deg)" },
    "15%": { transform: "rotate(-24deg)" },
    "17%": { transform: "rotate(22deg)" },
    "19%": { transform: "rotate(-20deg)" },
    "21%": { transform: "rotate(18deg)" },
    "23%": { transform: "rotate(-16deg)" },
    "25%": { transform: "rotate(14deg)" },
    "27%": { transform: "rotate(-12deg)" },
    "29%": { transform: "rotate(10deg)" },
    "31%": { transform: "rotate(-8deg)" },
    "33%": { transform: "rotate(6deg)" },
    "35%": { transform: "rotate(-4deg)" },
    "37%": { transform: "rotate(2deg)" },
    "39%": { transform: "rotate(-1deg)" },
    "41%": { transform: "rotate(1deg)" },

    "43%": { transform: "rotate(0)" },
    "100%": { transform: "rotate(0)" }
  },
  toggleIconButton: {
    padding: 0,
    color: "white"
  },
  readIconButton: {
    padding: 0
  },
  badge: {
    //marginLeft: "auto"
  }
});

class CartNotification extends React.Component {
  render() {
    const { theme, classes, items, total } = this.props;
    const colorProps = {};

    if (theme.header.iconColor) {
      colorProps.color = theme.header.iconColor;
    }
    if (items.length === 0) {
      return "";
    }
    return (
      <Badge
        key={`badge_${total}`}
        badgeContent={total}
        color="error"
        className={classes.badge}
      >
        <IconButton
          className={classes.toggleIconButton}
          //onClick={this.togglePopper}
          component={Link}
          to={BASE_PATH_CART}
          buttonRef={this.anchorEl}
        >
          <CartIcon
            key={`icon_${total}`}
            className={classes.bell}
            {...colorProps}
          />
        </IconButton>
      </Badge>
    );
  }
}

CartNotification.defaultProps = {
  type: "icon"
};

CartNotification.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  items: PropTypes.array,
  total: PropTypes.number
};

export default withStyles(styles, { withTheme: true })(
  CartWrapper(CartNotification)
);
