import React from "react";
import AsyncSelect from "react-select/async";
import ReactSelect, { components } from "react-select";
import PropTypes from "prop-types";
import restHelper from "helpers/restHelper";
import localize from "helpers/localize";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import { FormHelperText } from "@material-ui/core";
import { isNumber } from "util";

const styles = theme => ({
  label: { marginTop: -1 },
  formControl: {
    paddingTop: theme.spacing.unit * 2
  },
  formControlNoLabel: {
    paddingTop: 0
  }
});

const customStyles = theme => {
  return {
    option: (provided, state) => ({
      ...provided
      //borderBottom: "1px dotted pink",
      //color: state.isSelected ? "red" : "blue"
      //backgroundColor: state.isSelected ? "red" : "blue"
      //padding: 20
    }),
    menu: (provided, state) => ({
      ...provided,
      //borderBottom: "1px dotted pink",
      //color: state.isSelected ? "red" : "blue"
      //backgroundColor: state.isSelected ? "red" : "blue"
      "& .hidden-on-list": {
        display: "none"
      },
      zIndex: 2000
    }),
    control: (provided, state) => {
      let s = {};

      if (state.isFocused) {
        s.borderColor = theme.palette.primary.main;
        s.boxShadow = `${theme.palette.primary.main} 0 0 0 1px`;
        s["&:hover"] = {
          boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
          borderColor: theme.palette.primary.main
        };
      }
      if (state.isDisabled) {
        s.borderColor = "#ced4da";
        s.backgroundColor = "none";
      }

      if (state.selectProps.error) {
        s.borderColor = theme.palette.error.main;
        if (state.isFocused) {
          s.boxShadow = `${theme.palette.error.main} 0 0 0 1px`;
        }
        s["&:hover"] = {
          //boxShadow: `${theme.palette.error.main} 0 0 0 1px`,
          borderColor: theme.palette.error.main
        };
      }

      return {
        // none of react-select's styles are passed to <Control />
        ...provided,
        minHeight: "auto",
        ...s
      };
    },
    dropdownIndicator: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      paddingTop: 0,
      paddingBottom: 0
    }),
    clearIndicator: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      paddingTop: 0,
      paddingBottom: 0
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        "& .hidden-on-selected": {
          display: "none"
        }
      };
    }
  };
};

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.getOptionFromValue(props.value)
    };
  }

  componentDidMount() {
    //this.loadSelectedOption();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { type, options, value, valueKey, labelKey, isMulti } = this.props;
    if (type === "dropdown") {
      if (this.props.value !== nextProps.value) {
        this.setState({ value: this.getOptionFromValue(nextProps.value) });
      }
    } else if (type === "autocomplete" && isMulti) {
      if (
        JSON.stringify(this.props.value) !== JSON.stringify(nextProps.value)
      ) {
        if (Array.isArray(nextProps.value) || nextProps.value === null) {
          console.log("recive VAL", nextProps.value);
          this.setState({ value: nextProps.value });
        }
      }
    }
  }

  getOptionFromValue = value => {
    const { type, options, valueKey, labelKey, isMulti } = this.props;
    if (type === "dropdown") {
      if (options) {
        if (value || value === 0) {
          if (options[value]) {
            return { label: options[value], value };
          }
        }
      }
    } else if (type === "autocomplete" && isMulti) {
      if (Array.isArray(value) || value === null) {
        console.log("VAL", value);
        return value;
      }
    }
    return null;
  };

  /* loadSelectedOption = () => {
    const {
      url,
      value,
      restHelper,
      restHelperOptions,
      requestParams
    } = this.props;
    if (!value) {
      return;
    }
    const params = {
      //page: 1,
      //["per-page"]: 20,
      //sort: `${labelKey}`,
      //[`${modelClass}[${labelKey}]`]: inputValue
      id: value,
      ...requestParams
    };

    restHelper.index(url, params, restHelperOptions).then(response => {
      console.log(response.data);
    });
  };*/

  loadOptions = (inputValue, callback) => {
    const { restHelper, restHelperOptions, valueKey } = this.props;
    //Egyedi ajax függvény megadása
    if (this.props.loadOptions) {
      this.props.loadOptions(inputValue, callback);
      return;
    }

    const {
      url,
      labelKey,
      modelClass,
      requestParams,
      value,
      selectIfOne
    } = this.props;
    const params = {
      //page: 1,
      //["per-page"]: 20,
      //sort: `${labelKey}`,
      //[`${modelClass}[${labelKey}]`]: inputValue
      keyword: inputValue || inputValue !== "" ? inputValue : "*empty_string*",
      [valueKey]: value,
      ...requestParams
    };

    restHelper.index(url, params, restHelperOptions).then(response => {
      callback(Array.isArray(response.data) ? response.data : []); //TODO sima response.data, ha szerver találat nélkül nem {}-ot ad vissza, hanem null vagy []

      if (value && response.data && response.data.length > 0) {
        this.setState({
          value: response.data[0]
        });
      } else if (selectIfOne && response.data && response.data.length === 1) {
        this.setState({
          value: response.data[0]
        });
        this.onChange(response.data[0]);
      }
    });
  };

  getOptionLabel = option => {
    const { labelKey, getOptionLabel } = this.props;
    return getOptionLabel ? getOptionLabel(option) : option[labelKey];
  };
  getOptionValue = option => {
    const { valueKey, getOptionValue } = this.props;
    return getOptionValue ? getOptionValue(option) : option[valueKey];
  };

  onChange = (option, evt) => {
    const { onChange, valueKey, labelKey, type } = this.props;
    this.setState({
      value: option
    });
    if (type === "dropdown") {
      onChange &&
        onChange(
          option,
          option &&
            (!isNaN(option.value) ? parseInt(option.value) : option.value),
          valueKey,
          labelKey,
          evt
        );
    } else {
      onChange &&
        onChange(option, option && option[valueKey], valueKey, labelKey, evt);
    }
  };

  transofrmOptions = options => {
    if (Array.isArray(options)) {
      return options;
    } else if (typeof options === "object") {
      return Object.keys(options).map(key => {
        return { label: options[key], value: key };
      });
    }
    return [];
  };
  render() {
    /* eslint-disable no-unused-vars */
    const {
      classes,
      onChange,
      url,
      valueMap,
      labelKey,
      valueKey,
      modelClass,
      localizationCategory,
      getOptionLabel,
      getOptionValue,
      isClearable,
      fullWidth,
      id,
      value,
      label,
      type,
      options,
      error,
      helperText,
      disabled,
      theme,
      onMultiLabelClick,
      ...rest
    } = this.props;
    const { value: option } = this.state;
    /* eslint-enable no-unused-vars */
    //console.log(option);

    const MultiValueLabel = props => {
      console.log(props);
      return (
        <div
          onClick={evt => {
            if (onMultiLabelClick) {
              //props.selectProps.openMenuOnClick = false;
              evt.stopPropagation();
              evt.preventDefault();
              onMultiLabelClick(evt, props);
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <components.MultiValueLabel {...props} />
        </div>
      );
    };

    const renderInput = () => {
      switch (type) {
        case "autocomplete":
          return (
            <AsyncSelect
              cacheOptions
              loadOptions={this.loadOptions}
              onInputChange={this.handleInputChange}
              isClearable={isClearable}
              onChange={this.onChange}
              getOptionLabel={this.getOptionLabel}
              getOptionValue={this.getOptionValue}
              loadingMessage={() => localize("select", "Betöltés")}
              noOptionsMessage={() => localize("select", "Nincs eredmény")}
              placeholder={localize("select", "Válasszon...")}
              styles={customStyles(theme)}
              error={error ? true : false}
              value={option}
              isDisabled={disabled}
              components={onMultiLabelClick ? { MultiValueLabel } : {}}
              {...rest}
            />
          );
        case "dropdown":
        default:
          return (
            <ReactSelect
              //cacheOptions
              //loadOptions={this.loadOptions}
              defaultOptions
              //onInputChange={this.handleInputChange}
              isClearable={isClearable}
              onChange={this.onChange}
              //getOptionLabel={this.getOptionLabel}
              //getOptionValue={this.getOptionValue}
              loadingMessage={() => localize("select", "Betöltés")}
              noOptionsMessage={() => localize("select", "Nincs eredmény")}
              placeholder={localize("select", "Válasszon...")}
              styles={customStyles(theme)}
              options={this.transofrmOptions(options)}
              error={error ? true : false}
              isDisabled={disabled}
              {...rest}
              value={option}
            />
          );
      }
    };

    return (
      <FormControl
        fullWidth={fullWidth}
        className={label ? classes.formControl : classes.formControlNoLabel}
        error={error ? true : false}
      >
        <InputLabel
          shrink
          htmlFor={id}
          className={classes.label}
          error={error ? true : false}
        >
          {label}
        </InputLabel>
        {renderInput()}
        {(error || helperText) && (
          <FormHelperText error={error ? true : false}>
            {error || helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

Select.defaultProps = {
  id: "select-field",
  valueKey: "id",
  labelKey: "text",
  localizationCategory: "select",
  isClearable: true,
  type: "dropdown",
  restHelper: restHelper
};

Select.propTypes = {
  onChange: PropTypes.func,
  theme: PropTypes.object,
  url: PropTypes.string,
  valueMap: PropTypes.func,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  modelClass: PropTypes.string,
  localizationCategory: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  isClearable: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  type: PropTypes.oneOf(["dropdown", "autocomplete"]),
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  requestParams: PropTypes.object,
  restHelper: PropTypes.object,
  restHelperOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]), //Bool esetén az ignoreCache-nek felel meg
  selectIfOne: PropTypes.bool, //Amennyiben true, akkor ha egy elemű lista jön le kiválasztja az elsőt
  loadOptions: PropTypes.func, //Egyedi ajax megadása loadOptions(inputValue, callback) ahol a callback benő paramétere a lista: callback(response.data)
  onMultiLabelClick: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(Select);
