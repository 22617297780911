import { reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AnnualReviewFormBase from "./AnnualReviewFormBase";

const AnnualReviewFormPublic = reduxForm({
  form: "annualReviewsPublic",
  enableReinitialize: false,
  keepDirtyOnReinitialize: false,
  updateUnregisteredFields: false
})(AnnualReviewFormBase);

export default connect()(AnnualReviewFormPublic);
