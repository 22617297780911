import axios from "axios";
import { get } from "lodash";
import FileSaver from "file-saver";
import qs from "qs";

export const FILE_UPLOAD_LOAD = "FILE_UPLOAD_LOAD";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED";

export const FILE_DELETE_LOAD = "FILE_DELETE_LOAD";
export const FILE_DELETE_SUCCESS = "FILE_DELETE_SUCCESS";
export const FILE_DELETE_FAILED = "FILE_DELETE_FAILED";

export const FILE_DOWNLOAD_LOAD = "FILE_DOWNLOAD_LOAD";
export const FILE_DOWNLOAD_SUCCESS = "FILE_DOWNLOAD_SUCCESS";
export const FILE_DOWNLOAD_FAILED = "FILE_DOWNLOAD_FAILED";

const initialState = {
  failed: false,
  success: false,
  errors: null,
  message: false,
  filename: false,
  id: false,
  url: false,
  formErrors: {},
  file: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FILE_UPLOAD_LOAD:
    case FILE_DELETE_LOAD:
    case FILE_DOWNLOAD_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        success: false,
        errors: null,
        message: false,
        filename: false,
        id: false,
        file: false,
        formErrors: {}
      };
    case FILE_DELETE_SUCCESS:
    case FILE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false
      };
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        message: action.message,
        filename: action.filename,
        id: action.id,
        url: action.url,
        file: action.file
      };
    case FILE_UPLOAD_FAILED:
    case FILE_DELETE_FAILED:
    case FILE_DOWNLOAD_FAILED:
      return {
        ...state,
        loading: false,
        message: action.message,
        failed: true,
        formErrors: action.formErrors
      };
    default:
      return state;
  }
};

export const download = (path, name = "", params = false) => dispatch => {
  dispatch({
    type: FILE_DOWNLOAD_LOAD
  });
  return axios({
    url: path,
    method: "GET",
    responseType: "blob", // important
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    params: params
  })
    .then(response => {
      FileSaver.saveAs(new Blob([response.data]), name);
      return dispatch({
        type: FILE_DOWNLOAD_SUCCESS
      });
    })
    .catch(error => {
      return dispatch({
        type: FILE_DOWNLOAD_FAILED,
        message: get(error, "response.data.message", null)
      });
    });
};

export const upload = file => dispatch => {
  const formData = new FormData();
  formData.append("file", file);
  dispatch({
    type: FILE_UPLOAD_LOAD
  });
  return axios
    .post("/v1/files", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response =>
      dispatch({
        type: FILE_UPLOAD_SUCCESS,
        id: get(response, "data.data.id", false),
        url: get(response, "data.data.url", false),
        data: get(response, "data.data", null),
        file
      })
    )
    .catch(error => {
      return dispatch({
        type: FILE_UPLOAD_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
    });
};

export const fetchFile = path => {
  return axios
    .get(path, {
      responseType: "arraybuffer"
    })
    .then(response => new Uint8Array(response.data))
    .catch(error => false);
};

export const fileDelete = id => dispatch => {
  dispatch({
    type: FILE_DELETE_LOAD
  });

  return axios
    .delete("/v1/files/" + id)
    .then(response =>
      dispatch({
        type: FILE_DELETE_SUCCESS
      })
    )
    .catch(error => {
      return dispatch({
        type: FILE_DELETE_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
    });
};
