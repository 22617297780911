import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";


import List from "../../../../../Components/List/List";
import ContainerHeader from "../../../../../Components/ContainerHeader";
import Navbar from "../../Navbar";
import localize from "helpers/localize";

class IncomingSmsList extends PureComponent {
  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("sms", "Bejövő SMS")}</title>
        </Helmet>
        <Navbar />
        <ContainerHeader title={localize("sms", "Bejövő SMS")} />

        <List {...this.props} disabledCreate={true} disabledTools={true} />
      </div>
    );
  }
}

export default IncomingSmsList;
