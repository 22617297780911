const errorHelper = {
  parseFieldErrors: fieldErrors => {
    let errors = {};
    if (typeof fieldErrors === "object") {
      for (let key in fieldErrors) {
        errors[key] = fieldErrors[key].join(", ");
      }
    }
    return errors;
  }
};

export default errorHelper;
