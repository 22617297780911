import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CrulesList from "./views/CrulesList";
import * as crulesActions from "../../../../redux/modules/crules";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.crules.loading,
    success: state.crules.success,
    message: state.crules.message,
    data: state.crules.data,
    total: state.crules.total,
    limit: state.crules.limit,
    page: state.crules.page,
    order: state.crules.order,
    orderMode: state.crules.orderMode,
    deleted: state.crules.deleted,
    deletedId: state.crules.deletedId,
    token: state.crules.token,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: crulesActions.crulesList,
      setLoading: crulesActions.setLoading,
      reset: crulesActions.reset,
      delete: crulesActions.cruleDelete
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CrulesList);
