import axios from "axios";
import cookie from "react-cookies";
import qs from "qs";
import { get } from "lodash";
import * as fcmActions from "./fcm";
import * as signingActions from "./signing";
import authRules from "helpers/authRules";

export const USER_LOGIN_LOAD = "USER_LOGIN_LOAD";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";

export const USER_LOST_LOAD = "USER_LOST_LOAD";
export const USER_LOST_SUCCESS = "USER_LOST_SUCCESS";
export const USER_LOST_FAILED = "USER_LOST_FAILED";

export const USER_LOSTACTIVATE_LOAD = "USER_LOSTACTIVATE_LOAD";
export const USER_LOSTACTIVATE_SUCCESS = "USER_LOSTACTIVATE_SUCCESS";
export const USER_LOSTACTIVATE_FAILED = "USER_LOSTACTIVATE_FAILED";

export const USER_LOSTSETPASS_LOAD = "USER_LOSTSETPASS_LOAD";
export const USER_LOSTSETPASS_FAILED = "USER_LOSTSETPASS_FAILED";

export const USER_REGTOKEN_LOAD = "USER_REGTOKEN_LOAD";
export const USER_REGTOKEN_SUCCESS = "USER_REGTOKEN_SUCCESS";
export const USER_REGTOKEN_FAILED = "USER_REGTOKEN_FAILED";

export const USER_EMAILTOKEN_LOAD = "USER_EMAILTOKEN_LOAD";
export const USER_EMAILTOKEN_SUCCESS = "USER_EMAILTOKEN_SUCCESS";
export const USER_EMAILTOKEN_FAILED = "USER_EMAILTOKEN_FAILED";

export const USER_REGISTRATION_LOAD = "USER_REGISTRATION_LOAD";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const USER_REGISTRATION_FAILED = "USER_REGISTRATION_FAILED";

export const USER_CHECK_LOGIN = "USER_CHECK_LOGIN";
export const USER_LOGOUTED = "USER_LOGOUTED";

export const USER_RESET = "USER_RESET";
export const USER_RESET_FORM = "USER_RESET_FORM";
export const SET_USERDATA = "SET_USERDATA";
export const SET_DISTRIBUTORDATA = "SET_DISTRIBUTORDATA";

const initialState = {
  loading: false,
  logined: false,
  failed: false,
  success: false,
  tokenSuccess: false,
  errors: null,
  message: false,
  user: null,
  regTokenCheck: {
    loading: false,
    success: false,
    message: null,
    error: null,
    hasDistributor: null,
    isDistributorUser: null
  },
  permissions: null,
  formErrors: {},
  authToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOSTSETPASS_FAILED:
      return {
        ...state,
        loading: false,
        failed: true,
        message: action.message
      };
    case USER_LOSTSETPASS_LOAD:
      return {
        ...state,
        loading: true,
        failed: false
      };
    case USER_LOGIN_LOAD:
      return {
        ...state,
        loading: true,
        logined: false,
        user: null,
        errors: null,
        failed: false,
        success: false
      };
    case USER_REGISTRATION_LOAD: {
      return {
        ...state,
        loading: true,
        formErrors: {}
      };
    }
    case USER_EMAILTOKEN_LOAD:
      return {
        ...state,
        regTokenCheck: {
          loading: true
        }
      };
    case USER_REGTOKEN_LOAD:
    case USER_LOST_LOAD:
    case USER_LOSTACTIVATE_LOAD:
      return {
        ...state,
        loading: true,
        logined: false,
        user: null,
        errors: null,
        failed: false,
        success: false,
        tokenSuccess: false
      };
    case USER_REGISTRATION_FAILED:
      return {
        ...state,
        loading: false,
        message: action.message,
        formErrors: action.formErrors,
        failed: true
      };
    case USER_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        //formErrors: action.formErrors,
        failed: false
      };
    case USER_REGTOKEN_FAILED:
      return {
        ...state,
        regTokenCheck: {
          loading: false,
          error: action.message
        }
      };
    case USER_LOGIN_FAILED:
    case USER_LOST_FAILED:
    case USER_LOSTACTIVATE_FAILED:
    case USER_EMAILTOKEN_FAILED:
      return {
        ...state,
        loading: false,
        logined: false,
        user: null,
        errors: action.data,
        message: action.message,
        failed: true,
        success: false,
        tokenSuccess: false
      };
    case USER_LOGOUTED:
      try {
        localStorage.removeItem("access_token");
      } catch (err) {
        cookie.remove("access_token", { path: "/" });
      }
      axios.defaults.headers.common["Authorization"] = null;
      return {
        ...state,
        loading: false,
        logined: false,
        user: null,
        errors: null,
        failed: false,
        success: false,
        authToken: null
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        logined: true,
        permissions: action.permissions,
        failed: false,
        success: true,
        authToken: action.authToken
      };
    case SET_USERDATA:
      return {
        ...state,
        user: action.user
      };
    case SET_DISTRIBUTORDATA:
      return {
        ...state,
        distributor: action.distributor
      };
    case USER_LOST_SUCCESS:
      return {
        ...state,
        loading: false,
        logined: false,
        token: action.token,
        failed: false,
        success: true
      };
    case USER_REGTOKEN_SUCCESS:
      return {
        ...state,
        regTokenCheck: {
          loading: false,
          success: action.data.success,
          error: null,
          hasDistributor: action.data.data.hasDistributor,
          isDistributorUser: action.data.data.isDistributorUser,
          role: action.data.data.role,
          message: action.data.message
        }
      };
    case USER_EMAILTOKEN_SUCCESS:
    case USER_LOSTACTIVATE_SUCCESS:
      return {
        ...state,
        loading: false,
        logined: false,
        failed: false,
        tokenSuccess: true,
        distributor: action.distributor
      };
    case USER_RESET:
      return {
        ...state,
        failed: false,
        success: false,
        tokenSuccess: false,
        errors: null,
        loading: false,
        message: null,
        token: null,
        formErrors: {}
      };
    case USER_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case USER_CHECK_LOGIN:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export const reset = () => dispatch => dispatch({ type: USER_RESET });
export const resetForm = () => dispatch => dispatch({ type: USER_RESET_FORM });

export const logout = () => dispatch => {
  dispatch(fcmActions.disconnect());
  dispatch({
    type: USER_LOGOUTED
  });
};

export const me = access_token => dispatch => {
  dispatch({
    type: USER_CHECK_LOGIN
  });
  return axios
    .get("/v1/user/profile")
    .then(response =>
      dispatch(
        authorization(
          Object.assign(response.data.data, {
            root: response.data.data.role === "root"
          }),
          access_token
        )
      )
    )
    .catch(error => dispatch(logout()));
};

export const distributor = () => dispatch => {
  // dispatch({
  //     type: USER_CHECK_LOGIN
  // });
  return axios
    .get("/v1/user/distributor")
    .then(response =>
      dispatch({
        type: SET_DISTRIBUTORDATA,
        distributor: get(response, "data.data", null)
      })
    )
    .catch(error => dispatch(logout()));
};

export const authorization = (user, access_token) => dispatch => {
  dispatch({
    type: USER_CHECK_LOGIN
  });
  authRules.setIsRoot(user.root);
  authRules.setUser(user);
  //
  let result = user.root
    ? dispatch({
        type: USER_LOGIN_SUCCESS,
        authToken: access_token
    })
    : axios
        .get("/v1/user/authorization")
        .then(response => {
        authRules.setPermissions(response.data.data);
        dispatch({
          type: USER_LOGIN_SUCCESS,
          permissions: response.data.data,
          authToken: access_token
        });
      })
      .catch(() => dispatch(logout()));
  // let result = axios.get('/v1/user/authorization')
  //     .then(response => dispatch({
  //         type: USER_LOGIN_SUCCESS,
  //         permissions: response.data.data,
  //         authToken: access_token
  //     }))
  //     .catch(error => dispatch(logout()));

  dispatch(setUserData(user));

  if (["distributor", "mainvendor", "vendor", "root"].indexOf(user.role) >= 0) {
    dispatch(distributor());
  }
  dispatch(fcmActions.initalize());
  dispatch(signingActions.checkCode());
  return result;
};

export const setUserData = user => dispatch =>
  dispatch({
    type: SET_USERDATA,
    user
  });

export const setToken = () => dispatch => {
  let access_token;
  try {
    access_token = localStorage.getItem("access_token");
  } catch (err) {
    try {
      access_token = cookie.load("access_token");
    } catch (err) {
      dispatch({
        type: USER_LOGIN_FAILED
      });
    }
  }
  axios.defaults.headers.common["Authorization"] = access_token;
  dispatch(me(access_token));
};

export const login = (username, password) => dispatch => {
  dispatch({
    type: USER_LOGIN_LOAD
  });
  return axios
    .post("/v1/oauth2/token", {
      grant_type: "password",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      username: username,
      password: password
    })
    .then(response => {
      const access_token =
        response.data.token_type + " " + response.data.access_token;
      try {
        localStorage.setItem("access_token", access_token);
      } catch (err) {
        try {
          cookie.save("access_token", access_token, { path: "/" });
        } catch (err) {
          dispatch({
            type: USER_LOGIN_FAILED
          });
        }
      }
      dispatch(setToken());
      return response.data;
    })
    .catch(error => {
      dispatch({ type: USER_LOGIN_FAILED });
      throw error.response;
    });
};

export const lostPassword = email => dispatch => {
  dispatch({
    type: USER_LOST_LOAD
  });
  return axios
    .post("/v1/password/recovery-request", {
      User: {
        email
      }
    })
    .then(response =>
      dispatch({
        type: USER_LOST_SUCCESS,
        token: process.env.REACT_APP_TEST
          ? get(response, "data.data.token", null)
          : null
      })
    )
    .catch(error => {
      dispatch({
        type: USER_LOST_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const passwordRecoveryActivateCheck = token => dispatch => {
  dispatch({
    type: USER_LOSTACTIVATE_LOAD
  });
  return axios
    .get("/v1/password/recovery/" + token)
    .then(response => {
      if (response.data.success === true) {
        dispatch({
          type: USER_LOSTACTIVATE_SUCCESS
        });
      } else {
        dispatch({
          type: USER_LOSTACTIVATE_FAILED,
          message: get(response, "data.data.message", null)
        });
      }
      return response.data;
    })
    .catch(error => {
      dispatch({
        type: USER_LOSTACTIVATE_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const passwordRecovery = (token, password) => dispatch => {
  dispatch({
    type: USER_LOSTSETPASS_LOAD
  });
  return axios
    .post("/v1/password/recovery/" + token, {
      User: { password }
    })
    .then(response =>
      dispatch(login(get(response, "data.data.username", null), password))
    )
    .catch(error => {
      dispatch({
        type: USER_LOSTACTIVATE_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const registrationTokenCheck = token => dispatch => {
  dispatch({
    type: USER_REGTOKEN_LOAD
  });
  return axios
    .get("/v1/users/registration/" + token)
    .then(response => {
      const data = get(response, "data", null);
      const success = get(data, "success", false);
      const message = get(data, "message", null);
      console.log(data, success, message);
      dispatch({
        type: success ? USER_REGTOKEN_SUCCESS : USER_REGTOKEN_FAILED,
        data: data,
        message: message
      });
      return data;
    })
    .catch(error => {
      dispatch({
        type: USER_REGTOKEN_FAILED,
        message: get(error, "response.data.message", null)
      });
      return error.response;
    });
};

export const registration = params => dispatch => {
  dispatch({
    type: USER_REGISTRATION_LOAD
  });
  const payload = { RegistrationForm: params };
  return axios
    .post("/v1/users/registration/" + params.token, payload)
    .then(response => {
      const autoLogin = get(response, "data.data.login", false);
      return autoLogin
        ? dispatch(login(params.user_username, params.user_password))
        : dispatch({
            type: USER_REGISTRATION_SUCCESS,
          message:
              "Siekres regisztráció, a belépéshez további engedélyre van szükség."
          });
    })
    .catch(error => {
      return dispatch({
        type: USER_REGISTRATION_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
    });
};

export const registrationDistributor = (
  token,
  user_username,
  user_password,
  distributor_company_name,
  distributor_registration_number,
  distributor_address,
  distributor_phone,
  distributor_email,
  distributor_contact_person
) => dispatch => {
  dispatch({
    type: USER_REGISTRATION_LOAD
  });
  return axios
    .post("/v1/users/registration/" + token, {
      user_username,
      user_password,
      distributor_company_name,
      distributor_registration_number,
      distributor_address,
      distributor_phone,
      distributor_email,
      distributor_contact_person
    })
    .then(response => dispatch(login(user_username, user_password)))
    .catch(error => {
      return dispatch({
        type: USER_REGISTRATION_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
    });
};

export const emailChangeTokenCheck = token => dispatch => {
  dispatch({
    type: USER_EMAILTOKEN_LOAD
  });
  return axios
    .get("/v1/users/confirm-email-address/" + token)
    .then(response =>
      dispatch({
        type: USER_EMAILTOKEN_SUCCESS,
        message: get(response, "data.message", null)
      })
    )
    .catch(error =>
      dispatch({
        type: USER_EMAILTOKEN_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};
