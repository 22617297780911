import React from "react";
import PropTypes from "prop-types";
import localize from "helpers/localize";
import restHelper from "helpers/aee/restHelper";
import Paper from "@material-ui/core/Paper";
import Select from "Components/New/Input/Select/Select";

const LC = "cloud"; //Localization category

class ClientSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onIdentityChange = (client, id) => {
    const { history } = this.props;
    console.log(id, client);
    history.push(`/cloud/${id}/cashregisters`);
  };

  render() {
    console.log("IdentitySelector.render", this.props.match.params);
    const { client_id } = this.props.match.params;
    const { label } = this.props;
    return (
      <Select
        key={client_id}
        type="autocomplete"
        fullWidth
        label={label}
        value={client_id}
        url="identity/clients"
        onChange={this.onIdentityChange}
        restHelper={restHelper}
        defaultOptions
        selectIfOne
      />
    );
  }
}

ClientSelector.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  label: PropTypes.string
};

export default ClientSelector;
