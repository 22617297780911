import React, { Fragment, PureComponent } from "react";
import { Helmet } from "react-helmet";
import List from "../../../../../Components/List/List";
import ContainerHeader from "../../../../../Components/ContainerHeader";
import Navbar from "../../Navbar";
import ModifyPermission from "../../../../Permissions/ModifyPermission";
import Button from "@material-ui/core/Button/Button";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Modal from "Components/Modal/Modal";
import restHelper from "helpers/restHelper";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import { Link } from "react-router-dom";
import WorksheetModalContent from "Containers/Pages/Cashregisters/Cashregisters/views/WorksheetModalContent";
import WorksheetIcon from "@material-ui/icons/Assignment";
import Tooltip from "@material-ui/core/Tooltip";
import authRules from "helpers/authRules";
import { WORKSHEETS_PERMISSION } from "constans/permissions";
import localize from "helpers/localize";

class WorksheetsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      keyword: null,
      search: null,
      filters: null,
      perPage: null,
      openWorksheetModal: false,
      worksheetId: null
    };
  }

  openWorksheetModal = (id, evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({ openWorksheetModal: true, worksheetId: id });
  };

  closeWorksheetModal = () => {
    this.setState({ openWorksheetModal: false });
  };
  componentWillReceiveProps = nextProps => {
    if (!this.props.reload && nextProps.reload) {
      const { page, keyword, sort, filters, perPage } = this.state;
      this.getList(page, keyword, sort, filters, perPage);
    }
  };
  customIcons = obj => {
    const { mode } = this.props;
    const canViewWorksheets = authRules.canViewByKey(WORKSHEETS_PERMISSION);
    return (
      <Tooltip title={localize("worksheets", "Megtekintés")}>
        <Button
          className="jr-btn jr-btn-xs"
          target="_blank"
          color="secondary"
          onClick={evt =>
            canViewWorksheets
              ? this.props.history.push(`/worksheets/view/${obj.id}`)
              : this.openWorksheetModal(obj.id, evt)
          }
        >
          <EyeIcon />
        </Button>
      </Tooltip>
    );
  };

  render() {
    const { openWorksheetModal, worksheetId } = this.state;
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("cashregisters", "Munkalapok")}</title>
        </Helmet>
        <Navbar {...this.props} />
        <ContainerHeader title={localize("cashregisters", "Munkalapok")} />
        <Modal
          title="Munkalap"
          open={openWorksheetModal}
          disableCancel
          onAccept={this.closeWorksheetModal}
          onClose={this.closeWorksheetModal}
          maxWidth="lg"
          Icon={WorksheetIcon}
        >
          <WorksheetModalContent id={worksheetId} />
        </Modal>

        <List
          {...this.props}
          disableViewIcon={true}
          disableEditIcon={true}
          disabledCreate={true}
          disableDeleteIcon={true}
          //getListHeaderEndpoint="/cashregisters"
          //customButtons={this.customButtons.bind(this)}
          customIcons={this.customIcons}
          //getList={this.getList.bind(this)}
          //getListHeader={this.getListHeader}
          reset={() => {}}
        />
      </div>
    );
  }
}

export default WorksheetsList;
