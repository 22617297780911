import axios from "axios";
import qs from "qs";
import { get } from "lodash";
import * as authActions from "./auth";

export const PROFILE_EMAIL_LOAD = "PROFILE_EMAIL_LOAD";
export const PROFILE_EMAIL_SUCCESS = "PROFILE_EMAIL_SUCCESS";
export const PROFILE_EMAIL_FAILED = "PROFILE_EMAIL_FAILED";

export const PROFILE_PASSWORD_LOAD = "PROFILE_PASSWORD_LOAD";
export const PROFILE_PASSWORD_SUCCESS = "PROFILE_PASSWORD_SUCCESS";
export const PROFILE_PASSWORD_FAILED = "PROFILE_PASSWORD_FAILED";

export const PROFILE_CHANGE_LOAD = "PROFILE_CHANGE_LOAD";
export const PROFILE_CHANGE_SUCCESS = "PROFILE_CHANGE_SUCCESS";
export const PROFILE_CHANGE_FAILED = "PROFILE_CHANGE_FAILED";

const initialState = {
  profileLoading: false,
  emailLoading: false,
  passwordLoading: false,
  loading: false,
  failed: false,
  success: false,
  errors: null,
  message: false,
  saved: false,
  formErrors: {},
  token: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_CHANGE_LOAD:
      return {
        ...state,
        loading: true,
        profileLoading: false,
        failed: false,
        success: false,
        errors: null,
        message: false,
        saved: false,
        formErrors: {}
      };
    case PROFILE_PASSWORD_LOAD:
      return {
        ...state,
        loading: true,
        passwordLoading: false,
        failed: false,
        success: false,
        errors: null,
        message: false,
        saved: false,
        formErrors: {}
      };
    case PROFILE_EMAIL_LOAD:
      return {
        ...state,
        loading: true,
        emailLoading: true,
        failed: false,
        success: false,
        errors: null,
        message: false,
        saved: false,
        formErrors: {}
      };

    case PROFILE_CHANGE_SUCCESS:
      return {
        ...state,
        success: true,
        saved: true,
        loading: false,
        profileLoading: false
      };
    case PROFILE_PASSWORD_SUCCESS:
      return {
        ...state,
        success: true,
        saved: true,
        loading: false,
        passwordLoading: false
      };
    case PROFILE_EMAIL_SUCCESS:
      return {
        ...state,
        success: true,
        saved: true,
        loading: false,
        emailLoading: false,
        token: action.token
      };

    case PROFILE_CHANGE_FAILED:
      return {
        ...state,
        loading: false,
        message: action.message,
        formErrors: action.formErrors,
        failed: true,
        profileLoading: false
      };
    case PROFILE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        message: action.message,
        formErrors: action.formErrors,
        failed: true,
        passwordLoading: false
      };
    case PROFILE_EMAIL_FAILED:
      return {
        ...state,
        loading: false,
        message: action.message,
        formErrors: action.formErrors,
        failed: true,
        emailLoading: false
      };
    default:
      return state;
  }
};

export const changeEmail = (userID, email, password) => dispatch => {
  dispatch({
    type: PROFILE_EMAIL_LOAD
  });
  return axios
    .post("/v1/user/change-email", {
      EmailChange: {
        email,
        password
      }
    })
    .then(response => {
      dispatch({
        type: PROFILE_EMAIL_SUCCESS,
        token: process.env.REACT_APP_TEST
          ? get(response, "data.data.token", null)
          : null
      });
      return response.data;
    })
    .catch(error => {
      dispatch({
        type: PROFILE_EMAIL_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};

export const changePassword = email => dispatch => {
  dispatch({
    type: PROFILE_PASSWORD_LOAD
  });
  return axios
    .post("/v1/password/change-request", {
      User: {
        email
      }
    })
    .then(response => {
      dispatch({
        type: PROFILE_PASSWORD_SUCCESS
      });
      return response.data;
    })
    .catch(error => {
      dispatch({
        type: PROFILE_PASSWORD_FAILED,
        message: get(error, "response.data.message", null)
        //formErrors: get(error, 'response.data.form_errors', {})
      });
      throw error.response;
    });
};

export const changeProfile = data => dispatch => {
  dispatch({
    type: PROFILE_CHANGE_LOAD
  });

  return axios
    .put("/v1/user", { User: data })
    .then(response => {
      dispatch(authActions.setUserData(response.data.data));
      dispatch({
        type: PROFILE_CHANGE_SUCCESS
      });
      return response.data;
    })
    .catch(error => {
      dispatch({
        type: PROFILE_CHANGE_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      throw error.response;
    });
};
