import React from "react";


import ServiceOrderForm from "./ServiceOrderForm";
import EditPage from "../../../../Components/Extends/EditPage";
import Edit from "../../../../Components/Edit/Edit";
import localize from "helpers/localize";

class EditserviceOrder extends EditPage {
  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("serviceOrders", "Szerződés megtekintése");
    }
    return this.props.match.params.id
      ? localize("serviceOrders", "Szerződés szerkesztése")
      : localize("serviceOrders", "Szerződés létrehozása");
  };

  render() {
    return (
      <Edit
        getPagetitle={this.getPageTitle}
        isViewPage={this.isViewPage()}
        {...this.props}
      >
        <ServiceOrderForm {...this.props} isViewPage={this.isViewPage()} />
      </Edit>
    );
  }
}

export default EditserviceOrder;
