import React from "react";

import ChangesForm from "./ChangesForm";
import EditPage from "Components/Extends/EditPage";
import Edit from "Components/Edit/Edit";
import localize from "helpers/localize";

class EditChange extends EditPage {
  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("cashregisters", "Szerviz váltás adatok");
    }
    return this.props.match.params.id
      ? localize("cashregisters", "Szerviz váltás módosítása")
      : localize("cashregisters", "Szerviz váltás létrehozása");
  };

  render() {
    return (
      <Edit
        getPagetitle={this.getPageTitle}
        isViewPage={
          this.props.viewData && this.props.viewData.status === "accepted"
            ? true
            : this.isViewPage()
        }
        cardBoxStyle="col-lg-6 col-xs-12"
        {...this.props}
        key={"EditChange" + (this.isViewPage() ? "isViewPage" : "")}
      >
        <ChangesForm {...this.props} isViewPage={this.isViewPage()} />
      </Edit>
    );
  }
}

export default EditChange;
