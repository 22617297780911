import React from "react";
import DataTableDx from "Components/New/DataTable/DataTableDx/DataTableDx";
import DataForm from "Components/New/DataForm/DataFormNew";
import DataProvider from "Components/New/DataProvider/DataProviderNew";
import authRules from "helpers/authRules";
import { ACCOUNTANTS_PERMISSION } from "constans/permissions";
import localize from "helpers/localize";
import { Paper } from "@material-ui/core";
import ContainerHeader from "Components/ContainerHeader";
class Accountants extends React.Component {
  render() {
    const { params } = this.props.match;
    console.log(params);
    const title =
      params.menu === "edit"
        ? "Könyvelő szerkesztése"
        : params.menu === "create"
          ? "Új könyvelő felvitele"
          : "Könyvelők";
    return (
      <div style={{ width: "100%", position: "relative" }}>
        <ContainerHeader title={localize("accountant", title)} />
        <Paper style={{ padding: 16, paddingTop: params.menu ? 32 : 16 }}>
          <DataProvider
            url={"accountants"}
            //url={"cashregisters"}
            goBackAfterSave
            useUrlRouting
            disableGlobalValidationError
            //disableGetView
            tableComponent={DataTableDx}
            formComponent={DataForm}
            tableProps={{
              //title: localize("accountant", "Könyvelők"),
              canView: false,
              canCreate: authRules.canModifyByKey(ACCOUNTANTS_PERMISSION),
              canDelete: authRules.canDeleteByKey(ACCOUNTANTS_PERMISSION),
              canEdit: authRules.canModifyByKey(ACCOUNTANTS_PERMISSION),
              columns: {}
            }}
            formProps={{
              disableHeader: true,
              title: localize("accountant", "Könyvelő szerkesztése"),
              titleNew: localize("accountant", "Új könyvelő felvitele"),
              fields: {
                user_id: {
                  name: localize("accountant", "Felhasználó")
                },
                tax_number: {
                  name: localize("accountant", "Adószám"),
                  mask: "tax_number"
                },
                registration_no: {
                  name: localize("accountant", "Cégjegyzékszám")
                },
                name: {
                  newLine: true,
                  name: localize("accountant", "Név")
                },
                phone: {
                  name: localize("accountant", "Telefon"),
                  mask: "mobile_phone",
                  validators: ["mobile_phone"]
                },
                phone2: {
                  name: localize("accountant", "Mésodlagos telefon"),
                  mask: "mobile_phone",
                  validators: ["mobile_phone"]
                },
                zip: {
                  newLine: true,
                  name: localize("accountant", "Irányítószám")
                },
                city: {
                  name: localize("accountant", "Város")
                },
                address: {
                  name: localize("accountant", "Utca, hsz.")
                },
                bank_name: {
                  newLine: true,
                  name: localize("accountant", "Bank neve")
                },
                bank_account_no: {
                  name: localize("accountant", "Bankszámlaszám")
                }
              },
              //fieldsOrder: ["distributor_id", "shop_product_id", "price"],
              //component: CustomForm,
              autoRenderFields: true
            }}
          />
        </Paper>
      </div>
    );
  }
}

export default Accountants;
