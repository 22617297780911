import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CartItem from "./CartItem";
import InfoIcon from "@material-ui/icons/Info";
import CartIcon from "@material-ui/icons/ShoppingCart";
import {
  Grid,
  Button,
  Paper,
  Typography,
  SnackbarContent
} from "@material-ui/core";
import formatHelper from "helpers/formatHelper";
import ContinueIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";
import { BASE_PATH_CART_CHECKOUT, BASE_PATH_CATALOG } from "./../shopPath";
import { blue } from "@material-ui/core/colors";
import localize from "helpers/localize";
const styles = theme => ({
  bell: {
    WebkitAnimation: "ring 4s .7s ease-in-out 2",
    WebkitTransformOrigin: "50% 4px",
    MozAnimation: "ring 4s .7s ease-in-out 2",
    MozTransformOrigin: "50% 4px",
    animationName: "$ring 4s .7s ease-in-out 2",
    transformOrigin: "50% 4px"
  },
  "@keyframes ring": {
    "0%": { transform: "rotate(0)" },
    "1%": { transform: "rotate(30deg)" },
    "3%": { transform: "rotate(-28deg)" },
    "5%": { transform: "rotate(34deg)" },
    "7%": { transform: "rotate(-32deg)" },
    "9%": { transform: "rotate(30deg)" },
    "11%": { transform: "rotate(-28deg)" },
    "13%": { transform: "rotate(26deg)" },
    "15%": { transform: "rotate(-24deg)" },
    "17%": { transform: "rotate(22deg)" },
    "19%": { transform: "rotate(-20deg)" },
    "21%": { transform: "rotate(18deg)" },
    "23%": { transform: "rotate(-16deg)" },
    "25%": { transform: "rotate(14deg)" },
    "27%": { transform: "rotate(-12deg)" },
    "29%": { transform: "rotate(10deg)" },
    "31%": { transform: "rotate(-8deg)" },
    "33%": { transform: "rotate(6deg)" },
    "35%": { transform: "rotate(-4deg)" },
    "37%": { transform: "rotate(2deg)" },
    "39%": { transform: "rotate(-1deg)" },
    "41%": { transform: "rotate(1deg)" },

    "43%": { transform: "rotate(0)" },
    "100%": { transform: "rotate(0)" }
  },
  toggleIconButton: {
    padding: 0,
    color: "white"
  },
  readIconButton: {
    padding: 0
  },
  badge: {
    //marginLeft: "auto"
  },
  continueIcon: {
    marginRight: -theme.spacing.unit
  },
  centerContainer: {
    textAlign: "center"
  },
  info: {
    backgroundColor: blue[700],
    minWidth: 100,
    display: "inline-block",
    marginTop: theme.spacing.unit * 2
  },
  icon: {
    fontSize: 18,
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  messageInfo: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    fontStyle: "italic",
    justifyContent: "center",
    color: theme.palette.text.secondary
  }
});

class Products extends React.Component {
  render() {
    const { theme, classes, items, total, totalPrice } = this.props;
    const colorProps = {};

    if (theme.header.iconColor) {
      colorProps.color = theme.header.iconColor;
    }

    return (
      <Fragment>
        <Grid container spacing={16} justify="center">
          <Grid item xs={12} md={10}>
            <Grid container spacing={16}>
              {items.map((item, index) => {
                return (
                  <Grid key={index} item xs={12}>
                    <CartItem item={item} />
                  </Grid>
                );
              })}
              {items.length === 0 && (
                <Fragment>
                  <Grid item xs={12} className={classes.centerContainer}>
                    <SnackbarContent
                      className={classes.info}
                      message={
                        <span id="client-snackbar" className={classes.message}>
                          <CartIcon className={classes.icon} />
                          {localize("shop", "A kosara jelenleg üres.")}
                        </span>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.centerContainer}>
                    <span id="client-snackbar" className={classes.messageInfo}>
                      <InfoIcon className={classes.icon} />
                      {localize(
                        "shop",
                        "Böngésszen a katalógusban és tegyen termékeket a kosárba."
                      )}
                    </span>
                  </Grid>
                  <Grid item xs={12} className={classes.centerContainer}>
                    <Button
                      color="primary"
                      //variant="contained"
                      to={BASE_PATH_CATALOG}
                      component={Link}
                    >
                      {localize("shop", "Ugrás a katalógusra")}
                    </Button>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Grid>
          {items.length > 0 && (
            <Grid item xs={12} md={2}>
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <Typography variant="h6" color="textSecondary">
                    {localize("shop", "Rendelés összegzése")}:
                  </Typography>
                  <Typography variant="h6">
                    {formatHelper.currency(totalPrice)} +{" "}
                    {localize("shop", "Áfa")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="large"
                    to={BASE_PATH_CART_CHECKOUT}
                    onClick={() => this.props.changeStep(1)}
                    component={Link}
                  >
                    {localize("shop", "Folytatás")}
                    <ContinueIcon className={classes.continueIcon} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  }
}

Products.defaultProps = {
  type: "icon"
};

Products.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  items: PropTypes.array,
  total: PropTypes.number
};

export default withStyles(styles, { withTheme: true })(Products);
