import React from "react";


import MyExamForm from "./MyExamForm";
import EditPage from "../../../../Components/Extends/EditPage";
import Edit from "../../../../Components/Edit/Edit";
import localize from "helpers/localize";

class EditMyExam extends EditPage {
  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("exams", "Vizsga megtekintése");
    }
    return this.props.match.params.id
      ? localize("exams", "Vizsga kitöltése")
      : localize("exams", "Vizsga jelentkezés");
  };

  createSavedRedirect = () => {
    this.props.history.push(`/${window.location.pathname.split("/")[1]}`);
  };

  componentWillMount() {
    this.props.resetForm();
    this.props.reset();
  }

  render() {
    return (
      <Edit
        getPagetitle={this.getPageTitle}
        isViewPage={this.isViewPage()}
        createSavedRedirect={this.createSavedRedirect.bind(this)}
        {...this.props}
      >
        <MyExamForm {...this.props} isViewPage={this.isViewPage()} />
      </Edit>
    );
  }
}

export default EditMyExam;
