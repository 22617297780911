import { reducer as toastrReducer } from "react-redux-toastr";
import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import adminProducts from "./modules/admin-products";
import adminTags from "./modules/admin-tags";
import annualReviews from "./modules/annual-reviews";
import anyk from "./modules/anyk";
import articles from "./modules/articles";
import auth from "./modules/auth";
import autocomplete from "./modules/autocomplete";
import bannedCashregisters from "./modules/banned-cashregisters";
import cart from "./modules/cart";
import cashregisterRights from "./modules/cashregister-rights";
import cashregisters from "./modules/cashregisters";
import cashregistersWorksheets from "./modules/cashregisters-worksheets";
import catalog from "./modules/catalog";
import clients from "./modules/clients";
import crules from "./modules/crules";
import CrulesOptions from "./modules/CrulesOptions";
import distributor from "./modules/distributor";
import distributors from "./modules/distributors";
import downloads from "./modules/downloads";
import eventTypes from "./modules/event-types";
import events from "./modules/events";
import examineeExams from "./modules/examinee-exams";
import exams from "./modules/exams";
import fcm from "./modules/fcm";
import file from "./modules/file";
import home from "./modules/home";
import incomingSms from "./modules/incoming-sms";
import listItemsSms from "./modules/list-items-sms";
import myExams from "./modules/myExams";
import notifications from "./modules/notifications";
import options from "./modules/options";
import orders from "./modules/orders";
import outgoingSms from "./modules/outgoing-sms";
import product from "./modules/product";
import profile from "./modules/profile";
import serviceChanges from "./modules/service-changes";
import serviceOrders from "./modules/service-orders";
import shopCart from "./modules/shopCart";
import shopOrder from "./modules/shopOrder";
import signing from "./modules/signing";
import slider from "./modules/slider";
import stickers from "./modules/stickers";
import StickersOptions from "./modules/StickersOptions";
import tokens from "./modules/tokens";
import TokensOptions from "./modules/TokensOptions";
import UIsettings from "./modules/UIsettings";
import users from "./modules/users";
import workfees from "./modules/workfees";
import worksheets from "./modules/worksheets";

const appReducers = {
  UIsettings,
  autocomplete,
  file,
  options,
  auth,
  profile,
  users,
  articles,
  serviceChanges,
  distributors,
  downloads,
  bannedCashregisters,
  events,
  eventTypes,
  cashregisters,
  incomingSms,
  outgoingSms,
  stickers,
  tokens,
  exams,
  listItemsSms,
  distributor,
  annualReviews,
  cashregisterRights,
  examineeExams,
  myExams,
  ...{ homeDashBoard: home.dashboard, homeEvents: home.events },
  fcm,
  notifications,
  serviceOrders,
  clients,
  worksheets,
  signing,
  StickersOptions,
  TokensOptions,
  CrulesOptions,
  workfees,
  crules,
  slider,
  cart,
  product,
  catalog,
  adminProducts,
  adminTags,
  orders,
  anyk,
  cashregistersWorksheets,
  shopCart,
  shopOrder
};

const reducers = combineReducers({
  ...appReducers,
  routing: routerReducer,
  form: formReducer,
  toastr: toastrReducer
});

export default reducers;
