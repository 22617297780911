// import axios from 'axios';
// import qs from 'qs';
// import { get } from 'lodash';

export const MENU_COLLAPSE = 'MENU_COLLAPSE';
export const SIDEBAR_TOOGLE = 'SIDEBAR_TOOGLE';
export const SIDEBAR_COLOR = 'SIDEBAR_COLOR';
export const SIDEBAR_SIZE = 'SIDEBAR_SIZE';

const initialState = {
    menuCollapsed: false,
    sideBarOpened: false,
    sidebarColor: 'black',
    sidebarSizeClass: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MENU_COLLAPSE:
            return {
                ...state,
                menuCollapsed: action.menuCollapsed
            };
        case SIDEBAR_TOOGLE:
            return {
                ...state,
                sideBarOpened: action.sideBarOpened
            };
        case SIDEBAR_COLOR:
            return {
                ...state,
                sidebarColor: action.sidebarColor
            };
        case SIDEBAR_SIZE:
            return {
                ...state,
                sidebarSizeClass: action.sidebarSizeClass
            };
        default:
            return state;
    }
};
export const menuCollapse = (menuCollapsed) => dispatch => {
    dispatch({
        type: MENU_COLLAPSE,
        menuCollapsed: menuCollapsed
    });
};

export const sidebarToogle = (sideBarOpened) => dispatch => {
    dispatch({
        type: SIDEBAR_TOOGLE,
        sideBarOpened: sideBarOpened
    });
};

export const setSidebarColor = (sidebarColor) => dispatch => {
    dispatch({
        type: SIDEBAR_COLOR,
        sidebarColor: sidebarColor
    });
};

export const setSidebarSize = (sidebarSizeClass) => dispatch => {
    dispatch({
        type: SIDEBAR_SIZE,
        sidebarSizeClass: sidebarSizeClass
    });
};