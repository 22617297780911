const notificationstStyle = theme => ({
  "@global": {
    ".full-width-field-container .field-group-class": {
      flex: 1
    }
  },
  bell: {
    WebkitAnimation: "ring 4s .7s ease-in-out 2",
    WebkitTransformOrigin: "50% 4px",
    MozAnimation: "ring 4s .7s ease-in-out 2",
    MozTransformOrigin: "50% 4px",
    animationName: "$ring 4s .7s ease-in-out 2",
    transformOrigin: "50% 4px"
  },
  "@keyframes ring": {
    "0%": { transform: "rotate(0)" },
    "1%": { transform: "rotate(30deg)" },
    "3%": { transform: "rotate(-28deg)" },
    "5%": { transform: "rotate(34deg)" },
    "7%": { transform: "rotate(-32deg)" },
    "9%": { transform: "rotate(30deg)" },
    "11%": { transform: "rotate(-28deg)" },
    "13%": { transform: "rotate(26deg)" },
    "15%": { transform: "rotate(-24deg)" },
    "17%": { transform: "rotate(22deg)" },
    "19%": { transform: "rotate(-20deg)" },
    "21%": { transform: "rotate(18deg)" },
    "23%": { transform: "rotate(-16deg)" },
    "25%": { transform: "rotate(14deg)" },
    "27%": { transform: "rotate(-12deg)" },
    "29%": { transform: "rotate(10deg)" },
    "31%": { transform: "rotate(-8deg)" },
    "33%": { transform: "rotate(6deg)" },
    "35%": { transform: "rotate(-4deg)" },
    "37%": { transform: "rotate(2deg)" },
    "39%": { transform: "rotate(-1deg)" },
    "41%": { transform: "rotate(1deg)" },

    "43%": { transform: "rotate(0)" },
    "100%": { transform: "rotate(0)" }
  },
  toggleIconButton: {
    padding: 0,
    color: "white"
  },
  readIconButton: {
    padding: 0
  },
  badge: {
    //marginLeft: "auto"
    marginLeft: theme.spacing.unit * 2
  },
  popper: { zIndex: 1270, width: 300 },
  cardContent: { paddingTop: 0, paddingBottom: 0, minHeight: 20 },
  list: { paddingTop: 0, paddingBottom: 0 },
  listItemContainer: { listStyleType: "none" },
  navLink: { textDecoration: "none", color: "inherit!important" },
  listItem: {
    padding: 0,
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2
  },
  cardHeader: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit / 2
  },
  listItemTextPrimary: {
    fontSize: "0.875rem"
  },
  listItemTextPrimaryLink: {
    textDecoration: "none",
    color: "inherit!important"
  },
  listItemTextSecondary: { fontSize: "0.675rem" },
  progress: {
    position: "absolute",
    right: theme.spacing.unit * 2,
    top: theme.spacing.unit * 2
  },
  btnAllNotification: {},
  btnReadAll: {
    marginLeft: "auto"
  }
});

export default notificationstStyle;
