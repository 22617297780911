import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import CardItem from "./CardItem";
import restHelper from "helpers/restHelper";
import { Grid } from "@material-ui/core";

const styles = theme => ({
  card: {
    //maxWidth: 345
    display: "flex",
    height: "100%",
    position: "relative",
    //justifyContent: "space-between",
    flexDirection: "column"
  },
  tags: {
    height: "100%",
    alignSelf: "strech"
  },
  media: {
    height: 0,
    //paddingTop: "56.25%" // 16:9
    paddingTop: "80.25%", // 16:9,
    margin: theme.spacing.unit
  },
  actions: {
    justifyContent: "flex-start",
    marginTop: "auto"
  },
  addToCartInput: {
    width: 40,
    marginLeft: theme.spacing.unit * 2,
    "& input": {
      textAlign: "center"
    }
  },
  addToCartButton: {},
  price: {},
  expand: {
    transform: "rotate(0deg)",
    //marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500]
  }
});

class RelatedProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  getRelatedProducts = () => {
    restHelper.index("shop-products", { "per-page": 4 }).then(response => {
      this.setState({ data: response.data });
    });
  };

  componentDidMount() {
    this.getRelatedProducts();
  }

  render() {
    const { classes } = this.props;
    const { data } = this.state;

    return (
      <Grid container spacing={16}>
        {data.map((item, rowIndex) => {
          return (
            <Grid key={rowIndex} item xs={12} sm={6} md={4} lg={3}>
              <CardItem item={item} />
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

RelatedProducts.propTypes = {
  classes: PropTypes.object.isRequired,
  tags: PropTypes.array
};

export default withStyles(styles)(RelatedProducts);
