import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toastr } from "../../../helpers/CustomToastr";
import PropTypes from "prop-types";

import * as signingsActions from "../../../redux/modules/signing";
import * as fcmActions from "../../../redux/modules/fcm";
import { Field, reduxForm } from "redux-form";
import Validations from "../../../Components/Forms/Validations";
import InputField from "../../../Components/Forms/Fields/Input";
import DefaultFormButtons from "../../../Components/Forms/Buttons/DefaultFormButtons";
import { Form } from "reactstrap";
import logo from "../../../assets/img/prior_logo.png";
import SignaturePad from "react-signature-canvas";
import BodyClassName from "react-body-classname";
import SignatureImage from "./SignatureImage";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import StaticTable from "Components/New/DataTable/StaticTable/StaticTable";
import Modal from "Components/Modal/Modal";
import {
  Toolbar,
  Checkbox,
  FormControlLabel,
  Button,
  Typography
} from "@material-ui/core";
import { get } from "lodash";
import WorksheetDetails from "./WorksheetDetails";
import localize from "helpers/localize";
const DeviceCode = props => (
  <Grid container spacing={16} justify="center">
    <Grid item xs={12} style={{ textAlign: "center" }}>
      {props.showLogo && <img src={logo} alt="" />}
    </Grid>
    <Grid item xs={12} style={{ textAlign: "center" }}>
      <Typography
        variant="caption"
        style={{
          color:
            props.color === "white"
              ? "rgba(255,255,255, 0.2)"
              : "rgba(0,0,0, 0.2)"
        }}
      >
        {localize("connecting", "Eszköz kód")}
      </Typography>
      <Typography
        variant="h4"
        style={{
          color:
            props.color === "white"
              ? "rgba(255,255,255, 0.5)"
              : "rgba(0,0,0, 0.5)"
        }}
      >
        {props.code}
      </Typography>
    </Grid>
  </Grid>
);

DeviceCode.defaultProps = {
  showLogo: true
};

DeviceCode.propTypes = {
  color: PropTypes.oneOf(["white", "default"]),
  code: PropTypes.string.isRequired,
  showLogo: PropTypes.bool
};

class Signing extends PureComponent {
  state = {
    acceptSignatureOrder: false,
    acceptSignatureTakeover: false,
    signatureIsEmpty: true,
    openASZF: false
  };
  sigPad = {};

  handleChangeCheckbox = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  componentWillMount = () => {
    this.props.getCode();
  };

  componentWillUnmount = () => {
    this.props.unSubscribeConnecting();
    this.props.unSubscribeDisconnected();
    this.props.unSubscribeSigning();
    this.props.unSubscribeSigned();
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.message && nextProps.message) {
      toastr.error(nextProps.message);
    }
    //console.log("code", nextProps.code, this.props.code)
    if (nextProps.code && !this.props.code) {
      console.log("code IF", nextProps.code);
      this.props.fcmInitalize(true);
    }
    //console.log("token", nextProps.token, this.props.token)
    if (nextProps.token && !this.props.token) {
      console.log("token IF", nextProps.token);
      this.props.onMessage();
      this.props.sendFcm();
    }
    //console.log("saved", nextProps.saved, this.props.saved);
    if (nextProps.saved && !this.props.saved) {
      console.log("saved IF", nextProps.saved);
      this.props.subscribeDisconnect();
      this.props.subscribeSigning();
    }
    //console.log("getSigned", nextProps.getSigned, this.props.getSigned);
    if (nextProps.getSigned && !this.props.getSigned) {
      console.log("getSigned IF", nextProps.getSigned);
      this.handleGetSignature();
    }

    //Scrollozás a töltés befejezésénél
    if (this.props.loading && !nextProps.loading) {
      this.scrollToEnd();
    }
  }

  scrollToEnd = () => {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 200);
  };

  handleAdminSubmit = formData => {
    return this.props.sendAdminCode(formData);
  };

  handleGetSignature = () => {
    const status =
      this.props.viewData && this.props.viewData.status
        ? this.props.viewData.status
        : null;
    try {
      this.props
        .sendSign(
          this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
          status === "DONE" ? "takeover" : "order"
        )
        .then(res => {
          this.setState({
            signatureIsEmpty: true,
            acceptSignatureOrder: false,
            acceptSignatureTakeover: false
          });
        });
    } catch (e) {}
  };

  onDrawEnd = () => {
    this.setState({ signatureIsEmpty: this.sigPad.isEmpty() });
  };

  clearSignature = () => {
    if (this.sigPad) {
      this.sigPad.clear();
      this.setState({ signatureIsEmpty: true });
    }
  };

  openASZF = () => {
    this.setState({ openASZF: true });
  };

  closeASZF = () => {
    this.setState({ openASZF: false });
  };

  render() {
    const {
      pristine,
      submitting,
      reset,
      loading,
      viewData,
      worksheetToken
    } = this.props;
    const status = get(viewData, "status");
    const {
      acceptSignatureOrder,
      acceptSignatureTakeover,
      signatureIsEmpty,
      openASZF
    } = this.state;

    return (
      <BodyClassName className="signing dark-indigo">
        <div
          className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3 p-2"
          style={{
            backgroundColor:
              this.props.saved && !this.props.viewData ? "#8a5e87" : "#fff"
          }}
        >
          {this.props.loading && (
            <div className="text-center mt-2">
              <CircularProgress />
            </div>
          )}
          {!this.props.loading && (
            <Fragment>
              {this.props.screenShowCode && (
                <Fragment>
                  {/* ide */}
                  {!this.props.saved && (
                    <div className="text-center mt-2">
                      <DeviceCode code={this.props.code} showLogo={false} />
                      {this.props.showPassword && (
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            this.handleAdminSubmit({
                              admin_code: this.refs.codeRef.value || ""
                            });
                          }}
                        >
                          <Grid container spacing={16} justify="center">
                            <Grid item xs={8} sm={6} md={4} lg={2}>
                              <Field
                                name="admin_code"
                                label={localize("connecting", "Jelszó")}
                                fieldError={this.props.fieldErrors.admin_code}
                                isRequired={true}
                                validate={[Validations.required]}
                                component={InputField}
                                type="password"
                                placeholder={localize("connecting", "Jelszó")}
                                isViewPage={false}
                                ref="codeRef"
                              />
                            </Grid>
                            <Grid item container xs={12} justify="center">
                              <Grid item xs={8} sm={6} md={4} lg={2}>
                                <DefaultFormButtons
                                  pristine={pristine}
                                  submitting={submitting}
                                  reset={reset}
                                  loading={loading}
                                  isViewPage={false}
                                  disableCancel
                                  texts={{
                                    saveButton: localize(
                                      "connecting",
                                      "Csatlakozás"
                                    )
                                  }}
                                  {...this.props}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </div>
                  )}
                  {/* ideend */}
                  {this.props.saved && !this.props.viewData && (
                    <DeviceCode code={this.props.code} color="white" />
                  )}
                  {this.props.saved && this.props.viewData && (
                    <Fragment>
                      <Modal
                        maxWidth="md"
                        disableIcon
                        open={openASZF}
                        disableCancel
                        title={localize(
                          "worksheets",
                          "Általános szerződési feltételek"
                        )}
                        onCancel={this.closeASZF}
                        onClose={this.closeASZF}
                        onAccept={this.closeASZF}
                      >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus ut mattis turpis, nec aliquam tortor. Nullam
                        condimentum lectus in convallis tempus. Ut bibendum,
                        eros at rhoncus sollicitudin, lacus mi faucibus lacus,
                        in facilisis quam lorem sit amet nunc. Proin id tempor
                        lacus. Etiam at augue vulputate velit imperdiet aliquet.
                        Maecenas porttitor leo eu tempus consequat. Nunc semper
                        sem in metus venenatis tincidunt. Suspendisse luctus
                        gravida quam ut imperdiet. Sed vulputate sit amet nibh
                        quis pellentesque. Duis facilisis posuere pellentesque.
                        Cras in eros a risus rutrum fringilla id vitae metus.
                        Morbi fermentum nisl ornare, blandit eros sit amet,
                        venenatis erat. In hac habitasse platea dictumst. Nunc
                        luctus, odio nec malesuada consectetur, velit tortor
                        commodo odio, nec dignissim lacus augue ultrices est.
                        Sed vehicula mi ac vulputate vestibulum. Vivamus id
                        tortor ac lorem scelerisque interdum. Etiam nisi ex,
                        auctor ut imperdiet at, accumsan quis quam. Suspendisse
                        nec malesuada quam. Curabitur eu nunc dolor. Sed semper
                        nunc in mauris imperdiet rhoncus. Nullam in scelerisque
                        eros. Nulla facilisi. Donec egestas odio suscipit risus
                        porttitor pulvinar ut vitae metus. Etiam auctor faucibus
                        lorem id auctor. Etiam et turpis id metus venenatis
                        feugiat nec a dolor. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Vestibulum imperdiet est et
                        consectetur cursus. Ut congue metus eget leo porttitor
                        faucibus. Integer in leo molestie enim iaculis efficitur
                        non nec mauris. Orci varius natoque penatibus et magnis
                        dis parturient montes, nascetur ridiculus mus. In hac
                        habitasse platea dictumst. Aliquam faucibus arcu est,
                        nec cursus est dapibus ac. In eget metus lectus.
                        Phasellus euismod tincidunt lectus, a maximus est
                        sagittis vel. Duis vel ultricies nisi, ut aliquet erat.
                        Ut sagittis nunc non leo porttitor ornare. Sed a semper
                        dui. Morbi congue scelerisque lorem vitae malesuada.
                        Aenean consectetur tellus sem. Pellentesque porttitor
                        turpis quis tellus sodales vestibulum. Vestibulum
                        efficitur arcu magna, non tincidunt arcu volutpat sed.
                        Quisque urna lectus, volutpat eu fringilla vitae,
                        aliquam id urna. Vivamus tincidunt, nisl quis aliquam
                        finibus, ipsum diam pharetra justo, vel mattis sapien
                        tellus nec sapien. Sed ac lectus tincidunt, tempor
                        libero quis, ornare metus. Maecenas at elit turpis.
                        Integer congue dapibus bibendum. Donec efficitur mattis
                        interdum. Donec mi nibh, lacinia et feugiat vel,
                        consequat vitae odio. Phasellus pretium nunc in est
                        tempor, at condimentum nulla venenatis. Donec hendrerit
                        eros tincidunt elit accumsan commodo. Morbi ut
                        condimentum dui, a vehicula dui. Duis laoreet efficitur
                        est sit amet convallis. Morbi hendrerit nisi et feugiat
                        lobortis. Vestibulum mattis tortor in dui commodo, vel
                        dictum felis ultrices. Aliquam dignissim enim eget enim
                        luctus fermentum. Vestibulum sed nisi eget ex eleifend
                        laoreet. Sed in lacinia nisl. Nulla scelerisque
                        hendrerit augue non semper. Nulla venenatis turpis quis
                        nunc venenatis elementum. Praesent tortor mauris,
                        dignissim sit amet turpis sed, tincidunt faucibus lacus.
                      </Modal>
                      <WorksheetDetails
                        worksheet={this.props.viewData}
                        worksheetToken={worksheetToken}
                      />
                      <div className="row mt-3">
                        <div className="col-12">
                          <b>{localize("worksheets", "Aláírás")}</b>
                        </div>
                        <div className="col-12">
                          <SignaturePad
                            //clearOnResize={false}
                            onEnd={this.onDrawEnd}
                            canvasProps={{ className: "signature" }}
                            ref={ref => {
                              this.sigPad = ref;
                            }}
                          />
                        </div>
                      </div>
                      <Toolbar>
                        <Button
                          variant="contained"
                          disabled={signatureIsEmpty}
                          onClick={this.clearSignature}
                        >
                          {localize("worksheets", "Töröl")}
                        </Button>
                        <FormControlLabel
                          style={{ marginLeft: "auto" }}
                          control={
                            <Checkbox
                              checked={acceptSignatureOrder}
                              onChange={this.handleChangeCheckbox(
                                "acceptSignatureOrder"
                              )}
                              //value="checkedB"
                              color="primary"
                            />
                          }
                          label={
                            status === "FILLING" ? (
                              <span>
                                {localize("worksheets", "Elfogadom az")}{" "}
                                <Link
                                  onClick={evt => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    this.openASZF();
                                  }}
                                  style={{
                                    color: "#32408f",
                                    textDecoration: "underline"
                                  }}
                                >
                                  {localize("worksheets", "ÁSZF")}
                                </Link>
                                {localize(
                                  "worksheets",
                                  "-t , a munkát megrendelem"
                                )}
                              </span>
                            ) : (
                              localize("worksheets", "Átvettem a készüléket")
                            )
                          }
                        />
                        <Button
                          onClick={this.handleGetSignature}
                          variant="contained"
                          color="primary"
                          disabled={!acceptSignatureOrder || signatureIsEmpty}
                        >
                          {localize("worksheets", "Beküld")}
                        </Button>
                      </Toolbar>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      </BodyClassName>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.signing.loading,
    success: state.signing.success,
    failed: state.signing.failed,
    message: state.signing.message,
    saved: state.signing.saved,
    code: state.signing.code,
    token: state.fcm.token,
    worksheetToken: state.signing.token,
    screenShowCode: state.signing.screenShowCode,
    showPassword: state.signing.showPassword,
    fieldErrors: state.signing.fieldErrors,
    viewData: state.signing.viewData,
    reducerName: "signing",
    getSigned: state.signing.getSigned
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCode: signingsActions.getCode,
      fcmInitalize: fcmActions.initalize,
      onMessage: fcmActions.onMessage,
      sendFcm: signingsActions.sendFcm,
      unSubscribeConnecting: signingsActions.unSubscribeConnecting,
      unSubscribeDisconnected: signingsActions.unSubscribeDisconnected,
      subscribeDisconnect: signingsActions.subscribeDisconnect,
      sendAdminCode: signingsActions.sendAdminCode,
      unSubscribeSigning: signingsActions.unSubscribeSigning,
      subscribeSigning: signingsActions.subscribeSigning,
      unSubscribeSigned: signingsActions.unSubscribeSigned,
      sendSign: signingsActions.sendSign
    },
    dispatch
  );
};

const UsersReduxForm = reduxForm({
  form: "signing",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(Signing);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersReduxForm);
