import React, { PureComponent } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  INCOMINGSMS_PERMISSION,
  OUTGOINGSMS_PERMISSION,
  SMS_LIST_ITEMS_PERMISSION
} from "../../../constans/permissions";
import MenuPermission from "../../Permissions/MenuPermission";
import withRouter from "react-router/es/withRouter";
import localize from "helpers/localize";

class Navbar extends PureComponent {
  constructor(props) {
    super(props);
    let value = 0;
    switch (window.location.pathname.split("/")[1]) {
      case "outgoing-sms-messages":
        value = 1;
        break;
      case "sms-list-items":
        value = 2;
        break;
      default:
        value = 0;
    }
    this.state = {
      value
    };
  }

  handleChange = value => {
    this.props.history.push(`/${value}`);
  };

  render() {
    return (
      <AppBar className="mb-3" position="static" key={this.state.value}>
        <Tabs variant="scrollable" scrollButtons="on" value={this.state.value}>
          <MenuPermission module={INCOMINGSMS_PERMISSION}>
            <Tab
              label={localize("menu", "Bejövő SMS")}
              onChange={this.handleChange.bind(this, "incoming-sms-messages")}
            />
          </MenuPermission>
          <MenuPermission module={OUTGOINGSMS_PERMISSION}>
            <Tab
              label={localize("menu", "Kimenő SMS")}
              onChange={this.handleChange.bind(this, "outgoing-sms-messages")}
            />
          </MenuPermission>
          {/*<MenuPermission module={SMS_LIST_ITEMS_PERMISSION}>
            <Tab
              label={localize("menu", "Címlista")}
              onChange={this.handleChange.bind(this, "sms-list-items")}
            />
    </MenuPermission>*/}
        </Tabs>
      </AppBar>
    );
  }
}
export default withRouter(Navbar);
