import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { toastr } from "../../../../../helpers/CustomToastr";
import Email from "@material-ui/icons/Email";
import Button from "@material-ui/core/Button/Button";

import CardBox from "../../../../../Components/CardBox";
import ContainerHeader from "../../../../../Components/ContainerHeader";
import ChangeEmailForm from "./ChangeEmailForm";
import ChangeProfileForm from "./ChangeProfile";
import GenericForm from "../../../../../Components/Forms/GenericForm";

import localize from "helpers/localize";

class Profile extends PureComponent {
  componentWillMount = () => {};

  componentWillUnmount = () => {
    this.props.me();
  };

  profileSubmit = formData => {
    this.props.changeProfile(formData);
  };

  changeEmailSubmit = formData => {
    this.props.changeEmail(
      this.props.user.id,
      formData.email,
      formData.password
    );
  };

  changePassword = () => {
    this.props.changePassword(this.props.user.email);
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.saved && nextProps.saved) {
      toastr.success(localize("basic", "Sikeres művelet"));
    }
    if (!this.props.message && nextProps.message) {
      toastr.error(nextProps.message);
    }
  };

  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("user", "Saját profilom")}</title>
        </Helmet>
        <ContainerHeader
          title={
            <div>
              {localize("user", "Saját profilom")}
              {this.props.user.role_text && (
                <small style={{ marginLeft: 8 }}>
                  ({this.props.user.role_text})
                </small>
              )}
            </div>
          }
        />
        <CardBox styleName="" cardStyle="p-4">
          <GenericForm
            onSubmit={this.profileSubmit.bind(this)}
            fieldErrors={this.props.fieldErrors}
          >
            <ChangeProfileForm {...this.props} />
          </GenericForm>
        </CardBox>

        <ContainerHeader title={localize("user", "Email változtatás")} />
        <CardBox styleName="" cardStyle="p-4">
          <GenericForm
            onSubmit={this.changeEmailSubmit.bind(this)}
            fieldErrors={this.props.fieldErrors}
          >
            <ChangeEmailForm {...this.props} />
          </GenericForm>
        </CardBox>

        <ContainerHeader title={localize("user", "Jelszó változtatás")} />
        <CardBox styleName="" cardStyle="p-4">
          <Button
            onClick={this.changePassword.bind(this)}
            /*variant="contained"*/ color="primary"
          >
            <Email
              style={{
                marginRight: 8,
                marginTop: -2,
                marginBottom: 2
              }}
            />{" "}
            {localize("user", "Új jelszó kérés")}
          </Button>
        </CardBox>
      </div>
    );
  }
}

export default Profile;
