import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import NotificationsList from "./views/NotificationsList";
import * as notificationsActions from "../../../redux/modules/notifications";
import * as optionsActions from "../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.notifications.loading,
    success: state.notifications.success,
    message: state.notifications.message,
    data: state.notifications.data,
    total: state.notifications.total,
    limit: state.notifications.limit,
    page: state.notifications.page,
    order: state.notifications.order,
    orderMode: state.notifications.orderMode,
    deleted: state.notifications.deleted,
    deletedId: state.notifications.deletedId,
    reload: state.notifications.reload,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getNotificationList: notificationsActions.notificationsList,
      delete: notificationsActions.notificationDelete,
      setLoading: notificationsActions.setLoading,
      reset: notificationsActions.reset,
      readItem: notificationsActions.readItem
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsList);
