import inputStyle from "Components/Forms/Fields/inputStyle";
const documentListStyle = theme => ({
  buttonCell: {
    width: 28 + theme.spacing.unit + "px!important",
    paddingLeft: theme.spacing.unit + "px!important"
  },
  tableRow: {
    height: "auto"
  },
  table: {
    //marginLeft: theme.spacing.unit * 2 - 1,
    marginTop: theme.spacing.unit / 2,
    width: `calc(100% - ${theme.spacing.unit * 2 - 1}px)`
  },
  downloadButton: {
    width: 28,
    height: 28
  },
  uploadButton: {
    padding: 0,
    width: 28,
    height: 28,
    marginTop: 18
  },
  thead: {
    "& th": {
      paddingBottom: theme.spacing.unit,
      fontWeight: "normal!important"
    }
  },
  theadEmpty: {
    "& th": {
      borderBottom: "none"
    }
  },
  removeButton: {
    padding: 0,
    width: 28,
    height: 28
  },
  placeholder: {
    color: theme.palette.text.secondary
  },
  isViewLabel: {
    fontSize: 14,
    color: "#000"
  },
  ...inputStyle(theme)
});

export default documentListStyle;
