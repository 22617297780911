import React, { Component, Fragment } from "react";
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import { get } from "lodash";
import InputField from "Components/Forms/Fields/Input";
import SelectField from "Components/Forms/Fields/Select";
import CheckBoxField from "Components/Forms/Fields/Checkbox";
import DateField from "Components/Forms/Fields/Date";
import Wysiwyg from "Components/Forms/Fields/Wysiwyg";
import Validations from "Components/Forms/Validations";
import EditForm from "Components/Forms/Builder/EditForm";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Button, FormControlLabel, Checkbox } from "@material-ui/core";
import ModalConfirm from "Components/Modal/ModalConfirm";
import TableField from "Components/Forms/Fields/TableField";
import TargetItem from "./TargetItem";
import AddressesModal from "./AddressesModal";
import localize from "helpers/localize";

//Ezt kiválasztva jön a cíamzett választó
const TARGET_ADDRESSEES = "ADDRESSEES";
const TARGET_EVERYONE = "EVERYONE";

class NotificationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      addressesId: null,
      addressesType: null
    };
    this.modal = React.createRef();
  }

  getCurrentValue = () => {
    return this.props.currentValues || this.props.initialValues || {};
  };

  canPreview = () => {
    const { content, content_extended } = this.getCurrentValue();
    if (!content && !content_extended) {
      return false;
    }
    //console.log(content_extended && content_extended.substring(0, 7));
    if (
      content === "" &&
      (content_extended === "" ||
        (content_extended && content_extended.substring(0, 7) === "<p></p>"))
    ) {
      return false;
    }
    return true;
  };

  renderCustomButtons = () => {
    return (
      <Fragment>
        <Button
          //variant="contained"
          size="small"
          color="primary"
          onClick={this.showPreview}
          disabled={!this.canPreview()}
        >
          Megtekintés
        </Button>
      </Fragment>
    );
  };

  showPreview = () => {
    if (!this.canPreview()) {
      return;
    }
    const { content, content_extended } = this.getCurrentValue();
    //console.log(this.props.currentValues);
    this.modal
      .open(content, content_extended, null, "Ok", false, false)
      .then(() => {
        this.modal.close();
      });
  };

  openAdresses = (addressesId, addressesType) => {
    this.setState({ addressesId, addressesType });
  };

  handleTargetChange = target => {
    const { dispatch, change } = this.props;

    dispatch(change("accounting", null));
    if (target === TARGET_EVERYONE) {
      dispatch(change("addressees", null));
    }
  };

  accountEnabled = () => {
    //csak akkor legyen aktív, ha NEM a mindenki van kiválasztva, és a címzettek között van ügyfél
    const {
      currentValues: { target, addressees }
    } = this.props;

    if (!target || target === TARGET_EVERYONE) {
      return false;
    }
    if (!addressees || !Array.isArray(addressees)) {
      return false;
    }
    for (let i in addressees) {
      const adr = addressees[i];
      if (
        adr.value &&
        (adr.type === "client_id" || adr.type === "client_tag")
      ) {
        return true;
      }
    }

    return false;
  };

  render() {
    const {
      handleInputChange,
      fieldErrors,
      isViewPage,
      currentValues
    } = this.props;
    const { showPopup, addressesType, addressesId } = this.state;
    let targets = [];
    Object.keys(get(this.props, "baseFields.target.data", {})).forEach(key => {
      targets.push({ id: key, label: this.props.baseFields.target.data[key] });
    });
    //console.log(currentValues);

    return (
      <Fragment>
        <EditForm
          {...this.props}
          goBackAfterSubmit
          texts={{ saveButton: "Küldés" }}
          renderCustomButtons={this.renderCustomButtons}
        >
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Field
                name="content"
                label={localize("notifications", "Cím")}
                fieldError={fieldErrors.content}
                onChange={() => handleInputChange("content")}
                isRequired={true}
                validate={[Validations.required]}
                component={InputField}
                type="text"
                placeholder={localize("notifications", "Cím")}
                isViewPage={isViewPage}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Field
                    name="target"
                    label={localize("notifications", "Címzett")}
                    fieldError={fieldErrors.target}
                    onChange={evt => {
                      handleInputChange("target");
                      //console.log("evt", evt.target.value);
                      this.handleTargetChange(evt.target.value);
                    }}
                    isRequired={true}
                    validate={[Validations.required]}
                    component={SelectField}
                    placeholder={localize("notifications", "Címzett")}
                    isViewPage={isViewPage}
                    options={targets}
                  />
                </Grid>
              </Grid>
            </Grid>
            {currentValues.target === TARGET_ADDRESSEES && (
              <Grid item xs={12}>
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <h6 className="form-legend">
                      {localize("notifications", "Címzettek")}
                    </h6>
                    <FieldArray
                      name="addressees"
                      fullWidth
                      component={TableField}
                      fieldsError={fieldErrors.collectors}
                      isViewPage={isViewPage}
                      data={get(this.props, "currentValues.addressees", [])}
                      change={this.props.change}
                      dispatch={this.props.dispatch}
                      initialValues={this.props.initialValues}
                      columns={[
                        { name: "Típus", width: 200 },
                        { name: "Címzett" },
                        { name: "", width: 20 }
                      ]}
                      itemProps={{
                        openAdresses: this.openAdresses,
                        //shop_product_units,
                        /*shop_product_id: get(
                              this.props,
                              "baseFields.shop_product_id"
                            )*/
                        //shop_products,
                        //worksheetId: viewData ? viewData.id : null,
                        shop_product: get(
                          this.props,
                          "baseFields.shop_product",
                          {}
                        ),
                        distributor_id: get(
                          this.props,
                          "baseFields.distributor_id",
                          {}
                        ),
                        distributor_tag: get(
                          this.props,
                          "baseFields.distributor_tag",
                          {}
                        ),
                        client_id: get(this.props, "baseFields.client_id", {}),
                        client_tag: get(
                          this.props,
                          "baseFields.client_tag",
                          {}
                        ),
                        type: get(this.props, "baseFields.type", {})
                      }}
                      Item={TargetItem}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={16}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  style={{ display: "flex" }}
                >
                  <Field
                    name="send_email"
                    label={localize("notifications", "Email értesítés")}
                    fieldError={fieldErrors.send_email}
                    onChange={() => handleInputChange("send_email")}
                    //isRequired={true}
                    //validate={[Validations.required]}
                    component={CheckBoxField}
                    checkBoxStyle={{ paddingTop: 6, paddingBottom: 6 }}
                    labelStyle={{ paddingTop: 16 }}
                    //type="text"
                    //placeholder={localize("notifications", "type")}
                    isViewPage={isViewPage}
                  />
                  <Field
                    name="accounting"
                    label={localize("notifications", "Könyvelő")}
                    fieldError={fieldErrors.send_email}
                    onChange={() => handleInputChange("accounting")}
                    //isRequired={true}
                    //validate={[Validations.required]}
                    component={CheckBoxField}
                    checkBoxStyle={{ paddingTop: 6, paddingBottom: 6 }}
                    labelStyle={{ paddingTop: 16 }}
                    //type="text"
                    //placeholder={localize("notifications", "type")}
                    readOnly={!this.accountEnabled()}
                    isViewPage={isViewPage}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              style={{ display: "flex", marginTop: -16 }}
              className="full-width-field-container"
            >
              <FormControlLabel
                style={{ marginTop: 10, marginLeft: 0 }}
                control={
                  <Checkbox
                    color="primary"
                    checked={showPopup}
                    onChange={evt =>
                      this.setState({ showPopup: evt.target.checked })
                    }
                  />
                }
                label={
                  showPopup
                    ? localize(
                        "notifications",
                        "Felugró ablak a megadott dátumig:"
                      )
                    : localize("notifications", "Felugró")
                }
              />
              {showPopup && (
                <Field
                  name="popup"
                  //label={localize("notifications", "Dátumig")}
                  fieldError={fieldErrors.popup}
                  onChange={() => handleInputChange("popup")}
                  //isRequired={true}
                  placeholder={localize("notifications", "Dátum")}
                  validate={[Validations.required]}
                  component={DateField}
                  checkBoxStyle={{ paddingTop: 6, paddingBottom: 6 }}
                  labelStyle={{ paddingTop: 16 }}
                  //type="text"
                  //placeholder={localize("notifications", "type")}
                  isViewPage={isViewPage}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <Field
                name="content_extended"
                label={localize("notifications", "Üzenet")}
                fieldError={fieldErrors.content_extended}
                onChange={() => handleInputChange("content_extended")}
                component={Wysiwyg}
                //rows={5}
                minHeight={200}
                multiline
                placeholder={localize("notifications", "Üzenet")}
                isViewPage={isViewPage}
                isWysiwyg={true}
                key={"content_extended" + isViewPage}
              />
            </Grid>
          </Grid>
          <ModalConfirm
            onRef={ref => (this.modal = ref)}
            disableIcon
            disableAccept
            htmlContent
            //onAccept={() => {this.setState}}
          />
        </EditForm>
        <AddressesModal
          open={addressesId ? true : false}
          id={addressesId}
          type={addressesType}
          onClose={() => {
            this.setState({
              addressesType: null,
              addressesId: null
            });
          }}
        />
      </Fragment>
    );
  }
}

NotificationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  isViewPage: PropTypes.bool,
  enabledFields: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  baseFields: PropTypes.object,
  currentValues: PropTypes.object,
  initialValues: PropTypes.object
};

const NotificationReduxForm = reduxForm({
  form: "notifications",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false
})(NotificationForm);

export default connect(
  state => {
    const selector = formValueSelector("notifications");
    const currentValues = selector(
      state,
      "id",
      "content",
      "content_extended",
      "popup",
      "target",
      "addressees"
    );
    return {
      initialValues: state.notifications.viewData,
      currentValues
    };
  },
  null
)(NotificationReduxForm);
