import axios from "axios";
import { get } from "lodash";
import qs from "qs";

const CATALOG_OPTIONS_RESET = "CATALOG_OPTIONS_RESET";

const CATALOG_MENU_LOAD = "CATALOG_MENU_LOAD";
const CATALOG_MENU_SUCCESS = "CATALOG_MENU_SUCCESS";
const CATALOG_MENU_FAILED = "CATALOG_MENU_FAILED";

const CATALOG_PRODUCTS_LOAD = "CATALOG_PRODUCTS_LOAD";
const CATALOG_PRODUCTS_SUCCESS = "CATALOG_PRODUCTS_SUCCESS";
const CATALOG_PRODUCTS_FAILED = "CATALOG_PRODUCTS_FAILED";

const initialState = {
  loading: false,
  success: false,
  failed: false,
  message: false,
  totalProducts: 0,
  menuItems: [],
  products: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CATALOG_OPTIONS_RESET:
      return {
        initialState
      };
    case CATALOG_MENU_LOAD:
    case CATALOG_PRODUCTS_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        success: false,
        message: false
      };
    case CATALOG_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        success: true,
        message: false,
        menuItems: action.menuItems
      };
    case CATALOG_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        failed: false,
        success: true,
        message: false,
        totalProducts: action.totalProducts,
        products: action.products
      };
    case CATALOG_PRODUCTS_FAILED:
    case CATALOG_MENU_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        failed: true,
        message: action.message
      };
    default:
      return state;
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: CATALOG_OPTIONS_RESET
  });
};

export const addToCart = cartItem => dispatch => {
  dispatch({
    type: CATALOG_MENU_LOAD
  });
  return axios
    .post("/v1/shop/cart", {
      CartForm: {
        id: cartItem.id,
        quantity: cartItem.quantity
      }
    })
    .then(response => {
      const cartItems = get(response, "data.data.items", []);

      dispatch({
        type: CATALOG_MENU_SUCCESS,
        cartItems: cartItems
      });
      return response.data.data;
    })
    .catch(error => {
      dispatch({
        type: CATALOG_MENU_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};

export const getMenuItems = () => dispatch => {
  dispatch({
    type: CATALOG_MENU_LOAD
  });
  return axios
    .get("/v1/shop/menu")
    .then(response => {
      const menuItems = get(response, "data.data.items", []);

      dispatch({
        type: CATALOG_MENU_SUCCESS,
        menuItems: menuItems
      });
      return response.data.data;
    })
    .catch(error => {
      dispatch({
        type: CATALOG_MENU_FAILED,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};
export const getProducts = (url = null, pagionationOptions) => dispatch => {
  const _url = url ? url : "/v1/shop-products";
  let params = {
    page: pagionationOptions.page,
    // keyword: pagionationOptions.keyword,
    // sort: pagionationOptions.sort,
    "per-page": pagionationOptions.perPage
  };

  dispatch({
    type: CATALOG_PRODUCTS_LOAD
  });
  return axios
    .get(_url, {
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { encode: false });
      }
    })
    .then(response => {
      const products = get(response, "data.data", []);
      dispatch({
        type: CATALOG_PRODUCTS_SUCCESS,
        totalProducts: response.data.total,
        products: products
      });
      return response.data.data;
    })
    .catch(error => {
      dispatch({
        type: CATALOG_PRODUCTS_FAILED,
        totalProducts: 0,
        message: get(error, "response.data.message", null)
      });
      throw error.response;
    });
};
