import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BannedCashregistersList from "./views/BannedCashregistersList";
import * as bannedCashregistersActions from "../../../../redux/modules/banned-cashregisters";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.bannedCashregisters.loading,
    success: state.bannedCashregisters.success,
    message: state.bannedCashregisters.message,
    data: state.bannedCashregisters.data,
    total: state.bannedCashregisters.total,
    limit: state.bannedCashregisters.limit,
    page: state.bannedCashregisters.page,
    order: state.bannedCashregisters.order,
    orderMode: state.bannedCashregisters.orderMode,
    deleted: state.bannedCashregisters.deleted,
    deletedId: state.bannedCashregisters.deletedId,
    token: state.bannedCashregisters.token,
    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: bannedCashregistersActions.BannedCashregistersList,
      delete: bannedCashregistersActions.BannedCashregisterDelete,
      setLoading: bannedCashregistersActions.setLoading,
      reset: bannedCashregistersActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BannedCashregistersList);
