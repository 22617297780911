/* eslint-disable */
import axios from 'axios';
import qs from 'qs';
import { get } from 'lodash';

const DISTRIBUTORS_LIST_LOAD = 'DISTRIBUTORS_LIST_LOAD';
const DISTRIBUTORS_LIST_SUCCESS = 'DISTRIBUTORS_LIST_SUCCESS';
const DISTRIBUTORS_LIST_FAILED = 'DISTRIBUTORS_LIST_FAILED';
const DISTRIBUTORS_SET_LOADING = 'DISTRIBUTORS_SET_LOADING';

const DISTRIBUTORS_FORM_LOAD = 'DISTRIBUTORS_FORM_LOAD';
const DISTRIBUTORS_FORM_SUCCESS = 'DISTRIBUTORS_FORM_SUCCESS';
const DISTRIBUTORS_FORM_FAILED = 'DISTRIBUTORS_FORM_FAILED';

const DISTRIBUTOR_DELETE_LOAD = 'DISTRIBUTOR_DELETE_LOAD';
const DISTRIBUTOR_DELETE_SUCCESS = 'DISTRIBUTOR_DELETE_SUCCESS';
const DISTRIBUTOR_DELETE_FAILED = 'DISTRIBUTOR_DELETE_FAILED';

const DISTRIBUTOR_EDIT_LOAD = 'DISTRIBUTOR_EDIT_LOAD';
const DISTRIBUTOR_EDIT_SUCCESS = 'DISTRIBUTOR_EDIT_SUCCESS';
const DISTRIBUTOR_EDIT_FAILED = 'DISTRIBUTOR_EDIT_FAILED';

const DISTRIBUTOR_VIEW_LOAD = 'DISTRIBUTOR_VIEW_LOAD';
const DISTRIBUTOR_VIEW_SUCCESS = 'DISTRIBUTOR_VIEW_SUCCESS';
const DISTRIBUTOR_VIEW_FAILED = 'DISTRIBUTOR_VIEW_FAILED';

const DISTRIBUTORS_RESET = 'DISTRIBUTORS_RESET';
const DISTRIBUTORS_RESET_FORM = 'DISTRIBUTORS_RESET_FORM';

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit:process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: '',
  orderMode: 'ASC',
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISTRIBUTORS_RESET:
      return {
        ...initialState
      };
    case DISTRIBUTORS_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case DISTRIBUTOR_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case DISTRIBUTORS_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false
      };
    case DISTRIBUTOR_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case DISTRIBUTOR_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case DISTRIBUTORS_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case DISTRIBUTORS_FORM_FAILED:
    case DISTRIBUTOR_EDIT_FAILED:
    case DISTRIBUTOR_DELETE_FAILED:
    case DISTRIBUTORS_LIST_FAILED:
    case DISTRIBUTOR_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false
      };
    case DISTRIBUTORS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode
      };
      case DISTRIBUTOR_VIEW_SUCCESS:
        return {
            ...state,
            loading: false,
            success: true,
            failed: false,
            viewData: action.viewData,
            commentThread: action.commentThread || false,
            viewLoading: false
        };
    case DISTRIBUTORS_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case DISTRIBUTOR_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case DISTRIBUTOR_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: action.id,
        viewLoading: false
      };
    case DISTRIBUTORS_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
}

export const reset = () => dispatch => {
  dispatch({
    type: DISTRIBUTORS_RESET
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: DISTRIBUTORS_RESET_FORM
  });
};

export const updateDistributors = (model,id,data) => dispatch => {
  dispatch({
    type: DISTRIBUTOR_EDIT_LOAD
  });

    let obj = {};
    obj[model] = data;

  return axios.put('/v1/distributors/'+id, obj).then(
      (response) => { dispatch({
        type: DISTRIBUTOR_EDIT_SUCCESS
      })
      return response.data;
    }
  ).catch(error => {
    dispatch({
      type: DISTRIBUTOR_EDIT_FAILED,
      message: get(error, 'response.data.message', null),
      formErrors: get(error, 'response.data.form_errors', {})
    });
    throw error.response;
  });
};

export const viewDistributors = (id) => dispatch => {
    dispatch({
        type: DISTRIBUTOR_VIEW_LOAD
    });
    return axios.get('/v1/distributors/'+id).then(
        response => dispatch({
            type: DISTRIBUTOR_VIEW_SUCCESS,
            viewData: get(response, 'data.data'),
            commentThread: get(response, 'data.commentThread', false)
        })
    ).catch(error => {
        dispatch({
            type: DISTRIBUTOR_VIEW_FAILED,
            message: get(error, 'response.data.message', null)
        })
    });
};

export const createDistributors = (model, data) => dispatch => {
  dispatch({
    type: DISTRIBUTOR_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios.post('/v1/distributors', obj).then(response => dispatch({
    type: DISTRIBUTOR_EDIT_SUCCESS,
    id: get(response, 'data.data.id', null),
  })).catch(error => {
    dispatch({
      type: DISTRIBUTOR_EDIT_FAILED,
      message: get(error, 'response.data.message', null),
      formErrors: get(error, 'response.data.form_errors', {})
    })
  });
};

export const distributorDelete = (id) => dispatch => {
  dispatch({
    type: DISTRIBUTOR_DELETE_LOAD
  });
  return axios.delete('/v1/distributors/'+id)
      .then((response) => dispatch({
        type: DISTRIBUTOR_DELETE_SUCCESS,
        id
      })).catch(error => dispatch({
        type: DISTRIBUTOR_DELETE_FAILED,
        message: get(error, 'response.data.message', null)
      }));
};

export const setLoading = (loading) => dispatch => {
  dispatch({
    type: DISTRIBUTORS_SET_LOADING,
    loading
  });
};

export const distributorsList = (page, keyword, sort, filters, perPage) => dispatch => {
  dispatch({
    type: DISTRIBUTORS_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    'per-page': perPage
  };

  params[filters.model] = filters.filters;

  return axios.get(
      '/v1/distributors',
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
  ).then(response => {
    let sort = get(response, 'data.sort', null),
        mode = 'ASC';
    if(sort && sort.indexOf('-') !== -1) {
      mode = 'DESC';
      sort = sort.substr(1);
    }
    return dispatch({
      type: DISTRIBUTORS_LIST_SUCCESS,
      data: get(response, 'data.data', null),
      page: get(response, 'data.page', 0),
      limit: get(response, 'data.per-page', 0),
      total: get(response, 'data.total', 0),
      order: sort,
      orderMode: mode
    });
  })
  .catch(error => dispatch({
    type: DISTRIBUTORS_LIST_FAILED,
    message: get(error, 'response.data.message', null)
  }));
};
