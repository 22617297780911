import React from "react";

import NotificationForm from "./NotificationForm";
import EditPage from "Components/Extends/EditPage";
import Edit from "Components/Edit/Edit";
import localize from "helpers/localize";

class EditNotification extends EditPage {
  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("notifications", "Értesítés megtekintése");
    }
    return this.props.match.params.id
      ? localize("notifications", "Értesítés módosítása")
      : localize("notifications", "Értesítés létrehozása");
  };

  render() {
    return (
      <Edit
        getPagetitle={this.getPageTitle}
        isViewPage={this.isViewPage()}
        {...this.props}
        key={"EditNotification" + (this.isViewPage() ? "isViewPage" : "")}
      >
        <NotificationForm {...this.props} isViewPage={this.isViewPage()} />
      </Edit>
    );
  }
}

export default EditNotification;
