import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditArticle from "./views/EditArticle";
import * as articlesActions from "../../../redux/modules/articles";

const mapStateToProps = state => {
  return {
    loading: state.articles.loading,
    success: state.articles.success,
    saved: state.articles.saved,
    message: state.articles.message,
    fieldErrors: state.articles.formErrors,
    id: state.articles.id,
    viewData: state.articles.viewData,
    reducerName: "articles",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: articlesActions.createArticles,
      update: articlesActions.updateArticles,
      view: articlesActions.viewArticles,
      reset: articlesActions.reset,
      resetForm: articlesActions.resetForm
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditArticle);
