import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import BackIcon from "@material-ui/icons/ChevronLeft";
import EditIcon from "@material-ui/icons/Edit";
import Price from "./Price";
import AddToCart from "./AddToCart";
import StockInfo from "./StockInfo";
import Tags from "./Tags";
import ProductLink from "./ProductLink";
import RelatedProducts from "./RelatedProducts";
import { getUrl } from "Components/New/Image";
import {
  Magnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION
} from "react-image-magnifiers";
import { Grid, Fab } from "@material-ui/core";
import { BASE_PATH_CATALOG } from "./../shopPath";
import localize from "helpers/localize";

const styles = theme => ({
  card: {
    //maxWidth: 345
    display: "flex",
    height: "100%",
    position: "relative",
    //justifyContent: "space-between",
    flexDirection: "column"
  },
  tags: {
    height: "100%",
    alignSelf: "strech"
  },
  media: {
    height: 0,
    //paddingTop: "56.25%" // 16:9
    paddingTop: "80.25%", // 16:9,
    margin: theme.spacing.unit
  },
  actions: {
    justifyContent: "flex-start",
    marginTop: "auto"
  },
  addToCartInput: {
    width: 40,
    marginLeft: theme.spacing.unit * 2,
    "& input": {
      textAlign: "center"
    }
  },
  addToCartButton: {
    marginLeft: theme.spacing.unit
  },
  stockContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing.unit
  },
  stockInfo: {},
  price: {},
  expand: {
    transform: "rotate(0deg)",
    //marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500]
  },
  relatedTitle: {
    marginBottom: theme.spacing.unit * 2
  },
  backButton: {
    position: "absolute",
    zIndex: 1000,
    width: 46,
    height: 46,
    marginLeft: 6,
    marginTop: 6
  }
});

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: 1,
      relatedProducts: [props.editItem, props.editItem, props.editItem]
    };
  }

  onChangeQty = qty => {
    this.setState({ qty });
  };

  goBack = () => {
    const {
      history: { push }
    } = this.props;
    push(BASE_PATH_CATALOG);
  };

  render() {
    const { classes, editItem: item } = this.props;
    const { qty } = this.state;

    return (
      <Card className={classes.card}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <CardContent
              onContextMenu={e => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
            >
              <Fab className={classes.backButton} onClick={this.goBack}>
                <BackIcon />
              </Fab>
              {/*<IconButton className={classes.backButton} onClick={this.goBack}>
                <BackIcon />
            </IconButton>*/}
              <Magnifier
                imageSrc={getUrl(item.image_id)}
                imageAlt={item.name}
                im
                //largeImageSrc="./large-image.jpg" // Optional
                mouseActivation={MOUSE_ACTIVATION.CLICK} // Optional
                touchActivation={TOUCH_ACTIVATION.TAP} // Optional
              />
            </CardContent>
          </Grid>
          <Grid item xs={12} md={6} lg={7} xl={8}>
            <CardHeader
              /*avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  %
                </Avatar>
              }*/
              action={
                <ProductLink id={item.id} isEdit>
                  <IconButton aria-label="settings">
                    <EditIcon />
                  </IconButton>
                </ProductLink>
              }
              titleTypographyProps={{
                variant: "h6"
              }}
              title={<ProductLink id={item.id}>{item.name}</ProductLink>}
              subheader={<ProductLink id={item.id}>{item.sku}</ProductLink>}
            />
            <CardContent>
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
              <Tags rootClassName={classes.tags} tags={item.tags} />
            </CardContent>
            <div className={classes.stockContainer}>
              {localize("shop", "Készlet információ")}:
              <StockInfo
                stockInfo={item.stock_info}
                stockInfoText={item.stock_info_text}
                rootClassName={classes.stockInfo}
              />
            </div>
            <CardActions disableActionSpacing className={classes.actions}>
              <Price
                showAllPrices
                prices={item.prices}
                qty={qty}
                rootClassName={classes.price}
              />
              <AddToCart
                qty={qty}
                onChangeQty={this.onChangeQty}
                id={item.id}
                inputClassName={classes.addToCartInput}
                buttonClassName={classes.addToCartButton}
                type="button"
              />
            </CardActions>
          </Grid>
          <Grid item xs={12}>
            <CardContent>
              <Typography className={classes.relatedTitle} variant="h5">
                {localize("shop", "Kapcsolódó termékek")}
              </Typography>
              <RelatedProducts tags={item.tags} />
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

Product.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  editItem: PropTypes.object.isRequired
};

export default withStyles(styles)(Product);
