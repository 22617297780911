import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Validations from "Components/Forms/Validations";
import { Field } from "redux-form";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import SelectField from "Components/Forms/Fields/Select";
import Autocomplete from "Components/Forms/Fields/Autocomplete";
import { get } from "lodash";
import fieldHelper from "helpers/fieldHelper";
import ListIcon from "@material-ui/icons/List";
import { IconButton, Tooltip } from "@material-ui/core";
import localize from "helpers/localize";

class TargetItem extends PureComponent {
  state = {
    anchorEl: null,
    targetType: null,
    targetId: null
  };

  changePrice = price => {
    const { dispatch, change, collector } = this.props;
    dispatch(change(`${collector}.price`, price));
  };

  onSelectType = (evt, targetType) => {
    this.setState({ targetType });
  };
  onSelectId = targetId => {
    this.setState({ targetId });
  };

  onQuantityChange = evt => {
    const { prices } = this.state;
    const qty = evt.target.value ? parseInt(evt.target.value) : 1;
    this.setState({ qty });
    if (prices) {
      const price = this.getPrices(prices, qty);
      this.changePrice(price);
    }
  };

  handlePopoverOpen = event => {
    //console.log("hover", event.currentTarget);
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  getSelectProps = () => {
    const { targetType } = this.state;
    const {
      distributor_id,
      distributor_tag,
      client_id,
      client_tag
    } = this.props;

    let result = {};
    switch (targetType) {
      case "distributor_id":
        result.url = get(distributor_id, "url", null);
        break;
      case "distributor_tag":
        result.url = get(distributor_tag, "url", null);
        result.isTag = true;
        break;
      case "client_id":
        result.url = get(client_id, "url", null);
        break;
      case "client_tag":
        result.url = get(client_tag, "url", null);
        result.isTag = true;
        break;

      default:
        result.url = { path: "" };
        break;
    }

    return result;
  };

  render() {
    const {
      isViewPage,
      fieldError,
      index,
      collector,
      deleteCell,
      distributor_id,
      distributor_tag,
      client_id,
      client_tag,
      openAdresses,
      type
    } = this.props;
    const { targetType, targetId } = this.state;

    const f = fieldHelper({ type });

    const types = f.options("type");

    //console.log("targetType", targetType);

    return (
      <TableRow>
        <TableCell style={{ overflow: "visible" }}>
          <Field
            name={`${collector}.type`}
            fieldError={fieldError.type}
            component={SelectField}
            isViewPage={isViewPage}
            validate={[Validations.required]}
            options={types}
            onChange={this.onSelectType}
            //viewShowKey
          />
        </TableCell>
        <TableCell style={{ overflow: "visible" }}>
          <Field
            //key={targetType}
            onSelect={this.onSelectId}
            name={`${collector}.value`}
            fieldError={fieldError.value}
            component={Autocomplete}
            isViewPage={isViewPage}
            validate={[Validations.required]}
            formName="notification"
            mode="select"
            minChars={0}
            loadOnFocus
            showInTable
            fillDefaultValue
            //url={get(distributor_id, "url", null)}
            labelKey="text"
            valueKey="id"
            disabled={targetType ? false : true}
            {...this.getSelectProps()}
          />
        </TableCell>
        <TableCell align="right">
          {(targetType === "distributor_tag" ||
            targetType === "client_tag") && (
            <Tooltip
                title={localize("notifications", "Címzettek megjelenítése")}
              >
              <IconButton
                disabled={targetId ? false : true}
                style={{ padding: 0 }}
                onClick={evt => {
                  openAdresses(targetId, targetType);
                }}
              >
                <ListIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>

        {deleteCell(index)}
      </TableRow>
    );
  }
}

TargetItem.propTypes = {
  isViewPage: PropTypes.bool,
  fieldError: PropTypes.object,
  index: PropTypes.number,
  collector: PropTypes.string,
  deleteCell: PropTypes.func,
  dispatch: PropTypes.func,
  change: PropTypes.func,
  distributor_id: PropTypes.object,
  distributor_tag: PropTypes.object,
  client_id: PropTypes.object,
  client_tag: PropTypes.object,
  type: PropTypes.object
};

export default TargetItem;
