import React, { Fragment } from "react";


import BannedCashregisterForm from "./BannedCashregisterForm";
import EditPage from "../../../../../Components/Extends/EditPage";
import Edit from "../../../../../Components/Edit/Edit";
import Navbar from "../../Navbar";
import localize from "helpers/localize";

class EditBannedCashregister extends EditPage {
  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("cashregisters", "Tiltott AP szám megtekintése");
    }
    return this.props.match.params.id
      ? localize("cashregisters", "Tiltott AP szám szerkesztése")
      : localize("cashregisters", "Tiltott AP szám létrehozása");
  };
  render() {
    return (
      <Fragment>
        <Navbar {...this.props} />
        <Edit
          getPagetitle={this.getPageTitle}
          isViewPage={this.isViewPage()}
          {...this.props}
        >
          <BannedCashregisterForm
            {...this.props}
            isViewPage={this.isViewPage()}
          />
        </Edit>
      </Fragment>
    );
  }
}

export default EditBannedCashregister;
