import { TextField, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import MUIList from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import CommentIcon from "@material-ui/icons/Comment";
import CreateWorksheetIcon from "@material-ui/icons/DescriptionOutlined";
import { ColorIndicator } from "Components/Forms/Fields/Select";
import Modal from "Components/Modal/Modal";
import ModalConfirm from "Components/Modal/ModalConfirm";
import UploadButton from "Components/UploadButton";
import imgMobilAdat from "assets/img/mobil_adat.png";
import imgTelekom from "assets/img/telekom.png";
import authRules from "helpers/authRules";
import localize from "helpers/localize";
import restHelper from "helpers/restHelper";
import { get } from "lodash";
import QueryString from "qs";
import React, { Fragment, PureComponent } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { setColor } from "redux/modules/cashregisters";
import ContainerHeader from "../../../../../Components/ContainerHeader";
import List from "../../../../../Components/List/List";
import { toastr } from "../../../../../helpers/CustomToastr";
import FuncPermission from "../../../../Permissions/FuncPermission";
import Navbar from "../../Navbar";
import CashregisterImport from "../CashregisterImport";
import CashregisterRead from "../CashregisterRead";
import ClientPopover from "./ClientPopover";
class CashregistersList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      keyword: null,
      search: null,
      filters: null,
      perPage: null,
      readOpen: false,
      importOpen: false,
      clientAnchorEl: null,
      client_id: null,
      importFileKobak: null,
      importKobakError: null,
      openColoring: false,
      selectedColor: null,
      selectedRow: null
    };
    this.modalKobak = React.createRef();
    this.commentField = React.createRef();
  }

  componentWillMount = () => {
    //this.props.subscribeReceived();
  };

  componentWillUnmount = () => {
    //this.props.unSubscribeReceived();
  };

  getList = (page, keyword, sort, filters, perPage) => {
    this.setState({
      page,
      keyword,
      sort,
      filters,
      perPage
    });
    return this.props.getList(page, keyword, sort, filters, perPage);
  };

  handCloseRead = () => {
    this.setState({ readOpen: false });
    // this.props.closeReceived();
    const { page, keyword, sort, filters, perPage } = this.state;
    this.getList(page, keyword, sort, filters, perPage);
  };

  handleRequestCloseImport = () => {
    this.setState({ importOpen: false });
    const { page, keyword, sort, filters, perPage } = this.state;
    this.getList(page, keyword, sort, filters, perPage);
  };

  handleDownload = () => {
    const { page, keyword, sort, filters } = this.state;
    this.props.exportList(page, keyword, sort, filters);
  };

  getExportUrl = () => {
    const { page, keyword, sort, filters } = this.state;
    const params = QueryString.stringify(
      {
        //page,
        keyword,
        sort,
        CashregisterSearch: filters ? filters.filters : undefined,
        accessToken: localStorage.getItem("access_token").replace("Bearer ", "")
      },
      { encode: false }
    );
    return `${process.env.REACT_APP_HOST}/v1/cashregisters/export?${params}`;
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.file_message && nextProps.file_message) {
      toastr.error(nextProps.file_message);
    }
    // if(!this.props.receivedOpen && nextProps.receivedOpen && !this.state.readOpen) {
    //     this.setState({readOpen: true})
    // }
  }

  // create

  customButtons = () => {
    return (
      <div className="mr-auto">
        <FuncPermission
          module={this.props.module}
          permission={"cashregisters.read"}
        >
          <Button
            style={{ marginRight: 8 }}
            color="primary"
            onClick={() => this.setState({ readOpen: true })}
          >
            {localize("cashregisters", "Ügyféladatok beolvasása")}
          </Button>
        </FuncPermission>
        <FuncPermission
          module={this.props.module}
          permission="cashregisters.kobakimport"
        >
          <Button color="primary" onClick={this.openImportKobak}>
            {localize("cashregisters", "KOBAK Import")}
          </Button>
        </FuncPermission>
      </div>
    );
  };

  customIcons = obj => {
    return (
      <Fragment>
        <Tooltip
          title={localize("cashregisters", "Megjegyzés")}
          disableFocusListener
        >
          <Button
            className="jr-btn jr-btn-xs"
            target="_blank"
            onClick={this.openColoring(obj)}
            //component={Link}
            //to={`/worksheets/create?apn=${obj.apn}`}
          >
            <CommentIcon />
          </Button>
        </Tooltip>
        <Tooltip
          title={localize("cashregisters", "Új munkalap")}
          disableFocusListener
        >
          <Button
            className="jr-btn jr-btn-xs"
            target="_blank"
            component={Link}
            to={`/worksheets/create?apn=${obj.apn}`}
          >
            <CreateWorksheetIcon />
          </Button>
        </Tooltip>
      </Fragment>
    );
  };

  /* Set color start */
  saveColor = () => {
    const { selectedRow, selectedColor } = this.state;
    console.log(this.commentField.current.value);
    const comment = this.commentField.current
      ? this.commentField.current.value
      : null;
    setColor(
      selectedRow.id,
      selectedColor ? selectedColor.id : null,
      comment === "" ? null : comment
    ).then(response => {
      this.closeColoring();
      const newRow = {
        ...selectedRow,
        color_text: selectedColor ? selectedColor.label : null,
        color: selectedColor ? selectedColor.id : null,
        comment
      };

      this.props.changeRow(newRow);
      //this.refreshList();
    });
  };

  openColoring = row => evt => {
    evt.preventDefault();
    this.setState(
      {
        openColoring: true,
        selectedRow: row,
        selectedColor: row.color
          ? { id: row.color, label: row.color_text }
          : null
      },
      () => {
        if (this.commentField.current) {
          this.commentField.current.value = row.comment;
          this.commentField.current.focus();
        }
      }
    );
  };

  closeColoring = () => {
    this.setState(
      {
        openColoring: false,
        selectedColor: null,
        selectedRow: null
      },
      () => {
        if (this.commentField.current) {
          this.commentField.current.value = "";
        }
      }
    );
  };

  setColor = color => () => {
    this.setState({ selectedColor: color });
  };

  /* Set color end */

  clientPopoverOpen = id => event => {
    this.setState({ client_id: id, clientAnchorEl: event.currentTarget });
  };

  clientPopoverClose = () => {
    this.setState({ clientAnchorEl: null, client_id: null });
  };

  openImportKobak = () => {
    this.modalKobak.open(
      "KOBAK Import",
      //"Telekomos lista fájl leírása",
      null,
      null, //this.state.file.file_name,
      "Importálás",
      "Mégsem"
    );
  };

  onErrorKobak = error => {
    //console.log("onError", error);
    this.setState({ importError: error });
  };

  /**
   * Csatol egy feltöltött dokumentumot
   */
  onUploadKobak = file => {
    //console.log("onUpload", file);
    this.setState({
      importFileKobak: file,
      importErrorKobak: null
    });
    this.modalKobak.error(null);
  };

  /**
   * Eltávolít egy csatolt dokumentumot
   */
  onRemoveKobak = type => {
    //console.log("onRemoveKobak", type);
    /*let documents = Object.assign({}, this.state.documents);
    documents[type].current = [];*/
    this.setState({
      importFileKobak: null
    });
  };

  onAcceptKobak = () => {
    if (!this.state.importFileKobak) {
      this.modalKobak.error(
        localize("cashregisters", "Nincs fájl kiválasztva")
      );
      return;
    }
    this.modalKobak.loading(true);
    restHelper
      .create("cashregisters/kobak-import", "KobakImportForm", {
        file_id: this.state.importFileKobak.id
      })
      .then(res => {
        //console.log("bulkFeedback", res)
        this.modalKobak.loading(false);
        this.modalKobak.close();
        this.setState({
          importFileKobak: null
        });
      })
      .catch(error => {
        //console.log("error", error.response);
        let errorMessage = this.handleImportErrorKobak(error, "file_id");
        this.modalKobak.loading(false);
        this.modalKobak.error(errorMessage);
      });
  };

  handleImportErrorKobak = (error, fieldName) => {
    let errors = get(error, `response.data.form_errors.${fieldName}`, []);
    //errors = ["rossz", "Mégrosszabb", "nagyon rossz"];
    if (errors.length === 0) {
      return get(
        error,
        "response.data.message",
        localize("cashregisters", "Váratlan hiba")
      );
    }
    if (errors.length === 1) {
      return errors[0];
    }
    return (
      <div>
        Hibák:
        <br />
        <textarea style={{ width: "100%", height: 40 }}>
          {errors.join("\n")}
        </textarea>
      </div>
    );
  };

  render() {
    const {
      readOpen,
      clientAnchorEl,
      client_id,
      openColoring,
      selectedColor,
      selectedRow
    } = this.state;
    const openClientPopover = Boolean(clientAnchorEl);
    const clientPopoverId = openClientPopover ? "client-popover" : undefined;
    const colors = get(this.props, "columns.color_text.filter.data", []);
    const exportUrl = `${process.env.REACT_APP_HOST}/v1/cashregisters?`;
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <CashregisterRead
          {...this.props}
          onClose={this.handCloseRead}
          open={readOpen}
        />

        <Helmet>
          <title>{localize("cashregisters", "Pénztárgépek")}</title>
        </Helmet>
        <Navbar {...this.props} />
        <ContainerHeader title={localize("cashregisters", "Pénztárgépek")} />
        {this.state.importOpen && (
          <Dialog
            open={this.state.importOpen}
            scroll="body"
            className="col-md-8 col-lg-6 col-12 modal-centered"
          >
            <DialogTitle>
              {localize("cashregisters", "Napló file beolvasása XML-ből")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <CashregisterImport
                  {...this.props}
                  handleRequestClose={this.handleRequestCloseImport.bind(this)}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleRequestCloseImport.bind(this)}
                variant="contained"
                color="secondary"
                component="span"
              >
                {localize("cashregisters", "Bezár")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <Popover
          id={clientPopoverId}
          open={openClientPopover}
          anchorEl={clientAnchorEl}
          disableRestoreFocus
          onClose={this.clientPopoverClose}
          //onMouseOut={this.clientPopoverClose}
          style={{ pointerEvents: "none" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          <ClientPopover id={client_id} />
        </Popover>
        <ModalConfirm
          onRef={ref => (this.modalKobak = ref)}
          disableIcon
          onAccept={this.onAcceptKobak}
          modalBodyComp={
            <div>
              <div>
                {localize(
                  "cashregisters",
                  "Válassza ki a KOBAK csv-t a tallózás gomb segítségével, majd nyomja meg az importálás gombot."
                )}
              </div>
              {this.state.importFileKobak
                ? this.state.importFileKobak.file_name
                : localize("cashregisters", "Dokumentum tallózása")}
              <UploadButton
                name={"import_file"}
                label={""}
                onUpload={this.onUploadKobak}
                onRemove={this.onRemoveKobak}
                file={this.state.importFileKobak}
                onError={this.onErrorKobak}
                disableDelete={false}
                acceptedFiles=".csv"
                type="upload"
              />
              {this.state.importKobakError && (
                <div>{this.state.importKobakError}</div>
              )}
            </div>
          }
        />
        <Modal
          title={localize("worksheets", "Megjegyzés")}
          open={openColoring}
          disableIcon
          onClose={this.closeColoring}
          onCancel={this.closeColoring}
          onAccept={this.saveColor}
        >
          <MUIList component="nav" aria-label="coloring">
            <ListItem
              dense
              style={{
                backgroundColor: !selectedColor ? "#ddd" : undefined
              }}
              button
              onClick={this.setColor(null)}
            >
              <ListItemIcon>
                <ColorIndicator color={undefined} size={20} />
              </ListItemIcon>
              <ListItemText primary={localize("worksheets", "Nincs")} />
            </ListItem>
            {colors.map((color, index) => {
              return (
                <ListItem
                  dense
                  style={{
                    backgroundColor:
                      selectedColor && color.id === selectedColor.id
                        ? "#ddd"
                        : undefined
                  }}
                  key={index}
                  button
                  onClick={this.setColor(color)}
                >
                  <ListItemIcon>
                    <ColorIndicator color={color.id} size={20} />
                  </ListItemIcon>
                  <ListItemText primary={color.label} />
                </ListItem>
              );
            })}
          </MUIList>
          <TextField
            inputRef={this.commentField}
            InputLabelProps={{
              shrink: true
            }}
            key={selectedRow ? selectedRow.id : "none"}
            label={localize("worksheets", "Megjegyzés")}
            multiline
            rows={5}
            variant="outlined"
            fullWidth
          />
        </Modal>
        <List
          {...this.props}
          disabledTooltips={{
            client_name: authRules.hasPermissionByKeyAndName(
              "cashregisters",
              "cashregisters.client.view"
            )
          }}
          exportUrl={authRules.isRoot ? this.getExportUrl() : undefined}
          disableEditIcon={true}
          customButtons={this.customButtons.bind(this)}
          customIcons={this.customIcons}
          getList={this.getList.bind(this)}
          renderCell={(row, rowIndex, colIndex, header) => {
            if (header._key === "dph") {
              const prefix = row.dms ? row.dms.substring(0, 2) : null;
              let imgSrc = null;
              if (prefix === "23") {
                imgSrc = imgTelekom;
              } else if (prefix === "20") {
                imgSrc = imgMobilAdat;
              }
              if (imgSrc) {
                return (
                  <span>
                    <img
                      style={{
                        height: 20,
                        marginRight: 2,
                        marginTop: -1,
                        marginBottom: 1
                      }}
                      src={imgSrc}
                    />
                    {row["dph"]}
                  </span>
                );
              }
            }

            if (header._key === "apn") {
              return (
                <Link to={`/cashregisters/view/${row.id}`}>{row.apn}</Link>
              );
            }

            if (
              header._key === "client_name" &&
              authRules.hasPermissionByKeyAndName(
                "cashregisters",
                "cashregisters.client.view"
              )
            ) {
              return (
                <Link
                  //onMouseOver={this.clientPopoverOpen(row.client_id)}
                  onMouseEnter={this.clientPopoverOpen(row.client_id)}
                  onMouseLeave={this.clientPopoverClose}
                  to={`/clients/view/${row.client_id}`}
                >
                  {row.client_name}
                </Link>
              );
            }

            return false;
          }}
          rowFormat={item => {
            if (item.suspended_by_service_order === 1) {
              return {
                backgroundColor: "#ef9a9a"
              };
            } else if (item.color) {
              return {
                backgroundColor: item.color
              };
            }
          }}
        />
      </div>
    );
  }
}

export default CashregistersList;
