import axios from "axios";
import qs from "qs";
import { get } from "lodash";

const WORKSHEETS_LIST_LOAD = "WORKSHEETS_LIST_LOAD";
const WORKSHEETS_LIST_SUCCESS = "WORKSHEETS_LIST_SUCCESS";
const WORKSHEETS_LIST_FAILED = "WORKSHEETS_LIST_FAILED";
const WORKSHEETS_SET_LOADING = "WORKSHEETS_SET_LOADING";

const WORKSHEETS_STATISTIC_LOAD = "WORKSHEETS_STATISTIC_LOAD";
const WORKSHEETS_STATISTIC_SUCCESS = "WORKSHEETS_STATISTIC_SUCCESS";
const WORKSHEETS_STATISTIC_FAILED = "WORKSHEETS_STATISTIC_FAILED";

const WORKSHEETS_FORM_LOAD = "WORKSHEETS_FORM_LOAD";
const WORKSHEETS_FORM_SUCCESS = "WORKSHEETS_FORM_SUCCESS";
const WORKSHEETS_FORM_FAILED = "WORKSHEETS_FORM_FAILED";

const WORKSHEET_DELETE_LOAD = "WORKSHEET_DELETE_LOAD";
const WORKSHEET_DELETE_SUCCESS = "WORKSHEET_DELETE_SUCCESS";
const WORKSHEET_DELETE_FAILED = "WORKSHEET_DELETE_FAILED";

const WORKSHEET_EDIT_LOAD = "WORKSHEET_EDIT_LOAD";
const WORKSHEET_EDIT_SUCCESS = "WORKSHEET_EDIT_SUCCESS";
const WORKSHEET_EDIT_FAILED = "WORKSHEET_EDIT_FAILED";

const WORKSHEET_CHANGE = "WORKSHEET_CHANGE";

const WORKSHEET_VIEW_LOAD = "WORKSHEET_VIEW_LOAD";
const WORKSHEET_VIEW_SUCCESS = "WORKSHEET_VIEW_SUCCESS";
const WORKSHEET_VIEW_FAILED = "WORKSHEET_VIEW_FAILED";

const WORKSHEETS_RESET = "WORKSHEETS_RESET";
const WORKSHEETS_CHANGE_ROW = "WORKSHEETS_CHANGE_ROW";
const WORKSHEETS_RESET_FORM = "WORKSHEETS_RESET_FORM";

const initialState = {
  loading: false,
  success: false,
  saved: false,
  failed: false,
  data: [],
  message: null,
  total: 0,
  limit: process.env.REACT_APP_LIST_LIMIT,
  page: null,
  order: "",
  orderMode: "ASC",
  deleted: false,
  deletedId: null,
  formErrors: {},
  viewData: null,
  viewLoading: false,
  commentThread: false,
  id: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WORKSHEETS_RESET:
      return {
        ...initialState
      };
    case WORKSHEETS_CHANGE_ROW:
      var data = state.data.map(row => {
        if (row.id === action.row.id) {
          return Object.assign({}, action.row);
        } else {
          return Object.assign({}, row);
        }
      });

      return {
        ...state,
        data
      };
    case WORKSHEETS_RESET_FORM:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        formErrors: {},
        viewData: null,
        viewLoading: false,
        commentThread: false,
        id: null
      };
    case WORKSHEET_DELETE_LOAD:
      return {
        ...state,
        deleted: false,
        deletedId: null,
        viewLoading: false
      };
    case WORKSHEETS_LIST_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        viewLoading: false
      };
    case WORKSHEET_EDIT_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: false
      };
    case WORKSHEET_VIEW_LOAD:
      return {
        ...state,
        loading: true,
        failed: false,
        message: null,
        formErrors: {},
        viewLoading: true
      };
    case WORKSHEETS_FORM_LOAD:
      return {
        ...state,
        loading: false,
        failed: false,
        message: null,
        viewLoading: false
      };
    case WORKSHEETS_FORM_FAILED:
    case WORKSHEET_EDIT_FAILED:
    case WORKSHEET_DELETE_FAILED:
    case WORKSHEETS_LIST_FAILED:
    case WORKSHEET_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: false,
        failed: true,
        message: action.message,
        formErrors: action.formErrors || {},
        deleted: true,
        deletedId: null,
        viewLoading: false
      };
    case WORKSHEETS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        failed: false,
        data: action.data,
        total: action.total,
        limit: action.limit,
        page: action.page,
        order: action.order,
        orderMode: action.orderMode
      };
    case WORKSHEET_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failed: false,
        viewData: action.viewData,
        commentThread: action.commentThread || false,
        viewLoading: false
      };
    case WORKSHEETS_FORM_SUCCESS:
      return {
        ...state,
        fields: action.fields,
        loading: false,
        success: true,
        failed: false,
        viewLoading: false
      };
    case WORKSHEET_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletedId: action.id
      };
    case WORKSHEET_EDIT_SUCCESS:
      var newData = Object.assign({}, action.viewData);
      delete newData.collectors;
      var viewData = Object.assign({}, state.viewData, newData);
      return {
        ...state,
        loading: false,
        saved: true,
        failed: false,
        message: null,
        formErrors: {},
        id: viewData.id,
        viewData: viewData,
        viewLoading: false
      };
    case WORKSHEET_CHANGE:
      return {
        ...state,
        viewData: Object.assign({}, state.viewData, action.viewData)
      };
    case WORKSHEETS_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        viewLoading: false
      };
    default:
      return state;
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: WORKSHEETS_RESET
  });
};

export const changeRow = row => dispatch => {
  dispatch({
    type: WORKSHEETS_CHANGE_ROW,
    row
  });
};

export const resetForm = () => dispatch => {
  dispatch({
    type: WORKSHEETS_RESET_FORM
  });
};

export const updateWorksheets = (model, id, data) => dispatch => {
  dispatch({
    type: WORKSHEET_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .put("/v1/worksheets/" + id, obj)
    .then(response => {
      dispatch({
        type: WORKSHEET_EDIT_SUCCESS,
        viewData: response.data.data
      });
      return response.data;
    })
    .catch(error => {
      dispatch({
        type: WORKSHEET_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      console.error(error);
      throw error.response;
    });
};

export const changeWorksheets = data => dispatch => {
  dispatch({
    type: WORKSHEET_CHANGE,
    viewData: data
  });
};

export const viewWorksheets = id => dispatch => {
  dispatch({
    type: WORKSHEET_VIEW_LOAD
  });
  return axios
    .get("/v1/worksheets/" + id)
    .then(response =>
      dispatch({
        type: WORKSHEET_VIEW_SUCCESS,
        viewData: get(response, "data.data"),
        commentThread: get(response, "data.commentThread", false)
      })
    )
    .catch(error => {
      dispatch({
        type: WORKSHEET_VIEW_FAILED,
        message: get(error, "response.data.message", null)
      });
    });
};

export const createWorksheets = (model, data) => dispatch => {
  dispatch({
    type: WORKSHEET_EDIT_LOAD
  });

  let obj = {};
  obj[model] = data;

  return axios
    .post("/v1/worksheets", obj)
    .then(response => {
      dispatch({
        type: WORKSHEET_EDIT_SUCCESS,
        //id: get(response, "data.data.id", null)
        viewData: response.data.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: WORKSHEET_EDIT_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
      console.error(error);
      throw error.response;
    });
};

export const worksheetDelete = id => dispatch => {
  dispatch({
    type: WORKSHEET_DELETE_LOAD
  });
  return axios
    .delete("/v1/worksheets/" + id)
    .then(response =>
      dispatch({
        type: WORKSHEET_DELETE_SUCCESS,
        id
      })
    )
    .catch(error =>
      dispatch({
        type: WORKSHEET_DELETE_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: WORKSHEETS_SET_LOADING,
    loading
  });
};

export const worksheetsList = (
  page,
  keyword,
  sort,
  filters,
  perPage,
  section
) => dispatch => {
  dispatch({
    type: WORKSHEETS_LIST_LOAD
  });

  let params = {
    page,
    keyword,
    sort,
    "per-page": perPage,
    WorksheetSearch: get(filters, "customFilters"),
    section
  };

  params[filters.model] = filters.filters;
  return axios
    .get(
      "/v1/worksheets",
      {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, { encode: false });
        }
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
    .then(response => {
      let sort = get(response, "data.sort", null),
        mode = "ASC";
      if (sort && sort.indexOf("-") !== -1) {
        mode = "DESC";
        sort = sort.substr(1);
      }
      return dispatch({
        type: WORKSHEETS_LIST_SUCCESS,
        data: get(response, "data.data", null),
        page: get(response, "data.page", 0),
        limit: get(response, "data.per-page", 0),
        total: get(response, "data.total", 0),
        order: sort,
        orderMode: mode
      });
    })
    .catch(error =>
      dispatch({
        type: WORKSHEETS_LIST_FAILED,
        message: get(error, "response.data.message", null)
      })
    );
};

export const worksheetsStatistic = (date) => dispatch => {
  dispatch({
    type: WORKSHEETS_STATISTIC_LOAD
  });

  return axios.get(
      "/v1/worksheets/statistic",
      {
        params: {
          date: date
        }
      }
  )
  .then(response => {
    return dispatch({
      type: WORKSHEETS_STATISTIC_SUCCESS,
      data: get(response, "data.data", null),
      page: get(response, "data.page", 0),
      limit: get(response, "data.per-page", 0),
      total: get(response, "data.total", 0),
    });
  })
  .catch(error => dispatch({
    type: WORKSHEETS_STATISTIC_FAILED,
    message: get(error, "response.data.message", null)
  }));
};

export const worksheetsSgrid = (date) => dispatch => {
  dispatch({
    type: WORKSHEETS_STATISTIC_LOAD
  });

  return axios.get("/v1/worksheets/options?component=sgrid")
  .then(response => {
    return dispatch({
      type: WORKSHEETS_STATISTIC_SUCCESS,
      data: get(response, "data.data", null),
    });
  })
  .catch(error => dispatch({
    type: WORKSHEETS_STATISTIC_FAILED,
    message: get(error, "response.data.message", null)
  }));
};

//Redux nélkül
export const apnLoad = apn => {
  return axios
    .get("/v1/worksheets/apn-load/" + apn)
    .then(response => response.data)
    .catch(error => {
      throw error.response;
    });
};

export const restoreWorksheet = id => {
  return axios
    .post(`/v1/worksheets/restore/${id}`)
    .then(response => get(response, "data", null))
    .catch(error => {
      throw error.response;
    });
};
export const setPaidStatus = (id, paid_status) => {
  return axios
    .post(`/v1/worksheets/paid-status/${id}`, {
      PaidStatusForm: { paid_status }
    })
    .then(response => get(response, "data", null))
    .catch(error => {
      throw error.response;
    });
};
export const billingWorksheet = (id, data) => {
  return axios
    .post(`/v1/worksheets/invoice/${id}`, { InvoiceForm: data })
    .then(response => get(response, "data", null))
    .catch(error => {
      throw error.response;
    });
};

export const setColor = (id, color) => {
  return axios
    .post(`/v1/worksheets/color/${id}`, { ColorForm: { color } })
    .then(response => get(response, "data", null))
    .catch(error => {
      throw error.response;
    });
};

export const exportUnpaid = () => {
  return axios
    .get("/v1/worksheets/export-unpaid/")
    .then(response => get(response, "data", null))
    .catch(error => {
      throw error.response;
    });
};

export const removeAnyk = id => {
  return axios
    .delete(`/v1/worksheets/remove-anyk/${id}`)
    .then(response => get(response, "data", null))
    .catch(error => {
      throw error.response;
    });
};
