import React, { Component, Fragment } from "react";

import {
  Badge,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import DatePicker from "Components/New/Input/DatePicker/DatePicker";
import Select from "Components/New/Input/Select/Select";
import TextField from "Components/New/Input/TextField/TextField";

import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import AddIcon from "@material-ui/icons/AddCircle";
import TrashIcon from "@material-ui/icons/Delete";
import localize from "helpers/localize";
const emptyRow = () => {
  return {
    distributor_id: null,
    value: "",
    valid_until: null,
    ap_filter: ""
  };
};

class TokenCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [emptyRow()],
      errors: null
    };
  }

  resetRows = () => {
    this.setState({ rows: [emptyRow()] });
  };

  addRow = () => {
    let rows = this.state.rows.slice();
    rows.push(emptyRow());
    //console.log(rows);
    this.setState({ rows });
  };

  removeRow = index => {
    let rows = this.state.rows.slice();
    rows.splice(index, 1);
    this.setState({ rows });
  };

  setRowValue = (index, name, value) => {
    let rows = this.state.rows.slice();
    rows[index][name] = value;
    this.setState({ rows });
  };

  validateRange = (start, end) => {
    //lol
  };

  getCount = row => {
    const params = this.getValueParams(row);

    if (!params) {
      return 0;
    }

    return params.count;
  };
  getValueParams = row => {
    if (!row || !row.value) {
      return null;
    }
    let start = null;
    let end = null;
    let error = null;
    let count = 0;

    if (row.value.indexOf("-") >= 0) {
      const [_start, _end] = row.value.split("-");
      start = parseInt(_start.trim());
      end = parseInt(_end.trim());
      count = end - start + 1;
      if (end === 0) {
        error = true;
      }
    } else if (row.value.indexOf(",") >= 0) {
      const [_start, _count] = row.value.split(",");
      start = parseInt(_start.trim());
      count = parseInt(_count.trim());
      end = start + count - 1;
      if (count === 0) {
        error = true;
      }
    } else {
      return null;
    }
    if (isNaN(count)) {
      count = 0;
      error = true;
    }
    return { start, end, count, error };
  };

  transformRow = row => {
    const params = this.getValueParams(row);

    if (!params) {
      return [row];
    }
    let tokens = [];
    for (let value = params.start; value <= params.end; value++) {
      tokens.push(Object.assign({}, row, { value }));
    }

    return tokens;
  };

  transformRows = () => {
    const { rows } = this.state;

    let tokens = [];
    rows.forEach(row => {
      tokens = tokens.concat(this.transformRow(row));
    });
    return tokens;
  };

  validateRow = row => {
    //console.log(row);
    const params = this.getValueParams(row);
    let error = {};
    let hasError = false;

    if (!row.distributor_id) {
      error.distributor_id = localize("tokens", "Nincs viszonteladó");
      hasError = true;
    }

    if (!row.valid_until) {
      error.valid_until = localize("tokens", "Nincs érvényesség dátuma");
      hasError = true;
    }

    if (!row.value) {
      error.value = localize("tokens", "Nincs tokenszám");
      hasError = true;
    } else if (params) {
      if (params.error) {
        error.value = localize("tokens", "Hibás tartomány");
        hasError = true;
      } else if (params.count <= 0) {
        error.value = localize("tokens", "Negatív tartomány");
        hasError = true;
      }
    }

    /*if (!row.ap_filter) {
      error.ap_filter = "Nincs AP szűrő";
      hasError = true;
    }*/

    if (hasError) {
      return error;
    }
  };

  getErrors = () => {
    const { rows } = this.state;

    let errors = {};
    let hasError = false;
    rows.forEach((row, index) => {
      //console.log(index);
      const error = this.validateRow(row);
      if (error) {
        errors[index] = error;
        hasError = true;
      }
    });

    if (hasError) {
      this.setState({ errors });
    } else {
      this.setState({ errors: null });
    }

    return hasError ? errors : null;
  };

  needConfirm = () => {
    const { rows } = this.state;

    let hasError = false;
    rows.forEach(row => {
      const params = this.getValueParams(row);
      if (params && params.count > 50) {
        hasError = true;
      }
    });

    return hasError;
  };

  render() {
    const { rows, errors } = this.state;
    const getError = (index, key) =>
      errors && errors[index] && errors[index][key]
        ? errors[index][key]
        : undefined;

    let currentIndex = 0;
    return (
      <Fragment>
        <Typography
          variant="caption"
          color="textSecondary"
          style={{ marginBottom: 16 }}
        >
          * - {localize("tokens", "A mező elfogad token tartományt is.")} 1.: [
          {localize("tokens", "kezdő token")}]-[
          {localize("tokens", "befejezőtoken")}] {localize("tokens", "pl.")}:{" "}
          <b style={{ marginRight: 16 }}>170510-170550</b> 2.: [
          {localize("tokens", "kezdő token")}],[
          {localize("tokens", "darabszám")}] {localize("tokens", "pl.")}:
          <b>170510,40</b>
        </Typography>
        <Table padding="none">
          <TableHead>
            <TableRow style={{ height: "unset" }}>
              <TableCell />
              <TableCell style={{ paddingLeft: 4 }}>
                {localize("tokens", "Viszonteladó")}
              </TableCell>
              <TableCell style={{ paddingLeft: 4 }}>
                {localize("tokens", "Token szám/tartomány")}*
              </TableCell>
              <TableCell style={{ paddingLeft: 4 }}>
                {localize("tokens", "Érvényes")}
              </TableCell>
              <TableCell style={{ paddingLeft: 4 }}>
                {localize("tokens", "AP szűrő")}
              </TableCell>
              <TableCell style={{ paddingLeft: 4 }}>
                <Tooltip title={localize("tokens", "Új hozzáadása")}>
                  <IconButton
                    style={{ width: 24, height: 24, padding: 0 }}
                    onClick={this.addRow}
                    color="primary"
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              const count = this.getCount(row);
              currentIndex += count > 0 ? count : 1;
              return (
                <TableRow key={index}>
                  <TableCell
                    style={{ width: 70, textAlign: "left", fontSize: 15 }}
                  >
                    <Badge
                      //style={{ fontSize: 10 }}
                      color={
                        count > 50
                          ? "secondary"
                          : count <= 0
                          ? "error"
                          : "primary"
                      }
                      badgeContent={count}
                    >
                      {count > 0
                        ? `${currentIndex - count + 1}-${currentIndex}`
                        : currentIndex}
                      .
                    </Badge>
                  </TableCell>
                  <TableCell style={{ width: 350, overflow: "visible" }}>
                    <Select
                      type="autocomplete"
                      value={row.distributor_id}
                      url="distributors/autocomplete?attribute=company_name"
                      fullWidth
                      onChange={(obj, id) =>
                        this.setRowValue(index, "distributor_id", id)
                      }
                      error={getError(index, "distributor_id")}
                    />
                  </TableCell>
                  <TableCell style={{ paddingLeft: 4 }}>
                    <TextField
                      value={row.value}
                      fullWidth
                      error={getError(index, "value")}
                      onChanged2={evt =>
                        this.setRowValue(index, "value", evt.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell style={{ paddingLeft: 4 }}>
                    <DatePicker
                      value={row.valid_until}
                      valueFormat="Y.MM.DD"
                      fullWidth
                      inline
                      error={getError(index, "valid_until")}
                      onChange={date =>
                        this.setRowValue(index, "valid_until", date)
                      }
                    />
                  </TableCell>
                  <TableCell style={{ paddingLeft: 4, width: 200 }}>
                    {/*<Tags
                    value={row.ap_filter}
                    fullWidth
                    valueKey="value"
                    labelKey="label"
                    onChange={tags =>
                      this.setRowValue(index, "ap_filter", tags)
                    }
                  />*/}
                    <Select
                      type="autocomplete"
                      value={row.ap_filter}
                      url="tokens/autocomplete?attribute=company_name"
                      fullWidth
                      onChange={(obj, id) =>
                        this.setRowValue(index, "ap_filter", id)
                      }
                    />
                  </TableCell>
                  <TableCell style={{ paddingLeft: 4 }}>
                    {rows.length > 1 && (
                      <Tooltip title="Sor törlése">
                        <IconButton
                          style={{ width: 24, height: 24, padding: 0 }}
                          onClick={() => this.removeRow(index)}
                          color="secondary"
                        >
                          <TrashIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

TokenCreate.propTypes = {};

export default TokenCreate;
