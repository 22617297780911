import React, { PureComponent, Fragment } from "react";
import { Helmet } from "react-helmet";

import List from "Components/List/List";
import ContainerHeader from "Components/ContainerHeader";
import Button from "@material-ui/core/Button";
import FuncPermission from "Containers/Permissions/FuncPermission";
import Modal from "Components/Modal/Modal";
import Typography from "@material-ui/core/Typography";
import qs from "qs";
import localize from "helpers/localize";
class EventsList extends PureComponent {
  _isIE = window.navigator.msSaveOrOpenBlob || false;

  _downloadExportFileUrl;

  _filters = {};

  state = {
    isDownloadFileWarningModalOpen: false
  };

  getListHeader = () => {
    return this.props.getListHeader("/events");
  };

  getList = (page, keyword, sort, filters, perPage) => {
    this._filters = filters;

    return this.props.getList(page, keyword, sort, filters, perPage);
  };

  export = () => {
    this.props.checkIsExportAvailableByFilters(this._filters).then(response => {
      if (response.success) {
        const token = localStorage
          .getItem("access_token")
          .replace("Bearer ", "");
        const urlParams = qs.stringify(
          {
            accessToken: token,
            [this._filters.model]: this._filters.filters
          },
          { encode: false }
        );
        const downloadUrl = `${
          process.env.REACT_APP_HOST
        }/v1/events/export?${urlParams}`;
        let link;

        if (this._isIE) {
          this._downloadExportFileUrl = downloadUrl;
          this.setState({ isDownloadFileWarningModalOpen: true });
        } else {
          link = document.createElement("a");
          link.href = downloadUrl;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        this._downloadExportFileUrl = null;
        this.setState({ isDownloadFileWarningModalOpen: true });
      }
    });
  };

  handleDownloadButtonClick = () => {
    this.downloadExportElement.click();
  };

  onExportConfirmAccept = () => {
    const isNeedsPopupForDownload = this._isIE && this._downloadExportFileUrl;

    if (isNeedsPopupForDownload) {
      this.handleDownloadButtonClick();
    } else {
      this.handleCloseModal();
    }
  };

  handleCloseModal = e => {
    this.setState({ isDownloadFileWarningModalOpen: false });
  };

  customButtons = () => {
    const isNeedsPopupForDownload = this._isIE && this._downloadExportFileUrl;
    const { isDownloadFileWarningModalOpen } = this.state;
    let exportFileModalBody;
    let exportFileModalHeader;

    if (isNeedsPopupForDownload) {
      exportFileModalBody = (
        <a
          id="downloadEventsExport"
          hidden={true}
          href={this._downloadExportFileUrl}
          target="_blank"
          ref={a => (this.downloadExportElement = a)}
        >
          {localize("events", "Letöltés")}
        </a>
      );
      exportFileModalHeader = (
        <Fragment>{localize("events", "File letöltése.")}</Fragment>
      );
    } else {
      exportFileModalHeader = (
        <Fragment>
          {localize("events", "A szűrési feltételeken kérem változtasson.")}
        </Fragment>
      );
      exportFileModalBody = (
        <Fragment>
          {localize(
            "events",
            "A találtati lista a méretét tekintve nagy, kérem szűkítsen a szűrők segítségével."
          )}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <FuncPermission module="events" permission="events.export">
          <div className="col-auto mr-auto">
            <Button variant="contained" component="span" onClick={this.export}>
              {localize("events", "Exportálás")}
            </Button>
            <Modal
              title={"Exportálás"}
              open={isDownloadFileWarningModalOpen}
              onAccept={this.onExportConfirmAccept}
              onCancel={this.handleCloseModal}
              disableIcon
              keepMounted={false}
              yesText={isNeedsPopupForDownload ? "Letöltés" : "Ok"}
              onClose={() =>
                this.setState({ isDownloadFileWarningModalOpen: false })
              }
            >
              <div>
                <Typography>{exportFileModalHeader}</Typography>
                <Typography>{exportFileModalBody}</Typography>
              </div>
            </Modal>
          </div>
        </FuncPermission>
      </Fragment>
    );
  };

  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("events", "Események")}</title>
        </Helmet>
        <ContainerHeader title={localize("events", "Események")} />

        <List
          {...this.props}
          getListHeader={this.getListHeader.bind(this)}
          customButtons={this.customButtons.bind(this)}
          getList={this.getList.bind(this)}
          disabledCreate={true}
          disabledTools={true}
        />
      </div>
    );
  }
}

export default EventsList;
