import React from "react";
import PropTypes from "prop-types";
import PageMenuBase from "./PageMenuBase";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Paper } from "@material-ui/core";
import { withRouter, Link, Route, Switch } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const TabContainer = props => (
  <Paper style={{ padding: 12 }}>{props.children}</Paper>
);

TabContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.element
  ])
};

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    flex: 1
  },
  wrapper: {
    "&>span": {
      //padding: 0
      //minHeight: 48
    }
  }
});

class PageTabMenu extends PageMenuBase {
  render() {
    const {
      classes,
      basePath,
      match,
      color,
      showContainer,
      LeftToolBar,
      defaultPath
    } = this.props;

    //TODO: Ez át lehetne rakni a PageMenu-be
    const pathname = match.params.menu
      ? `${basePath}/${match.params.menu}`
      : `${basePath}${defaultPath}`;

    const childrenProps = this.getChildrenProps();
    const childrenVisible = this.getChildrenVisible();

    return (
      <div style={{ position: "relative" }}>
        <AppBar
          position="static"
          color={color}
          style={LeftToolBar ? { flexDirection: "row" } : {}}
        >
          {LeftToolBar}
          <Tabs
            variant="scrollable"
            scrollButtons="on"
            value={pathname}
            classes={LeftToolBar ? { root: classes.root } : {}}
          >
            {childrenProps.map((item, index) => {
              let path = basePath + item.path;
              return (
                <Tab
                  key={index}
                  value={path}
                  component={Link}
                  to={path + (match.params.id ? `/${match.params.id}` : "")}
                  label={item.title}
                  /*classes={{
                    textColorPrimary: {
                      padding: 0
                    }
                  }}*/
                  style={{
                    color: "inherit",
                    textDecoration: "none"
                  }}
                  icon={
                    item.icon ? (
                      <item.icon style={{ marginTop: 4 }} />
                    ) : (
                      undefined
                    )
                  }
                />
              );
            })}
          </Tabs>
        </AppBar>
        <Switch>
          {childrenProps.map((item, index) => {
            let path = basePath + item.path;
            return (
              <Route
                key={index}
                path={path + "/:menu?/:id?"}
                render={
                  (/*props*/) =>
                    showContainer ? (
                      <TabContainer padding={item.padding}>
                        {childrenVisible[index]}
                      </TabContainer>
                    ) : (
                      <div style={{ paddingTop: 8 }}>
                        {childrenVisible[index]}
                      </div>
                    )
                }
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}
PageTabMenu.defaultProps = {
  color: "default",
  showContainer: true
};
PageTabMenu.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  children: PropTypes.array,
  basePath: PropTypes.string,
  color: PropTypes.string,
  showContainer: PropTypes.bool,
  LeftToolBar: PropTypes.any
};

export default withRouter(withStyles(styles)(PageTabMenu));
