import React from "react";
import PropTypes from "prop-types";
import PageTabMenu from "./PageTabMenu";
import PageVerticalIconMenu from "./PageVerticalIconMenu";
import PageSubMenu from "./PageSubMenu";

const PageMenu = props => {
  switch (props.type) {
    case "verticalIcon":
      return <PageVerticalIconMenu {...props} />;
    case "sub":
      return <PageSubMenu {...props} />;
    case "tab":
    default:
      return <PageTabMenu {...props} />;
  }
};

PageMenu.defaultProps = {
  type: "tab"
};

PageMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.element
  ]),
  basePath: PropTypes.string,
  type: PropTypes.string
};

export default PageMenu;
