import React, { Fragment } from "react";
import PropTypes from "prop-types";

/**
 * Egyszerű wrapper elemek elrejtésére
 *
 * @param {Object} props react component properties.
 *
 * @return element react childrens or null
 */
const Hidden = ({ visible, children }) => {
  return visible ? <Fragment>{children}</Fragment> : null;
};

Hidden.propTypes = {
  visible: PropTypes.bool, //Látható-e
  children: PropTypes.any // Gyerek elemek
};

export default Hidden;
