import React, {Component, Fragment} from 'react';

import Edit from "../Edit/Edit";
import SettingsForm from "./SettingsForm";
import {bindActionCreators} from "redux";
import * as optionsAction from "../../redux/modules/options";
import connect from "react-redux/es/connect/connect";


class Settings extends Component {

  constructor(props) {
    super(props);
    this._form = React.createRef();
  }

  submit = () => {
    const instance = this._form.current.getWrappedInstance();
    if(instance) {
      instance.submit()
    }
  }

    onSubmitSettings = (model, formData) => {
        return this.props.saveGrid({visibleColumns : Object.keys(formData).filter(key => formData[key]) || []}).then(res => {
          if(this.props.afterSave) {
            this.props.afterSave(formData)
          }
        });
    };

    createSavedRedirect = () => {
        this.props.onClose();
    };

    render() {
        return <Fragment>
            <Edit
                handleGetFormInfo={() => { return new Promise(function(resolve) {
                    resolve({});
                });}}
                createSavedRedirect={this.createSavedRedirect.bind(this)}
                model={''}
                commentThread={''}
                forceCreateOnly={true}
                create={this.onSubmitSettings.bind(this)}
                initalize={() => {}}
                isViewPage={false}
                baseFields={true}
                disableCancel={true}
                {...this.props}
            >
                <SettingsForm {...this.props} isViewPage={false} ref={this._form}/>
            </Edit>
        </Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.options.grid_save_loading,
        saved: state.options.grid_save_saved,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            saveGrid: optionsAction.saveGrid
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps, null, {withRef: true})(Settings);