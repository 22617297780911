import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import OutgoingSmsList from "./views/OutgoingSmsList";
import * as outgoingSmsActions from "../../../../redux/modules/outgoing-sms";
import * as optionsActions from "../../../../redux/modules/options";

const mapStateToProps = state => {
  return {
    loading: state.outgoingSms.loading,
    success: state.outgoingSms.success,
    message: state.outgoingSms.message,
    data: state.outgoingSms.data,
    total: state.outgoingSms.total,
    limit: state.outgoingSms.limit,
    page: state.outgoingSms.page,
    order: state.outgoingSms.order,
    orderMode: state.outgoingSms.orderMode,
    deleted: state.outgoingSms.deleted,
    deletedId: state.outgoingSms.deletedId,
    token: state.outgoingSms.token,

    headerLoaded: state.options.headerLoaded,
    translate: state.options.translate,
    searchModel: state.options.searchModel,
    columns: state.options.columns,
    columnsOrder: state.options.columnsOrder
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getListHeader: optionsActions.getGridInfo,
      optionsReset: optionsActions.reset,

      getList: outgoingSmsActions.OutgoingSmsList,
      createOutgoingSms: outgoingSmsActions.createOutgoingSms,
      delete: outgoingSmsActions.OutgoingSmsDelete,
      setLoading: outgoingSmsActions.setLoading,
      reset: outgoingSmsActions.reset
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutgoingSmsList);
