import React, { Fragment } from "react";


import WorksheetForm from "./WorksheetForm";
import EditPage from "../../../../../Components/Extends/EditPage";
import Edit from "../../../../../Components/Edit/Edit";
import Navbar from "../../Navbar";
import localize from "helpers/localize";

class EditWorksheet extends EditPage {
  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("worksheets", "Munkalap megtekintése");
    }
    return this.props.match.params.id
      ? localize("worksheets", "Munkalap szerkesztése")
      : localize("worksheets", "Munkalap létrehozása");
  };

  afterSave = res => {
    /*const isUpdateMode = res.type && res.type === "WORKSHEET_EDIT_SUCCESS";

    if (!isUpdateMode) {
      this.history.push(`/worksheets/edit/${res.data.id}`);
    }*/
  };

  render() {
    return (
      <Fragment>
        {/*<Navbar />*/}
        <Edit
          getPagetitle={this.getPageTitle}
          isViewPage={this.isViewPage()}
          afterSave={this.afterSave}
          {...this.props}
          alwaysUseRaw
        >
          <WorksheetForm {...this.props} isViewPage={this.isViewPage()} />
        </Edit>
      </Fragment>
    );
  }
}

export default EditWorksheet;
