const fcmHelper = {
  subscribes: {},
  checkServiceWorker: () => {
    if (!("serviceWorker" in navigator) && !("PushManager" in window)) {
      alert(
        "A push üzenetekhez újabb böngésző kell. Chrome Desktop and Mobile (version 50+), Firefox Desktop and Mobile (version 44+), Opera on Mobile (version 37+)"
      );
      return false;
    }
    return true;
  },
  onMessage: message => {
    const { category, payload } = message.data[
      "firebase-messaging-msg-data"
    ].data;
    const data = JSON.parse(payload);
    const categories = JSON.parse(category);
    if (Array.isArray(categories)) {
      categories.forEach(key => {
        fcmHelper.subscribes[key] && fcmHelper.subscribes[key](data[key]);
      });
    } else {
      fcmHelper.subscribes[categories](data[categories]);
    }
  },

  subscribe: (category, callBack) => {
    fcmHelper.subscribes[category] = callBack;
  },

  unSubscribe: category => {
    if (fcmHelper.subscribes[category]) {
      delete fcmHelper.subscribes[category];
    }
  }
};

navigator.serviceWorker.addEventListener("message", fcmHelper.onMessage);

export default fcmHelper;
