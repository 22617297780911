import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DataForm from "Components/New/DataForm/DataFormNew";
import CartWrapper from "./CartWrapper";
import { Grid } from "@material-ui/core";
import CheckOutFrom from "./CheckOutForm";
import { BASE_PATH_CART_ORDER } from "./../shopPath";
import { get } from "lodash";
import localize from "helpers/localize";

const styles = theme => ({});
let fetched = false;

class CheckOut extends React.Component {
  componentDidMount() {
    const {
      fetchOrder,
      fetchOrderOptions,
      fetchOrderWithOptions,
      order
    } = this.props;
    console.log(order.item.id);
    if (!fetched || order.item.id) {
      /*fetchOrderOptions().then(response => {
        console.log("fetchOrderOptions", response);
        fetchOrder();
        fetched = true;
      });*/
      fetchOrderWithOptions().then(() => {
        fetched = true;
      });
    }
  }

  render() {
    const {
      theme,
      classes,
      items,
      total,
      totalPrice,
      order,
      history: {
        location: { pathname }
      }
    } = this.props;
    const colorProps = {};
    const getOption = name =>
      get(order, `options.order.baseFields.${name}.data`, {});
    const getOptionDefault = name => {
      const defaultValue = get(
        order,
        `options.order.baseFields.${name}.default`,
        null
      );
      const defaultLabel = get(
        order,
        `options.order.baseFields.${name}.data.${defaultValue}`,
        null
      );
      return {
        value: parseInt(defaultValue),
        label: defaultLabel
      };
    };
    const payment_methods = Object.assign({}, getOption("payment_method"));
    const shipping_methods = Object.assign({}, getOption("shipping_method"));
    const shipping_fee = getOptionDefault("shipping_fee");
    shipping_methods.COURIER += ` ${shipping_fee.label}`;

    //Futár esetén nincsen készpénz
    if (order.item.shipping_method === "COURIER") {
      delete payment_methods.CASH;
    }

    if (theme.header.iconColor) {
      colorProps.color = theme.header.iconColor;
    }
    //console.log(pathname);
    return (
      <Fragment>
        <Grid container spacing={16} justify="center">
          <Grid item xs={12} md={10}>
            <DataForm
              editItem={order.item}
              //title="Termék szerkesztése"
              //titleNew="Új termék felvitele"
              disableHeader
              disableButtons
              errors={order.errors}
              onChangeErrors={this.props.changeErrors}
              onChange={(key, value) => {
                this.props.changeOrder({
                  ...order.item,
                  [key]: value
                });
              }}
              onItemChange={item => {
                //console.log("onItemChange", item);
                this.props.changeOrder({
                  ...order.item,
                  ...item
                });
              }}
              onValidated={data => {
                //console.log("onValidated", data);
                this.props.changeStep(2);
                this.props.history.push(BASE_PATH_CART_ORDER);
              }}
              loading={false}
              options={{
                form: {
                  model: "ShopOrder",
                  fieldsOrder: [],
                  fields: {
                    tax_number: {
                      name: localize("shop", "Adószám"),
                      mask: "tax_number",
                      validators: ["required", "tax_number"]
                    },
                    name: {
                      name: localize("shop", "Név"),
                      validators: ["required"]
                    },
                    phone: {
                      name: localize("shop", "Mobil"),
                      mask: "mobile_phone",
                      validators: ["required", "mobile_phone"]
                    },
                    email: {
                      name: localize("shop", "Email"),
                      validators: ["required", "email"]
                    },
                    zip: {
                      name: localize("shop", "Irányítószám"),
                      validators: ["required"]
                    },
                    city: {
                      name: localize("shop", "Város"),
                      validators: ["required"]
                    },
                    street: {
                      name: localize("shop", "Közterület"),
                      validators: ["required"]
                    },
                    street_suffix: {
                      name: localize("shop", "Közterület jellege"),
                      validators: ["required"]
                    },
                    street_details: {
                      name: localize("shop", "Házszám"),
                      validators: ["required"]
                    },
                    comment: {
                      name: localize("shop", "Megjegyzés"),
                      multiline: true,
                      rows: 5
                    },
                    shipping_method: {
                      name: localize("shop", "Szállítási mód"),
                      validators: ["required"],
                      type: "dropdown",
                      data: shipping_methods
                    },
                    payment_method: {
                      name: localize("shop", "Fizetés mód"),
                      validators: ["required"],
                      type: "dropdown",
                      data: payment_methods
                    },
                    delivery_phone: {
                      name: localize("shop", "Mobil"),
                      mask: "mobile_phone",
                      validators: ["required", "mobile_phone"]
                    },
                    delivery_zip: {
                      name: localize("shop", "Irányítószám"),
                      validators: ["required"]
                    },
                    delivery_city: {
                      name: localize("shop", "Város"),
                      validators: ["required"]
                    },
                    delivery_street: {
                      name: localize("shop", "Közterület"),
                      validators: ["required"]
                    },
                    delivery_street_suffix: {
                      name: localize("shop", "Közterület jellege"),
                      validators: ["required"]
                    },
                    delivery_street_details: {
                      name: localize("shop", "Házszám"),
                      validators: ["required"]
                    }
                  }
                }
              }}
              //fieldsOrder: ["name"],
              component={CheckOutFrom}
              autoRenderFields={false}
              history={this.props.history}
              componentProps={{ changeStep: this.props.changeStep }}
            />
            {/*Checkout {formatHelper.currency(totalPrice)}*/}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

CheckOut.defaultProps = {};

CheckOut.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  items: PropTypes.array,
  total: PropTypes.number
};

export default withStyles(styles, { withTheme: true })(CartWrapper(CheckOut));
