/* eslint-disable no-console */
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Link,
  Toolbar
} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClearIcon from "@material-ui/icons/Clear";
import MailIcon from "@material-ui/icons/Mail";
import EditForm from "Components/Forms/Builder/EditForm";
import TagInput from "Components/Forms/Fields/AsyncTagInput";
import CheckboxField from "Components/Forms/Fields/Checkbox";
import DateField from "Components/Forms/Fields/Date";
import InputField from "Components/Forms/Fields/InputMaskedBlur";
import RadioField from "Components/Forms/Fields/Radio";
import SelectField from "Components/Forms/Fields/Select";
import TableField from "Components/Forms/Fields/TableField";
import Validations from "Components/Forms/Validations";
import Modal from "Components/Modal/Modal";
import ModalConfirm from "Components/Modal/ModalConfirm";
import { globalMessage } from "Components/New/GlobalMessaging/GlobalMessaging";
import Image from "Components/New/Image";
import ServiceOrders from "Components/ServiceOrders/ServiceOrders";
import UploadButton from "Components/UploadButton";
import EditClient from "Containers/Pages/Clients/EditClient";
import WorksheetDetails from "Containers/Pages/Signing/WorksheetDetails";
import worksheet_colors from "Containers/Pages/Worksheets/Worksheets/colors";
import fieldHelper from "helpers/fieldHelper";
import localize from "helpers/localize";
import restHelper from "helpers/restHelper";
import { get } from "lodash";
import MobileDetect from "mobile-detect";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import SignaturePad from "react-signature-canvas";
import { Field, FieldArray, getFormValues, reduxForm } from "redux-form";
import { apnLoad } from "redux/modules/worksheets";
import CollectorItem from "./CollectorItem";
import FeeItem from "./FeeItem";
import FileItem from "./FileItem";
import ProductItem from "./ProductItem";

let md = new MobileDetect(window.navigator.userAgent);
const STATUS_FILLING = "FILLING";
const STATUS_NEW = "NEW";
const STATUS_IN_PROGRESS = "IN_PROGRESS";
const STATUS_WAITING = "WAITING";
const STATUS_DONE = "DONE";
const STATUS_CLOSED = "CLOSED";

/**
 * Fake file objektumot csinál a FileUploadButton komponensnek,
 * hogy jelenjen meg a törlés gomb, ha ki vna töltve
 *
 * @param {string} id Fájl id
 */
const prepareFileFromId = id => {
  if (!id) {
    return null;
  }
  return {
    name: id,
    id
  };
};

const convertBase64ToFile = function(image, filename) {
  const byteString = atob(image.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  let newBlob = new Blob([ab], {
    type: "image/png"
  });

  return new File([newBlob], filename);
};

class WorksheetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waitingForSignature: false, //várunk az aláírásra, ilyenkor az összes mező readonly
      waitingForSignatureTakeover: false, //várunk a 2. aláírásra, ilyenkor az összes mező readonly
      selectedClient: false,
      selectedLocation: false,
      showClientEditModal: false,
      openModalConfirm: false,
      loading: false,
      clientData: {},
      //Új cuccok
      postEditingFirstSection: false, //Az első részt utólag szerkesztjük
      postEditingSecondSection: false, //A második részt utólag szerkesztjük
      signatureMode: false, //Aláíró mód, ilyenkor a nézet lástzódik
      signatureIsEmpty: true, //Nincs rajzolt aláírás
      warranty_case: false, //Garancia auto kiválasztás javítás esetén
      client_complete: true, //Betöltött doboz esetén állítüdik false-ra, ha hiányos az ügyfél. Amíg false nem lehet menteni
      justClosed: false, //Éppen lezért munkalap
      justSigned: false, //Éppen aláírt
      acceptSignature: false,
      openASZF: false,
      isClosingModal: false,
      events: [] //History
    };
    this.sigPad = {};
    this.handleApnLoad = null;
    this.handleTaxOnBlur = null;
    this.settingsForm = React.createRef();
    this.isViewPage = props.isViewPage;
    this.modal = null;
  }
  /* New part start */

  loadHistory = id => {
    if (id) {
      const context = this;
      this.historyTimeout = setTimeout(() => {
        restHelper.index(`worksheets/events/${id}`).then(response => {
          context.setState({ events: response.data });
        });
      }, 200);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.initialValues) !==
      JSON.stringify(nextProps.initialValues)
    ) {
      //Változtak az adatok
      this.loadHistory(nextProps.initialValues.id);
    }
    //Ez talán egy nézetről szerkesztésre váltás
    /*console.log(
      this.props.isViewPage,
      nextProps.isViewPage,
      this.props.history.location.pathname,
      nextProps.history.location.pathname
    );
    if (this.props.isViewPage && !nextProps.isViewPage) {
      const { initialize, initialValues } = this.props;
      console.log("NA MOST");
      setTimeout(() => {
        initialize(initialValues);
      }, 200);
    }*/
  }

  turnOnPostEditingFirstSection = () => {
    const { initialize, initialValues } = this.props;
    this.setState({ postEditingFirstSection: true });

    setTimeout(() => {
      initialize(initialValues);
    }, 200);
  };

  turnOffPostEditingFirstSection = () => {
    const { postEditingFirstSection } = this.state;
    if (!postEditingFirstSection) {
      return;
    }
    this.setState({ postEditingFirstSection: false });
  };

  turnOnPostEditingSecondSection = () => {
    const { initialize, initialValues } = this.props;
    this.setState({ postEditingSecondSection: true });
    setTimeout(() => {
      initialize(initialValues);
    }, 200);
  };

  turnOffPostEditingSecondSection = () => {
    const { postEditingSecondSection } = this.state;
    if (!postEditingSecondSection) {
      return;
    }
    this.setState({ postEditingSecondSection: false });
  };

  firstSectionIsEditable = () => {
    const {
      postEditingFirstSection,
      postEditingSecondSection,
      signatureMode
    } = this.state;
    const status = get(this.props, "initialValues.status");

    //Nézetben nem
    if (this.props.isViewPage) {
      return false;
    }

    //Aláírás módban nem
    if (signatureMode) {
      return false;
    }

    //Utólagos szerkesztés
    if (postEditingFirstSection) {
      return true;
    }
    //Utólagos második szekció szerkesztés nál nem
    if (postEditingSecondSection) {
      return false;
    }
    //Új felvitel
    if (!status) {
      return true;
    }

    //Egyébként nem
    return false;
  };

  secondSectionIsEditable = () => {
    const {
      postEditingFirstSection,
      postEditingSecondSection,
      signatureMode
    } = this.state;
    const status = get(this.props, "initialValues.status");

    //Nézetben nem
    if (this.props.isViewPage) {
      return false;
    }

    //Aláírás módban nem
    if (signatureMode) {
      return false;
    }

    //Ha a második részt szerkitik, akkor igen
    if (postEditingSecondSection) {
      return true;
    }

    //Ha az első részt szerkitik, akkor nem
    if (postEditingFirstSection) {
      return false;
    }

    //LEzárható
    if (this.isClosable()) {
      return false;
    }
    //A megfelelő státuszoknál
    return [STATUS_IN_PROGRESS, STATUS_WAITING].indexOf(status) >= 0;
  };

  /**
   * A maunkalap lezárható
   */
  isClosable = () => {
    const status = get(this.props, "initialValues.status");
    const signature_takeover = get(
      this.props,
      "initialValues.signature_takeover"
    );
    return status === STATUS_DONE && signature_takeover;
  };

  viewIsVisible = () => {
    const { isViewPage } = this.props;
    const {
      signatureMode,
      postEditingFirstSection,
      postEditingSecondSection
    } = this.state;
    const status = get(this.props, "initialValues.status");

    //Új esetén nem
    if (!status) {
      return false;
    }

    //aláírás módban igen
    if (signatureMode) {
      return true;
    }

    //Ha az első szekció szerkeszthető, akkor nem látszik
    if (this.firstSectionIsEditable()) {
      return false;
    }

    //Post editeknél nem
    if (postEditingFirstSection || postEditingSecondSection) {
      return false;
    }
    //Ha valamelyik szekció nem szerkeszthető
    if (!this.firstSectionIsEditable() || !this.secondSectionIsEditable()) {
      return true;
    }

    //View módban
    return isViewPage;
  };

  /**
   * Visszatér az éppeb aktuális hiányzó aláírással
   */
  missingSignature = () => {
    const status = get(this.props, "initialValues.status");
    const signature_order = get(this.props, "formValues.signature_order");
    const signature_takeover = get(this.props, "formValues.signature_takeover");

    if (status !== STATUS_DONE && status !== STATUS_CLOSED) {
      return signature_order ? null : "signature_order";
    }
    if (status === STATUS_DONE) {
      return signature_takeover ? null : "signature_takeover";
    }
    return null;
  };

  /* New part end */

  componentWillUnmount = () => {
    this.props.unSubscribeAdminSigning();
  };

  componentDidMount() {
    console.log("WorksheetForm.componentDidMount");
    /*if (this.props.initialValues) {
      this.loadHistory(this.props.initialValues.id);
    }*/
    //Alapértelmezett form mezők beállítása
    const defaultDeadLine = moment()
      .add(8, "days")
      .format("YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");
    this.props.initialize({
      deadline: defaultDeadLine,
      date: today
      //date_of_completion: today
    });
    this.props.initialize({
      deadline: defaultDeadLine,
      date: today
      //date_of_completion: today
    });
    if (window.shouldturnOnSignatureMode) {
      console.log("window.shouldturnOnSignatureMode detected.");
      delete window.shouldturnOnSignatureMode;
      this.turnOnSignatureMode();
    }

    //Handle auto fill from cashregister-id
    const params = new URLSearchParams(this.props.location.search);
    const apn = params.get("apn"); // bar

    if (apn) {
      console.log("autofill-apn", apn);

      this.props.changeWorksheets({ apn });
      this.handleApnOnBlur({ target: { value: apn } });
      restHelper.index("worksheets/cashregister", { apn }).then(response => {
        console.log("autofill-apn.response", response);
        this.props.changeWorksheets({
          apn,
          erc: response.data.dhp,
          serial_number: response.data.pgsn
        });
      });
    }
  }

  goToList = () => {
    clearTimeout(this.historyTimeout);
    this.props.history.push("/worksheets");
  };
  /**
   * Visszanavigál a listára
   */
  goBack = twice => {
    const context = this;
    clearTimeout(this.historyTimeout);

    if (twice) {
      this.props.history.go(-2);
    } else {
      this.props.history.goBack();
    }

    return;
    //setTimeout(() => {
    if (context.props.location.pathname.indexOf("/view") !== -1) {
      context.props.history.push(
        context.props.location.pathname.split("/view").shift()
      );
    } else if (context.props.location.pathname.indexOf("/edit") !== -1) {
      context.props.history.push(
        context.props.location.pathname.split("/edit").shift()
      );
    } else if (context.props.location.pathname.indexOf("/create") !== -1) {
      context.props.history.push(
        context.props.location.pathname.split("/create").shift()
      );
    }
    //}, 200);
  };

  submitClientForm = () => {
    const instance = this.settingsForm.current.getWrappedInstance();
    if (instance) {
      instance.submit();
    }
  };

  afterSaveClient = res => {
    console.log("afterSaveClient", res);
    this.handleCloseModal();
  };

  handleEditClient = clientData => {
    //const {dispatch, change} = this.props;
    //dispatch(change('client_id', null));
    this.setState({
      showClientEditModal: true,
      clientData: clientData
    });
  };

  onClientFound = data => {
    const { change, dispatch, formValues, initialize } = this.props;
    console.log("onClientFound", data);
    const fm = Object.assign({}, formValues);
    fm.client_id = data.client_id;
    fm.client_tax_number = data.client_tax_number;
    fm.client_city = data.client_city;
    fm.client_email = data.client_email;
    fm.client_name = data.client_name;
    fm.client_phone = data.client_phone;
    fm.client_phone2 = data.client_phone2;
    fm.client_registration_no = data.client_registration_no;
    fm.client_street = data.client_street;
    fm.client_street_details = data.client_street_details;
    fm.client_street_suffix = data.client_street_suffix;
    fm.client_street_suffix = data.client_street_suffix;
    fm.client_zip = data.client_zip;
    initialize(fm);
    /*
    dispatch(change("client_tax_number", data.client_tax_number));
    dispatch(change("client_city", data.client_city));
    dispatch(change("client_email", data.client_email));
    dispatch(change("client_name", data.client_name));
    dispatch(change("client_phone", data.client_phone));
    dispatch(change("client_phone2", data.client_phone2));
    dispatch(change("client_registration_no", data.client_registration_no));
    dispatch(change("client_street", data.client_street));
    dispatch(change("client_street_details", data.client_street_details));
    dispatch(change("client_street_suffix", data.client_street_suffix));
    dispatch(change("client_zip", data.client_zip));*/

    this.setState({ client_complete: data.complete });
  };
  onClientRemoved = () => {
    const { change, dispatch } = this.props;
    console.log("onClientRemoved");

    this.setState(
      {
        client_complete: true,
        selectedClient: false,
        selectedLocation: false
      },
      () => {
        dispatch(change("client_id", null));
        dispatch(change("client_tax_number", null));
        dispatch(change("client_city", null));
        dispatch(change("client_email", null));
        dispatch(change("client_name", null));
        dispatch(change("client_phone", null));
        dispatch(change("client_phone2", null));
        dispatch(change("client_registration_no", null));
        dispatch(change("client_street", null));
        dispatch(change("client_street_details", null));
        dispatch(change("client_street_suffix", null));
        dispatch(change("client_zip", null));
        this.onClientLocationRemoved();
      }
    );
  };
  onClientLocationFound = data => {
    const { change, dispatch, formValues, initialize } = this.props;
    console.log("onClientLocationFound", data);
    const fm = Object.assign({}, formValues);

    fm.client_location_id = data.client_location_id;
    fm.client_location_city = data.client_location_city;
    fm.client_location_zip = data.client_location_zip;
    fm.client_location_street = data.client_location_street;
    fm.client_location_street_suffix = data.client_location_street_suffix;
    fm.client_location_street_details = data.client_location_street_details;
    fm.client_location_name = data.client_location_name;
    fm.client_location_email = data.client_location_email;
    fm.client_location_phone = data.client_location_phone;

    initialize(fm);
    /*dispatch(change("client_location_id", data.client_location_id));
    dispatch(change("client_location_city", data.client_location_city));
    dispatch(change("client_location_street", data.client_location_street));
    dispatch(
      change(
        "client_location_street_suffix",
        data.client_location_street_suffix
      )
    );
    dispatch(
      change(
        "client_location_street_details",
        data.client_location_street_details
      )
    );
    dispatch(change("client_location_name", data.client_location_name));
    dispatch(change("client_location_email", data.client_location_email));
    dispatch(change("client_location_phone", data.client_location_phone));*/
    this.setState({ selectedLocation: data });
  };
  onClientLocationRemoved = () => {
    const { change, dispatch } = this.props;
    console.log("onClientLocationRemoved");
    this.setState({
      selectedLocation: false
    });
    dispatch(change("client_location_id", null));
    dispatch(change("client_location_city", null));
    dispatch(change("client_location_street", null));
    dispatch(change("client_location_street_suffix", null));
    dispatch(change("client_location_street_details", null));
    dispatch(change("client_location_name", null));
    dispatch(change("client_location_email", null));
    dispatch(change("client_location_phone", null));
    dispatch(change("client_location_zip", null));
  };

  handleCloseModal = () => {
    //Vissza a worksheets form opciókat
    this.props.getFormInfo("worksheets").then(response => {
      console.log("handleCloseModal.getFormInfo", response);
      if (this.handleTaxOnBlur) {
        this.handleTaxOnBlur(null, this.state.clientData.client_tax_number);
      }
      this.setState({
        showClientEditModal: false
      });
    });
  };

  turnOnSignatureMode = () => {
    this.setState({
      signatureMode: true,
      signatureIsEmpty: true,
      openModalConfirm: false
    });
    this.scrollToEnd();
  };

  turnOffSignatureMode = () => {
    this.setState({ signatureMode: false });
  };

  renderCustomButtons = () => {
    const { connected, isViewPage } = this.props;
    const {
      signatureMode,
      signatureIsEmpty,
      postEditingFirstSection,
      postEditingSecondSection,
      client_complete,
      justClosed,
      acceptSignature,
      justSigned
    } = this.state;

    const dbStatus = get(this.props, "formValues.status");
    const status = get(this.props, "initialValues.status");

    //const { id } = match.params;

    const missingSignature = this.missingSignature();

    //Mégse gomb
    const cancelButtonProps = {
      hidden: false,
      children:
        justClosed || justSigned
          ? localize("basic", "Rendben")
          : localize("basic", "Mégsem")
    };
    if (signatureMode) {
      cancelButtonProps.onClick = this.turnOffSignatureMode;
    } else if (postEditingFirstSection) {
      cancelButtonProps.onClick = this.turnOffPostEditingFirstSection;
    } else if (postEditingSecondSection) {
      cancelButtonProps.onClick = this.turnOffPostEditingSecondSection;
    } else {
      cancelButtonProps.onClick = this.goBack;
    }
    const nothingIsEditable =
      !this.firstSectionIsEditable() && !this.secondSectionIsEditable();
    //Mentés gomb
    const saveButtonProps = {
      hidden: nothingIsEditable && !signatureMode && status !== STATUS_NEW
    };
    let saveAfterFirstSignature = false;
    if (connected) {
      saveButtonProps.children = localize("basic", "Mentés");
      if (
        nothingIsEditable &&
        !signatureMode &&
        !missingSignature &&
        status === STATUS_DONE
      ) {
        //Lezárásra kész munkalap
        saveButtonProps.hidden = false;
        saveButtonProps.children = localize("basic", "Lezárás");
        saveButtonProps.onClick = this.closeWorksheet;
      } else if (!missingSignature && status === STATUS_FILLING) {
        //Beküldött aláíráa van
        //saveButtonProps.disabled = signatureIsEmpty;
        //saveButtonProps.children = localize("basic", "Mentés");
        saveButtonProps.hidden = false;
        saveButtonProps.onClick = this.saveAfterFirstSignature;
        saveAfterFirstSignature = true;
      } else if (signatureMode && status === STATUS_DONE) {
        //Aláírás módban lezárás van
        saveButtonProps.disabled = signatureIsEmpty || !acceptSignature;
        saveButtonProps.children = localize("basic", "Lezárás");
        saveButtonProps.onClick = this.saveSignatureAndSave;
      } else if (signatureMode) {
        //Aláírás módban mindig aláírás mentés+mentés van
        saveButtonProps.disabled = signatureIsEmpty || !acceptSignature;
        saveButtonProps.onClick = this.saveSignatureAndSave;
      } else if (!status || dbStatus === STATUS_CLOSED) {
        //Új felvitelkor és lezáráskot, ha nincs aláírás, akkor a megerősítő modal, egyébként mentés
        saveButtonProps.onClick = missingSignature
          ? this.openModalConfirm
          : this.saveWithoutSignature; //Felvitelkor állítja a státuszt NEW-ra, és utána ment, lezáráskor csak ment
      } else if (status === STATUS_NEW && !postEditingFirstSection) {
        saveButtonProps.children = localize("basic", "Munka megkezdése");
        saveButtonProps.onClick = this.startWorksheet;
      } else {
        //Sima mentés
        saveButtonProps.onClick = this.save;
      }
    } else {
      saveButtonProps.children = localize("basic", "Mentés");

      if (
        nothingIsEditable &&
        !signatureMode &&
        !missingSignature &&
        status === STATUS_DONE
      ) {
        //Lezárásra kész munkalap
        saveButtonProps.hidden = false;
        saveButtonProps.children = localize("basic", "Lezárás");
        saveButtonProps.onClick = this.closeWorksheet;
      } else if (signatureMode && status === STATUS_DONE) {
        //Aláírás módban lezárás van
        saveButtonProps.disabled = signatureIsEmpty || !acceptSignature;
        saveButtonProps.children = localize("basic", "Lezárás");
        saveButtonProps.onClick = this.saveSignatureAndSave;
      } else if (signatureMode) {
        //Aláírás módban mindig aláírás mentés+mentés van
        saveButtonProps.disabled = signatureIsEmpty || !acceptSignature;
        saveButtonProps.onClick = this.saveSignatureAndSave;
      } else if (!status || dbStatus === STATUS_CLOSED) {
        //Új felvitelkor és lezáráskot, ha nincs aláírás, akkor a megerősítő modal, egyébként mentés
        if (!missingSignature && dbStatus === STATUS_FILLING) {
          saveAfterFirstSignature = true;
        }
        saveButtonProps.onClick = missingSignature
          ? this.openModalConfirm
          : this.saveWithoutSignature; //Felvitelkor állítja a státuszt NEW-ra, és utána ment, lezáráskor csak ment
      } else if (status === STATUS_NEW && !postEditingFirstSection) {
        saveButtonProps.children = localize("basic", "Munka megkezdése");
        saveButtonProps.onClick = this.startWorksheet;
      } else {
        //Sima mentés
        saveButtonProps.onClick = this.save;
      }
    }

    //•	Kész, ügyfélre vár státuszban edit módban legyen lezár gomb is, ne csak mégsem és aláír.
    if (
      status === STATUS_DONE &&
      !this.secondSectionIsEditable() &&
      !this.firstSectionIsEditable() &&
      !signatureMode
    ) {
      saveButtonProps.hidden = false;
      saveButtonProps.children = localize("basic", "Lezárás");
      saveButtonProps.onClick = missingSignature
        ? this.openModalConfirmClosing
        : this.closeWorksheet;
    }

    //Mentés gomb láthatóság felülbírálása
    if (isViewPage) {
      saveButtonProps.hidden = true;
    }
    //Mentés gomb onClick felülbírálása
    if (!client_complete) {
      saveButtonProps.onClick = () => {
        globalMessage.error(
          localize("worksheets", "Hiányosan kitöltött ügyfél.")
        );
        const elem = document.getElementsByClassName(
          "app-main-content-wrapper"
        );
        if (elem && elem.length > 0) {
          //26186311-2-15
          console.log(elem[0]);
          elem[0].scrollTo(0, 0);
        }
      };
    }
    //console.log("missingSignature", missingSignature);
    //Aláírás gomb
    const signatureButtonProps = {
      hidden: !missingSignature || signatureMode ? true : false
    };

    //Az első aláírás csak első szerkesztés módban látszik
    if (
      missingSignature === "signature_order" &&
      status &&
      status !== STATUS_FILLING &&
      !this.firstSectionIsEditable()
    ) {
      signatureButtonProps.hidden = true;
    }

    if (connected) {
      signatureButtonProps.children = localize(
        "worksheets",
        "Aláírás kiküldése"
      );
      signatureButtonProps.onClick = this.saveAndSendtoDevice;
    } else {
      signatureButtonProps.children = localize("worksheets", "Aláírás");
      signatureButtonProps.onClick = nothingIsEditable
        ? this.turnOnSignatureMode
        : this.saveAndTurnOnSignatureMode;
    }

    //Aláírás gomb láthatóság felülbírálása
    if (isViewPage) {
      signatureButtonProps.hidden = true;
    }

    if (
      //Előfeltétel: Van APN és a delivery nem nincs esemény
      this.props.formValues.apn &&
      this.props.formValues.apn.length > 3 &&
      this.props.formValues.delivery &&
      this.props.formValues.delivery !== "" &&
      this.props.formValues.delivery !== "none"
    ) {
      if (
        saveAfterFirstSignature ||
        (dbStatus === STATUS_WAITING && status !== STATUS_WAITING) ||
        (dbStatus === STATUS_DONE && status !== STATUS_DONE)
      ) {
        saveButtonProps.children = (
          <Fragment>
            <MailIcon fontSize="small" style={{ marginRight: 4 }} />
            {saveButtonProps.children}
          </Fragment>
        );
      }
    }

    return (
      <Fragment>
        <Button size="small" {...cancelButtonProps} />
        <Button color="primary" size="small" {...saveButtonProps} />
        <Button color="secondary" size="small" {...signatureButtonProps} />
      </Fragment>
    );
  };

  handleGetSignature = () => {
    const status =
      this.props.initialValues && this.props.initialValues.status
        ? this.props.initialValues.status
        : null;
    try {
      //TODO: send
      //console.log(this.sigPad.getTrimmedCanvas())
      this.props
        .sendSign(
          this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
          status === "DONE" ? "takeover" : "order",
          this.props.initialValues.view_token
        )
        .then(res => {
          console.log("handleGetSignature.sendSign", res);
          this.setState({
            waitingForSignatureTakeover: false,
            waitingForSignature: false
          });
          this.props.view(this.props.initialValues.id);
        });
    } catch (e) {
      console.log("handleGetSignature", e);
    }
  };

  onDrawEnd = () => {
    this.setState({ signatureIsEmpty: this.sigPad.isEmpty() });
  };

  clearSignature = () => {
    if (this.sigPad) {
      this.sigPad.clear();
      this.setState({ signatureIsEmpty: true });
    }
  };

  saveSignatureAndSave = () => {
    console.log("saveSignatureAndSave");
    const { uploadFile, change, handleSubmit } = this.props;
    const status = get(this.props, "formValues.status");
    const img_b64 = this.sigPad.getTrimmedCanvas().toDataURL("image/png");
    const file = convertBase64ToFile(img_b64, "signature.png");
    const signature = this.missingSignature();
    this.setState({ loading: true });
    uploadFile(file)
      .then(resFile => {
        console.log("saveSignatureAndSave.uploadFile", resFile);
        change(signature, resFile.id);
        if (status === STATUS_FILLING) {
          change("status", STATUS_NEW);
        }
        let justClosed = false;
        if (status === STATUS_DONE) {
          change("status", STATUS_CLOSED);
          justClosed = true;
        }

        setTimeout(() => {
          const p = handleSubmit();
          if (p.then) {
            p.then(resSubmit => {
              console.log("saveSignatureAndSave.handleSubmit", resSubmit);
              //Kikapcsoljuk az aláíró módot
              this.turnOffSignatureMode();

              //Kikapcsoljuk a post edit módokat, ha be van kapcsolva;
              if (signature === "signature_order") {
                this.turnOffPostEditingFirstSection();
              }

              if (signature === "signature_takeover") {
                this.turnOffPostEditingFirstSection();
              }

              this.setState({ loading: false, justClosed, justSigned: true });
            }).catch(error => {
              this.setState({ loading: false });
              alert(JSON.stringify(error));
            });
          }
        }, 200);
      })
      .catch(error => {
        this.setState({ loading: false });
        alert(JSON.stringify(error));
      });
  };

  isReadonlyField = name => {
    const { enabledFields } = this.props;
    return enabledFields
      ? enabledFields.indexOf(name) !== -1
        ? false
        : true
      : false;
  };

  customConfirmButtons = () => {
    const { connected } = this.props;
    //const { status } = this.props.formValues;
    const status = get(this.props, "initialValues.status");
    const nothingIsEditable =
      !this.firstSectionIsEditable() && !this.secondSectionIsEditable();
    return connected ? (
      <Button onClick={this.saveAndSendtoDevice} color="secondary">
        {localize("worksheets", "Kiküldés aláírásra")}
      </Button>
    ) : (
      <Button
        onClick={
          nothingIsEditable
            ? this.turnOnSignatureMode
            : this.saveAndTurnOnSignatureMode
        }
        color="secondary"
      >
        {localize("worksheets", "Aláírás")}
      </Button>
    );
  };

  openModalConfirm = () => {
    //console.log(this.props);
    const status = get(this.props, "formValues.status");
    const isClosingModal = status === STATUS_CLOSED;
    const { valid, submit } = this.props;
    if (valid) {
      this.setState({ openModalConfirm: true, isClosingModal });
    } else {
      submit();
    }
  };

  openModalConfirmClosing = () => {
    //console.log(this.props);
    const { valid, submit } = this.props;
    if (valid) {
      this.setState({ openModalConfirm: true, isClosingModal: true });
    } else {
      submit();
    }
  };

  closeModalConfirm = () => {
    if (this.state.openModalConfirm) {
      this.setState({ openModalConfirm: false, isClosingModal: false });
    }
  };
  /**
   * Elmenti a munkalapot aláírás nélkül és visszatér a listára
   * Új felvitelkor a státuszt átállítja new-ra
   * Ezt lehet használni, akkor is, ha előre feltöltött aláírás van
   *
   */
  saveWithoutSignature = () => {
    const { handleSubmit, change } = this.props;
    const { status } = this.props.formValues;
    let goBackTwice = false;
    if (!status) {
      goBackTwice = true;
      change("status", STATUS_NEW);
    }

    let isClosed = false;
    if (status === STATUS_DONE) {
      change("status", STATUS_CLOSED);
      isClosed = true;
    }

    setTimeout(() => {
      const p = handleSubmit();
      if (p.then) {
        //Go back
        if (isClosed) {
          this.closeModalConfirm();
          this.checkContract(() => {
            p.then(res => {
              console.log("saveWithoutSignature.handleSubmit1", res);
              //this.goBack(goBackTwice);
              this.goToList();
            });
          });
        } else {
          p.then(res => {
            console.log("saveWithoutSignature.handleSubmit2", res);
            //this.goBack(goBackTwice);
            this.goToList();
          });
        }
      } else {
        //Ha véletlen mégse lenne valid a form bezárjuk a modalt
        //Ez igazából nem fog megtörténni, mert ki se nyílik a modal, ha nem valid a form
        this.closeModalConfirm();
      }
    }, 100);
  };

  save = () => {
    const { handleSubmit } = this.props;
    const status = get(this.props, "formValues.status");
    const dbStatus = get(this.props, "initialValues.status");
    const p = handleSubmit();
    let isMoblieOrTablet = md.mobile() || md.tablet();
    let shouldGoBack = true;
    //Mobilon, ha készre állítottuk valami másról, akkor ne dobjon vissza a listára
    if (
      isMoblieOrTablet &&
      dbStatus !== STATUS_DONE &&
      status === STATUS_DONE
    ) {
      shouldGoBack = false;
    }

    if (p.then) {
      //Go back
      p.then(res => {
        console.log("save.handleSubmit", res);
        if (shouldGoBack) {
          this.goBack();
        }
      });
    }
  };

  closeWorksheet = () => {
    const { handleSubmit, change } = this.props;
    change("status", STATUS_CLOSED);
    console.log("closeWorksheet");
    setTimeout(() => {
      const p = handleSubmit();
      if (p.then) {
        this.checkContract(() => {
          p.then(res => {
            console.log("closeWorksheet.handleSubmit", res);
            //this.goBack();
            this.goToList();
          });
        });
      }
    }, 100);
  };

  startWorksheet = () => {
    const { handleSubmit, change } = this.props;
    change("status", STATUS_IN_PROGRESS);

    setTimeout(() => {
      const p = handleSubmit();
      if (p.then) {
        p.then(res => {
          console.log("startWorksheet.handleSubmit", res);
          //dispatch(handleInputChange("status"));
        });
      }
    }, 100);
  };

  saveAfterFirstSignature = () => {
    const { handleSubmit, change } = this.props;
    change("status", "NEW");
    const context = this;
    setTimeout(() => {
      const p = handleSubmit();
      if (p.then) {
        p.then(res => {
          console.log("saveAfterFirstSignature.handleSubmit", res);
          context.goToList();
        });
      }
    }, 100);
  };

  saveAndGoBack = () => {
    console.log("saveAndGoBack");
    const { handleSubmit } = this.props;

    //console.log(handleSubmit());
    const p = handleSubmit();
    if (p.then) {
      this.setState({ loading: true });
      p.then(response => {
        console.log("saveAndGoBack.save", response);
        this.goBack();
      }).catch(error => {
        console.log(error);
        this.setState({ loading: false });
      });
    } else {
      this.closeModalConfirm();
    }
  };

  saveAndTurnOnSignatureMode = () => {
    console.log("saveAndTurnOnSignatureMode");
    const { handleSubmit, initialValues, dispatch, change } = this.props;

    //TODO: aláírás visszaállítás, mert épp most rakták készre
    const status = get(this.props, "formValues.status");
    const dbStatus = get(this.props, "initialValues.status");
    if (status !== dbStatus && status === STATUS_CLOSED) {
      dispatch(change("status", dbStatus));
    }
    const context = this;
    setTimeout(() => {
      const p = handleSubmit();
      if (p.then) {
        //context.setState({ loading: true });
        if (!initialValues || !initialValues.id) {
          //Create állapotban hackelni kell
          console.log(
            "On create Form component is unmounted before promise resolved, set window.shouldturnOnSignatureMode = true for later use"
          );
          window.shouldturnOnSignatureMode = true;
        }
        p.then(response => {
          console.log("saveAndTurnOnSignatureMode.save", response);

          if (initialValues && initialValues.id) {
            // Edit állapotban
            context.turnOnSignatureMode();
          }
        }).catch(error => {
          //Create állapot hack levétele
          delete window.shouldturnOnSignatureMode;
          console.log(error);
          //context.setState({ loading: false });
        });
      } else {
        context.closeModalConfirm();
      }
    }, 200);
    //console.log(handleSubmit());
  };

  saveAndSendtoDevice = () => {
    console.log("saveAndSendtoDevice");
    const { sendView, handleSubmit } = this.props;

    //console.log(handleSubmit());
    const p = handleSubmit();
    if (p.then) {
      this.setState({ loading: true });
      p.then(response => {
        console.log("saveAndSendtoDevice.save", response);
        sendView(response.data.id)
          .then(response => {
            console.log("saveAndSendtoDevice.send", response);
            this.setState({
              waitingForSignature: true,
              openModalConfirm: false,
              loading: false
            });
            //this.closeModalConfirm();
          })
          .catch(error => {
            console.log(error);
            this.setState({ loading: false });
          });
      }).catch(error => {
        console.log(error);
        this.setState({ loading: false });
      });
    } else {
      this.closeModalConfirm();
    }
  };
  saveAndSendSignature = takeover => {
    console.log("saveAndSendtoDevice");
    const { handleSubmit } = this.props;

    //console.log(handleSubmit());
    const p = handleSubmit();
    if (p.then) {
      this.setState({ loading: true });
      p.then(response => {
        console.log("saveAndSendtoDevice.save", response);
        this.setState({
          [takeover === true
            ? "waitingForSignatureTakeover"
            : "waitingForSignature"]: true,
          openModalConfirm: false,
          loading: false
        });
      }).catch(error => {
        console.log(error);
        this.setState({ loading: false });
      });
    } else {
      this.closeModalConfirm();
    }
  };

  scrollToEnd = () => {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 200);
  };

  handleSetTags = (name, tags) => {
    const { dispatch, change } = this.props;
    dispatch(change("tags", tags));
  };

  uploadSignatureOrder = (fileData, file) => {
    console.log("uploadSignatureOrder", fileData, file);
    const { dispatch, change } = this.props;
    dispatch(change("signature_order", fileData.id));
  };
  removeSignatureOrder = (fileData, file) => {
    console.log("removeSignatureOrder", fileData, file);
    const { dispatch, change } = this.props;
    dispatch(change("signature_order", null));
  };
  uploadSignatureTakeover = (fileData, file) => {
    console.log("uploadSignatureTakeover", fileData, file);
    const { dispatch, change } = this.props;
    dispatch(change("signature_takeover", fileData.id));
  };
  removeSignatureTakeover = (fileData, file) => {
    console.log("removeSignatureTakeover", fileData, file);
    const { dispatch, change } = this.props;
    dispatch(change("signature_takeover", null));
  };

  clientHasFilledFields = () => {
    const { formValues } = this.props;
    const { selectedClient } = this.state;

    //Doboz esetén
    if (selectedClient && selectedClient.id) {
      return true;
    }
    //console.log(selectedClient);

    //Kézzel kitöltött mező esetén
    let hasClientData = false;
    Object.keys(formValues).forEach(key => {
      const prefix = key.substring(0, 7);
      if (prefix === "client_") {
        if (
          formValues[key] &&
          formValues[key] !== "" &&
          formValues[key] !== "________-_-__" &&
          formValues[key] !== "06-__-_______"
        ) {
          console.log(key, formValues[key]);
          hasClientData = true;
        }
      }
    });
    return hasClientData;
  };

  checkAnnualReviewExpiry = apn => {
    if (!apn || apn === "") {
      return;
    }
    restHelper
      .view("worksheets/annual-review", null, { apn })
      .then(response => {
        const expired = get(response, "data.expired", null);
        if (expired === true) {
          this.modal
            .open(
              "Figyelmeztetés",
              "",
              "A pénztárgépnek lejárt az éves felülvizsgálata.",
              "Ok",
              false
            )
            .then(() => {
              this.modal.close();
            });
        }
      });
  };

  handleApnOnBlur = evt => {
    const { dispatch, change } = this.props;
    //Kilépünk, ha már van kitöltött ügyfél emző
    this.checkAnnualReviewExpiry(evt.target.value);
    if (this.clientHasFilledFields()) {
      return;
    }

    if (this.handleApnLoad) {
      apnLoad(evt.target.value).then(response => {
        if (
          response.data &&
          response.data.client_id &&
          response.data.client_location_id
        ) {
          //Gyáriszám betöltése
          if (response.data.cashregister_pgeprule_pgsn) {
            dispatch(
              change("serial_number", response.data.cashregister_pgeprule_pgsn)
            );
          }
          //Erc betöltése
          if (response.data.erc) {
            dispatch(change("erc", response.data.erc));
          }
          //garanica auto töltés tárolása
          if (response.data) {
            this.setState({
              warranty_case: response.data.warranty_case ? true : false
            });
          }
          dispatch(change("client_tax_number", null));
          this.handleApnLoad(response.data);
        }
      });
    }
  };

  afterLocationSave = data => {
    const clientData = {};
    Object.keys(data).forEach(key => {
      clientData[`client_location_${key}`] = data[key];
    });
    console.log("afterLocationSave", data, clientData);
    if (this.handleLocationSelect) {
      this.handleLocationSelect(clientData);
    }
    return true;
  };

  setWarrantyIfNeeded = error_type => {
    console.log("setWarrantyIfNeeded", error_type);
    const { dispatch, change } = this.props;
    const { warranty_case } = this.state;
    if (error_type === "FIXING" && warranty_case) {
      dispatch(change("payment", "PAYMENT_WARRANTY"));
    }
  };

  handleStatusChange = status => {
    const { change, dispatch, user, formValues } = this.props;
    if (status === STATUS_DONE && !formValues.mechanic_id) {
      dispatch(change("mechanic_id", user.id));
    }

    if (status === STATUS_DONE && !formValues.date_of_completion) {
      const today = moment().format("YYYY-MM-DD");
      dispatch(change("date_of_completion", today));
    }
    console.log("handleStatusChange", status);
    if (status === STATUS_CLOSED) {
      this.checkContract();
    }
  };

  checkContract = cb => {
    const {
      initialValues: { id }
    } = this.props;
    restHelper.view("worksheets/service-order", id).then(response => {
      const service_order = get(response, "data.service_order", null);
      if (service_order === false) {
        this.modal
          .open(
            "Figyelmeztetés",
            "",
            "A pénztárgépnek nincs szerződése.",
            "Ok",
            false
          )
          .then(() => {
            this.modal.close();
            cb && cb();
          });
      } else {
        cb && cb();
      }
    });
  };

  handleAcceptSignature = evt => {
    this.setState({ acceptSignature: evt.target.checked });
  };

  openASZF = () => {
    this.setState({ openASZF: true });
  };

  closeASZF = () => {
    this.setState({ openASZF: false });
  };

  render() {
    const { handleInputChange, fieldErrors, isViewPage } = this.props;
    //console.log(this.props);
    const {
      openModalConfirm,
      loading,
      signatureMode,
      acceptSignature,
      openASZF,
      events,
      isClosingModal
    } = this.state;
    const {
      signature_order,
      signature_takeover,
      status
    } = this.props.formValues;
    const fh = fieldHelper(this.props.baseFields);
    const dbStatus = get(this.props, "initialValues.status");
    const ercs = fh.options("erc"); //Géptípusok
    const types = fh.options("work_type"); //Munka típusok
    const deliveries = fh.options("delivery"); //Helyszínek
    const taxes = fh.options("collector_tax"); //Gyűjtó adónemek
    //Lezárt státusz kivétel, ha nincs kész a munkalap
    const statuses =
      !this.props.initialValues ||
      [STATUS_DONE, STATUS_CLOSED].indexOf(this.props.initialValues.status) < 0
        ? fh.optionsFiltered("status", STATUS_CLOSED)
        : fh.options("status"); //Státuszok
    const payments = fh.options("payment"); //Fizetési módok
    const paid_statuses = fh.options("paid_status"); //Fizetési módok
    const colors = fh.options("color"); //Fizetési módok
    const error_types = fh.options("error_type"); //Hiba típusok módok
    const place_of_receipts = fh.options("place_of_receipt"); //Átvétel helyek
    const workfees = fh.options("workfee_id"); //Munkadíjak
    const workfee_types = fh.options("workfee_type"); //Munkadíj típusok
    const shop_products = fh.options("shop_product_id"); //Termékek
    const shop_product_units = fh.options("shop_product_unit"); //Termék mértékegységek
    const mechanics = fh.options("mechanic_id"); //Szervizesek
    const clientMatch = Object.assign({}, this.props.match, {
      params: {
        id: this.state.clientData.client_id
      },
      path: "/clients/edit/" + this.state.clientData.client_id,
      url: "/clients/edit/" + this.state.clientData.client_id
    });
    const getLabel = (arr, key, value) => {
      const founded = arr.find(obj => obj[key] == value);
      if (founded) {
        return founded.label;
      } else {
        return value;
      }
    };
    //Munkadíj lista és terméklista elkészítése. id -> név legyen
    let viewWorkfees = [];
    if (this.props.initialValues && this.props.initialValues.workfees) {
      viewWorkfees = this.props.initialValues.workfees.map(wf => {
        return {
          ...wf,
          workfee_id: getLabel(workfees, "id", wf.workfee_id),
          type: getLabel(workfee_types, "id", wf.type)
        };
      });
    }

    let viewShop_products = [];
    if (this.props.initialValues && this.props.initialValues.shop_products) {
      viewShop_products = this.props.initialValues.shop_products.map(sp => {
        return {
          ...sp,
          shop_product_id: getLabel(shop_products, "id", sp.shop_product_id),
          unit: getLabel(shop_product_units, "id", sp.unit)
        };
      });
    }

    const viewData = this.props.initialValues
      ? Object.assign({}, this.props.initialValues)
      : undefined;

    if (viewData) {
      //viewData.workfees = viewWorkfees;
      //viewData.shop_products = viewShop_products;
      viewData.delivery = fh.optionName("delivery", viewData.delivery);
      viewData.work_type = fh.optionName("work_type", viewData.work_type);
      viewData.error_type = fh.optionName("error_type", viewData.error_type);
      viewData.mechanic_id = fh.optionName("mechanic_id", viewData.mechanic_id);
      viewData.payment = fh.optionName("payment", viewData.payment);
      viewData.color_text = fh.optionName("color", viewData.color);
      viewData.paid_status_value = viewData.paid_status;
      viewData.paid_status = fh.optionName("paid_status", viewData.paid_status);

      viewData.status_text = fh.optionName("status", viewData.status);
    }

    //console.log(this.props.initialValues, this.props.formValues);

    const RenderError = ({ meta }) => {
      const { touched, error } = meta;
      return touched && error ? (
        <FormHelperText style={{ marginTop: -16, marginLeft: 14 }} error>
          {error}
        </FormHelperText>
      ) : (
        ""
      );
    };

    return (
      <Fragment>
        <ModalConfirm onRef={ref => (this.modal = ref)} />
        {this.viewIsVisible() && (
          <Fragment>
            <WorksheetDetails
              events={events}
              worksheet={viewData}
              worksheetToken={this.props.initialValues.view_token}
              onPostEditFirstSection={
                isViewPage ? undefined : this.turnOnPostEditingFirstSection
              }
              onPostEditSecondSection={
                isViewPage ? undefined : this.turnOnPostEditingSecondSection
              }
              //showSecondSection={this.isReadonlyField("dummy")}
              isAdmin={!signatureMode}
              forceShowSecondSection={
                isViewPage &&
                [
                  STATUS_CLOSED,
                  STATUS_DONE,
                  STATUS_IN_PROGRESS,
                  STATUS_WAITING
                ].indexOf(status) >= 0
                  ? true
                  : false
              }
            />
          </Fragment>
        )}
        <Modal
          open={openModalConfirm}
          customButtons={this.customConfirmButtons}
          title="Megerősítés"
          onCancel={this.closeModalConfirm}
          onClose={this.closeModalConfirm}
          onAccept={this.saveWithoutSignature}
          loading={loading}
        >
          {isClosingModal
            ? localize(
                "worksheets",
                "Biztosan lezárja aláírás nélkül a munkalapot?"
              )
            : localize(
                "worksheets",
                "Biztosan menti aláírás nélkül a munkalapot?"
              )}
        </Modal>
        <Modal
          maxWidth="md"
          disableIcon
          open={openASZF}
          disableCancel
          title={localize("worksheets", "Általános szerződési feltételek")}
          onCancel={this.closeASZF}
          onClose={this.closeASZF}
          onAccept={this.closeASZF}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut
          mattis turpis, nec aliquam tortor. Nullam condimentum lectus in
          convallis tempus. Ut bibendum, eros at rhoncus sollicitudin, lacus mi
          faucibus lacus, in facilisis quam lorem sit amet nunc. Proin id tempor
          lacus. Etiam at augue vulputate velit imperdiet aliquet. Maecenas
          porttitor leo eu tempus consequat. Nunc semper sem in metus venenatis
          tincidunt. Suspendisse luctus gravida quam ut imperdiet. Sed vulputate
          sit amet nibh quis pellentesque. Duis facilisis posuere pellentesque.
          Cras in eros a risus rutrum fringilla id vitae metus. Morbi fermentum
          nisl ornare, blandit eros sit amet, venenatis erat. In hac habitasse
          platea dictumst. Nunc luctus, odio nec malesuada consectetur, velit
          tortor commodo odio, nec dignissim lacus augue ultrices est. Sed
          vehicula mi ac vulputate vestibulum. Vivamus id tortor ac lorem
          scelerisque interdum. Etiam nisi ex, auctor ut imperdiet at, accumsan
          quis quam. Suspendisse nec malesuada quam. Curabitur eu nunc dolor.
          Sed semper nunc in mauris imperdiet rhoncus. Nullam in scelerisque
          eros. Nulla facilisi. Donec egestas odio suscipit risus porttitor
          pulvinar ut vitae metus. Etiam auctor faucibus lorem id auctor. Etiam
          et turpis id metus venenatis feugiat nec a dolor. Lorem ipsum dolor
          sit amet, consectetur adipiscing elit. Vestibulum imperdiet est et
          consectetur cursus. Ut congue metus eget leo porttitor faucibus.
          Integer in leo molestie enim iaculis efficitur non nec mauris. Orci
          varius natoque penatibus et magnis dis parturient montes, nascetur
          ridiculus mus. In hac habitasse platea dictumst. Aliquam faucibus arcu
          est, nec cursus est dapibus ac. In eget metus lectus. Phasellus
          euismod tincidunt lectus, a maximus est sagittis vel. Duis vel
          ultricies nisi, ut aliquet erat. Ut sagittis nunc non leo porttitor
          ornare. Sed a semper dui. Morbi congue scelerisque lorem vitae
          malesuada. Aenean consectetur tellus sem. Pellentesque porttitor
          turpis quis tellus sodales vestibulum. Vestibulum efficitur arcu
          magna, non tincidunt arcu volutpat sed. Quisque urna lectus, volutpat
          eu fringilla vitae, aliquam id urna. Vivamus tincidunt, nisl quis
          aliquam finibus, ipsum diam pharetra justo, vel mattis sapien tellus
          nec sapien. Sed ac lectus tincidunt, tempor libero quis, ornare metus.
          Maecenas at elit turpis. Integer congue dapibus bibendum. Donec
          efficitur mattis interdum. Donec mi nibh, lacinia et feugiat vel,
          consequat vitae odio. Phasellus pretium nunc in est tempor, at
          condimentum nulla venenatis. Donec hendrerit eros tincidunt elit
          accumsan commodo. Morbi ut condimentum dui, a vehicula dui. Duis
          laoreet efficitur est sit amet convallis. Morbi hendrerit nisi et
          feugiat lobortis. Vestibulum mattis tortor in dui commodo, vel dictum
          felis ultrices. Aliquam dignissim enim eget enim luctus fermentum.
          Vestibulum sed nisi eget ex eleifend laoreet. Sed in lacinia nisl.
          Nulla scelerisque hendrerit augue non semper. Nulla venenatis turpis
          quis nunc venenatis elementum. Praesent tortor mauris, dignissim sit
          amet turpis sed, tincidunt faucibus lacus.
        </Modal>
        <Dialog
          open={this.state.showClientEditModal}
          maxWidth="md"
          onClose={this.handleCloseModal.bind(this)}
          scroll="body"
        >
          {/*<DialogTitle>
                  {this.state.selectedClient.client_name + " " + localize("settings", "szerkesztése")}
              </DialogTitle>*/}
          <DialogContent style={{ paddingBottom: 0, overflow: "hidden" }}>
            <EditClient
              //{...this.props}
              disableButtons={true}
              ref={this.settingsForm}
              match={clientMatch}
              handleGetFormInfo={this.handleGetClientFormInfo}
              history={this.props.history}
              afterSave={this.afterSaveClient}
              afterLocationSave={this.afterLocationSave}
              disableClientRegValidation
            />
          </DialogContent>
          <DialogActions
            style={{ padding: 16, paddingTop: 0, allignItems: "flex-end" }}
          >
            <Button
              style={{ marginRight: 8 }}
              onClick={this.handleCloseModal.bind(this)}
              variant="contained"
              color="secondary"
              component="span"
            >
              {localize("settings", "Bezár")}
            </Button>
            <Button
              onClick={this.submitClientForm}
              variant="contained"
              color="primary"
              component="span"
            >
              {localize("settings", "Mentés")}
            </Button>
          </DialogActions>
        </Dialog>
        <EditForm
          {...this.props}
          renderCustomButtons={this.renderCustomButtons}
          disableSaveButton
          disableCancel
          disableEditButton
          afterSave={this.afterSave}
          goBackAfterSubmit={false}
        >
          {this.firstSectionIsEditable() && (
            <ServiceOrders
              {...this.props}
              clientBox={true}
              handleEditClient={this.handleEditClient}
              showPayments={false}
              disableEmailAndPhoneRequiredValidation={true}
              enableEmailValidation
              handleTaxOnBlur={handle => (this.handleTaxOnBlur = handle)}
              handleLocationSelect={handle =>
                (this.handleLocationSelect = handle)
              }
              regNoRequired={false}
              onClientFound={this.onClientFound}
              onClientRemoved={this.onClientRemoved}
              onClientLocationFound={this.onClientLocationFound}
              onClientLocationRemoved={this.onClientLocationRemoved}
              disableClientTaxValidation
              companyNameAutocomplete
              selectedClient={
                this.props.formValues && this.props.formValues.client_id
                  ? this.props.formValues
                  : null
              }
              disableRemoveClient={
                this.props.initialValues &&
                this.props.initialValues.id &&
                dbStatus !== STATUS_FILLING
                  ? true
                  : false
              }
              disableRemoveLocation={dbStatus === STATUS_CLOSED ? true : false}
              disableEditClient={dbStatus === STATUS_CLOSED ? true : false}
              //disableEditClient={waitingForSignature || isViewPage}
              handleApnLoad={handler => (this.handleApnLoad = handler)}
            />
          )}
          <Fragment>
            <Grid item xs={12}>
              <Grid container spacing={16}>
                {this.firstSectionIsEditable() && (
                  <Fragment>
                    <Grid item xs={12}>
                      <h6 className="form-legend">
                        {localize("worksheets", "Eszköz adatok")}
                      </h6>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        name="erc"
                        label={localize("worksheets", "Eszköz megnevezése")}
                        fieldError={fieldErrors.erc}
                        onChange={() => handleInputChange("erc")}
                        component={SelectField}
                        placeholder={localize(
                          "worksheets",
                          "Eszköz megnevezése"
                        )}
                        isRequired={true}
                        validate={[Validations.required]}
                        isViewPage={isViewPage}
                        options={ercs}
                        readOnly={this.isReadonlyField("erc")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        name="serial_number"
                        label={localize("worksheets", "Gyári szám")}
                        fieldError={fieldErrors.serial_number}
                        onChange={() => handleInputChange("serial_number")}
                        isRequired={true}
                        validate={[Validations.required]}
                        component={InputField}
                        type="text"
                        placeholder={localize("worksheets", "Gyári szám")}
                        isViewPage={isViewPage}
                        readOnly={this.isReadonlyField("serial_number")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        name="apn"
                        label={localize("worksheets", "Apn")}
                        fieldError={fieldErrors.apn}
                        onChange={() => handleInputChange("apn")}
                        onBlur={this.handleApnOnBlur}
                        //isRequired={true}
                        //validate={[Validations.required]}
                        component={InputField}
                        type="text"
                        placeholder={localize("worksheets", "Apn")}
                        isViewPage={isViewPage}
                        readOnly={this.isReadonlyField("apn")}
                        //{...apMask}
                        mask="apn"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={16}>
                        <Grid item xs={12} md={6}>
                          <h6 className="form-legend">
                            {localize("worksheets", "Átvett tartozékok")}
                            {this.props.formValues.work_type !== "outdoor"
                              ? " *"
                              : ""}
                          </h6>
                          <Toolbar disableGutters>
                            <Field
                              name="machinebook"
                              label={localize("worksheets", "Pénztárgép napló")}
                              onChange={(evt, value) => {
                                value === 1 &&
                                  this.props.change("no_accessories", null);
                                handleInputChange("machinebook");
                              }}
                              component={CheckboxField}
                              isViewPage={isViewPage}
                              readOnly={this.isReadonlyField("machinebook")}
                            />
                            <Field
                              name="adapter"
                              label={localize("worksheets", "Adapter")}
                              component={CheckboxField}
                              isViewPage={isViewPage}
                              readOnly={this.isReadonlyField("adapter")}
                              onChange={(evt, value) => {
                                value === 1 &&
                                  this.props.change("no_accessories", null);
                                handleInputChange("adapter");
                              }}
                            />

                            <Field
                              name="spool"
                              label={localize("worksheets", "Egyéb tartozék")}
                              component={CheckboxField}
                              isViewPage={isViewPage}
                              readOnly={this.isReadonlyField("spool")}
                              onChange={(evt, value) => {
                                value === 1 &&
                                  this.props.change("no_accessories", null);
                                handleInputChange("spool");
                              }}
                            />
                            <Field
                              name="no_accessories"
                              label={localize("worksheets", "Nincs")}
                              component={CheckboxField}
                              isViewPage={isViewPage}
                              readOnly={this.isReadonlyField("no_accessories")}
                              onChange={(evt, value) => {
                                if (value === 1) {
                                  this.props.change("machinebook", null);
                                  this.props.change("adapter", null);
                                  this.props.change("spool", null);
                                }

                                handleInputChange("no_accessories");
                              }}
                            />
                          </Toolbar>
                          <Field name="machinebook" component={RenderError} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <h6 className="form-legend">
                            {localize("worksheets", "Átvett eszköz állapota")}
                            {this.props.formValues.work_type !== "outdoor"
                              ? " *"
                              : ""}
                          </h6>
                          <Toolbar disableGutters>
                            <Field
                              name="device_undamaged"
                              label={localize("worksheets", "Sérülésmentes")}
                              onChange={(evt, value) => {
                                value === 1 &&
                                  this.props.change("device_damaged", null);
                                handleInputChange("device_undamaged");
                              }}
                              component={CheckboxField}
                              isViewPage={isViewPage}
                              readOnly={this.isReadonlyField(
                                "device_undamaged"
                              )}
                            />
                            <Field
                              name="device_damaged"
                              label={localize("worksheets", "Sérült")}
                              component={CheckboxField}
                              isViewPage={isViewPage}
                              readOnly={this.isReadonlyField("device_damaged")}
                              onChange={(evt, value) => {
                                value === 1 &&
                                  this.props.change("device_undamaged", null);
                                handleInputChange("device_damaged");
                              }}
                            />

                            <Field
                              name="device_insufficient"
                              label={localize("worksheets", "Hiányos")}
                              component={CheckboxField}
                              isViewPage={isViewPage}
                              readOnly={this.isReadonlyField(
                                "device_insufficient"
                              )}
                            />
                          </Toolbar>
                          <Field
                            name="device_undamaged"
                            component={RenderError}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <h6 className="form-legend">
                        {localize("worksheets", "Gyűjtők beállítása")}
                      </h6>
                      <FieldArray
                        name="collectors"
                        component={TableField}
                        fieldsError={fieldErrors.collectors}
                        isViewPage={isViewPage}
                        readOnly={this.isReadonlyField("collectors")}
                        data={get(this.props, "initialValues.collectors", [])}
                        change={this.props.change}
                        dispatch={this.props.dispatch}
                        initialValues={this.props.initialValues}
                        columns={[
                          { name: localize("worksheets", "Név"), width: 200 },
                          {
                            name: localize("worksheets", "Értékhatár"),
                            width: 100
                          },
                          { name: localize("worksheets", "Áfa"), width: 100 }
                        ]}
                        itemProps={{ taxes: taxes }}
                        Item={CollectorItem}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <h6 className="form-legend">
                        {localize("worksheets", "Csatolt dokumentumok")}
                      </h6>
                      <FieldArray
                        name="attachments"
                        component={TableField}
                        fileItem
                        fieldsError={fieldErrors.attachments}
                        isViewPage={isViewPage}
                        readOnly={this.isReadonlyField("attachments")}
                        data={get(this.props, "initialValues.attachments", [])}
                        change={this.props.change}
                        dispatch={this.props.dispatch}
                        initialValues={this.props.initialValues}
                        columns={[{ name: "Fájl", width: 200 }]}
                        Item={FileItem}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <h6 className="form-legend">Munkavégzés</h6>
                      <Grid container spacing={16}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            name="error_type"
                            label={localize("worksheets", "Típusa")}
                            fieldError={fieldErrors.error_type}
                            onChange={evt => {
                              this.setWarrantyIfNeeded(evt.target.value);
                              return handleInputChange("error_type");
                            }}
                            component={SelectField}
                            placeholder={localize(
                              "worksheets",
                              "Eszköz megnevezése"
                            )}
                            isRequired={true}
                            validate={[Validations.required]}
                            isViewPage={isViewPage}
                            options={error_types}
                            readOnly={this.isReadonlyField("error_type")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            name="place_of_receipt"
                            label={localize("worksheets", "Átvétel helye")}
                            fieldError={fieldErrors.place_of_receipt}
                            onChange={evt => {
                              this.setWarrantyIfNeeded(evt.target.value);
                              return handleInputChange("place_of_receipt");
                            }}
                            component={SelectField}
                            placeholder={localize(
                              "worksheets",
                              "Átvétel helye"
                            )}
                            isRequired={
                              this.props.formValues.work_type === "service"
                            }
                            validate={
                              this.props.formValues.work_type === "service"
                                ? [Validations.required]
                                : []
                            }
                            isViewPage={isViewPage}
                            options={place_of_receipts}
                            readOnly={this.isReadonlyField("place_of_receipt")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            isRequired={true}
                            validate={[Validations.required]}
                            name="error"
                            label={localize("worksheets", "Bejelentett hiba")}
                            fieldError={fieldErrors.error}
                            onChange={() => handleInputChange("error")}
                            component={InputField}
                            type="text"
                            placeholder={localize(
                              "worksheets",
                              "Bejelentett hiba"
                            )}
                            multiline
                            rows="4"
                            isViewPage={isViewPage}
                            readOnly={this.isReadonlyField("error")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            isRequired={true}
                            validate={[Validations.required]}
                            name="work_type"
                            label={localize("worksheets", "Munkavégzés helye")}
                            fieldError={fieldErrors.work_type}
                            onChange={() => handleInputChange("work_type")}
                            component={SelectField}
                            placeholder={localize(
                              "worksheets",
                              "Munkavégzés helye"
                            )}
                            isViewPage={isViewPage}
                            options={types}
                            readOnly={this.isReadonlyField("work_type")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            name="delivery"
                            label={localize("worksheets", "Befejezés")}
                            fieldError={fieldErrors.delivery}
                            onChange={() => handleInputChange("delivery")}
                            component={SelectField}
                            placeholder={localize("worksheets", "Befejezés")}
                            isRequired={true}
                            validate={[Validations.required]}
                            isViewPage={isViewPage}
                            options={deliveries}
                            readOnly={this.isReadonlyField("delivery")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            name="deadline"
                            label={localize("worksheets", "Vállalt határidő")}
                            component={DateField}
                            fieldError={fieldErrors.deadline}
                            placeholder={localize(
                              "worksheets",
                              "Vállalt határidõ"
                            )}
                            isViewPage={isViewPage}
                            readOnly={this.isReadonlyField("deadline")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            name="date"
                            label={localize(
                              "worksheets",
                              "Szervizbe szállítás ideje"
                            )}
                            component={DateField}
                            fieldError={fieldErrors.date}
                            placeholder={localize(
                              "worksheets",
                              "Szervizbe szállítás ideje"
                            )}
                            isViewPage={isViewPage}
                            readOnly={this.isReadonlyField("date")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <h6 className="form-legend">
                            {localize("worksheets", "Díjszabás alapja")} *
                          </h6>
                          <Field
                            name="payment"
                            //label={localize("worksheets", "Fizetési mód")}
                            //isRequired={true}
                            validate={[Validations.required]}
                            component={RadioField}
                            inline
                            options={payments}
                            isViewPage={isViewPage}
                            fieldError={fieldErrors.payment}
                            readOnly={this.isReadonlyField("payment")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            name="price_limit"
                            label={localize(
                              "worksheets",
                              "Javítási összeghatár"
                            )}
                            fieldError={fieldErrors.serial_number}
                            onChange={() => handleInputChange("price_limit")}
                            //isRequired={true}
                            //validate={[Validations.required]}
                            component={InputField}
                            type="text"
                            placeholder={localize(
                              "worksheets",
                              "Javítási összeghatár"
                            )}
                            isViewPage={isViewPage}
                            readOnly={this.isReadonlyField("price_limit")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            name="color"
                            onChange={color => {
                              console.log(color);
                              handleInputChange("color");
                            }}
                            label={localize("sms", "Jelölés")}
                            component={SelectField}
                            isViewPage={isViewPage}
                            //options={paid_statuses}
                            options={colors}
                            colors={worksheet_colors}
                            displayEmpty
                            emptyText="Nincs"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          style={{
                            backgroundColor:
                              this.props.initialValues &&
                              this.props.initialValues.paid_status === "unpaid"
                                ? "red"
                                : undefined
                          }}
                        >
                          <Field
                            name="paid_status"
                            label={localize("worksheets", "Fizetési státusz")}
                            fieldError={fieldErrors.error_type}
                            onChange={() => {
                              //this.setWarrantyIfNeeded(evt.target.value);
                              return handleInputChange("paid_status");
                            }}
                            component={SelectField}
                            //placeholder={localize("worksheets", "Eszköz megnevezése")}
                            isRequired={
                              this.props.formValues.work_type === "service" &&
                              this.props.formValues.payment !==
                                "PAYMENT_WARRANTY"
                                ? true
                                : false
                            }
                            validate={
                              this.props.formValues.work_type === "service" &&
                              this.props.formValues.payment !==
                                "PAYMENT_WARRANTY"
                                ? [Validations.required]
                                : []
                            }
                            isViewPage={isViewPage}
                            options={paid_statuses}
                            readOnly={this.isReadonlyField("paid_status")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            name="tags"
                            onChangeTags={tags =>
                              this.handleSetTags("tags", tags)
                            }
                            label={localize("sms", "Címkék")}
                            component={TagInput}
                            isViewPage={isViewPage}
                            tagPath={get(
                              this.props,
                              "baseFields.tags.url",
                              null
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <h6
                            className="form-legend"
                            style={{ display: "inline-block" }}
                          >
                            {localize("worksheets", "Megrendelési aláírás")}
                          </h6>
                          <UploadButton
                            name="signature_order"
                            size="small"
                            type="upload"
                            removeOnlyOnClient
                            showDeleteConfirm
                            deleteConfirmText={localize(
                              "worksheets",
                              "Biztosan törli az aláírást?"
                            )}
                            uploadTitle={localize(
                              "worksheets",
                              "Megrendelési aláírás feltöltése"
                            )}
                            onUpload={this.uploadSignatureOrder}
                            onRemove={this.removeSignatureOrder}
                            file={prepareFileFromId(signature_order)}
                          />
                        </Grid>
                        {signature_order && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            style={{ marginTop: -16 }}
                          >
                            <Image
                              fileId={signature_order}
                              style={{
                                width: "100%",
                                border: "solid 1px #999",
                                padding: 16,
                                borderRadius: 2
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Fragment>
                )}

                {this.secondSectionIsEditable() && (
                  <Fragment>
                    <Grid item xs={12}>
                      <h6 className="form-legend">
                        {localize("worksheets", "Munka adatok")}
                      </h6>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        isRequired={
                          [STATUS_DONE, STATUS_CLOSED].indexOf(status) >= 0
                            ? true
                            : false
                        }
                        validate={
                          [STATUS_DONE, STATUS_CLOSED].indexOf(status) >= 0
                            ? [Validations.required]
                            : undefined
                        }
                        name="details_of_completion_public"
                        label={
                          <b>
                            {localize("worksheets", "Munka leírása (Publikus)")}
                            {[STATUS_DONE, STATUS_CLOSED].indexOf(status) >= 0
                              ? " *"
                              : ""}
                          </b>
                        }
                        fieldError={fieldErrors.details_of_completion_public}
                        onChange={() =>
                          handleInputChange("details_of_completion_public")
                        }
                        component={InputField}
                        type="text"
                        placeholder={localize("worksheets", "Munka leírása")}
                        multiline
                        rows="4"
                        isViewPage={isViewPage}
                        readOnly={this.isReadonlyField(
                          "details_of_completion_public"
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        //isRequired={true}
                        //validate={[Validations.required]}
                        name="details_of_completion_private"
                        label={localize("worksheets", "Munka leírása (Privát)")}
                        fieldError={fieldErrors.details_of_completion_private}
                        onChange={() =>
                          handleInputChange("details_of_completion_private")
                        }
                        component={InputField}
                        type="text"
                        placeholder={localize("worksheets", "Munka leírása")}
                        multiline
                        rows="4"
                        isViewPage={isViewPage}
                        readOnly={this.isReadonlyField(
                          "details_of_completion_private"
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={16}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            //isRequired={true}
                            //validate={[Validations.required]}
                            name="counter_before"
                            label={localize("worksheets", "Gépállás előtte")}
                            fieldError={fieldErrors.counter_before}
                            onChange={() => handleInputChange("counter_before")}
                            component={InputField}
                            placeholder={localize(
                              "worksheets",
                              "Gépállás előtte"
                            )}
                            isViewPage={isViewPage}
                            readOnly={this.isReadonlyField("counter_before")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            //isRequired={true}
                            //validate={[Validations.required]}
                            name="counter_after"
                            label={localize("worksheets", "Gépállás utána")}
                            fieldError={fieldErrors.counter_after}
                            onChange={() => handleInputChange("counter_after")}
                            component={InputField}
                            placeholder={localize(
                              "worksheets",
                              "Gépállás utána"
                            )}
                            isViewPage={isViewPage}
                            readOnly={this.isReadonlyField("counter_after")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            //isRequired={true}
                            //validate={[Validations.required]}
                            name="postmark_before"
                            label={localize("worksheets", "Pecsétnyomó előtte")}
                            fieldError={fieldErrors.postmark_before}
                            onChange={() =>
                              handleInputChange("postmark_before")
                            }
                            component={InputField}
                            placeholder={localize(
                              "worksheets",
                              "Pecsétnyomó előtte"
                            )}
                            isViewPage={isViewPage}
                            readOnly={this.isReadonlyField("postmark_before")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            //isRequired={true}
                            //validate={[Validations.required]}
                            name="postmark_after"
                            label={localize("worksheets", "Pecsétnyomó utána")}
                            fieldError={fieldErrors.postmark_after}
                            onChange={() => handleInputChange("postmark_after")}
                            component={InputField}
                            placeholder={localize(
                              "worksheets",
                              "Pecsétnyomó utána"
                            )}
                            isViewPage={isViewPage}
                            readOnly={this.isReadonlyField("postmark_after")}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <h6 className="form-legend">
                            {localize("worksheets", "Munkadíjak")}
                          </h6>
                          <FieldArray
                            name="workfees"
                            component={TableField}
                            fieldsError={fieldErrors.collectors}
                            isViewPage={isViewPage}
                            readOnly={this.isReadonlyField("workfees")}
                            data={get(this.props, "initialValues.workfees", [])}
                            change={this.props.change}
                            dispatch={this.props.dispatch}
                            initialValues={this.props.initialValues}
                            columns={[
                              {
                                name: localize("worksheets", "Név"),
                                width: 200
                              },
                              {
                                name: localize("worksheets", "Mennyiség"),
                                width: 100
                              },
                              {
                                name: localize("worksheets", "Típus"),
                                width: 100
                              },
                              { name: localize("worksheets", "Ár"), width: 100 }
                            ]}
                            itemProps={{
                              workfee_types,
                              /*workfee_id: get(
                              this.props,
                              "baseFields.workfee_id"
                            )*/
                              workfees,
                              workfee: get(this.props, "baseFields.workfee", {})
                            }}
                            Item={FeeItem}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <h6 className="form-legend">
                            {localize("worksheets", "Anyagfelhasználás")}
                          </h6>
                          <FieldArray
                            name="shop_products"
                            component={TableField}
                            fieldsError={fieldErrors.collectors}
                            isViewPage={isViewPage}
                            readOnly={this.isReadonlyField("shop_products")}
                            data={get(
                              this.props,
                              "initialValues.shop_products",
                              []
                            )}
                            change={this.props.change}
                            dispatch={this.props.dispatch}
                            initialValues={this.props.initialValues}
                            columns={[
                              {
                                name: localize("worksheets", "Név"),
                                width: 200
                              },
                              //{ name: "Menny.egys.", width: 100 },
                              {
                                name: localize("worksheets", "Mennyiség"),
                                width: 100
                              },
                              { name: localize("worksheets", "Ár"), width: 100 }
                            ]}
                            itemProps={{
                              shop_product_units,
                              /*shop_product_id: get(
                              this.props,
                              "baseFields.shop_product_id"
                            )*/
                              shop_products,
                              worksheetId: viewData ? viewData.id : null,
                              shop_product: get(
                                this.props,
                                "baseFields.shop_product",
                                {}
                              )
                            }}
                            Item={ProductItem}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            name="date_of_completion"
                            label={localize("worksheets", "Javítás dátuma")}
                            isRequired={status === STATUS_DONE}
                            validate={
                              status === STATUS_DONE
                                ? [Validations.required]
                                : []
                            }
                            component={DateField}
                            fieldError={fieldErrors.date_of_completion}
                            placeholder={localize(
                              "worksheets",
                              "Javítás dátuma"
                            )}
                            isViewPage={isViewPage}
                            readOnly={this.isReadonlyField(
                              "date_of_completion"
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            //isRequired={true}
                            //validate={[Validations.required]}
                            name="invoice_no"
                            label={localize("worksheets", "Számlaszám")}
                            fieldError={fieldErrors.invoice_no}
                            onChange={() => handleInputChange("invoice_no")}
                            component={InputField}
                            placeholder={localize("worksheets", "Számlaszám")}
                            isViewPage={isViewPage}
                            readOnly={this.isReadonlyField("invoice_no")}
                          />
                        </Grid>
                        {dbStatus !== STATUS_CLOSED && (
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Field
                              name="status"
                              label={localize("worksheets", "Státusz")}
                              fieldError={fieldErrors.status}
                              onChange={evt => {
                                //console.log(evt.target.value);
                                this.handleStatusChange(evt.target.value);
                                return handleInputChange("status");
                              }}
                              component={SelectField}
                              placeholder={localize("worksheets", "Státusz")}
                              isViewPage={isViewPage}
                              options={statuses}
                              readOnly={this.isReadonlyField("status")}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            name="mechanic_id"
                            label={localize("worksheets", "Szervizes")}
                            fieldError={fieldErrors.mechanic_id}
                            onChange={() => handleInputChange("mechanic_id")}
                            component={SelectField}
                            placeholder={localize("worksheets", "Szervizes")}
                            isRequired={
                              [STATUS_DONE, STATUS_CLOSED].indexOf(status) >= 0
                                ? true
                                : false
                            }
                            validate={
                              [STATUS_DONE, STATUS_CLOSED].indexOf(status) >= 0
                                ? [Validations.required]
                                : undefined
                            }
                            isViewPage={isViewPage}
                            options={mechanics}
                            readOnly={this.isReadonlyField("mechanic_id")}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            name="color"
                            onChange={color => {
                              console.log(color);
                              handleInputChange("color");
                            }}
                            label={localize("sms", "Jelölés")}
                            component={SelectField}
                            isViewPage={isViewPage}
                            //options={paid_statuses}
                            options={colors}
                            colors={worksheet_colors}
                            displayEmpty
                            emptyText="Nincs"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          style={{
                            backgroundColor:
                              this.props.initialValues &&
                              this.props.initialValues.paid_status === "unpaid"
                                ? "red"
                                : undefined
                          }}
                        >
                          <Field
                            name="paid_status"
                            label={localize("worksheets", "Fizetési státusz")}
                            fieldError={fieldErrors.error_type}
                            onChange={() => {
                              //this.setWarrantyIfNeeded(evt.target.value);
                              return handleInputChange("paid_status");
                            }}
                            component={SelectField}
                            //placeholder={localize("worksheets", "Eszköz megnevezése")}
                            /*isRequired={true}
                            validate={[Validations.required]}*/
                            isRequired={
                              this.props.formValues.work_type === "service" &&
                              this.props.formValues.payment !==
                                "PAYMENT_WARRANTY"
                                ? true
                                : false
                            }
                            validate={
                              this.props.formValues.work_type === "service" &&
                              this.props.formValues.payment !==
                                "PAYMENT_WARRANTY"
                                ? [Validations.required]
                                : []
                            }
                            isViewPage={isViewPage}
                            options={paid_statuses}
                            readOnly={this.isReadonlyField("paid_status")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            name="tags"
                            onChangeTags={tags =>
                              this.handleSetTags("tags", tags)
                            }
                            label={localize("sms", "Címkék")}
                            component={TagInput}
                            isViewPage={isViewPage}
                            tagPath={get(
                              this.props,
                              "baseFields.tags.url",
                              null
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <h6 className="form-legend">
                            {localize("worksheets", "Csatolt dokumentumok")}
                          </h6>
                          <FieldArray
                            name="attachments"
                            component={TableField}
                            fileItem
                            fieldsError={fieldErrors.attachments}
                            isViewPage={isViewPage}
                            readOnly={this.isReadonlyField("attachments")}
                            data={get(
                              this.props,
                              "initialValues.attachments",
                              []
                            )}
                            change={this.props.change}
                            dispatch={this.props.dispatch}
                            initialValues={this.props.initialValues}
                            columns={[
                              {
                                name: localize("worksheets", "Fájl"),
                                width: 200
                              }
                            ]}
                            Item={FileItem}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <h6
                            className="form-legend"
                            style={{ display: "inline-block" }}
                          >
                            {localize("worksheets", "Átvételi aláírás")}
                          </h6>
                          <UploadButton
                            name="signature_takeover"
                            size="small"
                            type="upload"
                            showDeleteConfirm
                            deleteConfirmText={localize(
                              "worksheets",
                              "Biztosan törli az aláírást?"
                            )}
                            removeOnlyOnClient
                            uploadTitle={localize(
                              "worksheets",
                              "Átvételi aláírás feltöltése"
                            )}
                            onUpload={this.uploadSignatureTakeover}
                            onRemove={this.removeSignatureTakeover}
                            file={prepareFileFromId(signature_takeover)}
                          />
                        </Grid>
                        {signature_takeover && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            style={{ marginTop: -16 }}
                          >
                            <Image
                              fileId={signature_takeover}
                              style={{
                                width: "100%",
                                border: "solid 1px #999",
                                padding: 16,
                                borderRadius: 2
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Fragment>
                )}
                {signatureMode && (
                  <Grid item xs={12} md={6} style={{ position: "relative" }}>
                    <Tooltip title={localize("worksheets", "Aláírás törlése")}>
                      <IconButton
                        onClick={this.clearSignature}
                        style={{ position: "absolute", right: 8, top: 28 }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <h6 className="form-legend">
                      {localize("worksheets", "Aláírás")}
                    </h6>
                    <SignaturePad
                      //clearOnResize={false}
                      onEnd={this.onDrawEnd}
                      canvasProps={{
                        className: "signature",
                        style: { borderRadius: 2 }
                      }}
                      ref={ref => {
                        this.sigPad = ref;
                      }}
                    />
                    <FormControlLabel
                      style={{ marginLeft: "auto" }}
                      control={
                        <Checkbox
                          checked={acceptSignature}
                          onChange={this.handleAcceptSignature}
                          //value="checkedB"
                          color="primary"
                        />
                      }
                      label={
                        this.missingSignature() === "signature_order" ? (
                          <span>
                            Elfogadom az{" "}
                            <Link
                              onClick={evt => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                this.openASZF();
                              }}
                              style={{
                                color: "#32408f",
                                textDecoration: "underline"
                              }}
                            >
                              ÁSZF
                            </Link>
                            {localize(
                              "worksheets",
                              "-t , a munkát megrendelem"
                            )}
                          </span>
                        ) : (
                          <span>
                            {localize("worksheets", "Átvettem a készüléket")}
                          </span>
                        )
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Fragment>
        </EditForm>
      </Fragment>
    );
  }
}

const validate = values => {
  let errors = {};
  if (
    values.work_type !== "outdoor" &&
    !values.machinebook &&
    !values.adapter &&
    !values.no_accessories &&
    !values.spool
  ) {
    errors.machinebook = localize(
      "worksheets",
      "Az egyik jelölő nézet kitöltése kötelező"
    );
  }
  if (
    values.work_type !== "outdoor" &&
    !values.device_insufficient &&
    !values.device_undamaged &&
    !values.device_damaged
  ) {
    errors.device_undamaged = localize(
      "worksheets",
      "Az egyik jelölő nézet kitöltése kötelező"
    );
  }
  return errors;
};

WorksheetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  view: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  baseFields: PropTypes.object,
  formValues: PropTypes.object,
  enabledFields: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  history: PropTypes.object,
  isViewPage: PropTypes.bool,
  valid: PropTypes.bool,
  connected: PropTypes.bool,
  sendView: PropTypes.func.isRequired,
  sendSign: PropTypes.func.isRequired,
  getSigned: PropTypes.func.isRequired,
  getFormInfo: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  unSubscribeAdminSigning: PropTypes.func.isRequired,
  dirty: PropTypes.bool
};

const WorksheetReduxForm = reduxForm({
  form: "worksheet",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  destroyOnUnmount: false,
  validate
})(WorksheetForm);

//const selector = formValueSelector("worksheet");

export default connect(state => {
  //const values = selector(state, "signature_order", "signature_takeover", "status");
  //console.log(state.form.worksheet);
  return {
    initialValues: state.worksheets.viewData,
    formValues: getFormValues("worksheet")(state) || {}
  };
})(WorksheetReduxForm);
