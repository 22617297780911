import React, { PureComponent, Fragment } from "react";
import { Helmet } from "react-helmet";
import List from "../../../../../Components/List/List";
import ContainerHeader from "../../../../../Components/ContainerHeader";
import Navbar from "../../Navbar";
import OutgoingSmsModal from "./OutgoingSmsModal";
import OutgoingAddressesModal from "./OutgoingAddressesModal";
import { Button, Tooltip } from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import ListIcon from "@material-ui/icons/List";
import OpenNewIcon from "@material-ui/icons/OpenInNew";
import { Link } from "react-router-dom";
import { getUrl } from "helpers/placeHelper";
import localize from "helpers/localize";

class OutgoingSmsList extends PureComponent {
  state = {
    openOutgoingSmsModal: false,
    loadingOutgoingSmsModal: false,
    openOutgoingAddressesModal: false,
    loadingOutgoingAddressesModal: false,
    message_id: null,
    addressType: "recipients"
  };

  customButtons = () => {
    return (
      <div className="col-auto mr-auto">
        <Button
          //variant="contained"

          color="primary"
          onClick={this.openOutgoingSmsModal}
        >
          <MessageIcon
            color="primary"
            fontSize="small"
            style={{ marginRight: 6 }}
          />
          {localize("sms", "Új üzenet")}
        </Button>
      </div>
    );
  };

  openOutgoingSmsModal = () => {
    this.setState({ openOutgoingSmsModal: true });
  };
  openOutgoingAddressesModal = message_id => () => {
    this.setState({
      openOutgoingAddressesModal: true,
      message_id,
      addressType: "recipients"
    });
  };

  sendSMS = sms => {
    this.setState({ loadingOutgoingSmsModal: true });
    this.props.createOutgoingSms("SmsOutForm", sms).then(response => {
      this.setState({
        loadingOutgoingSmsModal: false,
        openOutgoingSmsModal: false
      });
      this.refreshList();
    });
  };

  closeOutgoingSmsModal = () => {
    this.setState({ openOutgoingSmsModal: false });
  };
  closeOutgoingAddressesModal = () => {
    this.setState({ openOutgoingAddressesModal: false, message_id: null });
  };

  refreshList = () => {
    if (this.list && this.list.getList) {
      return this.list.getList();
    }
  };

  customIcons = obj => {
    return (
      <Fragment>
        {obj.place && (
          <Tooltip
            title={localize("sms", "Ugrás a kapcsolt objektumra")}
            disableFocusListener
          >
            <Button
              component={Link}
              to={getUrl(obj.place)}
              target="_blank"
              className="jr-btn jr-btn-xs"
              color="primary"
              onClick={this.openOutgoingAddressesModal(obj.id)}
            >
              <OpenNewIcon fontSize="small" />
            </Button>
          </Tooltip>
        )}
        <Tooltip title={localize("sms", "Címzettek")} disableFocusListener>
          <Button
            //disabled={obj.marked ? true : false}
            className="jr-btn jr-btn-xs"
            color="primary"
            onClick={this.openOutgoingAddressesModal(obj.id)}
          >
            <ListIcon />
          </Button>
        </Tooltip>
      </Fragment>
    );
  };
  onClientTagClick = tag => {
    console.log("onClientTagClick", tag);
    this.setState({
      message_id: tag,
      addressType: "client_tag",
      openOutgoingAddressesModal: true
    });
  };
  onDistributorTagClick = tag => {
    console.log("onDistributorTagClick", tag);
    this.setState({
      message_id: tag,
      addressType: "distributor_tag",
      openOutgoingAddressesModal: true
    });
  };
  onClientClick = tag => {
    console.log("onClientClick", tag);
    this.setState({
      message_id: tag,
      addressType: "client_id",
      openOutgoingAddressesModal: true
    });
  };
  onDistributorClick = tag => {
    console.log("onDistributorClick", tag);
    this.setState({
      message_id: tag,
      addressType: "distributor_id",
      openOutgoingAddressesModal: true
    });
  };
  render() {
    const {
      openOutgoingSmsModal,
      loadingOutgoingSmsModal,
      openOutgoingAddressesModal,
      loadingOutgoingAddressesModal,
      message_id,
      addressType
    } = this.state;
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("sms", "Kimenő SMS")}</title>
        </Helmet>
        <Navbar />
        <ContainerHeader title={localize("sms", "Kimenő SMS")} />
        <OutgoingSmsModal
          open={openOutgoingSmsModal}
          loading={loadingOutgoingSmsModal}
          sendSMS={this.sendSMS}
          onClose={this.closeOutgoingSmsModal}
          onClientTagClick={this.onClientTagClick}
          onDistributorTagClick={this.onDistributorTagClick}
          onClientClick={this.onClientClick}
          onDistributorClick={this.onDistributorClick}
        />
        <OutgoingAddressesModal
          open={openOutgoingAddressesModal}
          loading={loadingOutgoingAddressesModal}
          type={addressType}
          message_id={message_id}
          onClose={this.closeOutgoingAddressesModal}
        />
        <List
          {...this.props}
          listRef={ref => (this.list = ref)}
          disabledCreate={true}
          disableEditIcon
          disableViewIcon
          customIcons={this.customIcons}
          //disabledTools={true}
          customButtons={this.customButtons}
        />
      </div>
    );
  }
}

export default OutgoingSmsList;
