import React from "react";
import DataTable from "Components/New/DataTable/DataTable/DataTable";
import DataProvider from "Components/New/DataProvider/DataProvider";
import localize from "helpers/localize";

class UserClients extends React.Component {
  render() {
    const { user, readOnly, afterSave } = this.props;
    if (!user) {
      return "Betöltés...";
    }
    return (
      <div style={{ width: "100%", position: "relative" }}>
        <DataProvider
          url={"user-clients"}
          queryParams={{
            section: "clients",
            user_id: user.id
          }}
          //optionsUrl={`user-clients/options`}
          component={DataTable}
          onlyBasefieldsOnForm
          title={localize("user", "Ügyfelek")}
          filterable={false}
          readOnly={readOnly}
          canEdit={false}
          afterSave={afterSave}
          refreshAfterSave
          goBackAfterSave
          perPage={1000}
          options={{
            grid: {
              columns: {
                user_name: {
                  hidden: true
                }
              }
            },
            form: {
              baseFields: {
                //name: { validators: ["required"] },
                //zip: { validators: ["required"] },
                client_id: {
                  name: localize("user", "Ügyfél"),
                  validators: ["required"]
                },
                user_id: {
                  name: localize("user", "Felhasználó"),
                  validators: ["required"],
                  defaultValue: user.id,
                  hidden: true
                }
              }
            }
          }}
        />
      </div>
    );
  }
}

export default UserClients;
