import React, { Component } from "react";
import PropTypes from "prop-types";
import { CircularProgress, withStyles } from "@material-ui/core";

const styles = () => ({
  oaverlay: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.oaverlay}>
        <CircularProgress color="primary" />
      </div>
    );
  }
}

Loader.defaultProps = {};
Loader.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Loader);
