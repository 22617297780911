import React from "react";
import PropTypes from "prop-types";
import {
  fade,
  withStyles,
  makeStyles,
  createMuiTheme
} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText } from "@material-ui/core";
import MaskedInput from "react-text-mask";
import { inputMask, valueCorrector } from "helpers/maskHelper";
//import NumberFormat from "react-number-format";

function cloneEventObj(eventObj, overrideObj) {
  if (!overrideObj) {
    overrideObj = {};
  }

  function EventCloneFactory(overProps) {
    for (var x in overProps) {
      this[x] = overProps[x];
    }
  }

  EventCloneFactory.prototype = eventObj;

  return new EventCloneFactory(overrideObj);
}

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 2
    },
    "label[nolabel] + &": {
      marginTop: 0
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 14,
    //width: "auto",
    width: "100%",
    padding: "7px 9px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
      borderColor: theme.palette.primary.main
    },
    "&[aria-invalid=true]": {
      borderColor: theme.palette.error.main,
      "&:focus": {
        boxShadow: `${theme.palette.error.main} 0 0 0 1px`
      }
    }
  }
}))(InputBase);

function TextMaskCustom(props) {
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      //mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      mask={inputMask(mask)}
      //mask={mobilNumberMask}
      //placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  mask: PropTypes.string.isRequired
};

class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
    this.input = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value });
    }
  }

  focusMaskHack = evt => {
    const { mask } = this.props;
    const inp = this.input.current;
    let index = evt.target.value ? evt.target.value.indexOf("_") : -1;
    if (mask && index >= 0 /*&& !valueCorrector(mask, evt.target.value)*/) {
      if (inp.createTextRange) {
        var part = inp.createTextRange();
        part.move("character", index);
        part.select();
      } else if (inp.setSelectionRange) {
        inp.setSelectionRange(index, index);
      }
    }
  };
  onFocus = evt => {
    const { onFocus } = this.props;
    this.focusMaskHack(evt);
    onFocus && onFocus(evt);
  };

  onBlur = evt => {
    const {
      onChange,
      onChanged,
      onChanged2,
      onBlur,
      mask,
      inputProps
    } = this.props;

    //const newEventObj = cloneEventObj(evt, { target: evt.target });
    evt.persist();
    if (mask) {
      //let newEvt = new evt.constructor(evt.type, evt);
      evt.target = {
        id: evt.target.id,
        value: valueCorrector(mask, evt.target.value)
      };
      console.log("lol");
      //inputProps && inputProps.onChange && inputProps.onChange(evt);
      //return onChange && onChange(evt);
    }

    if (evt.target.value !== this.props.value) {
      inputProps && inputProps.onChange && inputProps.onChange(evt);
      inputProps && inputProps.onChanged && inputProps.onChanged(evt);
      onChange && onChange(evt);
      onChanged && onChanged(evt);
      onChanged2 && onChanged2(evt);
    }

    setTimeout(() => {
      inputProps && inputProps.onBlur && inputProps.onBlur(evt);
      onBlur && onBlur(evt);
    }, 100);
  };

  onClick = evt => {
    const { onClick } = this.props;
    this.focusMaskHack(evt);
    onClick && onClick(evt);
  };

  onChange = evt => {
    evt.persist();
    const { onChanged, onChanged2 } = this.props;
    this.setState({ value: evt.target.value }, () => {
      if (this.cTimeout) {
        clearTimeout(this.cTimeout);
      }
      this.cTimeout = setTimeout(() => {
        onChanged2 && onChanged2(evt);
      }, 300);
      onChanged && onChanged(evt);
    });
  };
  onChange2 = evt => {
    const { onChange, mask, inputProps } = this.props;
    //console.log(this.props);
    if (mask) {
      //let newEvt = new evt.constructor(evt.type, evt);
      evt.target = {
        id: evt.target.id,
        value: valueCorrector(mask, evt.target.value)
      };
      //inputProps && inputProps.onChange && inputProps.onChange(evt);
      //return onChange && onChange(evt);
    }
    inputProps && inputProps.onChange && inputProps.onChange(evt);
    return onChange && onChange(evt);
  };

  handleInputKeypress = evt => {
    let keyCode = evt.which || evt.keyCode;
    if (keyCode === 13) {
      //Enter
      this.props.onEnter && this.props.onEnter(evt);
    }
    this.props.onKeyPress && this.props.onKeyPress(evt);
  };

  render() {
    const {
      id,
      label,
      fullWidth,
      onKeyPress,
      onEnter,
      error,
      helperText,
      mask,
      isRequired,
      onChange,
      onClick,
      value: propsValue,
      onFocus,
      onChanged,
      maxLength,
      showRemainingChars,
      rowsMin,
      onBlur,
      onChanged2,
      InputProps, //Remove Material InputProps
      ...rest
    } = this.props;
    const { value } = this.state;
    //console.log(id, InputProps);;
    /* eslint-disable no-unused-vars */
    //console.log(id, mask);
    /* eslint-enable no-unused-vars */
    //console.log("value", value);
    return (
      <FormControl
        fullWidth={fullWidth}
        error={error ? true : false} /*className={classes.margin}*/
      >
        <InputLabel
          shrink
          htmlFor={id}
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontWeight: isRequired ? "bold" : "normal"
          }}
          nolabel={label ? undefined : 1}
          error={error ? true : false}
        >
          {label}
          {isRequired ? " *" : ""}
          {showRemainingChars && maxLength
            ? ` (${maxLength - value.length})`
            : ""}
        </InputLabel>
        <BootstrapInput
          inputRef={this.input}
          onKeyPress={this.handleInputKeypress}
          fullWidth={fullWidth}
          id={id}
          error={error ? true : false}
          value={value || ""}
          {...rest}
          inputProps={{
            mask: mask,
            autoComplete: "false",
            maxLength: maxLength
          }}
          inputComponent={mask ? TextMaskCustom : undefined}
          //onChange={this.onChange}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onClick={this.onClick}
          onFocus={this.onFocus}
        />
        {(error || helperText) && (
          <FormHelperText error={error ? true : false}>
            {error || helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

TextField.defaultProps = {
  id: `textfield_${new Date().getTime().toString()}`
};

TextField.propTypes = {
  isRequired: PropTypes.bool,
  showRemainingChars: PropTypes.bool
};

export default TextField;
