import React, { useEffect } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as cartActions from "redux/modules/shopCart";
import * as orderActions from "redux/modules/shopOrder";
import { get } from "lodash";
let fetched = false;
// eslint-disable-next-line react/display-name
const CartWrapper = Component => props => {
  // eslint-disable-next-line no-unused-vars
  const { dispatch, ...rest } = props;

  useEffect(() => {
    if (!fetched) {
      //Only need fetch at the first occurence
      props.fetch();
      fetched = true;
    }
  }, []);
  return <Component {...rest} />;
};

const mapStateToProps = state => {
  return { ...state.shopCart, order: state.shopOrder };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      ...cartActions,
      fetchOrder: orderActions.fetch,
      fetchOrderOptions: orderActions.fetchOptions,
      fetchOrderWithOptions: orderActions.fetchWithOptions,
      createOrder: orderActions.create,
      changeOrder: orderActions.change,
      changeStep: orderActions.changeStep,
      changeErrors: orderActions.changeErrors
    },
    dispatch
  );
};

const ComposedCartWrapper = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  CartWrapper
);
export default ComposedCartWrapper;
