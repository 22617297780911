import React, { Fragment, PureComponent } from "react";
import { Helmet } from "react-helmet";

import List from "../../../../../Components/List/List";
import Navbar from "../../Navbar";
import EditAnnualReview from "./EditAnnualReview";
import Link from "@material-ui/icons/Link";
import { EditAnnualReviewPage } from "../../../../index";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DownloadIcon from "@material-ui/icons/Description";
import DownloadIcon2 from "@material-ui/icons/InsertDriveFile";
import localize from "helpers/localize";

class AnnualReviewsList extends PureComponent {
  state = {
    open: false
  };

  handleModuleEvent = (type, id) => {
    this.setState({
      open: id
    });
  };

  onAcceptViewModal() {
    document.getElementById("download-pdf-annual-review").click();
  }

  getModalBodyByRow = row => {
    return (
      <EditAnnualReviewPage
        {...row}
        match={{ params: row }}
        mode="active"
        isViewPage={true}
        disabledEditButton={true}
        getPagetitle={() => localize("cashregisters", "Éves felülvizsgálat")}
        isModalMode={true}
      />
    );
  };

  handleOpenLink = link => {
    window.open(link, "_blank");
  };

  customIcons = obj => {
    return (
      <Fragment>
        {obj.certificate_url && (
          <Tooltip
            disableFocusListener
            title={localize("cashregisters", "Tanúsítvány letöltése")}
          >
            <Button
              className="jr-btn jr-btn-xs"
              target="_blank"
              color="primary"
              href={
                process.env.REACT_APP_HOST +
                obj.certificate_url +
                "?accessToken=" +
                localStorage.getItem("access_token").replace("Bearer ", "")
              }
              component="a"
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
        )}
        {obj.result_url && (
          <Tooltip
            disableFocusListener
            title={localize("cashregisters", "Eredmény letöltés")}
          >
            <Button
              className="jr-btn jr-btn-xs"
              target="_blank"
              color="primary"
              href={
                process.env.REACT_APP_HOST +
                obj.result_url +
                "?accessToken=" +
                localStorage.getItem("access_token").replace("Bearer ", "")
              }
              component="a"
            >
              <DownloadIcon2 />
            </Button>
          </Tooltip>
        )}
        {obj.public_view && (
          <Tooltip
            disableFocusListener
            title={localize("cashregisters", "Publikus nézet")}
          >
            <Button
              className="jr-btn jr-btn-xs"
              onClick={this.handleOpenLink.bind(this, obj.public_view)}
              component="span"
            >
              <Link />
            </Button>
          </Tooltip>
        )}
      </Fragment>
    );
  };

  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("cashregisters", "Éves felülvizsgálat")}</title>
        </Helmet>
        <Navbar {...this.props} />
        <List
          {...this.props}
          isViewInModalMode={true}
          modalData={this.state.modalData}
          getModalBodyByRow={this.getModalBodyByRow}
          onAcceptViewModal={this.onAcceptViewModal}
          modalYesText={localize("cashregisters", "Pdf letöltés")}
          disabledCreate={true}
          disableEditIcon={true}
          disableDeleteIcon={true}
          customIcons={this.customIcons}
        />
      </div>
    );
  }
}

export default AnnualReviewsList;
