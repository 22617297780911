import React, { Component, Fragment } from 'react';

export default class Text extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchKeyTimeout: null,
            searchAll: props.keyword
        };

        this.clearTimeOut = this.clearTimeOut.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

      handleSearch = () => {
        this.props.onChange(
            this.props.name,
            this.refs.input.value
        );
      };

    handleChange = prop => event => {
        this.setState({[prop]: event.target.value});
        this.clearTimeOut();
        if (event.keyCode === 13) {
            this.handleSearch();
        }
        else {
            this.setState({
                searchKeyTimeout: window.setTimeout(_this=>{
                    _this.handleSearch()
                }, 800, this)
            })
        }
    };

    componentWillUnmount = () => {
        this.clearTimeOut();
    };

    clearTimeOut = () => {
        window.clearTimeout(this.state.searchKeyTimeout);
    };

  render() {
    return (
        <Fragment>
          <input
              onBlur={this.handleSearch.bind(this)}
              name={this.props.name}
              ref="input"
              type="text"
              className="form-control"
              defaultValue={this.props.value || ''}
              onChange={this.handleChange('searchAll')}
          />
        </Fragment>
    );
  }
}