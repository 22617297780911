import React, { Fragment } from "react";


import ExamForm from "./ExamForm";
import EditPage from "../../../../../Components/Extends/EditPage";
import Edit from "../../../../../Components/Edit/Edit";
import Navbar from "../../Navbar";
import localize from "helpers/localize";

class EditExam extends EditPage {
  getPageTitle = () => {
    if (this.isViewPage()) {
      return localize("exams", "Vizsga megtekintése");
    }
    return this.props.match.params.id
      ? localize("exams", "Vizsga szerkesztése")
      : localize("exams", "Vizsga létrehozása");
  };
  render() {
    return (
      <Fragment key={"EditExam" + (this.isViewPage() ? "isViewPage" : "")}>
        <Navbar />
        <Edit
          getPagetitle={this.getPageTitle}
          isViewPage={this.isViewPage()}
          disabledEditButton={true}
          {...this.props}
        >
          <ExamForm {...this.props} isViewPage={this.isViewPage()} />
        </Edit>
      </Fragment>
    );
  }
}

export default EditExam;
