import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css"; // For some basic styling. (OPTIONAL)
import moment from 'moment';

import withWidth from '@material-ui/core/withWidth';

class DateRange extends React.Component {
  constructor(props) {
    super(props);

    const value = this.parseValue(props.value);
    this.state = {
      value: value,
      textValue: this.textValue(value),
      open: false
    };
  }

  getRange(range) {
    const today = new moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    const startOfWeek = moment().startOf("isoWeek");
    const startOfMonth = moment().startOf("Month");
    const endOfWeek = moment().endOf("isoWeek");
    const endOfMonth = moment().endOf("Month");

    switch (range) {
      case "today":
        return moment.range(today, today);
      case "thisWeek":
        return moment.range(startOfWeek, endOfWeek);
      case "thisMonth":
        return moment.range(startOfMonth, endOfMonth);
      case "lastWeek":
        return moment.range(
          startOfWeek.clone().subtract(7, "days"),
          endOfWeek.clone().subtract(7, "days")
        );
      case "lastMonth":
        return moment.range(
          startOfMonth.clone().subtract(1, "months"),
          startOfMonth.clone().subtract(1, "days")
        );
      case "last7Days":
        return moment.range(today.clone().subtract(6, "days"), today);
      case "last30Days":
        return moment.range(today.clone().subtract(29, "days"), today);
      default:
        return moment.range(today, today);
    }
  }

  parseName = range => {
    if (typeof range === "string") {
      switch (range) {
        case "today":
          return "Ma";
        case "thisWeek":
          return "Ez a hét";
        case "thisMonth":
          return "Ez a hónap";
        case "lastWeek":
          return "Előző hét";
        case "lastMonth":
          return "Előző hónap";
        case "last7Days":
          return "Elmúlt 7 nap";
        case "last30Days":
          return "Elmúlt 30 nap";
        default:
          return "Egyéni időszak";
      }
    } else if (range) {
      return "Egyéni időszak";
    } else {
      return null;
    }
  };

  parseValue = value => {
    if(value) {
      if(value[0] && value[1]) {
        
        return moment().range(
          moment(value[0]),
          moment(value[1])
        );
      }
    }
  };

  onOpen = () => this.setState({ open: true });

  onClose = () => {
    if (!this.unMounted) {
      this.setState({ open: false });
    }
  };

  componentWillUnmount() {
    this.unMounted = true;
  }

  onSelect = (value, rangeKey) => {  
    const v = [value.start.format(this.props.valueFormat), value.end.format(this.props.valueFormat)];
    this.setState({value: value, textValue: this.textValue(value)});
    this.props.onChange(this.props.name, v);
    this.onClose();
  };

  clearDates = evt => {
    evt.stopPropagation();
    this.props.onChange(this.props.name, [null,null]);
    this.setState({value: null, textValue: ""});
    this.onClose();
  };

  textValue = value => {

    if (!value || !value.start || !value.end) {
      return "";
    }

    const start = value.start.format(this.props.displayFormat).toLowerCase();
    const end = value.end.format(this.props.displayFormat).toLowerCase();
    
    return start + "-" + end;
  };

  onChange = (evt) => {
    const value = evt.target.value;
    this.setState({textValue: value})

    if(!value || value === "") {
      return;
    }
    
    const dates = value.split("-");
    if (dates.length !== 2) {
      return;
    }

    const start = moment(dates[0].trim());
    const end = moment(dates[1].trim());
    
    if(start.isValid() && end.isValid()) {
      const range = moment().range(
        start,
        end
      );

      this.setState({value: range});
      const v = [
        range.start.format(this.props.valueFormat),
        range.end.format(this.props.valueFormat)
      ];
      this.props.onChange(this.props.name, v);
    }
  }

  render() {
    const { label } = this.props;
    
    const { open, value, textValue } = this.state;
    
    const buttonProps = {
      size: "small",
      color: "primary",
      mini: true,
      //variant: "extendedFab",
      style: {
        display: "inline-block",
        fontSize: 10,
        minWidth:"auto",
      }
    };

    const clearAdormentStyle = {
      width:24,
      height:24,
      top:"calc(50% - 12px)",
      right:5,
      position:"absolute",
    }

    const ClearAdorment = value ?
    <IconButton onClick={this.clearDates} style={clearAdormentStyle}>
      <ClearIcon />
    </IconButton> : ""

    const SimpleInput = <div style={{position:"relative"}}>
      <input
        onChange={this.onChange}
        onClick={this.onOpen}
        name={this.props.name}
        ref="input"
        type="text"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        className="form-control"
        value={textValue}
        style={{
          paddingRight:30
        }}
      />
      {ClearAdorment}
    </div>;
    
    /*const CompactInput = <div
      onClick={this.onOpen}
      name={this.props.name}
      ref="input"
      className="form-control"
      style={{ position:"relative", minHeight:35 }}
    >
      {textValue}
      {ClearAdorment}
    </div>;*/

    const InputComponent = this.props.InputComponent
    ? this.props.InputComponent
    : SimpleInput;

    const numberOfCalendars = this.props.width === "xs" || this.props.width === "sm" ? 1 : 2;

    return (
      <div>
        
        {InputComponent}

        <Dialog
          onClose={this.onClose}
          aria-labelledby="simple-dialog-title"
          open={open}
          maxWidth={false}
        >
          {label ? (
            <DialogTitle id="simple-dialog-title" style={{ textAlign: numberOfCalendars === 1 ? "center": "left" }}>{label}</DialogTitle>
          ) : (
            ""
          )}
          {open ? (
            <DateRangePicker
              onSelect={this.onSelect}
              selectionType="range"
              numberOfCalendars={numberOfCalendars}
              singleDateRange={true}
              firstOfWeek={1}
              value={value}
              locale="hu"
            />
          ) : (
            ""
          )}
          <div style={{ padding: 15, maxWidth:numberOfCalendars === 1 ? 336 : "none", textAlign: numberOfCalendars === 1 ? "center": "left" }}>
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(this.getRange("today"), "today")
              }
            >
              Ma
            </Button>
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(this.getRange("thisWeek"), "thisWeek")
              }
            >
              Ez a hét
            </Button>
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(this.getRange("lastWeek"), "lastWeek")
              }
            >
              Előző hét
            </Button>
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(this.getRange("last7Days"), "last7Days")
              }
            >
              Elmúlt 7 nap
            </Button>
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(this.getRange("thisMonth"), "thisMonth")
              }
            >
              Ez a hónap
            </Button>
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(this.getRange("last30Days"), "last30Days")
              }
            >
              Elmúlt 30 nap
            </Button>
            <Button
              {...buttonProps}
              onClick={() =>
                this.onSelect(this.getRange("lastMonth"), "lastMonth")
              }
            >
              Előző hónap
            </Button>
            {this.props.showModalClearButton ? <Button
              {...buttonProps}
              onClick={this.clearDates}
              color="secondary"
            >
              Töröl
            </Button> : ""}
          </div>
        </Dialog>
      </div>
    );
  }
}

DateRange.defaultProps = {
  displayFormat: "YYYY MMM. DD",
  valueFormat: "YYYY.MM.DD.",
  showModalClearButton: false,
}

DateRange.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  InputComponent: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  displayFormat: PropTypes.string,
  valueFormat: PropTypes.string,
  showModalClearButton: PropTypes.bool,
};

export default withWidth()(DateRange);