import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Login from "./views/Login";
import * as authActions from "../../../../redux/modules/auth";

const mapStateToProps = state => {
  return {
    failed: state.auth.failed,
    success: state.auth.success,
    loading: state.auth.loading
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      login: authActions.login,
      reset: authActions.reset,
      resetForm: authActions.resetForm
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
