import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";


import List from "../../../../Components/List/List";
import ContainerHeader from "../../../../Components/ContainerHeader";
import localize from "helpers/localize";

class ClientsList extends PureComponent {
  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>{localize("clients", "Ügyfelek")}</title>
        </Helmet>
        <ContainerHeader title={localize("clients", "Ügyfelek")} />
        <List {...this.props} />
      </div>
    );
  }
}

export default ClientsList;
