import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Typography,
  Toolbar,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import ContinueIcon from "@material-ui/icons/ChevronRight";
import BackIcon from "@material-ui/icons/ChevronLeft";
import { Link } from "react-router-dom";
import { BASE_PATH_CART } from "./../shopPath";
import TextField from "Components/New/Input/TextField/TextField";
import Select from "Components/New/Input/Select/Select";
import localize from "helpers/localize";
/*import Tags from "Components/New/Input/Tags/Tags";
import Wysiwyg from "Components/New/Input/Wysiwyg/Wysiwyg";
import Image from "Components/New/Input/Image/Image";
import formatHelper from "helpers/formatHelper";*/

const styles = theme => ({});
const CheckOutForm = ({
  fieldProps,
  onChange,
  onItemChange,
  editItem,
  onSave,
  changeStep
}) => {
  //Számlázási adatok másolása
  const copyData = copy => {
    if (copy) {
      onItemChange({
        delivery_phone: editItem.phone,
        delivery_zip: editItem.zip,
        delivery_city: editItem.city,
        delivery_street: editItem.street,
        delivery_street_suffix: editItem.street_suffix,
        delivery_street_details: editItem.street_details,
        copy_billing: copy
      });
    } else {
      onItemChange({
        delivery_phone: null,
        delivery_zip: null,
        delivery_city: null,
        delivery_street: null,
        delivery_street_suffix: null,
        delivery_street_details: null,
        copy_billing: copy
      });
    }
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="h6">
          {localize("shop", "Rendelés részletei")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {localize("shop", "Számlázási adatok")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("tax_number")} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("name")} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("phone")} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("email")} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("zip")} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("city")} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("street")} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("street_suffix")} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField {...fieldProps("street_details")} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {localize("shop", "Szállítási adatok")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Select
                  {...fieldProps("shipping_method", "dropdown")}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          {editItem.shipping_method && editItem.shipping_method !== "NONE" && (
            <Fragment>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editItem.copy_billing || false}
                      onChange={evt => {
                        copyData(evt.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={localize("shop", "Megegyezik a számlázási adatokkal")}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField {...fieldProps("delivery_phone")} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField {...fieldProps("delivery_zip")} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField {...fieldProps("delivery_city")} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField {...fieldProps("delivery_street")} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  {...fieldProps("delivery_street_suffix")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  {...fieldProps("delivery_street_details")}
                  fullWidth
                />
              </Grid>
            </Fragment>
          )}
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {localize("shop", "Fizetési adatok")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Select
                  {...fieldProps("payment_method", "dropdown")}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField {...fieldProps("comment")} fullWidth />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Toolbar disableGutters>
          <Button
            color="default"
            variant="contained"
            size="large"
            onClick={() => changeStep(0)}
            to={BASE_PATH_CART}
            component={Link}
          >
            <BackIcon style={{ marginLeft: -8 }} />
            {localize("basic", "Vissza")}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            onClick={onSave}
            style={{ marginLeft: "auto" }}
          >
            {localize("basic", "Tovább")}
            <ContinueIcon style={{ marginRight: -8 }} />
          </Button>
        </Toolbar>
      </Grid>
    </Fragment>
  );
};
CheckOutForm.defaultProps = {};

CheckOutForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  fieldProps: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  changeStep: PropTypes.func,
  editItem: PropTypes.object
};

export default withStyles(styles, { withTheme: true })(CheckOutForm);
