import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import cookie from "react-cookies";
import IdleTimer from "react-idle-timer";
import BodyClassName from "react-body-classname";
const strings = require(`themes/${process.env.REACT_APP_THEME}/strings`);

class Layout extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  componentWillMount = () => {
    let lang = false;
    try {
      lang = localStorage.getItem("lang");
    } catch (err) {
      try {
        lang = cookie.load("lang");
      } catch (err) {}
    }
    if (!lang) {
      lang = process.env.REACT_APP_DEFAULT_LANG;
    }
    //TODO: LOAD Language
  };

  logoutUser = () => {
    const token = localStorage.getItem("access_token");
    if (token) {
      localStorage.removeItem("access_token");
      this.props.history.push("/login");
    }
  };

  _onAction(e) {
    //console.log("user did something", e);
  }

  _onActive(e) {
    //console.log("user is active", e);
    //console.log("time remaining", this.idleTimer.getRemainingTime());
    //localStorage.removeItem("access_token");
    //this.props.history.push("/login");
    this.logoutUser();
  }

  _onIdle(e) {
    //console.log("user is idle", e);
    //console.log("last active", this.idleTimer.getLastActiveTime());
    //localStorage.removeItem("access_token");
    //this.props.history.push("/login");
    this.logoutUser();
  }
  render = () => {
    return (
      <Fragment>
        <Helmet titleTemplate={`%s - ${strings.default.app.name}`} />
        <div id="app-site">
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={1000 * 60 * 120}
          />
          <div className="app-main">
            <BodyClassName className="dark-indigo">
              {this.props.children}
            </BodyClassName>
          </div>
        </div>
      </Fragment>
    );
  };
}
export default Layout;
