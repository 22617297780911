import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { get } from "lodash";

import * as autocompleteActions from "../../../redux/modules/autocomplete";
import localize from "helpers/localize";

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    if (props.value) {
      try {
        props.setSelected(JSON.parse(props.value));
      } catch (err) {}
    }
  }

  handleSearch = option => {
    this.props.onChange(
      this.props.name,
      get(option[0], "id") ? JSON.stringify(option[0]) : ""
    );
  };

  autocompleteSearch = query => {
    this.props.search(
      this.props.url.path,
      this.props.url.params.attribute,
      query
    );
  };

  render() {
    return (
      <Fragment>
        <AsyncTypeahead
          inputProps={{ name: this.props.name }}
          isLoading={this.props.loading}
          selected={this.props.selected}
          allowNew={false}
          options={this.props.options}
          multiple={false}
          minLength={this.props.minChars || 3}
          onSearch={this.autocompleteSearch}
          labelKey={option => `${option.label}`}
          onChange={this.handleSearch}
          emptyLabel={localize("basic", "Nem található")}
          promptText=""
          searchText={localize("basic", "Keresés")}
          placeholder=""
          renderMenuItemChildren={(option, props) => {
            return <div>{option.label}</div>;
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    options: state.autocomplete.options,
    loading: state.autocomplete.loading,
    selected: state.autocomplete.selected
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      search: autocompleteActions.search,
      setSelected: autocompleteActions.setSelected
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Autocomplete);
