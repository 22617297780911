import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { setLocale, getLocale } from "helpers/localize";
import LanguageIcon from "@material-ui/icons/Language";

export default function LanguageSelector() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const changeLocale = loc => evt => {
    setAnchorEl(null);
    setLocale(loc);
    window.location.reload();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        variant="text"
        style={{ color: "white" }}
      >
        <LanguageIcon style={{ marginRight: 8 }} />
        {window.localeData.locales[window.localeData.locale] || ""}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(window.localeData.locales).map((locale, index) => {
          const localeName = window.localeData.locales[locale];
          return (
            <MenuItem
              key={index}
              onClick={changeLocale(locale)}
              selected={locale === getLocale()}
            >
              {localeName}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
