import React from "react";
import PropTypes from "prop-types";

/**
 * Egyszerű img wrapper. Biztonságos képmegjelenítéshez fileId alapján. Minden egyéb property továbbítva lesz az img tag-nek
 *
 * @param {Object} props react component properties. Továbbküldve az img tag-nek;
 *
 * @return <img />
 */
const SignatureImage = props => {
  const { worksheetToken, phase, ...rest } = props;

  //Tokent tartalmazó letöltési url előállítása
  const url = `${
    process.env.REACT_APP_HOST
  }/v1/worksheet-signing-tools/worksheet-signature/${worksheetToken}?phase=${phase}`;

  return <img {...rest} src={url} />;
};

SignatureImage.propTypes = {
  worksheetToken: PropTypes.string.isRequired, //Munkalap token
  phase: PropTypes.oneOf(["order", "takeover"]).isRequired //Fázis: rendelés és átvétel
};

export default SignatureImage;
