import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EditEventType from "./views/EditEventType";
import * as eventTypesActions from "../../../../redux/modules/event-types";

const mapStateToProps = state => {
  return {
    loading: state.eventTypes.loading,
    success: state.eventTypes.success,
    saved: state.eventTypes.saved,
    message: state.eventTypes.message,
    fieldErrors: state.eventTypes.formErrors,
    id: state.eventTypes.id,
    viewData: state.eventTypes.viewData,
    reducerName: "eventTypes",
    model: state.options.model,
    baseFields: state.options.baseFields,
    enabledFields: state.options.enabledFields,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: eventTypesActions.createEvent,
      update: eventTypesActions.updateEvent,
      view: eventTypesActions.viewEvent,
      reset: eventTypesActions.reset,
      resetForm: eventTypesActions.resetForm
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEventType);
